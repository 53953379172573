<div class="modal-header">
  <h3>Document Details</h3>
  <button class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h2></h2>
</div>

<div class="modal-body">
  <div>
    <span>Customer Lead: </span>
    <b *ngIf="document.customer_lead else notAvailable">
      {{ document.customer_lead?.first_name | titlecase}} {{ document.customer_lead?.last_name | titlecase}}
    </b>
  </div>
  <div>
    <span>Supplier Lead: </span>
    <b *ngIf="document.supplier_lead else notAvailable">
      {{ document.supplier_lead?.first_name | titlecase}} {{ document.supplier_lead?.last_name | titlecase}}
    </b>
  </div>
  <div>
    Expiration Date: <b *ngIf="document.expires_at else notAvailable">
    {{ document.expires_at | date:'mediumDate': 'utc'}}</b>
  </div>
  <div>
    Lead time days: <b *ngIf="document.std_lead_time_days else notAvailable">{{ document.std_lead_time_days }}</b></div>

  <div class="file text-center mt-2">
    <a (click)="showFile(document?.file)">{{ document?.file.file_name }}</a>
    <br>
    <small>{{ document?.file.file_size | fileSize}}</small>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-danger" type="button" (click)="close()">Close</button>
</div>

<ng-template #notAvailable>
  <b>Not Available</b>
</ng-template>
