import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'ngx-date-picker-field',
  templateUrl: './date-picker-field.component.html',
  styleUrls: ['./date-picker-field.component.scss'],
  providers: [
    { provide: 'WINDOW', useValue: window },
  ],
})
export class DatePickerFieldComponent implements OnInit, OnChanges {

  @Input() placeholder;
  @Input() model;
  @Input() matchType;

  @Output() filter = new EventEmitter();

  @Output() modelChange = new EventEmitter();
  @Output() matchTypeChange = new EventEmitter();
  @ViewChild('filterMenu', { static: true }) filterMenu: ElementRef;

  fromDate: any;
  toDate: any;
  dateRangeParsed = '';

  showCalendar;
  dateSelectedControlValue;

  constructor(private elementRef: ElementRef,
              private renderer2: Renderer2,
              @Inject('WINDOW') private window,
  ) { }

  ngOnInit() {
  }

  setCalendarPosition() {
    const innerWidth = this.window.innerWidth;
    const containerSizes = this.elementRef.nativeElement.getBoundingClientRect();
    const selectMenuContainer = this.elementRef.nativeElement.querySelector('ngx-filter-menu .menu');
    const popupSizes = selectMenuContainer.getBoundingClientRect();
    let leftPosition = popupSizes.left || 0;
    if (popupSizes.width > containerSizes.width) {
      if (popupSizes.right > innerWidth) {
        leftPosition = popupSizes.width / 2;
        if ((popupSizes.right - leftPosition) > innerWidth) {
          leftPosition = popupSizes.width;
        }
        this.renderer2.setStyle(selectMenuContainer, 'left', leftPosition * -1 + 'px');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.model) {
      const [fromDate, toDate] = this.model.split('_');
      this.fromDate = fromDate;
      this.toDate = toDate;
    } else {
      this.dateRangeParsed = '';
      this.dateSelectedControlValue = undefined;
      this.fromDate = undefined;
      this.toDate = undefined;

    }
  }

  dateSelected($event) {
    this.dateSelectedControlValue = $event;
  }

  parseDateObject(datesArray, format = '', joinSelector = '') {
    const dates = [];
    if (datesArray[0]) {
      const { year, month, day } = datesArray[0];
      const fromDate = moment({ year, month: month - 1, day: day }).format(format);
      dates.push(fromDate);
    }
    if (datesArray[1]) {
      const { year, month, day } = datesArray[1];
      const toDate = moment({ year, month: month - 1, day: day }).format(format);
      dates.push(toDate);
    }
    return dates.join(joinSelector);
  }

  saveChanges($event) {
    if ($event) {
      this.matchType = 'exact';
      if (this.dateSelectedControlValue[1]) {
        this.matchType = 'between';
      }

      this.dateRangeParsed = this.parseDateObject(this.dateSelectedControlValue, 'YYYY/MM/DD', ' - ');
      this.model = this.parseDateObject(this.dateSelectedControlValue, 'YYYY-MM-DD', '_');
      this.modelChange.emit(this.model);
      this.matchTypeChange.emit(this.matchType);
      this.filter.emit(this.dateRangeParsed);
    } else {
      this.clear();
    }
    this.showCalendar = false;
  }

  clear() {
    this.dateRangeParsed = '';
    this.fromDate = undefined;
    this.toDate = undefined;
    this.model = undefined;
    this.matchType = undefined;
    this.showCalendar = false;

    this.modelChange.emit(this.model);
    this.matchTypeChange.emit(this.matchType);
    this.filter.emit(this.dateRangeParsed);
  }

  onFocusInput() {
    this.showCalendar = true;
    setTimeout(() => this.setCalendarPosition(), 200);
  }

  keypress($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
  }


  @HostListener('window:click', ['$event'])
  clickOutSite($event) {
    if (this.elementRef.nativeElement.contains($event.target)) {
      return;
    }
    this.showCalendar = false;
  }
}
