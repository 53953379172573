/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroCustomer_required_fileCollection } from '../models/lasgro-customer-_required-_file-collection';
import { LasgroOrganization_document } from '../models/lasgro-organization-_document';
import { LasgroCustomer_required_file } from '../models/lasgro-customer-_required-_file';
import { CustomerRequiredFileInputPayload } from '../models/customer-required-file-input-payload';
import { CustomerRequiredFileUpdateInputPayload } from '../models/customer-required-file-update-input-payload';
@Injectable({
  providedIn: 'root',
})
class CustomerRequiredFileService extends __BaseService {
  static readonly customerRequiredFileSupplierListPath = '/organizations/{organizationID}/customers/{customerID}/file_requirements';
  static readonly customerRequiredFileAttachToRequirementPath = '/organizations/{organizationID}/customers/{customerID}/file_requirements/{requirementID}/attach/{fileID}';
  static readonly customerRequiredFileListPath = '/organizations/{organizationID}/suppliers/{supplierID}/file_requirements';
  static readonly customerRequiredFileAddPath = '/organizations/{organizationID}/suppliers/{supplierID}/file_requirements';
  static readonly customerRequiredFileDeletePath = '/organizations/{organizationID}/suppliers/{supplierID}/file_requirements/{requirementID}';
  static readonly customerRequiredFileUpdatePath = '/organizations/{organizationID}/suppliers/{supplierID}/file_requirements/{requirementID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * supplier_list customer_required_file
   *
   * Lists all of this customer's file requirement definitions
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileSupplierListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `customerID`:
   *
   * @return OK
   */
  customerRequiredFileSupplierListResponse(params: CustomerRequiredFileService.CustomerRequiredFileSupplierListParams): __Observable<__StrictHttpResponse<LasgroCustomer_required_fileCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/customers/${encodeURIComponent(String(params.customerID))}/file_requirements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustomer_required_fileCollection>;
      })
    );
  }
  /**
   * supplier_list customer_required_file
   *
   * Lists all of this customer's file requirement definitions
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileSupplierListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `customerID`:
   *
   * @return OK
   */
  customerRequiredFileSupplierList(params: CustomerRequiredFileService.CustomerRequiredFileSupplierListParams): __Observable<LasgroCustomer_required_fileCollection> {
    return this.customerRequiredFileSupplierListResponse(params).pipe(
      __map(_r => _r.body as LasgroCustomer_required_fileCollection)
    );
  }

  /**
   * attach_to_requirement customer_required_file
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileAttachToRequirementParams` containing the following parameters:
   *
   * - `requirementID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * @return OK
   */
  customerRequiredFileAttachToRequirementResponse(params: CustomerRequiredFileService.CustomerRequiredFileAttachToRequirementParams): __Observable<__StrictHttpResponse<LasgroOrganization_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/customers/${encodeURIComponent(String(params.customerID))}/file_requirements/${encodeURIComponent(String(params.requirementID))}/attach/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_document>;
      })
    );
  }
  /**
   * attach_to_requirement customer_required_file
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileAttachToRequirementParams` containing the following parameters:
   *
   * - `requirementID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * @return OK
   */
  customerRequiredFileAttachToRequirement(params: CustomerRequiredFileService.CustomerRequiredFileAttachToRequirementParams): __Observable<LasgroOrganization_document> {
    return this.customerRequiredFileAttachToRequirementResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_document)
    );
  }

  /**
   * list customer_required_file
   *
   * Lists all customer required file definitions for this supplier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileListParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerRequiredFileListResponse(params: CustomerRequiredFileService.CustomerRequiredFileListParams): __Observable<__StrictHttpResponse<LasgroCustomer_required_fileCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/file_requirements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustomer_required_fileCollection>;
      })
    );
  }
  /**
   * list customer_required_file
   *
   * Lists all customer required file definitions for this supplier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileListParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerRequiredFileList(params: CustomerRequiredFileService.CustomerRequiredFileListParams): __Observable<LasgroCustomer_required_fileCollection> {
    return this.customerRequiredFileListResponse(params).pipe(
      __map(_r => _r.body as LasgroCustomer_required_fileCollection)
    );
  }

  /**
   * add customer_required_file
   *
   * Adds a file requirement for the supplier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileAddParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return Created
   */
  customerRequiredFileAddResponse(params: CustomerRequiredFileService.CustomerRequiredFileAddParams): __Observable<__StrictHttpResponse<LasgroCustomer_required_file>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/file_requirements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustomer_required_file>;
      })
    );
  }
  /**
   * add customer_required_file
   *
   * Adds a file requirement for the supplier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileAddParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return Created
   */
  customerRequiredFileAdd(params: CustomerRequiredFileService.CustomerRequiredFileAddParams): __Observable<LasgroCustomer_required_file> {
    return this.customerRequiredFileAddResponse(params).pipe(
      __map(_r => _r.body as LasgroCustomer_required_file)
    );
  }

  /**
   * delete customer_required_file
   *
   * Deletes a file requirement
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileDeleteParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `requirementID`:
   *
   * - `organizationID`:
   */
  customerRequiredFileDeleteResponse(params: CustomerRequiredFileService.CustomerRequiredFileDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/file_requirements/${encodeURIComponent(String(params.requirementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete customer_required_file
   *
   * Deletes a file requirement
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileDeleteParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `requirementID`:
   *
   * - `organizationID`:
   */
  customerRequiredFileDelete(params: CustomerRequiredFileService.CustomerRequiredFileDeleteParams): __Observable<null> {
    return this.customerRequiredFileDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update customer_required_file
   *
   * Updates a file requirement
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileUpdateParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `requirementID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerRequiredFileUpdateResponse(params: CustomerRequiredFileService.CustomerRequiredFileUpdateParams): __Observable<__StrictHttpResponse<LasgroCustomer_required_file>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/file_requirements/${encodeURIComponent(String(params.requirementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustomer_required_file>;
      })
    );
  }
  /**
   * update customer_required_file
   *
   * Updates a file requirement
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerRequiredFileService.CustomerRequiredFileUpdateParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `requirementID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerRequiredFileUpdate(params: CustomerRequiredFileService.CustomerRequiredFileUpdateParams): __Observable<LasgroCustomer_required_file> {
    return this.customerRequiredFileUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroCustomer_required_file)
    );
  }
}

module CustomerRequiredFileService {

  /**
   * Parameters for customerRequiredFileSupplierList
   */
  export interface CustomerRequiredFileSupplierListParams {
    organizationID: string;
    customerID: string;
  }

  /**
   * Parameters for customerRequiredFileAttachToRequirement
   */
  export interface CustomerRequiredFileAttachToRequirementParams {
    requirementID: string;
    organizationID: string;
    fileID: string;
    customerID: string;
  }

  /**
   * Parameters for customerRequiredFileList
   */
  export interface CustomerRequiredFileListParams {
    supplierID: string;
    organizationID: string;
  }

  /**
   * Parameters for customerRequiredFileAdd
   */
  export interface CustomerRequiredFileAddParams {
    supplierID: string;
    payload: CustomerRequiredFileInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for customerRequiredFileDelete
   */
  export interface CustomerRequiredFileDeleteParams {
    supplierID: string;
    requirementID: string;
    organizationID: string;
  }

  /**
   * Parameters for customerRequiredFileUpdate
   */
  export interface CustomerRequiredFileUpdateParams {
    supplierID: string;
    requirementID: string;
    payload: CustomerRequiredFileUpdateInputPayload;
    organizationID: string;
  }
}

export { CustomerRequiredFileService }
