import { Injectable } from '@angular/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AuthService {
  @LocalStorage() role: string;
  @LocalStorage() token: string;
  @LocalStorage() refresh: string;
  @LocalStorage() organizationId: string;

  isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAuthenticated());
  public isAuthenticated$: Observable<boolean>;

  constructor(
    public jwtHelper: JwtHelperService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {
    this.isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
    this.isAuthenticatedSubject.next(this.isAuthenticated());
    this.localStorageService.observe('token').subscribe(() => {
      this.isAuthenticatedSubject.next(this.isAuthenticated());
    });
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.token);
  }

  public logOutUser() {
    this.token = '';
    this.localStorageService.clear();
    return this.router.navigateByUrl('/auth/logout');
  }
}
