/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroShipment_tracking_references } from '../models/lasgro-shipment-_tracking-_references';
import { ShipmentTrackingReferencesAddInputPayload } from '../models/shipment-tracking-references-add-input-payload';
import { ShipmentTrackingReferencesUpdateInputPayload } from '../models/shipment-tracking-references-update-input-payload';
@Injectable({
  providedIn: 'root',
})
class ShipmentTrackingReferencesService extends __BaseService {
  static readonly shipmentTrackingReferencesListPath = '/organizations/{organizationID}/shipment_tracking/{trackingID}/references';
  static readonly shipmentTrackingReferencesAddPath = '/organizations/{organizationID}/shipment_tracking/{trackingID}/references';
  static readonly shipmentTrackingReferencesUpdatePath = '/organizations/{organizationID}/shipment_tracking/{trackingID}/references/{referenceID}';
  static readonly shipmentTrackingReferencesDeletePath = '/organizations/{organizationID}/shipment_tracking/{trackingID}/references/{referenceID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list shipment_tracking_references
   *
   * Returns the references (tags) for the specified shipment tracking.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingReferencesService.ShipmentTrackingReferencesListParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingReferencesListResponse(params: ShipmentTrackingReferencesService.ShipmentTrackingReferencesListParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking_references>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/references`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking_references>;
      })
    );
  }
  /**
   * list shipment_tracking_references
   *
   * Returns the references (tags) for the specified shipment tracking.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingReferencesService.ShipmentTrackingReferencesListParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingReferencesList(params: ShipmentTrackingReferencesService.ShipmentTrackingReferencesListParams): __Observable<LasgroShipment_tracking_references> {
    return this.shipmentTrackingReferencesListResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking_references)
    );
  }

  /**
   * add shipment_tracking_references
   *
   * Returns the references (tags) for the specified shipment tracking.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingReferencesService.ShipmentTrackingReferencesAddParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `payload`: Input to add references (tags) for a shipment tracking.
   *
   * - `organizationID`:
   */
  shipmentTrackingReferencesAddResponse(params: ShipmentTrackingReferencesService.ShipmentTrackingReferencesAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/references`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add shipment_tracking_references
   *
   * Returns the references (tags) for the specified shipment tracking.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingReferencesService.ShipmentTrackingReferencesAddParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `payload`: Input to add references (tags) for a shipment tracking.
   *
   * - `organizationID`:
   */
  shipmentTrackingReferencesAdd(params: ShipmentTrackingReferencesService.ShipmentTrackingReferencesAddParams): __Observable<null> {
    return this.shipmentTrackingReferencesAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update shipment_tracking_references
   *
   * Updates a reference (tag) for the specified shipment tracking.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingReferencesService.ShipmentTrackingReferencesUpdateParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `referenceID`:
   *
   * - `payload`: Input to update references (tags) for a shipment tracking.
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingReferencesUpdateResponse(params: ShipmentTrackingReferencesService.ShipmentTrackingReferencesUpdateParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking_references>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/references/${encodeURIComponent(String(params.referenceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking_references>;
      })
    );
  }
  /**
   * update shipment_tracking_references
   *
   * Updates a reference (tag) for the specified shipment tracking.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingReferencesService.ShipmentTrackingReferencesUpdateParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `referenceID`:
   *
   * - `payload`: Input to update references (tags) for a shipment tracking.
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingReferencesUpdate(params: ShipmentTrackingReferencesService.ShipmentTrackingReferencesUpdateParams): __Observable<LasgroShipment_tracking_references> {
    return this.shipmentTrackingReferencesUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking_references)
    );
  }

  /**
   * delete shipment_tracking_references
   *
   * Deletes a reference (tag) for the specified shipment tracking.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingReferencesService.ShipmentTrackingReferencesDeleteParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `referenceID`:
   *
   * - `organizationID`:
   */
  shipmentTrackingReferencesDeleteResponse(params: ShipmentTrackingReferencesService.ShipmentTrackingReferencesDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/references/${encodeURIComponent(String(params.referenceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete shipment_tracking_references
   *
   * Deletes a reference (tag) for the specified shipment tracking.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingReferencesService.ShipmentTrackingReferencesDeleteParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `referenceID`:
   *
   * - `organizationID`:
   */
  shipmentTrackingReferencesDelete(params: ShipmentTrackingReferencesService.ShipmentTrackingReferencesDeleteParams): __Observable<null> {
    return this.shipmentTrackingReferencesDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShipmentTrackingReferencesService {

  /**
   * Parameters for shipmentTrackingReferencesList
   */
  export interface ShipmentTrackingReferencesListParams {
    trackingID: string;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingReferencesAdd
   */
  export interface ShipmentTrackingReferencesAddParams {
    trackingID: string;

    /**
     * Input to add references (tags) for a shipment tracking.
     */
    payload: ShipmentTrackingReferencesAddInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingReferencesUpdate
   */
  export interface ShipmentTrackingReferencesUpdateParams {
    trackingID: string;
    referenceID: string;

    /**
     * Input to update references (tags) for a shipment tracking.
     */
    payload: ShipmentTrackingReferencesUpdateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingReferencesDelete
   */
  export interface ShipmentTrackingReferencesDeleteParams {
    trackingID: string;
    referenceID: string;
    organizationID: string;
  }
}

export { ShipmentTrackingReferencesService }
