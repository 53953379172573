import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '@app/app.config';
import { ContractDto, PurchaseOrderSubscriberModel } from '@app/models/contract.dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RcdssApplicationFormModel } from '@models/rcdss-application-form.model';

@Injectable({ providedIn: 'root' })
export class PurchaseOrderService {

  constructor(private httpClient: HttpClient) {
  }

  getPurchaseOrder(purchaseOrderId: string): Observable<ContractDto> {
    const endpoint = `${CONFIG.API}/purchase_orders/${purchaseOrderId}`;
    return this.httpClient.get<ContractDto>(endpoint);
  }

  getSubscribers(purchaseOrderId: string): Observable<PurchaseOrderSubscriberModel[]> {
    const endpoint = `${CONFIG.API}/purchase_orders/${purchaseOrderId}/subscribers`;
    return this.httpClient.get<PurchaseOrderSubscriberModel[]>(endpoint)
      .pipe(map(results => results.map(s => new PurchaseOrderSubscriberModel(s))));
  }

  addSubscriber(purchaseOrderId: string, userId: string) {
    const endpoint = `${CONFIG.API}/purchase_orders/${purchaseOrderId}/subscribers/${userId}`;
    return this.httpClient.post(endpoint, {});
  }

  removeSubscriber(purchaseOrderId: string, userId: string) {
    const endpoint = `${CONFIG.API}/purchase_orders/${purchaseOrderId}/subscribers/${userId}`;
    return this.httpClient.delete(endpoint);
  }

  removeManySubscribers(purchaseOrderId: string, userIDs: string[]): Observable<any> {
    const endpoint = `${CONFIG.API}/purchase_orders/${purchaseOrderId}/subscribers/delete`;
    return this.httpClient.post(endpoint, { ids: userIDs });
  }

  sendPoReminder(purchaseOrderId) {
    const endpoint = `${CONFIG.API}/purchase_orders/${purchaseOrderId}/send_reminder`;
    return this.httpClient.put(endpoint, {});
  }

  getDrafts(organizationId: string, deleted = false) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders_drafts?deleted=${deleted}`;
    return this.httpClient.get<ContractDto[]>(endpoint);
  }

  saveDraft(organizationId: string, data: ContractDto, finalize = false, noApprover = false) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders_draft?finalize=${finalize}&noApprover=${noApprover}`;
    return this.httpClient.post<ContractDto>(endpoint, data);
  }

  updateDraft(organizationId: string, data: ContractDto, finalize = false) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders_draft?finalize=${finalize}`;
    return this.httpClient.put<ContractDto>(endpoint, data);
  }

  deleteDraft(organizationId, purchaseOrderId) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders_draft/${purchaseOrderId}`;
    return this.httpClient.delete(endpoint);
  }

  deleteDraftPermanently(organizationId, purchaseOrderId) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders_draft/${purchaseOrderId}/delete_permanently`;
    return this.httpClient.delete(endpoint);
  }

  recoverDraft(organizationId, purchaseOrderId) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders_draft/${purchaseOrderId}/recover`;
    return this.httpClient.patch(endpoint, {});
  }

  createPurchaseOrder(organizationId: string, purchaseOrder: ContractDto): Observable<ContractDto> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders`;
    return this.httpClient.post<ContractDto>(endpoint, purchaseOrder);
  }

  updatePurchaseOrder(organizationId: string, po: ContractDto | any) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${po.id}`;
    return this.httpClient.put<ContractDto>(endpoint, po);
  }

  setPoMainFile(fileId: string) {
    const endpoint = `${CONFIG.API}/purchase_order_files/${fileId}/set_main_file`;
    return this.httpClient.patch(endpoint, { is_main_file: true });
  }

  restorePurchaseOrderStatus(purchaseOrderId: string) {
    const endpoint = `${CONFIG.API}/purchase_orders/${purchaseOrderId}/restore`;
    return this.httpClient.patch(endpoint, {});
  }

  saveRcdssApplicationForm(organizationId: string, purchaseOrderId: string, type: string, isDraft: boolean, data: any) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/rcdss_application_form`;
    return this.httpClient.post(endpoint, {
      type,
      is_draft: isDraft,
      json_data: JSON.stringify(data),
    });
  }

  changeStatusRcdssApplicationForm(organizationId: string, purchaseOrderId: string, status: string, reason: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/rcdss_application_form/change_status`;
    return this.httpClient.put(endpoint, {
      status: status,
      reason: reason,
    });
  }

  getRcdssApplicationForm(organizationId: string, purchaseOrderId: string): Observable<RcdssApplicationFormModel> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/rcdss_application_form`;
    return this.httpClient.get<RcdssApplicationFormModel>(endpoint);
  }

  saveRcdssSordForm(organizationId: string, purchaseOrderId: string, data: any): Observable<any> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/rcdss_sord_form`;
    return this.httpClient.post(endpoint, {
      Sord: JSON.stringify(data),
    });
  }

  getRcdssSordForm(organizationId: string, purchaseOrderId: string): Observable<any> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/rcdss_sord_form`;
    return this.httpClient.get<any>(endpoint);
  }

}
