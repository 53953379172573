import { Component, Input, OnInit } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { CompanyService, Toaster } from '@services/index';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-floating-action-button',
  templateUrl: './floating-action-button.component.html',
  styleUrls: ['./floating-action-button.component.scss'],
})
export class FloatingActionButtonComponent implements OnInit {
  @LocalStorage() companyLogo: string;
  @LocalStorage() companyType: string;
  @LocalStorage() role: string;

  @Input() additionalActions;

  actions: any[] = [
    /*{
      icon: 'plus',
      label: 'Add Contract',
      visible: true,
      fn: this.addContract.bind(this),
    },*/
  ];

  constructor(
    private $company: CompanyService,
    private $toaster: Toaster,
    protected router: Router,
  ) { }

  ngOnInit(): void {

  }

  executeAction(action) {
    if (action?.fn) {
      action.fn();
    }
  }

  getVisibility(action) {
    // Admin must see all menus
    if (this.role === 'Lasgro Admin') {
      return true;
    }
    // Action is hidden
    if (action?.visible === false) {
      return false;
    }
    // Role filter
    if (action.forRole && action.forRole !== this.role) {
      return false;
    }
    // Company type filter
    if (action.forCompanyType && action.forCompanyType !== this.companyType) {
      return false;
    }
    // Passed all filters
    return true;
  }

  addContract() {
    this.router.navigate(['purchase-order', 'create-simple']);
  }
}
