import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent implements OnInit {
  @Output() onFilter: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      const main_focus = document.getElementById('main_focus');
      const save_btn = document.getElementById('save_filter_btn');
      main_focus ? main_focus.focus() : save_btn.focus();
    });

    const menuRef = document.getElementsByClassName('menu');
    if (menuRef) {
      const menu = menuRef[0];
      const menuOffsetWidth = menu['offsetWidth'] || 0;
      const menuParentOffsetLeft = menu['offsetParent'] && menu['offsetParent'].offsetLeft;

      const columnsRef: any = document.getElementsByClassName('columns');
      const columnsOffsetWidth: number = columnsRef ? columnsRef.item(0)['offsetWidth'] : 0;

      const finalOffset = columnsOffsetWidth - (menuParentOffsetLeft + menuOffsetWidth);
      menu.setAttribute('style', `left: ${ finalOffset < 0 ? finalOffset - 10 : 0 }px;`);
    }
  }
}
