/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroChecklist_task } from '../models/lasgro-checklist-_task';
import { ChecklistTaskUpdateInputPayload } from '../models/checklist-task-update-input-payload';
import { LasgroChecklist_taskCollection } from '../models/lasgro-checklist-_task-collection';
import { LasgroId } from '../models/lasgro-id';
import { ChecklistTaskAddInputPayload } from '../models/checklist-task-add-input-payload';
@Injectable({
  providedIn: 'root',
})
class ChecklistTasksService extends __BaseService {
  static readonly checklistTasksShowPath = '/checklist_tasks/{taskID}';
  static readonly checklistTasksDeletePath = '/checklist_tasks/{taskID}';
  static readonly checklistTasksUpdatePath = '/checklist_tasks/{taskID}';
  static readonly checklistTasksListPath = '/checklists/{checklistID}/checklist_tasks';
  static readonly checklistTasksAddPath = '/checklists/{checklistID}/checklist_tasks';
  static readonly checklistTasksGetDashboardTasksPath = '/dashboard_tasks';
  static readonly checklistTasksList1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks';
  static readonly checklistTasksAdd1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks';
  static readonly checklistTasksShow1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks/{taskID}';
  static readonly checklistTasksDelete1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks/{taskID}';
  static readonly checklistTasksUpdate1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks/{taskID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * show checklist_tasks
   *
   * Checklist task by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksShowParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * @return OK
   */
  checklistTasksShowResponse(params: ChecklistTasksService.ChecklistTasksShowParams): __Observable<__StrictHttpResponse<LasgroChecklist_task>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklist_task>;
      })
    );
  }
  /**
   * show checklist_tasks
   *
   * Checklist task by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksShowParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * @return OK
   */
  checklistTasksShow(params: ChecklistTasksService.ChecklistTasksShowParams): __Observable<LasgroChecklist_task> {
    return this.checklistTasksShowResponse(params).pipe(
      __map(_r => _r.body as LasgroChecklist_task)
    );
  }

  /**
   * delete checklist_tasks
   *
   * Deletes checklist task. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksDeleteParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTasksDeleteResponse(params: ChecklistTasksService.ChecklistTasksDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete checklist_tasks
   *
   * Deletes checklist task. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksDeleteParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTasksDelete(params: ChecklistTasksService.ChecklistTasksDeleteParams): __Observable<null> {
    return this.checklistTasksDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update checklist_tasks
   *
   * Updates checklist task. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksUpdateParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `payload`: Input payload of checklist task
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTasksUpdateResponse(params: ChecklistTasksService.ChecklistTasksUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update checklist_tasks
   *
   * Updates checklist task. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksUpdateParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `payload`: Input payload of checklist task
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTasksUpdate(params: ChecklistTasksService.ChecklistTasksUpdateParams): __Observable<null> {
    return this.checklistTasksUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list checklist_tasks
   *
   * List all checklists tasks
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksListParams` containing the following parameters:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  checklistTasksListResponse(params: ChecklistTasksService.ChecklistTasksListParams): __Observable<__StrictHttpResponse<LasgroChecklist_taskCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklist_taskCollection>;
      })
    );
  }
  /**
   * list checklist_tasks
   *
   * List all checklists tasks
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksListParams` containing the following parameters:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  checklistTasksList(params: ChecklistTasksService.ChecklistTasksListParams): __Observable<LasgroChecklist_taskCollection> {
    return this.checklistTasksListResponse(params).pipe(
      __map(_r => _r.body as LasgroChecklist_taskCollection)
    );
  }

  /**
   * add checklist_tasks
   *
   * Create checklist task. Only customers can create, modify or delete checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksAddParams` containing the following parameters:
   *
   * - `payload`: Input payload of checklist task
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return Created
   */
  checklistTasksAddResponse(params: ChecklistTasksService.ChecklistTasksAddParams): __Observable<__StrictHttpResponse<LasgroId>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroId>;
      })
    );
  }
  /**
   * add checklist_tasks
   *
   * Create checklist task. Only customers can create, modify or delete checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksAddParams` containing the following parameters:
   *
   * - `payload`: Input payload of checklist task
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return Created
   */
  checklistTasksAdd(params: ChecklistTasksService.ChecklistTasksAddParams): __Observable<LasgroId> {
    return this.checklistTasksAddResponse(params).pipe(
      __map(_r => _r.body as LasgroId)
    );
  }

  /**
   * get_dashboard_tasks checklist_tasks
   *
   * Gets an array of pending tasks for the current user's open POs
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  checklistTasksGetDashboardTasksResponse(): __Observable<__StrictHttpResponse<LasgroChecklist_task>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dashboard_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklist_task>;
      })
    );
  }
  /**
   * get_dashboard_tasks checklist_tasks
   *
   * Gets an array of pending tasks for the current user's open POs
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  checklistTasksGetDashboardTasks(): __Observable<LasgroChecklist_task> {
    return this.checklistTasksGetDashboardTasksResponse().pipe(
      __map(_r => _r.body as LasgroChecklist_task)
    );
  }

  /**
   * list checklist_tasks
   *
   * List all checklists tasks
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistTasksList1Response(params: ChecklistTasksService.ChecklistTasksList1Params): __Observable<__StrictHttpResponse<LasgroChecklist_taskCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklist_taskCollection>;
      })
    );
  }
  /**
   * list checklist_tasks
   *
   * List all checklists tasks
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistTasksList1(params: ChecklistTasksService.ChecklistTasksList1Params): __Observable<LasgroChecklist_taskCollection> {
    return this.checklistTasksList1Response(params).pipe(
      __map(_r => _r.body as LasgroChecklist_taskCollection)
    );
  }

  /**
   * add checklist_tasks
   *
   * Create checklist task. Only customers can create, modify or delete checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksAdd1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist task
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return Created
   */
  checklistTasksAdd1Response(params: ChecklistTasksService.ChecklistTasksAdd1Params): __Observable<__StrictHttpResponse<LasgroId>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroId>;
      })
    );
  }
  /**
   * add checklist_tasks
   *
   * Create checklist task. Only customers can create, modify or delete checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksAdd1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist task
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return Created
   */
  checklistTasksAdd1(params: ChecklistTasksService.ChecklistTasksAdd1Params): __Observable<LasgroId> {
    return this.checklistTasksAdd1Response(params).pipe(
      __map(_r => _r.body as LasgroId)
    );
  }

  /**
   * show checklist_tasks
   *
   * Checklist task by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksShow1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistTasksShow1Response(params: ChecklistTasksService.ChecklistTasksShow1Params): __Observable<__StrictHttpResponse<LasgroChecklist_task>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklist_task>;
      })
    );
  }
  /**
   * show checklist_tasks
   *
   * Checklist task by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksShow1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistTasksShow1(params: ChecklistTasksService.ChecklistTasksShow1Params): __Observable<LasgroChecklist_task> {
    return this.checklistTasksShow1Response(params).pipe(
      __map(_r => _r.body as LasgroChecklist_task)
    );
  }

  /**
   * delete checklist_tasks
   *
   * Deletes checklist task. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksDelete1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTasksDelete1Response(params: ChecklistTasksService.ChecklistTasksDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete checklist_tasks
   *
   * Deletes checklist task. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksDelete1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTasksDelete1(params: ChecklistTasksService.ChecklistTasksDelete1Params): __Observable<null> {
    return this.checklistTasksDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update checklist_tasks
   *
   * Updates checklist task. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksUpdate1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist task
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTasksUpdate1Response(params: ChecklistTasksService.ChecklistTasksUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;


    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update checklist_tasks
   *
   * Updates checklist task. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTasksService.ChecklistTasksUpdate1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist task
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTasksUpdate1(params: ChecklistTasksService.ChecklistTasksUpdate1Params): __Observable<null> {
    return this.checklistTasksUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChecklistTasksService {

  /**
   * Parameters for checklistTasksShow
   */
  export interface ChecklistTasksShowParams {
    taskID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistTasksDelete
   */
  export interface ChecklistTasksDeleteParams {
    taskID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistTasksUpdate
   */
  export interface ChecklistTasksUpdateParams {
    taskID: string;

    /**
     * Input payload of checklist task
     */
    payload: ChecklistTaskUpdateInputPayload;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistTasksList
   */
  export interface ChecklistTasksListParams {
    checklistID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for checklistTasksAdd
   */
  export interface ChecklistTasksAddParams {

    /**
     * Input payload of checklist task
     */
    payload: ChecklistTaskAddInputPayload;
    checklistID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for checklistTasksList1
   */
  export interface ChecklistTasksList1Params {
    purchaseOrderID: string;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistTasksAdd1
   */
  export interface ChecklistTasksAdd1Params {
    purchaseOrderID: string;

    /**
     * Input payload of checklist task
     */
    payload: ChecklistTaskAddInputPayload;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistTasksShow1
   */
  export interface ChecklistTasksShow1Params {
    taskID: string;
    purchaseOrderID: string;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistTasksDelete1
   */
  export interface ChecklistTasksDelete1Params {
    taskID: string;
    purchaseOrderID: string;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistTasksUpdate1
   */
  export interface ChecklistTasksUpdate1Params {
    taskID: string;
    purchaseOrderID: string;

    /**
     * Input payload of checklist task
     */
    payload: ChecklistTaskUpdateInputPayload;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }
}

export { ChecklistTasksService }
