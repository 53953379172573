import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ngx-popup',
  styleUrls: ['./custom-popup.component.scss'],
  template: `
    <mat-dialog-content>
      <div class="py-2 text-right">
        <button type="button" mat-icon-button aria-label="close" (click)="close('close')" tabindex="3">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <p>{{description}}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button type="button" mat-button class="mx-4" (click)="close('success')" tabindex="1" cdkFocusInitial>YES</button>
      <button type="button" mat-button class="mx-4" (click)="close('cancel')" tabindex="2">NO</button>
    </mat-dialog-actions>
  `,
})

export class CustomPopupComponent {
  @Input() description;
  @Output() emitData: EventEmitter<any> = new EventEmitter<any>();

  constructor(public activeModal: MatDialogRef<CustomPopupComponent>) {}

  close(status: string) {
    this.emitData.emit(status);
    this.activeModal.close(status);
  }
}
