import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyDto, ContractDto, User } from '@app/models';
import { PoCommentsComponent } from '@app/modules/purchase-order/components/po-comments/po-comments.component';
import { ContractHelper } from '@app/shared/helpers/contract.helper';
import { Store } from '@ngrx/store';
import { LasgroPurchase_order_comments } from 'api/models';
import { PurchaseOrderCommentsService } from 'api/services';
import { NgxLoadingModule } from 'ngx-loading';
import { LocalStorage } from 'ngx-webstorage';
import { BaseComponent } from '../../base.component';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-comments',
  templateUrl: './contract-comments.component.html',
  styleUrls: ['./contract-comments.component.scss'],
  imports: [
    CommonModule,
    PoCommentsComponent
  ],
  providers: [
    ContractHelper
  ]
})
export class ContractCommentsComponent extends ContractBaseComponent implements OnInit {
  currentUser: User;
  contractFile: any;
  sendingToSupplier: boolean = false;
  loading: boolean = false;
  isEDI = false;
  allowEdit = true;
  contract: ContractDto;
  customer: CompanyDto;
  supplier: CompanyDto;
  @LocalStorage() organizationId;
  @LocalStorage() contractId;
  constructor(
    public contractHelper: ContractHelper,
    public store: Store<{ user: User }>,
    public injector: Injector
  ) {
    super(injector, store);
  }

  ngOnInit(): void {
  }
}
