<div class="background-screen">
  <div class="message-container">
    <h1 class="message-title">
      <img [width]="300" src="./assets/images/ion_logobrand.png"/>
    </h1>

    <!--        <router-outlet></router-outlet>-->
    <div class="text-center">
      <div *ngIf="!downloaded">
        <p>
          Your download will start shortly
        </p>
        <p>
          If the download didn't start automatically, <a class="btn-download ev-clickable" (click)="getFile()">click
          here</a>.
        </p>
      </div>

      <p *ngIf="downloaded">
        You can safely close this window now
      </p>
    </div>
  </div>
</div>
