export class Workflow {
	id: string;
	name: string;
	organization_id: string;
	created_at: string;
	is_enabled: string;
	description?: string;

	tasks?: TaskDefinition[];

	constructor(data) {
		Object.assign(this, data);
	}
}

export class TaskDefinition {
	id: string;
	workflow_id: string;
	task_type_id: string;
	order: number;

	name: string;
	description: string;
	entity_name: string;

	is_enabled: boolean;
	allow_anyone_to_complete: boolean;
	due_date_meta: DateMeta;
	created_at: string;
	default_responsible_id: string;

	intended_for_role: string;
	depends_on_task?: string;
	affected_task?: string;

	task_type?: TaskType;

	constructor(data) {
		Object.assign(this, data);
	}
}

export class WorkflowInstance {
	id: string;
	name: string;
	instance_id: string;
	created_at: string;
	started_at: string;
	last_executed_at: string;
	status: string;

	tasks: TaskInstance[];

	constructor(data) {
		Object.assign(this, data);
	}
}

export class TaskInstance {
	id: string;
	instance_id: string;
	order: string;

	name: string;
	status: string;
	description: string;
	task_type: TaskType;
	entity?: TaskValueEntity;

	responsible: TaskResponsible;
	completed_by?: TaskResponsible;
	completed_at?: string;
	affected_task_instance_id?: string;
	depends_on_task_instance_id?: string;

	active: boolean;
	allow_anyone_to_complete: boolean;
	is_dependency_completed: boolean;
	created_at: string;
	due_date: string;
}

export class TaskValueEntity {
	name: string;
	value: any;
	file?: TaskFile;
}

export class TaskFile {
	name: string;
	link: string;
	size: string;
}

export class TaskResponsible {
	id: string;
	name: string;
	organization_id: string;
	organization_name: string;
}

export class FieldNameToNamePair {
	field_name: string;
	name: string;
}

let DateFields: FieldNameToNamePair[] = [
	{field_name: 'due_date', name: 'Due Date'},
	{field_name: 'created_at', name: 'Creation Date'},
	{field_name: 'completed_at', name: 'Completion Date'},
];

export class DateMeta {
	number?: number;
	period?: string;
	before_after?: string;
	resource?: string;
	resource_id?: string;
	resource_date_field?: string;
	specific_date?: string;

	constructor(data) {
		Object.assign(this, data);
	}

	GetRelativeDateString() {
		if (this.specific_date) {
			return this.specific_date;
		}
		if (this.number) {
			let period = this.period.replace('_', ' ');
			let s = (this.number > 1 && !period.endsWith('s')) ? 's' : '';
			let date_field = DateFields.find(f => f.field_name === this.resource_date_field);
			return `${this.number} ${period}${s} ${this.before_after} ${this.resource}'s ${date_field.name}`;
		}
		return '';
	}
}

export class ResourceToDateFieldsMap {
	name: string;
	resource_name: string;
	fields: {field_name:string, name:string}[];
}

export let ValidResourceDateFields: ResourceToDateFieldsMap[] = [
	{
		name: 'Contract',
		resource_name: 'contract',
		fields: [
			{field_name: 'created_at', name: 'Creation Date'},
			{field_name: 'due_date', name: 'Due Date'},
		],
	},
	{
		name: 'Task',
		resource_name: 'task',
		fields: [
			{field_name: 'due_date', name: 'Due Date'},
			{field_name: 'created_at', name: 'Creation Date'},
			{field_name: 'completed_at', name: 'Completion Date'},
		],
	},
];

export let Roles: any[] = [
	{name: 'customers', description: 'Any user from Customer/Department company'},
	{name: 'suppliers', description: 'Any user from Supplier/Vendor company'},
];

export let DateMetaTypes: any[] = [
	{key: 'specific', name: 'Specific Date'},
	{key: 'relative', name: 'Relative Date'},
];

export let Periods: any[] = [
	{key: 'work_days', name: 'Working Day(s)'},
	{key: 'day', name: 'Calendar Day(s)'},
	{key: 'week', name: 'Week(s)'},
	{key: 'month', name: 'Month(s)'},
];

export class TaskType {
	id: string;
	name: string;
	entity_data_value_type: string;
	interaction_type: string;
	action_instructions: string;
	action_cta: string;
}