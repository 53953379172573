import { CommonModule } from '@angular/common';
import { Component, ElementRef, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { User } from '@app/models';
import { ChecklistComponent } from '@app/modules/purchase-order/components/checklist/checklist.component';
import { Store } from '@ngrx/store';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-tasks',
  templateUrl: './contract-tasks.component.html',
  styleUrls: ['./contract-tasks.component.scss'],
  imports: [CommonModule, ChecklistComponent],
})
export class ContractTasksComponent extends ContractBaseComponent implements OnInit {
  public selectedTaskId;
  constructor(
    public injector: Injector,
    public activatedRoute: ActivatedRoute,
    public elementRef: ElementRef,
    public router: Router,
    public store: Store<{ user: User }>
  ) {
    super(injector, store);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.activatedRoute.snapshot.queryParams.tab) {
      this.selectedTaskId = this.activatedRoute.snapshot.queryParams.taskId;
      let chosenMatTabLabel = this.activatedRoute.snapshot.queryParams.tab.toUpperCase();
      switch (chosenMatTabLabel) {
        case 'CHECKLIST':
          setTimeout(() => {
            if (this.elementRef.nativeElement) this.elementRef.nativeElement.scrollIntoView();
          }, 2000);
          break;
      }
    }
  }
}
