import { Component, HostListener, OnInit } from '@angular/core';
import { SocketsConnectorService } from '@app/ion-sockets/services/sockets-connector.service';
import { User } from '@app/models';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbIconLibraries } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CompanyInfoModalComponent } from '@shared/modals';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { debounceTime, filter, first, switchMap } from 'rxjs/operators';
import { AnalyticsService } from './@core/utils/analytics.service';
import { CompanyService } from '@app/services';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Training } from '@app/modules/training/models/training.model';
import { TrainingModalComponent } from '@app/modules/training/modals/training-modal/training-modal.component';
import { CalendlyService } from '@app/modules/calendly/services/calendly.service';
import { TrainingService } from '@app/modules/training/services/training.service';
import { AuthService } from '@services/auth.service';
import * as UserActions from '@app/store/actions/user.actions';
// import { UserSnapService } from './ngx-usersnap/services/user-snap-service';

declare var Calendly: any;

@Component({
  selector: 'ngx-app',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {

  user$: Observable<User>;
  currentUser: User;
  @LocalStorage() companyInfoModalOpen: boolean;
  @LocalStorage() organizationId: string;
  @LocalStorage() companyType: string;

  constructor(private analytics: AnalyticsService,
              private sockets: SocketsConnectorService,
              public jwtHelper: JwtHelperService,
              public localStorageService: LocalStorageService,
              public calendlyService: CalendlyService,
              public trainingService: TrainingService,
              public activatedRoute: ActivatedRoute,
              public authService: AuthService,
              private store: Store<{ user: User }>,
              public modalService: NgbModal,
              private $company: CompanyService,
              private iconLibraries: NbIconLibraries) {
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.store.dispatch(new UserActions.Fetch());
    }
    this.analytics.trackPageViews();
    this.iconLibraries.registerFontPack('font-awesome', { iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('lasgro-icons', { iconClassPrefix: 'ion' });
    this.iconLibraries.registerSvgPack('lasgro-svg', {
      'main-logo': '',
    });
    /*this.userSnapService.load({
      fields: {
        comment: { required: true },
        email: { required: true },
      },
    });*/

    this.user$ = this.store.select('user');
    this.user$
      .subscribe((user) => {
        this.currentUser = (user as User);
      });

    const token = this.localStorageService.retrieve('token');
    if (!this.jwtHelper.isTokenExpired(token)) {
      this.initSocketConnection();
    }
    this.localStorageService.observe('token')
      .pipe(
        filter(t => !!t),
      )
      .subscribe((value) => {
        if (!this.jwtHelper.isTokenExpired(value)) {
          this.initSocketConnection();
        }
      });

    /*this.userSnapService.api$.subscribe(UserSnap => {
      UserSnap.on('open', (e) => {
        const { organization_id, user_id } = this.jwtHelper.decodeToken();
        e.api.setValue('customData', { organization_id, user_id });
      });
    });

    this.user$ = this.store.select(state => state.user);
    this.user$
      .pipe(filter(u => !!u))
      .subscribe(user => {
        this.user = user;
        this.userSnapService.api$.subscribe(UserSnap => {
          UserSnap.on('open', (e) => {
            e.api.setValue('email', user.email);
          });
        });
      });*/

    this.activatedRoute.queryParams
      .pipe(filter(value => !_.isEmpty(value)))
      .pipe(first())
      .subscribe(params => {
        if (params['isFirst'] === 'true' && this.companyType === 'supplier' && params['isResetPassword'] !== 'true') {
          this.calendlyService.showCalendly();
        }
      });
  }

  initSocketConnection() {
    this.sockets.connect();
  }

  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload($event) {
    /*if (this.currentUser && !this.companyInfoModalOpen) {
      this.$company.shouldRequestInfo(this.organizationId).subscribe(res => {
        if (res.should_request_info) {
          this.openCompanyInfoModal();
        }
      });
      $event.returnValue = true;
      return true;
    }*/
  }

  @HostListener('window:unload', ['$event'])
  onunload($event) {
    this.companyInfoModalOpen = false;
  }

  openCompanyInfoModal() {
    const activeModal = this.modalService.open(CompanyInfoModalComponent, {
      size: 'lg',
      container: 'nb-layout',
      backdrop: 'static',
      keyboard: false,
    });
    activeModal.componentInstance.user = this.currentUser;
    /*activeModal.result.then(res => {
      if (res === 'close') {
        this.router.navigate(['../auth/logout'], { relativeTo: this.route });
      }
    });*/
  }

}
