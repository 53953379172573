import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';


@Injectable()
export class SweetAlertService {

  constructor() {}

  deleteConfirmation(): any {
    return Swal.fire({
      title: 'Delete Confirmation Required',
      text: 'This action cannot be undone, are you sure?',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
    });
  }
}
