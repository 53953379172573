/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroCustomer_part_identifier } from '../models/lasgro-customer-_part-_identifier';
import { CustomerPartIdentifierInputPayload } from '../models/customer-part-identifier-input-payload';
import { LasgroCustomer_part_identifierCollection } from '../models/lasgro-customer-_part-_identifier-collection';
import { AddCustomerPartIdentifierPayload } from '../models/add-customer-part-identifier-payload';
@Injectable({
  providedIn: 'root',
})
class CustomerPartIdentifierService extends __BaseService {
  static readonly customerPartIdentifierShowPath = '/customer_part_identifiers/{identifierID}';
  static readonly customerPartIdentifierUpdatePath = '/customer_part_identifiers/{identifierID}';
  static readonly customerPartIdentifierDeletePath = '/customer_part_identifiers/{identifierID}';
  static readonly customerPartIdentifierList1Path = '/organizations/{organizationID}/products/{productID}/customer_part_identifiers';
  static readonly customerPartIdentifierAdd1Path = '/organizations/{organizationID}/products/{productID}/customer_part_identifiers';
  static readonly customerPartIdentifierShow1Path = '/organizations/{organizationID}/products/{productID}/customer_part_identifiers/{identifierID}';
  static readonly customerPartIdentifierUpdate1Path = '/organizations/{organizationID}/products/{productID}/customer_part_identifiers/{identifierID}';
  static readonly customerPartIdentifierDelete1Path = '/organizations/{organizationID}/products/{productID}/customer_part_identifiers/{identifierID}';
  static readonly customerPartIdentifierListPath = '/products/{productID}/customer_part_identifiers';
  static readonly customerPartIdentifierAddPath = '/products/{productID}/customer_part_identifiers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * show customer_part_identifier
   *
   * Shows a specific customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierShowParams` containing the following parameters:
   *
   * - `identifierID`: Customer Part Identifier ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerPartIdentifierShowResponse(params: CustomerPartIdentifierService.CustomerPartIdentifierShowParams): __Observable<__StrictHttpResponse<LasgroCustomer_part_identifier>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.productID != null) __params = __params.set('productID', params.productID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer_part_identifiers/${encodeURIComponent(String(params.identifierID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustomer_part_identifier>;
      })
    );
  }
  /**
   * show customer_part_identifier
   *
   * Shows a specific customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierShowParams` containing the following parameters:
   *
   * - `identifierID`: Customer Part Identifier ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerPartIdentifierShow(params: CustomerPartIdentifierService.CustomerPartIdentifierShowParams): __Observable<LasgroCustomer_part_identifier> {
    return this.customerPartIdentifierShowResponse(params).pipe(
      __map(_r => _r.body as LasgroCustomer_part_identifier)
    );
  }

  /**
   * update customer_part_identifier
   *
   * Updates a specific customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `identifierID`: Customer Part Identifier ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   */
  customerPartIdentifierUpdateResponse(params: CustomerPartIdentifierService.CustomerPartIdentifierUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.productID != null) __params = __params.set('productID', params.productID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/customer_part_identifiers/${encodeURIComponent(String(params.identifierID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update customer_part_identifier
   *
   * Updates a specific customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `identifierID`: Customer Part Identifier ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   */
  customerPartIdentifierUpdate(params: CustomerPartIdentifierService.CustomerPartIdentifierUpdateParams): __Observable<null> {
    return this.customerPartIdentifierUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete customer_part_identifier
   *
   * Deletes a customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierDeleteParams` containing the following parameters:
   *
   * - `identifierID`: Customer Part Identifier ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   */
  customerPartIdentifierDeleteResponse(params: CustomerPartIdentifierService.CustomerPartIdentifierDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.productID != null) __params = __params.set('productID', params.productID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/customer_part_identifiers/${encodeURIComponent(String(params.identifierID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete customer_part_identifier
   *
   * Deletes a customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierDeleteParams` containing the following parameters:
   *
   * - `identifierID`: Customer Part Identifier ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   */
  customerPartIdentifierDelete(params: CustomerPartIdentifierService.CustomerPartIdentifierDeleteParams): __Observable<null> {
    return this.customerPartIdentifierDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list customer_part_identifier
   *
   * Lists customer part identifiers for a given supplier part/product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierList1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerPartIdentifierList1Response(params: CustomerPartIdentifierService.CustomerPartIdentifierList1Params): __Observable<__StrictHttpResponse<LasgroCustomer_part_identifierCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/customer_part_identifiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustomer_part_identifierCollection>;
      })
    );
  }
  /**
   * list customer_part_identifier
   *
   * Lists customer part identifiers for a given supplier part/product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierList1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerPartIdentifierList1(params: CustomerPartIdentifierService.CustomerPartIdentifierList1Params): __Observable<LasgroCustomer_part_identifierCollection> {
    return this.customerPartIdentifierList1Response(params).pipe(
      __map(_r => _r.body as LasgroCustomer_part_identifierCollection)
    );
  }

  /**
   * add customer_part_identifier
   *
   * Adds a customer part identifier to a given organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierAdd1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  customerPartIdentifierAdd1Response(params: CustomerPartIdentifierService.CustomerPartIdentifierAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/customer_part_identifiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add customer_part_identifier
   *
   * Adds a customer part identifier to a given organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierAdd1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  customerPartIdentifierAdd1(params: CustomerPartIdentifierService.CustomerPartIdentifierAdd1Params): __Observable<null> {
    return this.customerPartIdentifierAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show customer_part_identifier
   *
   * Shows a specific customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierShow1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `identifierID`: Customer Part Identifier ID
   *
   * @return OK
   */
  customerPartIdentifierShow1Response(params: CustomerPartIdentifierService.CustomerPartIdentifierShow1Params): __Observable<__StrictHttpResponse<LasgroCustomer_part_identifier>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/customer_part_identifiers/${encodeURIComponent(String(params.identifierID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustomer_part_identifier>;
      })
    );
  }
  /**
   * show customer_part_identifier
   *
   * Shows a specific customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierShow1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `identifierID`: Customer Part Identifier ID
   *
   * @return OK
   */
  customerPartIdentifierShow1(params: CustomerPartIdentifierService.CustomerPartIdentifierShow1Params): __Observable<LasgroCustomer_part_identifier> {
    return this.customerPartIdentifierShow1Response(params).pipe(
      __map(_r => _r.body as LasgroCustomer_part_identifier)
    );
  }

  /**
   * update customer_part_identifier
   *
   * Updates a specific customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierUpdate1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `identifierID`: Customer Part Identifier ID
   */
  customerPartIdentifierUpdate1Response(params: CustomerPartIdentifierService.CustomerPartIdentifierUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/customer_part_identifiers/${encodeURIComponent(String(params.identifierID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update customer_part_identifier
   *
   * Updates a specific customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierUpdate1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `identifierID`: Customer Part Identifier ID
   */
  customerPartIdentifierUpdate1(params: CustomerPartIdentifierService.CustomerPartIdentifierUpdate1Params): __Observable<null> {
    return this.customerPartIdentifierUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete customer_part_identifier
   *
   * Deletes a customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierDelete1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `identifierID`: Customer Part Identifier ID
   */
  customerPartIdentifierDelete1Response(params: CustomerPartIdentifierService.CustomerPartIdentifierDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/customer_part_identifiers/${encodeURIComponent(String(params.identifierID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete customer_part_identifier
   *
   * Deletes a customer part identifier
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierDelete1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `identifierID`: Customer Part Identifier ID
   */
  customerPartIdentifierDelete1(params: CustomerPartIdentifierService.CustomerPartIdentifierDelete1Params): __Observable<null> {
    return this.customerPartIdentifierDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list customer_part_identifier
   *
   * Lists customer part identifiers for a given supplier part/product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierListParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerPartIdentifierListResponse(params: CustomerPartIdentifierService.CustomerPartIdentifierListParams): __Observable<__StrictHttpResponse<LasgroCustomer_part_identifierCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}/customer_part_identifiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustomer_part_identifierCollection>;
      })
    );
  }
  /**
   * list customer_part_identifier
   *
   * Lists customer part identifiers for a given supplier part/product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierListParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerPartIdentifierList(params: CustomerPartIdentifierService.CustomerPartIdentifierListParams): __Observable<LasgroCustomer_part_identifierCollection> {
    return this.customerPartIdentifierListResponse(params).pipe(
      __map(_r => _r.body as LasgroCustomer_part_identifierCollection)
    );
  }

  /**
   * add customer_part_identifier
   *
   * Adds a customer part identifier to a given organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierAddParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  customerPartIdentifierAddResponse(params: CustomerPartIdentifierService.CustomerPartIdentifierAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}/customer_part_identifiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add customer_part_identifier
   *
   * Adds a customer part identifier to a given organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerPartIdentifierService.CustomerPartIdentifierAddParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  customerPartIdentifierAdd(params: CustomerPartIdentifierService.CustomerPartIdentifierAddParams): __Observable<null> {
    return this.customerPartIdentifierAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CustomerPartIdentifierService {

  /**
   * Parameters for customerPartIdentifierShow
   */
  export interface CustomerPartIdentifierShowParams {

    /**
     * Customer Part Identifier ID
     */
    identifierID: string;
    productID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for customerPartIdentifierUpdate
   */
  export interface CustomerPartIdentifierUpdateParams {
    payload: CustomerPartIdentifierInputPayload;

    /**
     * Customer Part Identifier ID
     */
    identifierID: string;
    productID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for customerPartIdentifierDelete
   */
  export interface CustomerPartIdentifierDeleteParams {

    /**
     * Customer Part Identifier ID
     */
    identifierID: string;
    productID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for customerPartIdentifierList1
   */
  export interface CustomerPartIdentifierList1Params {
    productID: string;
    organizationID: string;
  }

  /**
   * Parameters for customerPartIdentifierAdd1
   */
  export interface CustomerPartIdentifierAdd1Params {
    productID: string;
    payload: AddCustomerPartIdentifierPayload;
    organizationID: string;
  }

  /**
   * Parameters for customerPartIdentifierShow1
   */
  export interface CustomerPartIdentifierShow1Params {
    productID: string;
    organizationID: string;

    /**
     * Customer Part Identifier ID
     */
    identifierID: string;
  }

  /**
   * Parameters for customerPartIdentifierUpdate1
   */
  export interface CustomerPartIdentifierUpdate1Params {
    productID: string;
    payload: CustomerPartIdentifierInputPayload;
    organizationID: string;

    /**
     * Customer Part Identifier ID
     */
    identifierID: string;
  }

  /**
   * Parameters for customerPartIdentifierDelete1
   */
  export interface CustomerPartIdentifierDelete1Params {
    productID: string;
    organizationID: string;

    /**
     * Customer Part Identifier ID
     */
    identifierID: string;
  }

  /**
   * Parameters for customerPartIdentifierList
   */
  export interface CustomerPartIdentifierListParams {
    productID: string;
    organizationID?: string;
  }

  /**
   * Parameters for customerPartIdentifierAdd
   */
  export interface CustomerPartIdentifierAddParams {
    productID: string;
    payload: AddCustomerPartIdentifierPayload;
    organizationID?: string;
  }
}

export { CustomerPartIdentifierService }
