/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroMail } from '../models/lasgro-mail';
import { MailUpdatePayload } from '../models/mail-update-payload';
import { MailAddPayload } from '../models/mail-add-payload';
import { LasgroMailCollection } from '../models/lasgro-mail-collection';
@Injectable({
  providedIn: 'root',
})
class MailsService extends __BaseService {
  static readonly mailsUpdatePath = '/mails';
  static readonly mailsAddPath = '/mails';
  static readonly mailsListByOrganizationPath = '/mails/list_by_organization/{organizationID}';
  static readonly mailsListByTypePath = '/mails/list_by_type/{type}';
  static readonly mailsShowPath = '/mails/show/{id}';
  static readonly mailsShowByOrganizationAndTypePath = '/mails/show_by_organization_and_type/{organizationID}/{type}';
  static readonly mailsDeletePath = '/mails/{MailsID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update mails
   *
   * Updates a insurance Email
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  mailsUpdateResponse(payload: MailUpdatePayload): __Observable<__StrictHttpResponse<LasgroMail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMail>;
      })
    );
  }
  /**
   * update mails
   *
   * Updates a insurance Email
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  mailsUpdate(payload: MailUpdatePayload): __Observable<LasgroMail> {
    return this.mailsUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroMail)
    );
  }

  /**
   * add mails
   *
   * Add a new insurance Email
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  mailsAddResponse(payload: MailAddPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add mails
   *
   * Add a new insurance Email
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  mailsAdd(payload: MailAddPayload): __Observable<null> {
    return this.mailsAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * listByOrganization mails
   *
   * Lists insurance emails
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  mailsListByOrganizationResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroMailCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mails/list_by_organization/${encodeURIComponent(String(organizationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMailCollection>;
      })
    );
  }
  /**
   * listByOrganization mails
   *
   * Lists insurance emails
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  mailsListByOrganization(organizationID: string): __Observable<LasgroMailCollection> {
    return this.mailsListByOrganizationResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroMailCollection)
    );
  }

  /**
   * listByType mails
   *
   * Lists insurance emails
   *
   * Required security scopes:
   *   * `api:access`
   * @param type Type
   * @return OK
   */
  mailsListByTypeResponse(type: string): __Observable<__StrictHttpResponse<LasgroMailCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mails/list_by_type/${encodeURIComponent(String(type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMailCollection>;
      })
    );
  }
  /**
   * listByType mails
   *
   * Lists insurance emails
   *
   * Required security scopes:
   *   * `api:access`
   * @param type Type
   * @return OK
   */
  mailsListByType(type: string): __Observable<LasgroMailCollection> {
    return this.mailsListByTypeResponse(type).pipe(
      __map(_r => _r.body as LasgroMailCollection)
    );
  }

  /**
   * show mails
   *
   * Get insurance email by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param id ID
   * @return OK
   */
  mailsShowResponse(id: string): __Observable<__StrictHttpResponse<LasgroMail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mails/show/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMail>;
      })
    );
  }
  /**
   * show mails
   *
   * Get insurance email by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param id ID
   * @return OK
   */
  mailsShow(id: string): __Observable<LasgroMail> {
    return this.mailsShowResponse(id).pipe(
      __map(_r => _r.body as LasgroMail)
    );
  }

  /**
   * showByOrganizationAndType mails
   *
   * Get insurance email by organizationID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MailsService.MailsShowByOrganizationAndTypeParams` containing the following parameters:
   *
   * - `type`: Type
   *
   * - `organizationID`: Organization ID
   *
   * @return OK
   */
  mailsShowByOrganizationAndTypeResponse(params: MailsService.MailsShowByOrganizationAndTypeParams): __Observable<__StrictHttpResponse<LasgroMail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mails/show_by_organization_and_type/${encodeURIComponent(String(params.organizationID))}/${encodeURIComponent(String(params.type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMail>;
      })
    );
  }
  /**
   * showByOrganizationAndType mails
   *
   * Get insurance email by organizationID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MailsService.MailsShowByOrganizationAndTypeParams` containing the following parameters:
   *
   * - `type`: Type
   *
   * - `organizationID`: Organization ID
   *
   * @return OK
   */
  mailsShowByOrganizationAndType(params: MailsService.MailsShowByOrganizationAndTypeParams): __Observable<LasgroMail> {
    return this.mailsShowByOrganizationAndTypeResponse(params).pipe(
      __map(_r => _r.body as LasgroMail)
    );
  }

  /**
   * delete mails
   *
   * Delete insurance Email by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param MailsID Insurance Email ID
   */
  mailsDeleteResponse(MailsID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mails/${encodeURIComponent(String(MailsID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete mails
   *
   * Delete insurance Email by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param MailsID Insurance Email ID
   */
  mailsDelete(MailsID: string): __Observable<null> {
    return this.mailsDeleteResponse(MailsID).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MailsService {

  /**
   * Parameters for mailsShowByOrganizationAndType
   */
  export interface MailsShowByOrganizationAndTypeParams {

    /**
     * Type
     */
    type: string;

    /**
     * Organization ID
     */
    organizationID: string;
  }
}

export { MailsService }
