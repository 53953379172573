<form (ngSubmit)="submit()" [formGroup]="modalForm">
<div class="modal-header">
  <h5>Edit Product</h5>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <p>
      Please choose how to proceed.
    </p>
  </div>

  <div class="mt-2">
    <mat-slide-toggle color="primary" formControlName="makeNewProduct" [disabled]="pastCount > 0" [(ngModel)]="makeNewProduct">{{ makeNewProduct ? 'New Product number' : 'Keep old Product number' }}</mat-slide-toggle>
  </div>
  <div>
    <mat-slide-toggle color="primary" formControlName="reissuePos" [disabled]="openCount === 0 || (openCount > 0 && makeNewProduct === false)" [(ngModel)]="reissuePos">{{ reissuePos ? 'Re-issue all approved Purchase Orders' : 'Do not re-issue Purchase Orders' }}</mat-slide-toggle>
  </div>

  <div [style.min-height.px]=60 class="mt-2">
    <p *ngIf="pendingCount > 0 || pastCount > 0 || openCount === 0 || makeNewProduct">
      <small class="d-block">
        <strong>Notes:</strong>
      </small>
      <small class="d-block" *ngIf="pendingCount > 0">
        <strong>*</strong> This action will also affect {{ pendingCount }} Purchase Order{{ pendingCount > 1 ? 's' : '' }} waiting for approval.
      </small>
      <small class="d-block" *ngIf="pastCount > 0">
        <strong>*</strong> A new Product number is required as this product is related to {{ pastCount }} Purchase Order{{ pastCount > 1 ? 's' : '' }} that {{ pastCount > 1 ? 'are' : 'is' }} closed, archived, or overdue.
      </small>
      <small class="d-block" *ngIf="openCount === 0">
        <strong>*</strong> Purchase Order re-issuance is not necessary as there are no open Purchase Orders that include this product.
      </small>
      <small class="d-block" *ngIf="makeNewProduct">
        <strong>*</strong> Creating a new product number will apply your changes to a new product record, without affecting the old one.
      </small>
    </p>
  </div>

</div>
<div class="modal-footer po-modal d-flex justify-content-center mt-2">
  <button
    class="mr-2"
    mat-raised-button
    mat-grey
    color="grey"
    (click)="closeModal()"
  >
    Cancel
  </button>
  <button
    type="submit"
    mat-raised-button
    color="primary"
    [disabled]="modalForm.invalid"
  >
    Submit
  </button>
</div>
