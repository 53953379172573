import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Checklist, User } from 'app/models';
import { AddFilesModalComponent } from '@shared/modals';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'ngx-add-task-form',
  templateUrl: './add-task-form.component.html',
  styleUrls: ['./add-task-form.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgSelectModule,
    MatDatepickerModule,
    MatButtonModule
  ]
})
export class AddTaskFormComponent implements OnInit, OnDestroy {
  @Input() companyUsers: User[];
  @Input() checklist: Checklist;
  @Input() isSavedChecklist: boolean = false;
  files = [];
  @Output() saveTask: EventEmitter<{ task: any, files: File[] }> = new EventEmitter();
  taskForm: UntypedFormGroup;
  @ViewChild(FormGroupDirective, { static: true }) taskFormElement: FormGroupDirective;

  submitted: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private matDialog: MatDialog) { }

  ngOnInit() {
    if (this.isSavedChecklist) {
      this.taskForm = this.formBuilder.group({
        name: [null, [Validators.required]],
        responsible_party: [null, [Validators.required]],
        due_date_unparsed: [1, [Validators.required]],
      });
    } else {
      this.taskForm = this.formBuilder.group({
        name: [null, [Validators.required]],
        responsible_party: [null, [Validators.required]],
        due_date: [null, [Validators.required]],
      });
    }
  }

  save() {
    if (this.taskForm.invalid) {
      if (!this.taskForm.dirty && !this.submitted) {
        this.reset();
      }
      return;
    }
    this.submitted = true;
    let task = this.taskForm.getRawValue();
    if (task.due_date_unparsed) {
      task.due_date_unparsed = task.due_date_unparsed.toString() + ' days after order_date';
    }
    this.saveTask.emit({ task, files: this.files });
    this.reset();
    this.submitted = false;
  }

  reset() {
    this.taskForm.reset();
    this.taskFormElement.reset();
    this.taskFormElement.resetForm();
    this.submitted = false;
    this.files = [];
  }

  openAddFilesModal() {
    const modalRef = this.matDialog.open(AddFilesModalComponent, {
      width: '600px',
      height: '400px',
    });

    if (this.files.length > 0) {
      modalRef.componentInstance.files = this.files;
    }

    modalRef.afterClosed()
      .subscribe((files) => {
        this.files = files;
      });
  }

  blockCommaKey($event) {
    if ($event.key === ',' || $event.key === '.' || $event.key === '-') {
      $event.preventDefault();
    }
  }

  ngOnDestroy() {
  }
}
