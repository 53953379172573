/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroChecklist } from '../models/lasgro-checklist';
import { ChecklistUpdateInputPayload } from '../models/checklist-update-input-payload';
import { LasgroChecklistCollection } from '../models/lasgro-checklist-collection';
import { ChecklistAddInputPayload } from '../models/checklist-add-input-payload';
import { ChecklistAddFromSavedChecklistInputPayload } from '../models/checklist-add-from-saved-checklist-input-payload';
@Injectable({
  providedIn: 'root',
})
class ChecklistService extends __BaseService {
  static readonly checklistDeletePath = '/checklist/{checklistID}';
  static readonly checklistShowPath = '/checklists/{checklistID}';
  static readonly checklistUpdatePath = '/checklists/{checklistID}';
  static readonly checklistList1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists';
  static readonly checklistAdd1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists';
  static readonly checklistShow1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}';
  static readonly checklistAddFromSavedChecklist1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}';
  static readonly checklistDelete1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}';
  static readonly checklistUpdate1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}';
  static readonly checklistListPath = '/purchase_orders/{purchaseOrderID}/checklists';
  static readonly checklistAddPath = '/purchase_orders/{purchaseOrderID}/checklists';
  static readonly checklistAddFromSavedChecklistPath = '/purchase_orders/{purchaseOrderID}/checklists/{checklistID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * delete checklist
   *
   * Deletes checklist with all tasks. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistDeleteParams` containing the following parameters:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  checklistDeleteResponse(params: ChecklistService.ChecklistDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/checklist/${encodeURIComponent(String(params.checklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete checklist
   *
   * Deletes checklist with all tasks. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistDeleteParams` containing the following parameters:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  checklistDelete(params: ChecklistService.ChecklistDeleteParams): __Observable<null> {
    return this.checklistDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show checklist
   *
   * Checklist by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistShowParams` containing the following parameters:
   *
   * - `checklistID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  checklistShowResponse(params: ChecklistService.ChecklistShowParams): __Observable<__StrictHttpResponse<LasgroChecklist>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checklists/${encodeURIComponent(String(params.checklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklist>;
      })
    );
  }
  /**
   * show checklist
   *
   * Checklist by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistShowParams` containing the following parameters:
   *
   * - `checklistID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  checklistShow(params: ChecklistService.ChecklistShowParams): __Observable<LasgroChecklist> {
    return this.checklistShowResponse(params).pipe(
      __map(_r => _r.body as LasgroChecklist)
    );
  }

  /**
   * update checklist
   *
   * Updates checklist. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistUpdateParams` containing the following parameters:
   *
   * - `payload`: Input payload of checklist for update
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  checklistUpdateResponse(params: ChecklistService.ChecklistUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/checklists/${encodeURIComponent(String(params.checklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update checklist
   *
   * Updates checklist. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistUpdateParams` containing the following parameters:
   *
   * - `payload`: Input payload of checklist for update
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  checklistUpdate(params: ChecklistService.ChecklistUpdateParams): __Observable<null> {
    return this.checklistUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list checklist
   *
   * List all checklists for purchase order.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistList1Response(params: ChecklistService.ChecklistList1Params): __Observable<__StrictHttpResponse<LasgroChecklistCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklistCollection>;
      })
    );
  }
  /**
   * list checklist
   *
   * List all checklists for purchase order.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistList1(params: ChecklistService.ChecklistList1Params): __Observable<LasgroChecklistCollection> {
    return this.checklistList1Response(params).pipe(
      __map(_r => _r.body as LasgroChecklistCollection)
    );
  }

  /**
   * add checklist
   *
   * Create checklist for purchase order. Only customers can create, modify or delete checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistAdd1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist
   *
   * - `organizationID`:
   *
   * - `sourcing_project`:
   */
  checklistAdd1Response(params: ChecklistService.ChecklistAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add checklist
   *
   * Create checklist for purchase order. Only customers can create, modify or delete checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistAdd1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist
   *
   * - `organizationID`:
   *
   * - `sourcing_project`:
   */
  checklistAdd1(params: ChecklistService.ChecklistAdd1Params): __Observable<null> {
    return this.checklistAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show checklist
   *
   * Checklist by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistShow1Response(params: ChecklistService.ChecklistShow1Params): __Observable<__StrictHttpResponse<LasgroChecklist>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklist>;
      })
    );
  }
  /**
   * show checklist
   *
   * Checklist by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistShow1(params: ChecklistService.ChecklistShow1Params): __Observable<LasgroChecklist> {
    return this.checklistShow1Response(params).pipe(
      __map(_r => _r.body as LasgroChecklist)
    );
  }

  /**
   * add_from_saved_checklist checklist
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistAddFromSavedChecklist1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload to add tasks from a saved checklist to a checklist
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistAddFromSavedChecklist1Response(params: ChecklistService.ChecklistAddFromSavedChecklist1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_from_saved_checklist checklist
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistAddFromSavedChecklist1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload to add tasks from a saved checklist to a checklist
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistAddFromSavedChecklist1(params: ChecklistService.ChecklistAddFromSavedChecklist1Params): __Observable<null> {
    return this.checklistAddFromSavedChecklist1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete checklist
   *
   * Deletes checklist with all tasks. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistDelete1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistDelete1Response(params: ChecklistService.ChecklistDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete checklist
   *
   * Deletes checklist with all tasks. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistDelete1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistDelete1(params: ChecklistService.ChecklistDelete1Params): __Observable<null> {
    return this.checklistDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update checklist
   *
   * Updates checklist. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist for update
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistUpdate1Response(params: ChecklistService.ChecklistUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update checklist
   *
   * Updates checklist. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist for update
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistUpdate1(params: ChecklistService.ChecklistUpdate1Params): __Observable<null> {
    return this.checklistUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list checklist
   *
   * List all checklists for purchase order.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `sourcing_project`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  checklistListResponse(params: ChecklistService.ChecklistListParams): __Observable<__StrictHttpResponse<LasgroChecklistCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroChecklistCollection>;
      })
    );
  }
  /**
   * list checklist
   *
   * List all checklists for purchase order.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `sourcing_project`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  checklistList(params: ChecklistService.ChecklistListParams): __Observable<LasgroChecklistCollection> {
    return this.checklistListResponse(params).pipe(
      __map(_r => _r.body as LasgroChecklistCollection)
    );
  }

  /**
   * add checklist
   *
   * Create checklist for purchase order. Only customers can create, modify or delete checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistAddParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist
   *
   * - `sourcing_project`:
   *
   * - `organizationID`:
   */
  checklistAddResponse(params: ChecklistService.ChecklistAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add checklist
   *
   * Create checklist for purchase order. Only customers can create, modify or delete checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistAddParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload of checklist
   *
   * - `sourcing_project`:
   *
   * - `organizationID`:
   */
  checklistAdd(params: ChecklistService.ChecklistAddParams): __Observable<null> {
    return this.checklistAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add_from_saved_checklist checklist
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistAddFromSavedChecklistParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload to add tasks from a saved checklist to a checklist
   *
   * - `checklistID`:
   *
   * - `organizationID`:
   */
  checklistAddFromSavedChecklistResponse(params: ChecklistService.ChecklistAddFromSavedChecklistParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_from_saved_checklist checklist
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistService.ChecklistAddFromSavedChecklistParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload to add tasks from a saved checklist to a checklist
   *
   * - `checklistID`:
   *
   * - `organizationID`:
   */
  checklistAddFromSavedChecklist(params: ChecklistService.ChecklistAddFromSavedChecklistParams): __Observable<null> {
    return this.checklistAddFromSavedChecklistResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChecklistService {

  /**
   * Parameters for checklistDelete
   */
  export interface ChecklistDeleteParams {
    checklistID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for checklistShow
   */
  export interface ChecklistShowParams {
    checklistID: string;

    /**
     * What view type to show for the checklist
     */
    viewType?: 'default' | 'full';
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for checklistUpdate
   */
  export interface ChecklistUpdateParams {

    /**
     * Input payload of checklist for update
     */
    payload: ChecklistUpdateInputPayload;
    checklistID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for checklistList1
   */
  export interface ChecklistList1Params {
    purchaseOrderID: string;
    organizationID: string;

    /**
     * What view type to show for the checklist
     */
    viewType?: 'default' | 'full';
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistAdd1
   */
  export interface ChecklistAdd1Params {
    purchaseOrderID: string;

    /**
     * Input payload of checklist
     */
    payload: ChecklistAddInputPayload;
    organizationID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistShow1
   */
  export interface ChecklistShow1Params {
    purchaseOrderID: string;
    organizationID: string;
    checklistID: string;

    /**
     * What view type to show for the checklist
     */
    viewType?: 'default' | 'full';
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistAddFromSavedChecklist1
   */
  export interface ChecklistAddFromSavedChecklist1Params {
    purchaseOrderID: string;

    /**
     * Input payload to add tasks from a saved checklist to a checklist
     */
    payload: ChecklistAddFromSavedChecklistInputPayload;
    organizationID: string;
    checklistID: string;
  }

  /**
   * Parameters for checklistDelete1
   */
  export interface ChecklistDelete1Params {
    purchaseOrderID: string;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistUpdate1
   */
  export interface ChecklistUpdate1Params {
    purchaseOrderID: string;

    /**
     * Input payload of checklist for update
     */
    payload: ChecklistUpdateInputPayload;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistList
   */
  export interface ChecklistListParams {
    purchaseOrderID: string;

    /**
     * What view type to show for the checklist
     */
    viewType?: 'default' | 'full';
    sourcingProject?: boolean;
    organizationID?: string;
  }

  /**
   * Parameters for checklistAdd
   */
  export interface ChecklistAddParams {
    purchaseOrderID: string;

    /**
     * Input payload of checklist
     */
    payload: ChecklistAddInputPayload;
    sourcingProject?: boolean;
    organizationID?: string;
  }

  /**
   * Parameters for checklistAddFromSavedChecklist
   */
  export interface ChecklistAddFromSavedChecklistParams {
    purchaseOrderID: string;

    /**
     * Input payload to add tasks from a saved checklist to a checklist
     */
    payload: ChecklistAddFromSavedChecklistInputPayload;
    checklistID: string;
    organizationID?: string;
  }
}

export { ChecklistService }
