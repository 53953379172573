<div class="filter-buttons">
  <ngx-filter-menu-btn #customerPartMenu key="customerPart" [title]="filters.customerPart || 'Customer Part Identifier'" [showClearBtn]="filters.customerPart !== ''" (onClear)="onClearCustomerPartClick()" (onMenuClose)="onSaveCustomerPartClick()" *ngIf="showCustomerPartBtn">
    <div content class="content">
      <div class="hint">
        <span>Write a Customer Part Identifier if you want to look for a specific one.</span>
      </div>
      <form class="customer-part-form">
        <mat-form-field>
          <input type="text" placeholder="Customer Part ID" aria-label="Customer Part ID" matInput [formControl]="customerPartControl" [matAutocomplete]="customerPartAuto">
          <mat-autocomplete #customerPartAuto="matAutocomplete">
            <mat-option *ngFor="let part of filteredCustomerParts | async" [value]="part" (click)="$event.stopPropagation()">
              {{ part }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <div footer class="btns-container">
      <button class="app-clear-btn" (click)="onClearCustomerPartClick()">Clear</button>
      <div class="ml-auto">
        <button class="app-save-btn" (click)="onSaveCustomerPartClick()" id="save_customer_part_filter_btn">Save</button>
      </div>
    </div>
  </ngx-filter-menu-btn>
  <ngx-filter-menu-btn #nameMenu key="name" [title]="filters.name || 'Name'" [showClearBtn]="filters.name !== ''" (onClear)="onClearNameClick()" (onMenuClose)="onSaveNameClick()">
    <div content class="content">
      <div class="hint">
        <span>Write a Product Name if you know what you are looking for.</span>
      </div>
      <form class="name-form">
        <mat-form-field>
          <input type="text" placeholder="Name" aria-label="Number" matInput [formControl]="nameControl" [matAutocomplete]="nameAuto">
          <mat-autocomplete #nameAuto="matAutocomplete">
            <mat-option *ngFor="let name of filteredNames | async" [value]="name" (click)="$event.stopPropagation()">
              {{ name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <div footer class="btns-container">
      <button class="app-clear-btn" (click)="onClearNameClick()">Clear</button>
      <div class="ml-auto">
        <button class="app-save-btn" (click)="onSaveNameClick()" id="save_name_filter_btn">Save</button>
      </div>
    </div>
  </ngx-filter-menu-btn>
  <ngx-filter-menu-btn #descMenu key="description" [title]="filters.description || 'Description'" [showClearBtn]="filters.description !== ''" (onClear)="onClearDescClick()" (onMenuClose)="onSaveDescClick()">
    <div content class="content">
      <div class="hint">
        <span>Write a Product Description that matches your criteria.</span>
      </div>
      <form class="desc-form">
        <mat-form-field>
          <input type="text" placeholder="Description" aria-label="Description" matInput [formControl]="descControl" [matAutocomplete]="descAuto">
          <mat-autocomplete #descAuto="matAutocomplete">
            <mat-option *ngFor="let desc of filteredDesc | async" [value]="desc" (click)="$event.stopPropagation()">
              {{ desc }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <div footer class="btns-container">
      <button class="app-clear-btn" (click)="onClearDescClick()">Clear</button>
      <div class="ml-auto">
        <button class="app-save-btn" (click)="onSaveDescClick()" id="save_desc_filter_btn">Save</button>
      </div>
    </div>
  </ngx-filter-menu-btn>
  <ngx-filter-menu-btn #barcodeMenu key="barcode" [title]="filters.barcode || 'Barcode'" [showClearBtn]="filters.barcode !== ''" (onClear)="onClearBarcodeClick()" (onMenuClose)="onSaveBarcodeClick()">
    <div content class="content">
      <div class="hint">
        <span>Write a Barcode number if you want to filter for a specific one.</span>
      </div>
      <form class="barcode-form">
        <mat-form-field>
          <input type="text" placeholder="Barcode" aria-label="Barcode" matInput [formControl]="barcodeControl" [matAutocomplete]="barcodeAuto">
          <mat-autocomplete #barcodeAuto="matAutocomplete">
            <mat-option *ngFor="let barcode of filteredBarcode | async" [value]="barcode" (click)="$event.stopPropagation()">
              {{ barcode }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <div footer class="btns-container">
      <button class="app-clear-btn" (click)="onClearBarcodeClick()">Clear</button>
      <div class="ml-auto">
        <button class="app-save-btn" (click)="onSaveBarcodeClick()" id="save_barcode_filter_btn">Save</button>
      </div>
    </div>
  </ngx-filter-menu-btn>
</div>
