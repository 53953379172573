<div class="modal-header po-modal">
  Task Detail
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-8">

      <mat-form-field>
        <textarea matInput (change)="updateTask()" rows="10" [disabled]="!isCustomer || disabled" [(ngModel)]="taskData.name"></textarea>
      </mat-form-field>

      <div class="mb-2">
        Attachments ({{ taskFiles.length || '0'}})
      </div>

      <ul>
        <li class="row files-block mb-2" *ngFor="let file of taskFiles; let $index = index">
          <div class="col-md-1">
            <div class="icon-file">
              <i class="fa fa-paperclip"></i>
            </div>
          </div>

          <div class="col-md-10">
            <div class="name-fl text-center">
              <a (click)="downloadFile(file)">
                {{ file.file_name }}
              </a>
            </div>
            <div class="text-center">
              <small>
                <span>{{ file.file_size | fileSize }}</span>
                <span>{{ file.created_at | date:'MM/dd/yyyy':'utc' }}</span>
                <span>{{ file.created_by_name }}</span>
              </small>
            </div>
          </div>
          <div class="col-md-1">
            <a
              *ngIf="(isCustomer || (user$ | async).id === file.created_by) && allowEdit"
              (click)="removeFile(file)"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
        </li>
      </ul>

    </div>
    <div class="col-4">

      <ng-select
        [labelForId]="'task_responsible_party_select-'+taskData.checklist_task_id"
        [items]="availableUsers"
        [(ngModel)]="taskData.responsible_party"
        [hideSelected]="true"
        bindValue="id"
        bindLabel="fullName"
        placeholder="Select People *"
        appendTo="body"
        required
        [clearable]="false"
        #ngSelect
        typeToSearchText="Please enter 2 or more characters"
        (change)="selectContact($event)"
        [disabled]="disabled"
      >
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">
            {{ item.fullName }}
          </div>
        </ng-template>
      </ng-select>

      <mat-form-field>
        <input
          matInput
          [matDatepicker]="dueDatePicker"
          placeholder="Due date"
          [(ngModel)]="taskData.due_date"
          (click)="dueDatePicker.open()"
          (dateChange)="updateTask()"
          [disabled]="disabled"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dueDatePicker></mat-datepicker>
      </mat-form-field>


      <label for="fileToUpload" class="clickable w-100">
        <button type="button" class="btn btn-default btn-block" [disabled]="disabled" (click)="taskFilesInput.click()">
          <i class="fa fa-paperclip"></i>
          Add files
        </button>
        <input type="file" #taskFilesInput id="fileToUpload" class="d-none" (change)="addFileSelectedTask($event)"/>
      </label>

      <button class="btn btn-danger btn-block" *ngIf="isCustomer" [disabled]="disabled" (click)="deleteTask()">
        <i class="fa fa-times"></i>
        Delete Task
      </button>

    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-default" (click)="closeModal()">
    Close
  </button>
</div>
