import { environment } from '@env/environment';

export default {
  // API: window.location.hostname === 'localhost' ? 'http://localhost:8080' : 'http://localhost:8080',
  API: environment.apiUrl,
  WEB_SOCKET_API: environment.socketsApiUrl,
  WEB_SOCKET_SERVER: environment.socketsUrl,
  // API: window['backend'],
  CDN: 'https://s3-us-west-2.amazonaws.com/asddasd',
  version: '1.0.0',
  FILE_LIMIT: 10000000,
  URL_REGEX: 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
};
