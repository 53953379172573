import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@app/services';
import { Store } from '@ngrx/store';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, throwError as _throw } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import * as UserActions from '../store/actions/user.actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  @LocalStorage() token: string;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  @LocalStorage() refresh: string;
  private refreshTokenInProgress = false;
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshBody: any = {};

  constructor(private inj: Injector,
              private localStorageService: LocalStorageService,
              private store: Store<{ user: any }>, protected router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthenticationToken(request, this.token))
      .pipe(catchError(error => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (request.url.includes('refresh') || request.url.includes('token')) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page
          if (request.url.includes('refresh')) {
            //   window.location.href = '#/auth/logout';
            this.router.navigate(['auth', 'logout']);
          }

          return _throw(error);
        }

        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          return _throw(error);
        }

        if (!this.refresh || !this.token) {
          this.refreshTokenInProgress = false;
          this.localStorageService.clear();
          // return (window.location.href = '#/auth/logout');
          this.router.navigate(['auth', 'logout']);
          return;
        }

        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(token => next.handle(this.addAuthenticationToken(request, token))),
          );
        } else {
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until
          // the new token has been retrieved
          this.refreshTokenSubject.next(null);

          const auth = this.inj.get(AccountService);

          this.refreshBody.refresh_token = this.refresh.replace(/^"(.*)"$/, '$1');
          this.refreshBody.authorization_token = this.token.replace(/^"(.*)"$/, '$1');
          return auth
            .refreshToken(this.refreshBody)
            .pipe(
              switchMap(result => {
                const refresh: string = String(result.headers.get('Refresh'));
                const token: string = String(result.headers.getAll('Authorization'));
                this.token = token;
                this.refresh = refresh;

                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(token);

                this.store.dispatch(new UserActions.Fetch());
                return next.handle(this.addAuthenticationToken(request, token));
              }),
            ).pipe(
              catchError((error2: any) => {
                this.refreshTokenInProgress = false;
                this.localStorageService.clear();
                // window.location.href = '#/auth/logout';
                this.router.navigate(['auth', 'logout']);
                return _throw(error2);
              }));
        }
      }));
  }

  addAuthenticationToken(request: HttpRequest<any>, token: string) {
    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!token) {
      return request;
    }

    // We clone the request, because the original request is immutable
    const authReq = request.clone({
      headers: request.headers.set('Authorization', token),
    });
    return authReq;
  }
}
