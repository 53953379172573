import { Component, Input, OnInit } from '@angular/core';
import { ImportPos } from '@models/import-pos.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-po-imported-detail',
  templateUrl: './po-imported-detail.component.html',
  styleUrls: ['./po-imported-detail.component.scss'],
})
export class PoImportedDetailComponent implements OnInit {
  @Input() purchaseOrderImport: ImportPos;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

}
