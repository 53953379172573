import { Batch } from './batch.model';

export class Step {
    created_at: any;
    description: string;
    href: string;
    id: string;
    name: string;
    qualifier: string;
    product_id: string;
    route_step_category_id: number;
    updated_at: any;
    barcode: string;
    quantity: number;


    sequence_number: number;

    // this two properties will only be used on the line item details component
    batches: Batch[];
    completed: number;

    // used in the frontend only
    order?: number;
}
