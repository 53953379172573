import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '@services/company.service';
import { PdfVisualizerComponent } from '@shared/modals/pdf-visualizer/pdf-visualizer.component';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'ngx-modal-po-files',
  templateUrl: './modal-po-files.component.html',
  styleUrls: ['./modal-po-files.component.scss'],
})
export class ModalPOFilesComponent {

  @Input() public files: any;
  @LocalStorage() organizationId: string;
  @Input() public purchaseOrderId: any;

  constructor(private activeModal: NgbActiveModal, private $modal: NgbModal, private $company: CompanyService) {}

  closeModal() {
    this.activeModal.close('close');
  }

  viewPdf(file: any) {
    const modal = this.$modal.open(PdfVisualizerComponent, {
      size: 'lg',
      windowClass: 'pdf-visualizer',
    });
    modal.componentInstance.title = file.file_name;
    modal.componentInstance.loading = true;

    this.$company.getPoFileById(this.organizationId, this.purchaseOrderId, file.file_id).subscribe(
      res => {
        modal.componentInstance.pdfViewer.pdfSrc = res.body;
        modal.componentInstance.pdfViewer.refresh();
        modal.componentInstance.loading = false;
      },
      error => {
        console.error(error);
      },
    );
  }

  downloadFile(file: any) {
    this.$company.getPoFileById(this.organizationId, this.purchaseOrderId, file.file_id, true).subscribe(res => {
        const blob = new Blob([res.body], { type: 'application/octet-stream' });
        saveAs(blob, file.file_name);
      },
      error => {
        console.error(error);
      },
    );
  }

  getFileDate(fileDate) {
    return moment(fileDate).format('ll');
  }
}


