import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { CustomerIdentifier, MeasurementUnit, Product } from '@models/index';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService, SharedService, Toaster } from '@services/index';
import { ProductService } from '@services/product.service';
import { LocalStorage } from 'ngx-webstorage';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'ngx-create-product',
  styleUrls: ['../../../modules/organization/my-company/company.style.scss', './create-product-modal.component.scss'],
  templateUrl: './create-product-modal.component.html',
})
export class CreateProductModalComponent implements OnInit {
  readonly FILE_LIMIT: number = 10000000;
  @Input() public role: string;
  @LocalStorage() organizationId: string;
  @LocalStorage() companyType: string;

  @Input() copiedProduct: any;
  @Input() reissuePos: boolean = false;

  objectKeys = Object.keys;
  fileList: FileList;

  customers = []; // All customers in the app
  allowedCustomers = [];

  productForm: UntypedFormGroup;
  customerPartIdentifierForm: UntypedFormGroup;

  selectedLengthUnit: MeasurementUnit;
  selectedWeightUnit: MeasurementUnit;

  lengthUnits: MeasurementUnit[];
  weightUnits: MeasurementUnit[];
  currencies = this.sharedService.currencies();

  constructor(private $modal: NgbActiveModal,
              private $company: CompanyService,
              private formBuilder: UntypedFormBuilder,
              private productService: ProductService,
              private sharedService: SharedService,
              private $toaster: Toaster) {
  }

  async ngOnInit() {
    this.productService.getMeasurementUnits('LENGTH').subscribe(measureUNits => this.lengthUnits = measureUNits);
    this.productService.getMeasurementUnits('WEIGHT').subscribe(measureUNits => this.weightUnits = measureUNits);

    let forbiddenNameValidator = this.forbiddenNameValidator.bind(this);
    this.productForm = this.formBuilder.group({
      name: ['', [Validators.required, forbiddenNameValidator()]],
      description: [],
      barcode: [],
      case_barcode: [null, []],
      master_pack_barcode: [],
      customer_part_identifier: [],
      allowed_customers: this.formBuilder.array([]),
      master_pack_length: [],
      master_pack_width: [],
      master_pack_height: [],
      cases_per_master_pack: [],
      unit_length: [null, [Validators.min(0)]],
      unit_height: [null, [Validators.min(0)]],
      unit_width: [null, [Validators.min(0)]],
      unit_weight: [null, [Validators.min(0)]],
      case_length: [null, [Validators.min(0)]],
      case_height: [null, [Validators.min(0)]],
      case_width: [null, [Validators.min(0)]],
      case_weight: [null, [Validators.min(0)]],
      units_per_case: [null, [Validators.min(0)]],
      currency: ['USD', [Validators.required]],
      unit_price: [null, [Validators.min(0)]],
      case_price: [null, [Validators.min(0)]],
      master_pack_price: [null, [Validators.min(0)]],
      unit_length_mu_id: [],
      unit_weight_mu_id: [],
    });

    if (this.copiedProduct && this.copiedProduct.id) {
      this.productForm.patchValue(this.copiedProduct);
      this.productForm.controls.name.markAsTouched();
    } else {
      this.copiedProduct = undefined;
    }

    this.customerPartIdentifierForm = this.formBuilder.group({
      customer_part_identifier: [null, [Validators.required]],
      customer_id: [null, [Validators.required]],
    });

    if (this.role !== 'po') {
      this.customers = await this.$company.customers(this.organizationId).toPromise();
      this.allowedCustomers = this.customers.slice();
    }

    if (this.role === undefined) {
      this.role = this.companyType;
    }

  }

  forbiddenNameValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return (this.copiedProduct && control.value === this.copiedProduct.name) ? {forbiddenName: {value: control.value}} : null;
    };
  }

  lengthUnitChange($event) {
    this.selectedLengthUnit = $event;
    // this.updateProduct();
  }

  weightUnitChange($event) {
    this.selectedWeightUnit = $event;
    // this.updateProduct();
  }

  closeModal() {
    this.$modal.close();
  }

  addProduct() {
    const formValue = this.productForm.getRawValue();
    if (this.productForm.invalid) return;
    const data = new Product({
      ...formValue,
      copied_from: this.copiedProduct ? this.copiedProduct.id : undefined,
      replace_in_pos: this.reissuePos,
      currency: 'USD',
      allowed_customers: undefined,
    });

    this.$company.addProduct(this.organizationId, data)
      .pipe(
        switchMap((product: Product) => {
          const fileUploadSubscriptions = [];
          if (this.fileList) {
            for (let i = 0; i < this.fileList.length; i++) {
              const formData: FormData = new FormData();
              const file = this.fileList.item(i);
              if (file.size > this.FILE_LIMIT) {
                this.$toaster.show('warning', 'File size', 'Maximum file size for uploads is 10 mb');
                return;
              }
              formData.append('file', file, file.name);
              const fileSub = this.$company.addFileToPo(this.organizationId, product.id, formData, false, true);
              fileSub.pipe(catchError(e => {
                this.$toaster.show('error', 'File', e.error ? e.error.message : e.message);
                return of(e);
              }));
              fileUploadSubscriptions.push(fileSub);
            }
          }

          forkJoin(fileUploadSubscriptions)
            .pipe(take(1))
            .subscribe();
          return of(product);
        }),

        switchMap((product: Product) => {
          const customerIdentifierSubscriptions = [];
          if (this.role === 'customer' && formValue.customer_part_identifier) {
            const customerPartIdentifier = {
              customer_id: this.organizationId,
              product_id: product.id,
              customer_part_identifier: formValue.customer_part_identifier,
            };
            const newProductPartIdentifierSub = this.$company.addNewProductCustomerPartIdentifier(this.organizationId, product.id, customerPartIdentifier);
            customerIdentifierSubscriptions.push(newProductPartIdentifierSub);
          }
          if (this.role === 'supplier') {
            formValue.allowed_customers && formValue.allowed_customers.forEach((customerPartIdentifier) => {
              const newProductPartIdentifierSub = this.$company.addNewProductCustomerPartIdentifier(this.organizationId, product.id, customerPartIdentifier);
              customerIdentifierSubscriptions.push(newProductPartIdentifierSub);
            });
          }
          if (!customerIdentifierSubscriptions.length) {
            return of(product);
          }
          return forkJoin(customerIdentifierSubscriptions).pipe(map(result => product));
        }),
      )
      .subscribe(
        (product: Product) => {
          this.$toaster.show('success', 'Product', 'Product has been created');
          this.$modal.close(['success', product, this.role]);
        },
        error => {
          console.error(error);
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error occurred while adding a product.';
          this.$toaster.show('error', 'Product', errMsg);
        },
      );
  }

  attachFiles($event: any) {
    this.fileList = $event.srcElement.files;
  }

  addCustomerPartIdentifier() {
    if (this.customerPartIdentifierForm.valid) {
      const customerIdentifier: CustomerIdentifier = this.customerPartIdentifierForm.getRawValue();
      (this.productForm.controls.allowed_customers as UntypedFormArray).insert(-1, this.formBuilder.group({
        customer_id: [
          {
            value: customerIdentifier.customer_id,
            disabled: true,
          }, [Validators.required],
        ],
        customer_part_identifier: [customerIdentifier.customer_part_identifier, [Validators.required]],
      }));

      this.allowedCustomers = this.allowedCustomers.filter((customer) => {
        return customer.id !== customerIdentifier.customer_id;
      });

      setTimeout(() => {
        this.customerPartIdentifierForm.reset();
      }, 0);

    }
  }

  removeCustomerPartIdentifier(index) {
    const allowedCustomersControl: UntypedFormArray = this.productForm.controls.allowed_customers as UntypedFormArray;
    allowedCustomersControl.removeAt(index);

    if (allowedCustomersControl.length === 0) {
      if (this.role !== 'po') {
        ['customer_part_identifier', 'customer_id'].forEach(control => {
          this.customerPartIdentifierForm.get(control).setValidators([Validators.required]);
        });
      }

      this.allowedCustomers = this.customers.slice();
    } else {
      this.allowedCustomers = this.customers.filter(customer => {
        return allowedCustomersControl.controls.find((item) => {
          return item.get('customer_id').value !== customer.id;
        });
      });
    }

  }

  hasValidator(control, validator) {
    return true;
  }

}
