import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService, Toaster } from '@app/services';
import { TrainingsService } from 'api/services';
import { LasgroTraining } from 'api/models';
import { User } from '@app/models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-training-guides',
  templateUrl: './training-guides-modal.component.html',
  styleUrls: ['./training-guides-modal.component.scss'],
})
export class TrainingGuidesModalComponent implements OnInit {
  trainings: LasgroTraining[]
  newTraining: LasgroTraining = {} as LasgroTraining;
  user: Observable<User>;
  currentUser: User = {} as User;
  constructor(private activeModal: NgbActiveModal,
    private trainingServcie: TrainingsService,
    private store: Store<{ user: User }>,
    private toaster: Toaster) {
  }

  ngOnInit(): void {
    this.user = this.store.select('user');
    this.user.subscribe((user) => {
      this.currentUser = user as User;
    });
    this.initGuides();
  }

  closeModal() {
    this.activeModal.close();
  }

  initGuides() {
    this.trainingServcie.trainingsList().subscribe((trainings: LasgroTraining[]) => {
      this.trainings = trainings;
    })
  }

  delete(id) {
    this.trainingServcie.trainingsDelete(id).subscribe(() => {
      this.initGuides();
      this.toaster.show('success', 'SUCCESS', 'Trainig succesfuly removed');
    })
  }

  add() {
    this.trainingServcie.trainingsAdd(this.newTraining).subscribe(() => {
      this.initGuides();
      this.toaster.show('success', 'SUCCESS', 'Trainig succesfuly added');
    })
  }
}
