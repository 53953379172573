export class Badge {
  purchase_orders: boolean;
  finished_goods_reports: boolean;
  checklist: boolean;
  tasks: boolean;
  purchase_order_files: boolean;
  step_to_produce: boolean;

  constructor() {
    this.purchase_orders = false;
    this.finished_goods_reports = false;
    this.checklist = false;
    this.tasks = false;
    this.purchase_order_files = false;
  }
}

export class BadgeNotification {
  changed: boolean;
  section: string;
}
