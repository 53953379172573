/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SavedChecklistAddFromChecklistInputPayload } from '../models/saved-checklist-add-from-checklist-input-payload';
import { SavedChecklistAddInputPayload } from '../models/saved-checklist-add-input-payload';
import { LasgroSaved_checklist } from '../models/lasgro-saved-_checklist';
import { SavedChecklistUpdateInputPayload } from '../models/saved-checklist-update-input-payload';
import { LasgroSaved_checklistCollection } from '../models/lasgro-saved-_checklist-collection';
@Injectable({
  providedIn: 'root',
})
class SavedChecklistService extends __BaseService {
  static readonly savedChecklistAddFromChecklistPath = '/organizations/{organizationID}/saved_checklists';
  static readonly savedChecklistAddPath = '/organizations/{organizationID}/saved_checklists';
  static readonly savedChecklistShow1Path = '/organizations/{organizationID}/saved_checklists/{savedChecklistID}';
  static readonly savedChecklistDelete1Path = '/organizations/{organizationID}/saved_checklists/{savedChecklistID}';
  static readonly savedChecklistUpdate1Path = '/organizations/{organizationID}/saved_checklists/{savedChecklistID}';
  static readonly savedChecklistListPath = '/organizations/{organizationID}/suppliers/{supplierID}/saved_checklists';
  static readonly savedChecklistShowPath = '/saved_checklists/{savedChecklistID}';
  static readonly savedChecklistDeletePath = '/saved_checklists/{savedChecklistID}';
  static readonly savedChecklistUpdatePath = '/saved_checklists/{savedChecklistID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * add_from_checklist saved_checklist
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistAddFromChecklistParams` containing the following parameters:
   *
   * - `payload`: Input payload of saved checklist from checklist
   *
   * - `organizationID`:
   */
  savedChecklistAddFromChecklistResponse(params: SavedChecklistService.SavedChecklistAddFromChecklistParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_from_checklist saved_checklist
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistAddFromChecklistParams` containing the following parameters:
   *
   * - `payload`: Input payload of saved checklist from checklist
   *
   * - `organizationID`:
   */
  savedChecklistAddFromChecklist(params: SavedChecklistService.SavedChecklistAddFromChecklistParams): __Observable<null> {
    return this.savedChecklistAddFromChecklistResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add saved_checklist
   *
   * Create saved checklist for organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistAddParams` containing the following parameters:
   *
   * - `payload`: Input payload of saved checklist
   *
   * - `organizationID`:
   */
  savedChecklistAddResponse(params: SavedChecklistService.SavedChecklistAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add saved_checklist
   *
   * Create saved checklist for organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistAddParams` containing the following parameters:
   *
   * - `payload`: Input payload of saved checklist
   *
   * - `organizationID`:
   */
  savedChecklistAdd(params: SavedChecklistService.SavedChecklistAddParams): __Observable<null> {
    return this.savedChecklistAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show saved_checklist
   *
   * Saved checklist by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistShow1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the saved checklist
   *
   * @return OK
   */
  savedChecklistShow1Response(params: SavedChecklistService.SavedChecklistShow1Params): __Observable<__StrictHttpResponse<LasgroSaved_checklist>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSaved_checklist>;
      })
    );
  }
  /**
   * show saved_checklist
   *
   * Saved checklist by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistShow1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the saved checklist
   *
   * @return OK
   */
  savedChecklistShow1(params: SavedChecklistService.SavedChecklistShow1Params): __Observable<LasgroSaved_checklist> {
    return this.savedChecklistShow1Response(params).pipe(
      __map(_r => _r.body as LasgroSaved_checklist)
    );
  }

  /**
   * delete saved_checklist
   *
   * Deletes saved checklist with all tasks.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistDelete1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistDelete1Response(params: SavedChecklistService.SavedChecklistDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete saved_checklist
   *
   * Deletes saved checklist with all tasks.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistDelete1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistDelete1(params: SavedChecklistService.SavedChecklistDelete1Params): __Observable<null> {
    return this.savedChecklistDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update saved_checklist
   *
   * Updates saved checklist.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistUpdate1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist for update
   *
   * - `organizationID`:
   */
  savedChecklistUpdate1Response(params: SavedChecklistService.SavedChecklistUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update saved_checklist
   *
   * Updates saved checklist.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistUpdate1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist for update
   *
   * - `organizationID`:
   */
  savedChecklistUpdate1(params: SavedChecklistService.SavedChecklistUpdate1Params): __Observable<null> {
    return this.savedChecklistUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list saved_checklist
   *
   * List all saved checklists for this organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistListParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the saved checklist
   *
   * @return OK
   */
  savedChecklistListResponse(params: SavedChecklistService.SavedChecklistListParams): __Observable<__StrictHttpResponse<LasgroSaved_checklistCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/saved_checklists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSaved_checklistCollection>;
      })
    );
  }
  /**
   * list saved_checklist
   *
   * List all saved checklists for this organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistListParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the saved checklist
   *
   * @return OK
   */
  savedChecklistList(params: SavedChecklistService.SavedChecklistListParams): __Observable<LasgroSaved_checklistCollection> {
    return this.savedChecklistListResponse(params).pipe(
      __map(_r => _r.body as LasgroSaved_checklistCollection)
    );
  }

  /**
   * show saved_checklist
   *
   * Saved checklist by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistShowParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `viewType`: What view type to show for the saved checklist
   *
   * - `organizationID`:
   *
   * @return OK
   */
  savedChecklistShowResponse(params: SavedChecklistService.SavedChecklistShowParams): __Observable<__StrictHttpResponse<LasgroSaved_checklist>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSaved_checklist>;
      })
    );
  }
  /**
   * show saved_checklist
   *
   * Saved checklist by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistShowParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `viewType`: What view type to show for the saved checklist
   *
   * - `organizationID`:
   *
   * @return OK
   */
  savedChecklistShow(params: SavedChecklistService.SavedChecklistShowParams): __Observable<LasgroSaved_checklist> {
    return this.savedChecklistShowResponse(params).pipe(
      __map(_r => _r.body as LasgroSaved_checklist)
    );
  }

  /**
   * delete saved_checklist
   *
   * Deletes saved checklist with all tasks.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistDeleteParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistDeleteResponse(params: SavedChecklistService.SavedChecklistDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete saved_checklist
   *
   * Deletes saved checklist with all tasks.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistDeleteParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistDelete(params: SavedChecklistService.SavedChecklistDeleteParams): __Observable<null> {
    return this.savedChecklistDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update saved_checklist
   *
   * Updates saved checklist.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistUpdateParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist for update
   *
   * - `organizationID`:
   */
  savedChecklistUpdateResponse(params: SavedChecklistService.SavedChecklistUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update saved_checklist
   *
   * Updates saved checklist.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistService.SavedChecklistUpdateParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist for update
   *
   * - `organizationID`:
   */
  savedChecklistUpdate(params: SavedChecklistService.SavedChecklistUpdateParams): __Observable<null> {
    return this.savedChecklistUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SavedChecklistService {

  /**
   * Parameters for savedChecklistAddFromChecklist
   */
  export interface SavedChecklistAddFromChecklistParams {

    /**
     * Input payload of saved checklist from checklist
     */
    payload: SavedChecklistAddFromChecklistInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for savedChecklistAdd
   */
  export interface SavedChecklistAddParams {

    /**
     * Input payload of saved checklist
     */
    payload: SavedChecklistAddInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for savedChecklistShow1
   */
  export interface SavedChecklistShow1Params {
    savedChecklistID: string;
    organizationID: string;

    /**
     * What view type to show for the saved checklist
     */
    viewType?: 'default' | 'full';
  }

  /**
   * Parameters for savedChecklistDelete1
   */
  export interface SavedChecklistDelete1Params {
    savedChecklistID: string;
    organizationID: string;
  }

  /**
   * Parameters for savedChecklistUpdate1
   */
  export interface SavedChecklistUpdate1Params {
    savedChecklistID: string;

    /**
     * Input payload of saved checklist for update
     */
    payload: SavedChecklistUpdateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for savedChecklistList
   */
  export interface SavedChecklistListParams {
    supplierID: string;
    organizationID: string;

    /**
     * What view type to show for the saved checklist
     */
    viewType?: 'default' | 'full';
  }

  /**
   * Parameters for savedChecklistShow
   */
  export interface SavedChecklistShowParams {
    savedChecklistID: string;

    /**
     * What view type to show for the saved checklist
     */
    viewType?: 'default' | 'full';
    organizationID?: string;
  }

  /**
   * Parameters for savedChecklistDelete
   */
  export interface SavedChecklistDeleteParams {
    savedChecklistID: string;
    organizationID?: string;
  }

  /**
   * Parameters for savedChecklistUpdate
   */
  export interface SavedChecklistUpdateParams {
    savedChecklistID: string;

    /**
     * Input payload of saved checklist for update
     */
    payload: SavedChecklistUpdateInputPayload;
    organizationID?: string;
  }
}

export { SavedChecklistService }
