import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractDto } from '@app/models';
import { Toaster } from '@app/services';
import { FileTypePayload, LasgroFile_type_group } from 'api/models';
import { FileTypeGroupsService, PurchaseOrderService } from 'api/services';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'purchase-order-file-type',
  templateUrl: './purchase-order-file-type.modal.html',
  styleUrls: ['./purchase-order-file-type.modal.scss'],
})
export class PurchaseOrderFileTypeModal implements OnInit {
  fileTypesControl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTypesGroup: Observable<string[]>;
  typesGroups: any[] = [];
  purchaseOrder: ContractDto;
  saveTypesGroup = false;
  fileTypeGroup = { name: '', values: [] };
  @ViewChild('typesInput') typesInput: ElementRef<HTMLInputElement>;
  constructor(
    private fileTypeGroupService: FileTypeGroupsService,
    private purchaseOederService: PurchaseOrderService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private toaster: Toaster,
  ) {}

  ngOnInit(): void {
    this.fileTypesControl.setValue(this.dialogData.purchaseOrder.file_types);
    this.purchaseOrder = this.dialogData.purchaseOrder;
    if (!this.purchaseOrder.file_types) {
      this.purchaseOrder.file_types = []
    }
    this.initTypes();
  }

  initTypes() {
    this.fileTypeGroupService
      .fileTypeGroupsList(this.dialogData.organizationId)
      .subscribe((types: LasgroFile_type_group[]) => {
        this.typesGroups = types;
        this.filteredTypesGroup = this.fileTypesControl.valueChanges.pipe(
          startWith(null),
          map((search: string | null) => (search ? this.filter(search) : this.typesGroups.slice())),
        );
      });
  }

  add(event: any): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.purchaseOrder.file_types.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fileTypesControl.setValue(this.purchaseOrder.file_types);
  }

  remove(type: FileTypePayload): void {
    const index = this.purchaseOrder.file_types.indexOf(type);

    if (index >= 0) {
      this.purchaseOrder.file_types.splice(index, 1);
    }
    this.fileTypesControl.setValue(this.purchaseOrder.file_types);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.purchaseOrder.file_types = [...this.purchaseOrder.file_types, ...event.option.value];
    this.typesInput.nativeElement.value = '';
    this.fileTypesControl.setValue(this.purchaseOrder.file_types);
  }

  private filter(value: any): string[] {
    if (Array.isArray(value)) {
      return;
    }
    const filterValue = value.toLowerCase();

    return this.typesGroups.filter((typesGroup) => typesGroup.name.toLowerCase().indexOf(filterValue) === 0);
  }

  onSaveClick() {
    this.purchaseOederService
      .purchaseOrderUpdateFileType({
        payload: {file_types: this.purchaseOrder.file_types },
        organizationID: this.dialogData.organizationId,
        purchaseOrderID: this.purchaseOrder.id
        
      })
      .subscribe(
        (result) => {
          if (this.saveTypesGroup) {
            this.fileTypeGroup.values = this.purchaseOrder.file_types;
            this.fileTypeGroupService
              .fileTypeGroupsAdd({ organizationID: this.dialogData.organizationId, payload: this.fileTypeGroup })
              .subscribe(() => {});
          }
          this.toaster.show('success', 'Data saved', 'Type was updated');
        },
        (err) => {
          this.toaster.show('error', 'An error occurred', 'Please try again later');
        },
      );
  }
}
