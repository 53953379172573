<ng-container>
  <div class="modal-header po-modal">
    <span>Saved Task Lists</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
      <span class="ev-clickable" aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">Select a task list with a preview of its tasks and add it to your Contract</div>
    </div>
    <div class="row">
      <div class="col-12">
        <ng-select placeholder="Saved task lists"
                   bindValue="saved_checklist_id"
                   bindLabel="saved_checklist_name"
                   appendTo="body"
                   [hideSelected]="true"
                   [clearable]="false"
                   [virtualScroll]="true"
                   [labelForId]="'saved_checklist_select'"
                   (change)="selectChecklist($event)"
                   required
                   [items]="(checklists$ | async)"></ng-select>
      </div>
    </div>
    <div class="tasks-preview-container">
      <div class="task" *ngFor="let task of tasks">
        <div class="description">{{ task.name }}</div>
        <div class="dates">
          <div>Created at: {{ task['formatted_created_at'] }}</div>
          <div>Due date: {{ task['formatted_due_date'] }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="pull-right" mat-raised-button (click)="closeModal()">
      Cancel
    </button>
    <button
      class="pull-right"
      mat-raised-button
      color="primary"
      (click)="useSavedChecklist()"
      [disabled]="!selectedChecklist"
    >
      Use task list
    </button>
  </div>
</ng-container>
