import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SavedChecklist } from '@models/checklist.model';
import { SavedTask } from '@app/models/checklist-task.model';
import { Observable } from 'rxjs';
import CONFIG from '@app/app.config';

@Injectable()
export class ChecklistService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Service to fetch the saved checklists
   * @param organizationId ID of the company
   * @param supplierId ID of the company supplier
   */
  fetchSavedChecklist(organizationId: string, supplierId: string): Observable<SavedChecklist[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/suppliers/${ supplierId }/saved_checklists`;
    return this.httpClient.get<SavedChecklist[]>(endpoint);
  }

  getSavedChecklist(organizationId: string, savedChecklistId: string): Observable<SavedChecklist> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/saved_checklists/${ savedChecklistId }?viewType=full`;
    return this.httpClient.get<SavedChecklist>(endpoint);
  }

  /**
   * Service to save a checklist of a PO to be used again in another PO
   * @param organizationId ID of the company
   * @param checklistId ID of the checklist to be saved
   * @param name Name of the checklist to save
   * @param supplierId ID of the supplier
   */
  saveChecklist(organizationId: string, checklistId: string, name: string, supplierId: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/saved_checklists`;
    const obj = { checklist_id: checklistId, name, supplier_id: supplierId };
    return this.httpClient.put(endpoint, obj);
  }

  updateSavedChecklist(checklistId: string, data: SavedChecklist) {
    const endpoint = `${ CONFIG.API }/saved_checklists/${ checklistId }`;
    return this.httpClient.patch(endpoint, data);
  }

  deleteSavedChecklist(checklistId: string) {
    const endpoint = `${ CONFIG.API }/saved_checklists/${ checklistId }`;
    return this.httpClient.delete(endpoint);
  }

  //

  addSavedChecklistTask(taskId: string, data: SavedTask) {
    const endpoint = `${ CONFIG.API }/saved_checklist_tasks/${ taskId }`;
    return this.httpClient.post(endpoint, data);
  }

  updateSavedChecklistTask(taskId: string, data: SavedTask) {
    const endpoint = `${ CONFIG.API }/saved_checklist_tasks/${ taskId }`;
    return this.httpClient.patch(endpoint, data);
  }

  deleteSavedChecklistTask(taskId: string) {
    const endpoint = `${ CONFIG.API }/saved_checklist_tasks/${ taskId }`;
    return this.httpClient.delete(endpoint);
  }
}
