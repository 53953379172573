<div class="col w-100 p-0 pt-2" *ngIf="document.file as file">
  <div class="file-container border" [ngClass]="{ 'border-success': (file.is_main_file && !status) || (status && status === 'open'), 'border-danger': status === 'rejected', 'border-warning': status === 'pending' }" [class.border-success]="file.is_main_file">
    <div class="">
      <a matTooltip="Set as Terms and Conditions"
         *ngIf="(companyRole !== 'customer' && enableControls && !file.is_main_file) else fileIcon"
         (click)="setAsTermsFile(file)"
         class="file-icon d-flex justify-content-center align-items-center flex-column">
        <i class="fa fa-file-o"></i>
      </a>

      <ng-template #fileIcon>
        <span
          [class.text-success]="file.is_main_file"
          class="file-icon d-flex justify-content-center align-items-center flex-column">
          <i class="fa fa-file-o"></i>
        </span>
      </ng-template>
    </div>
    <div class="text-center">
      <!-- (click)="showFile(file)" -->
      <a class="text-primary" ngxFilesPreview [fileId]="file.file_id">{{ file.file_name }}</a>
      <span *ngIf="document.expires_at" [ngClass]="{ 'text-danger': isDocumentExpired() }">
       - {{ isDocumentExpired() ? 'Expired' : 'Expires'}} {{ document.expires_at | date:'mediumDate':'utc' }}
      </span>

      <div class="text-center d-flex justify-content-center">
        <small class="file label" *ngIf="file.label">{{ file.label }}</small>
        <small>
          {{ file.created_at | date:'mediumDate':'utc' }} by {{ file.created_by_name }} ({{ file.file_size | fileSize }})
        </small>
      </div>
    </div>
    <div class="actions d-flex">
      <a *ngIf="enableControls && companyRole === 'supplier'" matTooltip="View File Details" (click)="viewTermsFile()">
        <i class="fa fa-external-link"></i>
      </a>
      <a matTooltip="Edit File" class="ml-2" 
         *ngIf="enableControls && companyRole === 'supplier'" (click)="editDocument(document)">
        <i class="fa fa-edit"></i>
      </a>
      <a matTooltip="Change Expiry Date" class="ml-2" 
          *ngIf="enableControls && companyRole === 'customer'" (click)="picker.open()">
        <i class="fa fa-calendar-plus-o"></i>
        <mat-form-field style="width:1px;visibility:hidden;">
          <input matInput [matDatepicker]="picker" [min]="today" [value]="document.expires_at" (dateInput)="updateDueDate($event, file)">
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </a>
      <a matTooltip="Remove File"
         *ngIf="!file.is_main_file && enableControls"
         class="remove-file ml-2" (click)="removeFile(file)">
        <i class="fa fa-trash"></i>
      </a>
    </div>
  </div>
</div>
