/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroTask_typeCollection } from '../models/lasgro-task-_type-collection';
import { LasgroGeneral_success_id_payload } from '../models/lasgro-general-_success-_id-_payload';
import { TaskTypeInputPayload } from '../models/task-type-input-payload';
import { LasgroTask_type } from '../models/lasgro-task-_type';
import { TaskTypeUpdateInputPayload } from '../models/task-type-update-input-payload';
@Injectable({
  providedIn: 'root',
})
class TaskTypeService extends __BaseService {
  static readonly taskTypeListPath = '/workflows/task_types';
  static readonly taskTypeAddPath = '/workflows/task_types';
  static readonly taskTypeShowPath = '/workflows/task_types/{taskTypeID}';
  static readonly taskTypeUpdatePath = '/workflows/task_types/{taskTypeID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list task_type
   *
   * List all of the task types
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  taskTypeListResponse(): __Observable<__StrictHttpResponse<LasgroTask_typeCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows/task_types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_typeCollection>;
      })
    );
  }
  /**
   * list task_type
   *
   * List all of the task types
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  taskTypeList(): __Observable<LasgroTask_typeCollection> {
    return this.taskTypeListResponse().pipe(
      __map(_r => _r.body as LasgroTask_typeCollection)
    );
  }

  /**
   * add task_type
   *
   * Creates a task type
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload Input for a task type
   * @return Created
   */
  taskTypeAddResponse(payload: TaskTypeInputPayload): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/workflows/task_types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * add task_type
   *
   * Creates a task type
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload Input for a task type
   * @return Created
   */
  taskTypeAdd(payload: TaskTypeInputPayload): __Observable<LasgroGeneral_success_id_payload> {
    return this.taskTypeAddResponse(payload).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * show task_type
   *
   * Details of a Task Type
   *
   * Required security scopes:
   *   * `api:access`
   * @param taskTypeID undefined
   * @return OK
   */
  taskTypeShowResponse(taskTypeID: string): __Observable<__StrictHttpResponse<LasgroTask_type>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows/task_types/${encodeURIComponent(String(taskTypeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_type>;
      })
    );
  }
  /**
   * show task_type
   *
   * Details of a Task Type
   *
   * Required security scopes:
   *   * `api:access`
   * @param taskTypeID undefined
   * @return OK
   */
  taskTypeShow(taskTypeID: string): __Observable<LasgroTask_type> {
    return this.taskTypeShowResponse(taskTypeID).pipe(
      __map(_r => _r.body as LasgroTask_type)
    );
  }

  /**
   * update task_type
   *
   * Updates a task type
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskTypeService.TaskTypeUpdateParams` containing the following parameters:
   *
   * - `taskTypeID`:
   *
   * - `payload`: Update input for task type
   *
   * @return OK
   */
  taskTypeUpdateResponse(params: TaskTypeService.TaskTypeUpdateParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/workflows/task_types/${encodeURIComponent(String(params.taskTypeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * update task_type
   *
   * Updates a task type
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskTypeService.TaskTypeUpdateParams` containing the following parameters:
   *
   * - `taskTypeID`:
   *
   * - `payload`: Update input for task type
   *
   * @return OK
   */
  taskTypeUpdate(params: TaskTypeService.TaskTypeUpdateParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.taskTypeUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }
}

module TaskTypeService {

  /**
   * Parameters for taskTypeUpdate
   */
  export interface TaskTypeUpdateParams {
    taskTypeID: string;

    /**
     * Update input for task type
     */
    payload: TaskTypeUpdateInputPayload;
  }
}

export { TaskTypeService }
