<div *ngIf="contract" class="container-fluid" style="background: #e4f2f3;">
    <div class="row py-4 mt-4">
      <div class="col-md-10 offset-md-1">
        <ngx-title-divider title="Required Contract Documents (SECTION A)" [bold]="true"></ngx-title-divider>
      </div>
      <div class="col-md-10 offset-md-1 mt-2" style="max-height: 300px; overflow-y: scroll;">
        <div class="edi-lite-item-container">
          <div class="edi-lite-item-content">
            <div class="row" *ngIf="viewType == 'customer' && currentUser.role != 'User'">
              <div class="col-md-12">
                <div class="actions">
                  <div class="m-0">
                    <button
                      class="btn btn-success p-0 text-capitalize"
                      (click)="addSubmittedDocumentFileEventEmitter.emit()"
                    >
                      <!-- [disabled]="true" -->
                      <i class="fa fa-plus"></i>
                      Upload Document
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <ngx-po-files
              #filesSimplePO
              [companyType]="viewType"
              [hideAddButton]="true"
              [isApprovalSection]="true"
              [addFileEventEmitter]="addSubmittedDocumentFileEventEmitter"
              [enableControls]="viewType === 'supplier'"
              [purchaseOrderId]="contract?.id"
              [fileTypes]="contract.file_types"
              [defaultTag]="'$contract_documents_a'"
              [isApproveLogicEnabled]="false"
              [isInsuranceLogicEnabled]="false"
              [isCommentVisible]="true"
              [isFileDeletionAvaliable]="viewType == 'customer'"
            ></ngx-po-files>
          </div>
        </div>
      </div>
  </div>