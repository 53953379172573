/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroLine_item_stepCollection } from '../models/lasgro-line-_item-_step-collection';
import { LasgroStepCollection } from '../models/lasgro-step-collection';
import { AddStepPayload } from '../models/add-step-payload';
import { LasgroStep } from '../models/lasgro-step';
import { StepUpdateInputPayload } from '../models/step-update-input-payload';
@Injectable({
  providedIn: 'root',
})
class StepService extends __BaseService {
  static readonly stepStepsForItemPath = '/organizations/{organizationID}/line_items/{lineItemID}/steps';
  static readonly stepListPath = '/organizations/{organizationID}/products/{productID}/steps';
  static readonly stepAddPath = '/organizations/{organizationID}/products/{productID}/steps';
  static readonly stepShowPath = '/organizations/{organizationID}/products/{productID}/steps/{stepID}';
  static readonly stepUpdatePath = '/organizations/{organizationID}/products/{productID}/steps/{stepID}';
  static readonly stepDeletePath = '/organizations/{organizationID}/products/{productID}/steps/{stepID}';
  static readonly stepList1Path = '/products/{productID}/steps';
  static readonly stepAdd1Path = '/products/{productID}/steps';
  static readonly stepShow1Path = '/products/{productID}/steps/{stepID}';
  static readonly stepUpdate1Path = '/products/{productID}/steps/{stepID}';
  static readonly stepDelete1Path = '/products/{productID}/steps/{stepID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * steps_for_item step
   *
   * Lists line item steps
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepStepsForItemParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `lineItemID`:
   *
   * @return OK
   */
  stepStepsForItemResponse(params: StepService.StepStepsForItemParams): __Observable<__StrictHttpResponse<LasgroLine_item_stepCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/line_items/${encodeURIComponent(String(params.lineItemID))}/steps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroLine_item_stepCollection>;
      })
    );
  }
  /**
   * steps_for_item step
   *
   * Lists line item steps
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepStepsForItemParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `lineItemID`:
   *
   * @return OK
   */
  stepStepsForItem(params: StepService.StepStepsForItemParams): __Observable<LasgroLine_item_stepCollection> {
    return this.stepStepsForItemResponse(params).pipe(
      __map(_r => _r.body as LasgroLine_item_stepCollection)
    );
  }

  /**
   * list step
   *
   * Lists steps
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepListParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  stepListResponse(params: StepService.StepListParams): __Observable<__StrictHttpResponse<LasgroStepCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.archived != null) __params = __params.set('archived', params.archived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/steps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroStepCollection>;
      })
    );
  }
  /**
   * list step
   *
   * Lists steps
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepListParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  stepList(params: StepService.StepListParams): __Observable<LasgroStepCollection> {
    return this.stepListResponse(params).pipe(
      __map(_r => _r.body as LasgroStepCollection)
    );
  }

  /**
   * add step
   *
   * Adds a new step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepAddParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  stepAddResponse(params: StepService.StepAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/steps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add step
   *
   * Adds a new step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepAddParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  stepAdd(params: StepService.StepAddParams): __Observable<null> {
    return this.stepAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show step
   *
   * Retrieve step with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepShowParams` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  stepShowResponse(params: StepService.StepShowParams): __Observable<__StrictHttpResponse<LasgroStep>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/steps/${encodeURIComponent(String(params.stepID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroStep>;
      })
    );
  }
  /**
   * show step
   *
   * Retrieve step with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepShowParams` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  stepShow(params: StepService.StepShowParams): __Observable<LasgroStep> {
    return this.stepShowResponse(params).pipe(
      __map(_r => _r.body as LasgroStep)
    );
  }

  /**
   * update step
   *
   * Updates a step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepUpdateParams` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  stepUpdateResponse(params: StepService.StepUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/steps/${encodeURIComponent(String(params.stepID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update step
   *
   * Updates a step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepUpdateParams` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  stepUpdate(params: StepService.StepUpdateParams): __Observable<null> {
    return this.stepUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepDeleteParams` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   */
  stepDeleteResponse(params: StepService.StepDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/steps/${encodeURIComponent(String(params.stepID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepDeleteParams` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   */
  stepDelete(params: StepService.StepDeleteParams): __Observable<null> {
    return this.stepDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list step
   *
   * Lists steps
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepList1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  stepList1Response(params: StepService.StepList1Params): __Observable<__StrictHttpResponse<LasgroStepCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.archived != null) __params = __params.set('archived', params.archived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}/steps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroStepCollection>;
      })
    );
  }
  /**
   * list step
   *
   * Lists steps
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepList1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  stepList1(params: StepService.StepList1Params): __Observable<LasgroStepCollection> {
    return this.stepList1Response(params).pipe(
      __map(_r => _r.body as LasgroStepCollection)
    );
  }

  /**
   * add step
   *
   * Adds a new step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepAdd1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  stepAdd1Response(params: StepService.StepAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}/steps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add step
   *
   * Adds a new step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepAdd1Params` containing the following parameters:
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  stepAdd1(params: StepService.StepAdd1Params): __Observable<null> {
    return this.stepAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show step
   *
   * Retrieve step with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepShow1Params` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  stepShow1Response(params: StepService.StepShow1Params): __Observable<__StrictHttpResponse<LasgroStep>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}/steps/${encodeURIComponent(String(params.stepID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroStep>;
      })
    );
  }
  /**
   * show step
   *
   * Retrieve step with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepShow1Params` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  stepShow1(params: StepService.StepShow1Params): __Observable<LasgroStep> {
    return this.stepShow1Response(params).pipe(
      __map(_r => _r.body as LasgroStep)
    );
  }

  /**
   * update step
   *
   * Updates a step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepUpdate1Params` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  stepUpdate1Response(params: StepService.StepUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}/steps/${encodeURIComponent(String(params.stepID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update step
   *
   * Updates a step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepUpdate1Params` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  stepUpdate1(params: StepService.StepUpdate1Params): __Observable<null> {
    return this.stepUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepDelete1Params` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   */
  stepDelete1Response(params: StepService.StepDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}/steps/${encodeURIComponent(String(params.stepID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete step
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `StepService.StepDelete1Params` containing the following parameters:
   *
   * - `stepID`: Step ID
   *
   * - `productID`:
   *
   * - `organizationID`:
   */
  stepDelete1(params: StepService.StepDelete1Params): __Observable<null> {
    return this.stepDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module StepService {

  /**
   * Parameters for stepStepsForItem
   */
  export interface StepStepsForItemParams {
    organizationID: string;
    lineItemID: string;
  }

  /**
   * Parameters for stepList
   */
  export interface StepListParams {
    productID: string;
    organizationID: string;

    /**
     * Whether to include archived products
     */
    archived?: boolean;
  }

  /**
   * Parameters for stepAdd
   */
  export interface StepAddParams {
    productID: string;
    payload: AddStepPayload;
    organizationID: string;
  }

  /**
   * Parameters for stepShow
   */
  export interface StepShowParams {

    /**
     * Step ID
     */
    stepID: string;
    productID: string;
    organizationID: string;
  }

  /**
   * Parameters for stepUpdate
   */
  export interface StepUpdateParams {

    /**
     * Step ID
     */
    stepID: string;
    productID: string;
    payload: StepUpdateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for stepDelete
   */
  export interface StepDeleteParams {

    /**
     * Step ID
     */
    stepID: string;
    productID: string;
    organizationID: string;
  }

  /**
   * Parameters for stepList1
   */
  export interface StepList1Params {
    productID: string;
    organizationID?: string;

    /**
     * Whether to include archived products
     */
    archived?: boolean;
  }

  /**
   * Parameters for stepAdd1
   */
  export interface StepAdd1Params {
    productID: string;
    payload: AddStepPayload;
    organizationID?: string;
  }

  /**
   * Parameters for stepShow1
   */
  export interface StepShow1Params {

    /**
     * Step ID
     */
    stepID: string;
    productID: string;
    organizationID?: string;
  }

  /**
   * Parameters for stepUpdate1
   */
  export interface StepUpdate1Params {

    /**
     * Step ID
     */
    stepID: string;
    productID: string;
    payload: StepUpdateInputPayload;
    organizationID?: string;
  }

  /**
   * Parameters for stepDelete1
   */
  export interface StepDelete1Params {

    /**
     * Step ID
     */
    stepID: string;
    productID: string;
    organizationID?: string;
  }
}

export { StepService }
