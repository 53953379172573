<div class="container-fluid" style="background: #f9e9e9;" *ngIf="contract">
  <div class="row py-4">
    <div class="col-md-10 offset-md-1">
      <ngx-title-divider title="Required Voucher Documents" [bold]="true"></ngx-title-divider>
    </div>

    <div class="col-md-10 offset-md-1 mt-2" style="max-height: 300px; overflow-y: scroll;">
      <p  *ngIf="currentUser.role != 'User'">
        The following documents are required for each voucher submission (see section below for forms):
      </p>
      <ol  *ngIf="currentUser.role != 'User'">
        <li>
          Voucher
        </li>
        <li>
          Attestation Form
        </li>
        <li>
          Financial Report
        </li>
      </ol>
      <p *ngIf="currentUser.role != 'User'">
        <span class="bg-warning">
          (please click the "Upload Document" button below to upload these documents)
        </span>
      </p>
      <div #Invoices>
        <div class="actions">
          <div class="m-0" *ngIf="viewType === 'supplier' && currentUser.role != 'User'">
            <button class="btn btn-success p-0 text-capitalize" (click)="addSubmittedVoucherFileEventEmitter.emit()">
              <i class="fa fa-plus"></i>
              Upload Document
            </button>
          </div>
        </div>
        <div class="edi-lite-item-content">
          <ngx-po-files
            #filesSimplePO
            [fileTypes]="contract.file_types"
            [hideAddButton]="true"
            [isApprovalSection]="true"
            [addFileEventEmitter]="addSubmittedVoucherFileEventEmitter"
            [enableControls]="viewType === 'supplier'"
            [purchaseOrderId]="contract.id"
            [defaultTag]="'$submitted_voucher_documents'"
            [refreshEvent]="refreshSubmittedVoucherDocuments"
          ></ngx-po-files>
        </div>
      </div>
    </div>

    <div class="col-md-10 offset-md-1 mt-4">
      <ngx-title-divider title="Required Voucher Forms" [bold]="true"></ngx-title-divider>
    </div>
    <div class="col-md-10 offset-md-1 mt-2" style="max-height: 300px; overflow-y: scroll;">
      <div class="row"  *ngIf="currentUser.role != 'User'">
        <div class="col-md-12">
          <p class="p-0 text-danger">
            Download the fillable template forms below in order to fill them in. Please complete, sign (if necessary),
            save and upload to submit.
          </p>
          <ol type="1" >
            <li>
              <a
                class="text-danger font-weight-bold"
                href="https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhCigl-GviasP4EN9QlbYgurePEHfdg6Ai1je8u36VIoSGZytI2MhidHmtyELro4QqY*"
                target="_blank"
              >
                Voucher form
              </a>
              <!-- <a class="text-danger font-weight-bold ml-2" (click)="openPandaDocVoucherForm($event)" 
                  *ngIf="currentUser.role === 'Lasgro Admin'"
                >
                  [2023]
                </a> -->
            </li>
            <li>
              <a
                class="text-danger font-weight-bold"
                href="https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhA4NWdnP1yMHrlvgvjszeUJ-VoSdJJpOG5W6Le4OfGGweT4qaD3XVtW7u2hrj45XLk*"
                target="_blank"
              >
                Attestation Form
              </a>
            </li>
            <li>
              <a
                class="text-danger font-weight-bold"
                href="https://rcdss-documents.s3.amazonaws.com/sample+2021+Financial+Report.xlsx"
                target="_blank"
              >
                2022 Financial Reports
              </a>
            </li>
            <li>
              <span class="text-danger"
                >Supporting Documentation (please see "CHECKLIST" in SECTION D below for reference)</span
              >
            </li>
          </ol>
          <!-- <p class="text-danger">
              These forms will be finalized for use and available beginning January 2022.
            </p> -->
        </div>
      </div>
      <hr />
      <ngx-po-files
        #filesSimplePO
        [fileTypes]="contract.file_types"
        [enableControls]="viewType === 'customer'"
        [purchaseOrderId]="contract.id"
        [defaultTag]="'$voucher_documents'"
      ></ngx-po-files>
    </div>
  </div>
</div>
