<div class="modal-header po-modal font-weight-bold">
  TRAINING GUIDES
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ev-form po-modal text-center d-flex flex-column align-items-center">
  <h5 class="mb-4">Click any of the buttons to download:</h5>
  <div *ngFor="let training of trainings" class="d-flex">
    <a class="btn btn-success btn-download mb-4" href="{{ training.link }}" target="_blank" download>
      {{ training.title }}
    </a>
    <span
      class="translate-middle badge rounded-pill bg-danger delete-badge"
      *ngIf="currentUser.role == 'Lasgro Admin'"
      (click)="delete(training.id)"
    >
      x
    </span>
  </div>
  <div class="w-100 d-flex" *ngIf="currentUser.role == 'Lasgro Admin'">
    <div class="col-10">
      <mat-form-field class="col-12">
        <input matInput [(ngModel)]="newTraining.title" placeholder="Title" />
      </mat-form-field>
      <mat-form-field class="col-12 mt-2">
        <input matInput [(ngModel)]="newTraining.link" placeholder="Link" />
      </mat-form-field>
    </div>
    <button mat-stroked-button class="col-2 btn-success h-50 align-self-center" (click)="add()">Add</button>
  </div>
  <!-- <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Required+Contract+Documents+and+Forms.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    REQUIRED CONTRACT DOCUMENTS AND FORMS
  </a>
  <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Required+Voucher+Documents+%26+Forms.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    REQUIRED VOUCHER DOCUMENTS & FORMS
  </a>
  <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Sending+a+Message.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    SENDING A MESSAGE
  </a>
  <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Task+Management.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    TASK MANAGEMENT
  </a> -->
</div>
