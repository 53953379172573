import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Step } from '@models/index';
import { Toaster } from '@services/index';

@Component({
  selector: 'ngx-step-to-produce-form-modal',
  templateUrl: './step-to-produce-form-modal.component.html',
  styleUrls: ['./step-to-produce-form-modal.component.scss'],
})
export class StepToProduceFormModalComponent implements OnInit {
  stepForm: UntypedFormGroup;
  @Input() step: Step = new Step();

  constructor(private fb: UntypedFormBuilder,
              private $toaster: Toaster,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.stepForm = this.fb.group({
      name: [this.step.name, [Validators.required]],
      barcode: [this.step.barcode, [Validators.pattern('^[a-zA-Z0-9]*$')]],
      description: [this.step.description],
    });
  }

  saveStep() {
    if (this.stepForm.valid) {
      Object.assign(this.step, this.stepForm.getRawValue());
      this.activeModal.close(this.step);
    } else {
      this.$toaster.show('error', 'Invalid Form', 'Invalid format or missing requited fields');
    }
  }

}
