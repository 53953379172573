<form [formGroup]="productForm" (ngSubmit)="addProduct()">
  <button type="button" class="close clickable" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-header d-flex justify-content-between" *ngIf="companyType === 'customer'">
    <span class="modal-title d-block text-center" [style.padding-left.px]=60>NEW PRODUCT</span>
    <div [style.max-width.px]=110 class="pull-right">
      <mat-form-field>
        <mat-label>Product to</mat-label>
        <mat-select [(value)]="role">
            <mat-option [value]="'customer'">Buy</mat-option>
            <mat-option [value]="'supplier'">Sell</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="modal-header" *ngIf="companyType === 'supplier'">
    <span class="modal-title d-block text-center mb-4">NEW PRODUCT</span>
  </div>

  <div class="modal-body pt-0">

    <div class="row">
      <div class="col">
        <div class="alert alert-warning" role="alert" *ngIf="copiedProduct" [style.font-size.px]=12>
          <i class="fa fa-warning"></i>
          You're making a copy of the product "{{copiedProduct.name}}". Files and Steps to Produce will be copied from the previous product and you will be able to review them after creating the copy.
        </div>
        <mat-form-field class="name-field">
          <input type="text" matInput placeholder="Product name" formControlName="name" required/>
          <mat-error *ngIf="productForm && productForm.controls?.name?.errors?.forbiddenName">
            You cannot use the old product name
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col" *ngIf="role === 'customer'">
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Customer Part Identifier"
            formControlName="customer_part_identifier"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field>
          <textarea row="5" matInput placeholder="Description" formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-4">
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Product Barcode"
            formControlName="barcode"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="col-4">

        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Case Barcode"
            formControlName="case_barcode"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="col-4">

        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Master Pack Barcode"
            formControlName="master_pack_barcode"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-select
            placeholder="Currency"
            formControlName="currency">
            <mat-option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-form-field>
          <input
            type="number"
            matInput
            placeholder="Product Price"
            formControlName="unit_price"
          />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <input
            type="number"
            matInput
            placeholder="Price per case"
            formControlName="case_price"
          />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <input
            type="number"
            matInput
            placeholder="Master Pack Price"
            formControlName="master_pack_price"
          />
        </mat-form-field>
      </div>


    </div>

    <!-- Product Dimensions -->
    <div>
      <ngx-title-divider title="Measures"></ngx-title-divider>

      <div class="row">
        <div class="col">
          <ng-select
            (change)="weightUnitChange($event)"
            placeholder="Weight Measurement Unit"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            formControlName="unit_weight_mu_id"
            [virtualScroll]="true"
            [items]="weightUnits">
          </ng-select>
        </div>
        <div class="col">
          <ng-select
            (change)="lengthUnitChange($event)"
            placeholder="Length Measurement Unit"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            formControlName="unit_length_mu_id"
            [virtualScroll]="true"
            [items]="lengthUnits">
          </ng-select>
        </div>
      </div>
      <fieldset class="mt-2">
        <legend>Unit Measures</legend>
        <div class="row">
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Unit Length"
                formControlName="unit_length"
                [min]="0"
                (keypress)="productForm && productForm.controls.unit_length.markAsTouched()"
                autocomplete="off"
              />
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
              <mat-error *ngIf="productForm && productForm.controls.unit_length.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Unit Height"
                formControlName="unit_height"
                [min]="0"
                (keypress)="productForm && productForm.controls.unit_height.markAsTouched()"
                autocomplete="off"
              />
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
              <mat-error *ngIf="productForm && productForm.controls.unit_height.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Unit Width"
                formControlName="unit_width"
                [min]="0"
                (keypress)="productForm && productForm.controls.unit_width.markAsTouched()"
                autocomplete="off"
              />
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
              <mat-error *ngIf="productForm && productForm.controls.unit_width.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Unit Weight"
                formControlName="unit_weight"
                [min]="0"
                (keypress)="productForm && productForm.controls.unit_weight.markAsTouched()"
                autocomplete="off"
              />
              <span matSuffix>{{selectedWeightUnit?.alias}}</span>
              <mat-error *ngIf="productForm && productForm.controls.unit_weight.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
      <fieldset class="mt-2">
        <legend>Case Measures</legend>
        <div class="row">
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Case Length"
                formControlName="case_length"
                [min]="0"
                (keypress)="productForm && productForm.controls.case_length.markAsTouched()"
                autocomplete="off"
              />
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
              <mat-error *ngIf="productForm && productForm.controls.case_length.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Case Height"
                formControlName="case_height"
                [min]="0"
                (keypress)="productForm && productForm.controls.case_height.markAsTouched()"
                autocomplete="off"
              />
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
              <mat-error *ngIf="productForm && productForm.controls.case_height.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Case Width"
                formControlName="case_width"
                [min]="0"
                (keypress)="productForm && productForm.controls.case_width.markAsTouched()"
                autocomplete="off"
              />
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
              <mat-error *ngIf="productForm && productForm.controls.case_width.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Case Weight"
                formControlName="case_weight"
                [min]="0"
                (keypress)="productForm && productForm.controls.case_weight.markAsTouched()"
                autocomplete="off"
              />
              <span matSuffix>{{selectedWeightUnit?.alias}}</span>
              <mat-error *ngIf="productForm && productForm.controls.case_weight.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                placeholder="Units per Case"
                formControlName="units_per_case"
                [min]="0"
                (keypress)="productForm && productForm.controls.units_per_case.markAsTouched()"
                autocomplete="off"
              />
              <mat-error *ngIf="productForm && productForm.controls.units_per_case.errors?.min">
                Negative value not allowed
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
      <fieldset class="mt-2">
        <legend>Master Pack Measures</legend>
        <div class="row">
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input type="number" matInput
                     [min]="0"
                     placeholder="Master Pack Length"
                     formControlName="master_pack_length">
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input type="number" matInput
                     [min]="0"
                     placeholder="Master Pack Height"
                     formControlName="master_pack_height">
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input type="number" matInput
                     [min]="0"
                     placeholder="Master Pack Width"
                     formControlName="master_pack_width">
              <span matSuffix>{{selectedLengthUnit?.alias}}</span>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-lg-4">
            <mat-form-field>
              <input
                type="number"
                matInput
                [min]="0"
                placeholder="Cases in Master Pack"
                formControlName="cases_per_master_pack"
              />
            </mat-form-field>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="mb-3"
         *ngIf="role !== 'customer'">
      <span class="modal-title">Customer Identifiers *</span>

      <div class="form-row" [formGroup]="customerPartIdentifierForm">
        <div class="col">
          <mat-form-field>
            <mat-select
              [required]="!productForm && productForm.controls.allowed_customers?.controls?.length"
              formControlName="customer_id" placeholder="Customer">
              <mat-option *ngFor="let customer of allowedCustomers" [value]="customer.id">
                {{ customer.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field>
            <input
              type="text"
              matInput
              placeholder="Customer Part Identifier"
              formControlName="customer_part_identifier"
              [required]="!productForm && productForm.controls.allowed_customers?.controls?.length"
            />
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <button type="button"
                  [disabled]="customerPartIdentifierForm.invalid"
                  (click)="addCustomerPartIdentifier()"
                  class="btn btn-dark">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <div formArrayName="allowed_customers">
        <div class="form-row"
             [formGroup]="customerPart"
             *ngFor="let customerPart of productForm && productForm.controls.allowed_customers?.controls; let i = index">
          <div class="col">
            <mat-form-field>
              <mat-select placeholder="Customer" formControlName="customer_id">
                <mat-option *ngFor="let customer of customers" [value]="customer.id">
                  {{ customer.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="Customer Part Identifier"
                formControlName="customer_part_identifier"
              />
            </mat-form-field>
          </div>
          <div class="col-sm-2">
            <a type="button" class="btn btn-link text-danger p-0" (click)="removeCustomerPartIdentifier(i)">
              <i class="fa fa-times fa-2x pt-2"></i>
            </a>
          </div>
        </div>
      </div>

    </div>

    <div class="my-3 text-center">
      <label class="clickable text-success">
        <i class="fa fa-paperclip"></i>
        {{ !fileList ? 'Attach the' : 'Change the' }} designs of your product
        <input type="file"
               (change)="attachFiles($event)"
               style="display:none;"
               multiple/>
      </label>
    </div>

    <div class="mb-3" *ngIf="fileList">
      <ng-container *ngFor="let key of objectKeys(fileList); let last = last">
        {{ fileList[key].name + (last ? '' : ', ') }}
      </ng-container>
    </div>

    <button
      type="submit"
      class="btn btn-primary m-auto d-block"
      [disabled]="productForm && productForm.invalid ||
      (role === 'supplier' &&
      productForm && productForm.controls?.allowed_customers?.value?.length === 0)">
      CREATE
    </button>
  </div>

</form>
