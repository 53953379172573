/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOrganizationCollection } from '../models/lasgro-organization-collection';
import { LasgroOrganization } from '../models/lasgro-organization';
import { LasgroOrganization_document } from '../models/lasgro-organization-_document';
@Injectable({
  providedIn: 'root',
})
class CustomerService extends __BaseService {
  static readonly customerList1Path = '/customers';
  static readonly customerShow1Path = '/customers/{customerID}';
  static readonly customerAddFile1Path = '/customers/{customerID}/files';
  static readonly customerShowFile1Path = '/customers/{customerID}/files/{fileID}';
  static readonly customerDeleteFile1Path = '/customers/{customerID}/files/{fileID}';
  static readonly customerUpdateFileDetails1Path = '/customers/{customerID}/files/{fileID}';
  static readonly customerListPath = '/organizations/{organizationID}/customers';
  static readonly customerShowPath = '/organizations/{organizationID}/customers/{customerID}';
  static readonly customerAddFilePath = '/organizations/{organizationID}/customers/{customerID}/files';
  static readonly customerShowFilePath = '/organizations/{organizationID}/customers/{customerID}/files/{fileID}';
  static readonly customerDeleteFilePath = '/organizations/{organizationID}/customers/{customerID}/files/{fileID}';
  static readonly customerUpdateFileDetailsPath = '/organizations/{organizationID}/customers/{customerID}/files/{fileID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list customer
   *
   * List all customers of a given organization - in other words, all the organizations who have ordered parts from us (the logged in organization). Only Managers and Supply Chain Leads can see a organization's customers.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  customerList1Response(organizationID?: string): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (organizationID != null) __params = __params.set('organizationID', organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * list customer
   *
   * List all customers of a given organization - in other words, all the organizations who have ordered parts from us (the logged in organization). Only Managers and Supply Chain Leads can see a organization's customers.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  customerList1(organizationID?: string): __Observable<LasgroOrganizationCollection> {
    return this.customerList1Response(organizationID).pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * show customer
   *
   * Retrieve the customer with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerShow1Params` containing the following parameters:
   *
   * - `customerID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerShow1Response(params: CustomerService.CustomerShow1Params): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customers/${encodeURIComponent(String(params.customerID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show customer
   *
   * Retrieve the customer with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerShow1Params` containing the following parameters:
   *
   * - `customerID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerShow1(params: CustomerService.CustomerShow1Params): __Observable<LasgroOrganization> {
    return this.customerShow1Response(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * add_file customer
   *
   * Retrieve a customer with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerAddFile1Params` containing the following parameters:
   *
   * - `file`: uploaded file
   *
   * - `customerID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `organizationID`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   *
   * - `isInsuranceFile`:
   *
   * - `expiresAt`:
   *
   * - `customerRequiredFileID`:
   *
   * - `customerLeadID`:
   */
  customerAddFile1Response(params: CustomerService.CustomerAddFile1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.supplierLeadID != null) __params = __params.set('supplierLeadID', params.supplierLeadID.toString());
    if (params.stdLeadTimeMonths != null) __params = __params.set('stdLeadTimeMonths', params.stdLeadTimeMonths.toString());
    if (params.stdLeadTimeDays != null) __params = __params.set('stdLeadTimeDays', params.stdLeadTimeDays.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.metadata != null) __params = __params.set('metadata', params.metadata.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.isInsuranceFile != null) __params = __params.set('isInsuranceFile', params.isInsuranceFile.toString());
    if (params.expiresAt != null) __params = __params.set('expiresAt', params.expiresAt.toString());
    if (params.customerRequiredFileID != null) __params = __params.set('customerRequiredFileID', params.customerRequiredFileID.toString());
    if (params.customerLeadID != null) __params = __params.set('customerLeadID', params.customerLeadID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customers/${encodeURIComponent(String(params.customerID))}/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_file customer
   *
   * Retrieve a customer with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerAddFile1Params` containing the following parameters:
   *
   * - `file`: uploaded file
   *
   * - `customerID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `organizationID`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   *
   * - `isInsuranceFile`:
   *
   * - `expiresAt`:
   *
   * - `customerRequiredFileID`:
   *
   * - `customerLeadID`:
   */
  customerAddFile1(params: CustomerService.CustomerAddFile1Params): __Observable<null> {
    return this.customerAddFile1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show_file customer
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerShowFile1Params` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerShowFile1Response(params: CustomerService.CustomerShowFile1Params): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customers/${encodeURIComponent(String(params.customerID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show_file customer
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerShowFile1Params` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customerShowFile1(params: CustomerService.CustomerShowFile1Params): __Observable<LasgroOrganization> {
    return this.customerShowFile1Response(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * delete_file customer
   *
   * Delete a client organization's file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerDeleteFile1Params` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * - `organizationID`:
   */
  customerDeleteFile1Response(params: CustomerService.CustomerDeleteFile1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/customers/${encodeURIComponent(String(params.customerID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_file customer
   *
   * Delete a client organization's file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerDeleteFile1Params` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * - `organizationID`:
   */
  customerDeleteFile1(params: CustomerService.CustomerDeleteFile1Params): __Observable<null> {
    return this.customerDeleteFile1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update_file_details customer
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerUpdateFileDetails1Params` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `organizationID`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   *
   * @return OK
   */
  customerUpdateFileDetails1Response(params: CustomerService.CustomerUpdateFileDetails1Params): __Observable<__StrictHttpResponse<LasgroOrganization_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.supplierLeadID != null) __params = __params.set('supplierLeadID', params.supplierLeadID.toString());
    if (params.stdLeadTimeMonths != null) __params = __params.set('stdLeadTimeMonths', params.stdLeadTimeMonths.toString());
    if (params.stdLeadTimeDays != null) __params = __params.set('stdLeadTimeDays', params.stdLeadTimeDays.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.metadata != null) __params = __params.set('metadata', params.metadata.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.expiresAt != null) __params = __params.set('expiresAt', params.expiresAt.toString());
    if (params.customerLeadID != null) __params = __params.set('customerLeadID', params.customerLeadID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/customers/${encodeURIComponent(String(params.customerID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_document>;
      })
    );
  }
  /**
   * update_file_details customer
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerUpdateFileDetails1Params` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `organizationID`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   *
   * @return OK
   */
  customerUpdateFileDetails1(params: CustomerService.CustomerUpdateFileDetails1Params): __Observable<LasgroOrganization_document> {
    return this.customerUpdateFileDetails1Response(params).pipe(
      __map(_r => _r.body as LasgroOrganization_document)
    );
  }

  /**
   * list customer
   *
   * List all customers of a given organization - in other words, all the organizations who have ordered parts from us (the logged in organization). Only Managers and Supply Chain Leads can see a organization's customers.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  customerListResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/customers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * list customer
   *
   * List all customers of a given organization - in other words, all the organizations who have ordered parts from us (the logged in organization). Only Managers and Supply Chain Leads can see a organization's customers.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  customerList(organizationID: string): __Observable<LasgroOrganizationCollection> {
    return this.customerListResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * show customer
   *
   * Retrieve the customer with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerShowParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `customerID`:
   *
   * @return OK
   */
  customerShowResponse(params: CustomerService.CustomerShowParams): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/customers/${encodeURIComponent(String(params.customerID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show customer
   *
   * Retrieve the customer with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerShowParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `customerID`:
   *
   * @return OK
   */
  customerShow(params: CustomerService.CustomerShowParams): __Observable<LasgroOrganization> {
    return this.customerShowResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * add_file customer
   *
   * Retrieve a customer with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerAddFileParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `customerID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   *
   * - `isInsuranceFile`:
   *
   * - `expiresAt`:
   *
   * - `customerRequiredFileID`:
   *
   * - `customerLeadID`:
   */
  customerAddFileResponse(params: CustomerService.CustomerAddFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.supplierLeadID != null) __params = __params.set('supplierLeadID', params.supplierLeadID.toString());
    if (params.stdLeadTimeMonths != null) __params = __params.set('stdLeadTimeMonths', params.stdLeadTimeMonths.toString());
    if (params.stdLeadTimeDays != null) __params = __params.set('stdLeadTimeDays', params.stdLeadTimeDays.toString());
    if (params.metadata != null) __params = __params.set('metadata', params.metadata.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.isInsuranceFile != null) __params = __params.set('isInsuranceFile', params.isInsuranceFile.toString());
    if (params.expiresAt != null) __params = __params.set('expiresAt', params.expiresAt.toString());
    if (params.customerRequiredFileID != null) __params = __params.set('customerRequiredFileID', params.customerRequiredFileID.toString());
    if (params.customerLeadID != null) __params = __params.set('customerLeadID', params.customerLeadID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/customers/${encodeURIComponent(String(params.customerID))}/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_file customer
   *
   * Retrieve a customer with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerAddFileParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `customerID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   *
   * - `isInsuranceFile`:
   *
   * - `expiresAt`:
   *
   * - `customerRequiredFileID`:
   *
   * - `customerLeadID`:
   */
  customerAddFile(params: CustomerService.CustomerAddFileParams): __Observable<null> {
    return this.customerAddFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show_file customer
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerShowFileParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * @return OK
   */
  customerShowFileResponse(params: CustomerService.CustomerShowFileParams): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/customers/${encodeURIComponent(String(params.customerID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show_file customer
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerShowFileParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * @return OK
   */
  customerShowFile(params: CustomerService.CustomerShowFileParams): __Observable<LasgroOrganization> {
    return this.customerShowFileResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * delete_file customer
   *
   * Delete a client organization's file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerDeleteFileParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `customerID`:
   */
  customerDeleteFileResponse(params: CustomerService.CustomerDeleteFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/customers/${encodeURIComponent(String(params.customerID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_file customer
   *
   * Delete a client organization's file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerDeleteFileParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `customerID`:
   */
  customerDeleteFile(params: CustomerService.CustomerDeleteFileParams): __Observable<null> {
    return this.customerDeleteFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update_file_details customer
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerUpdateFileDetailsParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   *
   * @return OK
   */
  customerUpdateFileDetailsResponse(params: CustomerService.CustomerUpdateFileDetailsParams): __Observable<__StrictHttpResponse<LasgroOrganization_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.supplierLeadID != null) __params = __params.set('supplierLeadID', params.supplierLeadID.toString());
    if (params.stdLeadTimeMonths != null) __params = __params.set('stdLeadTimeMonths', params.stdLeadTimeMonths.toString());
    if (params.stdLeadTimeDays != null) __params = __params.set('stdLeadTimeDays', params.stdLeadTimeDays.toString());
    if (params.metadata != null) __params = __params.set('metadata', params.metadata.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.expiresAt != null) __params = __params.set('expiresAt', params.expiresAt.toString());
    if (params.customerLeadID != null) __params = __params.set('customerLeadID', params.customerLeadID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/customers/${encodeURIComponent(String(params.customerID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_document>;
      })
    );
  }
  /**
   * update_file_details customer
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomerService.CustomerUpdateFileDetailsParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `customerID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   *
   * @return OK
   */
  customerUpdateFileDetails(params: CustomerService.CustomerUpdateFileDetailsParams): __Observable<LasgroOrganization_document> {
    return this.customerUpdateFileDetailsResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_document)
    );
  }
}

module CustomerService {

  /**
   * Parameters for customerShow1
   */
  export interface CustomerShow1Params {
    customerID: string;
    organizationID?: string;
  }

  /**
   * Parameters for customerAddFile1
   */
  export interface CustomerAddFile1Params {

    /**
     * uploaded file
     */
    file: Blob;
    customerID: string;
    supplierLeadID?: string;
    stdLeadTimeMonths?: number;
    stdLeadTimeDays?: number;
    organizationID?: string;
    metadata?: string;
    isMainFile?: boolean;
    isInsuranceFile?: boolean;
    expiresAt?: string;
    customerRequiredFileID?: string;
    customerLeadID?: string;
  }

  /**
   * Parameters for customerShowFile1
   */
  export interface CustomerShowFile1Params {
    fileID: string;
    customerID: string;
    organizationID?: string;
  }

  /**
   * Parameters for customerDeleteFile1
   */
  export interface CustomerDeleteFile1Params {
    fileID: string;
    customerID: string;
    organizationID?: string;
  }

  /**
   * Parameters for customerUpdateFileDetails1
   */
  export interface CustomerUpdateFileDetails1Params {
    fileID: string;
    customerID: string;
    supplierLeadID?: string;
    stdLeadTimeMonths?: number;
    stdLeadTimeDays?: number;
    organizationID?: string;
    metadata?: string;
    isMainFile?: boolean;
    expiresAt?: string;
    customerLeadID?: string;
  }

  /**
   * Parameters for customerShow
   */
  export interface CustomerShowParams {
    organizationID: string;
    customerID: string;
  }

  /**
   * Parameters for customerAddFile
   */
  export interface CustomerAddFileParams {
    organizationID: string;

    /**
     * uploaded file
     */
    file: Blob;
    customerID: string;
    supplierLeadID?: string;
    stdLeadTimeMonths?: number;
    stdLeadTimeDays?: number;
    metadata?: string;
    isMainFile?: boolean;
    isInsuranceFile?: boolean;
    expiresAt?: string;
    customerRequiredFileID?: string;
    customerLeadID?: string;
  }

  /**
   * Parameters for customerShowFile
   */
  export interface CustomerShowFileParams {
    organizationID: string;
    fileID: string;
    customerID: string;
  }

  /**
   * Parameters for customerDeleteFile
   */
  export interface CustomerDeleteFileParams {
    organizationID: string;
    fileID: string;
    customerID: string;
  }

  /**
   * Parameters for customerUpdateFileDetails
   */
  export interface CustomerUpdateFileDetailsParams {
    organizationID: string;
    fileID: string;
    customerID: string;
    supplierLeadID?: string;
    stdLeadTimeMonths?: number;
    stdLeadTimeDays?: number;
    metadata?: string;
    isMainFile?: boolean;
    expiresAt?: string;
    customerLeadID?: string;
  }
}

export { CustomerService }
