import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  CustomerSearch,
  ProductSearch,
  SupplierSearch,
  UserSearch,
  WorkCommissionedSearch,
  WorkInProgressSearch,
} from '@models/search.model';

import CONFIG from '@app/app.config';
import { ContractDto } from '@app/models';

@Injectable()
export class SearchService {
  constructor(private http: HttpClient) {}

  /**
   * Search for users matching the provided information.
   * @param data User data to be searched in the database
   */
  searchUsers(data: UserSearch, globalSearch?: string): Promise<any> {
    const baseUrl = `${ CONFIG.API }/search/users`;
    const endpoint = data ? `${ baseUrl }?${ data.getSearchString() }` : `${ baseUrl }?global=${ globalSearch }`;
    return new Promise<any>((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  /**
   * Search for customers matching the provided information.
   * @param data: Customer data to be searched in the database
   */
  searchCustomers(data: CustomerSearch, globalSearch?: string): Promise<any> {
    const baseUrl = `${ CONFIG.API }/search/customers`;
    const endpoint = data ? `${ baseUrl }?${ data.getSearchString() }` : `${ baseUrl }?global=${ globalSearch }`;
    return new Promise<any>((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  /**
   * Search for suppliers matching the provided information.
   * @param data Supplier data to be searched in the database
   */
  searchSuppliers(data: SupplierSearch, globalSearch?: string): Promise<any> {
    const baseUrl = `${ CONFIG.API }/search/suppliers`;
    const endpoint = data ? `${ baseUrl }?${ data.getSearchString() }` : `${ baseUrl }?global=${ globalSearch }`;
    return new Promise<any>((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  /**
   * Search for products matching the provided information.
   * @param data Product data to be searched in the database
   */
  searchProducts(data: ProductSearch, globalSearch?: string): Promise<any> {
    const baseUrl = `${ CONFIG.API }/search/products`;
    const endpoint = data ? `${ baseUrl }?${ data.getSearchString() }` : `${ baseUrl }?global=${ globalSearch }`;
    return new Promise<any>((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  /**
   * Search for work in progress data matching the provided information.
   * @param data Work in progress data to be searched in the database
   */
  searchWorkInProgress(data: WorkInProgressSearch, globalSearch?: string) {
    // const endpoint = `${ CONFIG.API }/search/purchase_orders?${ data.getSearchString() }`
    const baseUrl = `${ CONFIG.API }/search/purchase_orders`;
    const params = new HttpParams({
      fromString: data.getSearchString(),
    });

    if (globalSearch) params.append('global', globalSearch);
    params.append('viewType', 'report');
    return this.http.get<ContractDto[]>(baseUrl, { params });
  }

  searchWorkCommissioned(data: WorkCommissionedSearch, globalSearch?: string) {
    const baseUrl = `${ CONFIG.API }/search/commissioned_po`;
    let params = new HttpParams({
      fromString: data.getSearchString(),
    });

    if (globalSearch) params = params.append('global', globalSearch);
    params = params.append('viewType', 'report');

    return this.http.get<ContractDto[]>(baseUrl, { params });
  }

  searchPurchaseOrders(query: string, isCustomer: boolean) {
    const baseUrl = `${ CONFIG.API }/search/` + (isCustomer ? 'commissioned_po' : 'purchase_orders');
    const endpoint = `${ baseUrl }?${ query }&viewType=report`;
    return this.http.get<any>(endpoint);
  }

}
