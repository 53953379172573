/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroGeneral_success_id_payload } from '../models/lasgro-general-_success-_id-_payload';
import { TaskInstanceInputPayload } from '../models/task-instance-input-payload';
import { LasgroTask_instance } from '../models/lasgro-task-_instance';
import { LasgroTask_instanceCollection } from '../models/lasgro-task-_instance-collection';
@Injectable({
  providedIn: 'root',
})
class TaskInstanceService extends __BaseService {
  static readonly taskInstanceUpdatePath = '/organizations/{organizationID}/workflows/instances/{workflowInstanceID}/tasks/{taskID}/instances/{taskInstanceID}';
  static readonly taskInstanceAddPath = '/organizations/{organizationID}/workflows/{workflowID}/instances/{workflowInstanceID}/tasks/{taskID}/instances';
  static readonly taskInstanceShowPath = '/tasks/{taskID}/instances/{taskInstanceID}';
  static readonly taskInstanceListPath = '/workflows/{workflowID}/instances/{workflowInstanceID}/instances';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update task_instance
   *
   * Updates a task instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskInstanceService.TaskInstanceUpdateParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `payload`: Input for a task instance payload
   *
   * - `organizationID`:
   *
   * @return OK
   */
  taskInstanceUpdateResponse(params: TaskInstanceService.TaskInstanceUpdateParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/instances/${encodeURIComponent(String(params.workflowInstanceID))}/tasks/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * update task_instance
   *
   * Updates a task instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskInstanceService.TaskInstanceUpdateParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `payload`: Input for a task instance payload
   *
   * - `organizationID`:
   *
   * @return OK
   */
  taskInstanceUpdate(params: TaskInstanceService.TaskInstanceUpdateParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.taskInstanceUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * add task_instance
   *
   * Creates a task instance for the workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskInstanceService.TaskInstanceAddParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskID`:
   *
   * - `payload`: Input for a task instance payload
   *
   * - `organizationID`:
   *
   * @return Created
   */
  taskInstanceAddResponse(params: TaskInstanceService.TaskInstanceAddParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}/instances/${encodeURIComponent(String(params.workflowInstanceID))}/tasks/${encodeURIComponent(String(params.taskID))}/instances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * add task_instance
   *
   * Creates a task instance for the workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskInstanceService.TaskInstanceAddParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskID`:
   *
   * - `payload`: Input for a task instance payload
   *
   * - `organizationID`:
   *
   * @return Created
   */
  taskInstanceAdd(params: TaskInstanceService.TaskInstanceAddParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.taskInstanceAddResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * show task_instance
   *
   * Details of a task instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskInstanceService.TaskInstanceShowParams` containing the following parameters:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * @return OK
   */
  taskInstanceShowResponse(params: TaskInstanceService.TaskInstanceShowParams): __Observable<__StrictHttpResponse<LasgroTask_instance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tasks/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_instance>;
      })
    );
  }
  /**
   * show task_instance
   *
   * Details of a task instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskInstanceService.TaskInstanceShowParams` containing the following parameters:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * @return OK
   */
  taskInstanceShow(params: TaskInstanceService.TaskInstanceShowParams): __Observable<LasgroTask_instance> {
    return this.taskInstanceShowResponse(params).pipe(
      __map(_r => _r.body as LasgroTask_instance)
    );
  }

  /**
   * list task_instance
   *
   * List all of the task instances of a workflow instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskInstanceService.TaskInstanceListParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * @return OK
   */
  taskInstanceListResponse(params: TaskInstanceService.TaskInstanceListParams): __Observable<__StrictHttpResponse<LasgroTask_instanceCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows/${encodeURIComponent(String(params.workflowID))}/instances/${encodeURIComponent(String(params.workflowInstanceID))}/instances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_instanceCollection>;
      })
    );
  }
  /**
   * list task_instance
   *
   * List all of the task instances of a workflow instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskInstanceService.TaskInstanceListParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * @return OK
   */
  taskInstanceList(params: TaskInstanceService.TaskInstanceListParams): __Observable<LasgroTask_instanceCollection> {
    return this.taskInstanceListResponse(params).pipe(
      __map(_r => _r.body as LasgroTask_instanceCollection)
    );
  }
}

module TaskInstanceService {

  /**
   * Parameters for taskInstanceUpdate
   */
  export interface TaskInstanceUpdateParams {
    workflowInstanceID: string;
    taskInstanceID: string;
    taskID: string;

    /**
     * Input for a task instance payload
     */
    payload: TaskInstanceInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for taskInstanceAdd
   */
  export interface TaskInstanceAddParams {
    workflowInstanceID: string;
    workflowID: string;
    taskID: string;

    /**
     * Input for a task instance payload
     */
    payload: TaskInstanceInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for taskInstanceShow
   */
  export interface TaskInstanceShowParams {
    taskInstanceID: string;
    taskID: string;
  }

  /**
   * Parameters for taskInstanceList
   */
  export interface TaskInstanceListParams {
    workflowInstanceID: string;
    workflowID: string;
  }
}

export { TaskInstanceService }
