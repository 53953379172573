/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFile_infoCollection } from '../models/lasgro-file-_info-collection';
@Injectable({
  providedIn: 'root',
})
class ChecklistTaskFilesService extends __BaseService {
  static readonly checklistTaskFilesShowPath = '/checklist_task_files/{fileID}';
  static readonly checklistTaskFilesUpdatePath = '/checklist_task_files/{fileID}';
  static readonly checklistTaskFilesDeletePath = '/checklist_task_files/{fileID}';
  static readonly checklistTaskFilesListPath = '/checklist_tasks/{taskID}/checklist_task_files';
  static readonly checklistTaskFilesAddPath = '/checklist_tasks/{taskID}/checklist_task_files';
  static readonly checklistTaskFilesList1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks/{taskID}/checklist_task_files';
  static readonly checklistTaskFilesAdd1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks/{taskID}/checklist_task_files';
  static readonly checklistTaskFilesShow1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks/{taskID}/checklist_task_files/{fileID}';
  static readonly checklistTaskFilesUpdate1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks/{taskID}/checklist_task_files/{fileID}';
  static readonly checklistTaskFilesDelete1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_tasks/{taskID}/checklist_task_files/{fileID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * show checklist_task_files
   *
   * Download checklist task file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesShowParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTaskFilesShowResponse(params: ChecklistTaskFilesService.ChecklistTaskFilesShowParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.taskID != null) __params = __params.set('taskID', params.taskID.toString());
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checklist_task_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * show checklist_task_files
   *
   * Download checklist task file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesShowParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTaskFilesShow(params: ChecklistTaskFilesService.ChecklistTaskFilesShowParams): __Observable<null> {
    return this.checklistTaskFilesShowResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update checklist_task_files
   *
   * Replace checklist task file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesUpdateParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `file`: uploaded file
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTaskFilesUpdateResponse(params: ChecklistTaskFilesService.ChecklistTaskFilesUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.taskID != null) __params = __params.set('taskID', params.taskID.toString());
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/checklist_task_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update checklist_task_files
   *
   * Replace checklist task file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesUpdateParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `file`: uploaded file
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTaskFilesUpdate(params: ChecklistTaskFilesService.ChecklistTaskFilesUpdateParams): __Observable<null> {
    return this.checklistTaskFilesUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete checklist_task_files
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesDeleteParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTaskFilesDeleteResponse(params: ChecklistTaskFilesService.ChecklistTaskFilesDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.taskID != null) __params = __params.set('taskID', params.taskID.toString());
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/checklist_task_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete checklist_task_files
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesDeleteParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTaskFilesDelete(params: ChecklistTaskFilesService.ChecklistTaskFilesDeleteParams): __Observable<null> {
    return this.checklistTaskFilesDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list checklist_task_files
   *
   * List all checklist task's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesListParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * @return OK
   */
  checklistTaskFilesListResponse(params: ChecklistTaskFilesService.ChecklistTaskFilesListParams): __Observable<__StrictHttpResponse<LasgroFile_infoCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checklist_tasks/${encodeURIComponent(String(params.taskID))}/checklist_task_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_infoCollection>;
      })
    );
  }
  /**
   * list checklist_task_files
   *
   * List all checklist task's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesListParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * @return OK
   */
  checklistTaskFilesList(params: ChecklistTaskFilesService.ChecklistTaskFilesListParams): __Observable<LasgroFile_infoCollection> {
    return this.checklistTaskFilesListResponse(params).pipe(
      __map(_r => _r.body as LasgroFile_infoCollection)
    );
  }

  /**
   * add checklist_task_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesAddParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `file`: uploaded file
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTaskFilesAddResponse(params: ChecklistTaskFilesService.ChecklistTaskFilesAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/checklist_tasks/${encodeURIComponent(String(params.taskID))}/checklist_task_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add checklist_task_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesAddParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `file`: uploaded file
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistTaskFilesAdd(params: ChecklistTaskFilesService.ChecklistTaskFilesAddParams): __Observable<null> {
    return this.checklistTaskFilesAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list checklist_task_files
   *
   * List all checklist task's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesList1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistTaskFilesList1Response(params: ChecklistTaskFilesService.ChecklistTaskFilesList1Params): __Observable<__StrictHttpResponse<LasgroFile_infoCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks/${encodeURIComponent(String(params.taskID))}/checklist_task_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_infoCollection>;
      })
    );
  }
  /**
   * list checklist_task_files
   *
   * List all checklist task's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesList1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistTaskFilesList1(params: ChecklistTaskFilesService.ChecklistTaskFilesList1Params): __Observable<LasgroFile_infoCollection> {
    return this.checklistTaskFilesList1Response(params).pipe(
      __map(_r => _r.body as LasgroFile_infoCollection)
    );
  }

  /**
   * add checklist_task_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesAdd1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTaskFilesAdd1Response(params: ChecklistTaskFilesService.ChecklistTaskFilesAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;



    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks/${encodeURIComponent(String(params.taskID))}/checklist_task_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add checklist_task_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesAdd1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTaskFilesAdd1(params: ChecklistTaskFilesService.ChecklistTaskFilesAdd1Params): __Observable<null> {
    return this.checklistTaskFilesAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show checklist_task_files
   *
   * Download checklist task file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesShow1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTaskFilesShow1Response(params: ChecklistTaskFilesService.ChecklistTaskFilesShow1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks/${encodeURIComponent(String(params.taskID))}/checklist_task_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * show checklist_task_files
   *
   * Download checklist task file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesShow1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTaskFilesShow1(params: ChecklistTaskFilesService.ChecklistTaskFilesShow1Params): __Observable<null> {
    return this.checklistTaskFilesShow1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update checklist_task_files
   *
   * Replace checklist task file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesUpdate1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTaskFilesUpdate1Response(params: ChecklistTaskFilesService.ChecklistTaskFilesUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;




    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks/${encodeURIComponent(String(params.taskID))}/checklist_task_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update checklist_task_files
   *
   * Replace checklist task file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesUpdate1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTaskFilesUpdate1(params: ChecklistTaskFilesService.ChecklistTaskFilesUpdate1Params): __Observable<null> {
    return this.checklistTaskFilesUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete checklist_task_files
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesDelete1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTaskFilesDelete1Response(params: ChecklistTaskFilesService.ChecklistTaskFilesDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_tasks/${encodeURIComponent(String(params.taskID))}/checklist_task_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete checklist_task_files
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistTaskFilesService.ChecklistTaskFilesDelete1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistTaskFilesDelete1(params: ChecklistTaskFilesService.ChecklistTaskFilesDelete1Params): __Observable<null> {
    return this.checklistTaskFilesDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChecklistTaskFilesService {

  /**
   * Parameters for checklistTaskFilesShow
   */
  export interface ChecklistTaskFilesShowParams {
    fileID: string;
    taskID?: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistTaskFilesUpdate
   */
  export interface ChecklistTaskFilesUpdateParams {
    fileID: string;

    /**
     * uploaded file
     */
    file: Blob;
    taskID?: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistTaskFilesDelete
   */
  export interface ChecklistTaskFilesDeleteParams {
    fileID: string;
    taskID?: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistTaskFilesList
   */
  export interface ChecklistTaskFilesListParams {
    taskID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistTaskFilesAdd
   */
  export interface ChecklistTaskFilesAddParams {
    taskID: string;

    /**
     * uploaded file
     */
    file: Blob;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistTaskFilesList1
   */
  export interface ChecklistTaskFilesList1Params {
    taskID: string;
    purchaseOrderID: string;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistTaskFilesAdd1
   */
  export interface ChecklistTaskFilesAdd1Params {
    taskID: string;
    purchaseOrderID: string;
    organizationID: string;

    /**
     * uploaded file
     */
    file: Blob;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistTaskFilesShow1
   */
  export interface ChecklistTaskFilesShow1Params {
    taskID: string;
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistTaskFilesUpdate1
   */
  export interface ChecklistTaskFilesUpdate1Params {
    taskID: string;
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;

    /**
     * uploaded file
     */
    file: Blob;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistTaskFilesDelete1
   */
  export interface ChecklistTaskFilesDelete1Params {
    taskID: string;
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }
}

export { ChecklistTaskFilesService }
