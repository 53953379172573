/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOrganization_page_component } from '../models/lasgro-organization-_page-_component';
import { OrganizationPageComponentUpdatePayload } from '../models/organization-page-component-update-payload';
import { OrganizationPageComponentAddPayload } from '../models/organization-page-component-add-payload';
import { LasgroOrganization_page_componentCollection } from '../models/lasgro-organization-_page-_component-collection';
import { UpdateListOrganizationPageComponentsPayload } from '../models/update-list-organization-page-components-payload';
@Injectable({
  providedIn: 'root',
})
class OrganizationPageComponentsService extends __BaseService {
  static readonly organizationPageComponentsUpdatePath = '/organization_page_components';
  static readonly organizationPageComponentsAddPath = '/organization_page_components';
  static readonly organizationPageComponentsListPath = '/organization_page_components/organization_page/{organizationPageID}';
  static readonly organizationPageComponentsShowPath = '/organization_page_components/{organizationPageComponentID}';
  static readonly organizationPageComponentsDeletePath = '/organization_page_components/{organizationPageComponentID}';
  static readonly organizationPageComponentsUpdateListPath = '/organization_page_components/{organizationPageID}/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  organizationPageComponentsUpdateResponse(payload: OrganizationPageComponentUpdatePayload): __Observable<__StrictHttpResponse<LasgroOrganization_page_component>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization_page_components`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_page_component>;
      })
    );
  }
  /**
   * update organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  organizationPageComponentsUpdate(payload: OrganizationPageComponentUpdatePayload): __Observable<LasgroOrganization_page_component> {
    return this.organizationPageComponentsUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroOrganization_page_component)
    );
  }

  /**
   * add organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  organizationPageComponentsAddResponse(payload: OrganizationPageComponentAddPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organization_page_components`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  organizationPageComponentsAdd(payload: OrganizationPageComponentAddPayload): __Observable<null> {
    return this.organizationPageComponentsAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageID undefined
   * @return OK
   */
  organizationPageComponentsListResponse(organizationPageID: string): __Observable<__StrictHttpResponse<LasgroOrganization_page_componentCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization_page_components/organization_page/${encodeURIComponent(String(organizationPageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_page_componentCollection>;
      })
    );
  }
  /**
   * list organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageID undefined
   * @return OK
   */
  organizationPageComponentsList(organizationPageID: string): __Observable<LasgroOrganization_page_componentCollection> {
    return this.organizationPageComponentsListResponse(organizationPageID).pipe(
      __map(_r => _r.body as LasgroOrganization_page_componentCollection)
    );
  }

  /**
   * show organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageComponentID undefined
   * @return OK
   */
  organizationPageComponentsShowResponse(organizationPageComponentID: string): __Observable<__StrictHttpResponse<LasgroOrganization_page_component>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization_page_components/${encodeURIComponent(String(organizationPageComponentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_page_component>;
      })
    );
  }
  /**
   * show organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageComponentID undefined
   * @return OK
   */
  organizationPageComponentsShow(organizationPageComponentID: string): __Observable<LasgroOrganization_page_component> {
    return this.organizationPageComponentsShowResponse(organizationPageComponentID).pipe(
      __map(_r => _r.body as LasgroOrganization_page_component)
    );
  }

  /**
   * delete organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageComponentID undefined
   */
  organizationPageComponentsDeleteResponse(organizationPageComponentID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organization_page_components/${encodeURIComponent(String(organizationPageComponentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageComponentID undefined
   */
  organizationPageComponentsDelete(organizationPageComponentID: string): __Observable<null> {
    return this.organizationPageComponentsDeleteResponse(organizationPageComponentID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * UpdateList organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationPageComponentsService.OrganizationPageComponentsUpdateListParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationPageID`:
   */
  organizationPageComponentsUpdateListResponse(params: OrganizationPageComponentsService.OrganizationPageComponentsUpdateListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization_page_components/${encodeURIComponent(String(params.organizationPageID))}/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * UpdateList organization_page_components
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationPageComponentsService.OrganizationPageComponentsUpdateListParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationPageID`:
   */
  organizationPageComponentsUpdateList(params: OrganizationPageComponentsService.OrganizationPageComponentsUpdateListParams): __Observable<null> {
    return this.organizationPageComponentsUpdateListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module OrganizationPageComponentsService {

  /**
   * Parameters for organizationPageComponentsUpdateList
   */
  export interface OrganizationPageComponentsUpdateListParams {
    payload: UpdateListOrganizationPageComponentsPayload;
    organizationPageID: string;
  }
}

export { OrganizationPageComponentsService }
