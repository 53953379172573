<div class="container-fluid py-4 my-4" *ngIf="!isEDI && contract">
    <!-- NOTES -->
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <ngx-po-notes-form
          [allowEdit]="allowEdit"
          [unreadMessages]="contract?.unread_messages"
          [poID]="contract?.id"
          [customerID]="contract?.customer_id"
          [supplierID]="contract?.supplier_id"
          [notes]="contract?.messages"
          (notesChangedEvent)="handleNotesChange($event)"
          [isScrollingEnabled]="true"
        ></ngx-po-notes-form>
      </div>
    </div>
  </div>