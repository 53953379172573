import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '@app/app.config';
import { LineItem } from '@models/line-item.model';
import { Shipment, Reference } from '@models/shipment.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ShipmentService {
  constructor(private httpClient: HttpClient) {
  }

  getPurchaseOrderShipments(organizationId: string, purchaseOrderId: string): Observable<Shipment[]> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking`;
    return this.httpClient.get<Shipment[]>(endpoint)
      .pipe(
        map(result => result.map(item => new Shipment(item))));
  }

  addPurchaseOrderShipment(organizationId: string, purchaseOrderId: string, data: Shipment): Observable<any> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking`;
    return this.httpClient.post<Shipment[]>(endpoint, data);
  }

  updatePurchaseOrderShipment(organizationId: string,
                              purchaseOrderId: string,
                              trackingId: string,
                              data: Shipment): Observable<any> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking/${trackingId}`;
    return this.httpClient.put<Shipment[]>(endpoint, data);
  }

  deletePurchaseOrderShipment(purchaseOrderId: string, shipmentId: string) {
    const endpoint = `${CONFIG.API}/purchase_orders/${purchaseOrderId}/shipment_tracking/${shipmentId}`;
    return this.httpClient.delete(endpoint);
  }

  getShipmentByTrackingCode(organizationId: string, trackingCode: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/shipment_tracking/code/${encodeURIComponent(trackingCode)}`;
    console.log(endpoint);
    return this.httpClient.get<Shipment>(endpoint);
  }

  listShipments(organizationId: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/shipment_tracking`;
    return this.httpClient.get<Shipment[]>(endpoint);
  }

  getShipment(organizationId: string, shipmentId: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/shipment_tracking/${shipmentId}`;
    return this.httpClient.get<Shipment>(endpoint);
  }

  getShipmentPORelated(organizationId: string, purchaseOrderId: string, shipmentId: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking/${shipmentId}`;
    return this.httpClient.get<Shipment>(endpoint);
  }

  addItemsToShipment(organizationId: string, purchaseOrderId: string, shipmentId: string, items: (LineItem | any)[]) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking/${shipmentId}/add_items`;
    return this.httpClient.post(endpoint, { line_items: items });
  }

  deleteLineItemShipment(organizationId: string, purchaseOrderId: string, shipmentId: string, lineItemId: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking/${shipmentId}/tracking_line/${lineItemId}`;
    return this.httpClient.delete(endpoint);
  }

  addShipmentFile(organizationId: string, purchaseOrderId: string, shipmentId: string, file: File) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking/${shipmentId}/add_file`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post(endpoint, formData);
  }

  deleteShipmentFile(organizationId: string, purchaseOrderId: string, shipmentId: string, fileId: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking/${shipmentId}/files/${fileId}`;
    return this.httpClient.delete(endpoint);
  }

  getShipmentFile(organizationId: string, purchaseOrderId: string, shipmentId: string, fileId: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking/${shipmentId}/files/${fileId}`;
    return this.httpClient.get(endpoint, { responseType: 'blob' });
  }

  shipmentCarriers(organizationId: string, purchaseOrderId: string) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/purchase_orders/${purchaseOrderId}/shipment_tracking/carriers`;
    return this.httpClient.get<any[]>(endpoint);
  }

  // REFERENCES
  
  addReference(organizationId: string, shipmentId: string, reference: string): Observable<any> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/shipment_tracking/${shipmentId}/references`;
    return this.httpClient.post<any>(endpoint, {name: reference});
  }

  deleteReference(organizationId: string, shipmentId: string, referenceId: string): Observable<any> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/shipment_tracking/${shipmentId}/references/${referenceId}`;
    return this.httpClient.delete<any>(endpoint);
  }

  // UNICARGO INTEGRATION
  
  requestUnicargoQuote(data: any): Observable<any> {
    const endpoint = `${CONFIG.API}/shipment_pricing_estimator`;
    return this.httpClient.post<any>(endpoint, data);
  }

  requestUnicargoBooking(organizationId: string, data: any): Observable<any> {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/shipment_booking`;
    return this.httpClient.post<any>(endpoint, data);
  }
}
