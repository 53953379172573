import { Injectable } from '@angular/core';
import { SocketsConnectorService } from '@app/ion-sockets/services/sockets-connector.service';
import { SocketStream } from '@app/ion-sockets/socket-stream';
import { CompanyDto, ContractDto, UserDTO } from '@app/models';
import { LasgroOrganization } from 'api/models';
import { CustomerService, OrganizationService, PurchaseOrderService, SupplierService } from 'api/services';
import * as moment from 'moment';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractHelper {
  @LocalStorage() contractId;
  @LocalStorage() organizationId;
  public contractListent: SocketStream;
  contract: Subject<ContractDto> = new Subject<ContractDto>();
  supplier: Subject<CompanyDto> = new Subject<CompanyDto>();
  customer: Subject<CompanyDto> = new Subject<CompanyDto>();
  isContractLoading = false;
  constructor(
    private localStorageService: LocalStorageService,
    private socketsService: SocketsConnectorService,
    private contractService: PurchaseOrderService,
    private organizationService: OrganizationService,
    private supplierService: SupplierService,
    private customerService: CustomerService
  ) {
    this.contractListent = this.socketsService.createSocketStream(`po-detail:${this.contractId}`);
    this.contractListent.listen().subscribe((data) => {
      this.getContractData();
    });
    this.localStorageService.observe('contractId').subscribe((value) => {
      this.contractListent.sendMessage({ action: 'purchase_order_updated' });
    });
  }

  getContractData(requestAllData: boolean = true) {
    if (!this.isContractLoading) {
      this.isContractLoading = true;
      this.contractService
        .purchaseOrderShow({ purchaseOrderID: this.contractId, organizationID: this.organizationId })
        .subscribe((result: ContractDto) => {
          result.messages = result.messages?.reverse();
          if (moment.utc(result.cancel_date).year() === 1900) {
            delete result.cancel_date;
          }
          if (moment.utc(result.ship_date).year() === 1900) {
            delete result.ship_date;
          }

          this.contract.next(result);
          this.getSupplierData(result.customer_id, result.supplier_id);
          this.getCustomerData(result.supplier_id, result.customer_id);
          //   this.purchaseOrderChange.emit(result);
          //   if (requestAllData) {
          //     this.getAdditionalData();
          //   }
          this.isContractLoading = false;
        });
    }
  }

  getSupplierData(organizationID, supplierID) {
    this.supplierService
      .supplierShow({ organizationID, supplierID })
      .subscribe((supplier: CompanyDto) => {
        this.supplier.next(supplier);
      });
  }

  getCustomerData(organizationID, customerID) {
    this.customerService
      .customerShow({ organizationID, customerID })
      .subscribe((customer: CompanyDto) => {
        this.customer.next(customer);
      });
  }
}
