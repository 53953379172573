<form
  [formGroup]="taskForm"
  (ngSubmit)="save()"
  #newTaskForm
>
  <div class="row align-items-center">
    <div [ngClass]="{ 'col-4': !isSavedChecklist, 'col-12': isSavedChecklist }">
      <mat-form-field>
        <input type="text" matInput formControlName="name" placeholder="Task Name" required/>
      </mat-form-field>
    </div>
    <div [ngClass]="{ 'col-2': !isSavedChecklist, 'col-3': isSavedChecklist }">
        <ng-select
          ngEditableSelect
          labelForId="task_responsible_party_select"
          [items]="companyUsers"
          bindValue="id"
          bindLabel="fullName"
          placeholder="Responsible *"
          appendTo="body"
          required
          [clearable]="false"
          [minlength]="2"
          typeToSearchText="Please enter 2 or more characters"
          formControlName="responsible_party"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item.fullName }}
            </div>
          </ng-template>
        </ng-select>
    </div>
    <div [ngClass]="{ 'col-2': !isSavedChecklist, 'col-3': isSavedChecklist }">
      <mat-form-field *ngIf="!isSavedChecklist">
        <input matInput
               (click)="datePicker.open()"
               formControlName="due_date" [matDatepicker]="datePicker" required placeholder="Due Date"
               readonly/>
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="isSavedChecklist">
        <input matInput
               required
               type="number"
               min="0"
               placeholder="Due after (days)"
               (keypress)="blockCommaKey($event)"
               formControlName="due_date_unparsed"
        />
      </mat-form-field>
    </div>
    <div class="col-auto" *ngIf="!isSavedChecklist">
      <div>
        <a (click)="openAddFilesModal()" class="text-success">
          <i class="fa fa-paperclip"></i>
          Attach Files
          <span *ngIf="files.length">({{ files.length }})</span>
        </a>
      </div>
    </div>
    <div class="col-auto">
      <button
        mat-raised-button
        color="dark"
        class="add-task-button"
      >
        Add Task
      </button>
      <button
        mat-button
        color="warn"
        type="button"
        (click)="reset()"
      >
        Clear
      </button>
    </div>
  </div>
</form>
