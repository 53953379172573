<form [formGroup]="stepForm" novalidate (ngSubmit)="saveStep()">
  <div class="modal-header">
    <span>{{ step.id ? 'Edit' : 'Create' }} Step to Produce</span>
    <button class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <mat-form-field>
      <input
        id="product-step-name"
        type="text"
        matInput
        placeholder="Step name"
        formControlName="name"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <input
        id="product-step-barcode"
        type="text"
        matInput
        placeholder="Step to Produce Barcode"
        formControlName="barcode"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        type="text"
        matInput
        placeholder="Description"
        formControlName="description"
      />
    </mat-form-field>

  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button class="btn btn-tn btn-primary" [disabled]="stepForm.invalid">Save</button>
      <button class="btn btn-tn btn-default" type="button" (click)="activeModal.dismiss()">Cancel</button>
    </div>
  </div>
</form>
