<div class="modal-header">
  <h3>Import Detail</h3>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div>
    Started Date: <b>{{ purchaseOrderImport.started_at | date:'short': 'utc'}}</b>
  </div>
  <div>
    Finished Date: <b>{{ purchaseOrderImport.started_at | date:'short': 'utc'}}</b>
  </div>
  <div>
    Total Purchase Orders: <b>{{ purchaseOrderImport.total_pos }}</b>
  </div>
  <div>
    Imported: <b>{{ purchaseOrderImport.imported_pos }}</b>
  </div>
  <div>
    Failed: <b>{{ purchaseOrderImport.non_imported_pos }}</b>
  </div>

  <div class="details mt-2">
    <div class="logs">
      <div class="log" *ngFor="let log of purchaseOrderImport.log"
           [ngClass]="{'text-info': log.type ==='info','text-warning': log.type ==='notice','text-danger': log.type ==='error'}">
        {{ log.message }}
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="text-right">
    <button (click)="closeModal()" class="btn btn-dark">Close</button>
  </div>
</div>
