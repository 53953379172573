<ngx-loading [show]="loading"></ngx-loading>

<ngx-title-divider title="Post-it Notes" [bold]="true"> </ngx-title-divider>

<div style="max-height: 500px; overflow: auto;" *ngIf="poID && comments">
  <div
    *ngFor="let item of comments"
    id="comments-container"
    class="comments-container pr-2 d-flex flex-column-reverse m-3"
  >
    <div [id]="item.id" [attr.id]="'comment-' + item.id" class="purchase-order-comments d-flex flex-column m-1">
      <!-- style="color: #40ae45;" -->
      <div class="w-100 d-flex">
        <span>
          <b>{{ item.subject }}</b>
        </span>
        <div class="ml-auto">
          <span style="font-size: 13px; font-weight: 500;">
            {{ item.userEmail }}
          </span>
          <span style="font-size: 13px; font-weight: 100;">
            {{ item.createdAt | timeAgo }}
          </span>
          <i *ngIf="userId == item.userId && currentUser.role != 'User'" class="fa fa-edit ml-2" (click)="onEditCommentClick(item)"></i>
          <i *ngIf="userId == item.userId && currentUser.role != 'User'" class="fa fa-trash ml-2" (click)="onRemoveCommentClick(item)"></i>
        </div>
      </div>
      <span>
        {{ item.content }}
      </span>
    </div>
  </div>
</div>
<div class="pt-4 mt-2 border-top" *ngIf="currentUser.role != 'User'">
  <form class="">
    <div class="row">
      <div class="col-md-10 d-flex flex-column">
        <mat-form-field>
          <input
            matInput
            placeholder="Put subject here"
            type="text"
            [(ngModel)]="comment.subject"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
        <mat-form-field>
          <textarea
            #noteTextarea="ngModel"
            rows="4"
            matInput
            placeholder="Put content here"
            type="text"
            [(ngModel)]="comment.content"
            [ngModelOptions]="{ standalone: true }"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-comments bg-primary text-white d-block mt-2"
          (click)="onSendCommentClick()"
        >
          <i class="fa fa-send"></i>
          Send
        </button>
      </div>
    </div>
  </form>
</div>
