import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { User } from '@app/models';
import { ContractHelper } from '@app/shared/helpers/contract.helper';
import { Store } from '@ngrx/store';
import { NgxLoadingModule } from 'ngx-loading';
import { PoNotesFormComponent } from '../../po-notes-form/po-notes-form.component';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-messages',
  templateUrl: './contract-messages.component.html',
  styleUrls: ['./contract-messages.component.scss'],
  imports: [
    CommonModule,
    PoNotesFormComponent
  ]
})
export class ContractMessagesComponent extends ContractBaseComponent implements OnInit {

  constructor(public injector: Injector, private contractHelper: ContractHelper,
    public store: Store<{ user: User }>) { 
    super(injector, store)
  }

  ngOnInit(): void {
  }
  
  handleNotesChange($event) {
    this.contractHelper.getContractData();
    this.contractHelper.contractListent.sendMessage({
      action: 'update_notes',
    });
  }

}
