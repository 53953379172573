/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOpenorderCollection } from '../models/lasgro-openorder-collection';
@Injectable({
  providedIn: 'root',
})
class ReportService extends __BaseService {
  static readonly reportOpenOrderPath = '/report/open_order';
  static readonly reportOpenOrder1Path = '/report/organizations/{organizationID}/open_order';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * open_order report
   *
   * Open order report.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ReportService.ReportOpenOrderParams` containing the following parameters:
   *
   * - `status_pending`:
   *
   * - `status_open`:
   *
   * - `status_closed`:
   *
   * - `status_archived`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `report_start_date_match_type`: What operation to filter with against report_date
   *
   * - `report_start_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `pos_received`:
   *
   * - `pos_issued`:
   *
   * - `organizationID`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  reportOpenOrderResponse(params: ReportService.ReportOpenOrderParams): __Observable<__StrictHttpResponse<LasgroOpenorderCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.statusPending != null) __params = __params.set('status_pending', params.statusPending.toString());
    if (params.statusOpen != null) __params = __params.set('status_open', params.statusOpen.toString());
    if (params.statusClosed != null) __params = __params.set('status_closed', params.statusClosed.toString());
    if (params.statusArchived != null) __params = __params.set('status_archived', params.statusArchived.toString());
    if (params.shipDateMatchType != null) __params = __params.set('ship_date_match_type', params.shipDateMatchType.toString());
    if (params.shipDate != null) __params = __params.set('ship_date', params.shipDate.toString());
    if (params.reportStartDateMatchType != null) __params = __params.set('report_start_date_match_type', params.reportStartDateMatchType.toString());
    if (params.reportStartDate != null) __params = __params.set('report_start_date', params.reportStartDate.toString());
    if (params.posReceived != null) __params = __params.set('pos_received', params.posReceived.toString());
    if (params.posIssued != null) __params = __params.set('pos_issued', params.posIssued.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.orderDateMatchType != null) __params = __params.set('order_date_match_type', params.orderDateMatchType.toString());
    if (params.orderDate != null) __params = __params.set('order_date', params.orderDate.toString());
    if (params.cancelDateMatchType != null) __params = __params.set('cancel_date_match_type', params.cancelDateMatchType.toString());
    if (params.cancelDate != null) __params = __params.set('cancel_date', params.cancelDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/open_order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOpenorderCollection>;
      })
    );
  }
  /**
   * open_order report
   *
   * Open order report.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ReportService.ReportOpenOrderParams` containing the following parameters:
   *
   * - `status_pending`:
   *
   * - `status_open`:
   *
   * - `status_closed`:
   *
   * - `status_archived`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `report_start_date_match_type`: What operation to filter with against report_date
   *
   * - `report_start_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `pos_received`:
   *
   * - `pos_issued`:
   *
   * - `organizationID`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  reportOpenOrder(params: ReportService.ReportOpenOrderParams): __Observable<LasgroOpenorderCollection> {
    return this.reportOpenOrderResponse(params).pipe(
      __map(_r => _r.body as LasgroOpenorderCollection)
    );
  }

  /**
   * open_order report
   *
   * Open order report.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ReportService.ReportOpenOrder1Params` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `status_pending`:
   *
   * - `status_open`:
   *
   * - `status_closed`:
   *
   * - `status_archived`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `report_start_date_match_type`: What operation to filter with against report_date
   *
   * - `report_start_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `pos_received`:
   *
   * - `pos_issued`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  reportOpenOrder1Response(params: ReportService.ReportOpenOrder1Params): __Observable<__StrictHttpResponse<LasgroOpenorderCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.statusPending != null) __params = __params.set('status_pending', params.statusPending.toString());
    if (params.statusOpen != null) __params = __params.set('status_open', params.statusOpen.toString());
    if (params.statusClosed != null) __params = __params.set('status_closed', params.statusClosed.toString());
    if (params.statusArchived != null) __params = __params.set('status_archived', params.statusArchived.toString());
    if (params.shipDateMatchType != null) __params = __params.set('ship_date_match_type', params.shipDateMatchType.toString());
    if (params.shipDate != null) __params = __params.set('ship_date', params.shipDate.toString());
    if (params.reportStartDateMatchType != null) __params = __params.set('report_start_date_match_type', params.reportStartDateMatchType.toString());
    if (params.reportStartDate != null) __params = __params.set('report_start_date', params.reportStartDate.toString());
    if (params.posReceived != null) __params = __params.set('pos_received', params.posReceived.toString());
    if (params.posIssued != null) __params = __params.set('pos_issued', params.posIssued.toString());
    if (params.orderDateMatchType != null) __params = __params.set('order_date_match_type', params.orderDateMatchType.toString());
    if (params.orderDate != null) __params = __params.set('order_date', params.orderDate.toString());
    if (params.cancelDateMatchType != null) __params = __params.set('cancel_date_match_type', params.cancelDateMatchType.toString());
    if (params.cancelDate != null) __params = __params.set('cancel_date', params.cancelDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/report/organizations/${encodeURIComponent(String(params.organizationID))}/open_order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOpenorderCollection>;
      })
    );
  }
  /**
   * open_order report
   *
   * Open order report.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ReportService.ReportOpenOrder1Params` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `status_pending`:
   *
   * - `status_open`:
   *
   * - `status_closed`:
   *
   * - `status_archived`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `report_start_date_match_type`: What operation to filter with against report_date
   *
   * - `report_start_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `pos_received`:
   *
   * - `pos_issued`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  reportOpenOrder1(params: ReportService.ReportOpenOrder1Params): __Observable<LasgroOpenorderCollection> {
    return this.reportOpenOrder1Response(params).pipe(
      __map(_r => _r.body as LasgroOpenorderCollection)
    );
  }
}

module ReportService {

  /**
   * Parameters for reportOpenOrder
   */
  export interface ReportOpenOrderParams {
    statusPending?: boolean;
    statusOpen?: boolean;
    statusClosed?: boolean;
    statusArchived?: boolean;

    /**
     * What operation to filter with against ship_date
     */
    shipDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    shipDate?: string;

    /**
     * What operation to filter with against report_date
     */
    reportStartDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    reportStartDate?: string;
    posReceived?: boolean;
    posIssued?: boolean;
    organizationID?: string;

    /**
     * What operation to filter with against order_date
     */
    orderDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    orderDate?: string;

    /**
     * What operation to filter with against cancel_date
     */
    cancelDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    cancelDate?: string;
  }

  /**
   * Parameters for reportOpenOrder1
   */
  export interface ReportOpenOrder1Params {
    organizationID: string;
    statusPending?: boolean;
    statusOpen?: boolean;
    statusClosed?: boolean;
    statusArchived?: boolean;

    /**
     * What operation to filter with against ship_date
     */
    shipDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    shipDate?: string;

    /**
     * What operation to filter with against report_date
     */
    reportStartDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    reportStartDate?: string;
    posReceived?: boolean;
    posIssued?: boolean;

    /**
     * What operation to filter with against order_date
     */
    orderDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    orderDate?: string;

    /**
     * What operation to filter with against cancel_date
     */
    cancelDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    cancelDate?: string;
  }
}

export { ReportService }
