<!-- <div
  ngxSticky
  [triggerOn]="'po-detail-content'"
  [scrollContainer]="poDetailContainer"
  class="container-fluid jumbotron-fluid"
> -->
<div
  ngxSticky
  class="container-fluid jumbotron-fluid"
>
  <div class="row align-items-center">
    <div class="col-xs-12 col-md-auto text-right order-md-2 mb-2 mb-md-0">
      <a class="go-back text-right btn btn-link" (click)="goBack()">
        <i class="fa fa-caret-left pr-1"></i>
        Go back
      </a>
    </div>

    <div class="col">
      <div class="row">
        <div class="col-auto">
          <strong>Contract Management Stage</strong>
        </div>
      </div>
    </div>
  </div>
</div>
