/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AccountService } from './services/account.service';
import { ActivityLogsService } from './services/activity-logs.service';
import { ChecklistService } from './services/checklist.service';
import { ChecklistFilesService } from './services/checklist-files.service';
import { ChecklistTaskFilesService } from './services/checklist-task-files.service';
import { ChecklistTasksService } from './services/checklist-tasks.service';
import { CommissionedPoService } from './services/commissioned-po.service';
import { CommissionedLineItemService } from './services/commissioned-line-item.service';
import { ComponentsService } from './services/components.service';
import { CustomerPartIdentifierService } from './services/customer-part-identifier.service';
import { CustomerService } from './services/customer.service';
import { TokenService } from './services/token.service';
import { ExternalTaskService } from './services/external-task.service';
import { PurchaseOrderFilesService } from './services/purchase-order-files.service';
import { FinishedGoodsReportsService } from './services/finished-goods-reports.service';
import { ApiService } from './services/api.service';
import { LineItemService } from './services/line-item.service';
import { MailsService } from './services/mails.service';
import { ProductService } from './services/product.service';
import { MessageService } from './services/message.service';
import { UserNotificationRecordService } from './services/user-notification-record.service';
import { NotificationTagService } from './services/notification-tag.service';
import { NotificationTriggerService } from './services/notification-trigger.service';
import { NotificationTypeService } from './services/notification-type.service';
import { FileTypeGroupsService } from './services/file-type-groups.service';
import { OrganizationClientsService } from './services/organization-clients.service';
import { OrganizationPageComponentsService } from './services/organization-page-components.service';
import { OrganizationPagesService } from './services/organization-pages.service';
import { OrganizationService } from './services/organization.service';
import { AnalyticsService } from './services/analytics.service';
import { CustomFormsService } from './services/custom-forms.service';
import { CustomerRequiredFileService } from './services/customer-required-file.service';
import { StepService } from './services/step.service';
import { OrganizationNotificationService } from './services/organization-notification.service';
import { OrganizationNotificationMessageService } from './services/organization-notification-message.service';
import { PurchaseOrderService } from './services/purchase-order.service';
import { InvoiceService } from './services/invoice.service';
import { ShipmentTrackingService } from './services/shipment-tracking.service';
import { QuickbooksOnlineService } from './services/quickbooks-online.service';
import { QuickbooksDesktopService } from './services/quickbooks-desktop.service';
import { ResourcesService } from './services/resources.service';
import { WorkflowService } from './services/workflow.service';
import { SavedChecklistService } from './services/saved-checklist.service';
import { SavedChecklistTasksService } from './services/saved-checklist-tasks.service';
import { PricingEstimatorService } from './services/pricing-estimator.service';
import { ShipmentTrackingReferencesService } from './services/shipment-tracking-references.service';
import { SupplierService } from './services/supplier.service';
import { UserService } from './services/user.service';
import { TaskInstanceService } from './services/task-instance.service';
import { WorkflowInstanceService } from './services/workflow-instance.service';
import { TaskService } from './services/task.service';
import { PageTemplatesService } from './services/page-templates.service';
import { PurchaseOrderCommentsService } from './services/purchase-order-comments.service';
import { UserSubscriberService } from './services/user-subscriber.service';
import { RelationshipRoleService } from './services/relationship-role.service';
import { ReportService } from './services/report.service';
import { SearchService } from './services/search.service';
import { TaskNotificationService } from './services/task-notification.service';
import { TrainingsService } from './services/trainings.service';
import { InstanceStatusService } from './services/instance-status.service';
import { TaskNotificationTagService } from './services/task-notification-tag.service';
import { TaskTypeService } from './services/task-type.service';
import { TaskNotificationRecordService } from './services/task-notification-record.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AccountService,
    ActivityLogsService,
    ChecklistService,
    ChecklistFilesService,
    ChecklistTaskFilesService,
    ChecklistTasksService,
    CommissionedPoService,
    CommissionedLineItemService,
    ComponentsService,
    CustomerPartIdentifierService,
    CustomerService,
    TokenService,
    ExternalTaskService,
    PurchaseOrderFilesService,
    FinishedGoodsReportsService,
    ApiService,
    LineItemService,
    MailsService,
    ProductService,
    MessageService,
    UserNotificationRecordService,
    NotificationTagService,
    NotificationTriggerService,
    NotificationTypeService,
    FileTypeGroupsService,
    OrganizationClientsService,
    OrganizationPageComponentsService,
    OrganizationPagesService,
    OrganizationService,
    AnalyticsService,
    CustomFormsService,
    CustomerRequiredFileService,
    StepService,
    OrganizationNotificationService,
    OrganizationNotificationMessageService,
    PurchaseOrderService,
    InvoiceService,
    ShipmentTrackingService,
    QuickbooksOnlineService,
    QuickbooksDesktopService,
    ResourcesService,
    WorkflowService,
    SavedChecklistService,
    SavedChecklistTasksService,
    PricingEstimatorService,
    ShipmentTrackingReferencesService,
    SupplierService,
    UserService,
    TaskInstanceService,
    WorkflowInstanceService,
    TaskService,
    PageTemplatesService,
    PurchaseOrderCommentsService,
    UserSubscriberService,
    RelationshipRoleService,
    ReportService,
    SearchService,
    TaskNotificationService,
    TrainingsService,
    InstanceStatusService,
    TaskNotificationTagService,
    TaskTypeService,
    TaskNotificationRecordService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
