export let PERIOD_TAGS = {
  monthly: [
    '$january',
    '$february',
    '$march',
    '$april',
    '$may',
    '$june',
    '$july',
    '$august',
    '$september',
    '$october',
    '$november',
    '$december',
  ],
  quarterly: [
    '$q1',
    '$q2',
    '$q3',
    '$q4',
  ],
}

export let TAGS_TO_MONTHLY_PERIODS = {
  '$january': 'January',
  '$february': 'February',
  '$march': 'March',
  '$april': 'April',
  '$may': 'May',
  '$june': 'June',
  '$july': 'July',
  '$august': 'August',
  '$september': 'September',
  '$october': 'October',
  '$november': 'November',
  '$december': 'December',
}

export let TAGS_TO_QUARTERLY_PERIODS = {
  '$q1': 'First Quarter',
  '$q2': 'Second Quarter',
  '$q3': 'Third Quarter',
  '$q4': 'Fourth Quarter',
}