import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Injector, OnInit } from '@angular/core';
import { User } from '@app/models';
import { PoFilesComponent } from '@app/modules/po-details/components/po-files/po-files.component';
import { FileSizePipe } from '@app/shared/pipes';
import { Store } from '@ngrx/store';
import { TitleDividerComponent } from '../../title-divider/title-divider.component';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-documents-a',
  templateUrl: './contract-documents-a.component.html',
  styleUrls: ['./contract-documents-a.component.scss'],
  imports: [
    CommonModule,
    TitleDividerComponent,
    PoFilesComponent
  ]
})
export class ContractDocumentsAComponent extends ContractBaseComponent implements OnInit {
  addSubmittedDocumentFileEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(public injector: Injector, public store: Store<{ user: User }>) {
    super(injector, store)
  }


  ngOnInit(): void {
  }


}
