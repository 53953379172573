import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import CONFIG from '@app/app.config';

@Component({
  selector: 'ngx-customer-required-file-modal',
  templateUrl: './customer-required-file-modal.component.html',
})
export class CustomerRequiredFileModalComponent implements OnInit {
  @Input() name: string = '';
  @Input() description: string = '';
  @Input() is_mandatory: boolean = true;

  modalForm: UntypedFormGroup;
  isUpdate = false;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    if (this.name != '') {
      this.isUpdate = true;
    }
    this.modalForm = this.fb.group({
      name: [this.name, [Validators.required]],
      description: [this.description, []],
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  submit() {
    const name = this.name.trim();
    const description = this.description.trim();
    this.activeModal.close({ 
      name: name, 
      description: description, 
      is_mandatory: this.is_mandatory,
    });
  }
}
