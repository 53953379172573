<h1>Add file details</h1>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" (selectionChange)="onSelectType($event)">
        <mat-option *ngFor="let type of types" [value]="type">
          {{type.value}} <span *ngIf="type.required">*</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input [matDatepicker]="picker" matInput placeholder="Expired date" formControlName="insuranceDate"/>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Name" formControlName="name" />
    </mat-form-field>
  </form>
</mat-dialog-content>
<div mat-dialog-actions>
  <button [disabled]="!form.valid" color="primary" mat-raised-button [mat-dialog-close]="getData()">Ok</button>
  <button mat-raised-button [mat-dialog-close]="null">Cancel</button>
</div>
