<nb-layout center="false" windowMode>
  <!-- <nb-layout-header subheader fixed>
    <button mat-icon-button (click)="toggleSidebar()">
      <mat-icon aria-label="Menu button">menu</mat-icon>
    </button>
    <ngx-header position="normal"></ngx-header>
  </nb-layout-header> -->

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    <ng-content select="ngx-menu-select"></ng-content>

    <div>
      <a *ngIf="companyLogo" class="main-logo"
        [style.background-image]="'url('+companyLogo+')'" 
        [routerLink]="'/dashboard'">
      </a>
      <p class="text-white pt-4 pb-2 h6" style="font-weight: 300;">{{siteName}}</p>
        <!-- [style.background-image]="'url(' + companyLogo + ') !important;'"  -->
    </div>

    <ng-container *ngIf="user$ | async as user">
      <p class="text-white pt-4 pb-2 h6" style="font-weight: 300;">Hi, {{user.first_name}}</p>
    </ng-container>

    <ng-content select="nb-menu"></ng-content>

    <ng-container *ngIf="user$ | async as user">
      <ng-content select="ngx-company-select" *ngIf="user.role === 'Lasgro Admin'"></ng-content>
    </ng-container>
  </nb-sidebar>

  <nb-layout-column class="main-content">
    <ng-content select="router-outlet"></ng-content>
    <toaster-container></toaster-container>
  </nb-layout-column>
</nb-layout>
