/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroDid_change_pass } from '../models/lasgro-did-_change-_pass';
import { PasswordResetRequestInputPayload } from '../models/password-reset-request-input-payload';
import { PasswordResetResponseInputPayload } from '../models/password-reset-response-input-payload';
import { LasgroUser } from '../models/lasgro-user';
@Injectable({
  providedIn: 'root',
})
class AccountService extends __BaseService {
  static readonly accountDidChangePassPath = '/account/changed-pass/{email}';
  static readonly accountPasswordResetRequestPath = '/account/password/reset-request';
  static readonly accountPasswordResetResponsePath = '/account/password/reset-response';
  static readonly accountShowPath = '/account/profile';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * did-change-pass account
   *
   * check if user with the email changed the pass at least one time
   * @param email undefined
   * @return OK
   */
  accountDidChangePassResponse(email: string): __Observable<__StrictHttpResponse<LasgroDid_change_pass>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/account/changed-pass/${encodeURIComponent(String(email))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroDid_change_pass>;
      })
    );
  }
  /**
   * did-change-pass account
   *
   * check if user with the email changed the pass at least one time
   * @param email undefined
   * @return OK
   */
  accountDidChangePass(email: string): __Observable<LasgroDid_change_pass> {
    return this.accountDidChangePassResponse(email).pipe(
      __map(_r => _r.body as LasgroDid_change_pass)
    );
  }

  /**
   * password-reset-request account
   *
   * Sends a an email to the passed-in user with a button for a password reset
   * @param payload undefined
   */
  accountPasswordResetRequestResponse(payload: PasswordResetRequestInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/account/password/reset-request`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * password-reset-request account
   *
   * Sends a an email to the passed-in user with a button for a password reset
   * @param payload undefined
   */
  accountPasswordResetRequest(payload: PasswordResetRequestInputPayload): __Observable<null> {
    return this.accountPasswordResetRequestResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * password-reset-response account
   *
   * After the user clicks on the link emailed to them and submits a new password, this is sent to the API to reset the password
   * @param payload undefined
   */
  accountPasswordResetResponseResponse(payload: PasswordResetResponseInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/account/password/reset-response`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * password-reset-response account
   *
   * After the user clicks on the link emailed to them and submits a new password, this is sent to the API to reset the password
   * @param payload undefined
   */
  accountPasswordResetResponse(payload: PasswordResetResponseInputPayload): __Observable<null> {
    return this.accountPasswordResetResponseResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show account
   *
   * Returns current user's information
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  accountShowResponse(): __Observable<__StrictHttpResponse<LasgroUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/account/profile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUser>;
      })
    );
  }
  /**
   * show account
   *
   * Returns current user's information
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  accountShow(): __Observable<LasgroUser> {
    return this.accountShowResponse().pipe(
      __map(_r => _r.body as LasgroUser)
    );
  }
}

module AccountService {
}

export { AccountService }
