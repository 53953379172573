import { AfterViewInit, Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

// tslint:disable
@Directive({
  selector: '[secure-url-input]',
})
// tslint:enable
export class SecureUrlInputDirective implements AfterViewInit {

  constructor(private control: NgControl) { }

  ngAfterViewInit() {
  }

  validateUrl(value) {
    const regExp = new RegExp('[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)');
    return regExp.test(value);
  }

  @HostListener('change', ['$event'])
  inputChange($event) {
    $event.preventDefault();
    const text = $event.target.value;
    if (this.validateUrl(text)) {
      if (/https?/.test(text)) {
        return;
      }

      this.control.control.setValue('http://' + text);
    }
  }

}
