import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@models/user.model';
import { Store } from '@ngrx/store';
import { CompanyService } from '@services/company.service';
import { NotificationService } from '@services/notification.service';
import { PurchaseOrderService } from '@services/purchase-order.service';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { Notification } from '@models/notification.model';
import Swal from 'sweetalert2';
import { Toaster } from '@services/toaster.service';
import { environment } from '@env/environment';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { GetNotificationReadableType, RESOURCE_TYPE, NOTIFICATION_TYPE } from '@models/notification.model';
import { RejectionReasonModalComponent } from '@shared/modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location, TitleCasePipe } from '@angular/common';
import { TaskFilesModalComponent } from '../purchase-order/modals/task-files-modal/task-files-modal.component';

@Component({
  selector: 'ngx-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  @LocalStorage() organizationId;
  @LocalStorage() role;
  @LocalStorage() companyType: string;
  user$: Observable<User>;

  dataSource: any;
  selectedCompanyId;
  notifications: Notification[] = [];
  selectedNotification: Notification;

  loading = true;
  fileLoading = false;
  completedTasks = 0;
  onlySubscriptions = true;
  isCustomer: boolean;

  getNotificationReadableType = GetNotificationReadableType;
  RESOURCE_TYPE = RESOURCE_TYPE;
  NOTIFICATION_TYPE = NOTIFICATION_TYPE;

  constructor(
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private purchaseOrderService: PurchaseOrderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private $modal: NgbModal,
    private $toaster: Toaster,
    protected titleCasePipe: TitleCasePipe,
    private store: Store<{ user: User }>,
  ) {}

  ngOnInit() {
    this.user$ = this.store.select('user');
    this.selectedCompanyId = this.organizationId;
    this.getNotificationList();
  }

  listSelectionChanged = (e) => {
    let n = e.addedItems[0];
    this.getNotificationPurchaseOrder(n);
    this.selectedNotification = n;
  };

  ngOnDestroy() {}

  getNotificationList() {
    this.loading = true;
    let filter = this.onlySubscriptions ? 'subscribed' : 'all';
    this.notificationService.getNotifications(this.organizationId, filter).subscribe(
      (notifications) => {
        this.notifications = this.prepareNotifications(notifications);
        this.dataSource = new DataSource({
          store: new ArrayStore({
            data: this.notifications,
            key: 'id',
          }),
          group: 'readable_type',
          searchExpr: ['readable_type'],
        });
        this.completedTasks = 0;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.error(error);
        this.$toaster.show('error', 'Error', 'An error occurred, please try again.');
      },
    );
  }

  prepareNotifications(notifications) {
    let result: Notification[] = [];
    notifications.forEach((n) => {
      n.readable_type = this.getNotificationReadableType(n.notification_type, true);
      n.parent_resource_number = n.message.substring(
        n.message.indexOf('Contract #') + 10,
        n.message.indexOf(" and it's"),
      );
      n.counterpartName = n.message.substring(n.message.indexOf('(') + 1, n.message.lastIndexOf(')'));
      n.notification_number = n.id.substring(0, 8);
      result.push(n);
    });
    return result;
  }

  async getNotificationPurchaseOrder(notification: any) {
    if (!notification) {
      return;
    }
    this.loading = true;
    const poID =
      notification.resource_type === RESOURCE_TYPE.PURCHASE_ORDER
        ? notification.resource_id
        : notification.parent_resource_id;

    await this.purchaseOrderService.getPurchaseOrder(poID).subscribe(async (po) => {
      notification.purchase_order = po;
      this.isCustomer = notification.purchase_order.customer_id === this.organizationId;
      if (notification.resource_type === RESOURCE_TYPE.CHECKLIST_TASK) {
        await this.companyService
          .getCheckListTask(this.organizationId, poID, '00000000-0000-0000-0000-000000000000', notification.resource_id)
          .subscribe((task) => {
            notification.task = task;
            this.fillNotification(notification);
            this.loading = false;
          });
      } else {
        this.fillNotification(notification);
        this.loading = false;
      }
    });
  }

  async fillNotification(notification: any) {
    if (this.organizationId === notification.purchase_order.customer_id) {
      notification.counterpartRole = 'Vendor';
      notification.counterpartName = notification.supplier_contact.organization_name;
    } else {
      notification.counterpartRole = 'Customer';
      notification.counterpartName = notification.customer_contact.organization_name;
    }
  }

  changeFilter($event) {
    this.onlySubscriptions = $event.checked;
    this.getNotificationList();
  }

  addTaskFile(notification: any) {
    event.stopPropagation();
    event.cancelBubble = true;
    const modalRef = this.$modal.open(TaskFilesModalComponent, {
      size: 'lg',
      container: 'nb-layout',
      keyboard: false,
    });

    modalRef.componentInstance.task = notification.task;
    modalRef.componentInstance.taskFiles = notification.task?.files || [];
    modalRef.componentInstance.isCustomer = this.organizationId === notification.purchase_order.customer_id;
    modalRef.componentInstance.allowEdit = true;
  }

  shouldShowApprovalBtns(notification: any) {
    if (notification?.readable_type?.indexOf('APPROVAL') === -1) {
      return false;
    }

    return true;
  }

  completeTask(notification: any) {
    const task = notification.task;
    task.is_completed = !task.completed_at;
    this.loading = true;
    this.companyService.updateTask(task.checklist_task_id, task).subscribe(
      async () => {
        await this.companyService
          .getCheckListTask(
            this.organizationId,
            notification.purchase_order.id,
            '00000000-0000-0000-0000-000000000000',
            task.checklist_task_id,
          )
          .subscribe((data) => {
            notification.task = data;
            this.$toaster.show(
              'success',
              'Success',
              'Task marked as ' + (task.is_completed ? 'complete.' : 'not completed'),
            );
            this.loading = false;
          });
      },
      (error) => {
        console.error(error);
        this.$toaster.show('error', 'Error', 'An error occurred, please try again.');
        task.is_completed = !task.is_completed;
        this.loading = false;
      },
    );
  }

  openTask(notification: any) {
    event.stopPropagation();
    event.cancelBubble = true;
    let clientType = 'customers';
    let view = 'work-commissioned';
    if (this.organizationId === notification.supplier_contact.organization_id) {
      clientType = 'suppliers';
      view = 'work-in-progress';
    }
    const resourceID = notification.parent_resource_id ? notification.parent_resource_id : notification.resource_id;
    this.router.navigate([clientType, view, resourceID], {
      queryParams: {
        tab: 'checklist',
        taskId: notification.resource_id,
      },
    });
  }

  openPurchaseOrder(notification: any) {
    event.stopPropagation();
    event.cancelBubble = true;
    let clientType = 'customers';
    let view = 'work-commissioned';
    if (this.organizationId === notification.supplier_contact.organization_id) {
      clientType = 'suppliers';
      view = 'work-in-progress';
    }
    const resourceID = notification.parent_resource_id ? notification.parent_resource_id : notification.resource_id;
    this.router.navigate([clientType, view, resourceID], {});
  }

  clearNotification(notification: any) {
    event.stopPropagation();
    event.cancelBubble = true;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to undo this!',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Yes, clear it!',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.notificationService.clearNotification(this.organizationId, notification.id).subscribe(() => {
          this.$toaster.show('success', 'Success', 'Notification cleared');
          this.loading = false;
          this.getNotificationList()
        }),
          (err) => (this.loading = false);
      }
    });
  }

  acceptOrReject(notification: any, accepted) {
    event.stopPropagation();
    const poID =
      notification.resource_type === RESOURCE_TYPE.PURCHASE_ORDER
        ? notification.resource_id
        : notification.parent_resource_id;

    let resourceName = '',
      acceptRejectFn,
      params;
    const statusText = accepted ? 'approved' : 'rejected';

    switch (notification.resource_type) {
      case RESOURCE_TYPE.FILE: {
        resourceName = 'File';
        acceptRejectFn = this.companyService.approvePoFile.bind(this.companyService);
        if (!accepted) {
          acceptRejectFn = this.companyService.rejectPoFile.bind(this.companyService);
        }
        params = [notification.purchase_order.customer_id, notification.purchase_order.id, notification.resource_id];
        break;
      }
      default: {
        return;
      }
    }

    const fn = (f, p, n, s) => {
      this.loading = true;
      f(...p).subscribe(
        (res) => {
          this.$toaster.show('success', 'Success', n + ' ' + s);
          this.loading = false;
          this.selectedNotification = null;
          this.getNotificationList();
        },
        (error) => {
          const errMsg = !!error.error && !!error.error.detail ? error.error.detail : error.message;
          this.$toaster.show('error', 'Error', 'An error occurred, please try again later.');
          console.log(errMsg);
          this.loading = false;
        },
      );
    };

    if (!accepted) {
      setTimeout(() => {
        this.$modal
          .open(RejectionReasonModalComponent, {
            size: 'md',
            container: 'nb-layout',
            keyboard: false,
            windowClass: 'rejection-reason-modal',
          })
          .result.then((result: any) => {
            if (result) {
              fn(acceptRejectFn, [...params, result.reason], resourceName, statusText);
            }
          });
      });
    } else {
      fn(acceptRejectFn, params, resourceName, statusText);
    }
  }

  getRcdssContractStage(purchaseOrder) {
    if (purchaseOrder?.rcdss_panda_doc_notified_event) {
      return 'Contract signing';
    }
    if (!purchaseOrder?.rcdss_application_form?.status) {
      return 'Application form submission';
    }
    switch (purchaseOrder.rcdss_application_form.status) {
      case 'closed':
      case 'pending':
        if (purchaseOrder.rcdss_application_form.is_draft) {
          return 'Application form submission';
        }
        return 'Application form approval';
      case 'approved':
        return 'Contract submission';
      case 'rejected':
        return 'Application rejected';
    }
  }

  getRcdssContractStageClass(po) {
    if (!po) {
      return '';
    }
    if (po?.rcdss_panda_doc_notified_event) {
      return 'text-success';
    }
    if (!po?.rcdss_application_form?.status) {
      return 'text-warning';
    }
    switch (po?.rcdss_application_form.status) {
      case 'closed':
      case 'pending':
        if (po?.rcdss_application_form.is_draft) {
          return 'text-warning';
        }
        return 'text-warning';
      case 'approved':
        if (!po?.files || po?.files.filter((f) => f.is_main_file).length === 0) {
          return '';
        } else {
          return 'text-success';
        }
      case 'rejected':
        return 'text-danger';
    }
  }

  getRcdssStatus(po) {
    let statusText = '';
    if (po?.rcdss_panda_doc_notified_event) {
      switch (po?.rcdss_panda_doc_notified_event) {
        case 'CONTRACT_READY_FOR_SIGNATURES':
          return 'Pending Signatures';
        case 'CONTRACT_COMPLETED':
          return 'Circulated for Counter Signatures';
        case 'FINAL_CONTRACT_AVAILABLE':
          return 'Fully Executed';
      }
    }
    if (
      !po.rcdss_application_form ||
      (this.getRcdssContractStage(po) === 'Contract submission' && !po.rcdss_panda_doc_notified_event)
    ) {
      return 'Not Submitted';
    }
    if (!po.rcdss_application_form.is_draft) {
      const status = po.rcdss_application_form.status === 'closed' ? 'Under Review' : po.rcdss_application_form.status;
      statusText = `Submitted/${this.titleCasePipe.transform(status)}`;
    }
    if (po.rcdss_application_form.is_draft) {
      statusText = 'Pending Draft';
    }

    return statusText;
  }

  isTextOverflow(elementId: string): boolean {
    const elem = document.getElementById(elementId);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  /*handleNotificationHandled($event) {
    if (!!$event.completedTask && this.notifications.length > 1) {
      this.completedTasks += $event.completedTask ? 1 : -1;
      return;
    }
    this.getNotificationList();
  }*/

  /*createSimplePo() {
    event.stopPropagation();
    event.cancelBubble = true;
    this.router.navigate(['purchase-order', 'create-simple']);
  }*/
}
