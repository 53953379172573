import * as UserActions from '../actions/user.actions';
import { User } from '@app/models';

export type Action = UserActions.All;

export function userReducer(state: User = null, action: Action) {
  switch (action.type) {
    case UserActions.FETCH_SUCCESS:
      return action.payload;
    case UserActions.FETCH_FAILED:
      console.error('ERROR Fetching User', action.payload);
      return state;
    case UserActions.CLEAR:
      return null;
    default:
      return state;
  }
}
