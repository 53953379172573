import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Toaster } from '@services/toaster.service';
import CONFIG from '@app/app.config';

@Component({
  selector: 'ngx-add-files-modal',
  templateUrl: './add-files-modal.component.html',
  styleUrls: ['./add-files-modal.component.scss'],
})
export class AddFilesModalComponent implements OnInit {
  @Input() files: File[] = [];
  loadingFiles = false;

  constructor(public matDialogRef: MatDialogRef<AddFilesModalComponent>,
              private $toaster: Toaster) { }

  ngOnInit() {
  }

  addFiles($event) {
    this.loadingFiles = true;
    const files: any = Object.values($event.target.files)
      .filter((file: File) => {
        if (file.size > CONFIG.FILE_LIMIT) {
          this.$toaster.show('warning', 'File size', 'Maximum file size for uploads is 10 mb');
          return false;
        }
        return file;
      });

    this.files = [...this.files, ...files];
    setTimeout(() => { this.loadingFiles = false; }, 1000);
  }

  removeFile($index) {
    this.files.splice($index, 1);
  }
}
