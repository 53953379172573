<div class="modal-header">
  <h3>{{ title }}</h3>
  <button
    type="button"
    class="close"
    aria-label="close"
    (click)="close('close')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3 *ngIf="loading">Loading...</h3>
  <img *ngIf="src" [src]="src" [alt]="title">
</div>
