/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroInstance_statusCollection } from '../models/lasgro-instance-_status-collection';
import { LasgroInstance_status } from '../models/lasgro-instance-_status';
@Injectable({
  providedIn: 'root',
})
class InstanceStatusService extends __BaseService {
  static readonly instanceStatusListPath = '/workflows/instance_status';
  static readonly instanceStatusShowPath = '/workflows/instance_status/{instanceStatusID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list instance_status
   *
   * List all of the instance statuses
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  instanceStatusListResponse(): __Observable<__StrictHttpResponse<LasgroInstance_statusCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows/instance_status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroInstance_statusCollection>;
      })
    );
  }
  /**
   * list instance_status
   *
   * List all of the instance statuses
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  instanceStatusList(): __Observable<LasgroInstance_statusCollection> {
    return this.instanceStatusListResponse().pipe(
      __map(_r => _r.body as LasgroInstance_statusCollection)
    );
  }

  /**
   * show instance_status
   *
   * Details of an instance status
   *
   * Required security scopes:
   *   * `api:access`
   * @param instanceStatusID undefined
   * @return OK
   */
  instanceStatusShowResponse(instanceStatusID: string): __Observable<__StrictHttpResponse<LasgroInstance_status>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows/instance_status/${encodeURIComponent(String(instanceStatusID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroInstance_status>;
      })
    );
  }
  /**
   * show instance_status
   *
   * Details of an instance status
   *
   * Required security scopes:
   *   * `api:access`
   * @param instanceStatusID undefined
   * @return OK
   */
  instanceStatusShow(instanceStatusID: string): __Observable<LasgroInstance_status> {
    return this.instanceStatusShowResponse(instanceStatusID).pipe(
      __map(_r => _r.body as LasgroInstance_status)
    );
  }
}

module InstanceStatusService {
}

export { InstanceStatusService }
