import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ContractDto, PurchaseOrderSubscriberModel } from '@app/models/contract.dto';
import { User } from '@models/user.model';
import { CompanyService } from '@services/company.service';
import { PurchaseOrderService } from '@services/purchase-order.service';
import { Toaster } from '@services/toaster.service';
import { DxTreeListComponent } from 'devextreme-angular';
import { LocalStorage } from 'ngx-webstorage';
import { from, of, Subject } from 'rxjs';
import { catchError, concatMap, map, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ngx-po-subscribers',
  templateUrl: './po-subscribers.component.html',
  styleUrls: ['./po-subscribers.component.scss'],
})
export class PoSubscribersComponent implements OnInit, OnDestroy {
  
  @Input() purchaseOrder: ContractDto;
  @Input() supplierId: string;
  @Input() companyType: string;
  @Input() allowEdit: boolean = true;
  @Input() isCreationMode: boolean = false;
  @Output() subscribersChange: EventEmitter<PurchaseOrderSubscriberModel[]>
    = new EventEmitter<PurchaseOrderSubscriberModel[]>();
  @Output() purchaseOrderChange: EventEmitter<ContractDto> = new EventEmitter<ContractDto>();
  @LocalStorage() organizationId: string;
  users: User[] = [];
  approverWasCopyReceiver: boolean;
  approverWasSubscriber: boolean;
  @ViewChild(DxTreeListComponent, { static: true }) dataGrid: DxTreeListComponent;

  @Input() subscribers: PurchaseOrderSubscriberModel[] = [];

  @Input() modal: MatDialogRef<PoSubscribersComponent>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private companyService: CompanyService,
    private purchaseOrderService: PurchaseOrderService,
    private $toaster: Toaster,
  ) {
  }

  ngOnInit() {
    this.getUsersAvailable();

    if (!this.purchaseOrder.subscribers) {
      this.purchaseOrder.subscribers = [];
    }

    if (!this.purchaseOrder.copy_receivers) {
      this.purchaseOrder.copy_receivers = [];
    }

    if (!this.subscribers.length) {
      this.getPurchaseOrderSubscribers();
    } else {
      from(this.subscribers).pipe(map(sub => sub.id))
        .subscribe(id => {
          this.addSubscriber(id);
        });
    }

    if (this.purchaseOrder.approver_id) {
      this.addApprover(this.purchaseOrder.approver_id);
    }
  }

  getUsersAvailable() {
    let requestSubscription = this.companyService.users(this.purchaseOrder.customer_id)
      .pipe(catchError(error => of([])));

    requestSubscription = requestSubscription.pipe(
      concatMap(customerUsers => {
        from(customerUsers).subscribe(u => {
          u.organization_id = this.purchaseOrder.customer_id;
        });
        return this.companyService.users(this.purchaseOrder.supplier_id)
          .pipe(
            catchError(error => of([])),
            map(
              supplierUsers => [].concat(customerUsers, supplierUsers),
            ),
          );
      }),
    );

    return requestSubscription
      .subscribe((users) => {
        from(users).subscribe(u => {
          if (u.organization_id === undefined) {
            u.organization_id = this.supplierId;
          }
          this.users = [...this.users, u];
        });
      });
  }

  getPurchaseOrderSubscribers() {
    this.purchaseOrderService.getSubscribers(this.purchaseOrder.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((subscribers) => {
        this.subscribers = subscribers;
        this.subscribersChange.emit(subscribers);
        from(subscribers).pipe(map(sub => sub.id))
          .subscribe(id => {
            this.addSubscriber(id);
          });
      });
  }

  setApprover($event, userID: string) {
    if (!this.isCreationMode) {
      return;
    }
    if ($event.checked) {
      this.addApprover(userID);
    }
  }

  addApprover(userID) {
    if (this.approverWasSubscriber === false) {
      this.removeSubscriber(this.purchaseOrder.approver_id);
    }
    if (this.approverWasCopyReceiver === false) {
      this.removeCopyReceiver(this.purchaseOrder.approver_id);
    }
    this.approverWasSubscriber = this.purchaseOrder.subscribers.includes(userID) === true;
    this.approverWasCopyReceiver = this.purchaseOrder.copy_receivers.includes(userID) === true;
    this.purchaseOrder.approver_id = userID;
    this.addCopyReceiver(userID);
    this.addSubscriber(userID);
  }

  setCopyEmailUser($event, userID: string) {
    if (!this.isCreationMode) {
      return;
    }
    if ($event.checked) {
      this.addCopyReceiver(userID);
    } else {
      this.removeCopyReceiver(userID);
    }
  }

  setSubscriber($event, userID: string) {
    if ($event.checked) {
      this.addSubscriber(userID, true);
    } else {
      this.removeSubscriber(userID, true);
    }
  }

  addCopyReceiver(userID) {
    if (this.purchaseOrder.copy_receivers.includes(userID) === false) {
      this.purchaseOrder.copy_receivers = [...this.purchaseOrder.copy_receivers, userID];
      this.purchaseOrderChange.emit(this.purchaseOrder);
    }
  }

  removeCopyReceiver(userID) {
    if (this.purchaseOrder.copy_receivers.includes(userID) === true) {
      this.purchaseOrder.copy_receivers.splice(this.purchaseOrder.copy_receivers.indexOf(userID), 1);
      this.purchaseOrderChange.emit(this.purchaseOrder);
    }
  }

  addSubscriber(userID, saveSub = false) {
    if (this.purchaseOrder.subscribers.includes(userID) === false) {
      this.purchaseOrder.subscribers = [...this.purchaseOrder.subscribers, userID];
      this.purchaseOrderChange.emit(this.purchaseOrder);
      if (!this.isCreationMode && saveSub === true) {
        this.saveSubscriber(userID);
      }
    }
  }

  removeSubscriber(userID, deleteSub = false) {
    if (this.purchaseOrder.subscribers.includes(userID) === true) {
      this.purchaseOrder.subscribers.splice(this.purchaseOrder.subscribers.indexOf(userID), 1);
      if (!this.isCreationMode && deleteSub === true) {
        this.deleteSubscriber(userID);
      }
    }
  }

  saveSubscriber(userID) {
    this.purchaseOrderService.addSubscriber(this.purchaseOrder.id, userID)
      .subscribe(() => {
        this.$toaster.show('success', 'Success', 'Your changes were saved');
        this.getPurchaseOrderSubscribers();
      }, (error) => {
        const errMsg = error.error && error.error.detail ? error.error.detail : error.message;
        this.$toaster.show('error', 'An error occurred', 'Please try again later.');
        console.error(errMsg);
      });
  }

  deleteSubscriber(userId) {
    this.purchaseOrderService.removeSubscriber(this.purchaseOrder.id, userId)
      .subscribe(() => {
        this.$toaster.show('success', 'Success', 'Your changes were saved');
        this.getPurchaseOrderSubscribers();
      }, (error) => {
        const errMsg = error.error && error.error.detail ? error.error.detail : error.message;
        this.$toaster.show('error', 'An error occurred', 'Please try again later.');
        console.error(errMsg);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
