import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';


@Pipe({
  name: 'pendingStatusToWaitingForApproval',
})
export class PendingStatusToWaitingForApprovalPipe implements PipeTransform {
  

  transform(value: string = ''): any {
    return this.getStatus(value);
  }

  getStatus(value: string) {
    if (value && value.toLowerCase() === 'pending') {
        return 'Waiting for Approval';
    }

    return value;
  }
}
