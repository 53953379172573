import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  standalone: true,
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      const objectDate = moment(value);
      return moment().diff(objectDate, 'days') >= 2 ?
        objectDate.format('LLL') :
        objectDate.fromNow();
    }
  }

}
