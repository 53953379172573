/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroMeasurement_unitCollection } from '../models/lasgro-measurement-_unit-collection';
import { LasgroProductCollection } from '../models/lasgro-product-collection';
import { LasgroProduct } from '../models/lasgro-product';
import { AddProductPayload } from '../models/add-product-payload';
import { ProductInputPayload } from '../models/product-input-payload';
import { LasgroAffected_pos } from '../models/lasgro-affected-_pos';
import { LasgroProduct_po_activitylogCollection } from '../models/lasgro-product-_po-_activitylog-collection';
@Injectable({
  providedIn: 'root',
})
class ProductService extends __BaseService {
  static readonly productListMeasurementUnitsPath = '/measurement_units';
  static readonly productList1Path = '/organizations/{organizationID}/products';
  static readonly productAdd1Path = '/organizations/{organizationID}/products';
  static readonly productShow1Path = '/organizations/{organizationID}/products/{productID}';
  static readonly productUpdate1Path = '/organizations/{organizationID}/products/{productID}';
  static readonly productDelete1Path = '/organizations/{organizationID}/products/{productID}';
  static readonly productCountAffectedPosPath = '/organizations/{organizationID}/products/{productID}/count_affected_pos';
  static readonly productProductPoActivityLogPath = '/organizations/{organizationID}/products/{productID}/po_activity_log';
  static readonly productListCouldBuyPath = '/organizations/{organizationID}/products_could_buy';
  static readonly productListForPoPath = '/organizations/{organizationID}/products_for_po';
  static readonly productListISellPath = '/organizations/{organizationID}/products_i_sell';
  static readonly productListPath = '/products';
  static readonly productAddPath = '/products';
  static readonly productShowPath = '/products/{productID}';
  static readonly productUpdatePath = '/products/{productID}';
  static readonly productDeletePath = '/products/{productID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list_measurement_units product
   *
   * Lists measurement units.
   *
   * Required security scopes:
   *   * `api:access`
   * @param type Type to filter the measurement units
   * @return OK
   */
  productListMeasurementUnitsResponse(type: 'LENGTH' | 'WEIGHT'): __Observable<__StrictHttpResponse<LasgroMeasurement_unitCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (type != null) __params = __params.set('type', type.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/measurement_units`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMeasurement_unitCollection>;
      })
    );
  }
  /**
   * list_measurement_units product
   *
   * Lists measurement units.
   *
   * Required security scopes:
   *   * `api:access`
   * @param type Type to filter the measurement units
   * @return OK
   */
  productListMeasurementUnits(type: 'LENGTH' | 'WEIGHT'): __Observable<LasgroMeasurement_unitCollection> {
    return this.productListMeasurementUnitsResponse(type).pipe(
      __map(_r => _r.body as LasgroMeasurement_unitCollection)
    );
  }

  /**
   * list product
   *
   * Lists products
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductList1Params` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the products
   *
   * - `iBuy`: List product I sell or I buy
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  productList1Response(params: ProductService.ProductList1Params): __Observable<__StrictHttpResponse<LasgroProductCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.iBuy != null) __params = __params.set('iBuy', params.iBuy.toString());
    if (params.archived != null) __params = __params.set('archived', params.archived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProductCollection>;
      })
    );
  }
  /**
   * list product
   *
   * Lists products
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductList1Params` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the products
   *
   * - `iBuy`: List product I sell or I buy
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  productList1(params: ProductService.ProductList1Params): __Observable<LasgroProductCollection> {
    return this.productList1Response(params).pipe(
      __map(_r => _r.body as LasgroProductCollection)
    );
  }

  /**
   * add product
   *
   * Adds a new product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductAdd1Params` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  productAdd1Response(params: ProductService.ProductAdd1Params): __Observable<__StrictHttpResponse<LasgroProduct>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProduct>;
      })
    );
  }
  /**
   * add product
   *
   * Adds a new product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductAdd1Params` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  productAdd1(params: ProductService.ProductAdd1Params): __Observable<LasgroProduct> {
    return this.productAdd1Response(params).pipe(
      __map(_r => _r.body as LasgroProduct)
    );
  }

  /**
   * show product
   *
   * Retrieve product with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductShow1Params` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for this product
   *
   * @return OK
   */
  productShow1Response(params: ProductService.ProductShow1Params): __Observable<__StrictHttpResponse<LasgroProduct>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProduct>;
      })
    );
  }
  /**
   * show product
   *
   * Retrieve product with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductShow1Params` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for this product
   *
   * @return OK
   */
  productShow1(params: ProductService.ProductShow1Params): __Observable<LasgroProduct> {
    return this.productShow1Response(params).pipe(
      __map(_r => _r.body as LasgroProduct)
    );
  }

  /**
   * update product
   *
   * Updates a product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductUpdate1Params` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `payload`:
   *
   * - `organizationID`: Organization ID
   *
   * @return OK
   */
  productUpdate1Response(params: ProductService.ProductUpdate1Params): __Observable<__StrictHttpResponse<LasgroProduct>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProduct>;
      })
    );
  }
  /**
   * update product
   *
   * Updates a product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductUpdate1Params` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `payload`:
   *
   * - `organizationID`: Organization ID
   *
   * @return OK
   */
  productUpdate1(params: ProductService.ProductUpdate1Params): __Observable<LasgroProduct> {
    return this.productUpdate1Response(params).pipe(
      __map(_r => _r.body as LasgroProduct)
    );
  }

  /**
   * delete product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductDelete1Params` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `organizationID`: Organization ID
   */
  productDelete1Response(params: ProductService.ProductDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductDelete1Params` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `organizationID`: Organization ID
   */
  productDelete1(params: ProductService.ProductDelete1Params): __Observable<null> {
    return this.productDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * count_affected_pos product
   *
   * Gets the amount of POs in which this product is included.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductCountAffectedPosParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  productCountAffectedPosResponse(params: ProductService.ProductCountAffectedPosParams): __Observable<__StrictHttpResponse<LasgroAffected_pos>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/count_affected_pos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAffected_pos>;
      })
    );
  }
  /**
   * count_affected_pos product
   *
   * Gets the amount of POs in which this product is included.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductCountAffectedPosParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  productCountAffectedPos(params: ProductService.ProductCountAffectedPosParams): __Observable<LasgroAffected_pos> {
    return this.productCountAffectedPosResponse(params).pipe(
      __map(_r => _r.body as LasgroAffected_pos)
    );
  }

  /**
   * product_po_activityLog product
   *
   * List with POs in which the product is included.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductProductPoActivityLogParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `onlyApprovedPos`:
   *
   * @return OK
   */
  productProductPoActivityLogResponse(params: ProductService.ProductProductPoActivityLogParams): __Observable<__StrictHttpResponse<LasgroProduct_po_activitylogCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.onlyApprovedPos != null) __params = __params.set('onlyApprovedPos', params.onlyApprovedPos.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products/${encodeURIComponent(String(params.productID))}/po_activity_log`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProduct_po_activitylogCollection>;
      })
    );
  }
  /**
   * product_po_activityLog product
   *
   * List with POs in which the product is included.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductProductPoActivityLogParams` containing the following parameters:
   *
   * - `productID`:
   *
   * - `organizationID`:
   *
   * - `onlyApprovedPos`:
   *
   * @return OK
   */
  productProductPoActivityLog(params: ProductService.ProductProductPoActivityLogParams): __Observable<LasgroProduct_po_activitylogCollection> {
    return this.productProductPoActivityLogResponse(params).pipe(
      __map(_r => _r.body as LasgroProduct_po_activitylogCollection)
    );
  }

  /**
   * list_could_buy product
   *
   * Lists supplier-created products not yet included in a PO for which there's a CPI for the specified organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  productListCouldBuyResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroProductCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/products_could_buy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProductCollection>;
      })
    );
  }
  /**
   * list_could_buy product
   *
   * Lists supplier-created products not yet included in a PO for which there's a CPI for the specified organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  productListCouldBuy(organizationID: string): __Observable<LasgroProductCollection> {
    return this.productListCouldBuyResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroProductCollection)
    );
  }

  /**
   * list_for_po product
   *
   * Lists products for Purchase Order creation
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  productListForPoResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroProductCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/products_for_po`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProductCollection>;
      })
    );
  }
  /**
   * list_for_po product
   *
   * Lists products for Purchase Order creation
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  productListForPo(organizationID: string): __Observable<LasgroProductCollection> {
    return this.productListForPoResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroProductCollection)
    );
  }

  /**
   * list_i_sell product
   *
   * Lists products I sell
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductListISellParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the products
   *
   * - `definedByMe`: List products defined by me or defined by customers
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  productListISellResponse(params: ProductService.ProductListISellParams): __Observable<__StrictHttpResponse<LasgroProductCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.definedByMe != null) __params = __params.set('definedByMe', params.definedByMe.toString());
    if (params.archived != null) __params = __params.set('archived', params.archived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/products_i_sell`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProductCollection>;
      })
    );
  }
  /**
   * list_i_sell product
   *
   * Lists products I sell
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductListISellParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the products
   *
   * - `definedByMe`: List products defined by me or defined by customers
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  productListISell(params: ProductService.ProductListISellParams): __Observable<LasgroProductCollection> {
    return this.productListISellResponse(params).pipe(
      __map(_r => _r.body as LasgroProductCollection)
    );
  }

  /**
   * list product
   *
   * Lists products
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductListParams` containing the following parameters:
   *
   * - `viewType`: What view type to show for the products
   *
   * - `organizationID`:
   *
   * - `iBuy`: List product I sell or I buy
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  productListResponse(params: ProductService.ProductListParams): __Observable<__StrictHttpResponse<LasgroProductCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.iBuy != null) __params = __params.set('iBuy', params.iBuy.toString());
    if (params.archived != null) __params = __params.set('archived', params.archived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProductCollection>;
      })
    );
  }
  /**
   * list product
   *
   * Lists products
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductListParams` containing the following parameters:
   *
   * - `viewType`: What view type to show for the products
   *
   * - `organizationID`:
   *
   * - `iBuy`: List product I sell or I buy
   *
   * - `archived`: Whether to include archived products
   *
   * @return OK
   */
  productList(params: ProductService.ProductListParams): __Observable<LasgroProductCollection> {
    return this.productListResponse(params).pipe(
      __map(_r => _r.body as LasgroProductCollection)
    );
  }

  /**
   * add product
   *
   * Adds a new product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductAddParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  productAddResponse(params: ProductService.ProductAddParams): __Observable<__StrictHttpResponse<LasgroProduct>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProduct>;
      })
    );
  }
  /**
   * add product
   *
   * Adds a new product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductAddParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  productAdd(params: ProductService.ProductAddParams): __Observable<LasgroProduct> {
    return this.productAddResponse(params).pipe(
      __map(_r => _r.body as LasgroProduct)
    );
  }

  /**
   * show product
   *
   * Retrieve product with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductShowParams` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `viewType`: What view type to show for this product
   *
   * - `organizationID`:
   *
   * @return OK
   */
  productShowResponse(params: ProductService.ProductShowParams): __Observable<__StrictHttpResponse<LasgroProduct>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProduct>;
      })
    );
  }
  /**
   * show product
   *
   * Retrieve product with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductShowParams` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `viewType`: What view type to show for this product
   *
   * - `organizationID`:
   *
   * @return OK
   */
  productShow(params: ProductService.ProductShowParams): __Observable<LasgroProduct> {
    return this.productShowResponse(params).pipe(
      __map(_r => _r.body as LasgroProduct)
    );
  }

  /**
   * update product
   *
   * Updates a product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductUpdateParams` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `payload`:
   *
   * - `organizationID`: Organization ID
   *
   * @return OK
   */
  productUpdateResponse(params: ProductService.ProductUpdateParams): __Observable<__StrictHttpResponse<LasgroProduct>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProduct>;
      })
    );
  }
  /**
   * update product
   *
   * Updates a product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductUpdateParams` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `payload`:
   *
   * - `organizationID`: Organization ID
   *
   * @return OK
   */
  productUpdate(params: ProductService.ProductUpdateParams): __Observable<LasgroProduct> {
    return this.productUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroProduct)
    );
  }

  /**
   * delete product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductDeleteParams` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `organizationID`: Organization ID
   */
  productDeleteResponse(params: ProductService.ProductDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/products/${encodeURIComponent(String(params.productID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete product
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ProductService.ProductDeleteParams` containing the following parameters:
   *
   * - `productID`: Product ID
   *
   * - `organizationID`: Organization ID
   */
  productDelete(params: ProductService.ProductDeleteParams): __Observable<null> {
    return this.productDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ProductService {

  /**
   * Parameters for productList1
   */
  export interface ProductList1Params {
    organizationID: string;

    /**
     * What view type to show for the products
     */
    viewType?: 'full' | 'tiny' | 'default';

    /**
     * List product I sell or I buy
     */
    iBuy?: boolean;

    /**
     * Whether to include archived products
     */
    archived?: boolean;
  }

  /**
   * Parameters for productAdd1
   */
  export interface ProductAdd1Params {
    payload: AddProductPayload;
    organizationID: string;
  }

  /**
   * Parameters for productShow1
   */
  export interface ProductShow1Params {

    /**
     * Product ID
     */
    productID: string;
    organizationID: string;

    /**
     * What view type to show for this product
     */
    viewType?: 'full' | 'default';
  }

  /**
   * Parameters for productUpdate1
   */
  export interface ProductUpdate1Params {

    /**
     * Product ID
     */
    productID: string;
    payload: ProductInputPayload;

    /**
     * Organization ID
     */
    organizationID: string;
  }

  /**
   * Parameters for productDelete1
   */
  export interface ProductDelete1Params {

    /**
     * Product ID
     */
    productID: string;

    /**
     * Organization ID
     */
    organizationID: string;
  }

  /**
   * Parameters for productCountAffectedPos
   */
  export interface ProductCountAffectedPosParams {
    productID: string;
    organizationID: string;
  }

  /**
   * Parameters for productProductPoActivityLog
   */
  export interface ProductProductPoActivityLogParams {
    productID: string;
    organizationID: string;
    onlyApprovedPos?: boolean;
  }

  /**
   * Parameters for productListISell
   */
  export interface ProductListISellParams {
    organizationID: string;

    /**
     * What view type to show for the products
     */
    viewType?: 'full' | 'tiny' | 'default';

    /**
     * List products defined by me or defined by customers
     */
    definedByMe?: boolean;

    /**
     * Whether to include archived products
     */
    archived?: boolean;
  }

  /**
   * Parameters for productList
   */
  export interface ProductListParams {

    /**
     * What view type to show for the products
     */
    viewType?: 'full' | 'tiny' | 'default';
    organizationID?: string;

    /**
     * List product I sell or I buy
     */
    iBuy?: boolean;

    /**
     * Whether to include archived products
     */
    archived?: boolean;
  }

  /**
   * Parameters for productAdd
   */
  export interface ProductAddParams {
    payload: AddProductPayload;
    organizationID?: string;
  }

  /**
   * Parameters for productShow
   */
  export interface ProductShowParams {

    /**
     * Product ID
     */
    productID: string;

    /**
     * What view type to show for this product
     */
    viewType?: 'full' | 'default';
    organizationID?: string;
  }

  /**
   * Parameters for productUpdate
   */
  export interface ProductUpdateParams {

    /**
     * Product ID
     */
    productID: string;
    payload: ProductInputPayload;

    /**
     * Organization ID
     */
    organizationID?: string;
  }

  /**
   * Parameters for productDelete
   */
  export interface ProductDeleteParams {

    /**
     * Product ID
     */
    productID: string;

    /**
     * Organization ID
     */
    organizationID?: string;
  }
}

export { ProductService }
