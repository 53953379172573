import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { User } from '@models/index';
import { Store } from '@ngrx/store';
import { SharedService } from '@services/index';
import { Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { environment } from '@env/environment';

@Component({
  selector: 'ngx-menu-select',
  templateUrl: './menu-select.component.html',
  styleUrls: ['./menu-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuSelectComponent implements OnInit, OnDestroy {
  @Output() onChooseSubscription: EventEmitter<any> = new EventEmitter<any>();

  user$: Observable<User>;
  subscription: Subscription;
  @Input() selectedSubscription: string;
  subscriptions: any[];
  sidebarIcon: string = '';
  environment: any = environment;

  constructor(private $shared: SharedService, private store: Store<{ user: User }>) {}

  ngOnInit() {
    this.subscriptions = this.$shared.subscriptions();
    this.user$ = this.store.select('user');
    this.subscription = this.user$
      .pipe(
        filter(u => !!u),
        first(),
      )
      .subscribe(user => {
        this.selectedSubscription = 'surechain';
        this.chooseSubscription();
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public chooseSubscription() {
    this.onChooseSubscription.emit(this.selectedSubscription);
  }
}
