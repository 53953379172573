import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { POSearchService } from '@shared/components/po-search/po-search.service';

@Component({
  selector: 'ngx-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss'],
})
export class FilterContainerComponent implements OnInit {
  @Input() btnTitle: string;
  @Input() btnHint: string;
  @Input() typePill: string;
  @Output() onFilter: EventEmitter<boolean> = new EventEmitter();
  showMenu: boolean;

  constructor(private searchMenu$: POSearchService) {}

  ngOnInit() {
    this.searchMenu$.toggleMenu$.subscribe(res => {
      this.showMenu = res[0] === this.typePill ? res[1] : false;
    });
  }

  onFilterEvent(isSave: boolean) {
    this.onFilter.emit(isSave);
    this.searchMenu$.toggleMenu(this.typePill, false);
  }
}
