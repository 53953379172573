<div class="filter-container">
  <ngx-filter-pill
    [btnHint]="btnHint"
    [btnTitle]="btnTitle"
    [typePill]="typePill"
    (onClear)="onFilterEvent(false)">
  </ngx-filter-pill>

  <ngx-filter-menu *ngIf="showMenu" (onFilter)="onFilterEvent($event)">
    <ng-content></ng-content>
  </ngx-filter-menu>
  <div class="disabler" *ngIf="showMenu" (click)="onFilterEvent(true)"></div>
</div>
