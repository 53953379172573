import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
// import { ToasterService, Toast, BodyOutputType } from 'angular2-toaster';

/*export const DEFAULT_TOASTER_CONFIG = {
    positionClass: 'toast-top-right',
    timeout: true,
    newestOnTop: true,
    preventDuplicates: true,
    animation: 'fade',
    limit: 5,
};*/

@Injectable()
export class Toaster {

    constructor (
        private _snackBar: MatSnackBar
        // private $toaster: ToasterService
    ) {
    }

    /**
     * Shows a toast at the top right corner
     * @param type Toast type. Can be 'success', 'warning' or 'error'
     * @param title Toast title
     * @param body Toast content or message
     */
    show(type: string, title: string, body: string) {
        /*const toast: Toast = {
            type: type,
            title: title,
            body: body,
            timeout: 10000,
            showCloseButton: true,
            bodyOutputType: BodyOutputType.TrustedHtml,
        };

        this.$toaster.popAsync(toast);*/
        // this._snackBar.open(title.concat(': ', body), "Dismiss", {duration: 10000});
        this._snackBar.open(body, "Dismiss", {duration: 10000});
    }

    /**
     * Clears all the toasts currently on screen
     */
    clear() {
        // this.$toaster.clear();
    }
}
