import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '@app/app.config';
import { SocketStream } from '@app/ion-sockets/socket-stream';
import { Observable, ReplaySubject, EMPTY } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class SocketsConnectorService {

  socketConnection: WebSocketSubject<{}>;
  public connectionListener = new ReplaySubject();

  constructor(private httpClient: HttpClient) {

  }

// Service method
  getTicket(): Observable<{
    ticket: string,
  }> {
    return EMPTY;
    /*return this.httpClient.get<{
      ticket: string,
    }>(CONFIG.WEB_SOCKET_API);*/
  }

  connect() {
    /*this.getTicket().subscribe(({ ticket }) => {
      const connection = webSocket({
        url: `${ CONFIG.WEB_SOCKET_SERVER }?ticket=${ ticket }`,
        openObserver: {
          next: () => console.log('WebSocket connection open'),
        },
        closeObserver: {
          next: () => {
            console.log('WebSocket connection close');
          },
        },
      });
      this.connectionListener.next(connection);
      connection.subscribe(() => {});
    });*/
  }

  createSocketStream(topic) {
    // return new SocketStream(topic, this.connectionListener);
    return new SocketStream(topic, this.connectionListener);
  }

  disconnect() {
    /*this.socketConnection.complete();
    this.connectionListener.complete();*/
  }

}
