import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { SidebarState } from 'app/shared/enums';

@Injectable()
export class LayoutService {
    private _sideBarState = SidebarState.Expanded;
    private _sideBarStateSub = new BehaviorSubject<SidebarState>(this._sideBarState);
    public SideBarStateObs = this._sideBarStateSub.asObservable();

    constructor() {
    }

    public get SideBarState(): SidebarState {
        return this._sideBarState;
    }

    public set  SideBarState(state: SidebarState) {
        if (state === this.SideBarState)
            return;

        this._sideBarState = state;
        this._sideBarStateSub.next(state);
    }
}
