import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import {
  NbActionsModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_THEME } from '@theme/styles/theme.default';
import { ToasterModule } from 'angular2-toaster';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { MomentModule } from 'ngx-moment';

import { SearchWidgetModule } from '../modules/organization/search-widget/search-widget.module';

import {
  CompanySelectComponent,
  CustomPopupComponent,
  MenuSelectComponent,
} from './components';
import { SwitchCompanyComponent } from './components/switch-company/switch-company.component';

import { DefaultLayoutComponent, MainLayoutComponent } from './layouts';

import {
  CapitalizePipe,
  DatePipe,
  PluralPipe,
  ReportDatePipe,
  RoundPipe,
  SortPipe,
  SumPipe,
  TimingPipe,
} from './pipes';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ToasterModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  MatButtonModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  PdfJsViewerModule,
  MatIconModule,
];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NgbModule,
  NbIconModule,
  NbContextMenuModule,
  NbEvaIconsModule,
];

const COMPONENTS = [
  CustomPopupComponent,
  DefaultLayoutComponent,
  MainLayoutComponent,
  MenuSelectComponent,
  CompanySelectComponent,
];

const PIPES = [CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, SumPipe, SortPipe, DatePipe, ReportDatePipe];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
    [
      DEFAULT_THEME,
      // COSMIC_THEME
    ],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  // ...NbLayoutModule,
];

@NgModule({
    imports: [
        ...BASE_MODULES,
        ...NB_MODULES,
        SearchWidgetModule,
        RouterModule,
        MomentModule,
        MatDialogModule,
        MatDividerModule,
    ],
    exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
    providers: [
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true, strict: true },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        SwitchCompanyComponent,
    ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
