<div class="datepicker-container">
  <ngb-datepicker
    #dp
    (select)="onDateSelect($event)"
    [displayMonths]="displayMonths"
    [dayTemplate]="t"
    [outsideDays]="outsideDays"
    style="outline: none;"
  ></ngb-datepicker>
</div>
<div class="btns-container d-flex mt-2">
  <button mat-button color="primary" (click)="setToday()">Today</button>
  <button mat-button color="primary" (click)="setYesterday()">Yesterday</button>
  <button mat-button color="primary" (click)="setLastSevenDays()">Last 7 days</button>
  <button mat-button color="primary" (click)="setLastThirtyDays()">Last 30 days</button>
</div>

<ng-template #t let-date="date" let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRangeMethod(date)"
    [class.faded]="isHoveredMethod(date) || isInsideMethod(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
