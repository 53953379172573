import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '@theme/layouts';
import { DashboardComponent } from './dashboard.component';
import { DashboardMentionsComponent } from './components/dashboard-mentions/dashboard-mentions.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
    ],
  },
  {
    path: 'my-messages',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardMentionsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
