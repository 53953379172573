import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { POSearchService } from '../po-search.service';

@Component({
  selector: 'ngx-filter-pill',
  templateUrl: './filter-pill.component.html',
  styleUrls: ['./filter-pill.component.scss'],
})
export class FilterPillComponent implements OnInit {
  @Input() btnTitle: string;
  @Input() btnHint: string;
  @Input() typePill: string;
  @Input() isActive: boolean = false;
  @Output() onClear: EventEmitter<boolean> = new EventEmitter();

  constructor(private searchMenu$: POSearchService) {}

  ngOnInit() {
    this.searchMenu$.toggleMenu$.subscribe(res => (this.isActive = res[0] === this.typePill ? res[1] : false));
  }

  toggle() {
    this.searchMenu$.toggleMenu(this.typePill, !this.isActive);
  }

  isActiveValue() {
    return this.searchMenu$.isActiveSearch(this.typePill);
  }
}
