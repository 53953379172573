/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroMentionCollection } from '../models/lasgro-mention-collection';
import { EmailReplyMessageInputPayload } from '../models/email-reply-message-input-payload';
import { LasgroNote_mentionCollection } from '../models/lasgro-note-_mention-collection';
import { LasgroGeneral_success_payload } from '../models/lasgro-general-_success-_payload';
import { MessageBroadcastInputPayload } from '../models/message-broadcast-input-payload';
import { AddNoteMessagePayload } from '../models/add-note-message-payload';
import { LasgroMessageCollection } from '../models/lasgro-message-collection';
import { AddMessagePayload } from '../models/add-message-payload';
import { LasgroMessage } from '../models/lasgro-message';
import { UpdateMessagePayload } from '../models/update-message-payload';
@Injectable({
  providedIn: 'root',
})
class MessageService extends __BaseService {
  static readonly messageMyMentionsPath = '/messages/my_mentions';
  static readonly messageEmailReplyPath = '/messages/{messageID}/email_reply';
  static readonly messageMyMentionsNotesPath = '/notes/my_mentions';
  static readonly messageEmailReply1Path = '/notes/{messageID}/email_reply';
  static readonly messageBroadcastPath = '/organizations/{organizationID}/messages/broadcast';
  static readonly messageAddNote1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/notes';
  static readonly messageList1Path = '/organizations/{organizationID}/resources/{resourceID}/messages';
  static readonly messageAdd1Path = '/organizations/{organizationID}/resources/{resourceID}/messages';
  static readonly messageShowPath = '/organizations/{organizationID}/resources/{resourceID}/messages/{messageID}';
  static readonly messageDeletePath = '/organizations/{organizationID}/resources/{resourceID}/messages/{messageID}';
  static readonly messageUpdate1Path = '/organizations/{organizationID}/resources/{resourceID}/messages/{messageID}';
  static readonly messageArchivePath = '/organizations/{organizationID}/resources/{resourceID}/messages/{messageID}/archive';
  static readonly messageAddNotePath = '/purchase_orders/{purchaseOrderID}/notes';
  static readonly messageListPath = '/resources/{resourceID}/messages';
  static readonly messageAddPath = '/resources/{resourceID}/messages';
  static readonly messageShow1Path = '/resources/{resourceID}/messages/{messageID}';
  static readonly messageDelete1Path = '/resources/{resourceID}/messages/{messageID}';
  static readonly messageUpdatePath = '/resources/{resourceID}/messages/{messageID}';
  static readonly messageArchive1Path = '/resources/{resourceID}/messages/{messageID}/archive';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * my_mentions message
   *
   * Retrieves mentions for the logged user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageMyMentionsParams` containing the following parameters:
   *
   * - `parentID`:
   *
   * - `childID`:
   *
   * @return OK
   */
  messageMyMentionsResponse(params: MessageService.MessageMyMentionsParams): __Observable<__StrictHttpResponse<LasgroMentionCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parentID != null) __params = __params.set('parentID', params.parentID.toString());
    if (params.childID != null) __params = __params.set('childID', params.childID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/messages/my_mentions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMentionCollection>;
      })
    );
  }
  /**
   * my_mentions message
   *
   * Retrieves mentions for the logged user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageMyMentionsParams` containing the following parameters:
   *
   * - `parentID`:
   *
   * - `childID`:
   *
   * @return OK
   */
  messageMyMentions(params: MessageService.MessageMyMentionsParams): __Observable<LasgroMentionCollection> {
    return this.messageMyMentionsResponse(params).pipe(
      __map(_r => _r.body as LasgroMentionCollection)
    );
  }

  /**
   * email_reply message
   *
   * Processes a message received from an email reply
   * @param params The `MessageService.MessageEmailReplyParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `messageID`:
   */
  messageEmailReplyResponse(params: MessageService.MessageEmailReplyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/messages/${encodeURIComponent(String(params.messageID))}/email_reply`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * email_reply message
   *
   * Processes a message received from an email reply
   * @param params The `MessageService.MessageEmailReplyParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `messageID`:
   */
  messageEmailReply(params: MessageService.MessageEmailReplyParams): __Observable<null> {
    return this.messageEmailReplyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * my_mentions_notes message
   *
   * Retrieves mentions for the logged user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageMyMentionsNotesParams` containing the following parameters:
   *
   * - `parentID`:
   *
   * - `childID`:
   *
   * @return OK
   */
  messageMyMentionsNotesResponse(params: MessageService.MessageMyMentionsNotesParams): __Observable<__StrictHttpResponse<LasgroNote_mentionCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parentID != null) __params = __params.set('parentID', params.parentID.toString());
    if (params.childID != null) __params = __params.set('childID', params.childID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notes/my_mentions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroNote_mentionCollection>;
      })
    );
  }
  /**
   * my_mentions_notes message
   *
   * Retrieves mentions for the logged user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageMyMentionsNotesParams` containing the following parameters:
   *
   * - `parentID`:
   *
   * - `childID`:
   *
   * @return OK
   */
  messageMyMentionsNotes(params: MessageService.MessageMyMentionsNotesParams): __Observable<LasgroNote_mentionCollection> {
    return this.messageMyMentionsNotesResponse(params).pipe(
      __map(_r => _r.body as LasgroNote_mentionCollection)
    );
  }

  /**
   * email_reply message
   *
   * Processes a message received from an email reply
   * @param params The `MessageService.MessageEmailReply1Params` containing the following parameters:
   *
   * - `payload`:
   *
   * - `messageID`:
   */
  messageEmailReply1Response(params: MessageService.MessageEmailReply1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/notes/${encodeURIComponent(String(params.messageID))}/email_reply`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * email_reply message
   *
   * Processes a message received from an email reply
   * @param params The `MessageService.MessageEmailReply1Params` containing the following parameters:
   *
   * - `payload`:
   *
   * - `messageID`:
   */
  messageEmailReply1(params: MessageService.MessageEmailReply1Params): __Observable<null> {
    return this.messageEmailReply1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * broadcast message
   *
   * Broadcasts a single message over multiple parent resources
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageBroadcastParams` containing the following parameters:
   *
   * - `payload`: The request payload for broadcast a message in bulk accross parent resources
   *
   * - `organizationID`:
   *
   * - `mention_all`:
   *
   * - `is_contract`:
   *
   * @return OK
   */
  messageBroadcastResponse(params: MessageService.MessageBroadcastParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.mentionAll != null) __params = __params.set('mention_all', params.mentionAll.toString());
    if (params.isContract != null) __params = __params.set('is_contract', params.isContract.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/messages/broadcast`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * broadcast message
   *
   * Broadcasts a single message over multiple parent resources
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageBroadcastParams` containing the following parameters:
   *
   * - `payload`: The request payload for broadcast a message in bulk accross parent resources
   *
   * - `organizationID`:
   *
   * - `mention_all`:
   *
   * - `is_contract`:
   *
   * @return OK
   */
  messageBroadcast(params: MessageService.MessageBroadcastParams): __Observable<LasgroGeneral_success_payload> {
    return this.messageBroadcastResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * add_note message
   *
   * Adds a new purchase order note. This is an endpoint that provides legacy support for message add
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageAddNote1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  messageAddNote1Response(params: MessageService.MessageAddNote1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_note message
   *
   * Adds a new purchase order note. This is an endpoint that provides legacy support for message add
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageAddNote1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  messageAddNote1(params: MessageService.MessageAddNote1Params): __Observable<null> {
    return this.messageAddNote1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list message
   *
   * Lists messages related to resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageList1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `child_id`:
   *
   * @return OK
   */
  messageList1Response(params: MessageService.MessageList1Params): __Observable<__StrictHttpResponse<LasgroMessageCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.childId != null) __params = __params.set('child_id', params.childId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMessageCollection>;
      })
    );
  }
  /**
   * list message
   *
   * Lists messages related to resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageList1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `child_id`:
   *
   * @return OK
   */
  messageList1(params: MessageService.MessageList1Params): __Observable<LasgroMessageCollection> {
    return this.messageList1Response(params).pipe(
      __map(_r => _r.body as LasgroMessageCollection)
    );
  }

  /**
   * add message
   *
   * Adds a new resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageAdd1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  messageAdd1Response(params: MessageService.MessageAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add message
   *
   * Adds a new resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageAdd1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  messageAdd1(params: MessageService.MessageAdd1Params): __Observable<null> {
    return this.messageAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show message
   *
   * Retrieve resource messages with given UUID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageShowParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `messageID`: Resource Message ID
   *
   * @return OK
   */
  messageShowResponse(params: MessageService.MessageShowParams): __Observable<__StrictHttpResponse<LasgroMessage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/messages/${encodeURIComponent(String(params.messageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMessage>;
      })
    );
  }
  /**
   * show message
   *
   * Retrieve resource messages with given UUID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageShowParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `messageID`: Resource Message ID
   *
   * @return OK
   */
  messageShow(params: MessageService.MessageShowParams): __Observable<LasgroMessage> {
    return this.messageShowResponse(params).pipe(
      __map(_r => _r.body as LasgroMessage)
    );
  }

  /**
   * delete message
   *
   * Deletes a resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageDeleteParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `messageID`:
   */
  messageDeleteResponse(params: MessageService.MessageDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/messages/${encodeURIComponent(String(params.messageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete message
   *
   * Deletes a resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageDeleteParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `messageID`:
   */
  messageDelete(params: MessageService.MessageDeleteParams): __Observable<null> {
    return this.messageDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update message
   *
   * Updates a resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageUpdate1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `messageID`:
   *
   * @return OK
   */
  messageUpdate1Response(params: MessageService.MessageUpdate1Params): __Observable<__StrictHttpResponse<LasgroMessage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/messages/${encodeURIComponent(String(params.messageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMessage>;
      })
    );
  }
  /**
   * update message
   *
   * Updates a resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageUpdate1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `messageID`:
   *
   * @return OK
   */
  messageUpdate1(params: MessageService.MessageUpdate1Params): __Observable<LasgroMessage> {
    return this.messageUpdate1Response(params).pipe(
      __map(_r => _r.body as LasgroMessage)
    );
  }

  /**
   * archive message
   *
   * Archives a resource's message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageArchiveParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `messageID`:
   */
  messageArchiveResponse(params: MessageService.MessageArchiveParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/messages/${encodeURIComponent(String(params.messageID))}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * archive message
   *
   * Archives a resource's message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageArchiveParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `messageID`:
   */
  messageArchive(params: MessageService.MessageArchiveParams): __Observable<null> {
    return this.messageArchiveResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add_note message
   *
   * Adds a new purchase order note. This is an endpoint that provides legacy support for message add
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageAddNoteParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  messageAddNoteResponse(params: MessageService.MessageAddNoteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_note message
   *
   * Adds a new purchase order note. This is an endpoint that provides legacy support for message add
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageAddNoteParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  messageAddNote(params: MessageService.MessageAddNoteParams): __Observable<null> {
    return this.messageAddNoteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list message
   *
   * Lists messages related to resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageListParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `child_id`:
   *
   * @return OK
   */
  messageListResponse(params: MessageService.MessageListParams): __Observable<__StrictHttpResponse<LasgroMessageCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.childId != null) __params = __params.set('child_id', params.childId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMessageCollection>;
      })
    );
  }
  /**
   * list message
   *
   * Lists messages related to resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageListParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `child_id`:
   *
   * @return OK
   */
  messageList(params: MessageService.MessageListParams): __Observable<LasgroMessageCollection> {
    return this.messageListResponse(params).pipe(
      __map(_r => _r.body as LasgroMessageCollection)
    );
  }

  /**
   * add message
   *
   * Adds a new resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageAddParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  messageAddResponse(params: MessageService.MessageAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add message
   *
   * Adds a new resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageAddParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  messageAdd(params: MessageService.MessageAddParams): __Observable<null> {
    return this.messageAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show message
   *
   * Retrieve resource messages with given UUID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageShow1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `messageID`: Resource Message ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  messageShow1Response(params: MessageService.MessageShow1Params): __Observable<__StrictHttpResponse<LasgroMessage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/messages/${encodeURIComponent(String(params.messageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMessage>;
      })
    );
  }
  /**
   * show message
   *
   * Retrieve resource messages with given UUID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageShow1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `messageID`: Resource Message ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  messageShow1(params: MessageService.MessageShow1Params): __Observable<LasgroMessage> {
    return this.messageShow1Response(params).pipe(
      __map(_r => _r.body as LasgroMessage)
    );
  }

  /**
   * delete message
   *
   * Deletes a resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageDelete1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `messageID`:
   *
   * - `organizationID`:
   */
  messageDelete1Response(params: MessageService.MessageDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/messages/${encodeURIComponent(String(params.messageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete message
   *
   * Deletes a resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageDelete1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `messageID`:
   *
   * - `organizationID`:
   */
  messageDelete1(params: MessageService.MessageDelete1Params): __Observable<null> {
    return this.messageDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update message
   *
   * Updates a resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageUpdateParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `messageID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  messageUpdateResponse(params: MessageService.MessageUpdateParams): __Observable<__StrictHttpResponse<LasgroMessage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/messages/${encodeURIComponent(String(params.messageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroMessage>;
      })
    );
  }
  /**
   * update message
   *
   * Updates a resources' message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageUpdateParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `messageID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  messageUpdate(params: MessageService.MessageUpdateParams): __Observable<LasgroMessage> {
    return this.messageUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroMessage)
    );
  }

  /**
   * archive message
   *
   * Archives a resource's message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageArchive1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `messageID`:
   *
   * - `organizationID`:
   */
  messageArchive1Response(params: MessageService.MessageArchive1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/messages/${encodeURIComponent(String(params.messageID))}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * archive message
   *
   * Archives a resource's message
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `MessageService.MessageArchive1Params` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `messageID`:
   *
   * - `organizationID`:
   */
  messageArchive1(params: MessageService.MessageArchive1Params): __Observable<null> {
    return this.messageArchive1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MessageService {

  /**
   * Parameters for messageMyMentions
   */
  export interface MessageMyMentionsParams {
    parentID?: string;
    childID?: string;
  }

  /**
   * Parameters for messageEmailReply
   */
  export interface MessageEmailReplyParams {
    payload: EmailReplyMessageInputPayload;
    messageID: string;
  }

  /**
   * Parameters for messageMyMentionsNotes
   */
  export interface MessageMyMentionsNotesParams {
    parentID?: string;
    childID?: string;
  }

  /**
   * Parameters for messageEmailReply1
   */
  export interface MessageEmailReply1Params {
    payload: EmailReplyMessageInputPayload;
    messageID: string;
  }

  /**
   * Parameters for messageBroadcast
   */
  export interface MessageBroadcastParams {

    /**
     * The request payload for broadcast a message in bulk accross parent resources
     */
    payload: MessageBroadcastInputPayload;
    organizationID: string;
    mentionAll?: boolean;
    isContract?: boolean;
  }

  /**
   * Parameters for messageAddNote1
   */
  export interface MessageAddNote1Params {
    purchaseOrderID: string;
    payload: AddNoteMessagePayload;
    organizationID: string;
  }

  /**
   * Parameters for messageList1
   */
  export interface MessageList1Params {
    resourceID: string;
    organizationID: string;
    childId?: string;
  }

  /**
   * Parameters for messageAdd1
   */
  export interface MessageAdd1Params {
    resourceID: string;
    payload: AddMessagePayload;
    organizationID: string;
  }

  /**
   * Parameters for messageShow
   */
  export interface MessageShowParams {
    resourceID: string;
    organizationID: string;

    /**
     * Resource Message ID
     */
    messageID: string;
  }

  /**
   * Parameters for messageDelete
   */
  export interface MessageDeleteParams {
    resourceID: string;
    organizationID: string;
    messageID: string;
  }

  /**
   * Parameters for messageUpdate1
   */
  export interface MessageUpdate1Params {
    resourceID: string;
    payload: UpdateMessagePayload;
    organizationID: string;
    messageID: string;
  }

  /**
   * Parameters for messageArchive
   */
  export interface MessageArchiveParams {
    resourceID: string;
    organizationID: string;
    messageID: string;
  }

  /**
   * Parameters for messageAddNote
   */
  export interface MessageAddNoteParams {
    purchaseOrderID: string;
    payload: AddNoteMessagePayload;
    organizationID?: string;
  }

  /**
   * Parameters for messageList
   */
  export interface MessageListParams {
    resourceID: string;
    organizationID?: string;
    childId?: string;
  }

  /**
   * Parameters for messageAdd
   */
  export interface MessageAddParams {
    resourceID: string;
    payload: AddMessagePayload;
    organizationID?: string;
  }

  /**
   * Parameters for messageShow1
   */
  export interface MessageShow1Params {
    resourceID: string;

    /**
     * Resource Message ID
     */
    messageID: string;
    organizationID?: string;
  }

  /**
   * Parameters for messageDelete1
   */
  export interface MessageDelete1Params {
    resourceID: string;
    messageID: string;
    organizationID?: string;
  }

  /**
   * Parameters for messageUpdate
   */
  export interface MessageUpdateParams {
    resourceID: string;
    payload: UpdateMessagePayload;
    messageID: string;
    organizationID?: string;
  }

  /**
   * Parameters for messageArchive1
   */
  export interface MessageArchive1Params {
    resourceID: string;
    messageID: string;
    organizationID?: string;
  }
}

export { MessageService }
