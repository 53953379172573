import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { User } from '@app/models';
import { ContractHelper } from '@app/shared/helpers/contract.helper';
import { Store } from '@ngrx/store';
import { LasgroActivity_log } from 'api/models';
import { ActivityLogsService } from 'api/services';
import { ResourceActionsComponent } from '../../resource-actions/resource-actions.component';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-logs',
  templateUrl: './contract-logs.component.html',
  styleUrls: ['./contract-logs.component.scss'],
  imports: [
    CommonModule,
    ResourceActionsComponent,
  ]
})
export class ContractLogsComponent extends ContractBaseComponent implements OnInit, AfterViewInit {
  actions = [] as LasgroActivity_log[];
  constructor(
    public injector: Injector,
    private logsServcie: ActivityLogsService,
    private contractHelper: ContractHelper,
    public store: Store<{ user: User }>
  ) {
    super(injector, store);
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.initContractLogs();
    this.contractHelper.contractListent.listen().subscribe(() => {
      this.initContractLogs();
    });
  }

  initContractLogs() {
    this.logsServcie.activityLogsList(this.contractId).subscribe((actions: LasgroActivity_log[]) => {
      this.actions = actions;
    });
  }
}
