/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroPage_template } from '../models/lasgro-page-_template';
import { PageTemplateUpdatePayload } from '../models/page-template-update-payload';
import { PageTemplateAddPayload } from '../models/page-template-add-payload';
import { LasgroPage_templateCollection } from '../models/lasgro-page-_template-collection';
@Injectable({
  providedIn: 'root',
})
class PageTemplatesService extends __BaseService {
  static readonly pageTemplatesUpdatePath = '/page_templates';
  static readonly pageTemplatesAddPath = '/page_templates';
  static readonly pageTemplatesListPath = '/page_templates/organization';
  static readonly pageTemplatesShowPath = '/page_templates/{pageTemplateID}';
  static readonly pageTemplatesDeletePath = '/page_templates/{pageTemplateID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  pageTemplatesUpdateResponse(payload: PageTemplateUpdatePayload): __Observable<__StrictHttpResponse<LasgroPage_template>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/page_templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPage_template>;
      })
    );
  }
  /**
   * update page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  pageTemplatesUpdate(payload: PageTemplateUpdatePayload): __Observable<LasgroPage_template> {
    return this.pageTemplatesUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroPage_template)
    );
  }

  /**
   * add page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  pageTemplatesAddResponse(payload: PageTemplateAddPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/page_templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  pageTemplatesAdd(payload: PageTemplateAddPayload): __Observable<null> {
    return this.pageTemplatesAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  pageTemplatesListResponse(): __Observable<__StrictHttpResponse<LasgroPage_templateCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/page_templates/organization`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPage_templateCollection>;
      })
    );
  }
  /**
   * list page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  pageTemplatesList(): __Observable<LasgroPage_templateCollection> {
    return this.pageTemplatesListResponse().pipe(
      __map(_r => _r.body as LasgroPage_templateCollection)
    );
  }

  /**
   * show page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @param pageTemplateID undefined
   * @return OK
   */
  pageTemplatesShowResponse(pageTemplateID: string): __Observable<__StrictHttpResponse<LasgroPage_template>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/page_templates/${encodeURIComponent(String(pageTemplateID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPage_template>;
      })
    );
  }
  /**
   * show page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @param pageTemplateID undefined
   * @return OK
   */
  pageTemplatesShow(pageTemplateID: string): __Observable<LasgroPage_template> {
    return this.pageTemplatesShowResponse(pageTemplateID).pipe(
      __map(_r => _r.body as LasgroPage_template)
    );
  }

  /**
   * delete page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @param pageTemplateID undefined
   */
  pageTemplatesDeleteResponse(pageTemplateID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/page_templates/${encodeURIComponent(String(pageTemplateID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete page_templates
   *
   * Required security scopes:
   *   * `api:access`
   * @param pageTemplateID undefined
   */
  pageTemplatesDelete(pageTemplateID: string): __Observable<null> {
    return this.pageTemplatesDeleteResponse(pageTemplateID).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PageTemplatesService {
}

export { PageTemplatesService }
