<ng-container [ngSwitch]="inputType">
  <!-- text -->
  <mat-form-field *ngSwitchCase="'text'">
    <input [type]="inputType" matInput [ngModel]="value" (ngModelChange)="updateValue($event)" [placeholder]="placeholder" />
  </mat-form-field>

  <!-- number -->
  <mat-form-field *ngSwitchCase="'number'">
    <input [type]="inputType" matInput [ngModel]="value" (ngModelChange)="updateValue($event)" [placeholder]="placeholder" />
  </mat-form-field>

  <!-- select -->
  <mat-form-field *ngSwitchCase="'select'">
    <mat-select [ngModel]="value" (ngModelChange)="updateValue($event)" [placeholder]="placeholder">
      <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- autocomplete -->
  <mat-form-field *ngSwitchCase="'autocomplete'">
    <input matInput [ngModel]="value" (ngModelChange)="updateValue($event)" [placeholder]="placeholder" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- checkbox -->
  <mat-checkbox *ngSwitchCase="'checkbox'" color="primary"  [ngModel]="value" (ngModelChange)="updateValue($event)">{{ placeholder }}</mat-checkbox>

  <!-- radio -->
  <mat-radio-group *ngSwitchCase="'radio'" color="primary"  [ngModel]="value" (ngModelChange)="updateValue($event)">
    <mat-radio-button *ngFor="let option of options" [value]="option">{{ option }}</mat-radio-button>
  </mat-radio-group>

  <!-- switch -->
  <mat-slide-toggle *ngSwitchCase="'switch'" color="primary" [ngModel]="value" (ngModelChange)="updateValue($event)">{{ placeholder }}</mat-slide-toggle>

  <!-- datepicker -->
  <mat-form-field *ngSwitchCase="'date'">
    <input matInput [ngModel]="value" (ngModelChange)="updateValue($event)" [matDatepicker]="picker" [placeholder]="placeholder" />
    <mat-datepicker-toggle *ngIf="inputType === 'date'" matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</ng-container>
