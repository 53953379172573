import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { take } from 'rxjs/operators';

export class BusinessValidator {
  static optionalIfExists(secondControlName) {
    return (AC: AbstractControl): ValidationErrors | null => {
      const firstValue = AC.value;
      let secondValue = null;
      if (AC.parent) {
        secondValue = AC.parent.get(secondControlName).value;
        const secondControl = AC.parent.get(secondControlName);
        secondControl.valueChanges
          .pipe(take(1))
          .subscribe(value => {
            AC.updateValueAndValidity({ onlySelf: true, emitEvent: false });
          });
      }

      if (!!firstValue || !!secondValue) {
        return null;
      }
      return { required: true };

    };
  }

  static requiredIfExists(...args) {
    const secondControlName = args[0];
    return (AC: AbstractControl): ValidationErrors | null => {
      const firstValue = AC.value;
      let secondValue = null;
      // let valueExists = []
      if (AC.parent) {
        secondValue = AC.parent.get(secondControlName).value;
        const secondControl = AC.parent.get(secondControlName);

        secondControl.valueChanges
          .pipe(take(1))
          .subscribe(value => {
            AC.updateValueAndValidity({ onlySelf: true, emitEvent: false });
          });
      }
      if (!!secondValue) {
        if (!!firstValue) return null;
        return { required: true };
      }
      return null;

    };
  }

  static allOrNoneRequired(AC: AbstractControl): ValidationErrors | null {
    const formGroup = AC as UntypedFormGroup;
    const controls = Object.values(formGroup.controls);
    return (controls.every(fc => !fc.value) || controls.every(fc => !!fc.value))
      ? null
      : { allOrNoneRequired: true };
  }

  static requiredDisabledFields(...args) {
    const fields = args;
    return (AC: AbstractControl): ValidationErrors | null => {
      const formGroup = AC as UntypedFormGroup;
      return (fields.every(key => !formGroup.get(key).value)) ? { requiredDisabledFields: true } : null;
    };
  }

}
