import { HttpClient } from '@angular/common/http';
import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from '@app/services';

@Directive({
  selector: '[ngxFilesPreview]',
  standalone: true
})
export class FilesPreviewDirective {
  @Input() fileId;
  @Input() file_url;
  @Output() finishedLoading: EventEmitter<any> = new EventEmitter<boolean>();

  constructor(private fileService: FileService, private http: HttpClient) {
  }

  @HostListener('click', ['$event'])
  clickHandler($event) {
    if (this.fileId) {
      this.fileService.downloadFile(this.fileId)
        .subscribe((result) => {
          this.finishedLoading.emit(true);
          const fileName = (result.headers.get('content-disposition').split('filename=')[1]).replace(/"/g, '');
          const fileExt = fileName.split('.').pop();
          if (fileExt === 'pdf') {
            // Show as blob
            /*var newBlob = new Blob([result.body], {type: "application/pdf"});
            var blobURL = URL.createObjectURL(newBlob);
            const w = window.open(blobURL, '_blank');
            w.document.title = fileName;*/

            /* var downloadLink = document.createElement("a");
             downloadLink.href = blobURL;
             downloadLink.target = '_blank';
             downloadLink.download = fileName;
   
             document.body.appendChild(downloadLink);
             downloadLink.click();
             document.body.removeChild(downloadLink);*/

            // Show as preview
            this.fileService.viewPdf(result.body, fileName);
          } else if (['jpg', 'jpeg', 'png', 'svg', 'webp'].includes(fileExt)) {
            this.fileService.viewImage(result.body, fileName);
          } else {
            this.fileService.downloadBlobFile(result.body, fileName);
          }
        }, (error) => {
          this.finishedLoading.emit(true);
          console.error(error);
        });
    }
    if (this.file_url) {
      this.http.get(this.file_url, { responseType: 'blob' }).subscribe(
        (blob) => {
          this.finishedLoading.emit(true);
          const contentDisposition = blob.type.includes('pdf') ? `attachment; filename="file.pdf"` : null;
          this.handleFile(blob, contentDisposition);
        },
        (error) => {
          this.finishedLoading.emit(true);
          console.error('Error downloading file from URL:', error);
        }
      );
    }
  }

  private handleFile(blob: Blob, contentDisposition: string | null) {
    const fileName = contentDisposition
      ? contentDisposition.split('filename=')[1]?.replace(/"/g, '') || 'file'
      : 'file';
    const fileExt = fileName.split('.').pop()?.toLowerCase();

    if (fileExt === 'pdf') {
      this.fileService.viewPdf(blob, fileName);
    } else if (['jpg', 'jpeg', 'png', 'svg', 'webp'].includes(fileExt)) {
      this.fileService.viewImage(blob, fileName);
    } else {
      this.fileService.downloadBlobFile(blob, fileName);
    }
  }
}
