import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
  @Input() displayMonths: number = 2;
  @Input() outsideDays: string = 'hidden';
  @Input() fromDate: NgbDate;
  @Input() untilDate: NgbDate;
  @Output() dateSelected = new EventEmitter<[NgbDate, NgbDate]>();
  @ViewChild('dp', { static: true }) datePicker: NgbDatepicker;
  hoveredDate: NgbDate;

  constructor(private calendar: NgbCalendar) {}

  ngOnInit() {}

  onDateSelect(ngbDateStruct: NgbDateStruct) {
    const date: NgbDate = new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
    if (!this.fromDate && !this.untilDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.untilDate && date.after(this.fromDate)) {
      this.untilDate = date;
    } else {
      this.untilDate = null;
      this.fromDate = date;
    }
    this.dateSelected.emit([this.fromDate, this.untilDate]);
  }

  isHoveredMethod(ngbDateStruct: NgbDateStruct) {
    const date: NgbDate = new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
    return (
      this.fromDate && !this.untilDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInsideMethod(ngbDateStruct: NgbDateStruct) {
    const date: NgbDate = new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
    return date.after(this.fromDate) && date.before(this.untilDate);
  }

  isRangeMethod(ngbDateStruct: NgbDateStruct) {
    const date: NgbDate = new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
    return (
      date.equals(this.fromDate) ||
      date.equals(this.untilDate) ||
      this.isInsideMethod(date) ||
      this.isHoveredMethod(date)
    );
  }

  setToday() {
    this.fromDate = this.calendar.getToday();
    this.untilDate = null;
    this.dateSelected.emit([this.fromDate, this.untilDate]);
  }

  setYesterday() {
    const today = this.calendar.getToday();
    this.fromDate = this.calendar.getNext(today, 'd', -1);
    this.untilDate = null;
    this.dateSelected.emit([this.fromDate, this.untilDate]);
  }

  setLastSevenDays() {
    const today = this.calendar.getToday();
    this.untilDate = this.calendar.getToday();
    this.fromDate = this.calendar.getNext(today, 'd', -6);
    this.dateSelected.emit([this.fromDate, this.untilDate]);
  }

  setLastThirtyDays() {
    const today = this.calendar.getToday();
    this.fromDate = this.calendar.getNext(today, 'd', -30);
    this.untilDate = this.calendar.getNext(this.fromDate, 'd', 30);
    this.dateSelected.emit([this.fromDate, this.untilDate]);
  }
}
