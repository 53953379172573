/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroCommissioned_poCollection } from '../models/lasgro-commissioned-_po-collection';
@Injectable({
  providedIn: 'root',
})
class CommissionedPoService extends __BaseService {
  static readonly commissionedPoList1Path = '/commissioned_pos';
  static readonly commissionedPoListPath = '/organizations/{organizationID}/commissioned_pos';
  static readonly commissionedPoListDraftsPath = '/organizations/{organizationID}/purchase_orders_drafts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list commissioned_po
   *
   * Lists commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedPoService.CommissionedPoList1Params` containing the following parameters:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `organizationID`:
   *
   * @return OK
   */
  commissionedPoList1Response(params: CommissionedPoService.CommissionedPoList1Params): __Observable<__StrictHttpResponse<LasgroCommissioned_poCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/commissioned_pos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_poCollection>;
      })
    );
  }
  /**
   * list commissioned_po
   *
   * Lists commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedPoService.CommissionedPoList1Params` containing the following parameters:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `organizationID`:
   *
   * @return OK
   */
  commissionedPoList1(params: CommissionedPoService.CommissionedPoList1Params): __Observable<LasgroCommissioned_poCollection> {
    return this.commissionedPoList1Response(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_poCollection)
    );
  }

  /**
   * list commissioned_po
   *
   * Lists commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedPoService.CommissionedPoListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * @return OK
   */
  commissionedPoListResponse(params: CommissionedPoService.CommissionedPoListParams): __Observable<__StrictHttpResponse<LasgroCommissioned_poCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/commissioned_pos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_poCollection>;
      })
    );
  }
  /**
   * list commissioned_po
   *
   * Lists commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedPoService.CommissionedPoListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * @return OK
   */
  commissionedPoList(params: CommissionedPoService.CommissionedPoListParams): __Observable<LasgroCommissioned_poCollection> {
    return this.commissionedPoListResponse(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_poCollection)
    );
  }

  /**
   * list_drafts commissioned_po
   *
   * List of purchase order drafts
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedPoService.CommissionedPoListDraftsParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `deleted`:
   *
   * @return OK
   */
  commissionedPoListDraftsResponse(params: CommissionedPoService.CommissionedPoListDraftsParams): __Observable<__StrictHttpResponse<LasgroCommissioned_poCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.deleted != null) __params = __params.set('deleted', params.deleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders_drafts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_poCollection>;
      })
    );
  }
  /**
   * list_drafts commissioned_po
   *
   * List of purchase order drafts
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedPoService.CommissionedPoListDraftsParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `deleted`:
   *
   * @return OK
   */
  commissionedPoListDrafts(params: CommissionedPoService.CommissionedPoListDraftsParams): __Observable<LasgroCommissioned_poCollection> {
    return this.commissionedPoListDraftsResponse(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_poCollection)
    );
  }
}

module CommissionedPoService {

  /**
   * Parameters for commissionedPoList1
   */
  export interface CommissionedPoList1Params {

    /**
     * What view type to show for the checklist
     */
    viewType?: 'tiny' | 'report';
    organizationID?: string;
  }

  /**
   * Parameters for commissionedPoList
   */
  export interface CommissionedPoListParams {
    organizationID: string;

    /**
     * What view type to show for the checklist
     */
    viewType?: 'tiny' | 'report';
  }

  /**
   * Parameters for commissionedPoListDrafts
   */
  export interface CommissionedPoListDraftsParams {
    organizationID: string;
    deleted?: boolean;
  }
}

export { CommissionedPoService }
