import { Directive, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';

/* tslint:disable */
@Directive({
  selector: '[ngEditableSelect]',
})
/* tslint:enable */
export class NgEditableSelectDirective implements OnInit, OnDestroy {

  private static comboBoxSelector = 'input[role="combobox"]';
  private static className = 'ng-select-edit-focused';
  private subscriptions: Subscription[] = [];

  constructor(private ngSelect: NgSelectComponent, private render: Renderer2) {}

  ngOnInit() {
    this.render.addClass(
      this.ngSelect.searchInput.nativeElement,
      NgEditableSelectDirective.className,
    );
    this.subscriptions = [
      this.ngSelect.blurEvent.subscribe(evt => this.onBlur(evt)),
      this.ngSelect.changeEvent.subscribe(evt => this.onChange(evt)),
      this.ngSelect.focusEvent.subscribe(evt => this.onFocus(evt)),
      this.ngSelect.clearEvent.subscribe(evt => this.onClear(evt)),
    ];
  }

  ngOnDestroy() {
    this.render.removeClass(
      this.ngSelect.searchInput.nativeElement,
      NgEditableSelectDirective.className,
    );
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getComboBox(): HTMLInputElement {
    return this.ngSelect.searchInput.nativeElement.querySelector(
      NgEditableSelectDirective.comboBoxSelector,
    );
  }

  onFocus($event) {
    const comboBox = this.getComboBox();
    if (comboBox && this.ngSelect.selectedItems.length) {
      comboBox.value = this.ngSelect.selectedItems[0].label;
    }
  }

  onChange($event) {
    const comboBox = this.getComboBox();
    if (comboBox && this.ngSelect.selectedItems.length) {
      setTimeout(() => {
        comboBox.value = this.ngSelect.selectedItems[0].label;
      }, 0);
    }
  }

  onClear($event) {
    const comboBox = this.getComboBox();
    if (comboBox) {
      comboBox.value = '';
      comboBox.blur();
    }
  }

  onBlur($event) {
    const comboBox = this.getComboBox();
    if (comboBox) {
      comboBox.value = '';
    }
  }

}
