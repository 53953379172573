import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyDto, FileItemDto, UserDTO } from '@models/index';
import { User } from '@app/models';
import { CompanyService } from '@services/company.service';
import { Toaster } from '@services/toaster.service';
import { Store } from '@ngrx/store';
import { MentionConfig, MentionModule } from 'angular-mentions';
import { Observable, forkJoin, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from '@env/environment';
import Swal from 'sweetalert2';
import * as es6printJS from 'print-js';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { NgxLoadingModule } from 'ngx-loading';
import { TitleDividerComponent } from '../title-divider/title-divider.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimeAgoPipe } from '@app/shared/pipes/time-ago.pipe';
import { LinkyModule } from 'ngx-linky';
import { AddMessageDto, MessageDto } from '@app/models/message.model';
import { HttpResponse } from '@angular/common/http';
import { FileService } from '@app/services';
import { PurchaseOrderFilesService } from 'api/services';
import { SmallFileViewerComponent } from '../small-file-viewer/small-file-viewer.component';

@Component({
  standalone: true,
  selector: 'ngx-po-notes-form',
  templateUrl: './po-notes-form.component.html',
  styleUrls: ['./po-notes-form.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    NgxLoadingModule,
    TitleDividerComponent,
    TimeAgoPipe,
    LinkyModule,
    MentionModule,
    SmallFileViewerComponent,
  ],
})
export class PoNotesFormComponent implements OnInit {
  readonly FILE_LIMIT: number = 10000000;
  @Input() unreadMessages: number;
  @Input() poID: string;
  @Input() customerID: string;
  @Input() supplierID: string;
  @Input() allowEdit: boolean;
  @Input() showTitle: boolean = true;
  @Input() notes = [] as MessageDto[];
  @Input() supplier: CompanyDto;
  @Input() customer: CompanyDto;
  @Input() isScrollingEnabled: boolean = false;
  @Input() attachmentsIDs: string[] = [];
  @LocalStorage() organizationId: string;

  @Output() notesChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  user$: Observable<User>;
  currentUser: User;

  visibility = 'PRIVATE';
  visibilityEnabled = true;

  loading = false;

  users: UserDTO[] = [];
  note: string = '';
  noteMentions = [];
  parentNoteID: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private $company: CompanyService,
    private fileService: PurchaseOrderFilesService,
    private $toaster: Toaster,
    private store: Store<{ user: User }>,
  ) {}

  get mentionsConfig(): MentionConfig {
    return {
      mentions: [
        {
          items: this.users,
          triggerChar: '@',
          disableSort: true,
          labelKey: 'fullName',
          returnTrigger: true,
          mentionSelect: this.mentionItemSelected,
        },
      ],
    };
  }

  get noteHasMentions() {
    return this.noteMentions.length > 0;
  }

  ngOnInit() {
    this.user$ = this.store.select('user');
    this.user$.subscribe((user) => {
      this.currentUser = user as User;
      if (this.currentUser.organization_id === this.supplierID || this.currentUser.role == 'Lasgro Admin') {
        this.visibilityEnabled = false;
        this.visibility = 'PUBLIC';
      }
    });

    this.getMentionableUsers();
  }

  getMentionableUsers() {
    const customerCompanyId = this.customerID;
    const supplierCompanyId = this.supplierID;
    return forkJoin([
      this.$company.users(customerCompanyId),
      this.$company.users(supplierCompanyId),
      this.customer ? of(this.customer) : this.$company.getById(customerCompanyId),
      this.supplier ? of(this.supplier) : this.$company.getById(supplierCompanyId),
    ])
      .pipe(takeUntil(this.destroy$))
      .pipe(
        switchMap(([customerUsers, supplierUsers, customerCompany, supplierCompany]) => {
          return of([
            ...customerUsers
              // .filter(u => u.id !== this.currentUser.id)
              .map((u) => ({ ...u, company: customerCompany })),
            ...supplierUsers
              // .filter(u => u.id !== this.currentUser.id)
              .map((u) => ({ ...u, company: supplierCompany })),
          ]);
        }),
      )
      .subscribe((users) => {
        this.users = users.map((u) => new UserDTO(u));
      });
  }

  async addNote() {
    this.loading = true;
    try {
      if (this.note.length <= 0 && !this.noteHasMentions) {
        return;
      }

      await this.$company.addNote(this.organizationId, this.poID, {
        content: this.note,
        mentions: this.noteMentions,
        parent_note_id: this.parentNoteID ? this.parentNoteID : undefined,
        visibility: this.visibility,
        attachments: this.attachmentsIDs,
      } as AddMessageDto);

      this.attachmentsIDs = [];
      this.parentNoteID = undefined;
      this.note = '';
      this.$toaster.show('success', 'Success', 'Message sent');
      if (this.visibilityEnabled === true) {
        this.visibility = 'PRIVATE';
      }
      this.loading = false;
      this.notesChangedEvent.emit();
    } catch (error) {
      console.error(error);
      this.$toaster.show('error', 'Error', 'An error occurred, please try again later.');
      this.loading = false;
    }
  }

  deleteNote(note) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Mentioned users may have a copy of this message in their emails, delete anyways?',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then(
      (swalResult) => {
        if (swalResult && swalResult?.isConfirmed === true) {
          this.loading = true;
          this.$company.deleteNote(this.organizationId, this.poID, note.id).then(
            (result) => {
              this.$toaster.show('success', 'Success', 'The message was deleted successfully.');
              this.loading = false;
              this.notesChangedEvent.emit();
            },
            (error) => {
              console.error(error);
              this.loading = false;
              this.$toaster.show('error', 'Error', 'An error ocurred, please try again later');
            },
          );
        }
      },
      (error) => {
        console.error(error);
      },
    );
  }

  async handleNoteChange($event: string) {
    const mentionRegex = /@([\w\.]+)/g;
    const regexMatch = $event.match(mentionRegex);

    if (!(regexMatch && regexMatch.length > 0)) {
      this.noteMentions = [];
      return;
    }

    const userIdsMap = new Map<string, boolean>();
    let match = mentionRegex.exec($event);
    let hasOutsideUsers = false;

    while (match != null) {
      const mentionedUserFullName = match[1];
      const mentionedUser = this.users.find(
        (user) => user.fullName.split(' ').join('.').split('-').join('.') === mentionedUserFullName,
      );

      if (mentionedUser) {
        console.log(mentionedUser);
        console.log(this.currentUser);

        const showConfirmation =
          mentionedUser.company.id !== this.currentUser.organization_id &&
          !userIdsMap.get(mentionedUser.id) &&
          this.visibility === 'PRIVATE';

        if (showConfirmation) {
          Swal.fire({
            title: 'Confirmation required',
            text: 'Mentioning an user outside your organization will set this message as public. Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Confirm',
            customClass: {
              confirmButton: 'btn btn-primary mr-2',
              cancelButton: 'btn btn-danger ml-2',
            },
            confirmButtonColor: '#41b146',
            cancelButtonColor: '#c74141',
          }).then(
            (swalResult) => {
              if (swalResult && swalResult?.isConfirmed === true) {
                userIdsMap.set(mentionedUser.id, true);
                this.visibility = 'PUBLIC';
                hasOutsideUsers = true;
              }
              if (swalResult && swalResult?.isDismissed === true) {
                this.note = this.note.replace('@' + mentionedUserFullName, '');
              }
            },
            (error) => {
              this.note = this.note.replace('@' + mentionedUserFullName, '');
              console.log(error);
            },
          );
        } else {
          if (mentionedUser.company.id !== this.currentUser.organization_id) {
            hasOutsideUsers = true;
          }
          userIdsMap.set(mentionedUser.id, true);
        }
      }

      match = mentionRegex.exec($event);
    }

    if (!hasOutsideUsers && this.visibilityEnabled === true) {
      this.visibility = 'PRIVATE';
    }
    this.noteMentions = Array.from(userIdsMap.keys());
  }

  mentionItemSelected = ($event: UserDTO) => {
    return `@${$event.fullName.split(' ').join('.').split('-').join('.')} `;
  };

  getCompanyName(companyID) {
    if (companyID === this.supplier.id) {
      return this.supplier.name;
    }
    if (companyID === this.customer.id) {
      return this.customer.name;
    }
    return '';
  }

  printNotes() {
    es6printJS({
      printable: 'notes-container',
      type: 'html',
    });
  }

  async addFile(event) {
    const fileList: FileList = event.srcElement.files;
    const formData: FormData = new FormData();

    if (fileList.length === 0) {
      return;
    }
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file.size > this.FILE_LIMIT) {
        this.$toaster.show('warning', 'File size', 'Maximum file size for uploads is 25MB');
        return;
      }
    }
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      this.loading = true;
      this.fileService
        .purchaseOrderFilesAdd({
          file: file,
          organizationID: this.organizationId,
          resourceID: this.poID,
          resourceType: 'PURCHASE_ORDER',
        } as PurchaseOrderFilesService.PurchaseOrderFilesAddParams)
        .subscribe(
          (filesID: string) => {
            this.attachmentsIDs.push(filesID);
            this.loading = false;
            this.$toaster.show('success', 'Success', 'File has been added');
          },
          (error) => {
            console.error(error);
            const errMsg =
              error.error && error.error.detail ? error.error.detail : 'An error ocurred while uploading a file.';
            this.$toaster.show('error', 'File upload', errMsg);
            this.loading = false;
          },
        );
    }
  }

  async removeFile(fileId) {
    this.fileService
      .purchaseOrderFilesDelete({
        fileID: fileId,
        purchaseOrderID: this.poID,
        organizationID: this.organizationId,
        isLine: false,
        isProduct: false,
      })
      .subscribe((x) => {
        var index = this.attachmentsIDs.indexOf(fileId);
        // Если fileId найден в массиве, удалим его
        if (index !== -1) {
          this.attachmentsIDs.splice(index, 1);
        }

        this.$toaster.show('success', 'Success', 'File has been removed');
      });
  }

  downloadFile(fileID, fileName) {
    this.fileService.purchaseOrderFilesDownload(fileID).subscribe(
      (res: HttpResponse<Blob>) => {
        const blob = new Blob([res.body], {
          type: 'application/octet-stream',
        });
        saveAs(blob, fileName);
      },
      (error) => {
        console.error(error);
        const errMsg =
          error.error && error.error.detail ? error.error.detail : 'An error occurred while downloading the files.';
        this.$toaster.show('error', 'File', errMsg);
      },
    );
  }
}
function saveAs(blob: Blob, fileName: any) {
  throw new Error('Function not implemented.');
}
