<div class="modal-header">
  <span>Provide a reason to re-issue PO</span>
  <button *ngIf="closable" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <mat-form-field>
      <input matInput type="text" placeholder="Re-issue Reason" [(ngModel)]="reason" [required]="required"/>
    </mat-form-field>
  </div>
</div>
<div class="modal-footer po-modal">
  <button
    class="pull-right margin10"
    mat-raised-button
    color="primary"
    [disabled]="required && !reason.length"
    (click)="submit()"
  >
    Submit
  </button>
</div>
