import { FileItemDto } from '@models/file-item.model';
import { LasgroContact, LasgroCustomer_required_fileCollection, LasgroOrganization, LasgroOrganization_document, LasgroSaved_checklistCollection } from 'api/models';
import { ContractDto } from './contract.dto';
import { User } from './user.model';

export class CompanyDto implements LasgroOrganization {
  customer_required_files?: LasgroCustomer_required_fileCollection;
  href: string;
  saved_checklists?: LasgroSaved_checklistCollection;
  time_zone: string;
  id: string;
  name: string;
  plan: "Free" | "Gold Monthly";
  postal_code: string;
  region: string;
  customer_id: number;
  address1: string;
  address2: string;
  city: string;
  country: string;
  government_id: string;
  government_id_type: number;
  can_edit: boolean;
  can_receive_pos: boolean;
  login_as: 'customer' | 'supplier';
  contact: LasgroContact;
  created_at: any;
  created_by: string;
  priorities: any;
  priority: string;
  priority_id: number;
  purchaseOrders: ContractDto[];
  manager: User;
  fob_point: string;
  shipped_via: string;
  port_of_origin: string;
  documents: SupplierLeadDocument[];
  insurance_files: SupplierLeadDocument[];

  logo?: string;
  site_name?: string;
}

export class SupplierLeadDocument implements LasgroOrganization_document {
  customer_lead: { email: string, first_name: string, last_name: string, id: string, phone_number: string };
  expires_at: string;
  file: FileItemDto;
  std_lead_time_days: number;
  std_lead_time_months: number;
  supplier_lead: { email: string, first_name: string, last_name: string, id: string, phone_number: string };
}
