<form (ngSubmit)="submit()" [formGroup]="modalForm">
<div class="modal-header">
  <span *ngIf="!isMetaUpdate">Add a link to a file</span>
  <span *ngIf="isMetaUpdate">Update file metadata</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <mat-form-field *ngIf="!isMetaUpdate">
      <input matInput
        secure-url-input
        formControlName="link"
        type="text" 
        placeholder="Link" 
        [(ngModel)]="link" 
        [required]="!isMetaUpdate"/>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <textarea 
        matInput 
        formControlName="metadata"
        type="text" 
        placeholder="Description" 
        [(ngModel)]="metadata" 
        [required]="isMetaUpdate">
      </textarea>
    </mat-form-field>
  </div>
</div>
<div class="modal-footer po-modal">
  <button
    type="submit"
    mat-raised-button
    class="pull-right margin10"
    color="primary"
    [disabled]="modalForm.invalid"
  >
    Submit
  </button>
</div>
