/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FinishedGoodsReportInputPayload } from '../models/finished-goods-report-input-payload';
import { LasgroFinished_goods } from '../models/lasgro-finished-_goods';
@Injectable({
  providedIn: 'root',
})
class FinishedGoodsReportsService extends __BaseService {
  static readonly finishedGoodsReportsUpdatePath = '/finished_goods_reports/{reportID}';
  static readonly finishedGoodsReportsList1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/finished_goods_reports';
  static readonly finishedGoodsReportsUpdate1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/finished_goods_reports/{reportID}';
  static readonly finishedGoodsReportsListPath = '/purchase_orders/{purchaseOrderID}/finished_goods_reports';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update finished_goods_reports
   *
   * Updates checklist. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinishedGoodsReportsService.FinishedGoodsReportsUpdateParams` containing the following parameters:
   *
   * - `reportID`:
   *
   * - `payload`: Input payload for finished goods reporting
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  finishedGoodsReportsUpdateResponse(params: FinishedGoodsReportsService.FinishedGoodsReportsUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/finished_goods_reports/${encodeURIComponent(String(params.reportID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update finished_goods_reports
   *
   * Updates checklist. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinishedGoodsReportsService.FinishedGoodsReportsUpdateParams` containing the following parameters:
   *
   * - `reportID`:
   *
   * - `payload`: Input payload for finished goods reporting
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  finishedGoodsReportsUpdate(params: FinishedGoodsReportsService.FinishedGoodsReportsUpdateParams): __Observable<null> {
    return this.finishedGoodsReportsUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list finished_goods_reports
   *
   * Finished goods report for purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinishedGoodsReportsService.FinishedGoodsReportsList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  finishedGoodsReportsList1Response(params: FinishedGoodsReportsService.FinishedGoodsReportsList1Params): __Observable<__StrictHttpResponse<LasgroFinished_goods>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/finished_goods_reports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinished_goods>;
      })
    );
  }
  /**
   * list finished_goods_reports
   *
   * Finished goods report for purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinishedGoodsReportsService.FinishedGoodsReportsList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  finishedGoodsReportsList1(params: FinishedGoodsReportsService.FinishedGoodsReportsList1Params): __Observable<LasgroFinished_goods> {
    return this.finishedGoodsReportsList1Response(params).pipe(
      __map(_r => _r.body as LasgroFinished_goods)
    );
  }

  /**
   * update finished_goods_reports
   *
   * Updates checklist. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinishedGoodsReportsService.FinishedGoodsReportsUpdate1Params` containing the following parameters:
   *
   * - `reportID`:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload for finished goods reporting
   *
   * - `organizationID`:
   */
  finishedGoodsReportsUpdate1Response(params: FinishedGoodsReportsService.FinishedGoodsReportsUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/finished_goods_reports/${encodeURIComponent(String(params.reportID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update finished_goods_reports
   *
   * Updates checklist. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinishedGoodsReportsService.FinishedGoodsReportsUpdate1Params` containing the following parameters:
   *
   * - `reportID`:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload for finished goods reporting
   *
   * - `organizationID`:
   */
  finishedGoodsReportsUpdate1(params: FinishedGoodsReportsService.FinishedGoodsReportsUpdate1Params): __Observable<null> {
    return this.finishedGoodsReportsUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list finished_goods_reports
   *
   * Finished goods report for purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinishedGoodsReportsService.FinishedGoodsReportsListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  finishedGoodsReportsListResponse(params: FinishedGoodsReportsService.FinishedGoodsReportsListParams): __Observable<__StrictHttpResponse<LasgroFinished_goods>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/finished_goods_reports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinished_goods>;
      })
    );
  }
  /**
   * list finished_goods_reports
   *
   * Finished goods report for purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinishedGoodsReportsService.FinishedGoodsReportsListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  finishedGoodsReportsList(params: FinishedGoodsReportsService.FinishedGoodsReportsListParams): __Observable<LasgroFinished_goods> {
    return this.finishedGoodsReportsListResponse(params).pipe(
      __map(_r => _r.body as LasgroFinished_goods)
    );
  }
}

module FinishedGoodsReportsService {

  /**
   * Parameters for finishedGoodsReportsUpdate
   */
  export interface FinishedGoodsReportsUpdateParams {
    reportID: string;

    /**
     * Input payload for finished goods reporting
     */
    payload: FinishedGoodsReportInputPayload;
    purchaseOrderID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for finishedGoodsReportsList1
   */
  export interface FinishedGoodsReportsList1Params {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for finishedGoodsReportsUpdate1
   */
  export interface FinishedGoodsReportsUpdate1Params {
    reportID: string;
    purchaseOrderID: string;

    /**
     * Input payload for finished goods reporting
     */
    payload: FinishedGoodsReportInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for finishedGoodsReportsList
   */
  export interface FinishedGoodsReportsListParams {
    purchaseOrderID: string;
    organizationID?: string;
  }
}

export { FinishedGoodsReportsService }
