<div class="">
  <div class="d-flex justify-content-end mb-2 mt-2">
    <div class="mb-2">
      {{ title }}
    </div>
    <div *ngIf="!editionMode && allowToEdit">
      <a class="btn main-btn" (click)="enableEditionMode()">
        Edit
      </a>
    </div>
    <div *ngIf="!editionMode && enableCopy">
      <a class="btn main-btn ml-1" (click)="copyAction()">
        Make a Copy
      </a>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-between mb-2" *ngIf="editionMode && allowToEdit">
    <div>{{ editionMessage }}</div>

    <div class="text-right">
      <button class="btn btn-sm btn-success" (click)="saveSection()">Save</button>
      <button class="btn btn-sm btn-danger" (click)="cancelAction()">Cancel</button>
      <a class="btn main-btn" (click)="copyAction()">
        Make a Copy
      </a>
    </div>
  </div>

  <ng-content></ng-content>
</div>

<div class="text-right" *ngIf="editionMode && allowToEdit">
  <button class="btn btn-sm btn-success" (click)="saveSection()">Save</button>
  <button class="btn btn-sm btn-danger" (click)="cancelAction()">Cancel</button>
</div>
