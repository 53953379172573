/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOrganizationCollection } from '../models/lasgro-organization-collection';
import { LasgroOrganization } from '../models/lasgro-organization';
import { LasgroOrganization_document } from '../models/lasgro-organization-_document';
@Injectable({
  providedIn: 'root',
})
class SupplierService extends __BaseService {
  static readonly supplierListPath = '/organizations/{organizationID}/suppliers';
  static readonly supplierShowPath = '/organizations/{organizationID}/suppliers/{supplierID}';
  static readonly supplierAddFilePath = '/organizations/{organizationID}/suppliers/{supplierID}/files';
  static readonly supplierShowFilePath = '/organizations/{organizationID}/suppliers/{supplierID}/files/{fileID}';
  static readonly supplierDeleteFilePath = '/organizations/{organizationID}/suppliers/{supplierID}/files/{fileID}';
  static readonly supplierUpdateFileDetailsPath = '/organizations/{organizationID}/suppliers/{supplierID}/files/{fileID}';
  static readonly supplierList1Path = '/suppliers';
  static readonly supplierShow1Path = '/suppliers/{supplierID}';
  static readonly supplierAddFile1Path = '/suppliers/{supplierID}/files';
  static readonly supplierShowFile1Path = '/suppliers/{supplierID}/files/{fileID}';
  static readonly supplierDeleteFile1Path = '/suppliers/{supplierID}/files/{fileID}';
  static readonly supplierUpdateFileDetails1Path = '/suppliers/{supplierID}/files/{fileID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list supplier
   *
   * List all suppliers of a given organization - in other words, all the organizations that have accepted POs from us (the logged-in organization). Only Managers and Supply Chain Leads can see a organization's suppliers.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `brokers`:
   *
   * @return OK
   */
  supplierListResponse(params: SupplierService.SupplierListParams): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.brokers != null) __params = __params.set('brokers', params.brokers.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * list supplier
   *
   * List all suppliers of a given organization - in other words, all the organizations that have accepted POs from us (the logged-in organization). Only Managers and Supply Chain Leads can see a organization's suppliers.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `brokers`:
   *
   * @return OK
   */
  supplierList(params: SupplierService.SupplierListParams): __Observable<LasgroOrganizationCollection> {
    return this.supplierListResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * show supplier
   *
   * Retrieve a supplier with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierShowParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  supplierShowResponse(params: SupplierService.SupplierShowParams): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show supplier
   *
   * Retrieve a supplier with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierShowParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  supplierShow(params: SupplierService.SupplierShowParams): __Observable<LasgroOrganization> {
    return this.supplierShowResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * add_file supplier
   *
   * Retrieve a supplier with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierAddFileParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   */
  supplierAddFileResponse(params: SupplierService.SupplierAddFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.supplierLeadID != null) __params = __params.set('supplierLeadID', params.supplierLeadID.toString());
    if (params.stdLeadTimeMonths != null) __params = __params.set('stdLeadTimeMonths', params.stdLeadTimeMonths.toString());
    if (params.stdLeadTimeDays != null) __params = __params.set('stdLeadTimeDays', params.stdLeadTimeDays.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.expiresAt != null) __params = __params.set('expiresAt', params.expiresAt.toString());
    if (params.customerLeadID != null) __params = __params.set('customerLeadID', params.customerLeadID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_file supplier
   *
   * Retrieve a supplier with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierAddFileParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   */
  supplierAddFile(params: SupplierService.SupplierAddFileParams): __Observable<null> {
    return this.supplierAddFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show_file supplier
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierShowFileParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * @return OK
   */
  supplierShowFileResponse(params: SupplierService.SupplierShowFileParams): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show_file supplier
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierShowFileParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * @return OK
   */
  supplierShowFile(params: SupplierService.SupplierShowFileParams): __Observable<LasgroOrganization> {
    return this.supplierShowFileResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * delete_file supplier
   *
   * Delete a client organization's file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierDeleteFileParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   */
  supplierDeleteFileResponse(params: SupplierService.SupplierDeleteFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_file supplier
   *
   * Delete a client organization's file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierDeleteFileParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   */
  supplierDeleteFile(params: SupplierService.SupplierDeleteFileParams): __Observable<null> {
    return this.supplierDeleteFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update_file_details supplier
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierUpdateFileDetailsParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   *
   * @return OK
   */
  supplierUpdateFileDetailsResponse(params: SupplierService.SupplierUpdateFileDetailsParams): __Observable<__StrictHttpResponse<LasgroOrganization_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.supplierLeadID != null) __params = __params.set('supplierLeadID', params.supplierLeadID.toString());
    if (params.stdLeadTimeMonths != null) __params = __params.set('stdLeadTimeMonths', params.stdLeadTimeMonths.toString());
    if (params.stdLeadTimeDays != null) __params = __params.set('stdLeadTimeDays', params.stdLeadTimeDays.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.expiresAt != null) __params = __params.set('expiresAt', params.expiresAt.toString());
    if (params.customerLeadID != null) __params = __params.set('customerLeadID', params.customerLeadID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/suppliers/${encodeURIComponent(String(params.supplierID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_document>;
      })
    );
  }
  /**
   * update_file_details supplier
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierUpdateFileDetailsParams` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   *
   * @return OK
   */
  supplierUpdateFileDetails(params: SupplierService.SupplierUpdateFileDetailsParams): __Observable<LasgroOrganization_document> {
    return this.supplierUpdateFileDetailsResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_document)
    );
  }

  /**
   * list supplier
   *
   * List all suppliers of a given organization - in other words, all the organizations that have accepted POs from us (the logged-in organization). Only Managers and Supply Chain Leads can see a organization's suppliers.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierList1Params` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `brokers`:
   *
   * @return OK
   */
  supplierList1Response(params: SupplierService.SupplierList1Params): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.brokers != null) __params = __params.set('brokers', params.brokers.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/suppliers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * list supplier
   *
   * List all suppliers of a given organization - in other words, all the organizations that have accepted POs from us (the logged-in organization). Only Managers and Supply Chain Leads can see a organization's suppliers.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierList1Params` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `brokers`:
   *
   * @return OK
   */
  supplierList1(params: SupplierService.SupplierList1Params): __Observable<LasgroOrganizationCollection> {
    return this.supplierList1Response(params).pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * show supplier
   *
   * Retrieve a supplier with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierShow1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  supplierShow1Response(params: SupplierService.SupplierShow1Params): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/suppliers/${encodeURIComponent(String(params.supplierID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show supplier
   *
   * Retrieve a supplier with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierShow1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  supplierShow1(params: SupplierService.SupplierShow1Params): __Observable<LasgroOrganization> {
    return this.supplierShow1Response(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * add_file supplier
   *
   * Retrieve a supplier with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierAddFile1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `file`: uploaded file
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `organizationID`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   */
  supplierAddFile1Response(params: SupplierService.SupplierAddFile1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.supplierLeadID != null) __params = __params.set('supplierLeadID', params.supplierLeadID.toString());
    if (params.stdLeadTimeMonths != null) __params = __params.set('stdLeadTimeMonths', params.stdLeadTimeMonths.toString());
    if (params.stdLeadTimeDays != null) __params = __params.set('stdLeadTimeDays', params.stdLeadTimeDays.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.expiresAt != null) __params = __params.set('expiresAt', params.expiresAt.toString());
    if (params.customerLeadID != null) __params = __params.set('customerLeadID', params.customerLeadID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/suppliers/${encodeURIComponent(String(params.supplierID))}/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_file supplier
   *
   * Retrieve a supplier with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierAddFile1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `file`: uploaded file
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `organizationID`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   */
  supplierAddFile1(params: SupplierService.SupplierAddFile1Params): __Observable<null> {
    return this.supplierAddFile1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show_file supplier
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierShowFile1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `fileID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  supplierShowFile1Response(params: SupplierService.SupplierShowFile1Params): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/suppliers/${encodeURIComponent(String(params.supplierID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show_file supplier
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierShowFile1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `fileID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  supplierShowFile1(params: SupplierService.SupplierShowFile1Params): __Observable<LasgroOrganization> {
    return this.supplierShowFile1Response(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * delete_file supplier
   *
   * Delete a client organization's file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierDeleteFile1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `fileID`:
   *
   * - `organizationID`:
   */
  supplierDeleteFile1Response(params: SupplierService.SupplierDeleteFile1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/suppliers/${encodeURIComponent(String(params.supplierID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_file supplier
   *
   * Delete a client organization's file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierDeleteFile1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `fileID`:
   *
   * - `organizationID`:
   */
  supplierDeleteFile1(params: SupplierService.SupplierDeleteFile1Params): __Observable<null> {
    return this.supplierDeleteFile1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update_file_details supplier
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierUpdateFileDetails1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `fileID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `organizationID`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   *
   * @return OK
   */
  supplierUpdateFileDetails1Response(params: SupplierService.SupplierUpdateFileDetails1Params): __Observable<__StrictHttpResponse<LasgroOrganization_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.supplierLeadID != null) __params = __params.set('supplierLeadID', params.supplierLeadID.toString());
    if (params.stdLeadTimeMonths != null) __params = __params.set('stdLeadTimeMonths', params.stdLeadTimeMonths.toString());
    if (params.stdLeadTimeDays != null) __params = __params.set('stdLeadTimeDays', params.stdLeadTimeDays.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.expiresAt != null) __params = __params.set('expiresAt', params.expiresAt.toString());
    if (params.customerLeadID != null) __params = __params.set('customerLeadID', params.customerLeadID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/suppliers/${encodeURIComponent(String(params.supplierID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_document>;
      })
    );
  }
  /**
   * update_file_details supplier
   *
   * Updates a client organization's file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SupplierService.SupplierUpdateFileDetails1Params` containing the following parameters:
   *
   * - `supplierID`:
   *
   * - `fileID`:
   *
   * - `supplierLeadID`:
   *
   * - `stdLeadTimeMonths`:
   *
   * - `stdLeadTimeDays`:
   *
   * - `organizationID`:
   *
   * - `isMainFile`:
   *
   * - `expiresAt`:
   *
   * - `customerLeadID`:
   *
   * @return OK
   */
  supplierUpdateFileDetails1(params: SupplierService.SupplierUpdateFileDetails1Params): __Observable<LasgroOrganization_document> {
    return this.supplierUpdateFileDetails1Response(params).pipe(
      __map(_r => _r.body as LasgroOrganization_document)
    );
  }
}

module SupplierService {

  /**
   * Parameters for supplierList
   */
  export interface SupplierListParams {
    organizationID: string;
    brokers?: boolean;
  }

  /**
   * Parameters for supplierShow
   */
  export interface SupplierShowParams {
    supplierID: string;
    organizationID: string;
  }

  /**
   * Parameters for supplierAddFile
   */
  export interface SupplierAddFileParams {
    supplierID: string;
    organizationID: string;

    /**
     * uploaded file
     */
    file: Blob;
    supplierLeadID?: string;
    stdLeadTimeMonths?: number;
    stdLeadTimeDays?: number;
    isMainFile?: boolean;
    expiresAt?: string;
    customerLeadID?: string;
  }

  /**
   * Parameters for supplierShowFile
   */
  export interface SupplierShowFileParams {
    supplierID: string;
    organizationID: string;
    fileID: string;
  }

  /**
   * Parameters for supplierDeleteFile
   */
  export interface SupplierDeleteFileParams {
    supplierID: string;
    organizationID: string;
    fileID: string;
  }

  /**
   * Parameters for supplierUpdateFileDetails
   */
  export interface SupplierUpdateFileDetailsParams {
    supplierID: string;
    organizationID: string;
    fileID: string;
    supplierLeadID?: string;
    stdLeadTimeMonths?: number;
    stdLeadTimeDays?: number;
    isMainFile?: boolean;
    expiresAt?: string;
    customerLeadID?: string;
  }

  /**
   * Parameters for supplierList1
   */
  export interface SupplierList1Params {
    organizationID?: string;
    brokers?: boolean;
  }

  /**
   * Parameters for supplierShow1
   */
  export interface SupplierShow1Params {
    supplierID: string;
    organizationID?: string;
  }

  /**
   * Parameters for supplierAddFile1
   */
  export interface SupplierAddFile1Params {
    supplierID: string;

    /**
     * uploaded file
     */
    file: Blob;
    supplierLeadID?: string;
    stdLeadTimeMonths?: number;
    stdLeadTimeDays?: number;
    organizationID?: string;
    isMainFile?: boolean;
    expiresAt?: string;
    customerLeadID?: string;
  }

  /**
   * Parameters for supplierShowFile1
   */
  export interface SupplierShowFile1Params {
    supplierID: string;
    fileID: string;
    organizationID?: string;
  }

  /**
   * Parameters for supplierDeleteFile1
   */
  export interface SupplierDeleteFile1Params {
    supplierID: string;
    fileID: string;
    organizationID?: string;
  }

  /**
   * Parameters for supplierUpdateFileDetails1
   */
  export interface SupplierUpdateFileDetails1Params {
    supplierID: string;
    fileID: string;
    supplierLeadID?: string;
    stdLeadTimeMonths?: number;
    stdLeadTimeDays?: number;
    organizationID?: string;
    isMainFile?: boolean;
    expiresAt?: string;
    customerLeadID?: string;
  }
}

export { SupplierService }
