import { Component, Input, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { Shipment } from '@models/shipment.model';
import { FileItemDto } from '@models/file-item.model';
import { Toaster } from '@services/toaster.service';
import CONFIG from '@app/app.config';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@models/user.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomPopupComponent } from '@theme/components';
import { CompanyService } from '@services/company.service';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'ngx-add-shipment-files-modal',
  templateUrl: './add-shipment-files-modal.component.html',
  styleUrls: ['./add-shipment-files-modal.component.scss'],
})
export class AddShipmentFilesModalComponent implements OnInit {
  @Input() selectedType: string;
  @Input() purchaseOrderID: string;
  @Input() files: FileItemDto[] = [];
  @Input() shipments: Shipment[] = [];
  @Input() fileTypes: string[];

  @LocalStorage() organizationId: string;
  user$: Observable<User>;

  form: UntypedFormGroup;
  filesLoading = false;
  removedFiles = false;

  constructor(public matDialogRef: MatDialogRef<AddShipmentFilesModalComponent>,
              public matDialog: MatDialog,
              public modal: NgbActiveModal,
              public $company: CompanyService,
              private formBuilder: UntypedFormBuilder,
              private titlecase: TitleCasePipe,
              public store: Store<{ user: User }>,
              private $toaster: Toaster) { }

  ngOnInit() {
    this.user$ = this.store.select('user');

    this.form = this.formBuilder.group({
      file_type: [null, [Validators.required]],
      shipment_tracking_id: [],
    });

    this.form.get('file_type').setValue(this.selectedType);
  }

  removeFile(file) {
    const modal = this.matDialog.open(CustomPopupComponent, {
      disableClose: true,
    });

    modal.componentInstance.description = 'Are you sure you want to delete this file?';
    modal.afterClosed().subscribe(
      (result) => {
        if (result !== 'success') {
          return;
        }
        this.$company.removeFileFromPO(this.organizationId, this.purchaseOrderID, file.file_id).subscribe(
          () => {
            this.getFilesList();
            this.$toaster.show('success', 'File', 'File has been removed');
          },
          error => {
            const errMsg =
              error.error && error.error.detail ? error.error.detail : 'An error occurred while deleting the files.';
            this.$toaster.show('error', 'File', errMsg);
          },
        );
      });

    this.removedFiles = true;
  }

  getFilesList() {
    this.$company.getPoFiles(this.purchaseOrderID)
      .subscribe((files) => {
        this.files = files.sort((currentFile: FileItemDto, nextFile: FileItemDto) => {
          return currentFile.created_at < nextFile.created_at ? 1 : -1;
        });
      });
  }

  async addFileSelectedTask($event) {
    const fileList: FileList = $event.srcElement.files;
    const formData: FormData = new FormData();

    if (fileList.length === 0) {
      return;
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file.size > CONFIG.FILE_LIMIT) {
        this.$toaster.show('warning', 'File size', 'Maximum file size for uploads is 200 mb');
        return;
      }
      formData.append('file', file, file.name);
    }

    this.filesLoading = true;

    this.$company.addPoFile(
      this.organizationId,
      this.purchaseOrderID,
      'PURCHASE_ORDER',
      formData,
      false,
      this.form.get('file_type').value,
      this.form.get('shipment_tracking_id').value,
    ).subscribe(res => {
      this.getFilesList();
      this.filesLoading = false;
      this.$toaster.show('success', 'File', 'File has been added');
      this.modal.close({ updated: true });
    }, error => {
      console.error(error);
      const errMsg =
        error.error && error.error.detail ? error.error.detail : 'An error occurred while uploading the files.';
      this.$toaster.show('error', 'File', errMsg);
    });

  }

  getShipmentDetailsText(id: string) {
    if (!id) {
      return 'Shipment: N/A';
    }
    let shipment: Shipment = this.shipments.find(s => s.id === id);
    if (!shipment) {
      return 'Shipment: N/A';
    }
    let text = 'Shipment: ' + shipment.tracking_code;
    if (shipment?.description) {
      text += '(' + shipment.description + ')';
    }
    return text;
  }
}
