<div
  class="task-container"
  [class.disabled]="disabled"
  [ngClass]="{
    'over-due': isOverDue,
    'assigned-to-me': task.responsible_party === currentUser?.id
  }"
>
  <div class="d-flex">
    <div class="draggable" *ngIf="draggable">
      <i class="fa fa-ellipsis-new drag-icon d-flex align-items-center"></i>
    </div>

    <div class="checkbox ml-2" *ngIf="currentUser.role != 'User'">
      <mat-checkbox [disabled]="disabled" color="primary" (change)="selectionChange(task)" [checked]="task.selected">
      </mat-checkbox>
    </div>

    <div
      class="ml-3 user-initials"
      *ngIf="task.responsible_contact"
      [matTooltip]="task.responsible_contact?.first_name + ' ' + task.responsible_contact?.last_name | titlecase"
    >
      <!--          <i class="fa fa-user"></i>-->
      <span
        >{{
          task.responsible_contact?.first_name?.substr(0, 1) + task.responsible_contact?.last_name?.substr(0, 1)
            | uppercase
        }}
      </span>
    </div>

    <div class="details" (click)="openTask(task, $event)">
      <div class="task-name">
        <!--<mat-form-field class="m-0">
          <input matInput type="text" [(ngModel)]="task.name" (change)="taskUpdate()">
        </mat-form-field>-->
        <p class="m-0 text-ellipsis">
          {{ task.name }}
        </p>
      </div>

      <div class="user-assigned" *ngIf="task.responsible_contact">
        <!--          <i class="fa fa-user"></i>-->
        <span>Assigned to: </span>
        <b>
          {{ task.responsible_contact?.first_name | titlecase }} {{ task.responsible_contact?.last_name | titlecase }}
        </b>
      </div>
    </div>

    <div class="d-flex align-items-end flex-column">
      <div class="d-flex justify-content-right mb-2">
        <div
          class="due-date badge mr-2"
          [ngClass]="{
            'badge-danger': isOverDue && task.responsible_party === currentUser?.id,
            'badge-success': !isOverDue && task.responsible_party === currentUser?.id
          }"
          *ngIf="task.due_date"
        >
          <i class="icon fa fa-clock-o"></i>
          <span>Due Date: {{ task.due_date | date: 'MMM dd':'utc' }}</span>
        </div>
        <button *ngIf="currentUser.role != 'User'"
          type="button"
          class="btn btn-default btn-complete"
          [disabled]="isCompleteButtonDisabled || disabled"
          (click)="toggleComplete(task)"
          [ngClass]="{ completed: task.completed_at, disabled: isCompleteButtonDisabled || disabled }"
          [matTooltip]="'Mark as Not Completed'"
          [matTooltipDisabled]="!task.completed_at"
        >
          <i class="fa fa-check mr-1"></i>
          {{ task.completed_at ? 'Task Completed ' : 'Mark as Completed' }}
          <span *ngIf="task.completed_at">{{ task.completed_at | date: 'MMM dd':'utc' }}</span>
        </button>
        <button *ngIf="isCustomer && currentUser.role != 'User'"
          type="button"
          class="ml-2 btn btn-default btn-complete"
          (click)="openNitification(task)"
          [matTooltip]="'Show mail modal'"
        >
          Notification
        </button>
      </div>

      <div class="creation-details">
        <small>
          <span>Created by</span>
          <b>{{ task.created_by_name }}</b>
          <span>from</span>
          <b>{{ task.created_by_organization_name }}</b>
        </small>
        <small>
          <span>At</span>
          <b>{{ task.created_at | date: 'MM/dd/yyyy':'utc' }}</b>
        </small>
      </div>
    </div>
  </div>
</div>
