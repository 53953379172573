/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroTrainingCollection } from '../models/lasgro-training-collection';
import { TrainingAddPayload } from '../models/training-add-payload';
@Injectable({
  providedIn: 'root',
})
class TrainingsService extends __BaseService {
  static readonly trainingsListPath = '/trainings';
  static readonly trainingsAddPath = '/trainings/add';
  static readonly trainingsDeletePath = '/trainings/delete/{trainingID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list trainings
   *
   * Lists trainings
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  trainingsListResponse(): __Observable<__StrictHttpResponse<LasgroTrainingCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trainings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTrainingCollection>;
      })
    );
  }
  /**
   * list trainings
   *
   * Lists trainings
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  trainingsList(): __Observable<LasgroTrainingCollection> {
    return this.trainingsListResponse().pipe(
      __map(_r => _r.body as LasgroTrainingCollection)
    );
  }

  /**
   * add trainings
   *
   * Add a new training
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  trainingsAddResponse(payload: TrainingAddPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trainings/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add trainings
   *
   * Add a new training
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  trainingsAdd(payload: TrainingAddPayload): __Observable<null> {
    return this.trainingsAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete trainings
   *
   * Delete training by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param trainingID training ID
   */
  trainingsDeleteResponse(trainingID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/trainings/delete/${encodeURIComponent(String(trainingID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete trainings
   *
   * Delete training by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param trainingID training ID
   */
  trainingsDelete(trainingID: string): __Observable<null> {
    return this.trainingsDeleteResponse(trainingID).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TrainingsService {
}

export { TrainingsService }
