/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroInvoiceCollection } from '../models/lasgro-invoice-collection';
import { LasgroInvoice } from '../models/lasgro-invoice';
import { InvoiceUpdateInputPayload } from '../models/invoice-update-input-payload';
import { LasgroFile_info } from '../models/lasgro-file-_info';
@Injectable({
  providedIn: 'root',
})
class InvoiceService extends __BaseService {
  static readonly invoiceListPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/invoices';
  static readonly invoiceAddPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/invoices';
  static readonly invoiceShowPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/invoices/{invoiceID}';
  static readonly invoiceDeletePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/invoices/{invoiceID}';
  static readonly invoiceUpdatePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/invoices/{invoiceID}';
  static readonly invoiceUpdateFilePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/invoices/{invoiceID}/files';
  static readonly invoiceShowFilePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/invoices/{invoiceID}/files/{fileID}';
  static readonly invoiceDeleteLineItemPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/invoices/{invoiceID}/line_items/{lineItemID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list invoice
   *
   * List all invoices for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  invoiceListResponse(params: InvoiceService.InvoiceListParams): __Observable<__StrictHttpResponse<LasgroInvoiceCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/invoices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroInvoiceCollection>;
      })
    );
  }
  /**
   * list invoice
   *
   * List all invoices for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  invoiceList(params: InvoiceService.InvoiceListParams): __Observable<LasgroInvoiceCollection> {
    return this.invoiceListResponse(params).pipe(
      __map(_r => _r.body as LasgroInvoiceCollection)
    );
  }

  /**
   * add invoice
   *
   * Create an invoice for this po.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceAddParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `is_proforma`:
   *
   * @return Created
   */
  invoiceAddResponse(params: InvoiceService.InvoiceAddParams): __Observable<__StrictHttpResponse<LasgroInvoice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.isProforma != null) __params = __params.set('is_proforma', params.isProforma.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/invoices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroInvoice>;
      })
    );
  }
  /**
   * add invoice
   *
   * Create an invoice for this po.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceAddParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `is_proforma`:
   *
   * @return Created
   */
  invoiceAdd(params: InvoiceService.InvoiceAddParams): __Observable<LasgroInvoice> {
    return this.invoiceAddResponse(params).pipe(
      __map(_r => _r.body as LasgroInvoice)
    );
  }

  /**
   * show invoice
   *
   * Shows an invoice by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceShowParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   *
   * @return OK
   */
  invoiceShowResponse(params: InvoiceService.InvoiceShowParams): __Observable<__StrictHttpResponse<LasgroInvoice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/invoices/${encodeURIComponent(String(params.invoiceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroInvoice>;
      })
    );
  }
  /**
   * show invoice
   *
   * Shows an invoice by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceShowParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   *
   * @return OK
   */
  invoiceShow(params: InvoiceService.InvoiceShowParams): __Observable<LasgroInvoice> {
    return this.invoiceShowResponse(params).pipe(
      __map(_r => _r.body as LasgroInvoice)
    );
  }

  /**
   * delete invoice
   *
   * Deletes invoice with all line items.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceDeleteParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   */
  invoiceDeleteResponse(params: InvoiceService.InvoiceDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/invoices/${encodeURIComponent(String(params.invoiceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete invoice
   *
   * Deletes invoice with all line items.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceDeleteParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   */
  invoiceDelete(params: InvoiceService.InvoiceDeleteParams): __Observable<null> {
    return this.invoiceDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update invoice
   *
   * Updates invoice.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceUpdateParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload for invoices
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   *
   * @return OK
   */
  invoiceUpdateResponse(params: InvoiceService.InvoiceUpdateParams): __Observable<__StrictHttpResponse<LasgroInvoice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/invoices/${encodeURIComponent(String(params.invoiceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroInvoice>;
      })
    );
  }
  /**
   * update invoice
   *
   * Updates invoice.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceUpdateParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload for invoices
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   *
   * @return OK
   */
  invoiceUpdate(params: InvoiceService.InvoiceUpdateParams): __Observable<LasgroInvoice> {
    return this.invoiceUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroInvoice)
    );
  }

  /**
   * update_file invoice
   *
   * Updates this invoice's file.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceUpdateFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   *
   * - `file`: uploaded file
   *
   * @return OK
   */
  invoiceUpdateFileResponse(params: InvoiceService.InvoiceUpdateFileParams): __Observable<__StrictHttpResponse<LasgroFile_info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;



    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/invoices/${encodeURIComponent(String(params.invoiceID))}/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_info>;
      })
    );
  }
  /**
   * update_file invoice
   *
   * Updates this invoice's file.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceUpdateFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   *
   * - `file`: uploaded file
   *
   * @return OK
   */
  invoiceUpdateFile(params: InvoiceService.InvoiceUpdateFileParams): __Observable<LasgroFile_info> {
    return this.invoiceUpdateFileResponse(params).pipe(
      __map(_r => _r.body as LasgroFile_info)
    );
  }

  /**
   * show_file invoice
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceShowFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   *
   * - `fileID`:
   *
   * @return OK
   */
  invoiceShowFileResponse(params: InvoiceService.InvoiceShowFileParams): __Observable<__StrictHttpResponse<LasgroInvoice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/invoices/${encodeURIComponent(String(params.invoiceID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroInvoice>;
      })
    );
  }
  /**
   * show_file invoice
   *
   * Download invoice file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceShowFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `invoiceID`:
   *
   * - `fileID`:
   *
   * @return OK
   */
  invoiceShowFile(params: InvoiceService.InvoiceShowFileParams): __Observable<LasgroInvoice> {
    return this.invoiceShowFileResponse(params).pipe(
      __map(_r => _r.body as LasgroInvoice)
    );
  }

  /**
   * delete_line_item invoice
   *
   * Deletes a line item from this invoice.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceDeleteLineItemParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`:
   *
   * - `invoiceID`:
   */
  invoiceDeleteLineItemResponse(params: InvoiceService.InvoiceDeleteLineItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/invoices/${encodeURIComponent(String(params.invoiceID))}/line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_line_item invoice
   *
   * Deletes a line item from this invoice.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `InvoiceService.InvoiceDeleteLineItemParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`:
   *
   * - `invoiceID`:
   */
  invoiceDeleteLineItem(params: InvoiceService.InvoiceDeleteLineItemParams): __Observable<null> {
    return this.invoiceDeleteLineItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module InvoiceService {

  /**
   * Parameters for invoiceList
   */
  export interface InvoiceListParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for invoiceAdd
   */
  export interface InvoiceAddParams {
    purchaseOrderID: string;
    organizationID: string;

    /**
     * uploaded file
     */
    file: Blob;
    isProforma?: boolean;
  }

  /**
   * Parameters for invoiceShow
   */
  export interface InvoiceShowParams {
    purchaseOrderID: string;
    organizationID: string;
    invoiceID: string;
  }

  /**
   * Parameters for invoiceDelete
   */
  export interface InvoiceDeleteParams {
    purchaseOrderID: string;
    organizationID: string;
    invoiceID: string;
  }

  /**
   * Parameters for invoiceUpdate
   */
  export interface InvoiceUpdateParams {
    purchaseOrderID: string;

    /**
     * Input payload for invoices
     */
    payload: InvoiceUpdateInputPayload;
    organizationID: string;
    invoiceID: string;
  }

  /**
   * Parameters for invoiceUpdateFile
   */
  export interface InvoiceUpdateFileParams {
    purchaseOrderID: string;
    organizationID: string;
    invoiceID: string;

    /**
     * uploaded file
     */
    file: Blob;
  }

  /**
   * Parameters for invoiceShowFile
   */
  export interface InvoiceShowFileParams {
    purchaseOrderID: string;
    organizationID: string;
    invoiceID: string;
    fileID: string;
  }

  /**
   * Parameters for invoiceDeleteLineItem
   */
  export interface InvoiceDeleteLineItemParams {
    purchaseOrderID: string;
    organizationID: string;
    lineItemID: string;
    invoiceID: string;
  }
}

export { InvoiceService }
