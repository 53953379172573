import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PoFilesComponent } from '@app/modules/po-details/components/po-files/po-files.component';
import { PrintRcdssApplicationFormComponent } from '@app/modules/purchase-order/components/print-rcdss-application-form/print-rcdss-application-form.component';
import { RcdssApplicationFormModalComponent } from '@app/modules/purchase-order/modals/rcdss-application-form-modal/rcdss-application-form-modal.component';
import { RcdssContractModalComponent } from '@app/modules/purchase-order/modals/rcdss-contract-modal/rcdss-contract-modal.component';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompanyService } from '@services/company.service';
import { ShipmentService } from '@services/shipment.service';
import { Toaster } from '@services/toaster.service';
import {
  BroadcastMessageComponent,
  DatePickerFieldComponent,
  DownloadFileComponent,
  FilterMenuBtnComponent,
  FloatingActionButtonComponent,
  PillBtnComponent, PoNotesFormComponent, PoSearchComponent,
  ProductFilterBarComponent,
  SupplierFileItemComponent,
  TitleDividerComponent
} from '@shared/components';
import { FieldsContainerComponent } from '@shared/components/fields-container/fields-container.component';
import { NgEditableSelectDirective, SecureUrlInputDirective } from '@shared/directives';
import {
  AddFilesModalComponent,
  AddLinkFileModalComponent,
  AddShipmentFilesModalComponent,
  CompanyInfoModalComponent,
  CreateCompanyModalComponent,
  CreateProductModalComponent,
  CustomerRequiredFileModalComponent,
  HelpVideosModalComponent,
  ImageViewerComponent,
  ModalPOFilesComponent,
  ModalPONotesComponent,
  PdfVisualizerComponent,
  ProductChangesModalComponent,
  ReIssueReasonModalComponent,
  RejectionReasonModalComponent,
  StepToProduceFormModalComponent,
  SupplierFileViewComponent
} from '@shared/modals';
import { FileSizePipe, PendingStatusToWaitingForApprovalPipe } from '@shared/pipes';
import { SafeUrlPipe } from '@shared/pipes/safe-url.pipe';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { ThemeModule } from '@theme/theme.module';
import { MentionModule } from 'angular-mentions';
import { ToasterService } from 'angular2-toaster';
import { DxDataGridModule, DxSpeedDialActionModule } from 'devextreme-angular';
import { DragulaModule } from 'ng2-dragula';
import { LinkyModule } from 'ngx-linky';
import { NgxLoadingModule } from 'ngx-loading';
import { HelpIconComponent } from './components/help-icon/help-icon.component';
import { PoImportedDetailComponent } from './components/po-imported-detail/po-imported-detail.component';
import { DateSelectorComponent } from './components/po-search/date-selector/date-selector.component';
import { EnteryKeyDirective } from './components/po-search/enterkey.directive';
import { FilterContainerComponent } from './components/po-search/filter-container/filter-container.component';
import { FilterMenuComponent } from './components/po-search/filter-menu/filter-menu.component';
import { FilterPillComponent } from './components/po-search/filter-pill/filter-pill.component';
import { POSearchService } from './components/po-search/po-search.service';
import { PurchaseOrdersImportedComponent } from './components/purchase-orders-imported/purchase-orders-imported.component';
import { ResourceActionsComponent } from './components/resource-actions/resource-actions.component';
import { HelpIconDirective } from './directives/help-icon.directive';
import { ContractHelper } from './helpers/contract.helper';
import { NavigationHelper } from './helpers/navigation.helper';
import { ItHelpModalComponent } from './modals/it-help-modal/it-help-modal.component';
import { TrainingGuidesModalComponent } from './modals/training-guides-modal/training-guides-modal.component';
import { UnicargoQuotesModalComponent } from './modals/unicargo-quotes-modal/unicargo-quotes-modal.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ComponentRegistry } from './registry/component.registry';
import { FieldComponent } from './components/field/field.component';

const COMPONENTS = [
  PrintRcdssApplicationFormComponent,
  PoSearchComponent,
  FilterPillComponent,
  FilterMenuComponent,
  FilterContainerComponent,
  DateSelectorComponent,
  DatePickerFieldComponent,
  PillBtnComponent,
  FilterMenuBtnComponent,
  ProductFilterBarComponent,
  SupplierFileItemComponent,
  PillBtnComponent,
  FilterMenuBtnComponent,
  ProductFilterBarComponent,
  FieldsContainerComponent,
  BroadcastMessageComponent,
  FloatingActionButtonComponent,
  FieldComponent
];

const MODALS = [
  RcdssContractModalComponent,
  RcdssApplicationFormModalComponent,
  CreateProductModalComponent,
  StepToProduceFormModalComponent,
  ProductChangesModalComponent,
  CompanyInfoModalComponent,
  ModalPOFilesComponent,
  ModalPONotesComponent,
  HelpVideosModalComponent,
  CreateCompanyModalComponent,
  AddFilesModalComponent,
  AddShipmentFilesModalComponent,
  PdfVisualizerComponent,
  ImageViewerComponent,
  RejectionReasonModalComponent,
  AddLinkFileModalComponent,
  ReIssueReasonModalComponent,
  SupplierFileViewComponent,
  PoImportedDetailComponent,
  CustomerRequiredFileModalComponent
];

const DIRECTIVES = [
  EnteryKeyDirective,
  NgEditableSelectDirective,
  SecureUrlInputDirective,
];

const PIPES = [PendingStatusToWaitingForApprovalPipe, SafeHtmlPipe, SafeUrlPipe];

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    ThemeModule,
    MatDialogModule,
    MatDividerModule,
    MatSlideToggleModule,
    NgxLoadingModule,
    MatTooltipModule,
    MatMenuModule,
    DxDataGridModule,
    DxSpeedDialActionModule,
    MentionModule,
    NgOptionHighlightModule,
    LinkyModule,
    DragulaModule,
    TitleDividerComponent,
    TimeAgoPipe,
    ResourceActionsComponent,
    PoFilesComponent,
    PoNotesFormComponent,
    FileSizePipe
  ],
  declarations: [
    ...COMPONENTS,
    ...MODALS,
    ...PIPES,
    AddFilesModalComponent,
    AddShipmentFilesModalComponent,
    BroadcastMessageComponent,
    DownloadFileComponent,
    PillBtnComponent,
    FilterContainerComponent,
    FilterMenuBtnComponent,
    SupplierFileViewComponent,
    SupplierFileItemComponent,
    PillBtnComponent,
    FilterContainerComponent,
    FilterMenuBtnComponent,
    ProductFilterBarComponent,
    PurchaseOrdersImportedComponent,
    HelpIconComponent,
    HelpIconDirective,
    ItHelpModalComponent,
    TrainingGuidesModalComponent,
    UnicargoQuotesModalComponent,
    ...DIRECTIVES,
    FieldComponent,
  ],
  exports: [
    ...COMPONENTS,
    ...MODALS,
    ...PIPES,
    NgSelectModule,
    NgOptionHighlightModule,
    ThemeModule,
    PurchaseOrdersImportedComponent,
    HelpIconDirective,
    HelpIconComponent,
    ...DIRECTIVES,
  ],
  providers: [
    CompanyService,
    ShipmentService,
    Toaster,
    POSearchService,
    ToasterService,
    TitleCasePipe,
    ContractHelper,
    ComponentRegistry,
    NavigationHelper,
  ],
})
export class SharedModule {}
