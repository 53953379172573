<ng-container *ngIf="company">
  <div class="modal-header">
    <span>In order to start getting {{ isBroker ? 'Projects' : 'Purchase Orders' }} you must fill these:</span>
    <button title="Closing this modal will log you out" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input type="text" matInput placeholder="Country" [(ngModel)]="company.country" required/>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field>
          <input type="text" matInput placeholder="State" [(ngModel)]="company.region" required/>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input type="text" matInput placeholder="Postal Code" [(ngModel)]="company.postal_code" required/>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <input type="text" matInput placeholder="City" [(ngModel)]="company.city" required/>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input type="text" matInput placeholder="Address 1" [(ngModel)]="company.address1" required/>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <input type="text" matInput placeholder="Address 2" [(ngModel)]="company.address2"/>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="modal-footer po-modal d-flex justify-content-center">
    <button
      class="float-right m-3"
      mat-raised-button
      color="primary"
      [disabled]="
        !company.country ||
        !company.region ||
        !company.city ||
        !company.address1 ||
        !company.postal_code ||
        saveInProgress
      "
      (click)="updateCompanyInfo()"
    >
      Save and logout
    </button>
  </div>
</ng-container>
