/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFull_contactCollection } from '../models/lasgro-full-_contact-collection';
import { SubscribersIDsInputPayload } from '../models/subscribers-ids-input-payload';
@Injectable({
  providedIn: 'root',
})
class UserSubscriberService extends __BaseService {
  static readonly userSubscriberListPoSubscribersPath = '/purchase_orders/{purchaseOrderID}/subscribers';
  static readonly userSubscriberDeletePoSubsPath = '/purchase_orders/{purchaseOrderID}/subscribers/delete';
  static readonly userSubscriberAddPoSubscriberPath = '/purchase_orders/{purchaseOrderID}/subscribers/{userID}';
  static readonly userSubscriberDeletePoPath = '/purchase_orders/{purchaseOrderID}/subscribers/{userID}';
  static readonly userSubscriberListPath = '/resources/{resourceID}/subscribers';
  static readonly userSubscriberDeleteSubsPath = '/resources/{resourceID}/subscribers/delete';
  static readonly userSubscriberAddPath = '/resources/{resourceID}/subscribers/{userID}';
  static readonly userSubscriberDeletePath = '/resources/{resourceID}/subscribers/{userID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list_po_subscribers user_subscriber
   *
   * List all subscribers for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID undefined
   * @return OK
   */
  userSubscriberListPoSubscribersResponse(purchaseOrderID: string): __Observable<__StrictHttpResponse<LasgroFull_contactCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(purchaseOrderID))}/subscribers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFull_contactCollection>;
      })
    );
  }
  /**
   * list_po_subscribers user_subscriber
   *
   * List all subscribers for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID undefined
   * @return OK
   */
  userSubscriberListPoSubscribers(purchaseOrderID: string): __Observable<LasgroFull_contactCollection> {
    return this.userSubscriberListPoSubscribersResponse(purchaseOrderID).pipe(
      __map(_r => _r.body as LasgroFull_contactCollection)
    );
  }

  /**
   * delete_po_subs user_subscriber
   *
   * Deletes multiple subscribers from this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberDeletePoSubsParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload to delete multiple subscribers from aPO
   */
  userSubscriberDeletePoSubsResponse(params: UserSubscriberService.UserSubscriberDeletePoSubsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/subscribers/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_po_subs user_subscriber
   *
   * Deletes multiple subscribers from this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberDeletePoSubsParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload to delete multiple subscribers from aPO
   */
  userSubscriberDeletePoSubs(params: UserSubscriberService.UserSubscriberDeletePoSubsParams): __Observable<null> {
    return this.userSubscriberDeletePoSubsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add_po_subscriber user_subscriber
   *
   * Add a subscriber for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberAddPoSubscriberParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `purchaseOrderID`:
   */
  userSubscriberAddPoSubscriberResponse(params: UserSubscriberService.UserSubscriberAddPoSubscriberParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/subscribers/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_po_subscriber user_subscriber
   *
   * Add a subscriber for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberAddPoSubscriberParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `purchaseOrderID`:
   */
  userSubscriberAddPoSubscriber(params: UserSubscriberService.UserSubscriberAddPoSubscriberParams): __Observable<null> {
    return this.userSubscriberAddPoSubscriberResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete_po user_subscriber
   *
   * Deletes subscriber from this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberDeletePoParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `purchaseOrderID`:
   */
  userSubscriberDeletePoResponse(params: UserSubscriberService.UserSubscriberDeletePoParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/subscribers/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_po user_subscriber
   *
   * Deletes subscriber from this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberDeletePoParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `purchaseOrderID`:
   */
  userSubscriberDeletePo(params: UserSubscriberService.UserSubscriberDeletePoParams): __Observable<null> {
    return this.userSubscriberDeletePoResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list user_subscriber
   *
   * List all subscribers for this parent resource.
   *
   * Required security scopes:
   *   * `api:access`
   * @param resourceID undefined
   * @return OK
   */
  userSubscriberListResponse(resourceID: string): __Observable<__StrictHttpResponse<LasgroFull_contactCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/resources/${encodeURIComponent(String(resourceID))}/subscribers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFull_contactCollection>;
      })
    );
  }
  /**
   * list user_subscriber
   *
   * List all subscribers for this parent resource.
   *
   * Required security scopes:
   *   * `api:access`
   * @param resourceID undefined
   * @return OK
   */
  userSubscriberList(resourceID: string): __Observable<LasgroFull_contactCollection> {
    return this.userSubscriberListResponse(resourceID).pipe(
      __map(_r => _r.body as LasgroFull_contactCollection)
    );
  }

  /**
   * delete_subs user_subscriber
   *
   * Deletes multiple subscribers from this parent resource.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberDeleteSubsParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`: Input payload to delete multiple subscribers from aPO
   */
  userSubscriberDeleteSubsResponse(params: UserSubscriberService.UserSubscriberDeleteSubsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/subscribers/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_subs user_subscriber
   *
   * Deletes multiple subscribers from this parent resource.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberDeleteSubsParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`: Input payload to delete multiple subscribers from aPO
   */
  userSubscriberDeleteSubs(params: UserSubscriberService.UserSubscriberDeleteSubsParams): __Observable<null> {
    return this.userSubscriberDeleteSubsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add user_subscriber
   *
   * Add a subscriber for this parent resource.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberAddParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `resourceID`:
   */
  userSubscriberAddResponse(params: UserSubscriberService.UserSubscriberAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/subscribers/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add user_subscriber
   *
   * Add a subscriber for this parent resource.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberAddParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `resourceID`:
   */
  userSubscriberAdd(params: UserSubscriberService.UserSubscriberAddParams): __Observable<null> {
    return this.userSubscriberAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete user_subscriber
   *
   * Deletes subscriber from this parent resource.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberDeleteParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `resourceID`:
   */
  userSubscriberDeleteResponse(params: UserSubscriberService.UserSubscriberDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/resources/${encodeURIComponent(String(params.resourceID))}/subscribers/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete user_subscriber
   *
   * Deletes subscriber from this parent resource.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserSubscriberService.UserSubscriberDeleteParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `resourceID`:
   */
  userSubscriberDelete(params: UserSubscriberService.UserSubscriberDeleteParams): __Observable<null> {
    return this.userSubscriberDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserSubscriberService {

  /**
   * Parameters for userSubscriberDeletePoSubs
   */
  export interface UserSubscriberDeletePoSubsParams {
    purchaseOrderID: string;

    /**
     * Input payload to delete multiple subscribers from aPO
     */
    payload: SubscribersIDsInputPayload;
  }

  /**
   * Parameters for userSubscriberAddPoSubscriber
   */
  export interface UserSubscriberAddPoSubscriberParams {
    userID: string;
    purchaseOrderID: string;
  }

  /**
   * Parameters for userSubscriberDeletePo
   */
  export interface UserSubscriberDeletePoParams {
    userID: string;
    purchaseOrderID: string;
  }

  /**
   * Parameters for userSubscriberDeleteSubs
   */
  export interface UserSubscriberDeleteSubsParams {
    resourceID: string;

    /**
     * Input payload to delete multiple subscribers from aPO
     */
    payload: SubscribersIDsInputPayload;
  }

  /**
   * Parameters for userSubscriberAdd
   */
  export interface UserSubscriberAddParams {
    userID: string;
    resourceID: string;
  }

  /**
   * Parameters for userSubscriberDelete
   */
  export interface UserSubscriberDeleteParams {
    userID: string;
    resourceID: string;
  }
}

export { UserSubscriberService }
