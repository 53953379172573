<toaster-container></toaster-container>
<div class="modal-header font-weight-bold">
  TECHNICAL ASSISTANCE

  <div class="subtitle">Please feel free to send us your questions, comments or concerns using the email below</div>

  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body text-center">

  <p class="email-support pt-4">help@ioncontracts.com</p>
  <div class="">
    <button (click)="copyToClipboard('help@ioncontracts.com')" class="btn btn-dark">Copy Address</button>
    <a href="mailto:help@ioncontracts.com" class="btn btn-primary text-white">Send Email</a>
  </div>
  <p class="font-weight-bold mt-4">
    NOTE: SUBSTANTIVE QUESTIONS RELATING TO YOUR APPLICATION/CONTRACT, SHOULD BE SUBMITTED THROUGH THE "MESSAGES"
    SECTION LOCATED ON THE CONTRACT PAGE
  </p>

</div>
