import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SavedChecklist, SavedTask } from '@models/index';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChecklistService } from '@services/checklist.service';

import * as moment from 'moment';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';


@Component({
  selector: 'ngx-saved-checklists-modal',
  styleUrls: ['./saved-checklists-modal.style.scss'],
  templateUrl: './saved-checklists-modal.component.html',
})
export class SavedChecklistsModalComponent implements OnInit {
  
  @LocalStorage() organizationId: string;
  @LocalStorage() companyType: string;
  @Input() supplierId: string;
  checklists$: Observable<SavedChecklist[]>;
  selectedChecklist: SavedChecklist;
  tasks: SavedTask[];

  constructor(private activeModal: NgbActiveModal, public router: Router, private checklistService: ChecklistService) {}

  ngOnInit() {
    this.checklists$ = this.checklistService.fetchSavedChecklist(this.organizationId, this.supplierId);
  }

  selectChecklist(selectedChecklist: SavedChecklist) {
    this.selectedChecklist = selectedChecklist;
    this.tasks = this.selectedChecklist.saved_checklist_tasks.map(t => {
      t['formatted_created_at'] = moment.utc(t.created_at).format('MM/DD/YYYY');
      t['formatted_due_date'] = t.due_date_unparsed ? t.due_date_unparsed.split(' ')[0] + ' days after' : 'None';
      return t;
    });
  }

  useSavedChecklist() {
    this.activeModal.close({ ...this.selectedChecklist });
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
