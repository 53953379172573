/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroShipment_trackingCollection } from '../models/lasgro-shipment-_tracking-collection';
import { LasgroShipment_tracking } from '../models/lasgro-shipment-_tracking';
import { ShipmentTrackingInputPayload } from '../models/shipment-tracking-input-payload';
import { LasgroCarrierCollection } from '../models/lasgro-carrier-collection';
import { ShipmentTrackingUpdateInputPayload } from '../models/shipment-tracking-update-input-payload';
import { ShipmentTrackingAddLineItemsInputPayload } from '../models/shipment-tracking-add-line-items-input-payload';
import { LasgroOcean_tracking_data } from '../models/lasgro-ocean-_tracking-_data';
@Injectable({
  providedIn: 'root',
})
class ShipmentTrackingService extends __BaseService {
  static readonly shipmentTrackingListPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking';
  static readonly shipmentTrackingAddPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking';
  static readonly shipmentTrackingListCarriersPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/carriers';
  static readonly shipmentTrackingShow1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}';
  static readonly shipmentTrackingUpdatePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}';
  static readonly shipmentTrackingDelete1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}';
  static readonly shipmentTrackingAddFilePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}/add_file';
  static readonly shipmentTrackingAddItemsPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}/add_items';
  static readonly shipmentTrackingShowFilePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}/files/{fileID}';
  static readonly shipmentTrackingDeleteFilePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}/files/{fileID}';
  static readonly shipmentTrackingDeleteItemsPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}/tracking_line/{trackingLineID}';
  static readonly shipmentTrackingListAllPath = '/organizations/{organizationID}/shipment_tracking';
  static readonly shipmentTrackingGetByCodePath = '/organizations/{organizationID}/shipment_tracking/code/{trackingCode}';
  static readonly shipmentTrackingShowAllPath = '/organizations/{organizationID}/shipment_tracking/{trackingID}';
  static readonly shipmentTrackingShowPath = '/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}';
  static readonly shipmentTrackingDeletePath = '/purchase_orders/{purchaseOrderID}/shipment_tracking/{trackingID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list shipment_tracking
   *
   * List all shipment tracking for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingListResponse(params: ShipmentTrackingService.ShipmentTrackingListParams): __Observable<__StrictHttpResponse<LasgroShipment_trackingCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_trackingCollection>;
      })
    );
  }
  /**
   * list shipment_tracking
   *
   * List all shipment tracking for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingList(params: ShipmentTrackingService.ShipmentTrackingListParams): __Observable<LasgroShipment_trackingCollection> {
    return this.shipmentTrackingListResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_trackingCollection)
    );
  }

  /**
   * add shipment_tracking
   *
   * Create a shipment tracking for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingAddParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload for po shipment tracking
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingAddResponse(params: ShipmentTrackingService.ShipmentTrackingAddParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * add shipment_tracking
   *
   * Create a shipment tracking for this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingAddParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload for po shipment tracking
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingAdd(params: ShipmentTrackingService.ShipmentTrackingAddParams): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingAddResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * list_carriers shipment_tracking
   *
   * Get the list of carriers from the Ocean Tracking API
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingListCarriersParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingListCarriersResponse(params: ShipmentTrackingService.ShipmentTrackingListCarriersParams): __Observable<__StrictHttpResponse<LasgroCarrierCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/carriers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCarrierCollection>;
      })
    );
  }
  /**
   * list_carriers shipment_tracking
   *
   * Get the list of carriers from the Ocean Tracking API
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingListCarriersParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingListCarriers(params: ShipmentTrackingService.ShipmentTrackingListCarriersParams): __Observable<LasgroCarrierCollection> {
    return this.shipmentTrackingListCarriersResponse(params).pipe(
      __map(_r => _r.body as LasgroCarrierCollection)
    );
  }

  /**
   * show shipment_tracking
   *
   * Shows a shipment tracking by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingShow1Params` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingShow1Response(params: ShipmentTrackingService.ShipmentTrackingShow1Params): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * show shipment_tracking
   *
   * Shows a shipment tracking by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingShow1Params` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingShow1(params: ShipmentTrackingService.ShipmentTrackingShow1Params): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingShow1Response(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * update shipment_tracking
   *
   * Updates this shipment tracking and it's line items.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingUpdateParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload to update a po shipment tracking
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingUpdateResponse(params: ShipmentTrackingService.ShipmentTrackingUpdateParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * update shipment_tracking
   *
   * Updates this shipment tracking and it's line items.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingUpdateParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input payload to update a po shipment tracking
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingUpdate(params: ShipmentTrackingService.ShipmentTrackingUpdateParams): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * delete shipment_tracking
   *
   * Deletes this shipment tracking with it's line items.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingDelete1Params` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  shipmentTrackingDelete1Response(params: ShipmentTrackingService.ShipmentTrackingDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete shipment_tracking
   *
   * Deletes this shipment tracking with it's line items.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingDelete1Params` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  shipmentTrackingDelete1(params: ShipmentTrackingService.ShipmentTrackingDelete1Params): __Observable<null> {
    return this.shipmentTrackingDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add_file shipment_tracking
   *
   * Adds a file for an existing shipment tracking on this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingAddFileParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   */
  shipmentTrackingAddFileResponse(params: ShipmentTrackingService.ShipmentTrackingAddFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;



    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/add_file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_file shipment_tracking
   *
   * Adds a file for an existing shipment tracking on this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingAddFileParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   */
  shipmentTrackingAddFile(params: ShipmentTrackingService.ShipmentTrackingAddFileParams): __Observable<null> {
    return this.shipmentTrackingAddFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add_items shipment_tracking
   *
   * Adds line items for an existing shipment tracking on this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingAddItemsParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input to add line items to a shipment tracking for a PO.
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingAddItemsResponse(params: ShipmentTrackingService.ShipmentTrackingAddItemsParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/add_items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * add_items shipment_tracking
   *
   * Adds line items for an existing shipment tracking on this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingAddItemsParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: Input to add line items to a shipment tracking for a PO.
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingAddItems(params: ShipmentTrackingService.ShipmentTrackingAddItemsParams): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingAddItemsResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * show_file shipment_tracking
   *
   * Download shipment tracking file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingShowFileParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * @return OK
   */
  shipmentTrackingShowFileResponse(params: ShipmentTrackingService.ShipmentTrackingShowFileParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * show_file shipment_tracking
   *
   * Download shipment tracking file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingShowFileParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * @return OK
   */
  shipmentTrackingShowFile(params: ShipmentTrackingService.ShipmentTrackingShowFileParams): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingShowFileResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * delete_file shipment_tracking
   *
   * Deletes a shipment tracking file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingDeleteFileParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   */
  shipmentTrackingDeleteFileResponse(params: ShipmentTrackingService.ShipmentTrackingDeleteFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_file shipment_tracking
   *
   * Deletes a shipment tracking file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingDeleteFileParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   */
  shipmentTrackingDeleteFile(params: ShipmentTrackingService.ShipmentTrackingDeleteFileParams): __Observable<null> {
    return this.shipmentTrackingDeleteFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete_items shipment_tracking
   *
   * Remove line items for an existing shipment tracking on this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingDeleteItemsParams` containing the following parameters:
   *
   * - `trackingLineID`:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingDeleteItemsResponse(params: ShipmentTrackingService.ShipmentTrackingDeleteItemsParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}/tracking_line/${encodeURIComponent(String(params.trackingLineID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * delete_items shipment_tracking
   *
   * Remove line items for an existing shipment tracking on this PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingDeleteItemsParams` containing the following parameters:
   *
   * - `trackingLineID`:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingDeleteItems(params: ShipmentTrackingService.ShipmentTrackingDeleteItemsParams): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingDeleteItemsResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * list_all shipment_tracking
   *
   * Returns all shipment trackings for the logged in organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  shipmentTrackingListAllResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroOcean_tracking_data>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/shipment_tracking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOcean_tracking_data>;
      })
    );
  }
  /**
   * list_all shipment_tracking
   *
   * Returns all shipment trackings for the logged in organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  shipmentTrackingListAll(organizationID: string): __Observable<LasgroOcean_tracking_data> {
    return this.shipmentTrackingListAllResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroOcean_tracking_data)
    );
  }

  /**
   * get_by_code shipment_tracking
   *
   * Returns the shipment tracking with the provided code, if found.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingGetByCodeParams` containing the following parameters:
   *
   * - `trackingCode`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingGetByCodeResponse(params: ShipmentTrackingService.ShipmentTrackingGetByCodeParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/shipment_tracking/code/${encodeURIComponent(String(params.trackingCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * get_by_code shipment_tracking
   *
   * Returns the shipment tracking with the provided code, if found.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingGetByCodeParams` containing the following parameters:
   *
   * - `trackingCode`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingGetByCode(params: ShipmentTrackingService.ShipmentTrackingGetByCodeParams): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingGetByCodeResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * show_all shipment_tracking
   *
   * Returns the specified shipment tracking with all its line items from all POs.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingShowAllParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingShowAllResponse(params: ShipmentTrackingService.ShipmentTrackingShowAllParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * show_all shipment_tracking
   *
   * Returns the specified shipment tracking with all its line items from all POs.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingShowAllParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingShowAll(params: ShipmentTrackingService.ShipmentTrackingShowAllParams): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingShowAllResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * show shipment_tracking
   *
   * Shows a shipment tracking by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingShowParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingShowResponse(params: ShipmentTrackingService.ShipmentTrackingShowParams): __Observable<__StrictHttpResponse<LasgroShipment_tracking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShipment_tracking>;
      })
    );
  }
  /**
   * show shipment_tracking
   *
   * Shows a shipment tracking by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingShowParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  shipmentTrackingShow(params: ShipmentTrackingService.ShipmentTrackingShowParams): __Observable<LasgroShipment_tracking> {
    return this.shipmentTrackingShowResponse(params).pipe(
      __map(_r => _r.body as LasgroShipment_tracking)
    );
  }

  /**
   * delete shipment_tracking
   *
   * Deletes this shipment tracking with it's line items.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingDeleteParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  shipmentTrackingDeleteResponse(params: ShipmentTrackingService.ShipmentTrackingDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/shipment_tracking/${encodeURIComponent(String(params.trackingID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete shipment_tracking
   *
   * Deletes this shipment tracking with it's line items.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ShipmentTrackingService.ShipmentTrackingDeleteParams` containing the following parameters:
   *
   * - `trackingID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  shipmentTrackingDelete(params: ShipmentTrackingService.ShipmentTrackingDeleteParams): __Observable<null> {
    return this.shipmentTrackingDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShipmentTrackingService {

  /**
   * Parameters for shipmentTrackingList
   */
  export interface ShipmentTrackingListParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingAdd
   */
  export interface ShipmentTrackingAddParams {
    purchaseOrderID: string;

    /**
     * Input payload for po shipment tracking
     */
    payload: ShipmentTrackingInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingListCarriers
   */
  export interface ShipmentTrackingListCarriersParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingShow1
   */
  export interface ShipmentTrackingShow1Params {
    trackingID: string;
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingUpdate
   */
  export interface ShipmentTrackingUpdateParams {
    trackingID: string;
    purchaseOrderID: string;

    /**
     * Input payload to update a po shipment tracking
     */
    payload: ShipmentTrackingUpdateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingDelete1
   */
  export interface ShipmentTrackingDelete1Params {
    trackingID: string;
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingAddFile
   */
  export interface ShipmentTrackingAddFileParams {
    trackingID: string;
    purchaseOrderID: string;
    organizationID: string;

    /**
     * uploaded file
     */
    file: Blob;
  }

  /**
   * Parameters for shipmentTrackingAddItems
   */
  export interface ShipmentTrackingAddItemsParams {
    trackingID: string;
    purchaseOrderID: string;

    /**
     * Input to add line items to a shipment tracking for a PO.
     */
    payload: ShipmentTrackingAddLineItemsInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingShowFile
   */
  export interface ShipmentTrackingShowFileParams {
    trackingID: string;
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;
  }

  /**
   * Parameters for shipmentTrackingDeleteFile
   */
  export interface ShipmentTrackingDeleteFileParams {
    trackingID: string;
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;
  }

  /**
   * Parameters for shipmentTrackingDeleteItems
   */
  export interface ShipmentTrackingDeleteItemsParams {
    trackingLineID: string;
    trackingID: string;
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingGetByCode
   */
  export interface ShipmentTrackingGetByCodeParams {
    trackingCode: string;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingShowAll
   */
  export interface ShipmentTrackingShowAllParams {
    trackingID: string;
    organizationID: string;
  }

  /**
   * Parameters for shipmentTrackingShow
   */
  export interface ShipmentTrackingShowParams {
    trackingID: string;
    purchaseOrderID: string;
    organizationID?: string;
  }

  /**
   * Parameters for shipmentTrackingDelete
   */
  export interface ShipmentTrackingDeleteParams {
    trackingID: string;
    purchaseOrderID: string;
    organizationID?: string;
  }
}

export { ShipmentTrackingService }
