import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  standalone: true,
  selector: 'ngx-title-divider',
  templateUrl: './title-divider.component.html',
  styleUrls: ['./title-divider.component.scss'],
  imports: [
    MatDividerModule,
    CommonModule
  ]
})
export class TitleDividerComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() bold: boolean;
  @Input() small: boolean;

  constructor() { }

  ngOnInit() {
  }

}
