<div class="modal-header">
  {{ document?.file ? 'Update' : 'Add' }} Supplier File
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="supplierLeadForm" (submit)="save()">
  <div class="modal-body">

    <div class="text-center my-2">
      <label class="control-label ev-clickable" *ngIf="!document?.file">
        {{ fileInput ? 'Change' : 'Add' }} File *
        <input class="form-control" type="file" placeholder="Supplier File" hidden
               (change)="fileInput = $event.target.files[0]">
      </label>

      <div *ngIf="fileInput" class="my-3">
        {{ fileInput?.name }}
        <br>
        {{ fileInput?.size |fileSize }}
      </div>
      <div *ngIf="document?.file as fileItem" class="my-3">
        {{ fileItem?.file_name }}
        <br>
        {{ fileItem?.file_size |fileSize }}
      </div>

    </div>
    <div>

      <ng-select placeholder="Customer Lead"
                 [hideSelected]="true"
                 formControlName="customerLeadId"
                 [labelForId]="'customerLeadIdSelect'"
                 bindLabel="fullName"
                 bindValue="id"
                 [clearable]="false"
                 [virtualScroll]="true"
                 [items]="customerUsers$ | async">
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">
            {{ item.fullName }}
          </div>
        </ng-template>
      </ng-select>

      <ng-select placeholder="Supplier Lead"
                 [hideSelected]="true"
                 formControlName="supplierLeadId"
                 [labelForId]="'supplierLeadIdSelect'"
                 bindLabel="fullName"
                 bindValue="id"
                 [clearable]="false"
                 [virtualScroll]="true"
                 [items]="supplierUsers$ | async">
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">
            {{ item.fullName }}
          </div>
        </ng-template>
      </ng-select>

      <mat-form-field>
        <input
          formControlName="expiresAt"
          (focus)="picker.open()"
          [matDatepicker]="picker" matInput placeholder="Date of expiry">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div class="d-flex flex-row">
        <div>
          <mat-form-field class="flex-grow-1">
            <input type="text" matInput placeholder="Standard Lead Time" formControlName="stdLeadTimeValue">
          </mat-form-field>
        </div>
        <div class="d-block">
          <mat-form-field>
            <mat-select formControlName="stdLeadTimeLength">
              <mat-option value="days">Days</mat-option>
              <mat-option value="months">Months</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            [disabled]="this.supplierLeadForm.invalid || (!this.fileInput && !this.document?.file.file_id)">Save
    </button>
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
  </div>
</form>
