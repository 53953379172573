<div class="modal-header">
  <span>{{ associateExistentCompany ? 'Associate' : 'Create' }} a new Company</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <mat-form-field>
      <input
        matInput
        (focusout)="searchClient($event)"
        type="email"
        placeholder="Contact Email"
        [formControl]="email"
        [(ngModel)]="user.email"
        [disabled]="associateExistentCompany"
        required
      />
    </mat-form-field>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput type="text" placeholder="Contact First Name" required [(ngModel)]="user.first_name"/>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <input matInput type="text" placeholder="Contact Last Name" required [(ngModel)]="user.last_name"/>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <input matInput type="text" placeholder="Company Name" required
                 [disabled]="associateExistentCompany"
                 [(ngModel)]="company.name"/>
        </mat-form-field>
      </div>
      <!-- <div class="col-6">
        <mat-form-field>
          <mat-select placeholder="Priority" [(ngModel)]="company.priority_id">
            <mat-option *ngFor="let p of priorities" [value]="p.id">
              {{ p.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>
  </div>
</div>
<div class="modal-footer po-modal">
  <button
    class="pull-right margin10"
    mat-raised-button
    color="primary"
    *ngIf="!disableSubmit"
    [disabled]="(!user.first_name || !user.last_name) || !company.name || email.invalid"
    (click)="saveCompany()"
  >
    {{ associateExistentCompany ? 'Save' : 'Create' }}
  </button>
</div>
