import { Component, Input, OnInit } from '@angular/core';
import { ContractDto } from '@app/models/contract.dto';
import { LocalStorage } from 'ngx-webstorage';

// import printJS = require("print-js");
import 'svg2pdf.js';
import { RcdssApplicationFormModel } from '@models/rcdss-application-form.model';

@Component({
  selector: 'ngx-print-rcdss-application-form',
  templateUrl: './print-rcdss-application-form.component.html',
  styleUrls: ['./print-rcdss-application-form.component.scss'],
})
export class PrintRcdssApplicationFormComponent implements OnInit {
  isDraft = true;
  selectedForm = 'long';

  @Input() purchaseOrder: ContractDto;
  @Input() companyType: string;
  @LocalStorage() organizationId;

  printing = false;
  formData: any;

  constructor() {
  }

  ngOnInit() {
    if (this.purchaseOrder && this.purchaseOrder.rcdss_application_form) {
      this.selectedForm = this.purchaseOrder.rcdss_application_form.type;
      this.isDraft = this.purchaseOrder.rcdss_application_form.is_draft;
      this.formData = JSON.parse(this.purchaseOrder.rcdss_application_form.json_data);
    }
  }

}
