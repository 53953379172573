import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AccountService } from '@app/services';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as UserActions from '../actions/user.actions';

@Injectable()
export class UserEffects {
  @Effect() fetchUserProfile$: Observable<Action> = this.actions$
    .pipe(
      ofType(UserActions.FETCH),
      mergeMap(action =>
        this.accountService.getProfile().pipe(
          map(data => ({ type: UserActions.FETCH_SUCCESS, payload: data })),
          catchError((error) => of({ type: UserActions.FETCH_FAILED, payload: error }))),
      ));

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
  ) { }
}
