/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroQb_auth_url } from '../models/lasgro-qb-_auth-_url';
import { QbAuthTokenInputPayload } from '../models/qb-auth-token-input-payload';
import { LasgroPos_import } from '../models/lasgro-pos-_import';
import { LasgroPos_import_progress } from '../models/lasgro-pos-_import-_progress';
import { LasgroQb_sync_info } from '../models/lasgro-qb-_sync-_info';
@Injectable({
  providedIn: 'root',
})
class QuickbooksOnlineService extends __BaseService {
  static readonly quickbooksOnlineAuthLinkPath = '/organizations/{organizationID}/qb_auth_link';
  static readonly quickbooksOnlineAuthTokenPath = '/organizations/{organizationID}/qb_auth_token';
  static readonly quickbooksOnlineDisconnectPath = '/organizations/{organizationID}/qb_disconnect';
  static readonly quickbooksOnlineImportPosPath = '/organizations/{organizationID}/qb_import_pos';
  static readonly quickbooksOnlineImportPosProgressPath = '/organizations/{organizationID}/qb_import_pos_progress';
  static readonly quickbooksOnlineSyncInfoPath = '/organizations/{organizationID}/qb_sync_info';
  static readonly quickbooksOnlineSyncListPath = '/organizations/{organizationID}/qb_syncs';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * auth_link quickbooks_online
   *
   * Get the link to quickbooks' authentication page
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  quickbooksOnlineAuthLinkResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroQb_auth_url>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/qb_auth_link`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroQb_auth_url>;
      })
    );
  }
  /**
   * auth_link quickbooks_online
   *
   * Get the link to quickbooks' authentication page
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  quickbooksOnlineAuthLink(organizationID: string): __Observable<LasgroQb_auth_url> {
    return this.quickbooksOnlineAuthLinkResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroQb_auth_url)
    );
  }

  /**
   * auth_token quickbooks_online
   *
   * Set the token received from the quickbooks authentication page
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `QuickbooksOnlineService.QuickbooksOnlineAuthTokenParams` containing the following parameters:
   *
   * - `payload`: Input payload to receive the quickbooks authentication token
   *
   * - `organizationID`:
   */
  quickbooksOnlineAuthTokenResponse(params: QuickbooksOnlineService.QuickbooksOnlineAuthTokenParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/qb_auth_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * auth_token quickbooks_online
   *
   * Set the token received from the quickbooks authentication page
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `QuickbooksOnlineService.QuickbooksOnlineAuthTokenParams` containing the following parameters:
   *
   * - `payload`: Input payload to receive the quickbooks authentication token
   *
   * - `organizationID`:
   */
  quickbooksOnlineAuthToken(params: QuickbooksOnlineService.QuickbooksOnlineAuthTokenParams): __Observable<null> {
    return this.quickbooksOnlineAuthTokenResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * disconnect quickbooks_online
   *
   * Disconnect from Quickbooks
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   */
  quickbooksOnlineDisconnectResponse(organizationID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/qb_disconnect`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * disconnect quickbooks_online
   *
   * Disconnect from Quickbooks
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   */
  quickbooksOnlineDisconnect(organizationID: string): __Observable<null> {
    return this.quickbooksOnlineDisconnectResponse(organizationID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * import_pos quickbooks_online
   *
   * Import all active POs from quickbooks
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  quickbooksOnlineImportPosResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroPos_import>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/qb_import_pos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPos_import>;
      })
    );
  }
  /**
   * import_pos quickbooks_online
   *
   * Import all active POs from quickbooks
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  quickbooksOnlineImportPos(organizationID: string): __Observable<LasgroPos_import> {
    return this.quickbooksOnlineImportPosResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroPos_import)
    );
  }

  /**
   * import_pos_progress quickbooks_online
   *
   * Get the progress of the PO importing process
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  quickbooksOnlineImportPosProgressResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroPos_import_progress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/qb_import_pos_progress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPos_import_progress>;
      })
    );
  }
  /**
   * import_pos_progress quickbooks_online
   *
   * Get the progress of the PO importing process
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  quickbooksOnlineImportPosProgress(organizationID: string): __Observable<LasgroPos_import_progress> {
    return this.quickbooksOnlineImportPosProgressResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroPos_import_progress)
    );
  }

  /**
   * sync_info quickbooks_online
   *
   * Quickbooks sync info
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  quickbooksOnlineSyncInfoResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroQb_sync_info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/qb_sync_info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroQb_sync_info>;
      })
    );
  }
  /**
   * sync_info quickbooks_online
   *
   * Quickbooks sync info
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  quickbooksOnlineSyncInfo(organizationID: string): __Observable<LasgroQb_sync_info> {
    return this.quickbooksOnlineSyncInfoResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroQb_sync_info)
    );
  }

  /**
   * sync_list quickbooks_online
   *
   * Quickbooks sync list
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   */
  quickbooksOnlineSyncListResponse(organizationID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/qb_syncs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * sync_list quickbooks_online
   *
   * Quickbooks sync list
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   */
  quickbooksOnlineSyncList(organizationID: string): __Observable<null> {
    return this.quickbooksOnlineSyncListResponse(organizationID).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module QuickbooksOnlineService {

  /**
   * Parameters for quickbooksOnlineAuthToken
   */
  export interface QuickbooksOnlineAuthTokenParams {

    /**
     * Input payload to receive the quickbooks authentication token
     */
    payload: QbAuthTokenInputPayload;
    organizationID: string;
  }
}

export { QuickbooksOnlineService }
