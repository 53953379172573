import { Injectable, Type } from '@angular/core';
import { ContractCommentsComponent } from '../components/contract/contract-comments/contract-comments.component';
import { ContractDetailsComponent } from '../components/contract/contract-details/contract-details.component';
import { ContractDocumentsAComponent } from '../components/contract/contract-documents-a/contract-documents-a.component';
import { ContractDocumentsBComponent } from '../components/contract/contract-documents-b/contract-documents-b.component';
import { ContractDocumentsCComponent } from '../components/contract/contract-documents-c/contract-documents-c.component';
import { ContractHeaderComponent } from '../components/contract/contract-header/contract-header.component';
import { ContractLogsComponent } from '../components/contract/contract-logs/contract-logs.component';
import { ContractMessagesComponent } from '../components/contract/contract-messages/contract-messages.component';
import { ContractTasksComponent } from '../components/contract/contract-tasks/contract-tasks.component';
import { ContractVouchersComponent } from '../components/contract/contract-vouchers/contract-vouchers.component';

@Injectable({
  providedIn: 'root',
})
export class ComponentRegistry {
  private componentMap: { [name: string]: Type<any> } = {
    "ContractDetailsComponent": ContractDetailsComponent,
    "ContractDocumentsAComponent": ContractDocumentsAComponent,
    "ContractDocumentsBComponent": ContractDocumentsBComponent,
    "ContractDocumentsCComponent": ContractDocumentsCComponent,
    "ContractHeaderComponent": ContractHeaderComponent,
    "ContractMessagesComponent": ContractMessagesComponent,
    "ContractCommentsComponent": ContractCommentsComponent,
    "ContractVouchersComponent": ContractVouchersComponent,
    "ContractTasksComponent": ContractTasksComponent,
    "ContractLogsComponent": ContractLogsComponent,
  };

  getComponentByName(name: string): Type<any> {
    return this.componentMap[name];
  }
}
