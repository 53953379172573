import { HttpClient } from '@angular/common/http';
import CONFIG from '@app/app.config';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardService {

  constructor(private httpClient: HttpClient) {

  }

  dashboardTasks() {
    const endpoint = `${ CONFIG.API }/dashboard_tasks`;
    return this.httpClient.get(endpoint);
  }

}
