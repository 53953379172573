<div class="modal-header po-modal font-weight-bold">
  Help Videos
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ev-form po-modal">
  <dx-data-grid
    #grid
    id="gridContainer"
    class="no-borders"
    [dataSource]="videosData"
    (onRowClick)="openVideo($event)"
    [rowAlternationEnabled]="true"
    [hoverStateEnabled]="true"
  >
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager [showNavigationButtons]="true"></dxo-pager>
    <dxo-paging [pageSize]="10"></dxo-paging>

    <dxi-column [width]="40" caption="" dataField="" [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column dataField="name" caption="Video name"></dxi-column>
    <dxi-column [width]="40" caption="" dataField="" [allowHeaderFiltering]="false"></dxi-column>

  </dx-data-grid>
</div>

<!-- <div class="modal-footer po-modal">

</div> -->

