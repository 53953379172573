import { Inject, Injectable, NgZone, OnDestroy, Optional } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { NGX_USERSNAP_CONFIG } from './config';
import { isUserSnapApiLoaded } from './util';

@Injectable({providedIn: 'root'})
export abstract class UserSnapService implements OnDestroy {
  api$: ReplaySubject<any> = new ReplaySubject(1);

  constructor(protected config) {
    this.config = this.config || {};
  }

  abstract load(initialConfig?);

  ngOnDestroy() {
    this.api$.complete();
  }
}

@Injectable()
export class NgxUserSnapAsyncCallbackApiLoader extends UserSnapService {

  constructor(protected zone: NgZone, @Optional() @Inject(NGX_USERSNAP_CONFIG) config) {
    super(config);
  }

  load(initialConfig?) {
    if (typeof window === 'undefined') {
      return;
    }

    if (isUserSnapApiLoaded()) {
      this.api$.next((window as any).UserSnap);
    } else if (!document.querySelector('#usersnap-api')) {
      (window as any).userSnapRef = (window as any).userSnapRef || [];
      (window as any).userSnapRef.push({
        zone: this.zone, componentFn: (UserSnap: any) => {
          console.log(UserSnap);
          UserSnap.init(initialConfig);
          this.api$.next(UserSnap);
          (window as any).UserSnap = UserSnap;
        },
      });
      this.addUserSnapApi();
    }
  }

  private addUserSnapApi() {
    (window as any).initusersnap = (window as any).initusersnap || ((UserSnap) => {
      (window as any).userSnapRef.forEach(userSnapRef => {
        userSnapRef.zone.run(() => { userSnapRef.componentFn(UserSnap); });
      });
      (window as any).userSnapRef.splice(0, (window as any).userSnapRef.length);
    });

    const script = document.createElement('script');
    script.id = 'usersnap-api';
    let apiUrl = this.config.apiUrl;
    apiUrl += apiUrl.indexOf('?') !== -1 ? '&' : '?';
    script.src = apiUrl + 'onload=initusersnap';
    script.setAttribute('defer', '1');
    document.querySelector('head').appendChild(script);
  }
}
