import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import CONFIG from '@app/app.config';
import { FileItemDto } from '@models/file-item.model';
import { ChecklistTaskDto } from '@app/models/checklist-task.model';
import { User } from '@models/user.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { CompanyService } from '@services/company.service';
import { Toaster } from '@services/toaster.service';
import { CustomPopupComponent } from '@theme/components';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'ngx-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    NgSelectModule,
    MatDatepickerModule,
    MatButtonModule
  ]
})
export class TaskDetailComponent implements OnInit {

  @Input() task: ChecklistTaskDto;
  taskData: ChecklistTaskDto;
  @Input() disabled: ChecklistTaskDto;
  @Input() availableUsers: User[] = [];
  filesLoading;
  taskFiles: FileItemDto[] = [];
  @Input() isCustomer: boolean;
  @Input() allowEdit: boolean;
  user$: Observable<User>;

  @Output() taskChange: EventEmitter<ChecklistTaskDto> = new EventEmitter<ChecklistTaskDto>();
  @Output() delete: EventEmitter<ChecklistTaskDto> = new EventEmitter<ChecklistTaskDto>();

  constructor(
    private companyService: CompanyService,
    private $toaster: Toaster,
    private matDialog: MatDialog,
    private store: Store<{ user: User }>,
    private $modalRef: NgbActiveModal) { }

  ngOnInit() {
    this.user$ = this.store.select('user');
    this.taskData = { ...this.task };
    this.getFilesList();
  }


  selectContact($event) {
    if (this.disabled) return;
    const user = $event;
    this.taskData.responsible_party = user.id;
    this.taskData.responsible_contact = user;
    this.updateTask();
  }

  closeModal() {
    this.$modalRef.close(this.taskData);
  }

  async addFileSelectedTask($event) {
    try {
      const fileList: FileList = $event.srcElement.files;
      const formData: FormData = new FormData();

      if (fileList.length === 0) {
        return;
      }

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        if (file.size > CONFIG.FILE_LIMIT) {
          this.$toaster.show('warning', 'File size', 'Maximum file size for uploads is 200 mb');
          return;
        }
        formData.append('file', file, file.name);
      }

      this.filesLoading = true;
      await this.companyService.addFileToTask(this.taskData.checklist_task_id, formData).toPromise();
      this.getFilesList();

      this.filesLoading = false;
      // this.onSubmit.emit('updated');
      this.$toaster.show('success', 'File', 'File has been added');
    } catch (error) {
      console.error(error);
      const errMsg =
        error.error && error.error.detail ? error.error.detail : 'An error occurred while uploading the files.';
      this.$toaster.show('error', 'File', errMsg);
    }
  }

  async getFilesList() {
    const taskFiles = await this.companyService.getTaskFiles(this.taskData.checklist_task_id).toPromise();
    this.taskFiles = taskFiles.sort((currentFile: FileItemDto, nextFile: FileItemDto) => {
      return currentFile.created_at < nextFile.created_at ? 1 : -1;
    });
  }

  removeFile(file) {
    const modal = this.matDialog.open(CustomPopupComponent, {
      disableClose: true,
    });

    modal.componentInstance.description = 'Are you sure you want to delete this file?';
    modal.afterClosed().subscribe(
      (result) => {
        if (result !== 'success') {
          return;
        }
        this.companyService.removeFileFromTask(file.file_id).subscribe(
          async () => {
            await this.getFilesList();
            this.$toaster.show('success', 'File', 'File has been removed');
            // this.onSubmit.emit('updated');
          },
          error => {
            const errMsg =
              error.error && error.error.detail ? error.error.detail : 'An error occurred while deleting the files.';
            this.$toaster.show('error', 'File', errMsg);
          },
        );
      });
  }

  downloadFile({ file_id: fileID, file_name: fileName }) {
    this.companyService
      .getTaskFileById(fileID)
      .subscribe(
        (res: HttpResponse<Blob>) => {
          const blob = new Blob([res.body], {
            type: 'application/octet-stream',
          });
          saveAs(blob, fileName);
        },
        error => {
          console.error(error);
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error occurred while downloading the files.';
          this.$toaster.show('error', 'File', errMsg);
        },
      );
  }

  updateTask() {
    this.taskChange.emit(this.taskData);
  }

  deleteTask() {
    this.delete.emit(this.taskData);
  }
}
