/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroCommissioned_liCollection } from '../models/lasgro-commissioned-_li-collection';
import { LasgroCommissioned_poCollection } from '../models/lasgro-commissioned-_po-collection';
import { LasgroOrganizationCollection } from '../models/lasgro-organization-collection';
import { LasgroLine_itemCollection } from '../models/lasgro-line-_item-collection';
import { LasgroOrganization } from '../models/lasgro-organization';
import { LasgroPurchase_orderCollection } from '../models/lasgro-purchase-_order-collection';
import { LasgroProductCollection } from '../models/lasgro-product-collection';
import { LasgroStepCollection } from '../models/lasgro-step-collection';
import { LasgroUserCollection } from '../models/lasgro-user-collection';
@Injectable({
  providedIn: 'root',
})
class SearchService extends __BaseService {
  static readonly searchCommissionedLiPath = '/search/commissioned_li';
  static readonly searchCommissionedPoPath = '/search/commissioned_po';
  static readonly searchCustomersPath = '/search/customers';
  static readonly searchLineItemsPath = '/search/line_items';
  static readonly searchOrganizationPrimaryContactPath = '/search/organization_primary_contact';
  static readonly searchOrganizationsPath = '/search/organizations';
  static readonly searchPurchaseOrdersPath = '/search/purchase_orders';
  static readonly searchRoutesPath = '/search/routes';
  static readonly searchStepsPath = '/search/steps';
  static readonly searchSuppliersPath = '/search/suppliers';
  static readonly searchUsersPath = '/search/users';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * commissioned_li search
   *
   * Search for commissioned line items
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchCommissionedLiParams` containing the following parameters:
   *
   * - `purchase_order_id`:
   *
   * - `status`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `quantity_ordered`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `line_item`:
   *
   * - `global`: Search from all fields
   *
   * - `customer_part_identifier`:
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  searchCommissionedLiResponse(params: SearchService.SearchCommissionedLiParams): __Observable<__StrictHttpResponse<LasgroCommissioned_liCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.purchaseOrderId != null) __params = __params.set('purchase_order_id', params.purchaseOrderId.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.shipDateMatchType != null) __params = __params.set('ship_date_match_type', params.shipDateMatchType.toString());
    if (params.shipDate != null) __params = __params.set('ship_date', params.shipDate.toString());
    if (params.quantityOrdered != null) __params = __params.set('quantity_ordered', params.quantityOrdered.toString());
    if (params.orderDateMatchType != null) __params = __params.set('order_date_match_type', params.orderDateMatchType.toString());
    if (params.orderDate != null) __params = __params.set('order_date', params.orderDate.toString());
    if (params.lineItem != null) __params = __params.set('line_item', params.lineItem.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.customerPartIdentifier != null) __params = __params.set('customer_part_identifier', params.customerPartIdentifier.toString());
    if (params.cancelDateMatchType != null) __params = __params.set('cancel_date_match_type', params.cancelDateMatchType.toString());
    if (params.cancelDate != null) __params = __params.set('cancel_date', params.cancelDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/commissioned_li`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_liCollection>;
      })
    );
  }
  /**
   * commissioned_li search
   *
   * Search for commissioned line items
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchCommissionedLiParams` containing the following parameters:
   *
   * - `purchase_order_id`:
   *
   * - `status`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `quantity_ordered`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `line_item`:
   *
   * - `global`: Search from all fields
   *
   * - `customer_part_identifier`:
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  searchCommissionedLi(params: SearchService.SearchCommissionedLiParams): __Observable<LasgroCommissioned_liCollection> {
    return this.searchCommissionedLiResponse(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_liCollection)
    );
  }

  /**
   * commissioned_po search
   *
   * Search for commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchCommissionedPoParams` containing the following parameters:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `supplier`:
   *
   * - `status`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `sales_order_number`:
   *
   * - `quantity_ordered`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `line_item`:
   *
   * - `global`: Searches from all fields
   *
   * - `customer_po_number`:
   *
   * - `customer_part_identifier`:
   *
   * - `completed`:
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  searchCommissionedPoResponse(params: SearchService.SearchCommissionedPoParams): __Observable<__StrictHttpResponse<LasgroCommissioned_poCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.supplier != null) __params = __params.set('supplier', params.supplier.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.shipDateMatchType != null) __params = __params.set('ship_date_match_type', params.shipDateMatchType.toString());
    if (params.shipDate != null) __params = __params.set('ship_date', params.shipDate.toString());
    if (params.salesOrderNumber != null) __params = __params.set('sales_order_number', params.salesOrderNumber.toString());
    if (params.quantityOrdered != null) __params = __params.set('quantity_ordered', params.quantityOrdered.toString());
    if (params.orderDateMatchType != null) __params = __params.set('order_date_match_type', params.orderDateMatchType.toString());
    if (params.orderDate != null) __params = __params.set('order_date', params.orderDate.toString());
    if (params.lineItem != null) __params = __params.set('line_item', params.lineItem.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.customerPoNumber != null) __params = __params.set('customer_po_number', params.customerPoNumber.toString());
    if (params.customerPartIdentifier != null) __params = __params.set('customer_part_identifier', params.customerPartIdentifier.toString());
    if (params.completed != null) __params = __params.set('completed', params.completed.toString());
    if (params.cancelDateMatchType != null) __params = __params.set('cancel_date_match_type', params.cancelDateMatchType.toString());
    if (params.cancelDate != null) __params = __params.set('cancel_date', params.cancelDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/commissioned_po`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_poCollection>;
      })
    );
  }
  /**
   * commissioned_po search
   *
   * Search for commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchCommissionedPoParams` containing the following parameters:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `supplier`:
   *
   * - `status`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `sales_order_number`:
   *
   * - `quantity_ordered`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `line_item`:
   *
   * - `global`: Searches from all fields
   *
   * - `customer_po_number`:
   *
   * - `customer_part_identifier`:
   *
   * - `completed`:
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  searchCommissionedPo(params: SearchService.SearchCommissionedPoParams): __Observable<LasgroCommissioned_poCollection> {
    return this.searchCommissionedPoResponse(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_poCollection)
    );
  }

  /**
   * customers search
   *
   * Search for customers
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchCustomersParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `postal_code`:
   *
   * - `plan_id`:
   *
   * - `name`:
   *
   * - `global`:
   *
   * - `country`:
   *
   * - `city`:
   *
   * - `address2`:
   *
   * - `address1`:
   *
   * @return OK
   */
  searchCustomersResponse(params: SearchService.SearchCustomersParams): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.region != null) __params = __params.set('region', params.region.toString());
    if (params.postalCode != null) __params = __params.set('postal_code', params.postalCode.toString());
    if (params.planId != null) __params = __params.set('plan_id', params.planId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    if (params.address2 != null) __params = __params.set('address2', params.address2.toString());
    if (params.address1 != null) __params = __params.set('address1', params.address1.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/customers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * customers search
   *
   * Search for customers
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchCustomersParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `postal_code`:
   *
   * - `plan_id`:
   *
   * - `name`:
   *
   * - `global`:
   *
   * - `country`:
   *
   * - `city`:
   *
   * - `address2`:
   *
   * - `address1`:
   *
   * @return OK
   */
  searchCustomers(params: SearchService.SearchCustomersParams): __Observable<LasgroOrganizationCollection> {
    return this.searchCustomersResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * line_items search
   *
   * Search for line items
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchLineItemsParams` containing the following parameters:
   *
   * - `unit_price_currency`: Currency of unit price
   *
   * - `status`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `quantity_ordered`:
   *
   * - `purchase_order_id`:
   *
   * - `product_id`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `line_item`:
   *
   * - `is_parent`:
   *
   * - `global`: Search from all fields
   *
   * - `customer_part_identifier`:
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  searchLineItemsResponse(params: SearchService.SearchLineItemsParams): __Observable<__StrictHttpResponse<LasgroLine_itemCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.unitPriceCurrency != null) __params = __params.set('unit_price_currency', params.unitPriceCurrency.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.shipDateMatchType != null) __params = __params.set('ship_date_match_type', params.shipDateMatchType.toString());
    if (params.shipDate != null) __params = __params.set('ship_date', params.shipDate.toString());
    if (params.quantityOrdered != null) __params = __params.set('quantity_ordered', params.quantityOrdered.toString());
    if (params.purchaseOrderId != null) __params = __params.set('purchase_order_id', params.purchaseOrderId.toString());
    if (params.productId != null) __params = __params.set('product_id', params.productId.toString());
    if (params.orderDateMatchType != null) __params = __params.set('order_date_match_type', params.orderDateMatchType.toString());
    if (params.orderDate != null) __params = __params.set('order_date', params.orderDate.toString());
    if (params.lineItem != null) __params = __params.set('line_item', params.lineItem.toString());
    if (params.isParent != null) __params = __params.set('is_parent', params.isParent.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.customerPartIdentifier != null) __params = __params.set('customer_part_identifier', params.customerPartIdentifier.toString());
    if (params.cancelDateMatchType != null) __params = __params.set('cancel_date_match_type', params.cancelDateMatchType.toString());
    if (params.cancelDate != null) __params = __params.set('cancel_date', params.cancelDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/line_items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroLine_itemCollection>;
      })
    );
  }
  /**
   * line_items search
   *
   * Search for line items
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchLineItemsParams` containing the following parameters:
   *
   * - `unit_price_currency`: Currency of unit price
   *
   * - `status`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `quantity_ordered`:
   *
   * - `purchase_order_id`:
   *
   * - `product_id`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `line_item`:
   *
   * - `is_parent`:
   *
   * - `global`: Search from all fields
   *
   * - `customer_part_identifier`:
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  searchLineItems(params: SearchService.SearchLineItemsParams): __Observable<LasgroLine_itemCollection> {
    return this.searchLineItemsResponse(params).pipe(
      __map(_r => _r.body as LasgroLine_itemCollection)
    );
  }

  /**
   * organization_primary_contact search
   *
   * Search for organization by primary contact's email
   *
   * Required security scopes:
   *   * `api:access`
   * @param email undefined
   * @return OK
   */
  searchOrganizationPrimaryContactResponse(email: string): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/organization_primary_contact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * organization_primary_contact search
   *
   * Search for organization by primary contact's email
   *
   * Required security scopes:
   *   * `api:access`
   * @param email undefined
   * @return OK
   */
  searchOrganizationPrimaryContact(email: string): __Observable<LasgroOrganization> {
    return this.searchOrganizationPrimaryContactResponse(email).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * organizations search
   *
   * Search for organizations
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchOrganizationsParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `postal_code`:
   *
   * - `plan_id`:
   *
   * - `name`:
   *
   * - `global`:
   *
   * - `country`:
   *
   * - `city`:
   *
   * - `address2`:
   *
   * - `address1`:
   *
   * @return OK
   */
  searchOrganizationsResponse(params: SearchService.SearchOrganizationsParams): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.region != null) __params = __params.set('region', params.region.toString());
    if (params.postalCode != null) __params = __params.set('postal_code', params.postalCode.toString());
    if (params.planId != null) __params = __params.set('plan_id', params.planId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    if (params.address2 != null) __params = __params.set('address2', params.address2.toString());
    if (params.address1 != null) __params = __params.set('address1', params.address1.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * organizations search
   *
   * Search for organizations
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchOrganizationsParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `postal_code`:
   *
   * - `plan_id`:
   *
   * - `name`:
   *
   * - `global`:
   *
   * - `country`:
   *
   * - `city`:
   *
   * - `address2`:
   *
   * - `address1`:
   *
   * @return OK
   */
  searchOrganizations(params: SearchService.SearchOrganizationsParams): __Observable<LasgroOrganizationCollection> {
    return this.searchOrganizationsResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * purchase_orders search
   *
   * Search for purchase_orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchPurchaseOrdersParams` containing the following parameters:
   *
   * - `viewType`: What view type to show for the search
   *
   * - `unit_price_currency`: Currency of unit price
   *
   * - `supplier_id`:
   *
   * - `status`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `sales_order_number`:
   *
   * - `quantity_ordered`:
   *
   * - `product_id`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `line_item`:
   *
   * - `is_parent`:
   *
   * - `global`: Searches from all fields
   *
   * - `customer_po_number`:
   *
   * - `customer_part_identifier`:
   *
   * - `customer_name`:
   *
   * - `customer_id`:
   *
   * - `completed`:
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  searchPurchaseOrdersResponse(params: SearchService.SearchPurchaseOrdersParams): __Observable<__StrictHttpResponse<LasgroPurchase_orderCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.unitPriceCurrency != null) __params = __params.set('unit_price_currency', params.unitPriceCurrency.toString());
    if (params.supplierId != null) __params = __params.set('supplier_id', params.supplierId.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.shipDateMatchType != null) __params = __params.set('ship_date_match_type', params.shipDateMatchType.toString());
    if (params.shipDate != null) __params = __params.set('ship_date', params.shipDate.toString());
    if (params.salesOrderNumber != null) __params = __params.set('sales_order_number', params.salesOrderNumber.toString());
    if (params.quantityOrdered != null) __params = __params.set('quantity_ordered', params.quantityOrdered.toString());
    if (params.productId != null) __params = __params.set('product_id', params.productId.toString());
    if (params.orderDateMatchType != null) __params = __params.set('order_date_match_type', params.orderDateMatchType.toString());
    if (params.orderDate != null) __params = __params.set('order_date', params.orderDate.toString());
    if (params.lineItem != null) __params = __params.set('line_item', params.lineItem.toString());
    if (params.isParent != null) __params = __params.set('is_parent', params.isParent.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.customerPoNumber != null) __params = __params.set('customer_po_number', params.customerPoNumber.toString());
    if (params.customerPartIdentifier != null) __params = __params.set('customer_part_identifier', params.customerPartIdentifier.toString());
    if (params.customerName != null) __params = __params.set('customer_name', params.customerName.toString());
    if (params.customerId != null) __params = __params.set('customer_id', params.customerId.toString());
    if (params.completed != null) __params = __params.set('completed', params.completed.toString());
    if (params.cancelDateMatchType != null) __params = __params.set('cancel_date_match_type', params.cancelDateMatchType.toString());
    if (params.cancelDate != null) __params = __params.set('cancel_date', params.cancelDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/purchase_orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_orderCollection>;
      })
    );
  }
  /**
   * purchase_orders search
   *
   * Search for purchase_orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchPurchaseOrdersParams` containing the following parameters:
   *
   * - `viewType`: What view type to show for the search
   *
   * - `unit_price_currency`: Currency of unit price
   *
   * - `supplier_id`:
   *
   * - `status`:
   *
   * - `ship_date_match_type`: What operation to filter with against ship_date
   *
   * - `ship_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `sales_order_number`:
   *
   * - `quantity_ordered`:
   *
   * - `product_id`:
   *
   * - `order_date_match_type`: What operation to filter with against order_date
   *
   * - `order_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * - `line_item`:
   *
   * - `is_parent`:
   *
   * - `global`: Searches from all fields
   *
   * - `customer_po_number`:
   *
   * - `customer_part_identifier`:
   *
   * - `customer_name`:
   *
   * - `customer_id`:
   *
   * - `completed`:
   *
   * - `cancel_date_match_type`: What operation to filter with against cancel_date
   *
   * - `cancel_date`: the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
   *
   * @return OK
   */
  searchPurchaseOrders(params: SearchService.SearchPurchaseOrdersParams): __Observable<LasgroPurchase_orderCollection> {
    return this.searchPurchaseOrdersResponse(params).pipe(
      __map(_r => _r.body as LasgroPurchase_orderCollection)
    );
  }

  /**
   * routes search
   *
   * Search for routes
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchRoutesParams` containing the following parameters:
   *
   * - `product_bar_code`:
   *
   * - `piece_rate_currency`:
   *
   * - `piece_rate`:
   *
   * - `name`:
   *
   * - `iBuy`: List route I sell or I buy
   *
   * - `global`:
   *
   * - `description`:
   *
   * - `customer_part_identifier`:
   *
   * @return OK
   */
  searchRoutesResponse(params: SearchService.SearchRoutesParams): __Observable<__StrictHttpResponse<LasgroProductCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productBarCode != null) __params = __params.set('product_bar_code', params.productBarCode.toString());
    if (params.pieceRateCurrency != null) __params = __params.set('piece_rate_currency', params.pieceRateCurrency.toString());
    if (params.pieceRate != null) __params = __params.set('piece_rate', params.pieceRate.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.iBuy != null) __params = __params.set('iBuy', params.iBuy.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.description != null) __params = __params.set('description', params.description.toString());
    if (params.customerPartIdentifier != null) __params = __params.set('customer_part_identifier', params.customerPartIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/routes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroProductCollection>;
      })
    );
  }
  /**
   * routes search
   *
   * Search for routes
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchRoutesParams` containing the following parameters:
   *
   * - `product_bar_code`:
   *
   * - `piece_rate_currency`:
   *
   * - `piece_rate`:
   *
   * - `name`:
   *
   * - `iBuy`: List route I sell or I buy
   *
   * - `global`:
   *
   * - `description`:
   *
   * - `customer_part_identifier`:
   *
   * @return OK
   */
  searchRoutes(params: SearchService.SearchRoutesParams): __Observable<LasgroProductCollection> {
    return this.searchRoutesResponse(params).pipe(
      __map(_r => _r.body as LasgroProductCollection)
    );
  }

  /**
   * steps search
   *
   * Search for steps
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchStepsParams` containing the following parameters:
   *
   * - `setup_time`:
   *
   * - `route_step_category_id`:
   *
   * - `product_id`:
   *
   * - `name`:
   *
   * - `machine_time`:
   *
   * - `global`:
   *
   * - `description`:
   *
   * - `barcode`:
   *
   * - `active`:
   *
   * @return OK
   */
  searchStepsResponse(params: SearchService.SearchStepsParams): __Observable<__StrictHttpResponse<LasgroStepCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.setupTime != null) __params = __params.set('setup_time', params.setupTime.toString());
    if (params.routeStepCategoryId != null) __params = __params.set('route_step_category_id', params.routeStepCategoryId.toString());
    if (params.productId != null) __params = __params.set('product_id', params.productId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.machineTime != null) __params = __params.set('machine_time', params.machineTime.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.description != null) __params = __params.set('description', params.description.toString());
    if (params.barcode != null) __params = __params.set('barcode', params.barcode.toString());
    if (params.active != null) __params = __params.set('active', params.active.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/steps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroStepCollection>;
      })
    );
  }
  /**
   * steps search
   *
   * Search for steps
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchStepsParams` containing the following parameters:
   *
   * - `setup_time`:
   *
   * - `route_step_category_id`:
   *
   * - `product_id`:
   *
   * - `name`:
   *
   * - `machine_time`:
   *
   * - `global`:
   *
   * - `description`:
   *
   * - `barcode`:
   *
   * - `active`:
   *
   * @return OK
   */
  searchSteps(params: SearchService.SearchStepsParams): __Observable<LasgroStepCollection> {
    return this.searchStepsResponse(params).pipe(
      __map(_r => _r.body as LasgroStepCollection)
    );
  }

  /**
   * suppliers search
   *
   * Search for suppliers
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchSuppliersParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `postal_code`:
   *
   * - `plan_id`:
   *
   * - `name`:
   *
   * - `is_broker`:
   *
   * - `global`:
   *
   * - `country`:
   *
   * - `city`:
   *
   * - `address2`:
   *
   * - `address1`:
   *
   * @return OK
   */
  searchSuppliersResponse(params: SearchService.SearchSuppliersParams): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.region != null) __params = __params.set('region', params.region.toString());
    if (params.postalCode != null) __params = __params.set('postal_code', params.postalCode.toString());
    if (params.planId != null) __params = __params.set('plan_id', params.planId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.isBroker != null) __params = __params.set('is_broker', params.isBroker.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    if (params.address2 != null) __params = __params.set('address2', params.address2.toString());
    if (params.address1 != null) __params = __params.set('address1', params.address1.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/suppliers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * suppliers search
   *
   * Search for suppliers
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchSuppliersParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `postal_code`:
   *
   * - `plan_id`:
   *
   * - `name`:
   *
   * - `is_broker`:
   *
   * - `global`:
   *
   * - `country`:
   *
   * - `city`:
   *
   * - `address2`:
   *
   * - `address1`:
   *
   * @return OK
   */
  searchSuppliers(params: SearchService.SearchSuppliersParams): __Observable<LasgroOrganizationCollection> {
    return this.searchSuppliersResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * users search
   *
   * Search for users
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchUsersParams` containing the following parameters:
   *
   * - `username`:
   *
   * - `role_id`:
   *
   * - `phone_number`:
   *
   * - `organization_id`:
   *
   * - `lastname`:
   *
   * - `global`:
   *
   * - `firstname`:
   *
   * - `email`:
   *
   * @return OK
   */
  searchUsersResponse(params: SearchService.SearchUsersParams): __Observable<__StrictHttpResponse<LasgroUserCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.username != null) __params = __params.set('username', params.username.toString());
    if (params.roleId != null) __params = __params.set('role_id', params.roleId.toString());
    if (params.phoneNumber != null) __params = __params.set('phone_number', params.phoneNumber.toString());
    if (params.organizationId != null) __params = __params.set('organization_id', params.organizationId.toString());
    if (params.lastname != null) __params = __params.set('lastname', params.lastname.toString());
    if (params.global != null) __params = __params.set('global', params.global.toString());
    if (params.firstname != null) __params = __params.set('firstname', params.firstname.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUserCollection>;
      })
    );
  }
  /**
   * users search
   *
   * Search for users
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SearchService.SearchUsersParams` containing the following parameters:
   *
   * - `username`:
   *
   * - `role_id`:
   *
   * - `phone_number`:
   *
   * - `organization_id`:
   *
   * - `lastname`:
   *
   * - `global`:
   *
   * - `firstname`:
   *
   * - `email`:
   *
   * @return OK
   */
  searchUsers(params: SearchService.SearchUsersParams): __Observable<LasgroUserCollection> {
    return this.searchUsersResponse(params).pipe(
      __map(_r => _r.body as LasgroUserCollection)
    );
  }
}

module SearchService {

  /**
   * Parameters for searchCommissionedLi
   */
  export interface SearchCommissionedLiParams {
    purchaseOrderId: string;
    status?: string;

    /**
     * What operation to filter with against ship_date
     */
    shipDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    shipDate?: string;
    quantityOrdered?: number;

    /**
     * What operation to filter with against order_date
     */
    orderDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    orderDate?: string;
    lineItem?: string;

    /**
     * Search from all fields
     */
    global?: string;
    customerPartIdentifier?: string;

    /**
     * What operation to filter with against cancel_date
     */
    cancelDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    cancelDate?: string;
  }

  /**
   * Parameters for searchCommissionedPo
   */
  export interface SearchCommissionedPoParams {

    /**
     * What view type to show for the checklist
     */
    viewType?: 'default' | 'report';
    supplier?: string;
    status?: string;

    /**
     * What operation to filter with against ship_date
     */
    shipDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    shipDate?: string;
    salesOrderNumber?: string;
    quantityOrdered?: number;

    /**
     * What operation to filter with against order_date
     */
    orderDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    orderDate?: string;
    lineItem?: string;

    /**
     * Searches from all fields
     */
    global?: string;
    customerPoNumber?: string;
    customerPartIdentifier?: string;
    completed?: boolean;

    /**
     * What operation to filter with against cancel_date
     */
    cancelDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    cancelDate?: string;
  }

  /**
   * Parameters for searchCustomers
   */
  export interface SearchCustomersParams {
    region?: string;
    postalCode?: string;
    planId?: number;
    name?: string;
    global?: string;
    country?: string;
    city?: string;
    address2?: string;
    address1?: string;
  }

  /**
   * Parameters for searchLineItems
   */
  export interface SearchLineItemsParams {

    /**
     * Currency of unit price
     */
    unitPriceCurrency?: string;
    status?: string;

    /**
     * What operation to filter with against ship_date
     */
    shipDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    shipDate?: string;
    quantityOrdered?: number;
    purchaseOrderId?: string;
    productId?: string;

    /**
     * What operation to filter with against order_date
     */
    orderDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    orderDate?: string;
    lineItem?: string;
    isParent?: boolean;

    /**
     * Search from all fields
     */
    global?: string;
    customerPartIdentifier?: string;

    /**
     * What operation to filter with against cancel_date
     */
    cancelDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    cancelDate?: string;
  }

  /**
   * Parameters for searchOrganizations
   */
  export interface SearchOrganizationsParams {
    region?: string;
    postalCode?: string;
    planId?: number;
    name?: string;
    global?: string;
    country?: string;
    city?: string;
    address2?: string;
    address1?: string;
  }

  /**
   * Parameters for searchPurchaseOrders
   */
  export interface SearchPurchaseOrdersParams {

    /**
     * What view type to show for the search
     */
    viewType?: 'default' | 'report';

    /**
     * Currency of unit price
     */
    unitPriceCurrency?: string;
    supplierId?: string;
    status?: string;

    /**
     * What operation to filter with against ship_date
     */
    shipDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    shipDate?: string;
    salesOrderNumber?: string;
    quantityOrdered?: number;
    productId?: string;

    /**
     * What operation to filter with against order_date
     */
    orderDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    orderDate?: string;
    lineItem?: string;
    isParent?: boolean;

    /**
     * Searches from all fields
     */
    global?: string;
    customerPoNumber?: string;
    customerPartIdentifier?: string;
    customerName?: string;
    customerId?: string;
    completed?: boolean;

    /**
     * What operation to filter with against cancel_date
     */
    cancelDateMatchType?: 'exact' | 'greater_than' | 'less_than' | 'between';

    /**
     * the date or dates for searching, in the format of either yyyy-mm-dd or yyyy-mm-dd_yyyy-mm-dd. The first format is if you're searching for exactly, greater than, or less than a single date, while the second is if you're searching between two dates
     */
    cancelDate?: string;
  }

  /**
   * Parameters for searchRoutes
   */
  export interface SearchRoutesParams {
    productBarCode?: string;
    pieceRateCurrency?: string;
    pieceRate?: number;
    name?: string;

    /**
     * List route I sell or I buy
     */
    iBuy?: boolean;
    global?: string;
    description?: string;
    customerPartIdentifier?: string;
  }

  /**
   * Parameters for searchSteps
   */
  export interface SearchStepsParams {
    setupTime?: string;
    routeStepCategoryId?: number;
    productId?: string;
    name?: string;
    machineTime?: string;
    global?: string;
    description?: string;
    barcode?: string;
    active?: boolean;
  }

  /**
   * Parameters for searchSuppliers
   */
  export interface SearchSuppliersParams {
    region?: string;
    postalCode?: string;
    planId?: number;
    name?: string;
    isBroker?: boolean;
    global?: string;
    country?: string;
    city?: string;
    address2?: string;
    address1?: string;
  }

  /**
   * Parameters for searchUsers
   */
  export interface SearchUsersParams {
    username?: string;
    roleId?: number;
    phoneNumber?: string;
    organizationId?: string;
    lastname?: string;
    global?: string;
    firstname?: string;
    email?: string;
  }
}

export { SearchService }
