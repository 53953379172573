import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, KeyValue } from '@angular/common';
import { Checklist, FileItemDto, Product, User } from '@models/index';
import { PdfVisualizerComponent, RejectionReasonModalComponent } from '@shared/modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CompanyService, InvoiceService, Toaster } from '@services/index';
import { PurchaseOrderService } from '@services/purchase-order.service';
import { CustomPopupComponent } from '@theme/components';
import { saveAs } from 'file-saver';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { PERIOD_TAGS, TAGS_TO_MONTHLY_PERIODS, TAGS_TO_QUARTERLY_PERIODS } from '@models/periods.model';
import Swal from 'sweetalert2';
import { AddInsuranceDateDialog } from '../add-insurance-date/add-insurance-date.dialog';
import { PurchaseOrderFilesService } from 'api/services';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { NgxLoadingModule } from 'ngx-loading';
import { FileSizePipe } from '@app/shared/pipes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FileInfoInputPayload, FileTypePayload, LasgroFile_info } from 'api/models';
import { FormsModule } from '@angular/forms';
import { FilesPreviewDirective } from '@app/shared/directives/files-preview.directive';

@Component({
  standalone: true,
  selector: 'ngx-po-files',
  templateUrl: './po-files.component.html',
  styleUrls: ['./po-files.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    NgxLoadingModule,
    FileSizePipe,
    MatTooltipModule,
    MatIconModule,
    MatInputModule,
    FilesPreviewDirective
  ]
})
export class PoFilesComponent implements OnInit, OnDestroy {
  readonly FILE_LIMIT: number = 25000000;

  loading: boolean = false;
  isCustomer: boolean;
  user$: Observable<User>;
  currentUser: User;
  checklist: Checklist[];
  files: any[] = [];
  filter: string;
  products$: Observable<Product[]>;

  @LocalStorage() organizationId: string;
  @Input() companyType: string;
  @Input() purchaseOrderId: string;
  @Input() fileTypes: FileTypePayload[];
  @Input() enableControls: boolean = true;
  @Input() isSimplePoView: boolean = false;
  @Input() defaultTag: string;
  @Input() refreshEvent: EventEmitter<any>;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() hideAddButton: boolean = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() isInsuranceLogicEnabled = false;
  @Input() isApproveLogicEnabled = false;
  @Input() isApprovalSection: boolean = false;
  @Input() isCommentVisible: boolean = false;
  @Input() isFileDeletionAvaliable: boolean = false;
  @Input() isListOfTypesDisplaying: boolean = false;
  @Input() addFileEventEmitter: EventEmitter<any>;

  periodTags = PERIOD_TAGS;
  tagsToMonthlyPeriods = TAGS_TO_MONTHLY_PERIODS;
  tagsToQuarterlyPeriods = TAGS_TO_QUARTERLY_PERIODS;

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  constructor(
    private $company: CompanyService,
    private $invoice: InvoiceService,
    private purchaseOrderService: PurchaseOrderService,
    private $toaster: Toaster,
    private matDialog: MatDialog,
    private $modal: NgbModal,
    private $store: Store<{ user: User }>,
    private purchaseOrderfileService: PurchaseOrderFilesService,
  ) {}

  ngOnInit() {
    this.user$ = this.$store.select('user');
    this.user$.subscribe((user) => {
      this.currentUser = user as User;
    });
    this.isCustomer = this.companyType === 'customer';
    this.updateFilesIfFileTab();
    this.$company.poChangesSeen(this.organizationId, this.purchaseOrderId, 'purchase_order_files').subscribe();

    if (this.refreshEvent) {
      this.refreshEvent.subscribe((e) => {
        this.updateFilesIfFileTab();
      });
    }
  }

  ngAfterViewInit() {
    if (this.addFileEventEmitter) {
      this.addFileEventEmitter.subscribe(() => {
        if (this.fileInput) {
          this.fileInput.nativeElement.click();
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeFilter($event) {
    this.filter = $event.value;
    this.resetSelectedFiles();
  }

  getSelectedFiles() {
    return this.files.filter((f) => f.checked === true);
  }

  isMoreThanOneApprovedFileSelected() {
    return this.getFilteredFiles().filter((f) => f.checked === true && f.status === 'approved').length > 1;
  }

  resetSelectedFiles() {
    this.files.forEach((f) => (f.checked = false));
  }

  getFilteredFiles() {
    if (!this.filter) {
      return this.files;
    }
    return this.files.filter((f) => f.tags.includes(this.filter));
  }

  updateFilesIfFileTab() {
    this.loading = true;
    const fn = ((poId: string) => {
      if (this.defaultTag) {
        return this.$company.getPoFiles(poId, this.defaultTag);
      }
      return this.$company.getAllPoFiles(poId);
    }).bind(this);

    fn(this.purchaseOrderId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (purchaseOrderFiles) => {
          this.loading = false;

          if (Array.isArray(purchaseOrderFiles)) {
            this.files = [...this.sortFilesByDate(purchaseOrderFiles)];
            return;
          }

          let mainFiles = [];
          let additionalFiles = [];

          if (purchaseOrderFiles.main_file) mainFiles.push(purchaseOrderFiles.main_file);
          if (purchaseOrderFiles.proforma_invoice_file)
            mainFiles.push({
              ...purchaseOrderFiles.proforma_invoice_file,
              is_proforma_invoice_file: true,
            });
          if (purchaseOrderFiles.invoice_files) {
            mainFiles = [...mainFiles, ...purchaseOrderFiles.invoice_files];
          }
          if (purchaseOrderFiles.purchase_order_files) {
            mainFiles = [...mainFiles, ...this.sortFilesByDate(purchaseOrderFiles.purchase_order_files)];
          }
          if (purchaseOrderFiles.line_item_files) {
            additionalFiles = [...additionalFiles, ...purchaseOrderFiles.line_item_files];
          }
          if (purchaseOrderFiles.product_files) {
            additionalFiles = [...additionalFiles, ...purchaseOrderFiles.product_files];
          }
          if (purchaseOrderFiles.product_url_files) {
            additionalFiles = [...additionalFiles, ...purchaseOrderFiles.product_url_files];
          }
          if (purchaseOrderFiles.checklist_files) {
            additionalFiles = [...additionalFiles, ...purchaseOrderFiles.checklist_files];
          }
          this.files = [...mainFiles, ...this.sortFilesByDate(additionalFiles)];
        },
        (error) => {
          this.loading = false;
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error occurred while fetching files.';
          this.$toaster.show('error', 'Files', errMsg);
        },
      );
  }

  sortFilesByDate(files) {
    return files.sort((currentFile, nextFile) => {
      return currentFile.created_at < nextFile.created_at ? 1 : -1;
    });
  }

  async addFile(event) {
    if (this.isInsuranceLogicEnabled && !this.isCustomer) {
      let file = event.target.files[0];
      let dialgRef = this.matDialog.open(AddInsuranceDateDialog, {
        data: { organizationId: this.organizationId, fileName: file.name, types: this.fileTypes },
      });
      dialgRef.afterClosed().subscribe(async (data) => {
        if (data) {
          await this.addServerFile(event, data);
        }
      });
    } else {
      await this.addServerFile(event, null);
    }
  }

  async addServerFile(event, data) {
    const fileList: FileList = event.srcElement.files;
    const formData: FormData = new FormData();

    if (fileList.length === 0) {
      return;
    }

    let periodTag;
    if (this.defaultTag === '$submitted_voucher_documents') {
      periodTag = await this.openPeriodModal();
      if (!periodTag) {
        this.fileInput.nativeElement.value = null;
        return;
      }
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file.size > this.FILE_LIMIT) {
        this.$toaster.show('warning', 'File size', 'Maximum file size for uploads is 25MB');
        return;
      }
      formData.append('file', file, file.name);
    }

    this.loading = true;
    this.$company
      .addFileToPo(
        this.organizationId,
        this.purchaseOrderId,
        formData,
        false,
        false,
        this.defaultTag,
        this.isApprovalSection,
        periodTag,
        data ? (data.insuranceDate != null ? data.insuranceDate.utc().toJSON() : null) : null,
        data ? data.type.value : null,
        data ? data.type.required : null,
        data ? data.name : null,
      )
      .subscribe(
        (_) => {
          this.updateFilesIfFileTab();
          this.loading = false;
          this.$toaster.show('success', 'Success', 'File has been added');
          this.onSubmit.emit('updated');
        },
        (error) => {
          console.error(error);
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error ocurred while uploading a file.';
          this.$toaster.show('error', 'File upload', errMsg);
          this.loading = false;
        },
      );
  }

  viewPdf(file: any) {
    const modal = this.$modal.open(PdfVisualizerComponent, {
      size: 'lg',
      windowClass: 'pdf-visualizer',
    });
    modal.componentInstance.title = file.file_name;
    modal.componentInstance.loading = true;

    const fileRequest =
      file.uuid_type === 'INVOICE'
        ? this.$invoice.getInvoiceFile(this.organizationId, this.purchaseOrderId, file.parent_uuid, file.file_id)
        : this.fetchFile(file);

    fileRequest.subscribe(
      (res) => {
        modal.componentInstance.pdfViewer.pdfSrc = res.body;
        modal.componentInstance.pdfViewer.refresh();
        modal.componentInstance.loading = false;
      },
      (error) => {
        console.error(error);
        const errMsg =
          error.error && error.error.detail ? error.error.detail : 'An error occurred while downloading the pdf.';
        this.$toaster.show('error', 'PDF download', errMsg);
      },
    );
  }

  downloadFile(file: any) {
    const fileRequest =
      file.uuid_type === 'INVOICE'
        ? this.$invoice.getInvoiceFile(this.organizationId, this.purchaseOrderId, file.parent_uuid, file.file_id)
        : this.fetchFile(file);

    fileRequest.subscribe(
      (res) => {
        const blob = new Blob([res.body], { type: 'application/octet-stream' });
        saveAs(blob, file.file_name);
      },
      (error) => {
        console.error(error);
        const errMsg =
          error.error && error.error.detail ? error.error.detail : 'An error occurred while downloading the file.';
        this.$toaster.show('error', 'File download', errMsg);
      },
    );
  }

  fetchFile({ file_id: fileID, file_name: fileName, uuid_type: fileType, parent_uuid: parentID }) {
    const fileRequest =
      fileType !== 'CHECKLIST_TASK'
        ? this.$company.getPoFileById(this.organizationId, this.purchaseOrderId, fileID, fileType === 'PRODUCT')
        : this.$company.getTaskFileById(fileID);
    return fileRequest;
  }

  async removeFile({ file_id: fileID, uuid_type: fileType }) {
    const modal = this.matDialog.open(CustomPopupComponent, { disableClose: true });
    modal.componentInstance.description = 'Are you sure you want to delete this file?';

    let result: any;
    try {
      result = await modal.afterClosed().toPromise();
    } catch (error) {
      if (error !== 0) {
        console.error(error);
        return;
      }
    }

    if (result !== 'success') {
      return;
    }

    this.loading = true;
    const req =
      fileType === 'CHECKLIST_TASK'
        ? this.$company.removeFileFromTask(fileID)
        : this.$company.removeFileFromPO(this.organizationId, this.purchaseOrderId, fileID);
    req.subscribe(
      (_) => {
        this.updateFilesIfFileTab();
        this.onSubmit.emit('updated');
        this.loading = false;
        this.$toaster.show('success', 'Success', 'File has been removed');
      },
      (error) => {
        console.error(error);
        const errMsg =
          error.error && error.error.detail ? error.error.detail : 'An error ocurred while deleting the file.';
        this.$toaster.show('error', 'File delete', errMsg);
        this.loading = false;
      },
    );
  }

  setAsMainFile(file: FileItemDto) {
    this.purchaseOrderService.setPoMainFile(file.file_id).subscribe(
      () => {
        this.$toaster.show('success', 'Purchase Order', 'Purchase Order File Updated.');
        this.updateFilesIfFileTab();
      },
      (error) => {
        const errMsg =
          error.error && error.error.detail ? error.error.detail : 'An error occurred while fetching files.';
        this.$toaster.show('error', 'Files', errMsg);
      },
    );
  }

  acceptOrRejectFile(file: FileItemDto, accepted: boolean) {
    if (!accepted) {
      setTimeout(() => {
        this.$modal
          .open(RejectionReasonModalComponent, {
            size: 'sm',
            container: 'nb-layout',
            keyboard: false,
            windowClass: 'rejection-reason-modal',
          })
          .result.then((result: any) => {
            if (result) {
              // file.rejection_reason = result.reason;
              this.loading = true;
              this.$company
                .rejectPoFile(this.organizationId, this.purchaseOrderId, file.file_id, result.reason)
                .subscribe(
                  async (res) => {
                    this.$toaster.show('success', 'Success', 'Document rejected');
                    this.updateFilesIfFileTab();
                    this.loading = false;
                  },
                  (error) => {
                    const errMsg = !!error.error && !!error.error.detail ? error.error.detail : error.message;
                    this.$toaster.show('error', 'An error occurred', 'Please try again later.');
                    console.error(errMsg);
                    this.loading = false;
                  },
                );
            } else {
              this.loading = false;
            }
          });
      });
    } else {
      this.loading = true;
      this.$company.approvePoFile(this.organizationId, this.purchaseOrderId, file.file_id).subscribe(
        async (res) => {
          this.$toaster.show('success', 'Success', 'Document accepted');
          this.updateFilesIfFileTab();
          this.loading = false;
        },
        (error) => {
          const errMsg = !!error.error && !!error.error.detail ? error.error.detail : error.message;
          this.$toaster.show('error', 'An error occurred', 'Please try again later.');
          console.error(errMsg);
          this.loading = false;
        },
      );
    }
  }

  getFilePeriod(file) {
    let period: string;
    period = this.periodTags.monthly.find((p) => file.tags.includes(p));
    if (!period) {
      period = this.periodTags.quarterly.find((p) => file.tags.includes(p));
    }
    return period ? period.replace('$', '') : '';
  }

  getPandaDocStatus(file) {
    if (!file?.panda_doc_document?.status) {
      return '';
    }
    let status: string = file.panda_doc_document.status;
    status = status.replace('document.', '');
    let pdStatus: string;

    switch (status) {
      case 'voided':
      case 'declined':
        pdStatus = 'Invalid';
        break;
      case 'uploaded':
      case 'draft':
        pdStatus = 'Document is being prepared...';
        break;
      case 'viewed':
      case 'sent':
        pdStatus = 'Pending signatures';
        break;
      case 'completed':
        pdStatus = 'Completed';
        break;
    }

    return pdStatus;
  }

  getPandaDocDocumentCta(doc) {
    if (!doc) return;
    switch (doc.status) {
      case 'document.viewed':
      case 'document.sent':
        if (this.isUserNextRecipientToSignPandaDocDocument(doc)) {
          return 'Sign';
        }
        return 'Open';
      case 'document.completed':
        return 'Open';
      default:
        return '';
    }
  }

  isUserNextRecipientToSignPandaDocDocument(doc) {
    if (!doc.recipients) {
      return false;
    }

    let recipient = doc.recipients.find((p) => p.email === this.currentUser.email);
    if (!recipient) {
      return false;
    }

    let previous = doc.recipients.find((p) => p.signing_order === recipient.signing_order - 1);

    return (!previous || previous.has_completed === true) && recipient.has_completed === false;
  }

  openPandaDocDocumentById(documentId) {
    this.$company.rcdssGetLinkToContractDocument(this.organizationId, this.purchaseOrderId, documentId).subscribe(
      (res) => {
        window.open('https://app.pandadoc.com/s/'.concat(res.id), '_blank').focus();
        this.updateFilesIfFileTab();
      },
      (error) => {
        console.error(error);
        this.$toaster.show('error', 'An error occurred', 'Please try again later');
      },
    );
  }

  async downloadSelectedFilesAsSinglePDF() {
    if (!this.isMoreThanOneApprovedFileSelected()) {
      return;
    }
    let selectedFileIds = this.getSelectedFiles().map((f) => f.file_id);
    const { value: fileName } = await Swal.fire({
      title: 'Enter a name for the file',
      input: 'text',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'File name cannot be empty';
        }
      },
    });
    this.loading = true;
    this.$company
      .convertAndMergeAsSinglePDF(this.purchaseOrderId, { file_ids: selectedFileIds, file_name: fileName })
      .subscribe(
        async (res) => {
          this.$toaster.show('success', 'Success', 'Your file is ready to be downloaded');
          this.resetSelectedFiles();
          this.updateFilesIfFileTab();
          this.loading = false;
        },
        (error) => {
          console.error(error);
          this.$toaster.show('error', 'An error occurred', 'Please try again later');
          this.loading = false;
        },
      );
  }

  async updatePeriod(file) {
    let newPeriodTag = await this.openPeriodModal();
    if (!newPeriodTag) {
      return;
    }
    let currentPeriodTag = '$'.concat(this.getFilePeriod(file));
    this.$company
      .replaceFileTag(this.purchaseOrderId, file.file_id, newPeriodTag as string, currentPeriodTag)
      .subscribe(
        (res) => {
          this.updateFilesIfFileTab();
          this.$toaster.show('success', 'Success', 'Time period was updated successfully');
        },
        (error) => {
          console.error(error);
          this.$toaster.show('error', 'An error occurred', 'Please try again later');
        },
      );
  }

  updateComment(file) {
    this.purchaseOrderfileService.purchaseOrderFilesUpdate({fileID: file.file_id, payload: file, organizationID: this.organizationId, purchaseOrderID: this.purchaseOrderId})
    .subscribe(() => {
      this.$toaster.show('success', 'Success', 'Comment was updated successfully');
    })
  }

  async openPeriodModal() {
    let options = this.periodTags;
    const { value: period } = await Swal.fire({
      title: 'Select a time period for this document',
      input: 'select',
      inputOptions: {
        Monthly: {
          $january: 'January',
          $february: 'February',
          $march: 'March',
          $april: 'April',
          $may: 'May',
          $june: 'June',
          $july: 'July',
          $august: 'August',
          $september: 'September',
          $october: 'October',
          $november: 'November',
          $december: 'December',
        },
        Quarterly: {
          $q1: 'First Quarter',
          $q2: 'Second Quarter',
          $q3: 'Third Quarter',
          $q4: 'Fourth Quarter',
        },
      },
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary',
      },
      inputPlaceholder: 'Select a time period',
      showCancelButton: true,
    });
    return period;
  }

  checkInsuranceDate(date) {
    if (!date) {
      return false;
    }
    return new Date(date) < new Date();
  }

  onFileURLClick(url) {
    if (url) {
      window.open(url, "_blank");
    }
  }

  /*setForAppliance($event, resource) {
    let resourceToTasks: ResourcesToTasks
    resourceToTasks = this.resourcesToApply.find(r => r.doc_id === resource.id);

    if(!$event.checked) {
      if (resourceToTasks) {
        this.resourcesToApply = this.resourcesToApply.filter(r => {
          return r.doc_id !== resourceToTasks.doc_id;
        });
      }
      return;
    }

    if (!resourceToTasks) {
      resourceToTasks = {
        doc_id: resource.id,
        supplier_id: resource.supplier_id,
        supplier_name: resource.supplier_name,
        doc_number: resource.customer_po_number,
        rcdss_contract_program: resource?.rcdss_contract_program,
        status: resource.status,
        tasks_pending_responsible: [],
      };
    }

    this.tasksPendingResponsible.forEach(t => {
      resourceToTasks.tasks_pending_responsible.push({doc_id: resource.id, task_id: t.id, task_name: t.name});
    });

    this.resourcesToApply.push(resourceToTasks);
  }*/
}
