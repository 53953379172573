/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroCustom_formCollection } from '../models/lasgro-custom-_form-collection';
import { LasgroGeneral_success_id_payload } from '../models/lasgro-general-_success-_id-_payload';
import { SubmitCustomFormInputPayload } from '../models/submit-custom-form-input-payload';
import { LasgroSubmitted_formCollection } from '../models/lasgro-submitted-_form-collection';
@Injectable({
  providedIn: 'root',
})
class CustomFormsService extends __BaseService {
  static readonly customFormsListPath = '/organizations/{organizationID}/custom-forms';
  static readonly customFormsSubmitFormPath = '/organizations/{organizationID}/custom-forms/submit';
  static readonly customFormsSubmittedFormsListPath = '/organizations/{organizationID}/resources/{resourceID}/custom-forms/submitted';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list custom-forms
   *
   * fetches a list of custom-forms by organization id
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  customFormsListResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroCustom_formCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/custom-forms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCustom_formCollection>;
      })
    );
  }
  /**
   * list custom-forms
   *
   * fetches a list of custom-forms by organization id
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  customFormsList(organizationID: string): __Observable<LasgroCustom_formCollection> {
    return this.customFormsListResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroCustom_formCollection)
    );
  }

  /**
   * submit_form custom-forms
   *
   * submits a custom form
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomFormsService.CustomFormsSubmitFormParams` containing the following parameters:
   *
   * - `payload`: input payload for submitting a custom form
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customFormsSubmitFormResponse(params: CustomFormsService.CustomFormsSubmitFormParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/custom-forms/submit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * submit_form custom-forms
   *
   * submits a custom form
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomFormsService.CustomFormsSubmitFormParams` containing the following parameters:
   *
   * - `payload`: input payload for submitting a custom form
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customFormsSubmitForm(params: CustomFormsService.CustomFormsSubmitFormParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.customFormsSubmitFormResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * submitted_forms_list custom-forms
   *
   * fetches a list of submitted forms by organization and resource id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomFormsService.CustomFormsSubmittedFormsListParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customFormsSubmittedFormsListResponse(params: CustomFormsService.CustomFormsSubmittedFormsListParams): __Observable<__StrictHttpResponse<LasgroSubmitted_formCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/custom-forms/submitted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSubmitted_formCollection>;
      })
    );
  }
  /**
   * submitted_forms_list custom-forms
   *
   * fetches a list of submitted forms by organization and resource id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CustomFormsService.CustomFormsSubmittedFormsListParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  customFormsSubmittedFormsList(params: CustomFormsService.CustomFormsSubmittedFormsListParams): __Observable<LasgroSubmitted_formCollection> {
    return this.customFormsSubmittedFormsListResponse(params).pipe(
      __map(_r => _r.body as LasgroSubmitted_formCollection)
    );
  }
}

module CustomFormsService {

  /**
   * Parameters for customFormsSubmitForm
   */
  export interface CustomFormsSubmitFormParams {

    /**
     * input payload for submitting a custom form
     */
    payload: SubmitCustomFormInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for customFormsSubmittedFormsList
   */
  export interface CustomFormsSubmittedFormsListParams {
    resourceID: string;
    organizationID: string;
  }
}

export { CustomFormsService }
