<ngx-loading [show]="loadingFiles"></ngx-loading>
<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between">
    <h2 mat-dialog-title>Files</h2>
    <div class="close-modal">
      <button
        type="button"
        class="close"
        aria-label="close"
        mat-dialog-close
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <mat-dialog-content class="h-100">
    <div class="d-flex justify-content-center">
      <label for="fileToUpload" class="clickable">
        <i class="fa fa-paperclip"></i>
        Add files
        <input type="file" id="fileToUpload" class="d-none" (change)="addFiles($event)" multiple/>
      </label>
    </div>

    <div class="modal-body-files">
      <ul>
        <li class="row files-block" *ngFor="let file of files; let $index = index">
          <div class="col-md-1">
            <div class="icon-file">
              <i class="fa fa-paperclip"></i>
            </div>
          </div>

          <div class="col-md-10">
            <div class="name-fl">{{ file.name }}</div>
            <div>
              <span>{{ file.size | fileSize }}</span>
            </div>
          </div>
          <div class="col-md-1">
            <a
              (click)="removeFile($index)"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
        </li>
      </ul>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="matDialogRef.close(this.files)">
      Done
    </button>
  </mat-dialog-actions>

</div>
