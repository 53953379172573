import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-help-videos-modal',
  templateUrl: './help-videos-modal.component.html',
  styleUrls: ['./help-videos-modal.component.scss'],
})

export class HelpVideosModalComponent {

  videosData = [
    {
      name: 'Accepting / Rejecting a Purchase Order Via Email',
      url: 'https://www.loom.com/share/52741321da2142cdb8a8a05ce8692b12',
    },
    {
      name: 'Accepting / Rejecting a Purchase Order - Purchase Orders Received Screen',
      url: 'https://www.loom.com/share/bfa7228d025b44dabb0d6bb80e88d6a0',
    },
    {
      name: 'Accepting an Invoice',
      url: 'https://www.loom.com/share/ffba7e476b194d0392da585ee7adcb5e',
    },
    {
      name: 'Adding a new supplier while creating a Purchase Order',
      url: 'https://www.loom.com/share/fce9c456a33f463bbb80eeab20113b99',
    },
    {
      name: 'Adding a new supplier who is already in the system',
      url: 'https://www.loom.com/share/133f3ca8cc0e4b1caea3fa8147e2341e',
    },
    {
      name: 'Adding a new user to a company',
      url: 'https://www.loom.com/share/382e987dbd764a9cacadc115f51d68d3',
    },
    {
      name: 'Adding a tracking record',
      url: 'https://www.loom.com/share/59ce2fc4561c42fea3f5b49d0406c3d6',
    },
    {
      name: 'Adding information to an existing Customer-defined product',
      url: 'https://www.loom.com/share/a67e200a9f354e01ab06c67e50e2d363',
    },
    {
      name: 'Adding Subscribers',
      url: 'https://www.loom.com/share/d4b18da1146343669941a1d3793882df',
    },
    {
      name: 'Approving a ProForma Invoice',
      url: 'https://www.loom.com/share/fd04b06b3bb14dc1ad068fe02ddb7b32',
    },
    {
      name: 'Create New User',
      url: 'https://www.loom.com/share/20f6d1d6c1c24a3f900621b2705ca58a',
    },
    {
      name: 'Creating a Purchase Order - #1: Purchase Order Summary',
      url: 'https://www.loom.com/share/b9be6500f710478794329ae43bbbb554',
    },
    {
      name: 'Creating a Purchase Order - #2: Adding Purchase Order File',
      url: 'https://www.loom.com/share/cae2f500e46845868f10630fc68d18c4',
    },
    {
      name: 'Creating a Purchase Order - #3: Subscribers',
      url: 'https://www.loom.com/share/f75621483b1545369e0b17380cd57ba8',
    },
    {
      name: 'Creating a Purchase Order - #4: Purchase Order Task list',
      url: 'https://www.loom.com/share/a79c2bac24d74ab0bdf17e4add8dac92',
    },
    {
      name: 'Creating a Purchase Order - #5: Review and Send',
      url: 'https://www.loom.com/share/5861a428469344d19b8c611293810f80',
    },
    {
      name: 'Customer add a new product - Product Screen',
      url: 'https://www.loom.com/share/58040f03094d4b1182a9953e937dd1bc',
    },
    {
      name: 'Customer View of Shipping Record',
      url: 'https://www.loom.com/share/acb73a885dac4cdfb752dadcf918f7cc',
    },
    {
      name: 'Customers add a new product - Add new Purchase Order screen',
      url: 'https://www.loom.com/share/7bf9010035f24fd79ada1207d26bfa75',
    },
    {
      name: 'Deleting Subscribers to a Purchase Order',
      url: 'https://www.loom.com/share/afd87c1aad72427dbed7fecb5bf37580',
    },
    {
      name: 'Editing Supplier Data',
      url: 'https://www.loom.com/share/141b7bf25ba74eeca280c79a9007a787',
    },
    {
      name: 'Inviting a new supplier to join - supplier screen',
      url: 'https://www.loom.com/share/9aaca32586284d8c946018eba297e666',
    },
    {
      name: 'Making sure you receive ION Supply Emails',
      url: 'https://www.loom.com/share/210efc96b8b0477ebd454f39b935f585',
    },
    {
      name: 'New Supplier - New Purchase Order Screen',
      url: 'https://www.loom.com/share/4a21377a54514acdaca6e56cd878caed',
    },
    {
      name: 'New Supplier - Supplier Screen',
      url: 'https://www.loom.com/share/d6c2cf8a2150449ea1843eec54f51534',
    },
    {
      name: 'Rejecting a ProForma Invoice',
      url: 'https://www.loom.com/share/a03d834948f04a388972ad23f06af20d',
    },
    {
      name: 'Rejecting an Invoice',
      url: 'https://www.loom.com/share/c710c442fe2f400cbacdf1e76d979074',
    },
    {
      name: 'Reporting FGI - Supplier',
      url: 'https://www.loom.com/share/98e664a5dd894898b966a73115aaa26f',
    },
    {
      name: 'Reset User Password',
      url: 'https://www.loom.com/share/c368be1f6b21488aa01eb5f40728be35',
    },
    {
      name: 'Reviewing a Purchase Order - Customer View',
      url: 'https://www.loom.com/share/70ffed351dcb47a7a4ac3460d676326b',
    },
    {
      name: 'Single Tracking Code For Multiple Suppliers + Export to Excel',
      url: 'https://www.loom.com/share/5624270791804f2f89429b9948da126a',
    },
    {
      name: 'Supplier Uses Same Container Code on 2 different Purchase Orders',
      url: 'https://www.loom.com/share/445546ea810e4c4ea2e6d93834a6021e',
    },
    {
      name: 'Supplier View and Approve a Purchase Order',
      url: 'https://www.loom.com/share/a586a581bf9742c5bb25a7dfd9f278de',
    },
    {
      name: 'Suppliers add products to sell - Product Screen',
      url: 'https://www.loom.com/share/eb507967d48d450e9e84ef793fb709dd',
    },
    {
      name: 'Uploading a ProForma Invoice',
      url: 'https://www.loom.com/share/1c72842dd55a46998b19b87615bb0196',
    },
    {
      name: 'Uploading an Invoice',
      url: 'https://www.loom.com/share/6f7438216acc4afba532c2467085a852',
    },
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private $modal: NgbModal,
  ) {
  }

  closeModal() {
    this.activeModal.close('close');
  }

  openVideo($event) {
    window.open($event.data.url, '_blank');
  }

}


