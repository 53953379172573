import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return this.getFileSize(value);
  }

  getFileSize(fileSize: number) {
    const sizesFormatted = ['bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(fileSize) / Math.log(1000));

    if (fileSize === 0) return '0 bytes';
    return `${ parseFloat((fileSize / Math.pow(1000, i)).toFixed(1)) } ${ sizesFormatted[i] }`;
  }
}
