
export class BaseComponent {
  snapshotData: any = {};
  viewType: string = '';
  loading: boolean = false;
  isEDI = false;
  allowEdit = true;
  constructor() {
  }
}
