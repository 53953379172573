<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<input type="file" class="d-none" #fileInput (change)="addFile($event)" />

<div class="container-fluid" *ngIf="!hideAddButton">
  <div class="row mt-4" *ngIf="enableControls && (isCustomer || false)">
    <div class="col text-right">
      <button class="btn btn-dark" type="button" (click)="fileInput.click()">
        <i class="fa fa-plus"></i>
        Add Files
      </button>
    </div>
  </div>
</div>

<div class="no-file-label" *ngIf="!getFilteredFiles() && !loading">
  {{ filter ? 'No files were found for the selected time period.' : 'There are currently no files.' }}
</div>

<div class="container-fluid">
  <div class="row" *ngIf="defaultTag === '$submitted_voucher_documents'">
    <div class="col-md-4 offset-md-8">
      <mat-form-field>
        <mat-select placeholder="Filter by time period" (selectionChange)="changeFilter($event)">
          <mat-option [value]="null">None</mat-option>
          <mat-option [value]="null" [disabled]="true"><strong>Monthly</strong></mat-option>
          <mat-option *ngFor="let p of tagsToMonthlyPeriods | keyvalue : originalOrder" [value]="p.key">
            {{ p.value }}
          </mat-option>
          <mat-option [value]="null" [disabled]="true"><strong>Quarterly</strong></mat-option>
          <mat-option *ngFor="let p of tagsToQuarterlyPeriods | keyvalue : originalOrder" [value]="p.key">
            {{ p.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!isApprovalSection">
    <ng-container *ngFor="let file of files; let $index = index">
      <div
        class="row mt-3"
        *ngIf="
          isSimplePoView
            ? !file.label || file.label.includes('Product') || file.label.includes('Purchase Order')
            : !defaultTag || !file.label
        "
      >
        <div class="col w-100">
          <div class="file-container" [class.border-success]="file.is_main_file && file.uuid_type === 'PURCHASE_ORDER'">
            <div class="">
              <span [class.text-success]="file.is_main_file" class="file-icon d-flex justify-content-between">
                <i class="fa fa-file-o"></i>
                <span
                  class="file label"
                  [ngClass]="{ 'main-file': file.uuid_type === 'PURCHASE_ORDER' && file.is_main_file }"
                  *ngIf="file.label && false"
                >
                  'Contract File'
                </span>
              </span>
            </div>

            <div class="text-center content">
              <a
                *ngIf="file.uuid_type === 'PRODUCT_URL'"
                class="text-primary filename"
                target="_blank"
                href="{{ file.file_name }}"
                >{{ file.file_name }}</a
              >
              <a
                *ngIf="file.uuid_type !== 'PRODUCT_URL'"
                class="text-primary filename"
                ngxFilesPreview
                [fileId]="file.file_id"
                >{{ file.file_name }}</a
              >

              <div class="text-center pt-2 details">
                <small *ngIf="file.file_size > 0">{{ file.file_size | fileSize }}</small>
                <span>
                  {{ file.created_at | date : 'mediumDate' : 'utc' }} by
                  <span class="author" matTooltip="{{ file.created_by_name }}">{{ file.created_by_name }}</span>
                </span>
                <a
                  *ngIf="file.uuid_type === 'PURCHASE_ORDER' && isCustomer && !file.is_main_file && false"
                  (click)="setAsMainFile(file)"
                  class="set-main"
                >
                  Mark as Contract File
                </a>
              </div>
            </div>

            <div class="">
              <a
                matTooltip="Delete File"
                *ngIf="
                  (isCustomer || currentUser.id === file.created_by) &&
                  !file.is_main_file &&
                  enableControls &&
                  !['INVOICE', 'PRODUCT', 'PRODUCT_URL'].includes(file.uuid_type)
                "
                class="remove-file pt-2"
                (click)="removeFile(file)"
              >
                <!-- <i class="fa fa-trash"></i> -->
                <i class="table-icon trash"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="file-info-container mt-2" *ngIf="isApprovalSection">
    <div
      class="selected-files-actions d-flex flex-row-reverse"
      *ngIf="companyType === 'customer' && defaultTag === '$submitted_voucher_documents'"
    >
      <button
        type="button"
        style="width: fit-content; transition: 0.5s all"
        class="btn btn-primary"
        (click)="downloadSelectedFilesAsSinglePDF()"
        [matTooltip]="
          'Click here to convert all selected files into PDFs and merge them into a single file. Only approved files can be merged.'
        "
        [ngClass]="{
          hide: !isMoreThanOneApprovedFileSelected()
        }"
      >
        Download selected files as single PDF
      </button>
    </div>
    <div class="file-wrapper" *ngFor="let file of getFilteredFiles(); let $index = index">
      <div
        class="row my-2 border bg-light p-2 justify-content-between"
        [ngClass]="{
          'd-none': file?.is_rcdss_voucher === true && !getPandaDocStatus(file)
        }"
      >
        <div class="col-md-1" *ngIf="companyType === 'customer' && defaultTag === '$submitted_voucher_documents'">
          <mat-checkbox
            [disabled]="
              file.status !== 'approved' || (file?.is_rcdss_voucher === true && getPandaDocStatus(file) !== 'Completed')
            "
            [(ngModel)]="file.checked"
            class="pull-left mr-2 mt-1 ml-4"
            [ngClass]="{
              'd-none': file?.is_rcdss_voucher === true && !getPandaDocStatus(file)
            }"
          ></mat-checkbox>
        </div>

        <div class="col-md-4 d-flex flex-column">
          <div class="mb-1" style="overflow: hidden; text-overflow: ellipsis; width: 100% !important">
            <span *ngIf="isApproveLogicEnabled"
              [ngClass]="{
                'text-danger': file.status === 'rejected',
                'text-success': file.status === 'approved',
                'text-secondary': file.status === 'closed',
                'text-warning': file.status === 'pending'
              }"
              class="my-1 font-weight-bold"
            >
              <span
                *ngIf="defaultTag === '$submitted_voucher_documents'"
                class="badge badge-secondary period clickable mr-2"
                (click)="updatePeriod(file)"
              >
                {{ getFilePeriod(file) || 'Period N/A' | uppercase }}
                <i class="fa fa-edit ml-2"></i>
              </span>
              <a ngxFilesPreview [fileId]="file.file_id">
                <ng-container *ngIf="file?.is_rcdss_voucher">
                  {{ file?.file_name }} - {{ getPandaDocStatus(file) }}
                  <a
                    class="text-secondary ml-2 font-weight-bold"
                    (click)="openPandaDocDocumentById(file?.panda_doc_document?.external_id)"
                    *ngIf="getPandaDocDocumentCta(file?.panda_doc_document)"
                  >
                    {{ getPandaDocDocumentCta(file?.panda_doc_document) }}</a
                  >
                </ng-container>
                <ng-container *ngIf="!file?.is_rcdss_voucher">
                  <span ngxFilesPreview [file_url]="file.file_url">{{ file?.file_name }}</span>
                  <span *ngIf="isInsuranceLogicEnabled && file.type.value"> ({{ file.type.value }})</span>
                  <span *ngIf="file.status !== 'closed'">- {{ file.status | titlecase }}</span>
                </ng-container>
              </a>
            </span>
            <span *ngIf="!isApproveLogicEnabled" class="my-1 font-weight-bold">
              <a ngxFilesPreview [fileId]="file.file_id">
                {{ file?.file_name }}
                <span *ngIf="isInsuranceLogicEnabled && file.type.value"> ({{ file.type.value }})</span>
              </a>
            </span>
          </div>
          <div class="file-additional-info-container">
            <span class="d-block" *ngIf="file?.status === 'rejected' && file.rejection_reason">
              Rejection reason: {{ file.rejection_reason }}
            </span>
            <small> by {{ file.created_by_name }} at {{ file.created_at | date : 'MM/dd/yyyy HH:mm' : 'utc' }} </small>
            <ng-container *ngIf="isInsuranceLogicEnabled">
              <span class="d-block" *ngIf="file.insurance_date">
                expired date: {{ file.insurance_date | date : 'MM/dd/yyyy' : 'utc' }}
                <mat-icon
                  class="text-warning warning-insurance"
                  *ngIf="checkInsuranceDate(file.insurance_date)"
                  matTooltip="Date was expired update document please!"
                  >warning</mat-icon
                >
              </span>
              <span class="d-block" *ngIf="file.expected_date">
                Expired date: {{ file.expected_date | date : 'MM/dd/yyyy' : 'utc' }}
              </span>
            </ng-container>
          </div>
        </div>
        <div class="col-md-5 d-flex flex-column" *ngIf="isCommentVisible && companyType === 'customer' && currentUser.role != 'User'">
          <mat-form-field>
            <textarea
              matInput
              placeholder="Comments"
              [(ngModel)]="file.comment"
              (change)="updateComment(file)"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="col-md-5 d-flex flex-column" *ngIf="isCommentVisible && companyType === 'supplier'">
          {{ file.comment }}
        </div>
        <div class="col-md-2 d-flex align-items-start pt-1" *ngIf="currentUser.role != 'User'">
          <div class="d-flex" *ngIf="!['approved', 'rejected'].includes(file?.status) && isFileDeletionAvaliable">
            <a
              class="ml-2 text-danger"
              style="text-decoration: none"
              matTooltip="Delete File"
              (click)="removeFile(file)"
            >
              Delete
            </a>
          </div>

          <div
            class="bd-highlight ml-2"
            *ngIf="
              file?.status === 'pending' &&
              companyType == 'customer' &&
              !file?.is_rcdss_voucher &&
              isApproveLogicEnabled
            "
          >
            <button class="btn btn-link text-success" type="button" (click)="acceptOrRejectFile(file, true)">
              Approve
            </button>
            <button class="btn btn-link text-danger" type="button" (click)="acceptOrRejectFile(file, false)">
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
