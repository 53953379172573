import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { LasgroFile_type_group } from 'api/models';
import { FileTypeGroupsService } from 'api/services';

@Component({
  selector: 'add-insurance-date-dialog',
  templateUrl: './add-insurance-date.dialog.html',
  styleUrls: ['./add-insurance-date.dialog.scss']
})
export class AddInsuranceDateDialog implements OnInit {
  form: UntypedFormGroup;
  types = []
  constructor(private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    this.form = this.fb.group({
      insuranceDate: [null],
      type: ['', Validators.required],
      name: [this.dialogData.fileName, Validators.required]
    })
    this.types = dialogData.types;
  }

  onSelectType(event: MatSelectChange) {
    const insuranceDateControl = this.form.get('insuranceDate');

    // Clear previous validation
    insuranceDateControl.clearValidators();
    insuranceDateControl.updateValueAndValidity();

    // Set new validation if 'type' is selected
    if (event.value.required) {
      insuranceDateControl.setValidators([Validators.required]);
    }

    insuranceDateControl.updateValueAndValidity();
  }

  ngOnInit(): void {
    
  }

  getData() {
    return this.form.getRawValue();
  }
}
