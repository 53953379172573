export class AppConstants {
     static InsuranceMailType = 'insurance'
     static TaskMailType = 'task'

     parseJson(value: string) {
          if (value) {
               return JSON.parse(value)
          }
          return null
     }
}