import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientCompany } from '@models/client-company.model';
import { Observable } from 'rxjs';
import CONFIG from '@app/app.config';

@Injectable()
export class ClientCompanyService {
  constructor(private httpClient: HttpClient) {}

  getAllClientCompanies(): Observable<ClientCompany[]> {
      const url = `${ CONFIG.API }/company_clients`;
      return this.httpClient.get<ClientCompany[]>(url);
  }
}
