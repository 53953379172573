import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigOption, NGX_USERSNAP_CONFIG } from './services/config';
import { NgxUserSnapAsyncCallbackApiLoader, UserSnapService } from './services/user-snap-service';


@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [],
})
export class NgxUserSnapModule {
  static forRoot(config: ConfigOption = {}): ModuleWithProviders<NgxUserSnapModule> {
    return {
      ngModule: NgxUserSnapModule,
      providers: [
        {
          provide: NGX_USERSNAP_CONFIG,
          useValue: config,
        },
        {
          provide: UserSnapService,
          useClass: NgxUserSnapAsyncCallbackApiLoader,
        },
      ],
    };
  }
}
