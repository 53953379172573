import { NgModule } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationsDashboardComponent } from '@app/modules/dashboard/components/notifications-dashboard/notifications-dashboard.component';
import { TodoDashboardCardComponent } from '@app/modules/dashboard/components/todo-dashboard-card/todo-dashboard-card.component';
import { PoNotesFormComponent } from '@app/shared/components';
import { SharedModule } from '@app/shared/shared.module';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompanyService } from '@services/company.service';
import { ThemeModule } from '@theme/theme.module';
import { DxButtonModule, DxListModule, DxTileViewModule } from 'devextreme-angular';
import { NgxLoadingModule } from 'ngx-loading';
import { MomentModule } from 'ngx-moment';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AchievementsComponent } from './components/achivements/achievements.component';
import { DashboardCarouselComponent } from './components/dashboard-carousel/dashboard-carousel.component';
import { DashboardMentionsComponent } from './components/dashboard-mentions/dashboard-mentions.component';
import { NoteItemComponent } from './components/note-item/note-item.component';
import { NotificationActionsComponent } from './components/notification-actions/notification-actions.component';
import { TrainingDashboardComponent } from './components/training-dashboard/training-dashboard.component';
import { WhiteGloveDashboardComponent } from './components/white-glove-dashboard/white-glove-dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
    imports: [
        DashboardRoutingModule,
        ThemeModule,
        MatSlideToggleModule,
        NgSelectModule,
        NgOptionHighlightModule,
        SharedModule,
        MomentModule,
        MatDividerModule,
        MatTooltipModule,
        MatTabsModule,
        PerfectScrollbarModule,
        NgxLoadingModule,
        MatDialogModule,
        DxTileViewModule,
        DxButtonModule,
        DxListModule,
        PoNotesFormComponent
    ],
    providers: [
        CompanyService,
        {
            provide: MatDialogRef,
            useValue: {},
        },
    ],
    declarations: [
        DashboardComponent,
        NotificationsDashboardComponent,
        NotificationActionsComponent,
        NoteItemComponent,
        WhiteGloveDashboardComponent,
        DashboardMentionsComponent,
        AchievementsComponent,
        TrainingDashboardComponent,
        DashboardCarouselComponent,
        TodoDashboardCardComponent,
    ],
    exports: []
})
export class DashboardModule {
}
