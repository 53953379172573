<div class="d-flex my-3 align-items-center title-container">
  <div *ngIf="title" class="title">
    <span [ngClass]="{'text-strong': bold, 'text-small': small}">
    	{{title}}
    </span>
    <span [ngClass]="{'text-strong': bold, 'text-small': small}" *ngIf="subTitle">
    	{{subTitle}}
	</span>
    <ng-content select=".badge"></ng-content>
  </div>
  <div class="d-block title-line">
    <mat-divider></mat-divider>
  </div>
</div>
