<div class="file-container">
  <mat-progress-spinner *ngIf="!downloaded" class="spinner" mode="indeterminate"></mat-progress-spinner>
  <mat-icon
    *ngIf="isEditable"
    (click)="removeFile()"
    style="position: absolute; top: 0px; right: 5px; color: red; font-size: 18px; cursor: pointer"
  >
    cancel
  </mat-icon>
  <mat-icon [ngStyle]="{ color: color }" class="file-icon" (click)="downloadFile()">insert_drive_file</mat-icon>
  <span class="file-name" [matTooltip]="fileName">{{ fileName }}</span>
</div>
