import { Component, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldComponent),
      multi: true
    }
  ]
})
export class FieldComponent implements ControlValueAccessor  {
  @Input() name: string;
  @Input() inputType: string;
  @Input() placeholder: string;
  @Input() options: string[] = [];
  value: any;
  @Output() onChange: any = () => {};
  @Output() onTouch: any = () => {};
  constructor() { }
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Optionally implement if your component can be disabled
  }

  // Method to propagate changes back to parent component
  updateValue(newValue: any) {
    this.value = newValue;
    this.onChange(this.value);
    this.onTouch();
  }

}
