/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroWorkflow_timeline } from '../models/lasgro-workflow-_timeline';
import { LasgroWorkflow_apply_success } from '../models/lasgro-workflow-_apply-_success';
import { WorkflowApplyInputPayload } from '../models/workflow-apply-input-payload';
import { LasgroWorkflowCollection } from '../models/lasgro-workflow-collection';
import { LasgroGeneral_success_id_payload } from '../models/lasgro-general-_success-_id-_payload';
import { WorkflowInputPayload } from '../models/workflow-input-payload';
import { LasgroWorkflowDetails } from '../models/lasgro-workflow-details';
import { WorkflowUpdateInputPayload } from '../models/workflow-update-input-payload';
@Injectable({
  providedIn: 'root',
})
class WorkflowService extends __BaseService {
  static readonly workflowShowTimelinePath = '/organizations/{organizationID}/resources/{resourceID}/workflows/timeline';
  static readonly workflowApplyPath = '/organizations/{organizationID}/resources/{resourceID}/workflows/{workflowID}/apply';
  static readonly workflowListByOrganizationPath = '/organizations/{organizationID}/workflows';
  static readonly workflowAddPath = '/organizations/{organizationID}/workflows';
  static readonly workflowShowPath = '/organizations/{organizationID}/workflows/{workflowID}';
  static readonly workflowUpdatePath = '/organizations/{organizationID}/workflows/{workflowID}';
  static readonly workflowListPath = '/workflows';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * show_timeline workflow
   *
   * Shows the workflow applied on a parent resource as a timeline
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowShowTimelineParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowShowTimelineResponse(params: WorkflowService.WorkflowShowTimelineParams): __Observable<__StrictHttpResponse<LasgroWorkflow_timeline>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/workflows/timeline`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroWorkflow_timeline>;
      })
    );
  }
  /**
   * show_timeline workflow
   *
   * Shows the workflow applied on a parent resource as a timeline
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowShowTimelineParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowShowTimeline(params: WorkflowService.WorkflowShowTimelineParams): __Observable<LasgroWorkflow_timeline> {
    return this.workflowShowTimelineResponse(params).pipe(
      __map(_r => _r.body as LasgroWorkflow_timeline)
    );
  }

  /**
   * apply workflow
   *
   * Applies a workflow definition on a parent resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowApplyParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `resourceID`:
   *
   * - `payload`: The request payload for applying a workflow
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowApplyResponse(params: WorkflowService.WorkflowApplyParams): __Observable<__StrictHttpResponse<LasgroWorkflow_apply_success>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/workflows/${encodeURIComponent(String(params.workflowID))}/apply`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroWorkflow_apply_success>;
      })
    );
  }
  /**
   * apply workflow
   *
   * Applies a workflow definition on a parent resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowApplyParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `resourceID`:
   *
   * - `payload`: The request payload for applying a workflow
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowApply(params: WorkflowService.WorkflowApplyParams): __Observable<LasgroWorkflow_apply_success> {
    return this.workflowApplyResponse(params).pipe(
      __map(_r => _r.body as LasgroWorkflow_apply_success)
    );
  }

  /**
   * list_by_organization workflow
   *
   * List all of the workflows for every organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  workflowListByOrganizationResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroWorkflowCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/workflows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroWorkflowCollection>;
      })
    );
  }
  /**
   * list_by_organization workflow
   *
   * List all of the workflows for every organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  workflowListByOrganization(organizationID: string): __Observable<LasgroWorkflowCollection> {
    return this.workflowListByOrganizationResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroWorkflowCollection)
    );
  }

  /**
   * add workflow
   *
   * Create a new workflow definition for a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowAddParams` containing the following parameters:
   *
   * - `payload`: Input for a workflow definition
   *
   * - `organizationID`:
   *
   * @return Created
   */
  workflowAddResponse(params: WorkflowService.WorkflowAddParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * add workflow
   *
   * Create a new workflow definition for a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowAddParams` containing the following parameters:
   *
   * - `payload`: Input for a workflow definition
   *
   * - `organizationID`:
   *
   * @return Created
   */
  workflowAdd(params: WorkflowService.WorkflowAddParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.workflowAddResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * show workflow
   *
   * Details of a workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowShowParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowShowResponse(params: WorkflowService.WorkflowShowParams): __Observable<__StrictHttpResponse<LasgroWorkflowDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroWorkflowDetails>;
      })
    );
  }
  /**
   * show workflow
   *
   * Details of a workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowShowParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowShow(params: WorkflowService.WorkflowShowParams): __Observable<LasgroWorkflowDetails> {
    return this.workflowShowResponse(params).pipe(
      __map(_r => _r.body as LasgroWorkflowDetails)
    );
  }

  /**
   * update workflow
   *
   * Updates an existing workflow definition for a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowUpdateParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `payload`: Input for updating an existing workflow definition
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowUpdateResponse(params: WorkflowService.WorkflowUpdateParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * update workflow
   *
   * Updates an existing workflow definition for a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowService.WorkflowUpdateParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `payload`: Input for updating an existing workflow definition
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowUpdate(params: WorkflowService.WorkflowUpdateParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.workflowUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * list workflow
   *
   * List all of the workflows
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  workflowListResponse(): __Observable<__StrictHttpResponse<LasgroWorkflowCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroWorkflowCollection>;
      })
    );
  }
  /**
   * list workflow
   *
   * List all of the workflows
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  workflowList(): __Observable<LasgroWorkflowCollection> {
    return this.workflowListResponse().pipe(
      __map(_r => _r.body as LasgroWorkflowCollection)
    );
  }
}

module WorkflowService {

  /**
   * Parameters for workflowShowTimeline
   */
  export interface WorkflowShowTimelineParams {
    resourceID: string;
    organizationID: string;
  }

  /**
   * Parameters for workflowApply
   */
  export interface WorkflowApplyParams {
    workflowID: string;
    resourceID: string;

    /**
     * The request payload for applying a workflow
     */
    payload: WorkflowApplyInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for workflowAdd
   */
  export interface WorkflowAddParams {

    /**
     * Input for a workflow definition
     */
    payload: WorkflowInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for workflowShow
   */
  export interface WorkflowShowParams {
    workflowID: string;
    organizationID: string;
  }

  /**
   * Parameters for workflowUpdate
   */
  export interface WorkflowUpdateParams {
    workflowID: string;

    /**
     * Input for updating an existing workflow definition
     */
    payload: WorkflowUpdateInputPayload;
    organizationID: string;
  }
}

export { WorkflowService }
