<div class="modal-header">
  <h6 class="text-right p-0 w-100">
    <!-- <span class="text-danger">DSS CONTRACT-2022</span> -->
  </h6>

  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <iframe [src]="iframeUrl" style="
    width: 100%;
    height: 600px;
    margin: auto;
  "></iframe>
</div>
