import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyDto, SupplierLeadDocument } from '@models/company.model';
import { FileItemDto } from '@models/file-item.model';
import { User } from '@models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CompanyService } from '@services/company.service';
import { FileService } from '@services/file.service';
import { Toaster } from '@services/toaster.service';
import { Observable } from 'rxjs';
import { SupplierTermsFormComponent } from '@app/customers/modals/supplier-terms-form/supplier-terms-form.component';
import { SupplierFileViewComponent } from '@shared/modals';
import * as moment from 'moment';

@Component({
  selector: 'ngx-supplier-file-item',
  templateUrl: './supplier-file-item.component.html',
  styleUrls: ['./supplier-file-item.component.scss'],
})
export class SupplierFileItemComponent implements OnInit {
  @Input() document: SupplierLeadDocument;
  @Input() supplier: CompanyDto;
  @Input() companyRole: string = 'supplier';
  @Input() enableControls: boolean = true;
  @Input() status: string;
  today = moment();
  user$: Observable<User>;

  @Output() filesChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private companyService: CompanyService,
              private fileService: FileService,
              private store: Store<{ user: User }>,
              private $modal: NgbModal,
              private $toaster: Toaster) {
  }

  ngOnInit() {
    this.user$ = this.store.select('user');
  }

  viewTermsFile() {
    const modalRef = this.$modal.open(SupplierFileViewComponent, {
      size: 'lg',
      container: 'nb-layout',
    });
    modalRef.componentInstance.document = this.document;
    modalRef.componentInstance.supplier = this.supplier;
  }

  setAsTermsFile(file) {
    this.companyService.updateSupplierFile(this.supplier.id, file.file_id, null, { isMainFile: true })
      .subscribe(() => {
        this.filesChanged.emit();
        this.$toaster.show('success', 'Success', 'Terms and condition File Updated.');
      }, (error) => {
        const errMsg = error.error && error.error.detail ? error.error.detail : 'An error occurred updating the file.';
        this.$toaster.show('error', 'Error', errMsg);
      });
  }

  updateDueDate($event, file) {

    let expiresAt = moment.utc($event.target.value).format();
    let fn = this.companyRole === 'supplier' ?
      this.companyService.updateSupplierFile.bind(this.companyService) :
      this.companyService.updateCustomerFile.bind(this.companyService);

    fn(this.supplier.id, file.file_id, null, { expiresAt: expiresAt })
      .subscribe(() => {
        this.filesChanged.emit();
        this.$toaster.show('success', 'Success', 'File due date updated.');
      }, (error) => {
        console.error(error);
        this.$toaster.show('error', 'An error occurred', 'Please try again later.');
      });
  }

  isDocumentExpired() {
    if (!this.document.expires_at) {
      return false;
    }
    return moment.utc().isAfter(moment.utc(this.document.expires_at));
  }

  showFile(file: FileItemDto) {
    this.fileService.downloadFile(file.file_id).subscribe((res) => {
      if (file.file_name.endsWith('.pdf')) {
        this.fileService.viewPdf(res.body, file.file_name);
      } else {
        this.fileService.downloadBlobFile(res.body, file.file_name);
      }
    }, (error) => {
      const errMsg =
        error.error && error.error.detail ? error.error.detail : 'An error occurred getting the file.';
      this.$toaster.show('error', 'File download', errMsg);
    });
  }

  editDocument() {
    const modalRef = this.$modal.open(SupplierTermsFormComponent, {
      size: 'lg',
      container: 'nb-layout',
    });

    modalRef.componentInstance.supplier = this.supplier;
    modalRef.componentInstance.document = this.document;
    modalRef.result.then(() => {
      this.filesChanged.emit();
    });
  }

  removeFile(file: FileItemDto) {
    let fn = this.companyRole === 'supplier' ?
      this.companyService.deleteSupplierFile.bind(this.companyService) :
      this.companyService.deleteCustomerFile.bind(this.companyService);

    fn(this.supplier.id, file.file_id).subscribe(() => {
      this.filesChanged.emit();
      this.$toaster.show('success', 'Success', 'File deleted.');
    }, (error) => {
      console.error(error);
      this.$toaster.show('error', 'An error occurred', 'Please try again later.');
    });
  }
}
