/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroCommissioned_liCollection } from '../models/lasgro-commissioned-_li-collection';
import { LasgroCommissioned_li } from '../models/lasgro-commissioned-_li';
import { LineItemInputPayload } from '../models/line-item-input-payload';
@Injectable({
  providedIn: 'root',
})
class CommissionedLineItemService extends __BaseService {
  static readonly commissionedLineItemList1Path = '/commissioned_pos/{purchaseOrderID}/commissioned_line_items';
  static readonly commissionedLineItemShow1Path = '/commissioned_pos/{purchaseOrderID}/commissioned_line_items/{lineItemID}';
  static readonly commissionedLineItemUpdate1Path = '/commissioned_pos/{purchaseOrderID}/commissioned_line_items/{lineItemID}';
  static readonly commissionedLineItemListPath = '/organizations/{organizationID}/commissioned_pos/{purchaseOrderID}/commissioned_line_items';
  static readonly commissionedLineItemShowPath = '/organizations/{organizationID}/commissioned_pos/{purchaseOrderID}/commissioned_line_items/{lineItemID}';
  static readonly commissionedLineItemUpdatePath = '/organizations/{organizationID}/commissioned_pos/{purchaseOrderID}/commissioned_line_items/{lineItemID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list commissioned_line_item
   *
   * Lists commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  commissionedLineItemList1Response(params: CommissionedLineItemService.CommissionedLineItemList1Params): __Observable<__StrictHttpResponse<LasgroCommissioned_liCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/commissioned_pos/${encodeURIComponent(String(params.purchaseOrderID))}/commissioned_line_items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_liCollection>;
      })
    );
  }
  /**
   * list commissioned_line_item
   *
   * Lists commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  commissionedLineItemList1(params: CommissionedLineItemService.CommissionedLineItemList1Params): __Observable<LasgroCommissioned_liCollection> {
    return this.commissionedLineItemList1Response(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_liCollection)
    );
  }

  /**
   * show commissioned_line_item
   *
   * Retrieve commisisioned purchase order with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `lineItemID`: Line Item ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  commissionedLineItemShow1Response(params: CommissionedLineItemService.CommissionedLineItemShow1Params): __Observable<__StrictHttpResponse<LasgroCommissioned_li>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/commissioned_pos/${encodeURIComponent(String(params.purchaseOrderID))}/commissioned_line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_li>;
      })
    );
  }
  /**
   * show commissioned_line_item
   *
   * Retrieve commisisioned purchase order with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `lineItemID`: Line Item ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  commissionedLineItemShow1(params: CommissionedLineItemService.CommissionedLineItemShow1Params): __Observable<LasgroCommissioned_li> {
    return this.commissionedLineItemShow1Response(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_li)
    );
  }

  /**
   * update commissioned_line_item
   *
   * Updates a commissioned purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `lineItemID`: Line Item ID
   *
   * - `organizationID`:
   */
  commissionedLineItemUpdate1Response(params: CommissionedLineItemService.CommissionedLineItemUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/commissioned_pos/${encodeURIComponent(String(params.purchaseOrderID))}/commissioned_line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update commissioned_line_item
   *
   * Updates a commissioned purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `lineItemID`: Line Item ID
   *
   * - `organizationID`:
   */
  commissionedLineItemUpdate1(params: CommissionedLineItemService.CommissionedLineItemUpdate1Params): __Observable<null> {
    return this.commissionedLineItemUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list commissioned_line_item
   *
   * Lists commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  commissionedLineItemListResponse(params: CommissionedLineItemService.CommissionedLineItemListParams): __Observable<__StrictHttpResponse<LasgroCommissioned_liCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/commissioned_pos/${encodeURIComponent(String(params.purchaseOrderID))}/commissioned_line_items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_liCollection>;
      })
    );
  }
  /**
   * list commissioned_line_item
   *
   * Lists commissioned purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  commissionedLineItemList(params: CommissionedLineItemService.CommissionedLineItemListParams): __Observable<LasgroCommissioned_liCollection> {
    return this.commissionedLineItemListResponse(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_liCollection)
    );
  }

  /**
   * show commissioned_line_item
   *
   * Retrieve commisisioned purchase order with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemShowParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`: Line Item ID
   *
   * @return OK
   */
  commissionedLineItemShowResponse(params: CommissionedLineItemService.CommissionedLineItemShowParams): __Observable<__StrictHttpResponse<LasgroCommissioned_li>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/commissioned_pos/${encodeURIComponent(String(params.purchaseOrderID))}/commissioned_line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCommissioned_li>;
      })
    );
  }
  /**
   * show commissioned_line_item
   *
   * Retrieve commisisioned purchase order with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemShowParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`: Line Item ID
   *
   * @return OK
   */
  commissionedLineItemShow(params: CommissionedLineItemService.CommissionedLineItemShowParams): __Observable<LasgroCommissioned_li> {
    return this.commissionedLineItemShowResponse(params).pipe(
      __map(_r => _r.body as LasgroCommissioned_li)
    );
  }

  /**
   * update commissioned_line_item
   *
   * Updates a commissioned purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemUpdateParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`: Line Item ID
   */
  commissionedLineItemUpdateResponse(params: CommissionedLineItemService.CommissionedLineItemUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/commissioned_pos/${encodeURIComponent(String(params.purchaseOrderID))}/commissioned_line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update commissioned_line_item
   *
   * Updates a commissioned purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `CommissionedLineItemService.CommissionedLineItemUpdateParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`: Line Item ID
   */
  commissionedLineItemUpdate(params: CommissionedLineItemService.CommissionedLineItemUpdateParams): __Observable<null> {
    return this.commissionedLineItemUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CommissionedLineItemService {

  /**
   * Parameters for commissionedLineItemList1
   */
  export interface CommissionedLineItemList1Params {
    purchaseOrderID: string;
    organizationID?: string;
  }

  /**
   * Parameters for commissionedLineItemShow1
   */
  export interface CommissionedLineItemShow1Params {
    purchaseOrderID: string;

    /**
     * Line Item ID
     */
    lineItemID: string;
    organizationID?: string;
  }

  /**
   * Parameters for commissionedLineItemUpdate1
   */
  export interface CommissionedLineItemUpdate1Params {
    purchaseOrderID: string;
    payload: LineItemInputPayload;

    /**
     * Line Item ID
     */
    lineItemID: string;
    organizationID?: string;
  }

  /**
   * Parameters for commissionedLineItemList
   */
  export interface CommissionedLineItemListParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for commissionedLineItemShow
   */
  export interface CommissionedLineItemShowParams {
    purchaseOrderID: string;
    organizationID: string;

    /**
     * Line Item ID
     */
    lineItemID: string;
  }

  /**
   * Parameters for commissionedLineItemUpdate
   */
  export interface CommissionedLineItemUpdateParams {
    purchaseOrderID: string;
    payload: LineItemInputPayload;
    organizationID: string;

    /**
     * Line Item ID
     */
    lineItemID: string;
  }
}

export { CommissionedLineItemService }
