export class UNICARGO_PORT {
	name: string;
	code: string;
	type: string;
}

export const TYPE_ORIGIN = 'Origin';
export const TYPE_DESTINATION = 'Destination';

export let CONTAINER_TYPES: string[] = [
	'20 GP',
	'40 GP',
	'40 HQ',
];

export let UNICARGO_PORTS: UNICARGO_PORT[] = [
    {
        name: 'Ningbo',
        code: 'CNNGB',
        type: 'Origin'
    },
    {
        name: 'Shanghai',
        code: 'CNSHA',
        type: 'Origin'
    },
    {
        name: 'Shenzhen',
        code: 'CNSZX',
        type: 'Origin'
    },
    {
        name: 'Los Angeles',
        code: 'USLAX',
        type: 'Destination'
    },
    {
        name: 'Oakland',
        code: 'USOAK',
        type: 'Destination'
    },
    {
        name: 'New York',
        code: 'USNYC',
        type: 'Destination'
    },
    {
        name: 'Seattle',
        code: 'USSEA',
        type: 'Destination'
    },
    {
        name: 'Houston',
        code: 'USHOU',
        type: 'Destination'
    }
];

