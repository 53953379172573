<form [formGroup]="form" *ngIf="insuranceEmail">
  <div class="d-flex mt-3">
    <div class="col-6 p-0">
      <div class="p-2"><b>Subject</b></div>
      <mat-form-field style="height: fit-content;">
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject" />
      </mat-form-field>
    </div>
    <div class="col-6 p-0 ml-3">
      <div class="p-2"><b>Time Period</b> <br> when the message will be sent (for example write in the email, "in 14 days before, in 7 days, in 2 days etc … your task will expire)</div>
      <mat-form-field>
        <mat-chip-list #chipList aria-label="Time Period">
          <mat-chip *ngFor="let period of insuranceEmail.periods" (removed)="remove(period)">
            {{ period }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            type="number"
            placeholder="New periods..."
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>
  <div class="mt-3">
    <dx-html-editor
      #htmlEditorInstance
      height="500px"
      [toolbar]="toolbar"
      formControlName="body"
      [placeholder]="'Enter body here...'"
    >
      <dxo-toolbar [items]="toolbarItems"></dxo-toolbar>
      <dxo-variables [dataSource]="variables" [escapeChar]="separators"> </dxo-variables>
    </dx-html-editor>
  </div>
  <button
    [disabled]="!this.form.valid"
    type="submit"
    class="pull-right m-3"
    mat-raised-button
    color="primary"
    (click)="onSaveClick()"
  >
    {{ insuranceEmail.id ? 'Save' : 'Create' }}
  </button>
</form>
