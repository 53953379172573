/*
*   Handle error on the http requests, redirect to login if unauthorized
*   @param {any} err
*   @param {function} reject
*/
export default function (err: any, reject: any) {
    console.log('Error handler...');

    console.log(err.error || err);

    if (err && err.status && err.status === 404) {
        return reject(err);
    }

    if (err.error.status === 401) {
        return;
    }

    return reject(err);
}
