import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '@app/app.config';
import { ImportPos } from '@models/import-pos.model';
import { Observable } from 'rxjs';

@Injectable()
export class QuickbooksService {

  constructor(private http: HttpClient) {
    this.http = http;
  }

  /**
   * Service to get the URL to redirect and auth
   * @param id --> Company Id
   */
  getUrlAuth(id: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/qb_auth_link`;
    return this.http.get<any>(endpoint);
  }

  /**
   * Service to verify if the user is already auth.
   *
   */
  isAuth(id: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/qb_sync_info`;
    return this.http.get<any>(endpoint);
  }

  /**
   * Service to disconnect from QB
   * @param id --> Company Id
   */
  disconnect(id: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/qb_disconnect`;
    return this.http.get<any>(endpoint);
  }

  /**
   * Service to validate the tokens and
   * authenticate after redirection on /qb-callback
   * @param id --> Company id
   * @param data --> Data with tokens (code, state, realmId)
   */
  authenticate(id: string, data: any) {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/qb_auth_token`;
    return this.http.post<any>(endpoint, data);
  }

  /**
   * Service to start the importation from QB
   * @param id --> Company id
   */
  importPOSFromQB(id: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/qb_import_pos`;
    return this.http.get<any>(endpoint);
  }

  /**
   * Service to get PO import progress
   */
  getPOImportProgress(id: string): Observable<ImportPos> {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/qb_import_pos_progress`;
    return this.http.get<ImportPos>(endpoint);
  }

  /**
   * Service to get Previous imports
   */
  getPreviousImports(organizationId: string): Observable<ImportPos[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/qb_syncs`;
    return this.http.get<ImportPos[]>(endpoint);
  }
}
