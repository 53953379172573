import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';

import { ResetPasswordFields, User } from '../models';

import CONFIG from './../app.config';

import errorHandler from './../helpers/error-handler';

@Injectable()
export class AccountService {
  @LocalStorage() role: string;

  constructor(private http: HttpClient) {
    this.http = http;
  }

  login(user) {
    const endpoint = `${ CONFIG.API }/token`;
    return this.http.post(endpoint, user, { observe: 'response' });
  }

  refreshToken(refresh) {
    return this.http.post(`${ CONFIG.API }/refresh`, refresh, { observe: 'response' });
  }

  profile(): Promise<User> {
    return new Promise((resolve, reject) => {
      this.http.get(`${ CONFIG.API }/account/profile`).subscribe(resolve, err => errorHandler(err, reject));
    });
  }

  getProfile(): Observable<User> {
    return this.http.get<User>(`${ CONFIG.API }/account/profile`);
  }

  // request token to be able to reset you password
  requestToken(username: string): Promise<any> {
    return this.http.put(`${ CONFIG.API }/account/password/reset-request`, { username: username }).toPromise();
  }

  exchangeToken(organizationId: string) {
    const endpoint = `${ CONFIG.API }/exchange_admin_token`;
    return this.http.post(endpoint, { organization_id: organizationId }, { observe: 'response' }).toPromise();
  }

  updatePassword(data: ResetPasswordFields): Observable<any> {
    return this.http.put(`${ CONFIG.API }/account/password/reset-response`, data);
  }

  permissions() {
    return new Promise((resolve, reject) => this.http.get(`${ CONFIG.API }/permissions`).subscribe(resolve, reject));
  }

  checkPermissions(controller: string, verb: string) {
    const endpoint = `${ CONFIG.API }/permissions/show?controller=${ controller }&verb=${ verb }&role_id=${ this.role }`;
    this.http.get(endpoint);
  }

  /**
   * Service to validate if the user already set its password
   * @param email email that belongs to the user owner of the account
   */
  checkPasswordSetting(email: string): Observable<any> {
    const endpoint = `${ CONFIG.API }/account/changed-pass/${ email }`;
    return this.http.get(endpoint);
  }

  adminResetPassword(userName: string): Observable<any> {
    return this.http.put(`${ CONFIG.API }/account/password/reset-request`, { username: userName });
  }

  showPermission(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(`${ CONFIG.API }/permissions/${ id }`).subscribe(resolve, reject);
    });
  }

  countries() {
    // const endpoint = 'https://restcountries.eu/rest/v2/all'
    let endpoint = 'https://raw.githubusercontent.com/';
    endpoint += 'apilayer/restcountries/master/src/main/resources/countriesV1.json';

    return new Promise((resolve, reject) => {
      this.http.get(endpoint).subscribe(resolve, reject);
    });
  }

  reSendInvite(userId) {
    return this.http.put(`${ CONFIG.API }/users/${ userId }/invite`, {});
  }
}
