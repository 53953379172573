import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";

interface BroadcastEvent {
  key: any;
  data?: any;
}

export const EventTypes = {
  USER_SEARCH_FINISHED: 'USER_SEARCH_FINISHED',
  CUSTOMER_SEARCH_FINISHED: 'CUSTOMER_SEARCH_FINISHED',
  SUPPLIER_SEARCH_FINISHED: 'SUPPLIER_SEARCH_FINISHED',
  BROKER_SEARCH_FINISHED: 'BROKER_SEARCH_FINISHED',
  PRODUCT_SEARCH_FINISHED: 'PRODUCT_SEARCH_FINISHED',
  WORK_PROGRESS_SEARCH_FINISHED: 'WORK_PROGRESS_SEARCH_FINISHED',
  WORK_COMMISSIONED_SEARCH_FINISHED: 'WORK_COMMISSIONED_SEARCH_FINISHED',
  RESET_SEARCH: 'RESET_SEARCH',
  CLOSE_MODAL: 'CLOSE_MODAL',
  FILES_UPDATE: 'FILES_UPDATE',
  CHECK_LIST: 'CHECK_LIST',
};

@Injectable()
export class Broadcaster {
  private _eventBus: Subject<BroadcastEvent>;

  constructor() {
    this._eventBus = new Subject<BroadcastEvent>();
  }

  broadcast(key: any, data?: any) {
    this._eventBus.next({ key, data });
  }

  on<T>(key: any): Observable<T> {
    return this._eventBus
      .asObservable().pipe(
        filter(event => event.key === key),
        map(event => <T>event.data));
  }
}
