import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class NavigationHelper {
    public previousUrl = '';
    public currentUrl = '';
    constructor(private router: Router) {
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
    }

    goBack() {
        this.router.navigate([this.previousUrl]);
    }
}