/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroPurchase_order_comments } from '../models/lasgro-purchase-_order-_comments';
import { PurchaseOrderCommentUpdatePayload } from '../models/purchase-order-comment-update-payload';
import { PurchaseOrderCommentInputPayload } from '../models/purchase-order-comment-input-payload';
import { LasgroPurchase_order_commentsCollection } from '../models/lasgro-purchase-_order-_comments-collection';
@Injectable({
  providedIn: 'root',
})
class PurchaseOrderCommentsService extends __BaseService {
  static readonly purchaseOrderCommentsUpdatePath = '/purchase_order/purchase_order_comments';
  static readonly purchaseOrderCommentsAddPath = '/purchase_order/purchase_order_comments';
  static readonly purchaseOrderCommentsShowPath = '/purchase_order/purchase_order_comments/{purchaseOrderCommentID}';
  static readonly purchaseOrderCommentsDeletePath = '/purchase_order/purchase_order_comments/{purchaseOrderCommentID}';
  static readonly purchaseOrderCommentsListPath = '/purchase_order/{purchaseOrderID}/purchase_order_comments';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update purchase_order_comments
   *
   * Updates a purchase order comment
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  purchaseOrderCommentsUpdateResponse(payload: PurchaseOrderCommentUpdatePayload): __Observable<__StrictHttpResponse<LasgroPurchase_order_comments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_order/purchase_order_comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order_comments>;
      })
    );
  }
  /**
   * update purchase_order_comments
   *
   * Updates a purchase order comment
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  purchaseOrderCommentsUpdate(payload: PurchaseOrderCommentUpdatePayload): __Observable<LasgroPurchase_order_comments> {
    return this.purchaseOrderCommentsUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroPurchase_order_comments)
    );
  }

  /**
   * add purchase_order_comments
   *
   * Add a new purchase order comment
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  purchaseOrderCommentsAddResponse(payload: PurchaseOrderCommentInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/purchase_order_comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add purchase_order_comments
   *
   * Add a new purchase order comment
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  purchaseOrderCommentsAdd(payload: PurchaseOrderCommentInputPayload): __Observable<null> {
    return this.purchaseOrderCommentsAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show purchase_order_comments
   *
   * Get purchase order comment by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderCommentID Purchase Order Comment ID
   * @return OK
   */
  purchaseOrderCommentsShowResponse(purchaseOrderCommentID: string): __Observable<__StrictHttpResponse<LasgroPurchase_order_comments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/purchase_order_comments/${encodeURIComponent(String(purchaseOrderCommentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order_comments>;
      })
    );
  }
  /**
   * show purchase_order_comments
   *
   * Get purchase order comment by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderCommentID Purchase Order Comment ID
   * @return OK
   */
  purchaseOrderCommentsShow(purchaseOrderCommentID: string): __Observable<LasgroPurchase_order_comments> {
    return this.purchaseOrderCommentsShowResponse(purchaseOrderCommentID).pipe(
      __map(_r => _r.body as LasgroPurchase_order_comments)
    );
  }

  /**
   * delete purchase_order_comments
   *
   * Delete purchase order comment by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderCommentID Purchase Order Comment ID
   */
  purchaseOrderCommentsDeleteResponse(purchaseOrderCommentID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_order/purchase_order_comments/${encodeURIComponent(String(purchaseOrderCommentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete purchase_order_comments
   *
   * Delete purchase order comment by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderCommentID Purchase Order Comment ID
   */
  purchaseOrderCommentsDelete(purchaseOrderCommentID: string): __Observable<null> {
    return this.purchaseOrderCommentsDeleteResponse(purchaseOrderCommentID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list purchase_order_comments
   *
   * Lists purchase order comments
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID Purchase Order ID
   * @return OK
   */
  purchaseOrderCommentsListResponse(purchaseOrderID: string): __Observable<__StrictHttpResponse<LasgroPurchase_order_commentsCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/${encodeURIComponent(String(purchaseOrderID))}/purchase_order_comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order_commentsCollection>;
      })
    );
  }
  /**
   * list purchase_order_comments
   *
   * Lists purchase order comments
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID Purchase Order ID
   * @return OK
   */
  purchaseOrderCommentsList(purchaseOrderID: string): __Observable<LasgroPurchase_order_commentsCollection> {
    return this.purchaseOrderCommentsListResponse(purchaseOrderID).pipe(
      __map(_r => _r.body as LasgroPurchase_order_commentsCollection)
    );
  }
}

module PurchaseOrderCommentsService {
}

export { PurchaseOrderCommentsService }
