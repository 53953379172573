import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-pill-btn',
  templateUrl: './pill-btn.component.html',
  styleUrls: ['./pill-btn.component.scss'],
})
export class PillBtnComponent implements OnInit {
  @Input() title: string = '';
  @Input() hint: string = '';
  @Input() showClearBtn = false;

  @Output()
  onClick = new EventEmitter<any>();

  @Output()
  onClear = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
}
