<div class="container-fluid mt-4" *ngIf="isCustomer && allowEdit && currentUser.role != 'User'">
  <div class="jumbotron-fluid">
    <ngx-add-task-form
      (saveTask)="createNewTask($event)"
      [checklist]="checklist"
      [companyUsers]="availableUsers"></ngx-add-task-form>
  </div>
</div>

<div class="container-fluid mt-4">
  <div class="checklist-container jumbotron-fluid">
    <div class="d-flex justify-content-between align-items-middle">
      <div class="d-flex justify-content-start align-items-middle left-actions mb-2" *ngIf="currentUser.role != 'User'">
        <button mat-raised-button color="primary" (click)="openChecklistSavedModal()" class="mr-2" *ngIf="isCustomer && allowEdit">
          See Saved Task Lists
        </button>
        <button mat-raised-button color="primary" (click)="saveChecklist()" class="mr-2" *ngIf="allowToSaveChecklist && hasTasks() && isCustomer">
          Save this Task List
        </button>
        <button mat-raised-button color="warn" class="mr-2" [ngClass]="{ 'transparent': !mustEnableBulkDelete() }" (click)="applyBulkDelete()" type="button">
          Delete Selected
        </button>
      </div>
      <div class="d-flex justify-content-end align-items-middle mb-2 sorting" *ngIf="hasTasks()">
        <a (click)="sortTasks()" *ngIf="hasTasks()" [matTooltip]="'Sort by Due Date'">
          <i class="fa" [ngClass]="'fa-sort' + (currentSorting !== 'none' ? '-' + currentSorting : '')"></i>
          <i class="icon fa fa-clock-o"></i>
        </a>
      </div>
    </div>
    <div
      *ngIf="isCustomer && hasTasks()"
      class="checklist"
      [dragula]="'checklist-'+ purchaseOrderId"
      [(dragulaModel)]="tasks"
    >
      <ng-container *ngTemplateOutlet="tasksTemplate"></ng-container>
    </div>

    <div *ngIf="!isCustomer && hasTasks()" class="checklist">
      <ng-container *ngTemplateOutlet="tasksTemplate"></ng-container>
    </div>
  </div>

  <ng-template #tasksTemplate>
    <div class="task-item" [id]="task.sequence_number" *ngFor="let task of tasks; let $index = index">
      <ngx-task-item
        [isCompleteButtonDisabled]="isCompleteButtonDisabled(task)"
        [allowEdit]="allowEdit"
        [disabled]="!allowEdit"
        [draggable]="isCustomer && currentSorting === 'none'"
        [isCustomer]="isCustomer"
        [availableUsers]="availableUsers"
        (delete)="removeTask([task])"
        (taskChange)="updateTask($event)"
        (addFiles)="openAddFilesModal($event)"
        (selectionEvent)="handleSelection($event)"
        [task]="task"></ngx-task-item>
    </div>
  </ng-template>
</div>

<div class="container-fluid mt-4 d-flex justify-content-center" *ngIf="!hasTasks()">
  <div class="row">
    <p
      class="no-tasks-text">There aren't any tasks added yet</p>
  </div>
</div>
