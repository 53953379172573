import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { CompanyService, Toaster } from '@services/index';
import { LocalStorage } from 'ngx-webstorage';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { MessageService } from 'api/services';

@Component({
  selector: 'ngx-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.scss'],
})
export class BroadcastMessageComponent implements OnInit {
  
  @LocalStorage() organizationId: string;
  messageForm: UntypedFormGroup;
  loading = false;

  // TODO: create type
  @Input() resources: any[];
  @Input() resourceType: string = "resource";
  @Input() modalRef: NgbModalRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private messagesService: MessageService,
    private $toaster: Toaster,
  ) { }

  ngOnInit() {
    this.messageForm = this.formBuilder.group({
      mention_all: [null, [Validators.required]],
      content: [null, [Validators.required]],
    });
  }

  save() {
    if (this.messageForm.invalid) {
      return;
    }

    this.loading = true;
    let payload = this.messageForm.getRawValue();
    payload.resource_ids = this.resources.map(r => r.resource_id);

    this.messagesService.messageBroadcast({organizationID: this.organizationId, payload, mentionAll: payload.mention_all, isContract: true})
      .subscribe(
        async (res) => {
          this.$toaster.show('success', 'Success', 'Your message was sent successfully');
          this.reset();
          this.loading = false;
          this.modalRef.close(true);
        },
        error => {
          console.error(error);
          this.$toaster.show('error', '', 'An error occurred, please try again later');
          this.loading = false;
        },
      );
  }

  getResourceList() {
    // let list = "";
    if (!this.resources || !this.resources.length) {
      return "";
    }
    return this.resources.map(r => "#".concat(r.customer_po_number));

    /*this.resources.forEach(r => {
      list.concat("#", r.customer_po_number, ", ");
    });
    list.slice(0, -2) + '.';

    return list;*/
  }

  reset() {
    this.messageForm.reset();
  }

}
