import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '@app/app.config';
import { Invoice } from '@models/invoice.model';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';

@Injectable()
export class InvoiceService {
  @LocalStorage() token;

  constructor(private httpClient: HttpClient) {}

  attachInvoiceFile(
    organizationId: string,
    purchaseOrderId: string,
    file: File,
    isProforma: boolean = false): Observable<Invoice> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices`;
    const formData: FormData = new FormData();

    const params = new HttpParams({
      fromObject: {
        is_proforma: isProforma.toString(),
      },
    });

    formData.append('file', file, file.name);
    return this.httpClient.post<Invoice>(endpoint, formData, { params });
  }

  deleteInvoiceFile(organizationId: string, purchaseOrderId: string, invoiceId: string, fileId: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices/${ invoiceId }/files/${ fileId }`;
    return this.httpClient.delete(endpoint);
  }

  updateInvoiceFile(organizationId: string, purchaseOrderId: string, invoiceId: string, file: File): Observable<Invoice> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices/${ invoiceId }/files`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.patch<Invoice>(endpoint, formData);
  }

  updateInvoice(organizationId: string, purchaseOrderId: string, invoiceId: string, data: any): Observable<Invoice> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices/${ invoiceId }`;
    return this.httpClient.patch<Invoice>(endpoint, data);
  }

  acknowledgeInvoice(organizationId: string, purchaseOrderId: string, invoiceId: string, accept: boolean) {
    const data = {
      status: accept ? 'approved' : 'rejected',
    };
    return this.updateInvoice(organizationId, purchaseOrderId, invoiceId, data);
  }

  rejectInvoiceWithReason(organizationId: string, purchaseOrderId: string, invoiceId: string, reason: string) {
    const data = {
      reason,
      status: 'rejected',
    };
    return this.updateInvoice(organizationId, purchaseOrderId, invoiceId, data);
  }

  /**
   * Service to upload a file to the server and attach it to a purchase order
   * @param organizationId ID of the company
   * @param purchaseOrderId ID of the purchase order
   * @param invoiceId ID of the invoice related to the PO
   * @param fileId ID of the file to upload to the server
   */
  getInvoiceFile(
    organizationId: string,
    purchaseOrderId: string,
    invoiceId: string,
    fileId: string,
  ) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices/${ invoiceId }/files/${ fileId }`;
    return this.httpClient.get(endpoint, { observe: 'response', responseType: 'blob' });
  }

  getPurchaseOrderInvoices(organizationId, purchaseOrderId): Observable<Invoice[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices`;
    return this.httpClient.get<Invoice[]>(endpoint);
  }

  getInvoice(
    organizationId: string,
    purchaseOrderId: string,
    invoiceId: string,
  ) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices/${ invoiceId }`;
    return this.httpClient.get<Invoice>(endpoint);
  }

  deleteInvoice(
    organizationId: string,
    purchaseOrderId: string,
    invoiceId: string,
  ) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices/${ invoiceId }`;
    return this.httpClient.delete(endpoint);
  }

  deleteInvoiceLineItem(organizationId: string,
                        purchaseOrderId: string,
                        invoiceId: string,
                        lineItemId: string,
  ) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/invoices/${ invoiceId }/line_items/${ lineItemId }`;
    return this.httpClient.delete(endpoint);
  }

}
