import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { User } from '@app/models';
import { ContractHelper } from '@app/shared/helpers/contract.helper';
import { NavigationHelper } from '@app/shared/helpers/navigation.helper';
import { Store } from '@ngrx/store';
import { StickyDirective } from 'ngx-sticky-directive';
import { filter } from 'rxjs/operators';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-header',
  templateUrl: './contract-header.component.html',
  styleUrls: ['./contract-header.component.scss'],
  imports: [
    CommonModule
  ],
  providers: [
    StickyDirective
  ]
})
export class ContractHeaderComponent extends ContractBaseComponent implements OnInit {
  constructor(public injector: Injector, private navigationHelper: NavigationHelper, public store: Store<{ user: User }>) {
    super(injector, store)
  } 

  ngOnInit(): void {}

  goBack() {
    this.navigationHelper.goBack();
  }
}
