/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOrganization_page } from '../models/lasgro-organization-_page';
import { OrganizationPageUpdatePayload } from '../models/organization-page-update-payload';
import { OrganizationPageAddPayload } from '../models/organization-page-add-payload';
import { LasgroOrganization_pageCollection } from '../models/lasgro-organization-_page-collection';
import { UpdateListOrganizationPagesPayload } from '../models/update-list-organization-pages-payload';
@Injectable({
  providedIn: 'root',
})
class OrganizationPagesService extends __BaseService {
  static readonly organizationPagesUpdatePath = '/organization_pages';
  static readonly organizationPagesAddPath = '/organization_pages';
  static readonly organizationPagesGetPagesForOrganizationPath = '/organization_pages/organization';
  static readonly organizationPagesShowPath = '/organization_pages/{organizationPageID}';
  static readonly organizationPagesDeletePath = '/organization_pages/{organizationPageID}';
  static readonly organizationPagesUpdateListPath = '/organization_pages/{viewType}/{organizationID}/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  organizationPagesUpdateResponse(payload: OrganizationPageUpdatePayload): __Observable<__StrictHttpResponse<LasgroOrganization_page>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization_pages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_page>;
      })
    );
  }
  /**
   * update organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  organizationPagesUpdate(payload: OrganizationPageUpdatePayload): __Observable<LasgroOrganization_page> {
    return this.organizationPagesUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroOrganization_page)
    );
  }

  /**
   * add organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  organizationPagesAddResponse(payload: OrganizationPageAddPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organization_pages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  organizationPagesAdd(payload: OrganizationPageAddPayload): __Observable<null> {
    return this.organizationPagesAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * GetPagesForOrganization organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationPagesService.OrganizationPagesGetPagesForOrganizationParams` containing the following parameters:
   *
   * - `viewType`:
   *
   * - `for_organization_id`:
   *
   * @return OK
   */
  organizationPagesGetPagesForOrganizationResponse(params: OrganizationPagesService.OrganizationPagesGetPagesForOrganizationParams): __Observable<__StrictHttpResponse<LasgroOrganization_pageCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.forOrganizationId != null) __params = __params.set('for_organization_id', params.forOrganizationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization_pages/organization`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_pageCollection>;
      })
    );
  }
  /**
   * GetPagesForOrganization organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationPagesService.OrganizationPagesGetPagesForOrganizationParams` containing the following parameters:
   *
   * - `viewType`:
   *
   * - `for_organization_id`:
   *
   * @return OK
   */
  organizationPagesGetPagesForOrganization(params: OrganizationPagesService.OrganizationPagesGetPagesForOrganizationParams): __Observable<LasgroOrganization_pageCollection> {
    return this.organizationPagesGetPagesForOrganizationResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_pageCollection)
    );
  }

  /**
   * show organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageID undefined
   * @return OK
   */
  organizationPagesShowResponse(organizationPageID: string): __Observable<__StrictHttpResponse<LasgroOrganization_page>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization_pages/${encodeURIComponent(String(organizationPageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_page>;
      })
    );
  }
  /**
   * show organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageID undefined
   * @return OK
   */
  organizationPagesShow(organizationPageID: string): __Observable<LasgroOrganization_page> {
    return this.organizationPagesShowResponse(organizationPageID).pipe(
      __map(_r => _r.body as LasgroOrganization_page)
    );
  }

  /**
   * delete organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageID undefined
   */
  organizationPagesDeleteResponse(organizationPageID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organization_pages/${encodeURIComponent(String(organizationPageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationPageID undefined
   */
  organizationPagesDelete(organizationPageID: string): __Observable<null> {
    return this.organizationPagesDeleteResponse(organizationPageID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * UpdateList organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationPagesService.OrganizationPagesUpdateListParams` containing the following parameters:
   *
   * - `viewType`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  organizationPagesUpdateListResponse(params: OrganizationPagesService.OrganizationPagesUpdateListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization_pages/${encodeURIComponent(String(params.viewType))}/${encodeURIComponent(String(params.organizationID))}/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * UpdateList organization_pages
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationPagesService.OrganizationPagesUpdateListParams` containing the following parameters:
   *
   * - `viewType`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  organizationPagesUpdateList(params: OrganizationPagesService.OrganizationPagesUpdateListParams): __Observable<null> {
    return this.organizationPagesUpdateListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module OrganizationPagesService {

  /**
   * Parameters for organizationPagesGetPagesForOrganization
   */
  export interface OrganizationPagesGetPagesForOrganizationParams {
    viewType?: string;
    forOrganizationId?: string;
  }

  /**
   * Parameters for organizationPagesUpdateList
   */
  export interface OrganizationPagesUpdateListParams {
    viewType: string;
    payload: UpdateListOrganizationPagesPayload;
    organizationID: string;
  }
}

export { OrganizationPagesService }
