<form (ngSubmit)="submit()" [formGroup]="modalForm">
<div class="modal-header">
  <span *ngIf="!isUpdate">Add File Requirement</span>
  <span *ngIf="isUpdate">Update File Requirement</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <mat-form-field>
      <input matInput
        formControlName="name"
        type="text" 
        placeholder="Name" 
        [(ngModel)]="name" 
        required/>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <textarea 
        matInput 
        formControlName="description"
        type="text" 
        placeholder="Description" 
        [(ngModel)]="description">
      </textarea>
    </mat-form-field>
  </div>
  <div>
    <mat-checkbox [checked]="is_mandatory"
        [(ngModel)]="is_mandatory">
      Mandatory
    </mat-checkbox>
  </div>
</div>
<div class="modal-footer po-modal">
  <button
    type="submit"
    mat-raised-button
    class="pull-right margin10"
    color="primary"
    [disabled]="modalForm.invalid"
  >
    Submit
  </button>
</div>
