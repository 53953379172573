/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroComponent } from '../models/lasgro-component';
import { ComponentUpdatePayload } from '../models/component-update-payload';
import { ComponentAddPayload } from '../models/component-add-payload';
import { LasgroComponentCollection } from '../models/lasgro-component-collection';
@Injectable({
  providedIn: 'root',
})
class ComponentsService extends __BaseService {
  static readonly componentsUpdatePath = '/components';
  static readonly componentsAddPath = '/components';
  static readonly componentsListPath = '/components/list';
  static readonly componentsShowPath = '/components/{componentID}';
  static readonly componentsDeletePath = '/components/{componentID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update components
   *
   * Updates a component
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  componentsUpdateResponse(payload: ComponentUpdatePayload): __Observable<__StrictHttpResponse<LasgroComponent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/components`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroComponent>;
      })
    );
  }
  /**
   * update components
   *
   * Updates a component
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  componentsUpdate(payload: ComponentUpdatePayload): __Observable<LasgroComponent> {
    return this.componentsUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroComponent)
    );
  }

  /**
   * add components
   *
   * Add a new component
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  componentsAddResponse(payload: ComponentAddPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/components`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add components
   *
   * Add a new component
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  componentsAdd(payload: ComponentAddPayload): __Observable<null> {
    return this.componentsAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list components
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  componentsListResponse(organizationID?: string): __Observable<__StrictHttpResponse<LasgroComponentCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (organizationID != null) __params = __params.set('organizationID', organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/components/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroComponentCollection>;
      })
    );
  }
  /**
   * list components
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  componentsList(organizationID?: string): __Observable<LasgroComponentCollection> {
    return this.componentsListResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroComponentCollection)
    );
  }

  /**
   * show components
   *
   * Get component by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param componentID Purchase Order Comment ID
   * @return OK
   */
  componentsShowResponse(componentID: string): __Observable<__StrictHttpResponse<LasgroComponent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/components/${encodeURIComponent(String(componentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroComponent>;
      })
    );
  }
  /**
   * show components
   *
   * Get component by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param componentID Purchase Order Comment ID
   * @return OK
   */
  componentsShow(componentID: string): __Observable<LasgroComponent> {
    return this.componentsShowResponse(componentID).pipe(
      __map(_r => _r.body as LasgroComponent)
    );
  }

  /**
   * delete components
   *
   * Delete component by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param componentID Component ID
   */
  componentsDeleteResponse(componentID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/components/${encodeURIComponent(String(componentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete components
   *
   * Delete component by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param componentID Component ID
   */
  componentsDelete(componentID: string): __Observable<null> {
    return this.componentsDeleteResponse(componentID).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ComponentsService {
}

export { ComponentsService }
