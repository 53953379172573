<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closePopup()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- Calendly inline widget begin -->
  <div class="calendly-inline-widget" [attr.data-url]="calendlyEventUrl"
       style="position:relative;min-width:678px;height:900px;"></div>
<!--  <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>-->
  <!-- Calendly inline widget end -->
</div>
