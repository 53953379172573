/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroTask_notification_recordCollection } from '../models/lasgro-task-_notification-_record-collection';
import { LasgroTask_notification_record } from '../models/lasgro-task-_notification-_record';
@Injectable({
  providedIn: 'root',
})
class TaskNotificationRecordService extends __BaseService {
  static readonly taskNotificationRecordListByInstancePath = '/workflows/{workflowID}/task/{taskID}/instances/{taskInstanceID}/notification_records';
  static readonly taskNotificationRecordShowPath = '/workflows/{workflowID}/task/{taskID}/instances/{taskInstanceID}/notification_records/{recordID}';
  static readonly taskNotificationRecordListPath = '/workflows/{workflowID}/task/{taskID}/notification_records';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list_by_instance task_notification_record
   *
   * List all of the task notification records for a task instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationRecordService.TaskNotificationRecordListByInstanceParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * @return OK
   */
  taskNotificationRecordListByInstanceResponse(params: TaskNotificationRecordService.TaskNotificationRecordListByInstanceParams): __Observable<__StrictHttpResponse<LasgroTask_notification_recordCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows/${encodeURIComponent(String(params.workflowID))}/task/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}/notification_records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_notification_recordCollection>;
      })
    );
  }
  /**
   * list_by_instance task_notification_record
   *
   * List all of the task notification records for a task instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationRecordService.TaskNotificationRecordListByInstanceParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * @return OK
   */
  taskNotificationRecordListByInstance(params: TaskNotificationRecordService.TaskNotificationRecordListByInstanceParams): __Observable<LasgroTask_notification_recordCollection> {
    return this.taskNotificationRecordListByInstanceResponse(params).pipe(
      __map(_r => _r.body as LasgroTask_notification_recordCollection)
    );
  }

  /**
   * show task_notification_record
   *
   * Details of a task notification record
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationRecordService.TaskNotificationRecordShowParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `recordID`:
   *
   * @return OK
   */
  taskNotificationRecordShowResponse(params: TaskNotificationRecordService.TaskNotificationRecordShowParams): __Observable<__StrictHttpResponse<LasgroTask_notification_record>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows/${encodeURIComponent(String(params.workflowID))}/task/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}/notification_records/${encodeURIComponent(String(params.recordID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_notification_record>;
      })
    );
  }
  /**
   * show task_notification_record
   *
   * Details of a task notification record
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationRecordService.TaskNotificationRecordShowParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `recordID`:
   *
   * @return OK
   */
  taskNotificationRecordShow(params: TaskNotificationRecordService.TaskNotificationRecordShowParams): __Observable<LasgroTask_notification_record> {
    return this.taskNotificationRecordShowResponse(params).pipe(
      __map(_r => _r.body as LasgroTask_notification_record)
    );
  }

  /**
   * list task_notification_record
   *
   * List all of the task notification records
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationRecordService.TaskNotificationRecordListParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskID`:
   *
   * @return OK
   */
  taskNotificationRecordListResponse(params: TaskNotificationRecordService.TaskNotificationRecordListParams): __Observable<__StrictHttpResponse<LasgroTask_notification_recordCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workflows/${encodeURIComponent(String(params.workflowID))}/task/${encodeURIComponent(String(params.taskID))}/notification_records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_notification_recordCollection>;
      })
    );
  }
  /**
   * list task_notification_record
   *
   * List all of the task notification records
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationRecordService.TaskNotificationRecordListParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskID`:
   *
   * @return OK
   */
  taskNotificationRecordList(params: TaskNotificationRecordService.TaskNotificationRecordListParams): __Observable<LasgroTask_notification_recordCollection> {
    return this.taskNotificationRecordListResponse(params).pipe(
      __map(_r => _r.body as LasgroTask_notification_recordCollection)
    );
  }
}

module TaskNotificationRecordService {

  /**
   * Parameters for taskNotificationRecordListByInstance
   */
  export interface TaskNotificationRecordListByInstanceParams {
    workflowID: string;
    taskInstanceID: string;
    taskID: string;
  }

  /**
   * Parameters for taskNotificationRecordShow
   */
  export interface TaskNotificationRecordShowParams {
    workflowID: string;
    taskInstanceID: string;
    taskID: string;
    recordID: string;
  }

  /**
   * Parameters for taskNotificationRecordList
   */
  export interface TaskNotificationRecordListParams {
    workflowID: string;
    taskID: string;
  }
}

export { TaskNotificationRecordService }
