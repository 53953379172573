import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeasurementUnit, ProductHistory } from '@models/product.model';
import { Observable } from 'rxjs';
import CONFIG from '@app/app.config';

@Injectable()
export class ProductService {
  constructor(private httpClient: HttpClient) {}

  getProductHistory(organizationId: string, productId: string, onlyApprovedPos = false): Observable<ProductHistory[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/po_history`;
    const params = new HttpParams({
      fromObject: {
        onlyApprovedPos: onlyApprovedPos.toString(),
      },
    });
    return this.httpClient.get<ProductHistory[]>(endpoint, { params });
  }

  getMeasurementUnits(type: string): Observable<MeasurementUnit[]> {
    const endpoint = `${ CONFIG.API }/measurement_units`;
    const params = new HttpParams({
      fromObject: {
        type,
      },
    });
    return this.httpClient.get<MeasurementUnit[]>(endpoint, { params });
  }
}
