import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import CONFIG from '@app/app.config';
import { FileItemDto } from '@models/file-item.model';
import { ChecklistTaskDto } from '@app/models/checklist-task.model';
import { User } from '@models/user.model';
import { Store } from '@ngrx/store';
import { CompanyService } from '@services/company.service';
import { Toaster } from '@services/toaster.service';
import { CustomPopupComponent } from '@theme/components';
import { saveAs } from 'file-saver';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileSizePipe } from '@app/shared/pipes';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxLoadingModule } from 'ngx-loading';

@Component({
  selector: 'ngx-task-files-modal',
  templateUrl: './task-files-modal.component.html',
  styleUrls: ['./task-files-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
    NgxLoadingModule,
    MatDialogModule,
    FileSizePipe
  ]
})
export class TaskFilesModalComponent implements OnInit {
  filesLoading;
  @Input() taskFiles: FileItemDto[] = [];
  @Input() task: ChecklistTaskDto;
  @Input() isCustomer: boolean;
  @Input() allowEdit: boolean = true;
  @LocalStorage() organizationId;
  user$: Observable<User>;

  constructor(
    public matDialog: MatDialog,
    public modal: NgbActiveModal,
    public $company: CompanyService,
    public matDialogRef: MatDialogRef<TaskFilesModalComponent>,
    public store: Store<{ user: User }>,
    private $toaster: Toaster) { }

  ngOnInit() {
    this.user$ = this.store.select('user');
    this.getFilesList();
  }

  removeFile(file) {
    const modal = this.matDialog.open(CustomPopupComponent, {
      disableClose: true,
    });

    modal.componentInstance.description = 'Are you sure you want to delete this file?';
    modal.afterClosed().subscribe(
      (result) => {
        if (result !== 'success') {
          return;
        }
        this.$company.removeFileFromTask(file.file_id).subscribe(
          () => {
            this.getFilesList();
            this.$toaster.show('success', 'File', 'File has been removed');
            // this.onSubmit.emit('updated');
          },
          error => {
            const errMsg =
              error.error && error.error.detail ? error.error.detail : 'An error occurred while deleting the files.';
            this.$toaster.show('error', 'File', errMsg);
          },
        );
      });
  }

  getFilesList() {
    this.$company.getTaskFiles(this.task.checklist_task_id)
      .subscribe((taskFiles) => {
        this.taskFiles = taskFiles.sort((currentFile: FileItemDto, nextFile: FileItemDto) => {
          return currentFile.created_at < nextFile.created_at ? 1 : -1;
        });
      });
  }

  async addFileSelectedTask($event) {
    try {
      const fileList: FileList = $event.srcElement.files;
      const formData: FormData = new FormData();

      if (fileList.length === 0) {
        return;
      }

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        if (file.size > CONFIG.FILE_LIMIT) {
          this.$toaster.show('warning', 'File size', 'Maximum file size for uploads is 200 mb');
          return;
        }
        formData.append('file', file, file.name);
      }

      this.filesLoading = true;
      await this.$company.addFileToTask(this.task.checklist_task_id, formData).toPromise();
      this.getFilesList();

      this.filesLoading = false;
      // this.onSubmit.emit('updated');
      this.$toaster.show('success', 'File', 'File has been added');
    } catch (error) {
      console.error(error);
      const errMsg =
        error.error && error.error.detail ? error.error.detail : 'An error occurred while uploading the files.';
      this.$toaster.show('error', 'File', errMsg);
    }
  }

  downloadFile({ file_id: fileID, file_name: fileName }) {
    this.$company
      .getTaskFileById(fileID)
      .subscribe(
        (res: HttpResponse<Blob>) => {
          const blob = new Blob([res.body], {
            type: 'application/octet-stream',
          });
          saveAs(blob, fileName);
        },
        error => {
          console.error(error);
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error occurred while downloading the files.';
          this.$toaster.show('error', 'File', errMsg);
        },
      );
  }
}
