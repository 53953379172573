/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroUserCollection } from '../models/lasgro-user-collection';
import { UserCreateInputPayload } from '../models/user-create-input-payload';
import { LasgroUser } from '../models/lasgro-user';
import { UserInputPayload } from '../models/user-input-payload';
import { ScheduledOnboardingUserPayload } from '../models/scheduled-onboarding-user-payload';
import { LasgroTraining_moduleCollection } from '../models/lasgro-training-_module-collection';
import { UpdateTrainingModuleUserPayload } from '../models/update-training-module-user-payload';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly userList1Path = '/organizations/{organizationID}/users';
  static readonly userAddPath = '/organizations/{organizationID}/users';
  static readonly userShow1Path = '/organizations/{organizationID}/users/{userID}';
  static readonly userDelete1Path = '/organizations/{organizationID}/users/{userID}';
  static readonly userUpdate1Path = '/organizations/{organizationID}/users/{userID}';
  static readonly userScheduledOnboardingPath = '/organizations/{organizationID}/users/{userID}/scheduled_onboarding';
  static readonly userTrainingModulesPath = '/organizations/{organizationID}/users/{userID}/training';
  static readonly userUpdateTrainingModulePath = '/organizations/{organizationID}/users/{userID}/training/{index}';
  static readonly userListPath = '/users';
  static readonly userAdd1Path = '/users';
  static readonly userShowPath = '/users/{userID}';
  static readonly userDeletePath = '/users/{userID}';
  static readonly userUpdatePath = '/users/{userID}';
  static readonly userSendInvitationPath = '/users/{userID}/invite';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list user
   *
   * List all users in a organization. Users can only see the users in their organization, and only according to their role - that is, managers can see all the users in their organization, but	supervisors can only see users below them. Lasgro admins can see users in any organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  userList1Response(organizationID: string): __Observable<__StrictHttpResponse<LasgroUserCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUserCollection>;
      })
    );
  }
  /**
   * list user
   *
   * List all users in a organization. Users can only see the users in their organization, and only according to their role - that is, managers can see all the users in their organization, but	supervisors can only see users below them. Lasgro admins can see users in any organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  userList1(organizationID: string): __Observable<LasgroUserCollection> {
    return this.userList1Response(organizationID).pipe(
      __map(_r => _r.body as LasgroUserCollection)
    );
  }

  /**
   * add user
   *
   * Signs up user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserAddParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userAddResponse(params: UserService.UserAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add user
   *
   * Signs up user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserAddParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userAdd(params: UserService.UserAddParams): __Observable<null> {
    return this.userAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show user
   *
   * Retrieve user with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserShow1Params` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  userShow1Response(params: UserService.UserShow1Params): __Observable<__StrictHttpResponse<LasgroUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/users/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUser>;
      })
    );
  }
  /**
   * show user
   *
   * Retrieve user with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserShow1Params` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  userShow1(params: UserService.UserShow1Params): __Observable<LasgroUser> {
    return this.userShow1Response(params).pipe(
      __map(_r => _r.body as LasgroUser)
    );
  }

  /**
   * delete user
   *
   * Deletes user (soft delete)
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserDelete1Params` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   */
  userDelete1Response(params: UserService.UserDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/users/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete user
   *
   * Deletes user (soft delete)
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserDelete1Params` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   */
  userDelete1(params: UserService.UserDelete1Params): __Observable<null> {
    return this.userDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update user
   *
   * Updates user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserUpdate1Params` containing the following parameters:
   *
   * - `userID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userUpdate1Response(params: UserService.UserUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/users/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update user
   *
   * Updates user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserUpdate1Params` containing the following parameters:
   *
   * - `userID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userUpdate1(params: UserService.UserUpdate1Params): __Observable<null> {
    return this.userUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * scheduled_onboarding user
   *
   * Sets the onboarding session for this user as scheduled or rejected.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserScheduledOnboardingParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userScheduledOnboardingResponse(params: UserService.UserScheduledOnboardingParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/users/${encodeURIComponent(String(params.userID))}/scheduled_onboarding`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * scheduled_onboarding user
   *
   * Sets the onboarding session for this user as scheduled or rejected.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserScheduledOnboardingParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userScheduledOnboarding(params: UserService.UserScheduledOnboardingParams): __Observable<null> {
    return this.userScheduledOnboardingResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * training_modules user
   *
   * Gets the list of training modules for this user.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserTrainingModulesParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   *
   * - `showAll`:
   *
   * @return OK
   */
  userTrainingModulesResponse(params: UserService.UserTrainingModulesParams): __Observable<__StrictHttpResponse<LasgroTraining_moduleCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.showAll != null) __params = __params.set('showAll', params.showAll.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/users/${encodeURIComponent(String(params.userID))}/training`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTraining_moduleCollection>;
      })
    );
  }
  /**
   * training_modules user
   *
   * Gets the list of training modules for this user.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserTrainingModulesParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   *
   * - `showAll`:
   *
   * @return OK
   */
  userTrainingModules(params: UserService.UserTrainingModulesParams): __Observable<LasgroTraining_moduleCollection> {
    return this.userTrainingModulesResponse(params).pipe(
      __map(_r => _r.body as LasgroTraining_moduleCollection)
    );
  }

  /**
   * update_training_module user
   *
   * Updates a training module for this user.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserUpdateTrainingModuleParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `index`:
   */
  userUpdateTrainingModuleResponse(params: UserService.UserUpdateTrainingModuleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/users/${encodeURIComponent(String(params.userID))}/training/${encodeURIComponent(String(params.index))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update_training_module user
   *
   * Updates a training module for this user.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserUpdateTrainingModuleParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `index`:
   */
  userUpdateTrainingModule(params: UserService.UserUpdateTrainingModuleParams): __Observable<null> {
    return this.userUpdateTrainingModuleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list user
   *
   * List all users in a organization. Users can only see the users in their organization, and only according to their role - that is, managers can see all the users in their organization, but	supervisors can only see users below them. Lasgro admins can see users in any organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  userListResponse(organizationID?: string): __Observable<__StrictHttpResponse<LasgroUserCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (organizationID != null) __params = __params.set('organizationID', organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUserCollection>;
      })
    );
  }
  /**
   * list user
   *
   * List all users in a organization. Users can only see the users in their organization, and only according to their role - that is, managers can see all the users in their organization, but	supervisors can only see users below them. Lasgro admins can see users in any organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  userList(organizationID?: string): __Observable<LasgroUserCollection> {
    return this.userListResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroUserCollection)
    );
  }

  /**
   * add user
   *
   * Signs up user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserAdd1Params` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userAdd1Response(params: UserService.UserAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add user
   *
   * Signs up user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserAdd1Params` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userAdd1(params: UserService.UserAdd1Params): __Observable<null> {
    return this.userAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show user
   *
   * Retrieve user with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserShowParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  userShowResponse(params: UserService.UserShowParams): __Observable<__StrictHttpResponse<LasgroUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUser>;
      })
    );
  }
  /**
   * show user
   *
   * Retrieve user with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserShowParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  userShow(params: UserService.UserShowParams): __Observable<LasgroUser> {
    return this.userShowResponse(params).pipe(
      __map(_r => _r.body as LasgroUser)
    );
  }

  /**
   * delete user
   *
   * Deletes user (soft delete)
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserDeleteParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   */
  userDeleteResponse(params: UserService.UserDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete user
   *
   * Deletes user (soft delete)
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserDeleteParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `organizationID`:
   */
  userDelete(params: UserService.UserDeleteParams): __Observable<null> {
    return this.userDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update user
   *
   * Updates user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserUpdateParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userUpdateResponse(params: UserService.UserUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/users/${encodeURIComponent(String(params.userID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update user
   *
   * Updates user
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `UserService.UserUpdateParams` containing the following parameters:
   *
   * - `userID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  userUpdate(params: UserService.UserUpdateParams): __Observable<null> {
    return this.userUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * send_invitation user
   *
   * Sends an invitation for a given user
   *
   * Required security scopes:
   *   * `api:access`
   * @param userID undefined
   */
  userSendInvitationResponse(userID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/${encodeURIComponent(String(userID))}/invite`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * send_invitation user
   *
   * Sends an invitation for a given user
   *
   * Required security scopes:
   *   * `api:access`
   * @param userID undefined
   */
  userSendInvitation(userID: string): __Observable<null> {
    return this.userSendInvitationResponse(userID).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserService {

  /**
   * Parameters for userAdd
   */
  export interface UserAddParams {
    payload: UserCreateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for userShow1
   */
  export interface UserShow1Params {
    userID: string;
    organizationID: string;
  }

  /**
   * Parameters for userDelete1
   */
  export interface UserDelete1Params {
    userID: string;
    organizationID: string;
  }

  /**
   * Parameters for userUpdate1
   */
  export interface UserUpdate1Params {
    userID: string;
    payload: UserInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for userScheduledOnboarding
   */
  export interface UserScheduledOnboardingParams {
    userID: string;
    payload: ScheduledOnboardingUserPayload;
    organizationID: string;
  }

  /**
   * Parameters for userTrainingModules
   */
  export interface UserTrainingModulesParams {
    userID: string;
    organizationID: string;
    showAll?: boolean;
  }

  /**
   * Parameters for userUpdateTrainingModule
   */
  export interface UserUpdateTrainingModuleParams {
    userID: string;
    payload: UpdateTrainingModuleUserPayload;
    organizationID: string;
    index: number;
  }

  /**
   * Parameters for userAdd1
   */
  export interface UserAdd1Params {
    payload: UserCreateInputPayload;
    organizationID?: string;
  }

  /**
   * Parameters for userShow
   */
  export interface UserShowParams {
    userID: string;
    organizationID?: string;
  }

  /**
   * Parameters for userDelete
   */
  export interface UserDeleteParams {
    userID: string;
    organizationID?: string;
  }

  /**
   * Parameters for userUpdate
   */
  export interface UserUpdateParams {
    userID: string;
    payload: UserInputPayload;
    organizationID?: string;
  }
}

export { UserService }
