<div class="menu-select d-none" [ngClass]="{ 'rcdss': environment?.name === 'rcdss' }">
  <a [routerLink]="['/dashboard']" style="color:#fff;">
    <!-- <img id="lasgro-logo" [src]="sidebarIcon" class="main-logo"> -->
    <!--  <mat-select [(value)]="selectedSubscription" (selectionChange)="chooseSubscription()" style="color:#fff;" panelClass="menu-select-panel">
        <mat-option *ngFor="let subscription of subscriptions" [value]="subscription.value">
          {{subscription.title}}
        </mat-option>
      </mat-select>-->
  </a>
</div>
