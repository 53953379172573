/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOrganization_notification_messageCollection } from '../models/lasgro-organization-_notification-_message-collection';
import { OrganizationNotificationMessageInputPayload } from '../models/organization-notification-message-input-payload';
@Injectable({
  providedIn: 'root',
})
class OrganizationNotificationMessageService extends __BaseService {
  static readonly organizationNotificationMessageListPath = '/organizations/{organizationID}/notifications/{notificationID}/messages';
  static readonly organizationNotificationMessageAddPath = '/organizations/{organizationID}/notifications/{notificationID}/messages';
  static readonly organizationNotificationMessageDeletePath = '/organizations/{organizationID}/notifications/{notificationID}/messages/{messageID}';
  static readonly organizationNotificationMessageUpdatePath = '/organizations/{organizationID}/notifications/{notificationID}/messages/{messageID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list organization_notification_message
   *
   * List all messages for this notification.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationMessageService.OrganizationNotificationMessageListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * @return OK
   */
  organizationNotificationMessageListResponse(params: OrganizationNotificationMessageService.OrganizationNotificationMessageListParams): __Observable<__StrictHttpResponse<LasgroOrganization_notification_messageCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications/${encodeURIComponent(String(params.notificationID))}/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_notification_messageCollection>;
      })
    );
  }
  /**
   * list organization_notification_message
   *
   * List all messages for this notification.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationMessageService.OrganizationNotificationMessageListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * @return OK
   */
  organizationNotificationMessageList(params: OrganizationNotificationMessageService.OrganizationNotificationMessageListParams): __Observable<LasgroOrganization_notification_messageCollection> {
    return this.organizationNotificationMessageListResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_notification_messageCollection)
    );
  }

  /**
   * add organization_notification_message
   *
   * Create a message for this notification.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationMessageService.OrganizationNotificationMessageAddParams` containing the following parameters:
   *
   * - `payload`: Input payload for organization notification messages
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   */
  organizationNotificationMessageAddResponse(params: OrganizationNotificationMessageService.OrganizationNotificationMessageAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications/${encodeURIComponent(String(params.notificationID))}/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add organization_notification_message
   *
   * Create a message for this notification.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationMessageService.OrganizationNotificationMessageAddParams` containing the following parameters:
   *
   * - `payload`: Input payload for organization notification messages
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   */
  organizationNotificationMessageAdd(params: OrganizationNotificationMessageService.OrganizationNotificationMessageAddParams): __Observable<null> {
    return this.organizationNotificationMessageAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete organization_notification_message
   *
   * Deletes this notification message.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationMessageService.OrganizationNotificationMessageDeleteParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * - `messageID`:
   */
  organizationNotificationMessageDeleteResponse(params: OrganizationNotificationMessageService.OrganizationNotificationMessageDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications/${encodeURIComponent(String(params.notificationID))}/messages/${encodeURIComponent(String(params.messageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete organization_notification_message
   *
   * Deletes this notification message.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationMessageService.OrganizationNotificationMessageDeleteParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * - `messageID`:
   */
  organizationNotificationMessageDelete(params: OrganizationNotificationMessageService.OrganizationNotificationMessageDeleteParams): __Observable<null> {
    return this.organizationNotificationMessageDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update organization_notification_message
   *
   * Updates this notification message.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationMessageService.OrganizationNotificationMessageUpdateParams` containing the following parameters:
   *
   * - `payload`: Input payload for organization notification messages
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * - `messageID`:
   */
  organizationNotificationMessageUpdateResponse(params: OrganizationNotificationMessageService.OrganizationNotificationMessageUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;



    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications/${encodeURIComponent(String(params.notificationID))}/messages/${encodeURIComponent(String(params.messageID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update organization_notification_message
   *
   * Updates this notification message.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationMessageService.OrganizationNotificationMessageUpdateParams` containing the following parameters:
   *
   * - `payload`: Input payload for organization notification messages
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * - `messageID`:
   */
  organizationNotificationMessageUpdate(params: OrganizationNotificationMessageService.OrganizationNotificationMessageUpdateParams): __Observable<null> {
    return this.organizationNotificationMessageUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module OrganizationNotificationMessageService {

  /**
   * Parameters for organizationNotificationMessageList
   */
  export interface OrganizationNotificationMessageListParams {
    organizationID: string;
    notificationID: string;
  }

  /**
   * Parameters for organizationNotificationMessageAdd
   */
  export interface OrganizationNotificationMessageAddParams {

    /**
     * Input payload for organization notification messages
     */
    payload: OrganizationNotificationMessageInputPayload;
    organizationID: string;
    notificationID: string;
  }

  /**
   * Parameters for organizationNotificationMessageDelete
   */
  export interface OrganizationNotificationMessageDeleteParams {
    organizationID: string;
    notificationID: string;
    messageID: string;
  }

  /**
   * Parameters for organizationNotificationMessageUpdate
   */
  export interface OrganizationNotificationMessageUpdateParams {

    /**
     * Input payload for organization notification messages
     */
    payload: OrganizationNotificationMessageInputPayload;
    organizationID: string;
    notificationID: string;
    messageID: string;
  }
}

export { OrganizationNotificationMessageService }
