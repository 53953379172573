<form (ngSubmit)="submit()" [formGroup]="appForm" autocomplete="off" id="applicationForm"
      [ngClass]="{submitted: !isDraft}">
  <div class="modal-header">
    <h2 class="modal-title text-center" id="modal-basic-title" style="font-size: 20px;">
      ROCKLAND COUNTY
      <br>
      <small>Department of Social Services</small>
    </h2>

    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pt-4">
    <span class="text-center d-block">
      COUNTY OF ROCKLAND NOTICE OF {{purchaseOrder?.period || '2024'}} FUNDING OPPORTUNITY
      <br>
      TO PROVIDE SERVICES/PROGRAMS TO COUNTY RESIDENTS
    </span>

    <div class="row mt-4" *ngIf="purchaseOrder?.rcdss_application_form?.status !== 'pending'">
      <div class="col-md-12">
        <div class="alert alert-danger" *ngIf="purchaseOrder?.rcdss_application_form?.status === 'rejected'">
          This application has been
          rejected. {{ purchaseOrder?.rcdss_application_form?.rejection_reason ? 'Reason: ' + purchaseOrder?.rcdss_application_form?.rejection_reason + '.' : '' }}
        </div>
        <div class="alert alert-success" *ngIf="purchaseOrder?.rcdss_application_form?.status === 'approved'">
          This application has been approved.
        </div>
      </div>
    </div>

    <div class="top-controls text-center mt-4 mb-1 px-4">
      <div class="row">
        <div class="col-auto m-auto">
          <mat-form-field class="w-auto">
            <mat-select name="selectedForm" [(ngModel)]="selectedForm"
                        [disabled]="purchaseOrder?.rcdss_application_form?.is_draft === false || companyType === 'customer'"
                        [ngModelOptions]="{standalone: true}" panelClass="menu-select-panel">
              <mat-option value="short">Short Form</mat-option>
              <mat-option value="long">Long Form</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <span class="font-weight-bold d-block pt-4">
        FOR ORGANIZATIONS SEEKING FUNDING $20,000 OR MORE, PICK THE "LONG FORM"
        <br>
        [ORGANIZATIONS SEEKING FUNDING UNDER $19,999, PICK THE "SHORT FORM"]
      </span>
    </div>

    <span class="text-center d-block pt-4">
      <i>
        This opportunity for a minimum one-year contract is a consideration from the County
        <br>
        (January 1, {{purchaseOrder?.period || '2024'}} – December 31, {{purchaseOrder?.period || '2024'}}) and is subject to availability of funds.
        <br>
        A contract in one year does not guarantee a contract in future years.
      </i>
    </span>

    <p class="text-justify pt-4">
      NOTABLE CHANGES FOR {{purchaseOrder?.period || '2024'}} CONTRACT FUNDING REQUESTS: At this time, please do not submit any attachments other than
      your completed Service Proposal and funding request. Funding provided by the County will be subject to the Office
      of Management and Budget’s Uniformed Guidance as detailed in the Code of Federal Regulations, Title 2, Grants and
      Agreements. All submissions must be developed on a price per unit of service basis rather than an annual, monthly
      cost or periodic date basis. Based on the Funding Application and the source of funding, the County will determine
      if a competitive procurement process is required. <u>Proposals that do not conform with this requirement and/or
      are incomplete will not be evaluated.</u>
      <br>
      <br>
      After your submission of a completed Service Proposal and funding application request, IF you are subsequently
      advised that the County will be offering a {{purchaseOrder?.period || '2024'}} contract to your agency, you will then be asked to furnish all
      appropriate documentation, as will be requested. *A separate portal opening date for this further documentation
      submission can be expected in November, {{purchaseOrder?.period === '2023' ? '2022' : '2023'}}.
      <br>
      <br>
      <strong style="color: red;">
        <!-- <u>PROPOSAL DUE DATE: 5:00PM ON FRIDAY, {{ purchaseOrder?.period === '2023' ? 'OCTOBER 1st, 2022': 'OCTOBER 20, 2023'}} ON THE DSS PORTAL ONLY. PROPOSALS WILL NOT BE
          ACCEPTED AFTER THIS TIME</u> -->
        <u>PROPOSAL DUE DATE: 5:00PM ON FRIDAY, OCTOBER 20, 2023 ON THE DSS PORTAL ONLY. PROPOSALS WILL NOT BE
          ACCEPTED AFTER THIS TIME</u>
      </strong>
    </p>

    <ul class="ul-check-bullet">
      <li>
        <u>AGAIN: At this time, please do not submit any attachments other than your completed Service Proposal and
          funding request.</u>
      </li>
    </ul>

    <ul>
      <li>
        PROPOSAL MUST BE COMPLETED USING THIS APPLICATION FORM. ENTER RESPONSES UNDER EACH SECTION. SECTIONS ARE
        EXPANDABLE.
      </li>
      <li>
        FOR YOUR CONVENIENCE, THIS APPLICATION HAS BEEN MODIFIED FROM PRIOR YEARS. THEREFORE, YOU ARE ENCOURAGED TO READ
        THROUGH THE ENTIRE APPLICATION IN ADVANCE, BEFORE YOU BEGIN TO ANSWER QUESTIONS.
      </li>
      <li>
        YOU WILL NOT BE ABLE TO SUBMIT INCOMPLETE APPLICATIONS, EVERY FIELD MUST BE POPULATED.
      </li>
      <li>
        RESPOND TO ALL QUESTIONS IN THE EXPANDABLE SPACE PROVIDED.
      </li>
      <li>
        ONLY APPLICATIONS SUBMITTED THROUGH THIS PORTAL WILL BE CONSIDERED
      </li>
      <li>
        THE FOCUS OF THIS DOCUMENT IS ON THE PROPOSED PROGRAM (NOT ON YOUR ORGANIZATION).
      </li>
      <li>
        SUBSTANTIVE QUESTIONS RELATING TO THIS DOCUMENT, SHOULD BE SUBMITTED THROUGH THE "MESSAGES" SECTION LOCATED ON
        THE CONTRACT PAGE
        <br>
        <br>
        FOR TECHNICAL ASSISTANCE RELATING TO THIS DOCUMENT, PLEASE EMAIL <a href="mailto:rcdss@ionsupply.com">rcdss@ionsupply.com</a>
        <br>
        <br>
        INQUIRIES BY EMAIL, TELEPHONE, FAX, ETC. TO ANY OTHER COUNTY OF ROCKLAND DEPARTMENT OR EMPLOYEE WILL NOT BE
        ACCEPTED OR ANSWERED.
        <br>
        <br>
        This funding request does not guarantee a contract and/or performance requirements may be modified by the
        County.
      </li>
    </ul>

    <p class="pt-4">
      Organizations submitting a proposal must meet the following requirements, as applicable:
    </p>
    <ul class="ul-check-bullet">
      <li>
        Serve Rockland County residents
      </li>
      <li>
        Be a 501(c)3
      </li>
      <li>
        Be governed by a Board of Directors
      </li>
      <li>
        Be up-to-date with all IRS filings
      </li>
      <li>
        Be up-to-date with all NYS filings
      </li>
      <li>
        Have a {{purchaseOrder?.period === '2023' ? '2022' : '2023'}} budget with Board approval
      </li>
      <li>
        Be fiscally sound
      </li>
      <li>
        Have a Non-discrimination Policy
      </li>
      <li>
        Have a Conflict of Interest Policy
      </li>
      <li>
        Maintain program records for the purpose of an audit and provide to the County all program-related information
        including proprietary materials, upon request
      </li>
      <li>
        Submit reports as required by the County Department monitoring the contract including year-end reports,
        attendance lists for each group activity, and log sheets for clients served
      </li>
      <li>
        Be available for on-site visits, announced and unannounced
      </li>
      <li>
        The proposal must sufficiently demonstrate how your organization will collaborate with RCDSS in providing
        services to individuals and families of Rockland County in order to protect the vulnerable Rockland County
        residents, empower them to achieve and maintain self-sufficiency, provide financial assistance, safety and
        support; with compassion, respect and dignity.
      </li>
    </ul>

    <div class="border my-4 font-weight-bold">
      <div class="row m-0">
        <div class="col-md-6 border-right pt-4">
          <label>County Department Managing {{purchaseOrder?.period || '2024'}} Contract:</label>
          <mat-form-field>
            <input type="text" matInput placeholder="" formControlName="county_department_managing" value="DSS" />
          </mat-form-field>

          <label>Organization/Provider Name:</label>
          <mat-form-field>
            <textarea placeholder="" rows="1" formControlName="field_1" matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="999"
            ></textarea>
            <mat-error
              *ngIf="appForm.controls.field_1.invalid && (appForm.controls.field_1.dirty || appForm.controls.field_1.touched)">
              This field is required
            </mat-error>
          </mat-form-field>

          <label>Organization/Provider Main Administrative Office Address: (must include street location):</label>
          <mat-form-field>
            <textarea placeholder="" rows="6" formControlName="field_2" matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="999"></textarea>
            <mat-error
              *ngIf="appForm.controls.field_2.invalid && (appForm.controls.field_2.dirty || appForm.controls.field_2.touched)">
              This field is required
            </mat-error>
          </mat-form-field>

          <label>Federal ID #:</label>
          <mat-form-field>
            <textarea placeholder="" rows="1" formControlName="field_3" matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="999"></textarea>
            <mat-error
              *ngIf="appForm.controls.field_3.invalid && (appForm.controls.field_3.dirty || appForm.controls.field_3.touched)">
              This field is required
            </mat-error>
          </mat-form-field>

          <label>Days and Hours of Proposed Program Operation:</label>
          <mat-form-field>
            <textarea placeholder="" rows="6" formControlName="field_4" matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="999"></textarea>
            <mat-error
              *ngIf="appForm.controls.field_4.invalid && (appForm.controls.field_4.dirty || appForm.controls.field_4.touched)">
              This field is required
            </mat-error>
          </mat-form-field>

          <hr>

          <span>Requested Funding Amount for Proposed Service/Program:</span>
          <br>
          <br>
          <!-- Acá solo debe poder seleccionarse 1 de los checkbox -->

          <mat-radio-group
            formControlName="funding_amount"
            color="primary" (change)="requestedFundingCheckboxSwitch($event)">
            <div class="row text-right">
              <div class="col-md-2">
                <mat-radio-button
                  name="same_amount"
                  value="same_amount"
                ></mat-radio-button>
              </div>
              <div class="col-md-6 font-weight-normal">
                Same amount as {{purchaseOrder?.period === '2023' ? '2022' : '2023'}} funding
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input type="number" min="0" matInput placeholder="$" formControlName="same_amount_value"
                         (change)="calculateTotalSources()" />
                  <mat-error
                    *ngIf="appForm.controls.same_amount_value.invalid && (appForm.controls.same_amount_value.dirty || appForm.controls.same_amount_value.touched)">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row text-right">
              <div class="col-md-2">
                <mat-radio-button
                  name="additional_funding"
                  value="additional_funding"
                ></mat-radio-button>
              </div>
              <div class="col-md-6 font-weight-normal">
                Additional funding sought
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input type="number" min="0" matInput placeholder="$" formControlName="additional_funding_value" />
                  <mat-error
                    *ngIf="appForm.controls.additional_funding_value.invalid && (appForm.controls.additional_funding_value.dirty || appForm.controls.additional_funding_value.touched)">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row text-right">
              <div class="col-md-2">
              </div>
              <div class="col-md-6 font-weight-normal">
                For a total contract amount of:
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input type="number" min="0" matInput placeholder="$"
                         formControlName="additional_funding_value_total" (change)="calculateTotalSources()" />
                  <mat-error
                    *ngIf="appForm.controls.additional_funding_value_total.invalid && (appForm.controls.additional_funding_value_total.dirty || appForm.controls.additional_funding_value_total.touched)">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 text-left">
                Note: The County will consider reasonable and modest requests for additional funding, provided that
                additional service deliverables are proposed.
              </div>
            </div>
          </mat-radio-group>
        </div>
        <div class="col-md-6 pt-4">
          <label>Title of Your Proposed Service/Program:</label>
          <mat-form-field>
            <textarea placeholder="" rows="1" formControlName="field_5" matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="999"></textarea>
            <mat-error
              *ngIf="appForm.controls.field_5.invalid && (appForm.controls.field_5.dirty || appForm.controls.field_5.touched)">
              This field is required
            </mat-error>
          </mat-form-field>

          <hr>

          <label>Individual Submitting Proposal:</label>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <textarea placeholder="Name" rows="1" formControlName="field_6" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_6.invalid && (appForm.controls.field_6.dirty || appForm.controls.field_6.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <textarea placeholder="Title" rows="1" formControlName="field_7" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_7.invalid && (appForm.controls.field_7.dirty || appForm.controls.field_7.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <textarea placeholder="Phone" rows="1" formControlName="field_8" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_8.invalid && (appForm.controls.field_8.dirty || appForm.controls.field_8.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <textarea placeholder="Fax" rows="1" formControlName="individual_submitting_fax" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.individual_submitting_fax.invalid && (appForm.controls.individual_submitting_fax.dirty || appForm.controls.individual_submitting_fax.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <textarea placeholder="Email" rows="1" formControlName="field_9" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_9.invalid && (appForm.controls.field_9.dirty || appForm.controls.field_9.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <hr>

          <label>Proposed Program Contact Person:</label>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <textarea placeholder="Name" rows="1" formControlName="field_10" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_10.invalid && (appForm.controls.field_10.dirty || appForm.controls.field_10.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <textarea placeholder="Title" rows="1" formControlName="field_11" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_11.invalid && (appForm.controls.field_11.dirty || appForm.controls.field_11.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <textarea placeholder="Phone" rows="1" formControlName="field_12" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_12.invalid && (appForm.controls.field_12.dirty || appForm.controls.field_12.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <textarea placeholder="Fax" rows="1" formControlName="proposed_program_fax" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.proposed_program_fax.invalid && (appForm.controls.proposed_program_fax.dirty || appForm.controls.proposed_program_fax.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <textarea placeholder="Email" rows="1" formControlName="field_13" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_13.invalid && (appForm.controls.field_13.dirty || appForm.controls.field_13.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <hr>

          <label>Proposed Contract Signatory:</label>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <textarea placeholder="Name" rows="1" formControlName="field_14" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_14.invalid && (appForm.controls.field_14.dirty || appForm.controls.field_14.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <textarea placeholder="Title" rows="1" formControlName="field_15" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_15.invalid && (appForm.controls.field_15.dirty || appForm.controls.field_15.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <textarea placeholder="Phone" rows="1" formControlName="field_16" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_16.invalid && (appForm.controls.field_16.dirty || appForm.controls.field_16.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <textarea placeholder="Fax" rows="1" formControlName="proposed_contract_fax" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.proposed_contract_fax.invalid && (appForm.controls.proposed_contract_fax.dirty || appForm.controls.proposed_contract_fax.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <textarea placeholder="Email" rows="1" formControlName="field_17" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="appForm.controls.field_17.invalid && (appForm.controls.field_17.dirty || appForm.controls.field_17.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- .border -->

    <p>
      <i>
        Note: Responses must contain accurate, current data and must reflect your most recent performance and financial
        records
      </i>
    </p>

    <p>
      <strong>PROPOSAL: PART I:</strong>
      <br>
      <br>
      <strong>
        SERVICE/PROGRAM DESCRIPTION: Please provide detailed and specific information for each section. Use as much
        space as necessary but be precise.
      </strong>
      <br>
      <br>
      <strong>
        SERVICE/PROGRAM SUMMARY
      </strong>
    </p>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          1.
        </span>
      </div>
      <div>
        <p>
          <strong>BRIEF SERVICES/PROGRAM DESCRIPTION OF NEED/OPPORTUNITY:</strong>
          <br>
          <br>
          Describe the need(s) for the service/program - be specific. Provide data and/or a needs assessment that
          identifies/supports the need. How does your program address and/or meet this need(s)?
        </p>
        <mat-form-field>
          <textarea placeholder="RESPONSE" rows="4" formControlName="field_18" matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="999"></textarea>
          <mat-error
            *ngIf="appForm.controls.field_18.invalid && (appForm.controls.field_18.dirty || appForm.controls.field_18.touched)">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          2.
        </span>
      </div>
      <div style="width: 100%;">
        <p>
          <strong>TARGET POPULATION:</strong>
          <br>
          <br>
          Describe:
        </p>
        <ul>
          <li>
            <p>Unduplicated number to be served in {{purchaseOrder?.period || '2024'}}.</p>
            <mat-form-field style="max-width: 200px;">
              <input type="number" min="0" matInput placeholder="" formControlName="unduplicated_number" />
              <mat-error
                *ngIf="appForm.controls.unduplicated_number.invalid && (appForm.controls.unduplicated_number.dirty || appForm.controls.unduplicated_number.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </li>
          <li>
            <p>Specify if:</p>
            <div class="d-flex d-row mb-4">
              <div class="px-4">
                <mat-checkbox name="population_individuals" formControlName="population_individuals"
                              color="primary"></mat-checkbox>
                <span>Individuals</span>
              </div>
              <div class="px-4">
                <mat-checkbox name="population_family" formControlName="population_family"
                              color="primary"></mat-checkbox>
                <span>Family</span>
              </div>
            </div>
          </li>
          <li>
            <p>Demographic breakdown (age, gender, and location)</p>
            <mat-form-field>
          <textarea placeholder="RESPONSE"
                    rows="4" formControlName="field_19" matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="appForm.controls.field_19.invalid && (appForm.controls.field_19.dirty || appForm.controls.field_19.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </li>
        </ul>
      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          3.
        </span>
      </div>
      <div style="width: 100%;">
        <p>
          <strong>QUALIFICATIONS:</strong>
        </p>
        <ol type="a">
          <li>What makes your organization uniquely qualified to provide this type of program/services?</li>
          <li>Are there any barriers to working with the target population that your organization is uniquely
            appropriate/prepared to address? How does your organization plan to overcome these barriers?
          </li>
        </ol>
        <mat-form-field>
          <textarea placeholder="RESPONSE"
                    rows="4" formControlName="field_20" matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="999"></textarea>
          <mat-error
            *ngIf="appForm.controls.field_20.invalid && (appForm.controls.field_20.dirty || appForm.controls.field_20.touched)">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          4.
        </span>
      </div>
      <div style="width: 100%;">
        <p>
          <strong>ELIGIBILITY REQUIREMENTS:</strong>
          <br>
          <br>
          <span>Does your proposed program have any eligibility requirements?  If yes, complete boxes below and <u>explain how eligibility will be verified.</u></span>
        </p>

        <div class="row border text-center">
          <div class="col-md-4 border-right py-2">
            <strong>
              Eligibility Requirements
              <br>
              (check, as applicable)
            </strong>
          </div>
          <div class="col-md-8 py-2">
            <strong>
              List Process for Verifying Eligibility
            </strong>
          </div>
        </div>
        <div class="row border">
          <div class="col-md-4 border-right pt-2">
            <mat-checkbox name="rockland_county_resident" formControlName="rockland_county_resident" color="primary"
                          class="mr-2" (change)="eligibilityRequirements($event)"></mat-checkbox>
            Rockland County Resident
          </div>
          <div class="col-md-8 pt-2">
            <mat-form-field>
              <textarea placeholder=""
                        rows="4" formControlName="rockland_county_resident_value" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999">
              </textarea>
              <mat-error
                *ngIf="appForm.controls.rockland_county_resident_value.invalid &&
                (appForm.controls.rockland_county_resident_value.dirty || appForm.controls.rockland_county_resident_value.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row border">
          <div class="col-md-4 pt-2 border-right">
            <mat-checkbox name="low_income" formControlName="low_income" color="primary" class="mr-2"
                          (change)="eligibilityRequirements($event)"></mat-checkbox>
            Low Income
          </div>
          <div class="col-md-8 pt-2">
            <mat-form-field>
              <textarea placeholder=""
                        rows="4" formControlName="low_income_value" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="appForm.controls.low_income_value.invalid && (appForm.controls.low_income_value.dirty || appForm.controls.low_income_value.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div formArrayName="additional_eligibility_requirements">
          <div class="row border eligibility-requirement pt-4"
               [formGroup]="item"
               *ngFor="let item of (appForm.get('additional_eligibility_requirements')['controls']); let index = index">
            <div class="col-md-4 pt-2 border-right">
              <mat-form-field>
                <textarea placeholder="" rows="1" formControlName="field_name" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="item.controls.field_name.invalid && (item.controls.field_name.dirty || item.controls.field_name.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-8 pt-2 pr-4">
              <mat-form-field>
                <textarea placeholder=""
                          rows="4" formControlName="field_value" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="item.controls.field_value.invalid && (item.controls.field_value.dirty || item.controls.field_value.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>

            <a (click)="removeEligibilityRequirement(index)"
               title="Remove Eligibility Requirement"
               class="btn-link remove-item text-danger">
              Remove <i class="fa-times"></i>
            </a>
          </div>
        </div>

        <div class="row p-4" *ngIf="isDraft">
          <div class="col-md-12 text-right">
            <button type="button"
                    class="btn btn-success" style="width: fit-content;"
                    (click)="addEligibilityRequirement()">
              <strong>+</strong> Add Eligibility Requirement
            </button>
          </div>
        </div>

      </div>
    </div>


    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          5.
        </span>
      </div>
      <div style="width: 100%;" formArrayName="services_deliverables">
        <p>
          <strong>SERVICE(S) DELIVERABLES:</strong>
          <br>
          <br>
          Respond to each section below for each service deliverable.
          <br>
          <br>
          <span style="color: red;">
            <strong>NOTE: FOR ANY FUNDING REQUESTS IN EXCESS OF {{purchaseOrder?.period === '2023' ? '2022' : '2023'}} CONTRACT AMOUNT, AN ADDED SERVICE DELIVERABLE IS REQUIRED.</strong>
          </span>
        </p>
        <div class="row mt-4 p-4 border bg-light service-deliverable"
             [formGroup]="item"
             *ngFor="let item of (appForm.get('services_deliverables')['controls']); let index = index">
          <div class="col-md-12">
            <div class="row">
              <div class="col">
                <h5>SERVICE DELIVERABLE {{ index + 1 }}</h5>
                <hr>
              </div>
              <a (click)="removeServiceDeliverables(index)"
                 *ngIf="index !== 0"
                 title="Remove Service Deliverable"
                 class="btn-link remove-item text-danger">
                Remove <i class="fa-times"></i>
              </a>
            </div>
          </div>
          <hr>
          <div class="col-md-12">
            <p>Type (i.e.: Case Management, Employment Services, Information and Referrals, etc)</p>
            <mat-form-field>
                  <textarea placeholder="" formControlName="deliverable_type" matInput
                            cdkTextareaAutosize
                            maxlength="30"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="999">
                  </textarea>
              <mat-error
                *ngIf="item.controls.deliverable_type.invalid && (item.controls.deliverable_type.dirty || item.controls.deliverable_type.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <p>
              Program Service Description
            </p>
            <mat-form-field>
              <textarea placeholder="" rows="4" formControlName="deliverable_field_1" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="item.controls.deliverable_field_1.invalid && (item.controls.deliverable_field_1.dirty || item.controls.deliverable_field_1.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <p>
              How will above services be delivered (delivery model / modality; reference best practice or evidence-based
              model being replicated)
            </p>
            <mat-form-field>
              <textarea placeholder="" rows="4" formControlName="deliverable_field_2" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="item.controls.deliverable_field_2.invalid && (item.controls.deliverable_field_2.dirty || item.controls.deliverable_field_2.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <p>
              Indicate how this approach addresses the need(s)
            </p>
            <mat-form-field>
              <textarea placeholder="" rows="4" formControlName="deliverable_field_3" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="item.controls.deliverable_field_3.invalid && (item.controls.deliverable_field_3.dirty || item.controls.deliverable_field_3.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <p>
              Explain your Agency’s Emergency Response Protocol, to ensure no / minimal disruption of service (ie due to
              a pandemic, extreme weather, etc.)
            </p>
            <mat-form-field>
              <textarea placeholder="" rows="4" formControlName="deliverable_field_4" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="item.controls.deliverable_field_4.invalid && (item.controls.deliverable_field_4.dirty || item.controls.deliverable_field_4.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <p>
              List all employees (name, title, contact information) who will provide the services listed
            </p>
            <mat-form-field>
              <textarea placeholder="" rows="4" formControlName="deliverable_field_5" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="item.controls.deliverable_field_5.invalid && (item.controls.deliverable_field_5.dirty || item.controls.deliverable_field_5.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row p-4" *ngIf="isDraft">
          <div class="col-md-12 text-right">
            <button type="button"
                    class="btn btn-success" style="width: fit-content;"
                    (click)="addServiceDeliverables()">
              <strong>+</strong> Add Service Deliverable
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          6.
        </span>
      </div>
      <div style="width: 100%;">
        <p>
          <strong>VOLUNTEERS:</strong>
          <br>
          <br>
          Do you intend to use volunteer staff to provide direct services for this proposed program? If yes, please
          specify. Describe your organization’s screening process for volunteers.
        </p>
        <mat-form-field>
          <textarea placeholder="RESPONSE"
                    rows="4" formControlName="field_21" matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="999"></textarea>
          <mat-error
            *ngIf="appForm.controls.field_21.invalid && (appForm.controls.field_21.dirty || appForm.controls.field_21.touched)">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          7.
        </span>
      </div>
      <div style="width: 100%;">
        <p>
          <strong>COLLABORATION WITH ROCKLAND COUNTY DEPARTMENT OF SOCIAL SERVICES (RCDSS).</strong>
        </p>

        <div class="d-flex flex-row">
          <div>
            <span class="p-4">
              A.
            </span>
          </div>
          <div style="width: 100%;">
            <p>
              Explain how you will maintain a collaborative relationship with and refer individuals / families
              to the RCDSS in order to ensure that those served receive complete care.
            </p>
            <mat-form-field>
              <textarea placeholder="" rows="4" formControlName="field_22" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="appForm.controls.field_22.invalid && (appForm.controls.field_22.dirty || appForm.controls.field_22.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div>
            <span class="p-4">
              B.
            </span>
          </div>
          <div style="width: 100%;">
            <p>
              How do you propose to follow up to ensure someone referred to RCDSS was in contact with RCDSS? Ex.
              If someone is receiving assistance in completing an application, what is your Agency’s procedure for
              following up to ensure application was submitted or the client was contacted / received the
              service.
            </p>
            <mat-form-field>
              <textarea placeholder="" rows="4" formControlName="field_23" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="appForm.controls.field_23.invalid && (appForm.controls.field_23.dirty || appForm.controls.field_23.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div>
            <span class="p-4">
              C.
            </span>
          </div>
          <div style="width: 100%;">
            <p>
              Do you have any program-enhancing collaborations that are part of the program for which you are
              requesting funding?
            </p>
            <mat-form-field>
              <textarea placeholder="" rows="4" formControlName="field_24" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="appForm.controls.field_24.invalid && (appForm.controls.field_24.dirty || appForm.controls.field_24.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          8.
        </span>
      </div>
      <div style="width: 100%;">
        <p>
          <strong>If program services will be provided at any other location other than the address listed on the cover
            page, please identify:</strong>
        </p>

        <div class="container-fluid border" formArrayName="program_services">
          <div class="row py-2 border-bottom">
            <div class="col-md-1">
              <strong>
                Site
              </strong>
            </div>
            <div class="col-md-5">
              <strong>
                Name & Address of Site
              </strong>
            </div>
            <div class="col-md-3">
              <strong>
                Day(s)
              </strong>
            </div>
            <div class="col-md-3">
              <strong>
                Time (from____ to____)
              </strong>
            </div>
          </div>

          <div class="row pt-4 border-bottom program-service px-2"
               [formGroup]="item"
               *ngFor="let item of (appForm.get('program_services')['controls']); let index = index">
            <div class="col-md-1">
              {{ index + 1 }}
            </div>
            <div class="col-md-5">
              <mat-form-field>
              <textarea placeholder="" rows="1" formControlName="program_service_field_1" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"
              ></textarea>
                <mat-error
                  *ngIf="item.controls.program_service_field_1.invalid && (item.controls.program_service_field_1.dirty || item.controls.program_service_field_1.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
              <textarea placeholder="" rows="1" formControlName="program_service_field_2" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="item.controls.program_service_field_2.invalid && (item.controls.program_service_field_2.dirty || item.controls.program_service_field_2.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <textarea placeholder="" rows="1" formControlName="program_service_field_3" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                <mat-error
                  *ngIf="item.controls.program_service_field_3.invalid && (item.controls.program_service_field_3.dirty || item.controls.program_service_field_3.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>

            <a (click)="removeProgramService(index)"
               *ngIf="index !== 0"
               title="Remove Program Service"
               class="btn-link remove-item text-danger">
              Remove <i class="fa-times"></i>
            </a>
          </div>

          <div class="row text-right" *ngIf="isDraft">
            <div class="col py-2">
              <button type="button"
                      class="btn btn-success" style="width: fit-content;"
                      (click)="addProgramServices()">
                <strong>+</strong> Add Location
              </button>
            </div>
          </div>
        </div>

        <div class="row p-4">
          <div class="col-md-6">
            Use this space to explain possible ad hoc sites that may be used for this program, e.g. libraries, schools,
            etc.
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <textarea placeholder=""
                        rows="1"
                        formControlName="possible_ad_hoc_sites"
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="appForm.controls.possible_ad_hoc_sites.invalid && (appForm.controls.possible_ad_hoc_sites.dirty || appForm.controls.possible_ad_hoc_sites.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>

        </div>

      </div>
    </div>

    <div class="d-flex flex-row">
      <div>
        <span class="p-4">
          9.
        </span>
      </div>
      <div style="width: 100%;" formArrayName="program_goals">
        <p>
          <strong>PROGRAM GOALS:</strong>
          <br>
          <br>
          Briefly summarize the specific goals and anticipated outcomes for this program <u>for each of the deliverables
          listed in Section 5 above.</u>
        </p>

        <!-- ngFor para cada SERVICE DELIVERABLE: -->
        <div class="row border bg-light my-2 p-2"
             [formGroup]="item"
             *ngFor="let item of (appForm.get('program_goals')['controls']); let index = index">
          <div class="col-md-3">
            <span>
              SERVICE DELIVERABLE {{ index + 1 }}
              <span
                *ngIf="(appForm.get('services_deliverables')['controls'][index]['controls'].deliverable_type.value)">
                &nbsp;-&nbsp;{{(appForm.get('services_deliverables')['controls'][index]['controls'].deliverable_type.value)}}
              </span>
            </span>
          </div>
          <div class="col-md-9">
            <mat-form-field>
              <textarea placeholder="" rows="2" formControlName="field_value_1" matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="999"></textarea>
              <mat-error
                *ngIf="item.controls.field_value_1.invalid && (item.controls.field_value_1.dirty || item.controls.field_value_1.touched)">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          10.
        </span>
      </div>
      <div style="width: 100%;">
        <p>
          <strong>DIRECTIONS FOR PERFORMANCE OUTCOMES, INDICATORS AND TARGETS: What will you measure and monitor to know
            if consumers have achieved the desired outcome(s) for each service deliverable outlined in Section 5,
            hereinabove?</strong>
          <br>
          <br>
          <strong>**NOTE: There must be a Performance Outcomes for each service deliverable outlined in Section
            5.</strong>
        </p>
        <ul>
          <li>
            <strong>Performance Outcomes</strong> are <strong>benefits or changes</strong> for participants during or
            after participating in service/program activities. Outcomes are <strong>based on service/program
            goals.</strong>
          </li>
          <li>
            <strong>Outcome Indicators</strong> are the <strong>specific items of data</strong> that you will <strong>measure
            and monitor</strong> to track how well the service/program is succeeding on reaching identified outcomes.
            The data should tell you if participants have achieved the desired outcomes.
          </li>
          <li>
            <strong>Outcome Targets</strong> are either of the following: <strong>percentage, rate or number of
            participants</strong> expected to achieve the desired outcomes, or the <strong>amount of change</strong>
            participants are expected to experience.
          </li>
        </ul>
        <p class="text-center font-weight-bold" style="padding: 10px 10%;">
          <u>
            NOTE: THE FOLLOWING IS AN EXAMPLE ONLY. APPLICANT SHOULD USE ITS OWN JUDGEMENT
            IN DEVELOPING PROPOSAL-RELATED PERFORMANCE OUTCOMES, OUTCOME INDICATORS, TARGETS, ETC
            <br>
            <br>
            PERFORMANCE OUTCOME: <span class="pl-4" style="font-size: 20px;">EXAMPLE ONLY</span>
          </u>
        </p>

        <div>
          <div class="row mt-4 border border-dark py-2" style="background-color: #e2e2e2;">
            <div class="col-md-6 border-right border-dark">

            </div>
            <div class="col-md-6 py-2">
              Sample
            </div>
          </div>
          <div class="row border border-dark" style="background-color: #e2e2e2;">
            <div class="col-md-6 border-right border-dark py-3">
              <strong>Projected Performance Outcome:</strong>
              <br>
              <strong>Benefit or change for participants</strong> during or after participating in service/program
              activities. Should be based on service/program goals.
            </div>
            <div class="col-md-6 pt-3">
              <mat-form-field>
                <textarea placeholder=""
                          rows="3"
                          matInput
                          value="Recipient will comply with the conditions of apartment’s lease and maintain permanent, independent housing."
                          disabled></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row border border-dark" style="background-color: #e2e2e2;">
            <div class="col-md-6 border-right border-dark py-3">
              <strong>Outcome Indicator & Target</strong>
              <br>
              <br>
              The <strong>Outcome Indicator</strong> is the <strong>specific item of data to be measured and
              monitored</strong> to track how well the program is succeeding on this outcome. <strong>The data should
              tell you if participants have achieved the desired outcome</strong> within the specified timeframe
              <strong>(at least semiannually)</strong>.

              <br>
              <br>
              The <strong>Outcome Target</strong> is the <strong>percentage, rate or number of participants expected to
              achieve</strong> this outcome, or the <strong>amount of change</strong> participants are <strong>expected
              to experience quarterly</strong>.
            </div>
            <div class="col-md-6 pt-3">
              <mat-form-field>
                <textarea placeholder=""
                          rows="3" matInput
                          value="90% of leases will be renewed upon expiration"
                          disabled></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row border border-dark" style="background-color: #e2e2e2;">
            <div class="col-md-6 border-right border-dark py-3">
              <strong>Data Collection Method: (will be due to County Department as required)</strong>
              <br>
              How will data be collected to measure and monitor the outcome indicator.
              <br>
              <strong>Attach data collection instrument(s).</strong>

            </div>
            <div class="col-md-6 pt-3">
              <mat-form-field>
                <textarea placeholder=""
                          rows="5" matInput
                          value="Data will be collected based on the individual terms of the leases (i.e. month to month, semiannual or annual) and charted as leases expire."
                          disabled>
                </textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- ngFor: 1 por cada SERVICE DELIVERABLE ... validar que se provean por lo menos 2, y mantener los indices -->
        <div formArrayName="performance_outcomes">
          <div
            [formGroup]="item"
            *ngFor="let item of (appForm.get('performance_outcomes')['controls']); let index = index">
            <div class="row mt-4 py-2">
              <div class="col-md-12 font-weight-bold text-center">
                <span class="text-underline font-weight-bold">
                  PERFORMANCE OUTCOME: SERVICE DELIVERABLE #{{ index + 1 }}
                  <span
                    *ngIf="(appForm.get('services_deliverables')['controls'][index]['controls'].deliverable_type.value)">
                   &nbsp;-&nbsp;{{(appForm.get('services_deliverables')['controls'][index]['controls'].deliverable_type.value)}}
                  </span>
                </span>
              </div>
            </div>
            <div class="row border border-dark bg-light">
              <div class="col-md-6 border-right border-dark py-3">
                <strong>Projected Performance Outcome:</strong>
                <br>
                <strong>Benefit or change for participants</strong> during or after participating in service/program
                activities. Should be based on service/program goals.
              </div>
              <div class="col-md-6 pt-3">
                <mat-form-field>
                <textarea placeholder="" rows="4" formControlName="performance_outcome_field_1" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                  <mat-error
                    *ngIf="item.controls.performance_outcome_field_1.invalid && (item.controls.performance_outcome_field_1.dirty || item.controls.performance_outcome_field_1.touched)">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row border border-dark bg-light">
              <div class="col-md-6 border-right border-dark py-3">
                <strong>Outcome Indicator & Target</strong>
                <br>
                <br>
                The <strong>Outcome Indicator</strong> is the <strong>specific item of data to be measured and
                monitored</strong> to track how well the program is succeeding on this outcome. <strong>The data should
                tell you if participants have achieved the desired outcome</strong> within the specified timeframe
                <strong>(at least semiannually)</strong>.

                <br>
                <br>
                The <strong>Outcome Target</strong> is the <strong>percentage, rate or number of participants expected
                to
                achieve</strong> this outcome, or the <strong>amount of change</strong> participants are <strong>expected
                to experience quarterly</strong>.
              </div>
              <div class="col-md-6 pt-3">
                <mat-form-field>
                <textarea placeholder="" rows="4" formControlName="performance_outcome_field_2" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                  <mat-error
                    *ngIf="item.controls.performance_outcome_field_2.invalid && (item.controls.performance_outcome_field_2.dirty || item.controls.performance_outcome_field_2.touched)">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row border border-dark bg-light">
              <div class="col-md-6 border-right border-dark py-3">
                <strong>Data Collection Method: (will be due to County Department as required)</strong>
                <br>
                How will data be collected to measure and monitor the outcome indicator.
                <br>
                <strong>Attach data collection instrument(s).</strong>

              </div>
              <div class="col-md-6 pt-3">
                <mat-form-field>
                <textarea placeholder="" rows="4" formControlName="performance_outcome_field_3" matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="999"></textarea>
                  <mat-error
                    *ngIf="item.controls.performance_outcome_field_3.invalid && (item.controls.performance_outcome_field_3.dirty || item.controls.performance_outcome_field_3.touched)">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <!-- 11 -->

    <div class="row">
      <div class="col-md-10 offset-md-1">
        <p class="font-weight-bold py-4">
          IF PERFORMANCE OUTCOMES ARE NOT MET, AND/OR REQUIRED REPORTS AND ANY REQUESTED DOCUMENTS ARE NOT SUBMITTED
          WHEN DUE, VOUCHER PROCESSING MAY BE DELAYED AND/OR A FINANCIAL PENALTY MAY BE DEDUCTED FROM THE CONTRACT
          AMOUNT
        </p>
      </div>
      <div class="col-md-12">
        <p>
          <strong><u>BUDGET PROPOSAL: PART II:</u></strong>
          <br>
          <br>
          <strong>
            BEFORE DEVELOPING BUDGET, PLEASE READ BELOW AND THE NOTES AT END OF FORM:
          </strong>
        </p>
        <ul>
          <li>
            <strong class="text-danger">FOR ANY FUNDING REQUESTS IN EXCESS OF {{purchaseOrder?.period === '2023' ? '2022' : '2023'}} CONTRACT AMOUNT, AN ADDED SERVICE
              DELIVERABLE IS REQUIRED.</strong>
          </li>
          <li>
            <strong>This request does not guarantee a formal {{purchaseOrder?.period || '2024'}} contract award. If awarded, the actual contract amount
              for {{purchaseOrder?.period || '2024'}} is subject to approval by the County Legislature.</strong>
          </li>
          <li>
            <strong>The budget must be developed on a “price per unit of service” basis rather than an annual, monthly
              cost or periodic date basis; <span
                class="bg-warning">specific breakdowns should be provided</span></strong>
          </li>
          <li>
            <strong><u>Reimbursement is subject to full compliance with all contract deliverables.</u></strong>
          </li>
        </ul>
      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <div>
        <span class="p-4">
          1.
        </span>
      </div>
      <div style="width: 100%;">

        <div class="row mt-4">
          <div class="col-md-7 offset-md-1 py-2 border bg-light py-2">
            <strong>
              SERVICE DELIVERABLES
            </strong>
          </div>
          <div class="col-md-2 border bg-light py-2">
            <strong>
              COST PER UNIT
              <br>
              OF SERVICE
            </strong>
          </div>
        </div>
        <!-- ngFor: 1 por cada Service Deliverable - validar que se llene minimo 1 - ocultar los que no tengan valor de la vista de impresión -->
        <div formArrayName="budget_proposal">
          <div class="row"
               [formGroup]="item"
               *ngFor="let item of (appForm.get('budget_proposal')['controls']); let index = index">
            <div class="col-md-7 offset-md-1 border bg-light py-2">
              SERVICE DELIVERABLE {{ index + 1 }}
              <span
                *ngIf="(appForm.get('services_deliverables')['controls'][index]['controls'].deliverable_type.value)">
                &nbsp;-&nbsp;{{(appForm.get('services_deliverables')['controls'][index]['controls'].deliverable_type.value)}}
              </span>
            </div>
            <div class="col-md-2 border bg-light py-2">
              <mat-form-field>
                <input type="number" min="0" matInput placeholder="$" formControlName="budget_proposal_field_1"
                       (change)="calculateTotalAmountRequested()" />
                <mat-error
                  *ngIf="item.controls.budget_proposal_field_1.invalid && (item.controls.budget_proposal_field_1.dirty || item.controls.budget_proposal_field_1.touched)">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-7 offset-md-1 border bg-light py-2 text-right">
            <strong>
              TOTAL AMOUNT REQUESTED
            </strong>
          </div>
          <div class="col-md-2 border bg-light py-2">
            <!-- calcular este campo como la sumatoria de los anteriores -->
            <mat-form-field>
              <input type="number" matInput placeholder="$"
                     formControlName="service_deliverable_total_amount_requested" />
            </mat-form-field>
            <mat-error *ngIf="appForm.controls.service_deliverable_total_amount_requested.invalid ||
                (
                appForm.errors?.requiredDisabledFields &&
                !appForm.controls.service_deliverable_total_amount_requested.value
                )">At least one value is required
            </mat-error>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 offset-md-1">
            <p class="my-4">
              <i>* Service unit costs: total expense from above divided by number of service units [i.e.: number of
                people
                served; number of meals delivered; number of training sessions provided; price per advertisement, flyer,
                etc.]</i>
            </p>
            <ul class="ul-check-bullet">
              <li><strong class="pl-2">TOTAL EXPENSES ABOVE / SERVICE UNITS = COST PER UNIT OF SERVICES</strong></li>
            </ul>
          </div>
        </div>
      </div>

    </div>


    <div class="d-flex flex-row mt-4">
      <div>
        <span class="px-4 d-block" style="width: max-content;">
          2. a.
        </span>
      </div>
      <div style="width: 100%;">
        <p>
          Will this specific proposed program be funded from different/additional sources?
        </p>

        <div class="row">
          <div class="col">
            <mat-radio-group color="primary" formControlName="proposed_program_funded">
              <mat-radio-button [value]="false">No</mat-radio-button>
              <mat-radio-button [value]="true">Yes</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row mt-4" *ngIf="appForm.get('proposed_program_funded').value === true">
      <div>
        <span class="px-4 d-block" style="width: max-content;">
          2. b.
        </span>
      </div>
      <div style="width: 100%;">
        <div>
          <p>
            State what amount of this program is funded through the following sources. Do not include in-kind
            donations/services, use of facilities, etc.
          </p>
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="row my-4">
                <div class="col-md-10 offset-md-1">
                  <div class="alert alert-warning" *ngIf="getFundingAmount() === 0">
                    Please fill the section named "Requested Funding Amount for Proposed Service/Program" at the top of
                    this form before continuing with the table below.
                  </div>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-8">
                </div>
                <div class="col-md-2">
                  <strong>
                    Amount
                  </strong>
                </div>
                <div class="col-md-2">
                  <strong>
                    Percentage
                  </strong>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>a.</strong>
                </div>
                <div class="col-md-7">
                  Fundraising proceeds
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_25"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_25.invalid && (appForm.controls.field_25.dirty || appForm.controls.field_25.touched)">
                      <span *ngIf="appForm.controls.field_25.errors.min || appForm.controls.field_25.errors.max">
                        Value not allowed
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_25_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>b.</strong>
                </div>
                <div class="col-md-7">
                  School district
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_26"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_26.invalid && (appForm.controls.field_26.dirty || appForm.controls.field_26.touched)">
                      <span *ngIf="appForm.controls.field_26.errors.min || appForm.controls.field_26.errors.max">
                        Value not allowed
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_26_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>c.</strong>
                </div>
                <div class="col-md-7">
                  County funds or grants
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_27"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_27.invalid && (appForm.controls.field_27.dirty || appForm.controls.field_27.touched)">
                      <span *ngIf="appForm.controls.field_27.errors.min || appForm.controls.field_27.errors.max">
                        Value not allowed
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_27_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>d.</strong>
                </div>
                <div class="col-md-7">
                  Other County department funds or grants
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_28"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_28.invalid && (appForm.controls.field_28.dirty || appForm.controls.field_28.touched)">
                      <span *ngIf="appForm.controls.field_27.errors.min || appForm.controls.field_28.errors.max">
                        Value not allowed
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_28_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>e.</strong>
                </div>
                <div class="col-md-7">
                  State funds or grants
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_29"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_29.invalid && (appForm.controls.field_29.dirty || appForm.controls.field_29.touched)">
                      <span *ngIf="appForm.controls.field_29.errors.min || appForm.controls.field_29.errors.max">
                        Value not allowed
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_29_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>f.</strong>
                </div>
                <div class="col-md-7">
                  Federal funds or grants
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_30"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_30.invalid && (appForm.controls.field_30.dirty || appForm.controls.field_30.touched)">
                      <span *ngIf="appForm.controls.field_30.errors.min || appForm.controls.field_30.errors.max">
                        Value not allowed
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_30_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>g.</strong>
                </div>
                <div class="col-md-7">
                  Municipality funds or grants
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_31"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_31.invalid && (appForm.controls.field_31.dirty || appForm.controls.field_31.touched)">
                      <span *ngIf="appForm.controls.field_31.errors.min || appForm.controls.field_31.errors.max">
                        Value not allowed
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_31_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>h.</strong>
                </div>
                <div class="col-md-7">
                  Funds from other community agencies
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_32"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_32.invalid && (appForm.controls.field_32.dirty || appForm.controls.field_32.touched)">
                      <span *ngIf="appForm.controls.field_32.errors.min || appForm.controls.field_32.errors.max">
                        Value not allowed
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_32_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-1">
                  <strong>i.</strong>
                </div>
                <div class="col-md-7">
                  <div class="d-flex flex-row">
                    <span class="d-block pr-3">Other (specify)</span>
                    <mat-form-field>
                    <textarea placeholder="" rows="1" formControlName="field_33" matInput
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="999"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" max="{{ getFundingAmount() }}" min="0" matInput placeholder="$"
                           formControlName="field_34"
                           (change)="calculateTotalSources()" />
                    <mat-error
                      *ngIf="appForm.controls.field_34.invalid && (appForm.controls.field_34.dirty || appForm.controls.field_34.touched)">
                      <span *ngIf="appForm.controls.field_34.errors.required">This field is required</span>
                      <span *ngIf="appForm.controls.field_34.errors.min || appForm.controls.field_34.errors.max">Value not allowed</span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <input type="number" matInput placeholder="%" formControlName="field_33_p" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row p-2 border bg-light">
                <div class="col-md-8 text-right">
                  TOTAL
                </div>
                <div class="col-md-2">
                  <mat-form-field class="mb-0" style="margin-bottom: 0 !important;">
                    <input type="number" min="0" max="100" matInput placeholder="$" formControlName="total_sources" />
                    <mat-error
                      *ngIf="appForm.controls.total_sources.errors?.max">
                      The summation of all values is greater than 100%
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="mb-0" style="margin-bottom: 0 !important;">
                    <input type="number" matInput placeholder="%" formControlName="total_sources_p" />
                  </mat-form-field>
                </div>
                <div class="col-12" *ngIf="(!appForm.controls.total_sources.value)">
                  <div class="text-right">
                    <mat-error>
                      At least one value is required
                    </mat-error>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <p class="my-4">
            <u>NOTE: Payments for the work to be performed as described in the corresponding Service/Program Proposal
              shall not duplicate payments for the same work performed or to be performed under other agreements between
              the Agency and other funding sources including Rockland County.</u>
          </p>

        </div>

      </div>
    </div>

    <div class="row" *ngIf="purchaseOrder?.rcdss_application_form?.status !== 'pending'">
      <div class="col-md-12">
        <div class="alert alert-danger" *ngIf="purchaseOrder?.rcdss_application_form?.status === 'rejected'">
          This application has been
          rejected. {{ purchaseOrder?.rcdss_application_form?.rejection_reason ? 'Reason: ' + purchaseOrder?.rcdss_application_form?.rejection_reason + '.' : '' }}
        </div>
        <div class="alert alert-success" *ngIf="purchaseOrder?.rcdss_application_form?.status === 'approved'">
          This application has been approved.
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" id="buttons">
    <button type="button" class="btn btn-danger" (click)="cancel()"
            [ngClass]="{
        'btn-danger': companyType === 'supplier' && isDraft,
        'btn-secondary': companyType === 'customer' || !isDraft
      }"
    >
      {{ companyType === 'supplier' && isDraft ? 'Cancel' : 'Close' }}
    </button>
    <button *ngIf="companyType === 'customer'" type="button" class="btn btn-info" (click)="print()">
      Print
    </button>

    <button
      *ngIf="companyType === 'supplier' && (!purchaseOrder?.rcdss_application_form || isDraft)"
      type="button" class="btn btn-secondary" (click)="saveDraft()">
      Save Draft
    </button>
    <button
      *ngIf="companyType === 'supplier' && (!purchaseOrder?.rcdss_application_form || isDraft)"
      type="button" class="btn btn-success" (click)="submit()">
      Submit
    </button>

    <button
      *ngIf="companyType === 'customer' && purchaseOrder?.rcdss_application_form?.status === 'pending' && !isDraft"
      type="button" class="btn btn-success" (click)="underReview()" [disabled]="appForm.invalid">
      Under Review
    </button>

    <button
      *ngIf="companyType === 'customer' && purchaseOrder?.rcdss_application_form?.status === 'closed' && !isDraft"
      type="button" class="btn btn-danger" (click)="reject()">
      Reject
    </button>
    <button
      *ngIf="companyType === 'customer' && purchaseOrder?.rcdss_application_form?.status === 'closed' && !isDraft"
      type="button" class="btn btn-success" (click)="approve()" [disabled]="appForm.invalid">
      Approve
    </button>
  </div>
</form>

<div #printApplicationForm>
  <ngx-print-rcdss-application-form
    *ngIf="printing"
    [purchaseOrder]="purchaseOrder"
    [companyType]="companyType"
  ></ngx-print-rcdss-application-form>
</div>
