import { LineItem } from '@models/line-item.model';
import { FileTypePayload, LasgroPurchase_order, LasgroPurchase_order_comments, LasgroShipment_tracking, PurchaseOrderInputPayload, TaskResponsiblesInputPayload } from 'api/models';
import { Action } from './action.model';
import { FileItemDto } from './file-item.model';
import { Invoice, InvoiceFile } from './invoice.model';

export class ContractDto implements PurchaseOrderInputPayload, LasgroPurchase_order {
  file_types: FileTypePayload[];
  cancel_date: any;
  term_date_start: any;
  term_date_end: any;
  contract_type: string;
  customer_id: string;
  customer_name: string;
  contract_info?: string;
  customerContactName: string;
  customerAddress: string;
  customer_po_number: string;
  po_number_supplier_name: string;
  customer_priority: 1|2|3;
  created_at: any;
  href: string;
  id: string;
  messages: any;
  comments?: Array<LasgroPurchase_order_comments>;
  order_date: any;
  sales_order_number: string;
  ship_date: any;
  status: "open" | "closed" | "archived" | "rejected" | "pending" | "qb-imported" | "approved";
  supplier_id: string;
  supplier_name: string;
  updated_at: string;
  risk: any;
  total_completed: any;
  total_ordered: any;
  finished_goods_reports_period: "daily" | "weekly" | "biweekly" | "monthly" | "No report needed";
  email: string;
  address: string;
  phone: string;
  ID: number;
  lineItems: any;
  batches: any;
  line_items: LineItem[];
  report: any;
  is_following: boolean;
  report_start_date: any;
  terms: number;
  enabled_reporting: boolean;
  actions: Array<Action>;
  files?: Array<any | FileItemDto>;
  shipped_items_amount?: number;
  invoice?: Invoice;
  invoice_file?: InvoiceFile;
  invoice_status: string;

  reason: string;
  rejection_reason: string;
  reissuance_reason: string;
  is_draft: boolean;
  is_simple: boolean;
  proforma_invoice: Invoice;
  file_count: number;
  checklist_task_count: number;
  unread_messages: number;
  period: string;

  approver_id: string;
  subscribers: string[];
  copy_receivers: string[];

  rcdss_application_form: any;
  rcdss_panda_doc_contract_document: any;
  rcdss_panda_doc_notified_event: string;

  is_workflow_applied: boolean;

  constructor(data) {
    Object.assign(this, data);
  }
  percent_complete: number;
  percent_time_expired?: number;
  po_shipment_trackings?: LasgroShipment_tracking[];
  rcdss_panda_doc_notified_date?: string;
  rcdss_contract_program?: string;
  responsibles?: TaskResponsiblesInputPayload[];
  saved_checklist_id?: string;
  units_name: string;
  get isApproved() {
    return this.status === 'approved';
  }
  get isClosed() {
    return this.status === 'closed';
  }

  get isPending() {
    return this.status === 'pending';
  }

  get isRejected() {
    return this.status === 'rejected';
  }

  get isArchived() {
    return this.status === 'archived';
  }
}

export class PurchaseOrderSubscriberModel {

  organization_id: string;
  organization_name: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  phone_number: string;
  role: string;

  constructor(data) {
    Object.assign(this, data);
  }

  get fullName() {
    return `${ this.first_name } ${ this.last_name }`.trim();
  }
}
