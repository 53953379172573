<div class="filter-container">
  <ngx-pill-btn
    [title]="title"
    (onClick)="onFilterButtonClicked()"
    (onClear)="onClear.emit()"
    [showClearBtn]="showClearBtn">
  </ngx-pill-btn>

  <div id="filter-menu-dropdown" class="menu" [ngClass]="showDropdownMenu ? 'showMenu' : 'hideMenu'">
    <div class="content-container">
      <div class="content">
        <ng-content select="[content]"></ng-content>
      </div>
    </div>
    <div class="footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
