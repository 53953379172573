// Static values taken from the DB

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { map } from 'rxjs/operators';
import CONFIG from '@app/app.config';

@Injectable()
export class ReportService {

  constructor(
    private http: HttpClient,
  ) {
    this.http = http;
  }

  getAvailableResources(organizationId: string): any {
    const endpoint = `${ CONFIG.API }/organizations/${organizationId}/analytics/resources`;
    return this.http.get<any[]>(endpoint);
  }

  getReportsInDateRangeByResourceNameAndCompanyId(organizationId: string, from: string, to: string, resourceId: string): any {
    const endpoint = `${ CONFIG.API }/organizations/${organizationId}/analytics/resources/${resourceId}`;
    let p: any = {from: from, to: to};
    const params = new HttpParams({ fromObject: p });
    return this.http.get<any[]>(endpoint, { params });
  }

  getAveragePendingTimesInDateRangeByCompanyId(organizationId: string, from: string, to: string): any {
    const endpoint = `${ CONFIG.API }/organizations/${organizationId}/analytics/average-pending`;
    let p: any = {from: from, to: to};
    const params = new HttpParams({ fromObject: p });
    return this.http.get<any[]>(endpoint, { params });
  }

  openOrders(filters: any): Promise<any> {
    const endpoint = `${ CONFIG.API }/report/open_order`;

    const params = new HttpParams({
      fromObject: filters,
    });

    return this.http.get<any[]>(endpoint, { params })
      .pipe(map(result => {
        return result.map(o => {
          o.line_item_quantity_open = o.line_item_quantity_ordered - o.line_item_quantity_complete;
          return o;
        });
      }))
      .toPromise();
  }


  timeRanges() {

    const today = new Date();
    return [
      {
        name: 'Today',
        start: moment(today).format('YYYY-MM-DD'),
        end: moment(today).format('YYYY-MM-DD'),
        interval: 'daily',
      },
      {
        name: 'Yesterday',
        start: moment(today).subtract(1, 'd').format('YYYY-MM-DD'),
        end: moment(today).subtract(1, 'd').format('YYYY-MM-DD'),
        interval: 'daily',
      },
      {
        name: 'Last 7 days',
        end: moment(today).format('YYYY-MM-DD'),
        start: moment(today).subtract(7, 'd').format('YYYY-MM-DD'),
        interval: 'daily',
      },
      {
        name: 'Last 30 days',
        end: moment(today).format('YYYY-MM-DD'),
        start: moment(today).subtract(30, 'd').format('YYYY-MM-DD'),
        interval: 'daily',
      },
      {
        name: 'Last month',
        start: moment(today).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        end: moment(today).subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        interval: 'daily',
      },
      {
        name: 'Last 12 months',
        end: moment(today).format('YYYY-MM-DD'),
        start: moment(today).subtract(12, 'M').format('YYYY-MM-DD'),
        interval: 'monthly',
      },
      { name: 'Custom' },
    ];
  }
}
