/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroTask_notificationCollection } from '../models/lasgro-task-_notification-collection';
import { LasgroGeneral_success_id_payload } from '../models/lasgro-general-_success-_id-_payload';
import { TaskNotificationInputPayload } from '../models/task-notification-input-payload';
import { LasgroTask_notification_details } from '../models/lasgro-task-_notification-_details';
import { TaskNotificationUpdatePayload } from '../models/task-notification-update-payload';
@Injectable({
  providedIn: 'root',
})
class TaskNotificationService extends __BaseService {
  static readonly taskNotificationListPath = '/tasks/{taskID}/notifications';
  static readonly taskNotificationAddPath = '/tasks/{taskID}/notifications';
  static readonly taskNotificationShowPath = '/tasks/{taskID}/notifications/{notificationID}';
  static readonly taskNotificationUpdatePath = '/tasks/{taskID}/notifications/{notificationID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list task_notification
   *
   * List all of the task notifications
   *
   * Required security scopes:
   *   * `api:access`
   * @param taskID undefined
   * @return OK
   */
  taskNotificationListResponse(taskID: string): __Observable<__StrictHttpResponse<LasgroTask_notificationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tasks/${encodeURIComponent(String(taskID))}/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_notificationCollection>;
      })
    );
  }
  /**
   * list task_notification
   *
   * List all of the task notifications
   *
   * Required security scopes:
   *   * `api:access`
   * @param taskID undefined
   * @return OK
   */
  taskNotificationList(taskID: string): __Observable<LasgroTask_notificationCollection> {
    return this.taskNotificationListResponse(taskID).pipe(
      __map(_r => _r.body as LasgroTask_notificationCollection)
    );
  }

  /**
   * add task_notification
   *
   * Creates a task notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationService.TaskNotificationAddParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `payload`: Input for a task notification
   *
   * @return Created
   */
  taskNotificationAddResponse(params: TaskNotificationService.TaskNotificationAddParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tasks/${encodeURIComponent(String(params.taskID))}/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * add task_notification
   *
   * Creates a task notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationService.TaskNotificationAddParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `payload`: Input for a task notification
   *
   * @return Created
   */
  taskNotificationAdd(params: TaskNotificationService.TaskNotificationAddParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.taskNotificationAddResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * show task_notification
   *
   * Details of a task notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationService.TaskNotificationShowParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `notificationID`:
   *
   * @return OK
   */
  taskNotificationShowResponse(params: TaskNotificationService.TaskNotificationShowParams): __Observable<__StrictHttpResponse<LasgroTask_notification_details>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tasks/${encodeURIComponent(String(params.taskID))}/notifications/${encodeURIComponent(String(params.notificationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTask_notification_details>;
      })
    );
  }
  /**
   * show task_notification
   *
   * Details of a task notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationService.TaskNotificationShowParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `notificationID`:
   *
   * @return OK
   */
  taskNotificationShow(params: TaskNotificationService.TaskNotificationShowParams): __Observable<LasgroTask_notification_details> {
    return this.taskNotificationShowResponse(params).pipe(
      __map(_r => _r.body as LasgroTask_notification_details)
    );
  }

  /**
   * update task_notification
   *
   * Updates a task notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationService.TaskNotificationUpdateParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `payload`: Input for updating a task notification
   *
   * - `notificationID`:
   *
   * @return OK
   */
  taskNotificationUpdateResponse(params: TaskNotificationService.TaskNotificationUpdateParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tasks/${encodeURIComponent(String(params.taskID))}/notifications/${encodeURIComponent(String(params.notificationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * update task_notification
   *
   * Updates a task notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskNotificationService.TaskNotificationUpdateParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `payload`: Input for updating a task notification
   *
   * - `notificationID`:
   *
   * @return OK
   */
  taskNotificationUpdate(params: TaskNotificationService.TaskNotificationUpdateParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.taskNotificationUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }
}

module TaskNotificationService {

  /**
   * Parameters for taskNotificationAdd
   */
  export interface TaskNotificationAddParams {
    taskID: string;

    /**
     * Input for a task notification
     */
    payload: TaskNotificationInputPayload;
  }

  /**
   * Parameters for taskNotificationShow
   */
  export interface TaskNotificationShowParams {
    taskID: string;
    notificationID: string;
  }

  /**
   * Parameters for taskNotificationUpdate
   */
  export interface TaskNotificationUpdateParams {
    taskID: string;

    /**
     * Input for updating a task notification
     */
    payload: TaskNotificationUpdatePayload;
    notificationID: string;
  }
}

export { TaskNotificationService }
