/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroSaved_checklist_taskCollection } from '../models/lasgro-saved-_checklist-_task-collection';
import { LasgroId } from '../models/lasgro-id';
import { SavedChecklistTaskAddInputPayload } from '../models/saved-checklist-task-add-input-payload';
import { SavedChecklistTaskUpdateInputPayload } from '../models/saved-checklist-task-update-input-payload';
@Injectable({
  providedIn: 'root',
})
class SavedChecklistTasksService extends __BaseService {
  static readonly savedChecklistTasksList1Path = '/organizations/{organizationID}/saved_checklists/{savedChecklistID}/saved_checklist_tasks';
  static readonly savedChecklistTasksAdd1Path = '/organizations/{organizationID}/saved_checklists/{savedChecklistID}/saved_checklist_tasks';
  static readonly savedChecklistTasksDelete1Path = '/organizations/{organizationID}/saved_checklists/{savedChecklistID}/saved_checklist_tasks/{taskID}';
  static readonly savedChecklistTasksUpdate1Path = '/organizations/{organizationID}/saved_checklists/{savedChecklistID}/saved_checklist_tasks/{taskID}';
  static readonly savedChecklistTasksDeletePath = '/saved_checklist_tasks/{taskID}';
  static readonly savedChecklistTasksUpdatePath = '/saved_checklist_tasks/{taskID}';
  static readonly savedChecklistTasksListPath = '/saved_checklists/{savedChecklistID}/saved_checklist_tasks';
  static readonly savedChecklistTasksAddPath = '/saved_checklists/{savedChecklistID}/saved_checklist_tasks';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list saved_checklist_tasks
   *
   * List all saved checklists tasks
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksList1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  savedChecklistTasksList1Response(params: SavedChecklistTasksService.SavedChecklistTasksList1Params): __Observable<__StrictHttpResponse<LasgroSaved_checklist_taskCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}/saved_checklist_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSaved_checklist_taskCollection>;
      })
    );
  }
  /**
   * list saved_checklist_tasks
   *
   * List all saved checklists tasks
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksList1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  savedChecklistTasksList1(params: SavedChecklistTasksService.SavedChecklistTasksList1Params): __Observable<LasgroSaved_checklist_taskCollection> {
    return this.savedChecklistTasksList1Response(params).pipe(
      __map(_r => _r.body as LasgroSaved_checklist_taskCollection)
    );
  }

  /**
   * add saved_checklist_tasks
   *
   * Create saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksAdd1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist task
   *
   * - `organizationID`:
   *
   * @return Created
   */
  savedChecklistTasksAdd1Response(params: SavedChecklistTasksService.SavedChecklistTasksAdd1Params): __Observable<__StrictHttpResponse<LasgroId>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}/saved_checklist_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroId>;
      })
    );
  }
  /**
   * add saved_checklist_tasks
   *
   * Create saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksAdd1Params` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist task
   *
   * - `organizationID`:
   *
   * @return Created
   */
  savedChecklistTasksAdd1(params: SavedChecklistTasksService.SavedChecklistTasksAdd1Params): __Observable<LasgroId> {
    return this.savedChecklistTasksAdd1Response(params).pipe(
      __map(_r => _r.body as LasgroId)
    );
  }

  /**
   * delete saved_checklist_tasks
   *
   * Deletes saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksDelete1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistTasksDelete1Response(params: SavedChecklistTasksService.SavedChecklistTasksDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}/saved_checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete saved_checklist_tasks
   *
   * Deletes saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksDelete1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistTasksDelete1(params: SavedChecklistTasksService.SavedChecklistTasksDelete1Params): __Observable<null> {
    return this.savedChecklistTasksDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update saved_checklist_tasks
   *
   * Updates saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksUpdate1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist task
   *
   * - `organizationID`:
   */
  savedChecklistTasksUpdate1Response(params: SavedChecklistTasksService.SavedChecklistTasksUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}/saved_checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update saved_checklist_tasks
   *
   * Updates saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksUpdate1Params` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist task
   *
   * - `organizationID`:
   */
  savedChecklistTasksUpdate1(params: SavedChecklistTasksService.SavedChecklistTasksUpdate1Params): __Observable<null> {
    return this.savedChecklistTasksUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete saved_checklist_tasks
   *
   * Deletes saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksDeleteParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistTasksDeleteResponse(params: SavedChecklistTasksService.SavedChecklistTasksDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.savedChecklistID != null) __params = __params.set('savedChecklistID', params.savedChecklistID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/saved_checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete saved_checklist_tasks
   *
   * Deletes saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksDeleteParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistTasksDelete(params: SavedChecklistTasksService.SavedChecklistTasksDeleteParams): __Observable<null> {
    return this.savedChecklistTasksDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update saved_checklist_tasks
   *
   * Updates saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksUpdateParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `payload`: Input payload of saved checklist task
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistTasksUpdateResponse(params: SavedChecklistTasksService.SavedChecklistTasksUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.savedChecklistID != null) __params = __params.set('savedChecklistID', params.savedChecklistID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/saved_checklist_tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update saved_checklist_tasks
   *
   * Updates saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksUpdateParams` containing the following parameters:
   *
   * - `taskID`:
   *
   * - `payload`: Input payload of saved checklist task
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   */
  savedChecklistTasksUpdate(params: SavedChecklistTasksService.SavedChecklistTasksUpdateParams): __Observable<null> {
    return this.savedChecklistTasksUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list saved_checklist_tasks
   *
   * List all saved checklists tasks
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksListParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  savedChecklistTasksListResponse(params: SavedChecklistTasksService.SavedChecklistTasksListParams): __Observable<__StrictHttpResponse<LasgroSaved_checklist_taskCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}/saved_checklist_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSaved_checklist_taskCollection>;
      })
    );
  }
  /**
   * list saved_checklist_tasks
   *
   * List all saved checklists tasks
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksListParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  savedChecklistTasksList(params: SavedChecklistTasksService.SavedChecklistTasksListParams): __Observable<LasgroSaved_checklist_taskCollection> {
    return this.savedChecklistTasksListResponse(params).pipe(
      __map(_r => _r.body as LasgroSaved_checklist_taskCollection)
    );
  }

  /**
   * add saved_checklist_tasks
   *
   * Create saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksAddParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist task
   *
   * - `organizationID`:
   *
   * @return Created
   */
  savedChecklistTasksAddResponse(params: SavedChecklistTasksService.SavedChecklistTasksAddParams): __Observable<__StrictHttpResponse<LasgroId>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/saved_checklists/${encodeURIComponent(String(params.savedChecklistID))}/saved_checklist_tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroId>;
      })
    );
  }
  /**
   * add saved_checklist_tasks
   *
   * Create saved checklist task.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `SavedChecklistTasksService.SavedChecklistTasksAddParams` containing the following parameters:
   *
   * - `savedChecklistID`:
   *
   * - `payload`: Input payload of saved checklist task
   *
   * - `organizationID`:
   *
   * @return Created
   */
  savedChecklistTasksAdd(params: SavedChecklistTasksService.SavedChecklistTasksAddParams): __Observable<LasgroId> {
    return this.savedChecklistTasksAddResponse(params).pipe(
      __map(_r => _r.body as LasgroId)
    );
  }
}

module SavedChecklistTasksService {

  /**
   * Parameters for savedChecklistTasksList1
   */
  export interface SavedChecklistTasksList1Params {
    savedChecklistID: string;
    organizationID: string;
  }

  /**
   * Parameters for savedChecklistTasksAdd1
   */
  export interface SavedChecklistTasksAdd1Params {
    savedChecklistID: string;

    /**
     * Input payload of saved checklist task
     */
    payload: SavedChecklistTaskAddInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for savedChecklistTasksDelete1
   */
  export interface SavedChecklistTasksDelete1Params {
    taskID: string;
    savedChecklistID: string;
    organizationID: string;
  }

  /**
   * Parameters for savedChecklistTasksUpdate1
   */
  export interface SavedChecklistTasksUpdate1Params {
    taskID: string;
    savedChecklistID: string;

    /**
     * Input payload of saved checklist task
     */
    payload: SavedChecklistTaskUpdateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for savedChecklistTasksDelete
   */
  export interface SavedChecklistTasksDeleteParams {
    taskID: string;
    savedChecklistID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for savedChecklistTasksUpdate
   */
  export interface SavedChecklistTasksUpdateParams {
    taskID: string;

    /**
     * Input payload of saved checklist task
     */
    payload: SavedChecklistTaskUpdateInputPayload;
    savedChecklistID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for savedChecklistTasksList
   */
  export interface SavedChecklistTasksListParams {
    savedChecklistID: string;
    organizationID?: string;
  }

  /**
   * Parameters for savedChecklistTasksAdd
   */
  export interface SavedChecklistTasksAddParams {
    savedChecklistID: string;

    /**
     * Input payload of saved checklist task
     */
    payload: SavedChecklistTaskAddInputPayload;
    organizationID?: string;
  }
}

export { SavedChecklistTasksService }
