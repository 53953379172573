import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { Toaster } from '@app/services';
import { AppConstants } from '@app/shared/constants/app.constants';
import { LasgroMail } from 'api/models';
import { MailsService } from 'api/services';
import { DxHtmlEditorComponent, DxHtmlEditorModule } from 'devextreme-angular';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    DxHtmlEditorModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
  ],
})
export class MailComponent implements OnInit {
  @ViewChild(DxHtmlEditorComponent, { static: false }) htmlEditorInstance?: DxHtmlEditorComponent;
  @LocalStorage() organizationId;
  @Input() mailId;
  @Input() type;
  @Input() subVariables;
  @Output() onSave = new EventEmitter();
  insuranceEmail: LasgroMail = { periods: [] } as LasgroMail;
  form: UntypedFormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  toolbarItems = [
    'undo',
    'redo',
    'separator',
    {
      formatName: 'size',
      formatValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
    },
    {
      formatName: 'font',
      formatValues: [
        'Arial',
        'Courier New',
        'Georgia',
        'Impact',
        'Lucida Console',
        'Tahoma',
        'Times New Roman',
        'Verdana',
      ],
    },
    'separator',
    'bold',
    'italic',
    'strike',
    'underline',
    'separator',
    'alignLeft',
    'alignCenter',
    'alignRight',
    'alignJustify',
    'separator',
    {
      formatName: 'header',
      formatValues: [false, 1, 2, 3, 4, 5],
    },
    'insertTable',
    'link',
    'image',

    'separator',
    'clear',
    'separator',
    'codeBlock',
    'blockquote',
    'separator',
    'orderedList',
    'bulletList',
    'separator',
    'header',
    'separator',
    'variable',
  ];
  variables = [
    'days_before_or_after',
    'recipient_first_name',
    'recipient_organization_name',
    'login_link',
    'logo',
    'logo_alt',
  ];
  separators = ['{{', '}}'];
  constructor(private insuranceEmailsServcie: MailsService, private fb: UntypedFormBuilder, private toaster: Toaster) {
    this.form = this.fb.group({
      subject: ['', Validators.required],
      periods: [[], Validators.required],
      body: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.initInsuranceEmail();
    this.variables.push(...this.subVariables);
  }

  initInsuranceEmail() {
    switch (this.type) {
      case AppConstants.InsuranceMailType: {
        this.insuranceEmailsServcie
          .mailsShowByOrganizationAndType({ organizationID: this.organizationId, type: this.type })
          .subscribe((response: LasgroMail) => {
            this.insuranceEmail = response;
            this.form.patchValue(this.insuranceEmail);
          });
        break;
      }
      default: {
        if (this.mailId) {
          this.insuranceEmailsServcie.mailsShow(this.mailId).subscribe((response: LasgroMail) => {
            this.insuranceEmail = response;
            this.form.patchValue(this.insuranceEmail);
          });
        }
      }
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = Number(event.value);
    // Add our fruit
    if (value) {
      this.insuranceEmail.periods.push(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.form.controls['periods'].setValue(this.insuranceEmail.periods);
  }

  remove(type: number): void {
    const index = this.insuranceEmail.periods.indexOf(type);

    if (index >= 0) {
      this.insuranceEmail.periods.splice(index, 1);
    }
    this.form.controls['periods'].setValue(this.insuranceEmail.periods);
  }

  onSaveClick() {
    if (!this.form.valid) {
      return;
    }
    let formValues = this.form.getRawValue();
    this.insuranceEmail.subject = formValues.subject;
    this.insuranceEmail.body = formValues.body;
    this.insuranceEmail.periods = formValues.periods;
    this.insuranceEmail.organizationId = this.organizationId;
    if (this.insuranceEmail.id) {
      this.insuranceEmailsServcie.mailsUpdate(this.insuranceEmail).subscribe((response) => {
        this.toaster.show('success', '', `${this.type} mail was saved successfully`);
        this.onSave.emit(response.id);
      });
    } else {
      this.insuranceEmail.type = this.type;
      this.insuranceEmailsServcie.mailsAdd(this.insuranceEmail).subscribe((response: string) => {
        this.insuranceEmail.id = response;
        this.onSave.emit(response);
        this.toaster.show('success', '', `${this.type} mail was created successfully`);
      });
    }
  }
}
