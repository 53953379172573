/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroPo_changes } from '../models/lasgro-po-_changes';
import { LasgroActivity_logCollection } from '../models/lasgro-activity-_log-collection';
import { ActivityLogAddInputPayload } from '../models/activity-log-add-input-payload';
@Injectable({
  providedIn: 'root',
})
class ActivityLogsService extends __BaseService {
  static readonly activityLogsCheckChangesPath = '/changes/{resourceID}';
  static readonly activityLogsListPath = '/logs/{resourceID}';
  static readonly activityLogsAddPath = '/organizations/{organizationID}/logs/{resourceID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * check_changes activity-logs
   *
   * List all changes performed over the po and infer if the user had not see any of them.
   *
   * Required security scopes:
   *   * `api:access`
   * @param resourceID undefined
   * @return OK
   */
  activityLogsCheckChangesResponse(resourceID: string): __Observable<__StrictHttpResponse<LasgroPo_changes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/changes/${encodeURIComponent(String(resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPo_changes>;
      })
    );
  }
  /**
   * check_changes activity-logs
   *
   * List all changes performed over the po and infer if the user had not see any of them.
   *
   * Required security scopes:
   *   * `api:access`
   * @param resourceID undefined
   * @return OK
   */
  activityLogsCheckChanges(resourceID: string): __Observable<LasgroPo_changes> {
    return this.activityLogsCheckChangesResponse(resourceID).pipe(
      __map(_r => _r.body as LasgroPo_changes)
    );
  }

  /**
   * list activity-logs
   *
   * List all activity logs over the purchase order.
   *
   * Required security scopes:
   *   * `api:access`
   * @param resourceID undefined
   * @return OK
   */
  activityLogsListResponse(resourceID: string): __Observable<__StrictHttpResponse<LasgroActivity_logCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/logs/${encodeURIComponent(String(resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroActivity_logCollection>;
      })
    );
  }
  /**
   * list activity-logs
   *
   * List all activity logs over the purchase order.
   *
   * Required security scopes:
   *   * `api:access`
   * @param resourceID undefined
   * @return OK
   */
  activityLogsList(resourceID: string): __Observable<LasgroActivity_logCollection> {
    return this.activityLogsListResponse(resourceID).pipe(
      __map(_r => _r.body as LasgroActivity_logCollection)
    );
  }

  /**
   * add activity-logs
   *
   * Adds a new activity log based on what the user sees
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ActivityLogsService.ActivityLogsAddParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  activityLogsAddResponse(params: ActivityLogsService.ActivityLogsAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/logs/${encodeURIComponent(String(params.resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add activity-logs
   *
   * Adds a new activity log based on what the user sees
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ActivityLogsService.ActivityLogsAddParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  activityLogsAdd(params: ActivityLogsService.ActivityLogsAddParams): __Observable<null> {
    return this.activityLogsAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ActivityLogsService {

  /**
   * Parameters for activityLogsAdd
   */
  export interface ActivityLogsAddParams {
    resourceID: string;
    payload: ActivityLogAddInputPayload;
    organizationID: string;
  }
}

export { ActivityLogsService }
