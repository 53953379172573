import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '@app/app.config';

@Injectable({
  providedIn: 'root',
})
export class TrainingService {

  constructor(private httpClient: HttpClient) {
  }


  getTrainingList(organizationId, userId, showAll = true) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/users/${userId}/training`;
    return this.httpClient.get(endpoint, { params: { showAll: showAll.toString() } });
  }

  skipTrainingItem(organizationId, userId, itemId) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/users/${userId}/training/${itemId}`;
    const data = {
      skipped: true,
      completed: false,
    };
    return this.httpClient.put(endpoint, data);
  }

  completeTrainingItem(organizationId, userId, itemId) {
    const endpoint = `${CONFIG.API}/organizations/${organizationId}/users/${userId}/training/${itemId}`;
    const data = {
      skipped: false,
      completed: true,
    };
    return this.httpClient.put(endpoint, data);
  }

}
