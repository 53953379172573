/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroWorkflow_instanceCollection } from '../models/lasgro-workflow-_instance-collection';
import { WorkflowInstanceInputPayload } from '../models/workflow-instance-input-payload';
import { LasgroWorkflow_instance } from '../models/lasgro-workflow-_instance';
@Injectable({
  providedIn: 'root',
})
class WorkflowInstanceService extends __BaseService {
  static readonly workflowInstanceListPath = '/organizations/{organizationID}/workflows/{workflowID}/instances';
  static readonly workflowInstanceAddPath = '/organizations/{organizationID}/workflows/{workflowID}/instances';
  static readonly workflowInstanceShowPath = '/organizations/{organizationID}/workflows/{workflowID}/instances/{workflowInstanceID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list workflow_instance
   *
   * List all of the instances for a workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowInstanceService.WorkflowInstanceListParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowInstanceListResponse(params: WorkflowInstanceService.WorkflowInstanceListParams): __Observable<__StrictHttpResponse<LasgroWorkflow_instanceCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}/instances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroWorkflow_instanceCollection>;
      })
    );
  }
  /**
   * list workflow_instance
   *
   * List all of the instances for a workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowInstanceService.WorkflowInstanceListParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowInstanceList(params: WorkflowInstanceService.WorkflowInstanceListParams): __Observable<LasgroWorkflow_instanceCollection> {
    return this.workflowInstanceListResponse(params).pipe(
      __map(_r => _r.body as LasgroWorkflow_instanceCollection)
    );
  }

  /**
   * add workflow_instance
   *
   * Creates a new instance for a workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowInstanceService.WorkflowInstanceAddParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `payload`: Input for a workflow instance payload
   *
   * - `organizationID`:
   */
  workflowInstanceAddResponse(params: WorkflowInstanceService.WorkflowInstanceAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}/instances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add workflow_instance
   *
   * Creates a new instance for a workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowInstanceService.WorkflowInstanceAddParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `payload`: Input for a workflow instance payload
   *
   * - `organizationID`:
   */
  workflowInstanceAdd(params: WorkflowInstanceService.WorkflowInstanceAddParams): __Observable<null> {
    return this.workflowInstanceAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show workflow_instance
   *
   * Details of a workflow instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowInstanceService.WorkflowInstanceShowParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowInstanceShowResponse(params: WorkflowInstanceService.WorkflowInstanceShowParams): __Observable<__StrictHttpResponse<LasgroWorkflow_instance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}/instances/${encodeURIComponent(String(params.workflowInstanceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroWorkflow_instance>;
      })
    );
  }
  /**
   * show workflow_instance
   *
   * Details of a workflow instance
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `WorkflowInstanceService.WorkflowInstanceShowParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  workflowInstanceShow(params: WorkflowInstanceService.WorkflowInstanceShowParams): __Observable<LasgroWorkflow_instance> {
    return this.workflowInstanceShowResponse(params).pipe(
      __map(_r => _r.body as LasgroWorkflow_instance)
    );
  }
}

module WorkflowInstanceService {

  /**
   * Parameters for workflowInstanceList
   */
  export interface WorkflowInstanceListParams {
    workflowID: string;
    organizationID: string;
  }

  /**
   * Parameters for workflowInstanceAdd
   */
  export interface WorkflowInstanceAddParams {
    workflowID: string;

    /**
     * Input for a workflow instance payload
     */
    payload: WorkflowInstanceInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for workflowInstanceShow
   */
  export interface WorkflowInstanceShowParams {
    workflowInstanceID: string;
    workflowID: string;
    organizationID: string;
  }
}

export { WorkflowInstanceService }
