import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItHelpModalComponent } from '@shared/modals/it-help-modal/it-help-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ItHelpGuard implements CanActivate {

  constructor(private $modal: NgbModal) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const ref = this.$modal.open(ItHelpModalComponent, {
      size: 'lg',
      container: 'nb-layout',
      keyboard: false,
    });

    return ref.result.then(() => {
      return false;
    });
  }

}
