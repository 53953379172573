import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompanyDto, User } from '@models/index';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { CompanyService, SharedService, Toaster } from '@services/index';
import { LocalStorage } from 'ngx-webstorage';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ngx-company-info-modal',
  templateUrl: './company-info-modal.component.html',
  styleUrls: ['./company-info-modal.component.scss'],
})
export class CompanyInfoModalComponent implements OnInit, OnDestroy {
  @LocalStorage() organizationId: string;
  @LocalStorage() companyInfoModalOpen: boolean;
  @Input() user: User;

  company: CompanyDto;
  saveInProgress: boolean = false;
  governmentTypes: any = [];
  isBroker: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private $companyService: CompanyService,
    private $shared: SharedService,
    private $toaster: Toaster,
  ) {}

  ngOnInit() {
    this.companyInfoModalOpen = true;
    this.isBroker = this.user.role.includes('Broker');
    this.governmentTypes = this.$shared.taxTypes();
    this.$companyService
      .getById(this.organizationId)
      .pipe(first())
      .subscribe(
        res => (this.company = res),
        error => {
          console.error(error);
          const errorMsg =
            error.error && error.error.detail
              ? error.error.detail
              : 'An error occurred while fetching company details.';
          this.$toaster.show('error', 'Company', errorMsg);
        },
      );
  }

  async closeModal() {
    this.activeModal.close('close');
  }

  updateCompanyInfo() {
    this.saveInProgress = true;
    this.company.government_id_type = +this.company.government_id_type;
    this.$companyService
      .updateCompany(this.company)
      .pipe(
        switchMap(_ => {
          return this.$companyService.updateUser(this.organizationId, {
            ...this.user,
            show_company_info: false,
          });
        }),
      )
      .subscribe(res => {
        this.saveInProgress = false;
        this.activeModal.close('saved');
      }, error => {
        console.error(error);
        this.$toaster.show('error', 'Company update', 'An error occurred while updating the company details.');
        this.saveInProgress = false;
        this.activeModal.close('error');
      });
    
  }

  ngOnDestroy() {
    this.companyInfoModalOpen = false;
  }
}
