/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFile_infoCollection } from '../models/lasgro-file-_info-collection';
@Injectable({
  providedIn: 'root',
})
class ChecklistFilesService extends __BaseService {
  static readonly checklistFilesShowPath = '/checklist_files/{fileID}';
  static readonly checklistFilesUpdatePath = '/checklist_files/{fileID}';
  static readonly checklistFilesDeletePath = '/checklist_files/{fileID}';
  static readonly checklistFilesListPath = '/checklists/{checklistID}/checklist_files';
  static readonly checklistFilesAddPath = '/checklists/{checklistID}/checklist_files';
  static readonly checklistFilesList1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_files';
  static readonly checklistFilesAdd1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_files';
  static readonly checklistFilesShow1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_files/{fileID}';
  static readonly checklistFilesUpdate1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_files/{fileID}';
  static readonly checklistFilesDelete1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/checklists/{checklistID}/checklist_files/{fileID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * show checklist_files
   *
   * Download checklist file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesShowParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistFilesShowResponse(params: ChecklistFilesService.ChecklistFilesShowParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checklist_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * show checklist_files
   *
   * Download checklist file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesShowParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistFilesShow(params: ChecklistFilesService.ChecklistFilesShowParams): __Observable<null> {
    return this.checklistFilesShowResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update checklist_files
   *
   * Replace checklist file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesUpdateParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `file`: uploaded file
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistFilesUpdateResponse(params: ChecklistFilesService.ChecklistFilesUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/checklist_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update checklist_files
   *
   * Replace checklist file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesUpdateParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `file`: uploaded file
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistFilesUpdate(params: ChecklistFilesService.ChecklistFilesUpdateParams): __Observable<null> {
    return this.checklistFilesUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete checklist_files
   *
   * Deletes checklist with all tasks. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesDeleteParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistFilesDeleteResponse(params: ChecklistFilesService.ChecklistFilesDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.checklistID != null) __params = __params.set('checklistID', params.checklistID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/checklist_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete checklist_files
   *
   * Deletes checklist with all tasks. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesDeleteParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   */
  checklistFilesDelete(params: ChecklistFilesService.ChecklistFilesDeleteParams): __Observable<null> {
    return this.checklistFilesDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list checklist_files
   *
   * List all checklist's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesListParams` containing the following parameters:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  checklistFilesListResponse(params: ChecklistFilesService.ChecklistFilesListParams): __Observable<__StrictHttpResponse<LasgroFile_infoCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_infoCollection>;
      })
    );
  }
  /**
   * list checklist_files
   *
   * List all checklist's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesListParams` containing the following parameters:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  checklistFilesList(params: ChecklistFilesService.ChecklistFilesListParams): __Observable<LasgroFile_infoCollection> {
    return this.checklistFilesListResponse(params).pipe(
      __map(_r => _r.body as LasgroFile_infoCollection)
    );
  }

  /**
   * add checklist_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesAddParams` containing the following parameters:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  checklistFilesAddResponse(params: ChecklistFilesService.ChecklistFilesAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add checklist_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesAddParams` containing the following parameters:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  checklistFilesAdd(params: ChecklistFilesService.ChecklistFilesAddParams): __Observable<null> {
    return this.checklistFilesAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list checklist_files
   *
   * List all checklist's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistFilesList1Response(params: ChecklistFilesService.ChecklistFilesList1Params): __Observable<__StrictHttpResponse<LasgroFile_infoCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_infoCollection>;
      })
    );
  }
  /**
   * list checklist_files
   *
   * List all checklist's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   *
   * @return OK
   */
  checklistFilesList1(params: ChecklistFilesService.ChecklistFilesList1Params): __Observable<LasgroFile_infoCollection> {
    return this.checklistFilesList1Response(params).pipe(
      __map(_r => _r.body as LasgroFile_infoCollection)
    );
  }

  /**
   * add checklist_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesAdd1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistFilesAdd1Response(params: ChecklistFilesService.ChecklistFilesAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add checklist_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesAdd1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistFilesAdd1(params: ChecklistFilesService.ChecklistFilesAdd1Params): __Observable<null> {
    return this.checklistFilesAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show checklist_files
   *
   * Download checklist file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistFilesShow1Response(params: ChecklistFilesService.ChecklistFilesShow1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * show checklist_files
   *
   * Download checklist file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistFilesShow1(params: ChecklistFilesService.ChecklistFilesShow1Params): __Observable<null> {
    return this.checklistFilesShow1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update checklist_files
   *
   * Replace checklist file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistFilesUpdate1Response(params: ChecklistFilesService.ChecklistFilesUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;



    if (params.file != null) { __formData.append('file', params.file as string | Blob);}

    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update checklist_files
   *
   * Replace checklist file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `file`: uploaded file
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistFilesUpdate1(params: ChecklistFilesService.ChecklistFilesUpdate1Params): __Observable<null> {
    return this.checklistFilesUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete checklist_files
   *
   * Deletes checklist with all tasks. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesDelete1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistFilesDelete1Response(params: ChecklistFilesService.ChecklistFilesDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.sourcingProject != null) __params = __params.set('sourcing_project', params.sourcingProject.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/checklists/${encodeURIComponent(String(params.checklistID))}/checklist_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete checklist_files
   *
   * Deletes checklist with all tasks. Only customers can create or modify checklists
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ChecklistFilesService.ChecklistFilesDelete1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `checklistID`:
   *
   * - `sourcing_project`:
   */
  checklistFilesDelete1(params: ChecklistFilesService.ChecklistFilesDelete1Params): __Observable<null> {
    return this.checklistFilesDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChecklistFilesService {

  /**
   * Parameters for checklistFilesShow
   */
  export interface ChecklistFilesShowParams {
    fileID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistFilesUpdate
   */
  export interface ChecklistFilesUpdateParams {
    fileID: string;

    /**
     * uploaded file
     */
    file: Blob;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistFilesDelete
   */
  export interface ChecklistFilesDeleteParams {
    fileID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
    checklistID?: string;
  }

  /**
   * Parameters for checklistFilesList
   */
  export interface ChecklistFilesListParams {
    checklistID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for checklistFilesAdd
   */
  export interface ChecklistFilesAddParams {

    /**
     * uploaded file
     */
    file: Blob;
    checklistID: string;
    sourcingProject?: boolean;
    purchaseOrderID?: string;
    organizationID?: string;
  }

  /**
   * Parameters for checklistFilesList1
   */
  export interface ChecklistFilesList1Params {
    purchaseOrderID: string;
    organizationID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistFilesAdd1
   */
  export interface ChecklistFilesAdd1Params {
    purchaseOrderID: string;
    organizationID: string;

    /**
     * uploaded file
     */
    file: Blob;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistFilesShow1
   */
  export interface ChecklistFilesShow1Params {
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistFilesUpdate1
   */
  export interface ChecklistFilesUpdate1Params {
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;

    /**
     * uploaded file
     */
    file: Blob;
    checklistID: string;
    sourcingProject?: boolean;
  }

  /**
   * Parameters for checklistFilesDelete1
   */
  export interface ChecklistFilesDelete1Params {
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;
    checklistID: string;
    sourcingProject?: boolean;
  }
}

export { ChecklistFilesService }
