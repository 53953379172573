<!-- ACTIONS -->
<div class="row m-0 p-0">
  <div class="col-md-8">
    <ngx-title-divider title="Activity Log"></ngx-title-divider>
  </div>
  <div class="col-md-4">
    <mat-checkbox class="pull-right hide-low-priority" [(ngModel)]="hideLowPriority">Hide low priority logs</mat-checkbox>
  </div>
</div>
<div class="mb-3">
  <div class="actions-wrapper scrollable-section" style="max-height: 500px; overflow-y: scroll;" [ngClass]="{
    'hide-low-priority': hideLowPriority
  }">

    <div *ngFor="let action of actions">
      
      <div class="action-box alert mt-2" [ngClass]="{
        'alert-danger': action.type === 'REJECTED' || (['CHANGED_STATUS', 'UPDATE'].includes(action.type) && action.new_value === 'rejected'),
        'alert-success': action.type === 'ACCEPTED' || action.type === 'SEND' || (['CHANGED_STATUS', 'UPDATE'].includes(action.type) && ['open', 'accepted', 'approved'].includes(action.new_value)),
        'alert-secondary': (action.type === 'UPDATE' && !['open', 'approved', 'accepted', 'rejected'].includes(action.new_value)),
        'alert-warning': action.type === 'REISSUE_BY_PRODUCT',
        'alert-light low-priority border': !['REJECTED', 'ACCEPTED', 'UPDATE', 'SEND', 'REISSUE_BY_PRODUCT'].includes(action.type) && !(action.type === 'CHANGED_STATUS' && ['open', 'accepted', 'approved', 'rejected'].includes(action.new_value))
      }">
        <span class="timestamp text-secondary" style="font-family: monospace;">
          {{ action.created_at | date:'short' }}
        </span>
        {{ action.display_text }}
      </div>
    </div>
    
  </div>
</div>
