// TODO: separate this into multiple files

import * as moment from 'moment';
import { LocalStorage } from 'ngx-webstorage';

export class WorkInProgressSearch {
  public status: string = '';
  public shipDate: string = '';
  public orderDate: string = '';
  public customerName: string = '';
  public customerId: string = '';
  public customerPartId: string = '';
  public line_item: string = '';
  supplierId?: string;

  @LocalStorage() organizationId: string;

  getSearchString(): string {
    const stringElements: Array<string> = [``];

    if (this.customerId) stringElements.push(`customer_id=${ this.customerId }`);

    if (this.supplierId) stringElements.push(`supplier=${ this.supplierId }`);

    if (this.status) stringElements.push(`status=${ this.status }`);

    if (this.customerName) stringElements.push(`customer_name=${ this.customerName }`);

    if (this.customerPartId) stringElements.push(`customer_part_identifier=${ this.customerPartId }`);

    if (this.orderDate) {
      const formattedDate = moment(this.orderDate).format('YYYY-MM-DD');
      stringElements.push(`order_date=${ formattedDate }`);
      stringElements.push(`order_date_match_type=exact`);
    }

    if (this.shipDate) {
      const formattedDate = moment(this.shipDate).format('YYYY-MM-DD');
      stringElements.push(`ship_date=${ formattedDate }`);
      stringElements.push(`ship_date_match_type=exact`);
    }

    if (this.line_item) {
      stringElements.push(`line_item=${ this.line_item }`);
    }

    return stringElements.join('&');
  }
}

export class WorkCommissionedSearch {
  public status: string;
  public shipDate: string;
  public orderDate: string;
  public supplierName: string;
  public supplierPartId: string;
  public customerId: string;
  public totalCompleted: number;
  public lineItem: string;
  supplierId?: string;

  @LocalStorage() organizationId: string;

  constructor() {
    this.supplierName = '';
    this.status = '';
    this.shipDate = '';
    this.orderDate = '';
    this.customerId = '';
    this.supplierPartId = '';
    this.lineItem = '';
    this.totalCompleted = null;
  }

  getSearchString(): string {
    const stringElements: Array<string> = [];

    if (this.customerId !== '') stringElements.push(`customer_id=${ this.customerId }`);

    if (this.supplierId) stringElements.push(`supplierId=${ this.supplierId }`);

    if (this.status !== '') stringElements.push(`status=${ this.status }`);

    if (this.supplierName) stringElements.push(`supplier=${ this.supplierName }`);

    if (this.supplierPartId) stringElements.push(`supplier_part_identifier=${ this.supplierPartId }`);

    if (this.orderDate) {
      const formattedDate = moment(this.orderDate).format('YYYY-MM-DD');
      stringElements.push(`order_date=${ formattedDate }`);
      stringElements.push(`order_date_match_type=exact`);
    }

    if (this.shipDate) {
      const formattedDate = moment(this.shipDate).format('YYYY-MM-DD');
      stringElements.push(`ship_date=${ formattedDate }`);
      stringElements.push(`ship_date_match_type=exact`);
    }

    if (this.lineItem) {
      stringElements.push(`line_item=${ this.lineItem }`);
    }

    return stringElements.join('&');
  }
}

export class CustomerSearch {
  public city: string;
  public name: string;
  public planId: number;
  public global: string;
  public region: string;
  public country: string;
  public address1: string;
  public address2: string;
  public postalCode: string;
  @LocalStorage() organizationId: string;
  constructor() {
    this.city = '';
    this.name = '';
    this.planId = 0;
    this.global = '';
    this.region = '';
    this.country = '';
    this.address1 = '';
    this.address2 = '';
    this.postalCode = '';
  }

  getSearchString(): string {
    const organizationId = this.organizationId.replace(/"/g, '');
    const stringElements: Array<string> = [`organization_id=${ organizationId }`];

    if (this.address1 !== '') stringElements.push(`address1=${ this.address1 }`);
    if (this.address2 !== '') stringElements.push(`address2=${ this.address2 }`);
    if (this.city !== '') stringElements.push(`city=${ this.city }`);
    if (this.country !== '') stringElements.push(`country=${ this.country }`);
    if (this.global !== '') stringElements.push(`global=${ this.global }`);
    if (this.name !== '') stringElements.push(`name=${ this.name }`);
    if (this.planId && this.planId > 0) stringElements.push(`plan_id=${ this.planId }`);
    if (this.postalCode !== '') stringElements.push(`postal_code=${ this.postalCode }`);
    if (this.region !== '') stringElements.push(`region=${ this.region }`);

    return stringElements.join('&');
  }
}

export class SupplierSearch {
  public city: string;
  public name: string;
  public planId: number;
  public global: string;
  public region: string;
  public country: string;
  public address1: string;
  public address2: string;
  public postalCode: string;
  public broker: boolean;

  @LocalStorage() organizationId: string;

  constructor() {
    this.city = '';
    this.name = '';
    this.planId = 0;
    this.global = '';
    this.region = '';
    this.country = '';
    this.address1 = '';
    this.address2 = '';
    this.postalCode = '';
    this.broker = null;
  }

  getSearchString(): string {
    const organizationId = this.organizationId.replace(/"/g, '');
    const stringElements: Array<string> = [`organization_id=${ organizationId }`];

    if (this.address1 !== '') stringElements.push(`address1=${ this.address1 }`);
    if (this.address2 !== '') stringElements.push(`address2=${ this.address2 }`);
    if (this.city !== '') stringElements.push(`city=${ this.city }`);
    if (this.country !== '') stringElements.push(`country=${ this.country }`);
    if (this.global !== '') stringElements.push(`global=${ this.global }`);
    if (this.name !== '') stringElements.push(`name=${ this.name }`);
    if (this.planId && this.planId > 0) stringElements.push(`plan_id=${ this.planId }`);
    if (this.postalCode !== '') stringElements.push(`postal_code=${ this.postalCode }`);
    if (this.region !== '') stringElements.push(`region=${ this.region }`);
    if (this.broker) stringElements.push(`broker=${ this.broker }`);

    return stringElements.join('&');
  }
}

export class ProductSearch {
  public name: string;
  public description: string;
  public supplierIdentifier: string;
  @LocalStorage() organizationId: string;
  constructor() {
    this.name = '';
    this.description = '';
    this.supplierIdentifier = '';
  }

  getSearchString(): string {
    const organizationId = this.organizationId.replace(/"/g, '');
    const stringElements: Array<string> = [`organization_id=${ organizationId }`];

    if (this.name !== '') stringElements.push(`name=${ this.name }`);
    if (this.description !== '') stringElements.push(`description=${ this.description }`);
    if (this.supplierIdentifier !== '') stringElements.push(`supplier_part_identifier=${ this.supplierIdentifier }`);
    return stringElements.join('&');
  }
}

export class UserSearch {
  public email: string;
  public roleId: number;
  public global: string;
  public lastName: string;
  public username: string;
  public firstName: string;
  public phoneNumber: string;
  @LocalStorage() organizationId: string;
  constructor() {
    this.email = '';
    this.roleId = 0;
    this.global = '';
    this.lastName = '';
    this.username = '';
    this.firstName = '';
    this.phoneNumber = '';
  }

  getSearchString(): string {
    const organizationId = this.organizationId.replace(/"/g, '');
    const stringElements: Array<string> = [`organization_id=${ organizationId }`];

    if (this.email !== '') stringElements.push(`email=${ this.email }`);
    if (this.firstName !== '') stringElements.push(`firstname=${ this.firstName }`);
    if (this.global !== '') stringElements.push(`global=${ this.global }`);
    if (this.lastName !== '') stringElements.push(`lastname=${ this.lastName }`);
    if (this.phoneNumber !== '') stringElements.push(`phone_number=${ this.phoneNumber }`);
    if (this.roleId && this.roleId > 0) stringElements.push(`role_id=${ this.roleId }`);
    if (this.username !== '') stringElements.push(`username=${ this.username }`);

    return stringElements.join('&');
  }
}
