<div class="company-menu-select mt-4 pt-4">
  <div class="subtitle">Browse as:</div>
  <mat-select
    [value]="activeCompanyId"
    (selectionChange)="switchCompany($event)"
    panelClass="menu-select-panel"
  >
    <mat-option *ngFor="let company of companies" [value]="company.id">
      {{ company.name }}
    </mat-option>
  </mat-select>
</div>
