/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFile_type_group } from '../models/lasgro-file-_type-_group';
import { FileTypeGroupUpdatePayload } from '../models/file-type-group-update-payload';
import { LasgroFile_type_groupCollection } from '../models/lasgro-file-_type-_group-collection';
import { FileTypeGroupInputPayload } from '../models/file-type-group-input-payload';
@Injectable({
  providedIn: 'root',
})
class FileTypeGroupsService extends __BaseService {
  static readonly fileTypeGroupsUpdatePath = '/organization/file_type_groups';
  static readonly fileTypeGroupsShowPath = '/organization/file_type_groups/{fileTypeGroupID}';
  static readonly fileTypeGroupsDeletePath = '/organization/file_type_groups/{fileTypeGroupID}';
  static readonly fileTypeGroupsListPath = '/organization/{organizationID}/file_type_groups';
  static readonly fileTypeGroupsAddPath = '/organization/{organizationID}/file_type_groups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update file_type_groups
   *
   * Updates a file type group
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  fileTypeGroupsUpdateResponse(payload: FileTypeGroupUpdatePayload): __Observable<__StrictHttpResponse<LasgroFile_type_group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization/file_type_groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_type_group>;
      })
    );
  }
  /**
   * update file_type_groups
   *
   * Updates a file type group
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  fileTypeGroupsUpdate(payload: FileTypeGroupUpdatePayload): __Observable<LasgroFile_type_group> {
    return this.fileTypeGroupsUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroFile_type_group)
    );
  }

  /**
   * show file_type_groups
   *
   * Get file type group by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param fileTypeGroupID File Type Group ID
   * @return OK
   */
  fileTypeGroupsShowResponse(fileTypeGroupID: string): __Observable<__StrictHttpResponse<LasgroFile_type_group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/file_type_groups/${encodeURIComponent(String(fileTypeGroupID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_type_group>;
      })
    );
  }
  /**
   * show file_type_groups
   *
   * Get file type group by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param fileTypeGroupID File Type Group ID
   * @return OK
   */
  fileTypeGroupsShow(fileTypeGroupID: string): __Observable<LasgroFile_type_group> {
    return this.fileTypeGroupsShowResponse(fileTypeGroupID).pipe(
      __map(_r => _r.body as LasgroFile_type_group)
    );
  }

  /**
   * delete file_type_groups
   *
   * Delete file type group by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param fileTypeGroupID File Type Group ID
   */
  fileTypeGroupsDeleteResponse(fileTypeGroupID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organization/file_type_groups/${encodeURIComponent(String(fileTypeGroupID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete file_type_groups
   *
   * Delete file type group by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param fileTypeGroupID File Type Group ID
   */
  fileTypeGroupsDelete(fileTypeGroupID: string): __Observable<null> {
    return this.fileTypeGroupsDeleteResponse(fileTypeGroupID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list file_type_groups
   *
   * Lists organization file type groups
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  fileTypeGroupsListResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroFile_type_groupCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/${encodeURIComponent(String(organizationID))}/file_type_groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_type_groupCollection>;
      })
    );
  }
  /**
   * list file_type_groups
   *
   * Lists organization file type groups
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  fileTypeGroupsList(organizationID: string): __Observable<LasgroFile_type_groupCollection> {
    return this.fileTypeGroupsListResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroFile_type_groupCollection)
    );
  }

  /**
   * add file_type_groups
   *
   * Add a new file type group
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FileTypeGroupsService.FileTypeGroupsAddParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`: Organization ID
   */
  fileTypeGroupsAddResponse(params: FileTypeGroupsService.FileTypeGroupsAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organization/${encodeURIComponent(String(params.organizationID))}/file_type_groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add file_type_groups
   *
   * Add a new file type group
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FileTypeGroupsService.FileTypeGroupsAddParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`: Organization ID
   */
  fileTypeGroupsAdd(params: FileTypeGroupsService.FileTypeGroupsAddParams): __Observable<null> {
    return this.fileTypeGroupsAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FileTypeGroupsService {

  /**
   * Parameters for fileTypeGroupsAdd
   */
  export interface FileTypeGroupsAddParams {
    payload: FileTypeGroupInputPayload;

    /**
     * Organization ID
     */
    organizationID: string;
  }
}

export { FileTypeGroupsService }
