<div class="menu">
  <div class="content-container">
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="footer">
    <div class="btns-container">
      <button class="clear-btn" (click)="onFilter.emit(false)">Clear</button>
      <div class="ml-auto">
        <button class="save-btn" (click)="onFilter.emit(true)" id="save_filter_btn">Apply</button>
      </div>
    </div>
  </div>
</div>
