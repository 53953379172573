import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { TaskRequest } from '@app/models/checklist-task.model';
import { TaskItemComponent } from '@app/modules/checklist/components/task-item/task-item.component';
import { Checklist, ChecklistTaskDto, ContractDto, FileItemDto, User } from '@models/index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ChecklistService } from '@services/checklist.service';
import { CompanyService, Toaster } from '@services/index';
import { SweetAlertService } from '@services/sweet-alert.service';
import { AddTaskFormComponent } from '@shared/components';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { LocalStorage } from 'ngx-webstorage';
import { forkJoin, of, Subject, Subscription, zip } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { SavedChecklistsModalComponent } from '../../modals/saved-checklists-modal/saved-checklists-modal.component';
import { TaskFilesModalComponent } from '../../modals/task-files-modal/task-files-modal.component';

@Component({
  standalone: true,
  selector: 'ngx-check-list',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    AddTaskFormComponent,
    TaskItemComponent
  ]
})
export class ChecklistComponent implements OnInit, OnDestroy, OnChanges {
  @LocalStorage() organizationId: string;
  @Input() purchaseOrderId: string;
  @Input() purchaseOrder: ContractDto;
  @Input() selectedTaskId: string;
  @Input() allowEdit: boolean = true;
  @Input() allowToSaveChecklist: boolean = true;
  @Input() companyType: string;

  @Input() checklist: Checklist;
  @Output() checklistChange: EventEmitter<Checklist> = new EventEmitter<Checklist>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('datePicker', { static: true }) datePicker: any;
  @ViewChild(AddTaskFormComponent, { static: false }) addTaskFormComponentRef: AddTaskFormComponent;

  availableUsers: User[] = [];
  tasks: ChecklistTaskDto[] = [];
  files: FileItemDto[];
  isCustomer: boolean;
  isSupplier: boolean;
  currentSorting: string = 'none';
  enableBulkDelete: boolean = false;

  newTask: boolean = false;
  loading: boolean = false;
  newTaskId;
  dragulaOptions: any = {
    moves: (el, container, handle) => {
      if (handle.classList.contains('drag-icon')) {
        return handle.className.indexOf('drag-icon') !== -1;
      }
    },
  };

  currentUser: User;
  companyUsers: User[] = [];
  
  private subscription = new Subscription();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private $company: CompanyService,
    private checklistService: ChecklistService,
    private dragulaService: DragulaService,
    private $toaster: Toaster,
    private $modal: NgbModal,
    private matDialog: MatDialog,
    private sweetAlertService: SweetAlertService,
    private store: Store<{ user: User }>,
    public location: Location,
  ) {
  }

  ngOnChanges() {
    this.sortTaskList();
  }

  async ngOnInit() {
    this.store.select('user')
      .pipe(takeUntil(this.destroy$))
      .subscribe(u => this.currentUser = u);
    this.isCustomer = this.companyType === 'customer';
    this.isSupplier = this.companyType === 'supplier';
    if (this.purchaseOrderId) {
      this.getCheckList();
      this.getAllowUsersForPO();
    }

    if (this.checklist) {
      this.sortTaskList();
    }

    this.dragulaService.createGroup('checklist-' + this.purchaseOrderId, this.dragulaOptions);

    this.subscription.add(
      this.dragulaService.drop('checklist-' + this.purchaseOrderId)
        .subscribe((...data) => {
          const oldSequenceNumber = +data[0].el.id;
          data[0].el.classList.remove('on-drag');

          const taskIndex = this.tasks.findIndex((iterableTask: ChecklistTaskDto): boolean => {
            return iterableTask.sequence_number === oldSequenceNumber;
          });

          const task: ChecklistTaskDto = this.tasks[taskIndex];
          const requestTask: TaskRequest = {
            sequence_number: taskIndex + 1,
          };
          this.$company.updateTask(task.checklist_task_id, requestTask).subscribe();
        }),
    );

    this.subscription.add(
      this.dragulaService.drag('checklist-' + this.purchaseOrderId).subscribe((...data) => {
        data[0].el.classList.add('on-drag');
      }),
    );

    if (this.purchaseOrderId) {
      this.$company.poChangesSeen(this.organizationId, this.purchaseOrderId, 'checklist')
        .pipe(takeUntil(this.destroy$))
        .subscribe();
      this.$company.poChangesSeen(this.organizationId, this.purchaseOrderId, 'tasks')
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.dragulaService.destroy('checklist-' + this.purchaseOrderId);
    if (this.newTask && this.newTaskId) {
      this.deleteNewTask();
    }
  }

  getCheckList() {

    this.$company
      .getCheckList(this.organizationId, this.purchaseOrderId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((checklist) => {
        this.checklist = checklist[0];
        this.sortTaskList();
        this.checklistChange.emit(this.checklist);
      }, (error) => {
        console.error(error);
        const errMsg =
          error.error && error.error.detail ? error.error.detail : 'An error occurred while fetching the checklist.';
        this.$toaster.show('error', 'Checklist', errMsg);
      });
  }

  getAllowUsersForPO() {
    const customers$ = this.$company.users(this.isCustomer ?
      this.purchaseOrder.customer_id : this.purchaseOrder.supplier_id).pipe(map((users: User[]) => {
      const organizationId = this.isCustomer ? this.purchaseOrder.customer_id : this.purchaseOrder.supplier_id;
      return users.map(u => {
        u.organization_id = organizationId;
        return u;
      });
    }));
    const suppliers$ = this.$company.users(this.isCustomer ?
      this.purchaseOrder.supplier_id : this.purchaseOrder.customer_id).pipe(map((users: User[]) => {
      const organizationId = this.isCustomer ? this.purchaseOrder.supplier_id : this.purchaseOrder.customer_id;
      return users.map(u => {
        u.organization_id = organizationId;
        return u;
      });
    }));

    zip(customers$, suppliers$)
      .pipe(takeUntil(this.destroy$))
      .pipe(map(x => [...x[0], ...x[1]]))
      // .pipe(tap(users => this.companyUsers = users))
      .pipe(
        catchError(error => {
          console.error(error);
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error occurred while fetching the users.';
          this.$toaster.show('error', 'Users', errMsg);
          return of([]);
        })).subscribe(users => {
      this.companyUsers = users;
      this.availableUsers = users;
    });
  }

  saveChecklist() {
    const name = prompt('Introduce a name for the checklist to save.', '');
    if (name.length <= 0)
      this.$toaster.show('warning', 'Checklist', 'You must introduce a name to save the checklist.');
    this.checklistService.saveChecklist(
      this.organizationId,
      this.checklist.checklist_id,
      name,
      this.purchaseOrder.supplier_id,
    )
      .subscribe(
        _ => this.$toaster.show('success', 'Checklist', 'The checklist was saved successfully.'),
        error => {
          console.error(error);
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error occurred saving the checklist.';
          this.$toaster.show('error', 'Save Checklist', errMsg);
        },
      );
  }

  openChecklistSavedModal() {
    const activeModal = this.$modal.open(SavedChecklistsModalComponent, {
      size: 'lg',
      container: 'nb-layout',
    });
    activeModal.componentInstance.supplierId = this.purchaseOrder.supplier_id;
    activeModal.result
      .then(res => {
        if (!res) return;
        this.$company
          .assignSavedChecklist(
            this.organizationId,
            this.purchaseOrderId,
            this.checklist.checklist_id,
            res.saved_checklist_id,
          )
          .subscribe(
            _ => {
              this.$toaster.show('success', 'Saved checklist', 'The saved checklist was used successfully.');
              this.getCheckList();
            },
            error => {
              console.error(error);
              const errMsg = error.error && error.error.detail ? error.error.detail : error.message;
              this.$toaster.show('error', 'Saved Checklist', errMsg);
            },
          );
      })
      .catch(error => {
        if (!error || error === 0 || error === 1) {
          return;
        }
        const errMsg = error.error && error.error.detail ? error.error.detail : error.message;
        this.$toaster.show('error', 'Checklist', errMsg);
      });
  }

  async createNewTask({ task, files }) {
    const newTask: ChecklistTaskDto = {
      ...task,
      sequence_number: this.tasks ? this.tasks.length + 1 : 1,
    };

    this.$company
      .createNewTask(this.organizationId, this.purchaseOrderId, this.checklist.checklist_id, newTask)
      .pipe(tap((result: any) => {
        this.addFilesToTask(files, result.id);
      }))
      .subscribe(
        () => {
          this.getCheckList();
        },
        error => {
          console.error(error);
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error occurred while creating the task.';
          this.$toaster.show('error', 'Task', errMsg);
        },
      );
  }

  sortTasks() {
    const possibleSortings = ['none', 'desc', 'asc'];
    const currentSortIndex = possibleSortings.indexOf(this.currentSorting);
    const nextIndex = currentSortIndex >= possibleSortings.length - 1 ? 0 : currentSortIndex + 1;
    this.currentSorting = possibleSortings[nextIndex];
    switch (this.currentSorting) {
      case 'none':
        this.sortTaskList();
        break;
      case 'desc':
        this.tasks = this.tasks.sort((currentTask: ChecklistTaskDto, nextTask: ChecklistTaskDto) => {
          return moment(nextTask.due_date).unix() - moment(currentTask.due_date).unix();
        });
        break;
      case 'asc':
        this.tasks = this.tasks.sort((currentTask: ChecklistTaskDto, nextTask: ChecklistTaskDto) => {
          return moment(currentTask.due_date).unix() - moment(nextTask.due_date).unix();
        });
        break;
    }
  }

  sortTaskList() {
    if (!!this.checklist && this.checklist.checklist_tasks) {
      this.tasks = this.checklist.checklist_tasks.map(t => {
        const locale = navigator.language;
        const newDate = moment.utc(t.created_at).toObject();
        moment.locale(locale);
        t['created_at_formatted'] = moment(newDate).format('L');
        return t;
      });

      this.tasks = this.tasks.sort((currentTask: ChecklistTaskDto, nextTask: ChecklistTaskDto) => {
        return currentTask.sequence_number - nextTask.sequence_number;
      });
    } else {
      this.tasks = [];
    }
  }

  updateTask(task: ChecklistTaskDto) {
    this.getCheckList();
  }

  async removeTask(tasks: ChecklistTaskDto[]) {
    this.sweetAlertService.deleteConfirmation().then(async (result) => {
      if (result.value) {
        const taskObservers = tasks.map(t => {
          return this.$company.removeTask(this.organizationId, this.purchaseOrderId, t.checklist_id, t.checklist_task_id);
        });
        forkJoin(taskObservers).subscribe(() => {
          this.$toaster.show('success', 'Success', 'Task has been removed.');
          this.getCheckList();
        }, (error) => {
          this.$toaster.show('error', 'Error', 'An error occurred, please try again later.');
          this.getCheckList();
        });
      }
    });
    this.onSubmit.emit('updated');
  }

  deleteNewTask() {
    if (this.newTaskId) {
      this.$company
        .removeTask(this.organizationId, this.purchaseOrderId, this.checklist.checklist_id, this.newTaskId)
        .subscribe();
      this.newTaskId = '';
    }
    this.newTask = false;
    this.onSubmit.emit('updated');
  }

  isCompleteButtonDisabled(task: ChecklistTaskDto): boolean {
      const assignedTo = this.companyUsers.find(u => u.id === task.responsible_contact.id);
      return assignedTo && assignedTo.organization_id !== this.currentUser.organization_id;
  }

  handleSelection(task) {
    const selectedTask = this.tasks.find(t => t.checklist_task_id === task.checklist_task_id);
    if (selectedTask) {
      selectedTask.selected = !selectedTask.selected;
    }
  }

  mustEnableBulkDelete() {
    return !!this.tasks.find(t => t.selected === true);
  }

  applyBulkDelete() {
    const selectedTasks = this.tasks.filter(t => t.selected === true);
    if (!selectedTasks) {
      return;
    }
    this.removeTask(selectedTasks);
  }

  async addFilesToTask(files, taskId) {
    if (files && files.length) {

      const fileObservers = files.map(file => {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.$company.addFileToTask(taskId, formData);
      });

      return forkJoin(fileObservers).subscribe(() => {
        this.onSubmit.emit('updated');
      }, (error) => {
        console.error(error);
        const errMsg =
          error.error && error.error.detail ? error.error.detail : 'An error occurred while uploading the files.';
        this.$toaster.show('error', 'File', errMsg);
      });
    }
    this.onSubmit.emit('updated');
  }

  async openAddFilesModal(task: ChecklistTaskDto) {
    const modalRef = this.matDialog.open(TaskFilesModalComponent, {
      width: '600px',
      height: '400px',
    });

    modalRef.componentInstance.task = task;
    modalRef.componentInstance.isCustomer = this.isCustomer;
    modalRef.componentInstance.allowEdit = this.allowEdit;

    modalRef.afterClosed()
      .subscribe((taskFiles) => {
        task.checklist_task_files = taskFiles;
      });
  }

  hasTasks() {
    return this.tasks && this.tasks.length > 0;
  }
}
