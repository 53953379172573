import { Component, OnInit } from '@angular/core';
// @ts-ignore
import * as clipboardCopy from 'clipboard-copy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from '@app/services';

@Component({
  selector: 'ngx-it-help',
  templateUrl: './it-help-modal.component.html',
  styleUrls: ['./it-help-modal.component.scss'],
})
export class ItHelpModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private $toaster: Toaster) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

  copyToClipboard(data) {
    clipboardCopy(data).then(() => {
      this.$toaster.show('success', 'Success', 'The address has been copied to your clipboard');
    });
  }

}
