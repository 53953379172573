/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroTaskCollection } from '../models/lasgro-task-collection';
import { LasgroGeneral_success_id_payload } from '../models/lasgro-general-_success-_id-_payload';
import { TaskInputPayload } from '../models/task-input-payload';
import { LasgroTaskDetails } from '../models/lasgro-task-details';
import { TaskUpdateInputPayload } from '../models/task-update-input-payload';
import { LasgroGeneral_success_payload } from '../models/lasgro-general-_success-_payload';
import { TaskActionInputPayload } from '../models/task-action-input-payload';
@Injectable({
  providedIn: 'root',
})
class TaskService extends __BaseService {
  static readonly taskListPath = '/organizations/{organizationID}/workflows/{workflowID}/tasks';
  static readonly taskAddPath = '/organizations/{organizationID}/workflows/{workflowID}/tasks';
  static readonly taskShowPath = '/organizations/{organizationID}/workflows/{workflowID}/tasks/{taskID}';
  static readonly taskUpdatePath = '/organizations/{organizationID}/workflows/{workflowID}/tasks/{taskID}';
  static readonly taskActionPath = '/workflows/{workflowID}/instances/{workflowInstanceID}/tasks/{taskID}/instances/{taskInstanceID}/action';
  static readonly taskUploadActionPath = '/workflows/{workflowID}/instances/{workflowInstanceID}/tasks/{taskID}/instances/{taskInstanceID}/upload/action';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list task
   *
   * List all of the tasks for a certain workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskListParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  taskListResponse(params: TaskService.TaskListParams): __Observable<__StrictHttpResponse<LasgroTaskCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTaskCollection>;
      })
    );
  }
  /**
   * list task
   *
   * List all of the tasks for a certain workflow
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskListParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  taskList(params: TaskService.TaskListParams): __Observable<LasgroTaskCollection> {
    return this.taskListResponse(params).pipe(
      __map(_r => _r.body as LasgroTaskCollection)
    );
  }

  /**
   * add task
   *
   * Create a new task definition under a worflow definition for a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskAddParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `payload`: Input for creating a new task definition
   *
   * - `organizationID`:
   *
   * @return Created
   */
  taskAddResponse(params: TaskService.TaskAddParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * add task
   *
   * Create a new task definition under a worflow definition for a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskAddParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `payload`: Input for creating a new task definition
   *
   * - `organizationID`:
   *
   * @return Created
   */
  taskAdd(params: TaskService.TaskAddParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.taskAddResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * show task
   *
   * Details of a task
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskShowParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  taskShowResponse(params: TaskService.TaskShowParams): __Observable<__StrictHttpResponse<LasgroTaskDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}/tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroTaskDetails>;
      })
    );
  }
  /**
   * show task
   *
   * Details of a task
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskShowParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  taskShow(params: TaskService.TaskShowParams): __Observable<LasgroTaskDetails> {
    return this.taskShowResponse(params).pipe(
      __map(_r => _r.body as LasgroTaskDetails)
    );
  }

  /**
   * update task
   *
   * Updates an existing task definition for a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskUpdateParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskID`:
   *
   * - `payload`: Input for updating an existing task definition
   *
   * - `organizationID`:
   *
   * @return OK
   */
  taskUpdateResponse(params: TaskService.TaskUpdateParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/workflows/${encodeURIComponent(String(params.workflowID))}/tasks/${encodeURIComponent(String(params.taskID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * update task
   *
   * Updates an existing task definition for a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskUpdateParams` containing the following parameters:
   *
   * - `workflowID`:
   *
   * - `taskID`:
   *
   * - `payload`: Input for updating an existing task definition
   *
   * - `organizationID`:
   *
   * @return OK
   */
  taskUpdate(params: TaskService.TaskUpdateParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.taskUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * action task
   *
   * detects an action performed on a task
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskActionParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `payload`: The request payload for whenever an action is performed on a task
   *
   * @return OK
   */
  taskActionResponse(params: TaskService.TaskActionParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    __body = params.payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/workflows/${encodeURIComponent(String(params.workflowID))}/instances/${encodeURIComponent(String(params.workflowInstanceID))}/tasks/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}/action`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * action task
   *
   * detects an action performed on a task
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskActionParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `payload`: The request payload for whenever an action is performed on a task
   *
   * @return OK
   */
  taskAction(params: TaskService.TaskActionParams): __Observable<LasgroGeneral_success_payload> {
    return this.taskActionResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * upload_action task
   *
   * detects an upload action performed on a task
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskUploadActionParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `file`: uploaded_file
   *
   * @return OK
   */
  taskUploadActionResponse(params: TaskService.TaskUploadActionParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;




    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/workflows/${encodeURIComponent(String(params.workflowID))}/instances/${encodeURIComponent(String(params.workflowInstanceID))}/tasks/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}/upload/action`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * upload_action task
   *
   * detects an upload action performed on a task
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `TaskService.TaskUploadActionParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `file`: uploaded_file
   *
   * @return OK
   */
  taskUploadAction(params: TaskService.TaskUploadActionParams): __Observable<LasgroGeneral_success_payload> {
    return this.taskUploadActionResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }
}

module TaskService {

  /**
   * Parameters for taskList
   */
  export interface TaskListParams {
    workflowID: string;
    organizationID: string;
  }

  /**
   * Parameters for taskAdd
   */
  export interface TaskAddParams {
    workflowID: string;

    /**
     * Input for creating a new task definition
     */
    payload: TaskInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for taskShow
   */
  export interface TaskShowParams {
    workflowID: string;
    taskID: string;
    organizationID: string;
  }

  /**
   * Parameters for taskUpdate
   */
  export interface TaskUpdateParams {
    workflowID: string;
    taskID: string;

    /**
     * Input for updating an existing task definition
     */
    payload: TaskUpdateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for taskAction
   */
  export interface TaskActionParams {
    workflowInstanceID: string;
    workflowID: string;
    taskInstanceID: string;
    taskID: string;

    /**
     * The request payload for whenever an action is performed on a task
     */
    payload: TaskActionInputPayload;
  }

  /**
   * Parameters for taskUploadAction
   */
  export interface TaskUploadActionParams {
    workflowInstanceID: string;
    workflowID: string;
    taskInstanceID: string;
    taskID: string;

    /**
     * uploaded_file
     */
    file: Blob;
  }
}

export { TaskService }
