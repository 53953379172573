import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '@app/app.config';
import { MessageDto } from '@app/models/message.model';
import { Notification } from '@models/notification.model';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationService {
  constructor(private httpClient: HttpClient) {}

  getNotifications(organizationId, filter = 'subscribed'): Observable<Notification[]> {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications`;

    const params = new HttpParams({
      fromObject: { filter },
    });

    return this.httpClient.get<Notification[]>(url, {
      params,
    });
  }

  clearAllNotification(organizationId: string) {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications`;
    return this.httpClient.delete(url);
  }

  clearNotification(organizationId: string, notificationId: string) {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications/${ notificationId }`;
    return this.httpClient.delete(url);
  }

  escalateNotification(organizationId: string, notificationId: string) {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications/${ notificationId }/escalate`;
    return this.httpClient.put(url, {});
  }

  postponeNotification(organizationId: string, notificationId: string, days: number) {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications/${ notificationId }/postpone`;
    const params = new HttpParams({ fromObject: { days: days.toString() } });
    return this.httpClient.patch(url, {}, { params });
  }

  getNotes(organizationId: string, notificationId: string): Observable<MessageDto[]> {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications/${ notificationId }/messages`;
    return this.httpClient.get<MessageDto[]>(url);
  }

  addNote(organizationId: string, notificationId: string, content: string) {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications/${ notificationId }/messages`;
    return this.httpClient.post(url, { content });
  }

  updateNote(organizationId: string, notificationId: string, nodeId: string, content: string) {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications/${ notificationId }/messages/${ nodeId }`;
    return this.httpClient.patch(url, { content });
  }

  deleteNote(organizationId: string, notificationId: string, nodeId: string) {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications/${ notificationId }/messages/${ nodeId }`;
    return this.httpClient.delete(url);
  }

  getMentions() {
    return this.httpClient.get(`${ CONFIG.API }/notes/my_mentions`);
  }

  notificationsRead(organizationId: string) {
    const url = `${ CONFIG.API }/organizations/${ organizationId }/notifications_readen`;
    return this.httpClient.post(url, {});
  }

  archiveMention(purchaseOrderId, noteId) {
    const url = `${ CONFIG.API }/purchase_orders/${ purchaseOrderId }/messages/${ noteId }/archive`;
    return this.httpClient.patch(url, {});
  }
}
