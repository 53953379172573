import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '@models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CompanyService } from '@services/company.service';
import { Toaster } from '@services/toaster.service';
import * as moment from 'moment';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TaskDetailComponent } from '@app/modules/checklist/modals/task-detail/task-detail.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { TaskNotificationModal } from '../../modals/task-notification/task-notification.modal';
import { ChecklistTaskDto } from '@app/models';

@Component({
  standalone: true,
  selector: 'ngx-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
    TaskNotificationModal
  ]
})
export class TaskItemComponent implements OnInit {
  @LocalStorage() organizationId;

  @Input() isCustomer: boolean = true;
  @Input() allowEdit: boolean = true;
  @Input() disabled: boolean = false;
  @Input() isCompleteButtonDisabled: boolean;
  @Input() draggable: boolean;
  @Input() task: ChecklistTaskDto;
  @Output() taskChange: EventEmitter<ChecklistTaskDto> = new EventEmitter<ChecklistTaskDto>();
  @Input() availableUsers;

  @Output() addFiles: EventEmitter<ChecklistTaskDto> = new EventEmitter<ChecklistTaskDto>();
  @Output() delete: EventEmitter<ChecklistTaskDto> = new EventEmitter<ChecklistTaskDto>();
  @Output() selectionEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  currentUser: User;
  user$: Observable<User>;

  constructor(private store: Store<{ user: User }>,
              private companyService: CompanyService,
              private $toaster: Toaster,
              private $modal: NgbModal) { }

  get isOverDue() {
    const dueDate = moment(this.task.due_date).endOf('day');
    return dueDate.isBefore(moment()) && !this.task.is_completed;
  }

  ngOnInit() {
    this.task.is_completed = !!this.task.completed_at;
    this.user$ = this.store.select('user');

    this.user$
      .pipe(take(1))
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  toggleComplete(task) {
    task.is_completed = !task.is_completed;
    this.taskUpdate(task);
  }

  taskUpdate(task) {
    if (this.disabled) return;
    this.companyService.updateTask(task.checklist_task_id, task).subscribe(
      () => {
        this.$toaster.show('success', 'Success', 'Task has been updated.');
        this.task = task;
        this.taskChange.emit();
      },
      error => {
        console.error(error);
        const errMsg =
          error.error && error.error.detail ? error.error.detail : 'An error occurred while updating the name.';
        this.$toaster.show('error', 'Task', errMsg);
      },
    );
  }

  deleteTask(task) {
    if (this.disabled) return;
    this.delete.emit(task);
  }

  selectionChange(task) {
    this.selectionEvent.emit(task);
  }

  openTask(task, $event) {
    if (this.currentUser.role == 'User') {
      return
    }
    const modalRef = this.$modal.open(TaskDetailComponent, {
      size: 'lg',
      container: 'nb-layout',
      keyboard: false,
    });

    modalRef.componentInstance.task = task;
    modalRef.componentInstance.isCustomer = this.isCustomer;
    modalRef.componentInstance.availableUsers = this.availableUsers;
    modalRef.componentInstance.allowEdit = this.allowEdit;
    modalRef.componentInstance.disabled = this.disabled;
    modalRef.componentInstance.taskChange.subscribe((t) => this.taskUpdate(t));
    modalRef.componentInstance.delete.subscribe((t) => this.deleteTask(t));

    modalRef.result.then((t) => {
      this.taskChange.emit(this.task);
    }, (r) => { console.log(r); });
  }

  openNitification(task) {
    const modalRef = this.$modal.open(TaskNotificationModal, {
      size: 'lg',
      container: 'nb-layout',
      keyboard: false,
    });
    if (this.task.mail_id) {
      modalRef.componentInstance.mailId = this.task.mail_id
    }
    modalRef.componentInstance.onSave.subscribe((id) => {
      this.task.mail_id = id;
      this.taskUpdate(this.task)
    });
    modalRef.result.then((t) => {
      this.taskChange.emit(this.task);
    }, (r) => { console.log(r); });
  }
}
