<mat-form-field>
  <input matInput
         autocomplete="off"
         [placeholder]="placeholder"
         (keypress)="keypress($event)"
         [ngModel]="dateRangeParsed" type="text" (click)="onFocusInput()">
  <span matSuffix (click)="onFocusInput()">
      <i class="fa fa-calendar-o"></i>
    </span>
</mat-form-field>

<ngx-filter-menu
  #filterMenu
  [ngClass]="{ 'd-none': !showCalendar }" (onFilter)="saveChanges($event)">
  <ngx-date-selector
    [fromDate]="fromDate"
    [untilDate]="toDate"
    (dateSelected)="dateSelected($event)"
  ></ngx-date-selector>
</ngx-filter-menu>
