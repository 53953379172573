import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import APP_CONFIG from '@app/app.config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageViewerComponent } from '@shared/modals/image-viewer/image-viewer.component';
import { PdfVisualizerComponent } from '@shared/modals/pdf-visualizer/pdf-visualizer.component';
import { saveAs } from 'file-saver';

@Injectable()
export class FileService {

  constructor(
    private httpClient: HttpClient,
    private $modal: NgbModal,
    private domSanitizer: DomSanitizer) {
  }

  viewPdf(blobFile: Blob, fileName: string) {
    const modal = this.$modal.open(PdfVisualizerComponent, {
      size: 'lg',
      windowClass: 'pdf-visualizer',
    });
    modal.componentInstance.title = fileName;
    modal.componentInstance.loading = true;

    modal.componentInstance.pdfViewer.pdfSrc = blobFile;
    modal.componentInstance.pdfViewer.refresh();
    modal.componentInstance.loading = false;
  }

  downloadFile(fileId: string) {
    return this.httpClient.get(`${ APP_CONFIG.API }/file/${ fileId }`, { observe: 'response', responseType: 'blob' });
  }

  downloadBlobFile(blobFile: Blob, fileName?: string) {
    const blob = new Blob([blobFile], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

  viewImage(blobFile: Blob, fileName: string) {
    const modal = this.$modal.open(ImageViewerComponent, {
      size: 'lg',
    });
    modal.componentInstance.loading = true;
    modal.componentInstance.title = fileName;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      modal.componentInstance.src = this.domSanitizer.bypassSecurityTrustUrl(fileReader.result as any);
      modal.componentInstance.loading = false;
    };
    fileReader.readAsDataURL(blobFile);
  }

}
