<ngx-loading [show]="loading || fileLoading"></ngx-loading>

<div class="container-fluid p-4">
  <div class="row">
    <div class="col">
      <h2>Alerts</h2>
      <h3>
        <small>You have {{ notifications?.length - completedTasks }} open actions</small>
      </h3>
    </div>
  </div>
</div>

<div class="container-fluid" style="background-color: #ebeff5;">
  <div class="row">
      <div class="col-md-12">

        <div class="row p-0 m-0">
          <div class="col-md-12 d-flex flex-row">
            <mat-checkbox [checked]="onlySubscriptions" (change)="changeFilter($event)">
              Subscriptions only
            </mat-checkbox>
            <!-- <mat-form-field style="max-width: 150px;" class="ml-2">
              <mat-select [(value)]="viewMode">
                <mat-option value="">Agenda</mat-option>
                <mat-option value="">List</mat-option>
                <mat-option value="">Calendar</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>
        </div>
        
        <div class="row p-0 m-0 border bg-white rounded shadow">

          <div class="col-md-4 list-side bg-light p-4 border-right">
            <dx-list
              class="list"
              [dataSource]="dataSource"
              [grouped]="true"
              [searchEnabled]="true"
              selectionMode="single"
              [selectedItemKeys]="[selectedNotification?.id]"
              (onSelectionChanged)="listSelectionChanged($event)"
            >
              <div *dxTemplate="let item of 'item'">
                <div>
                  <div [ngClass]="{
                    'text-secondary': !selectedNotification?.id || selectedNotification?.id !== item?.id,
                    'text-dark': selectedNotification?.id === item?.id
                  }">
                    <div class="font-weight-bold line-container">
                      {{item?.parent_resource_type ? item.parent_resource_type : 'Contract'}}
                      # {{item?.parent_resource_number}}
                    </div>
                    <div class="line-container">
                      {{item?.counterpartName}}
                    </div>
                    <div>
                      <small>
                        {{item?.created_at | amTimeAgo}}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div *dxTemplate="let item of 'group'">
                <div class="readable_type">
                  {{ item.key | titlecase }}
                </div>
              </div>
            </dx-list>
          </div>

          <div class="col-md-6 card-side p-4 d-flex flex-column justify-content-between" *ngIf="!!selectedNotification?.id && !loading">
            <!-- <div class="header">
              
            </div> -->
            <div class="body">
              <div class="content">
                <table class="table table-borderless table-striped table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">
                        Contract
                      </th>
                      <td>
                        {{ selectedNotification.purchase_order?.customer_po_number }}
                      </td>
                      <th scope="row">
                        Year
                      </th>
                      <td *ngIf="!!selectedNotification.purchase_order?.period">
                        {{ selectedNotification.purchase_order?.period }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Stage</th>
                      <td>
                        <!-- class="{{getRcdssContractStageClass(selectedNotification?.purchase_order)}}" -->
                        {{getRcdssContractStage(selectedNotification?.purchase_order)}}
                      </td>
                      <th scope="row">Status</th>
                      <td>
                        {{getRcdssStatus(selectedNotification?.purchase_order)}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ selectedNotification.counterpartRole }}
                      </th>
                      <td colspan="3">
                        {{ selectedNotification.counterpartName }}
                      </td>
                    </tr>
                    <tr *ngIf="selectedNotification?.task?.name">
                      <th scope="row">Task</th>
                      <td colspan="3">{{selectedNotification?.task?.name}}</td>
                    </tr>
                    <tr *ngIf="selectedNotification?.purchase_order?.rcdss_contract_program">
                      <th scope="row">Program</th>
                      <td colspan="3">{{selectedNotification?.purchase_order?.rcdss_contract_program}}</td>
                    </tr>
                    
                    <!-- INTEGRATE WITH TIMELINE -->
                    <!-- <tr>
                      <th scope="row">Active task</th>
                      <td></td>
                    </tr> -->
                    <!-- <tr>
                      <th scope="row"></th>
                      <td></td>
                    </tr> -->
                  </tbody>
                </table>
              </div>

              <div class="file-container p-4 bg-light border content d-flex flex-row">
                <a class="file-icon-container border" ngxFilesPreview (click)="fileLoading = true" (finishedLoading)="fileLoading = false" [fileId]="selectedNotification?.file?.file_id">
                  <i class="fa fa-file text-secondary p-4" style="font-size: 64px;"></i>
                </a>
                <div class="file-info-container pl-4 d-flex flex-column">
                  <a class="link" ngxFilesPreview (click)="fileLoading = true" (finishedLoading)="fileLoading = false" [fileId]="selectedNotification?.file?.file_id">
                    {{selectedNotification?.file?.file_name}} 
                  </a>
                  <small class="pt-2">
                    By {{selectedNotification?.file?.created_by_name}} at {{selectedNotification?.file?.created_at | amTimeAgo}}
                  </small>
                </div>
              </div>
            </div>
            <div class="footer">
              <span class="ref text-secondary">
                REF {{selectedNotification.notification_number}}
              </span>
              <span class="timestamp pull-right text-secondary">
                {{selectedNotification.created_at | amTimeAgo}} ({{selectedNotification.created_at | date: 'M/dd/yyyy H:mm'}})
              </span>
            </div>
            <!-- <i class="fa fa-file-excel-o mr-2"></i> -->
            <!-- <i class="fa fa-file-pdf-o mr-2"></i> -->
            <!-- <i class="fa fa-file-word-o mr-2"></i> -->
            <!-- <i class="fa fa-file-image-o mr-2"></i> -->
            <!-- <i class="fa fa-file-video-o mr-2"></i> -->
            <!-- <i class="fa fa-file-audio-o mr-2"></i> -->
            <!-- <i class="fa {{getIconClass}} mr-2"></i> -->
            
          </div>
          <div class="col-md-2 actions p-4 bg-light" *ngIf="!!selectedNotification?.id && !loading">
            <h6>Recommended Actions</h6>
            <button class="mb-2 btn btn-success"
                    *ngIf="selectedNotification?.purchase_order && shouldShowApprovalBtns(selectedNotification)"
                    (click)="acceptOrReject(selectedNotification, true)">Approve
            </button>
            <button class="mb-2 btn btn-danger"
                    *ngIf="selectedNotification?.purchase_order && shouldShowApprovalBtns(selectedNotification)"
                    (click)="acceptOrReject(selectedNotification, false)">Reject
            </button>
            <span class="completed-task-label" *ngIf="!!selectedNotification?.task?.completed_at">
              <i class="fa fa-check"></i> You completed this task
            </span>
            <button class="mb-2 btn btn-success"
                    [ngClass]="{'completed': !!selectedNotification?.task?.completed_at}"
                    *ngIf="selectedNotification?.resource_type === RESOURCE_TYPE.CHECKLIST_TASK"
                    (click)="completeTask(selectedNotification)">
              {{!!selectedNotification?.task?.completed_at ? 'Undo' : 'Mark As Completed'}}
            </button>

            <h6 class="border-bottom"></h6>

            <h6>Other Actions</h6>
            <button class="mb-2 btn btn-secondary" *ngIf="selectedNotification?.resource_type === RESOURCE_TYPE.CHECKLIST_TASK"
               (click)="addTaskFile(selectedNotification)">
              See attachments ({{selectedNotification?.task?.checklist_task_files?.length || 0}})
            </button>
            <button class="mb-2 btn btn-dark" (click)="openTask(selectedNotification)"
               *ngIf="selectedNotification?.resource_type === RESOURCE_TYPE.CHECKLIST_TASK">
              Open Task
            </button>
            <button class="mb-2 btn btn-dark" (click)="openPurchaseOrder(selectedNotification)">
              Open Contract
            </button>
            <button class="mb-2 btn btn-secondary" (click)="clearNotification(selectedNotification)"
               *ngIf="selectedNotification?.notification_type !== NOTIFICATION_TYPE.ONBOARDING_REJECTED">
              Clear
            </button>
          </div>
          <div class="col" *ngIf="!selectedNotification?.id">
            <div class="d-flex align-items-center" style="height: 100%;">
              <h2 class="text-center text-secondary w-100">
                Pick an alert from the left to open it
              </h2>
            </div>
          </div>
        </div>

      </div>
  </div>
</div>

