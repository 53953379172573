import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomPopupComponent } from '@app/@theme/components';
import { CompanyService, Toaster } from '@app/services';
import { LasgroFile_info } from 'api/models';
import { PurchaseOrderFilesService } from 'api/services';
import { saveAs } from 'file-saver';

@Component({
  selector: 'small-file-viewer',
  templateUrl: './small-file-viewer.component.html',
  styleUrls: ['./small-file-viewer.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule, 
    MatProgressBarModule, 
    CommonModule, 
    MatTooltipModule
  ],
})
export class SmallFileViewerComponent implements OnInit, AfterViewInit {
  fileName: string = '';
  downloaded: boolean = true;
  @Input() fileID;
  @Input() color = 'white';
  @Input() isEditable = false;
  @Output() deleteFile = new EventEmitter();
  constructor(private fileService: PurchaseOrderFilesService,
  private matDialog: MatDialog,
  private companyService: CompanyService,
  private toaster: Toaster) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.getFileInfo();
  }

  getFileInfo() {
    this.fileService.purchaseOrderFilesFileInfo(this.fileID).subscribe((response: LasgroFile_info) => {
      this.fileName = response.file_name;
    });
  }

  removeFile() {
    this.deleteFile.emit(this.fileID);
  }

  downloadFile() {
    this.downloaded = false;
    this.companyService
      .getFileById(this.fileID)
      .subscribe(
        (res: HttpResponse<Blob>) => {
          const blob = new Blob([res.body], {
            type: 'application/octet-stream',
          });
          saveAs(blob, this.fileName);
          this.downloaded = true;
        },
        error => {
          console.error(error);
          const errMsg =
            error.error && error.error.detail ? error.error.detail : 'An error occurred while downloading the files.';
          this.toaster.show('error', 'File', errMsg);
        },
      );
  }

}
