import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { TitleDividerComponent } from '../title-divider/title-divider.component';
// import { Action } from '@app/models';

@Component({
  standalone: true,
  selector: 'ngx-resource-actions',
  templateUrl: './resource-actions.component.html',
  styleUrls: ['./resource-actions.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    TitleDividerComponent
  ]
})
export class ResourceActionsComponent implements OnInit {
  @Input() actions: any[];

  hideLowPriority: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initializeActions();
  }

  initializeActions() {
    if (!this.actions) {
      return;
    }

    this.actions = this.actions.filter(a => !(a.type === 'READ' && a.resource_name !== 'purchase_orders'))
    this.actions.forEach(a => {
      a.display_text = "";
      a.display_text = a.display_text.concat(
        this.getInitiatorText(a),
        ' ',
        this.getActionText(a),
        ' ',
        this.getArticle(a),
        ' ',
        this.getResourceName(a),
        ' ',
        this.getAdditionalData(a),
      );
    });
  }

  getInitiatorText(a: any) {
    return a.initiator.name.concat(' ', '(', a.initiator.organization_name, ')');
  }

  getActionText(a: any) {
    let actionText = a.type.replace("_", " ").toLowerCase();

    switch(a.type) {
    case 'CHANGED_STATUS':
      return 'updated';
    case 'ADD_FILE':
    case 'ADD':
      return 'added';
    case 'DELETE_FILE':
      return 'deleted';
    case 'READ':
    case 'ACCEPTED':
    case 'REJECTED':
    case 'DELETED':
      return actionText;
    }

    if (!actionText.endsWith('d')) {
      actionText = actionText.concat('d');
    }

    return actionText;
  }

  getArticle(a: any) {
    switch(a.resource_name) {
    case 'tasks':
    case 'note':
      return 'a';
    }

    return 'the';
  }

  getResourceName(a: any) {
    let name = a.resource_name
      .toLowerCase()
      .replace('purchase_order', 'contract')
      .replace('note', 'message')
      .split('_')
      .map(x => this.capitalizeFirstLetter(x))
      .join(' ')
      .trim();

    if (name.endsWith('s')) {
      name = name.substring(0, name.length - 1);
    }

    return name;
  }

  getAdditionalData(a: any) {
    if (a.type.includes('FILE')) {
      return '\"'.concat(a.new_value, '\"');
    }

    if (a.resource_name.toLowerCase().includes('document') && !!a?.new_value) {
      return '\"'.concat(a.new_value, '\"');
    }

    return '';
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
