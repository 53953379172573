/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroResourceCollection } from '../models/lasgro-resource-collection';
import { LasgroGeneral_success_id_payload } from '../models/lasgro-general-_success-_id-_payload';
import { ResourceInputPayload } from '../models/resource-input-payload';
import { LasgroResource_column_typeCollection } from '../models/lasgro-resource-_column-_type-collection';
import { LasgroResource_typeCollection } from '../models/lasgro-resource-_type-collection';
import { ResourceTypeInputPayload } from '../models/resource-type-input-payload';
import { LasgroGeneral_success_payload } from '../models/lasgro-general-_success-_payload';
import { ResourceTypeUpdateInputPayload } from '../models/resource-type-update-input-payload';
import { LasgroResource_for_vendorsCollection } from '../models/lasgro-resource-_for-_vendors-collection';
import { LasgroResource_details } from '../models/lasgro-resource-_details';
import { ResourceUpdateInputPayload } from '../models/resource-update-input-payload';
@Injectable({
  providedIn: 'root',
})
class ResourcesService extends __BaseService {
  static readonly resourcesListPath = '/organizations/{organizationID}/resource-types/{resourceTypeID}/resources';
  static readonly resourcesAddPath = '/organizations/{organizationID}/resources';
  static readonly resourcesListColumnTypePath = '/organizations/{organizationID}/resources/columns/types';
  static readonly resourcesListResourceTypePath = '/organizations/{organizationID}/resources/types';
  static readonly resourcesAddResourceTypePath = '/organizations/{organizationID}/resources/types';
  static readonly resourcesUpdateResourceTypePath = '/organizations/{organizationID}/resources/types/{resourceTypeID}';
  static readonly resourcesDeactivateResourceTypePath = '/organizations/{organizationID}/resources/types/{resourceTypeID}';
  static readonly resourcesListResourcesForRelatedOrganizationsPath = '/organizations/{organizationID}/resources/vendors';
  static readonly resourcesShowPath = '/organizations/{organizationID}/resources/{resourceID}';
  static readonly resourcesUpdatePath = '/organizations/{organizationID}/resources/{resourceID}';
  static readonly resourcesDeletePath = '/organizations/{organizationID}/resources/{resourceID}';
  static readonly resourcesArchivePath = '/organizations/{organizationID}/resources/{resourceID}';
  static readonly resourcesRestorePath = '/organizations/{organizationID}/resources/{resourceID}/restore';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list resources
   *
   * Returns a list of generic resources
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesListParams` containing the following parameters:
   *
   * - `resourceTypeID`:
   *
   * - `organizationID`:
   *
   * - `isDeleted`:
   *
   * - `isArchived`:
   *
   * @return OK
   */
  resourcesListResponse(params: ResourcesService.ResourcesListParams): __Observable<__StrictHttpResponse<LasgroResourceCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    if (params.isArchived != null) __params = __params.set('isArchived', params.isArchived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resource-types/${encodeURIComponent(String(params.resourceTypeID))}/resources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroResourceCollection>;
      })
    );
  }
  /**
   * list resources
   *
   * Returns a list of generic resources
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesListParams` containing the following parameters:
   *
   * - `resourceTypeID`:
   *
   * - `organizationID`:
   *
   * - `isDeleted`:
   *
   * - `isArchived`:
   *
   * @return OK
   */
  resourcesList(params: ResourcesService.ResourcesListParams): __Observable<LasgroResourceCollection> {
    return this.resourcesListResponse(params).pipe(
      __map(_r => _r.body as LasgroResourceCollection)
    );
  }

  /**
   * add resources
   *
   * Creates a new parent resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesAddParams` containing the following parameters:
   *
   * - `payload`: The request payload for creating a new parent resource
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesAddResponse(params: ResourcesService.ResourcesAddParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * add resources
   *
   * Creates a new parent resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesAddParams` containing the following parameters:
   *
   * - `payload`: The request payload for creating a new parent resource
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesAdd(params: ResourcesService.ResourcesAddParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.resourcesAddResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * list_column_type resources
   *
   * Returns a list of parent resource type column types
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesListColumnTypeParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `resourceTypeID`:
   *
   * @return OK
   */
  resourcesListColumnTypeResponse(params: ResourcesService.ResourcesListColumnTypeParams): __Observable<__StrictHttpResponse<LasgroResource_column_typeCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.resourceTypeID != null) __params = __params.set('resourceTypeID', params.resourceTypeID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/columns/types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroResource_column_typeCollection>;
      })
    );
  }
  /**
   * list_column_type resources
   *
   * Returns a list of parent resource type column types
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesListColumnTypeParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `resourceTypeID`:
   *
   * @return OK
   */
  resourcesListColumnType(params: ResourcesService.ResourcesListColumnTypeParams): __Observable<LasgroResource_column_typeCollection> {
    return this.resourcesListColumnTypeResponse(params).pipe(
      __map(_r => _r.body as LasgroResource_column_typeCollection)
    );
  }

  /**
   * list_resource_type resources
   *
   * Returns a list of generic resource type
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  resourcesListResourceTypeResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroResource_typeCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/resources/types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroResource_typeCollection>;
      })
    );
  }
  /**
   * list_resource_type resources
   *
   * Returns a list of generic resource type
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  resourcesListResourceType(organizationID: string): __Observable<LasgroResource_typeCollection> {
    return this.resourcesListResourceTypeResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroResource_typeCollection)
    );
  }

  /**
   * add_resource_type resources
   *
   * Create a new generic resource type
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesAddResourceTypeParams` containing the following parameters:
   *
   * - `payload`: The request payload for creating resource type
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesAddResourceTypeResponse(params: ResourcesService.ResourcesAddResourceTypeParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * add_resource_type resources
   *
   * Create a new generic resource type
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesAddResourceTypeParams` containing the following parameters:
   *
   * - `payload`: The request payload for creating resource type
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesAddResourceType(params: ResourcesService.ResourcesAddResourceTypeParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.resourcesAddResourceTypeResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * update_resource_type resources
   *
   * Updates a resource type
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesUpdateResourceTypeParams` containing the following parameters:
   *
   * - `resourceTypeID`:
   *
   * - `payload`: The request payload for updating an existing resource type
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesUpdateResourceTypeResponse(params: ResourcesService.ResourcesUpdateResourceTypeParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/types/${encodeURIComponent(String(params.resourceTypeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * update_resource_type resources
   *
   * Updates a resource type
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesUpdateResourceTypeParams` containing the following parameters:
   *
   * - `resourceTypeID`:
   *
   * - `payload`: The request payload for updating an existing resource type
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesUpdateResourceType(params: ResourcesService.ResourcesUpdateResourceTypeParams): __Observable<LasgroGeneral_success_payload> {
    return this.resourcesUpdateResourceTypeResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * deactivate_resource_type resources
   *
   * Deactivates a resource type
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesDeactivateResourceTypeParams` containing the following parameters:
   *
   * - `resourceTypeID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesDeactivateResourceTypeResponse(params: ResourcesService.ResourcesDeactivateResourceTypeParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/types/${encodeURIComponent(String(params.resourceTypeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * deactivate_resource_type resources
   *
   * Deactivates a resource type
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesDeactivateResourceTypeParams` containing the following parameters:
   *
   * - `resourceTypeID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesDeactivateResourceType(params: ResourcesService.ResourcesDeactivateResourceTypeParams): __Observable<LasgroGeneral_success_payload> {
    return this.resourcesDeactivateResourceTypeResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * list_resources_for_related_organizations resources
   *
   * Returns a list of generic resources for related organziations
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesListResourcesForRelatedOrganizationsParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `isDeleted`:
   *
   * - `isArchived`:
   *
   * @return OK
   */
  resourcesListResourcesForRelatedOrganizationsResponse(params: ResourcesService.ResourcesListResourcesForRelatedOrganizationsParams): __Observable<__StrictHttpResponse<LasgroResource_for_vendorsCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    if (params.isArchived != null) __params = __params.set('isArchived', params.isArchived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/vendors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroResource_for_vendorsCollection>;
      })
    );
  }
  /**
   * list_resources_for_related_organizations resources
   *
   * Returns a list of generic resources for related organziations
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesListResourcesForRelatedOrganizationsParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `isDeleted`:
   *
   * - `isArchived`:
   *
   * @return OK
   */
  resourcesListResourcesForRelatedOrganizations(params: ResourcesService.ResourcesListResourcesForRelatedOrganizationsParams): __Observable<LasgroResource_for_vendorsCollection> {
    return this.resourcesListResourcesForRelatedOrganizationsResponse(params).pipe(
      __map(_r => _r.body as LasgroResource_for_vendorsCollection)
    );
  }

  /**
   * show resources
   *
   * Returns a specific generic resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesShowParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesShowResponse(params: ResourcesService.ResourcesShowParams): __Observable<__StrictHttpResponse<LasgroResource_details>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroResource_details>;
      })
    );
  }
  /**
   * show resources
   *
   * Returns a specific generic resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesShowParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesShow(params: ResourcesService.ResourcesShowParams): __Observable<LasgroResource_details> {
    return this.resourcesShowResponse(params).pipe(
      __map(_r => _r.body as LasgroResource_details)
    );
  }

  /**
   * update resources
   *
   * Updates an existing parent resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesUpdateParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`: The request payload for create a new parent resource
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesUpdateResponse(params: ResourcesService.ResourcesUpdateParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * update resources
   *
   * Updates an existing parent resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesUpdateParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `payload`: The request payload for create a new parent resource
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesUpdate(params: ResourcesService.ResourcesUpdateParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.resourcesUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * delete resources
   *
   * Deletes a specific generic resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesDeleteParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesDeleteResponse(params: ResourcesService.ResourcesDeleteParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * delete resources
   *
   * Deletes a specific generic resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesDeleteParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesDelete(params: ResourcesService.ResourcesDeleteParams): __Observable<LasgroGeneral_success_payload> {
    return this.resourcesDeleteResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * archive resources
   *
   * Archives a specific generic resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesArchiveParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesArchiveResponse(params: ResourcesService.ResourcesArchiveParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * archive resources
   *
   * Archives a specific generic resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesArchiveParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesArchive(params: ResourcesService.ResourcesArchiveParams): __Observable<LasgroGeneral_success_payload> {
    return this.resourcesArchiveResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * restore resources
   *
   * Restores a specific generic resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesRestoreParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesRestoreResponse(params: ResourcesService.ResourcesRestoreParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/resources/${encodeURIComponent(String(params.resourceID))}/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * restore resources
   *
   * Restores a specific generic resource
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `ResourcesService.ResourcesRestoreParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  resourcesRestore(params: ResourcesService.ResourcesRestoreParams): __Observable<LasgroGeneral_success_payload> {
    return this.resourcesRestoreResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }
}

module ResourcesService {

  /**
   * Parameters for resourcesList
   */
  export interface ResourcesListParams {
    resourceTypeID: string;
    organizationID: string;
    isDeleted?: boolean;
    isArchived?: boolean;
  }

  /**
   * Parameters for resourcesAdd
   */
  export interface ResourcesAddParams {

    /**
     * The request payload for creating a new parent resource
     */
    payload: ResourceInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for resourcesListColumnType
   */
  export interface ResourcesListColumnTypeParams {
    organizationID: string;
    resourceTypeID?: string;
  }

  /**
   * Parameters for resourcesAddResourceType
   */
  export interface ResourcesAddResourceTypeParams {

    /**
     * The request payload for creating resource type
     */
    payload: ResourceTypeInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for resourcesUpdateResourceType
   */
  export interface ResourcesUpdateResourceTypeParams {
    resourceTypeID: string;

    /**
     * The request payload for updating an existing resource type
     */
    payload: ResourceTypeUpdateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for resourcesDeactivateResourceType
   */
  export interface ResourcesDeactivateResourceTypeParams {
    resourceTypeID: string;
    organizationID: string;
  }

  /**
   * Parameters for resourcesListResourcesForRelatedOrganizations
   */
  export interface ResourcesListResourcesForRelatedOrganizationsParams {
    organizationID: string;
    isDeleted?: boolean;
    isArchived?: boolean;
  }

  /**
   * Parameters for resourcesShow
   */
  export interface ResourcesShowParams {
    resourceID: string;
    organizationID: string;
  }

  /**
   * Parameters for resourcesUpdate
   */
  export interface ResourcesUpdateParams {
    resourceID: string;

    /**
     * The request payload for create a new parent resource
     */
    payload: ResourceUpdateInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for resourcesDelete
   */
  export interface ResourcesDeleteParams {
    resourceID: string;
    organizationID: string;
  }

  /**
   * Parameters for resourcesArchive
   */
  export interface ResourcesArchiveParams {
    resourceID: string;
    organizationID: string;
  }

  /**
   * Parameters for resourcesRestore
   */
  export interface ResourcesRestoreParams {
    resourceID: string;
    organizationID: string;
  }
}

export { ResourcesService }
