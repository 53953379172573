/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LineItemStepInputPayload } from '../models/line-item-step-input-payload';
import { LasgroLine_itemCollection } from '../models/lasgro-line-_item-collection';
import { AddLineItemPayload } from '../models/add-line-item-payload';
import { LasgroLine_item } from '../models/lasgro-line-_item';
import { LineItemInputPayload } from '../models/line-item-input-payload';
@Injectable({
  providedIn: 'root',
})
class LineItemService extends __BaseService {
  static readonly lineItemStepProgressPath = '/line_items/{lineItemID}/step_progress/{stepID}';
  static readonly lineItemDeletePath = '/line_items/{listItemID}';
  static readonly lineItemListPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/line_items';
  static readonly lineItemAddPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/line_items';
  static readonly lineItemShowPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/line_items/{lineItemID}';
  static readonly lineItemUpdatePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/line_items/{lineItemID}';
  static readonly lineItemList1Path = '/purchase_orders/{purchaseOrderID}/line_items';
  static readonly lineItemAdd1Path = '/purchase_orders/{purchaseOrderID}/line_items';
  static readonly lineItemShow1Path = '/purchase_orders/{purchaseOrderID}/line_items/{lineItemID}';
  static readonly lineItemUpdate1Path = '/purchase_orders/{purchaseOrderID}/line_items/{lineItemID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * step_progress line_item
   *
   * Updates the progress of a step to produce for line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemStepProgressParams` containing the following parameters:
   *
   * - `stepID`:
   *
   * - `payload`:
   *
   * - `lineItemID`: Line Item ID
   */
  lineItemStepProgressResponse(params: LineItemService.LineItemStepProgressParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/line_items/${encodeURIComponent(String(params.lineItemID))}/step_progress/${encodeURIComponent(String(params.stepID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * step_progress line_item
   *
   * Updates the progress of a step to produce for line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemStepProgressParams` containing the following parameters:
   *
   * - `stepID`:
   *
   * - `payload`:
   *
   * - `lineItemID`: Line Item ID
   */
  lineItemStepProgress(params: LineItemService.LineItemStepProgressParams): __Observable<null> {
    return this.lineItemStepProgressResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete line_item
   *
   * Required security scopes:
   *   * `api:access`
   * @param listItemID List Item ID
   */
  lineItemDeleteResponse(listItemID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/line_items/${encodeURIComponent(String(listItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete line_item
   *
   * Required security scopes:
   *   * `api:access`
   * @param listItemID List Item ID
   */
  lineItemDelete(listItemID: string): __Observable<null> {
    return this.lineItemDeleteResponse(listItemID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list line_item
   *
   * Lists line items
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  lineItemListResponse(params: LineItemService.LineItemListParams): __Observable<__StrictHttpResponse<LasgroLine_itemCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/line_items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroLine_itemCollection>;
      })
    );
  }
  /**
   * list line_item
   *
   * Lists line items
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  lineItemList(params: LineItemService.LineItemListParams): __Observable<LasgroLine_itemCollection> {
    return this.lineItemListResponse(params).pipe(
      __map(_r => _r.body as LasgroLine_itemCollection)
    );
  }

  /**
   * add line_item
   *
   * Adds a new line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemAddParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  lineItemAddResponse(params: LineItemService.LineItemAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/line_items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add line_item
   *
   * Adds a new line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemAddParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  lineItemAdd(params: LineItemService.LineItemAddParams): __Observable<null> {
    return this.lineItemAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show line_item
   *
   * Retrieve line item with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemShowParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`: Line Item ID
   *
   * @return OK
   */
  lineItemShowResponse(params: LineItemService.LineItemShowParams): __Observable<__StrictHttpResponse<LasgroLine_item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroLine_item>;
      })
    );
  }
  /**
   * show line_item
   *
   * Retrieve line item with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemShowParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`: Line Item ID
   *
   * @return OK
   */
  lineItemShow(params: LineItemService.LineItemShowParams): __Observable<LasgroLine_item> {
    return this.lineItemShowResponse(params).pipe(
      __map(_r => _r.body as LasgroLine_item)
    );
  }

  /**
   * update line_item
   *
   * Updates a line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemUpdateParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`: Line Item ID
   */
  lineItemUpdateResponse(params: LineItemService.LineItemUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update line_item
   *
   * Updates a line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemUpdateParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `lineItemID`: Line Item ID
   */
  lineItemUpdate(params: LineItemService.LineItemUpdateParams): __Observable<null> {
    return this.lineItemUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list line_item
   *
   * Lists line items
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  lineItemList1Response(params: LineItemService.LineItemList1Params): __Observable<__StrictHttpResponse<LasgroLine_itemCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/line_items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroLine_itemCollection>;
      })
    );
  }
  /**
   * list line_item
   *
   * Lists line items
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  lineItemList1(params: LineItemService.LineItemList1Params): __Observable<LasgroLine_itemCollection> {
    return this.lineItemList1Response(params).pipe(
      __map(_r => _r.body as LasgroLine_itemCollection)
    );
  }

  /**
   * add line_item
   *
   * Adds a new line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemAdd1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  lineItemAdd1Response(params: LineItemService.LineItemAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/line_items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add line_item
   *
   * Adds a new line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemAdd1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   */
  lineItemAdd1(params: LineItemService.LineItemAdd1Params): __Observable<null> {
    return this.lineItemAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show line_item
   *
   * Retrieve line item with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `lineItemID`: Line Item ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  lineItemShow1Response(params: LineItemService.LineItemShow1Params): __Observable<__StrictHttpResponse<LasgroLine_item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroLine_item>;
      })
    );
  }
  /**
   * show line_item
   *
   * Retrieve line item with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `lineItemID`: Line Item ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  lineItemShow1(params: LineItemService.LineItemShow1Params): __Observable<LasgroLine_item> {
    return this.lineItemShow1Response(params).pipe(
      __map(_r => _r.body as LasgroLine_item)
    );
  }

  /**
   * update line_item
   *
   * Updates a line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `lineItemID`: Line Item ID
   *
   * - `organizationID`:
   */
  lineItemUpdate1Response(params: LineItemService.LineItemUpdate1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/line_items/${encodeURIComponent(String(params.lineItemID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update line_item
   *
   * Updates a line item
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `LineItemService.LineItemUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `lineItemID`: Line Item ID
   *
   * - `organizationID`:
   */
  lineItemUpdate1(params: LineItemService.LineItemUpdate1Params): __Observable<null> {
    return this.lineItemUpdate1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LineItemService {

  /**
   * Parameters for lineItemStepProgress
   */
  export interface LineItemStepProgressParams {
    stepID: string;
    payload: LineItemStepInputPayload;

    /**
     * Line Item ID
     */
    lineItemID: string;
  }

  /**
   * Parameters for lineItemList
   */
  export interface LineItemListParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for lineItemAdd
   */
  export interface LineItemAddParams {
    purchaseOrderID: string;
    payload: AddLineItemPayload;
    organizationID: string;
  }

  /**
   * Parameters for lineItemShow
   */
  export interface LineItemShowParams {
    purchaseOrderID: string;
    organizationID: string;

    /**
     * Line Item ID
     */
    lineItemID: string;
  }

  /**
   * Parameters for lineItemUpdate
   */
  export interface LineItemUpdateParams {
    purchaseOrderID: string;
    payload: LineItemInputPayload;
    organizationID: string;

    /**
     * Line Item ID
     */
    lineItemID: string;
  }

  /**
   * Parameters for lineItemList1
   */
  export interface LineItemList1Params {
    purchaseOrderID: string;
    organizationID?: string;
  }

  /**
   * Parameters for lineItemAdd1
   */
  export interface LineItemAdd1Params {
    purchaseOrderID: string;
    payload: AddLineItemPayload;
    organizationID?: string;
  }

  /**
   * Parameters for lineItemShow1
   */
  export interface LineItemShow1Params {
    purchaseOrderID: string;

    /**
     * Line Item ID
     */
    lineItemID: string;
    organizationID?: string;
  }

  /**
   * Parameters for lineItemUpdate1
   */
  export interface LineItemUpdate1Params {
    purchaseOrderID: string;
    payload: LineItemInputPayload;

    /**
     * Line Item ID
     */
    lineItemID: string;
    organizationID?: string;
  }
}

export { LineItemService }
