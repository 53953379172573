<div class="modal-header po-modal">
  <h4>Task email notification</h4>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <span>Please write out the message detail for this task</span>
  <app-mail [subVariables]="['task_name']" [mailId]="mailId" [type]="type" (onSave)="onSaveClick($event)"></app-mail>
</div>
