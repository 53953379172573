import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Injector, OnInit } from '@angular/core';
import { User } from '@app/models';
import { PoFilesComponent } from '@app/modules/po-details/components/po-files/po-files.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TitleDividerComponent } from '../../title-divider/title-divider.component';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-vouchers',
  templateUrl: './contract-vouchers.component.html',
  styleUrls: ['./contract-vouchers.component.scss'],
  imports: [
    CommonModule,
    TitleDividerComponent,
    PoFilesComponent
  ]
})
export class ContractVouchersComponent  extends ContractBaseComponent implements OnInit {
  addSubmittedVoucherFileEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor(public injector: Injector,
    private modal: NgbModal,
    public store: Store<{ user: User }>) {
    super(injector, store)
   }

  ngOnInit(): void {
  }
  
  // openFinancialReportDialog() {
  //   let activeModal = this.modal.open(FinancialReportModal, {
  //     size: 'xl',
  //     container: 'nb-layout',
  //     backdrop: 'static',
  //   });
  //   if (!this.contract.financial_report) {
  //     this.contract.financial_report = {
  //       purchaseOrderId: this.contract.id,
  //       financialReportServiceCategories: [],
  //     } as LasgroFinancial_report;
  //     if (this.contract.service_categories.length > 0) {
  //       this.contract.service_categories.forEach(
  //         (purchaseOrderServicecategory: LasgroPurchase_order_service_category) => {
  //           this.contract.financial_report.financialReportServiceCategories.push({
  //             serviceCategoryId: purchaseOrderServicecategory.serviceCategoryId,
  //             serviceCategory: purchaseOrderServicecategory.serviceCategory,
  //             contractualAmount: 0,
  //             quarterVoucher1: 0,
  //             quarterVoucher2: 0,
  //             quarterVoucher3: 0,
  //             quarterVoucher4: 0,
  //             pricePerUnit: 0,
  //           } as LasgroFinancial_report_service_category);
  //         },
  //       );
  //     }
  //   }
  //   activeModal.componentInstance.po = this.contract;
  // }
}
