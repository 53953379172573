import { Component, OnInit, Input, HostListener, ElementRef, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ngx-filter-menu-btn',
  templateUrl: './filter-menu-btn.component.html',
  styleUrls: ['./filter-menu-btn.component.scss'],
})
export class FilterMenuBtnComponent implements OnInit  {
  showDropdownMenu = false;
  @Input() key: string = '';
  @Input() title: string = '';
  @Input() showClearBtn: boolean = false;

  @Output() onMenuClose = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<any>();

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    const clickedOutside = !this._el.nativeElement.contains(event.target);
    if (clickedOutside) {
      this.showDropdownMenu = false;
      this.onMenuClose.emit();
    }
  }

  constructor(private _el: ElementRef) { }

  ngOnInit() {
  }

  onFilterButtonClicked() {
    this.showDropdownMenu = !this.showDropdownMenu;
  }

  show() {
    this.showDropdownMenu = true;
  }

  hide() {
    this.showDropdownMenu = false;
  }
}
