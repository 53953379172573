/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ShipmentBookingInputPayload } from '../models/shipment-booking-input-payload';
import { LasgroPricing_estimator_output } from '../models/lasgro-pricing-_estimator-_output';
import { PricingEstimatorInputPayload } from '../models/pricing-estimator-input-payload';
@Injectable({
  providedIn: 'root',
})
class PricingEstimatorService extends __BaseService {
  static readonly pricingEstimatorAddPath = '/organizations/{organizationID}/shipment_booking';
  static readonly pricingEstimatorShowPath = '/shipment_pricing_estimator';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * add pricing_estimator
   *
   * Books a shipment from an estimation made through the platform.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PricingEstimatorService.PricingEstimatorAddParams` containing the following parameters:
   *
   * - `payload`: Input to book via email a shipment previously estimated on the platform.
   *
   * - `organizationID`: Organization ID
   */
  pricingEstimatorAddResponse(params: PricingEstimatorService.PricingEstimatorAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/shipment_booking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add pricing_estimator
   *
   * Books a shipment from an estimation made through the platform.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PricingEstimatorService.PricingEstimatorAddParams` containing the following parameters:
   *
   * - `payload`: Input to book via email a shipment previously estimated on the platform.
   *
   * - `organizationID`: Organization ID
   */
  pricingEstimatorAdd(params: PricingEstimatorService.PricingEstimatorAddParams): __Observable<null> {
    return this.pricingEstimatorAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show pricing_estimator
   *
   * Returns the estimated price for sending a specific type of container from a port to another for a specific date.
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload Input to get the estimated price for sending a specific type of container from a port to another for a specific date
   * @return OK
   */
  pricingEstimatorShowResponse(payload: PricingEstimatorInputPayload): __Observable<__StrictHttpResponse<LasgroPricing_estimator_output>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shipment_pricing_estimator`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPricing_estimator_output>;
      })
    );
  }
  /**
   * show pricing_estimator
   *
   * Returns the estimated price for sending a specific type of container from a port to another for a specific date.
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload Input to get the estimated price for sending a specific type of container from a port to another for a specific date
   * @return OK
   */
  pricingEstimatorShow(payload: PricingEstimatorInputPayload): __Observable<LasgroPricing_estimator_output> {
    return this.pricingEstimatorShowResponse(payload).pipe(
      __map(_r => _r.body as LasgroPricing_estimator_output)
    );
  }
}

module PricingEstimatorService {

  /**
   * Parameters for pricingEstimatorAdd
   */
  export interface PricingEstimatorAddParams {

    /**
     * Input to book via email a shipment previously estimated on the platform.
     */
    payload: ShipmentBookingInputPayload;

    /**
     * Organization ID
     */
    organizationID: string;
  }
}

export { PricingEstimatorService }
