<div class="modal-header po-modal font-weight-bold">
  Item Attachments
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ev-form po-modal">
  <ul>
    <ng-container>
      <li *ngFor="let file of files" class="row file-row file-list">
        <div [class.col-md-12]="file.is_main_file">
          <div class="document-icon">
            <i class="fa fa-file"></i>
          </div>
          <div class="file-info-container">
            <a ngxFilesPreview [fileId]="file.file_id">
              {{ file.file_name }}
            </a>
            <div class="file-additional-info-container">
              {{ file.file_size | fileSize }}
              <span class="file task-name" title="{{ file.parent_task_name }}" *ngIf="file.parent_task_name">
                                {{ file.parent_task_name }}
                            </span>
              {{ getFileDate(file.created_at) }} by
              <span class="author" title="{{ file.created_by_name }}">{{ file.created_by_name }}</span>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
  <div *ngIf="!files || files.length === 0">
    <span class="no-files">This item doesn't have any attachments.</span>
  </div>
</div>

<!-- <div class="modal-footer po-modal">

</div> -->

