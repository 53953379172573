import { Action } from '@ngrx/store';
import { User } from '@models/index';

export const FETCH = '[User] Fetch';
export const FETCH_SUCCESS = '[User] Fetch Success';
export const FETCH_FAILED = '[User] Fetch Failed';
export const CLEAR = '[User] Clear';

export class Fetch implements Action {
  readonly type = FETCH;
}

export class FetchSuccess implements Action {
  readonly type = FETCH_SUCCESS;

  constructor(public payload: User) {}
}

export class FetchFailed implements Action {
  readonly type = FETCH_FAILED;

  constructor(public payload: Error) {}
}

export class Clear implements Action {
  readonly type = CLEAR;
}

export type All = Fetch | FetchSuccess | FetchFailed | Clear;
