import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { User } from '@app/models';
import { Toaster } from '@app/services';
import { TitleDividerComponent } from '@app/shared/components';
import { TimeAgoPipe } from '@app/shared/pipes/time-ago.pipe';
import { Store } from '@ngrx/store';
import { ToasterModule } from 'angular2-toaster';
import { LasgroPurchase_order_comments } from 'api/models';
import { PurchaseOrderCommentsService } from 'api/services';
import { NgxLoadingModule } from 'ngx-loading';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'po-comments',
  templateUrl: './po-comments.component.html',
  styleUrls: ['./po-comments.component.scss'],
  imports: [
    CommonModule,
    NgxLoadingModule,
    TitleDividerComponent,
    ToasterModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    TimeAgoPipe
  ],
})
export class PoCommentsComponent implements OnInit {
  @Input() poID: string;
  @Input() userId: string;
  @Input() comments = []
  comment = {} as any
  loading = false;
  user$: Observable<User>;
  currentUser: User;
  
  constructor(private purchaseOrderCommentsService: PurchaseOrderCommentsService, 
    private detector: ChangeDetectorRef,
     private toaster: Toaster,
     private store: Store<{ user: User }>) { }

  ngOnInit(): void {
    this.user$ = this.store.select('user');

    this.user$
      .pipe(take(1))
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  onSendCommentClick() {
    if (this.comment.id) {
      this.purchaseOrderCommentsService.purchaseOrderCommentsUpdate(this.comment).subscribe((response: LasgroPurchase_order_comments) => {
        this.comment = {};
        this.refreshComments();
        this.toaster.show('success', '', `Comment was updated successfully`);
      }, (error) => {
        console.error(error);
        this.toaster.show('error', 'Error', 'An error occurred, please try again later.');
      });
    } else {
      this.comment.userId = this.userId;
      this.comment.purchaseOrderId = this.poID;
      this.comment.createdAt = new Date().toJSON();
      this.purchaseOrderCommentsService.purchaseOrderCommentsAdd(this.comment).subscribe((response: string) => {
        this.comment.id = response;
        this.refreshComments();
        this.comment = {};
        this.toaster.show('success', '', `Comment was created successfully`);
      }, (error) => {
        console.error(error);
        this.toaster.show('error', 'Error', 'An error occurred, please try again later.');
      });
    }
  }

  onRemoveCommentClick(comment) {
    Swal.fire({
      title: 'Are you sure?',
      text: `This comment will be removed.`,
      icon: 'question',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then((result) => {
      if (result.value) {
        this.purchaseOrderCommentsService.purchaseOrderCommentsDelete(comment.id)
        .subscribe(() => {
          this.refreshComments();
          this.toaster.show('success', '', `Comment was removed successfully`);
        }, (error) => {
          console.error(error);
          this.toaster.show('error', 'Error', 'An error occurred, please try again later.');
        });
      }
    });
  }

  onEditCommentClick(comment) {
    this.comment = comment;
  }

  refreshComments() {
    this.purchaseOrderCommentsService.purchaseOrderCommentsList(this.poID).subscribe((response) => {
      this.comments = response;
      this.detector.detectChanges();
    }, (error) => {
      console.error(error);
      this.toaster.show('error', 'Error', 'An error occurred, please try again later.');
    });
  }

}
