import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Broadcaster, EventTypes, SearchService } from '@services/index';

import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable()
export class POSearchService {
  toggleMenu$: Subject<[string, boolean]>;
  private searchObject = {
    number: {
      active: false,
      value: null,
    },
    product: {
      active: false,
      value: null,
    },
    company: {
      active: false,
      value: null,
    },
    order: {
      active: false,
      value: null,
    },
    ship: {
      active: false,
      value: null,
    },
    cancel: {
      active: false,
      value: null,
    },
    status: {
      active: false,
      value: null,
    },
    completed: {
      active: false,
      value: null,
    },
  };

  constructor(private $broadcaster: Broadcaster,
              private router: Router,
              private $search: SearchService) {
    this.toggleMenu$ = new Subject<[string, boolean]>();
  }

  toggleMenu(key: string, value: boolean) {
    this.toggleMenu$.next([key, value]);
  }

  addSearchParameter(key: string, value: any) {
    if (value === null || value === undefined) return;
    this.searchObject[key].active = !value ? false : true;
    this.searchObject[key].value = value;
    this.searchPurchaseOrders();
  }

  clearSearchParameter(key: string) {
    this.searchObject[key].active = false;
    this.searchObject[key].value = null;
    this.searchPurchaseOrders();
  }

  cleanSearch() {
    Object.keys(this.searchObject).forEach(k => {
      this.searchObject[k].active = false;
      this.searchObject[k].value = null;
    });
  }

  isActiveSearch(key: string) {
    return this.searchObject[key].active;
  }

  getValue(key: string) {
    return this.searchObject[key].value;
  }

  buildQuery(isCustomer: boolean) {
    const queryElems: Array<string> = [];

    if (this.isActiveSearch('number')) queryElems.push(`customer_po_number=${ this.getValue('number') }`);

    if (this.isActiveSearch('product')) queryElems.push(`line_item=${ this.getValue('product') }`);

    if (this.isActiveSearch('company') && isCustomer) queryElems.push(`supplier=${ this.getValue('company') }`);

    if (this.isActiveSearch('company') && !isCustomer) queryElems.push(`customer_name=${ this.getValue('company') }`);

    if (this.isActiveSearch('order')) {
      if (!!this.getValue('order')[1]) {
        const ngbDate0 = this.getValue('order')[0];
        const date0 = new Date(ngbDate0.year, ngbDate0.month - 1, ngbDate0.day, 0, 0, 0, 0);
        const ngbDate1 = this.getValue('order')[1];
        const date1 = new Date(ngbDate1.year, ngbDate1.month - 1, ngbDate1.day, 0, 0, 0, 0);
        const fromDate = moment(date0).format('YYYY-MM-DD');
        const toDate = moment(date1).format('YYYY-MM-DD');
        queryElems.push(`order_date=${ fromDate }_${ toDate }`);
        queryElems.push(`order_date_match_type=between`);
      } else {
        const ngbDate0 = this.getValue('order')[0];
        const date0 = new Date(ngbDate0.year, ngbDate0.month - 1, ngbDate0.day, 0, 0, 0, 0);
        const fromDate = moment(date0).format('YYYY-MM-DD');
        queryElems.push(`order_date=${ fromDate }`);
        queryElems.push(`order_date_match_type=exact`);
      }
    }

    if (this.isActiveSearch('ship')) {
      if (!!this.getValue('ship')[1]) {
        const ngbDate0 = this.getValue('ship')[0];
        const date0 = new Date(ngbDate0.year, ngbDate0.month - 1, ngbDate0.day, 0, 0, 0, 0);
        const ngbDate1 = this.getValue('ship')[1];
        const date1 = new Date(ngbDate1.year, ngbDate1.month - 1, ngbDate1.day, 0, 0, 0, 0);
        const fromDate = moment(date0).format('YYYY-MM-DD');
        const toDate = moment(date1).format('YYYY-MM-DD');
        queryElems.push(`ship_date=${ fromDate }_${ toDate }`);
        queryElems.push(`ship_date_match_type=between`);
      } else {
        const ngbDate0 = this.getValue('ship')[0];
        const date0 = new Date(ngbDate0.year, ngbDate0.month - 1, ngbDate0.day, 0, 0, 0, 0);
        const fromDate = moment(date0).format('YYYY-MM-DD');
        queryElems.push(`ship_date=${ fromDate }`);
        queryElems.push(`ship_date_match_type=exact`);
      }
    }

    if (this.isActiveSearch('cancel')) {
      if (!!this.getValue('cancel')[1]) {
        const ngbDate0 = this.getValue('cancel')[0];
        const date0 = new Date(ngbDate0.year, ngbDate0.month - 1, ngbDate0.day, 0, 0, 0, 0);
        const ngbDate1 = this.getValue('cancel')[1];
        const date1 = new Date(ngbDate1.year, ngbDate1.month - 1, ngbDate1.day, 0, 0, 0, 0);
        const fromDate = moment(date0).format('YYYY-MM-DD');
        const toDate = moment(date1).format('YYYY-MM-DD');
        queryElems.push(`cancel_date=${ fromDate }_${ toDate }`);
        queryElems.push(`cancel_date_match_type=between`);
      } else {
        const ngbDate0 = this.getValue('cancel')[0];
        const date0 = new Date(ngbDate0.year, ngbDate0.month - 1, ngbDate0.day, 0, 0, 0, 0);
        const fromDate = moment(date0).format('YYYY-MM-DD');
        queryElems.push(`cancel_date=${ fromDate }`);
        queryElems.push(`cancel_date_match_type=exact`);
      }
    }

    if (this.isActiveSearch('status')) queryElems.push(`status=${ this.getValue('status') }`);

    if (this.isActiveSearch('completed')) queryElems.push(`completed=${ this.getValue('completed') }`);

    return queryElems.join('&');
  }

  searchPurchaseOrders() {
    const isCustomer = this.router.url.includes('work-commissioned');
    const query = this.buildQuery(isCustomer);
    if (query.length <= 0) {
      this.$broadcaster.broadcast(EventTypes.RESET_SEARCH);
      return;
    }

    this.$search.searchPurchaseOrders(query, isCustomer).subscribe(r => {
      this.$broadcaster.broadcast(
        isCustomer ? EventTypes.WORK_COMMISSIONED_SEARCH_FINISHED : EventTypes.WORK_PROGRESS_SEARCH_FINISHED,
        r,
      );
    });
  }
}
