/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroRelationship_roleCollection } from '../models/lasgro-relationship-_role-collection';
import { LasgroGeneral_success_id_payload } from '../models/lasgro-general-_success-_id-_payload';
import { RelationshipRoleInputPayload } from '../models/relationship-role-input-payload';
import { LasgroRelationship_role } from '../models/lasgro-relationship-_role';
import { RelationshipRoleUpdatePayload } from '../models/relationship-role-update-payload';
import { LasgroGeneral_success_payload } from '../models/lasgro-general-_success-_payload';
@Injectable({
  providedIn: 'root',
})
class RelationshipRoleService extends __BaseService {
  static readonly relationshipRoleListPath = '/relationships/roles';
  static readonly relationshipRoleAddPath = '/relationships/roles';
  static readonly relationshipRoleShowPath = '/relationships/roles/{relationshipRoleID}';
  static readonly relationshipRoleUpdatePath = '/relationships/roles/{relationshipRoleID}';
  static readonly relationshipRoleRestorePath = '/relationships/roles/{relationshipRoleID}';
  static readonly relationshipRoleDeletePath = '/relationships/roles/{relationshipRoleID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list relationship_role
   *
   * Lists all the relationship roles
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  relationshipRoleListResponse(): __Observable<__StrictHttpResponse<LasgroRelationship_roleCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/relationships/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroRelationship_roleCollection>;
      })
    );
  }
  /**
   * list relationship_role
   *
   * Lists all the relationship roles
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  relationshipRoleList(): __Observable<LasgroRelationship_roleCollection> {
    return this.relationshipRoleListResponse().pipe(
      __map(_r => _r.body as LasgroRelationship_roleCollection)
    );
  }

  /**
   * add relationship_role
   *
   * Creates a new relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The request payload for creating a new relationship role
   * @return OK
   */
  relationshipRoleAddResponse(payload: RelationshipRoleInputPayload): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/relationships/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * add relationship_role
   *
   * Creates a new relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The request payload for creating a new relationship role
   * @return OK
   */
  relationshipRoleAdd(payload: RelationshipRoleInputPayload): __Observable<LasgroGeneral_success_id_payload> {
    return this.relationshipRoleAddResponse(payload).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * show relationship_role
   *
   * Fetches a single relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param relationshipRoleID undefined
   * @return OK
   */
  relationshipRoleShowResponse(relationshipRoleID: string): __Observable<__StrictHttpResponse<LasgroRelationship_role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/relationships/roles/${encodeURIComponent(String(relationshipRoleID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroRelationship_role>;
      })
    );
  }
  /**
   * show relationship_role
   *
   * Fetches a single relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param relationshipRoleID undefined
   * @return OK
   */
  relationshipRoleShow(relationshipRoleID: string): __Observable<LasgroRelationship_role> {
    return this.relationshipRoleShowResponse(relationshipRoleID).pipe(
      __map(_r => _r.body as LasgroRelationship_role)
    );
  }

  /**
   * update relationship_role
   *
   * Updates an existing relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `RelationshipRoleService.RelationshipRoleUpdateParams` containing the following parameters:
   *
   * - `relationshipRoleID`:
   *
   * - `payload`: The request payload for updating an existing relationship role
   *
   * @return OK
   */
  relationshipRoleUpdateResponse(params: RelationshipRoleService.RelationshipRoleUpdateParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_id_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/relationships/roles/${encodeURIComponent(String(params.relationshipRoleID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_id_payload>;
      })
    );
  }
  /**
   * update relationship_role
   *
   * Updates an existing relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `RelationshipRoleService.RelationshipRoleUpdateParams` containing the following parameters:
   *
   * - `relationshipRoleID`:
   *
   * - `payload`: The request payload for updating an existing relationship role
   *
   * @return OK
   */
  relationshipRoleUpdate(params: RelationshipRoleService.RelationshipRoleUpdateParams): __Observable<LasgroGeneral_success_id_payload> {
    return this.relationshipRoleUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_id_payload)
    );
  }

  /**
   * restore relationship_role
   *
   * Restores a soft deleted relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param relationshipRoleID undefined
   * @return OK
   */
  relationshipRoleRestoreResponse(relationshipRoleID: string): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/relationships/roles/${encodeURIComponent(String(relationshipRoleID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * restore relationship_role
   *
   * Restores a soft deleted relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param relationshipRoleID undefined
   * @return OK
   */
  relationshipRoleRestore(relationshipRoleID: string): __Observable<LasgroGeneral_success_payload> {
    return this.relationshipRoleRestoreResponse(relationshipRoleID).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * delete relationship_role
   *
   * Deletes an existing relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param relationshipRoleID undefined
   */
  relationshipRoleDeleteResponse(relationshipRoleID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/relationships/roles/${encodeURIComponent(String(relationshipRoleID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete relationship_role
   *
   * Deletes an existing relationship role
   *
   * Required security scopes:
   *   * `api:access`
   * @param relationshipRoleID undefined
   */
  relationshipRoleDelete(relationshipRoleID: string): __Observable<null> {
    return this.relationshipRoleDeleteResponse(relationshipRoleID).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RelationshipRoleService {

  /**
   * Parameters for relationshipRoleUpdate
   */
  export interface RelationshipRoleUpdateParams {
    relationshipRoleID: string;

    /**
     * The request payload for updating an existing relationship role
     */
    payload: RelationshipRoleUpdatePayload;
  }
}

export { RelationshipRoleService }
