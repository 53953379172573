import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '@app/app.config';
import { MessageDto, AddMessageDto } from '@app/models/message.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Action,
  BadgeNotification,
  Batch,
  Checklist,
  CompanyDto,
  CustomerIdentifier,
  FileItemDto,
  LineItem,
  Product,
  ContractDto,
  Step,
  ChecklistTaskDto,
  TaskRequest,
  User,
  CustomerRequiredFile,
  Workflow,
  TaskDefinition,
  TaskType,
  WorkflowInstance,
  TaskInstance,
} from './../models';

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient) {
    this.http = http;
  }

  /**
   * Add new company
   */
  create(company: CompanyDto) {
    const endpoint = `${ CONFIG.API }/organizations`;
    return this.http.post(endpoint, company).toPromise();
  }

  /**
   * Service to fetch companies from server
   */
  fetchCompany(): Observable<CompanyDto[]> {
    const endpoint = `${ CONFIG.API }/organizations`;
    return this.http.get<CompanyDto[]>(endpoint);
  }

  /**
   * Get company details
   */
  getById(organizationId: string): Observable<CompanyDto> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }`;
    return this.http.get<CompanyDto>(endpoint);
  }

  shouldRequestInfo(organizationId: string): Observable<{ should_request_info: boolean }> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/should_request_info`;
    return this.http.get<{ should_request_info: boolean }>(endpoint);
  }

  customers(id?: string): Observable<CompanyDto[]> {
    const endpoint = id ? `${ CONFIG.API }/organizations/${ id }/customers` : `${ CONFIG.API }/customers`;
    return this.http.get<CompanyDto[]>(endpoint);
  }

  customer(organizationId: string, customerId: string): Observable<CompanyDto> {
    const endpoint = organizationId
      ? `${ CONFIG.API }/organizations/${ organizationId }/customers/${ customerId }`
      : `${ CONFIG.API }/customers/${ customerId }`;
    return this.http.get<CompanyDto>(endpoint);
  }

  addCustomerFile(customerId: string, file: File, data: any) {
    const endpoint = `${ CONFIG.API }/customers/${ customerId }/files`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    const params = new HttpParams({ fromObject: data });
    return this.http.post(endpoint, formData, { params });
  }

  updateCustomerFile(customerId: string, fileId: string, file: File | null, data: any) {
    const endpoint = `${ CONFIG.API }/customers/${ customerId }/files/${ fileId }`;
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    const params = new HttpParams({ fromObject: data });
    return this.http.patch(endpoint, data, { params });
  }

  deleteCustomerFile(customerId: string, fileId: string) {
    const endpoint = `${ CONFIG.API }/customers/${ customerId }/files/${ fileId }`;
    return this.http.delete(endpoint);
  }

  getCustomerFileData(customerId: string, fileId: string, file: File, data: any) {
    const endpoint = `${ CONFIG.API }/customers/${ customerId }/files/${ fileId }`;
    return this.http.get(endpoint);
  }

  /**
   * Service to fetch customers of a given company
   * @param organizationId ID of the company
   */
  fetchCustomers(organizationId?: string): Observable<CompanyDto[]> {
    const endpoint = organizationId ? `${ CONFIG.API }/organizations/${ organizationId }/customers` : `${ CONFIG.API }/customers`;
    return this.http.get<CompanyDto[]>(endpoint);
  }

  /**
   * Service to fetch a customer by its ID and company ID
   * @param organizationId ID of the company
   * @param customerId ID of the customer
   */
  fetchCustomer(organizationId: string, customerId: string): Observable<CompanyDto> {
    const endpoint = organizationId
      ? `${ CONFIG.API }/organizations/${ organizationId }/customers/${ customerId }`
      : `${ CONFIG.API }/customers/${ customerId }`;
    return this.http.get<CompanyDto>(endpoint);
  }

  /**
   * Update customer profile
   */
  updateCustomer(organizationId: string, customer: any) {
    const endpoint = organizationId
      ? `${ CONFIG.API }/organizations/${ organizationId }/customers/${ customer.id }`
      : `${ CONFIG.API }/customers/${ customer.id }`;

    return new Promise((resolve, reject) => this.http.put(endpoint, customer).subscribe(resolve, reject));
  }

  products(organizationId?: string): Observable<Product[]> {
    const endpoint = organizationId ? `${ CONFIG.API }/organizations/${ organizationId }/products` : `${ CONFIG.API }/products`;
    return this.http.get<Product[]>(endpoint);
  }

  getProductsByCompanyId(organizationId: string): Observable<Product[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products_for_po`;
    return this.http.get<Product[]>(endpoint);
  }

  productsBuy(): Observable<Product[]> {
    const endpoint = `${ CONFIG.API }/products?iBuy=true`;
    return this.http.get<Product[]>(endpoint);
  }

  productsCouldBuy(organizationId): Observable<Product[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products_could_buy`;
    return this.http.get<Product[]>(endpoint);
  }

  product(organizationId: string, productId: string): Observable<Product> {
    const endpoint = organizationId
      ? `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }`
      : `${ CONFIG.API }/products/${ productId }`;

    return this.http.get<Product>(endpoint);
  }

  /**
   * Service to fetch a product by its ID
   * @param organizationId ID of the company
   * @param productId ID of the product
   */
  fetchProduct(organizationId: string, productId: string): Observable<Product> {
    const endpoint = organizationId
      ? `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }`
      : `${ CONFIG.API }/products/${ productId }`;

    return this.http.get<Product>(endpoint);
  }

  routeResults(organizationId: string, productId: string) {
    const endpoint = organizationId
      ? `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/result`
      : `${ CONFIG.API }/products/${ productId }/result`;

    return new Promise((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  customerPartIdentifiers(organizationId: string, id: string): Observable<CustomerIdentifier[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ id }/customer_part_identifiers`;
    return this.http.get<CustomerIdentifier[]>(endpoint);
  }

  updateCustomerPartIdentifiers(id: string, data: any) {
    const endpoint = `${ CONFIG.API }/customer_part_identifiers/${ id }`;
    return this.http.put(endpoint, data);
  }

  /**
   * Add new customer identifier
   * @param organizationId
   * @param data Identifier object
   */
  addCustomerPartIdentifier(company: string, data) {
    const endpoint = `${ CONFIG.API }/organizations/${ company }/products/${ data.product_id }/customer_part_identifiers`;
    return new Promise((resolve, reject) => this.http.post(endpoint, data).subscribe(resolve, reject));
  }

  /**
   * Service to add a product to a customer
   * @param organizationId ID of the company
   * @param productId ID of the product
   * @param data OBJ with the identifier for the customer
   */
  addNewProductCustomerPartIdentifier(organizationId: string, productId: string, data: any) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/customer_part_identifiers`;
    return this.http.post(endpoint, data);
  }

  deleteCustomerPartIdentifier(company: string, product_id: string, id: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ company }/products/${ product_id }/customer_part_identifiers/${ id }`;
    return new Promise((resolve, reject) => this.http.delete(endpoint).subscribe(resolve, reject));
  }

  /**
   * Update data from a product
   * @param organizationId
   * @param data Product object
   */
  updateProduct(organizationId: string, data: any) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ data.id }`;
    return new Promise((resolve, reject) => this.http.put(endpoint, data).subscribe(resolve, reject));
  }

  /**
   * Service to add a product
   * @param organizationId ID of the company
   * @param data OBJ with the product to save
   */
  addProduct(organizationId: string, data: Product): Observable<any> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products`;
    return this.http.post(endpoint, data);
  }

  deleteProduct(organizationId: string, id: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ id }`;
    return new Promise((resolve, reject) => this.http.delete(endpoint).subscribe(resolve, reject));
  }

  productSteps(organizationId: string, productId: string): Observable<Step[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/steps`;
    return this.http.get<Step[]>(endpoint);
  }

  stepDetails(organizationId: string, productId: string, stepId: string): Observable<Step> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/steps/${ stepId }`;
    return this.http.get<Step>(endpoint);
  }

  deleteStep(organizationId: string, productId: string, stepId) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/steps/${ stepId }`;
    return new Promise((resolve, reject) => this.http.delete(endpoint).subscribe(resolve, reject));
  }

  addStep(organizationId: string, id: string, step) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ id }/steps`;
    return new Promise((resolve, reject) => this.http.post(endpoint, step).subscribe(resolve, reject));
  }

  updateStep(organizationId: string, productId: string, step) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/steps/${ step.id }`;
    return new Promise((resolve, reject) => this.http.put(endpoint, step).subscribe(resolve, reject));
  }

  addResult(organizationId: string, productId: string, result: any) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/results`;
    return new Promise((resolve, reject) => this.http.post(endpoint, result).subscribe(resolve, reject));
  }

  commissionedPOs(organizationId: string = null) {
    const endpoint = organizationId ? `${ CONFIG.API }/organizations/${ organizationId }/commissioned_pos?viewType=report` :
      `${ CONFIG.API }/commissioned_pos?viewType=report`;
    return this.http.get<ContractDto[]>(endpoint);
  }

  commissionedPOsTiny(organizationId: string = null) {
    const endpoint = organizationId ? `${ CONFIG.API }/organizations/${ organizationId }/commissioned_pos?viewType=tiny` :
      `${ CONFIG.API }/commissioned_pos?viewType=tiny`;
    return this.http.get<ContractDto[]>(endpoint);
  }

  purchaseOrders(organizationId: string = null): Observable<ContractDto[]> {
    const endpoint = organizationId ? `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders?viewType=report` :
      `${ CONFIG.API }/purchase_orders?viewType=report`;
    return this.http.get<ContractDto[]>(endpoint);
  }

  countNewPoDrafts(organizationId: string): Observable<{ count: number }> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/count_new_drafts`;
    return this.http.get<{ count: number }>(endpoint);
  }

  /**
   * Service to fetch Purchase Orders / Projects given type of entity
   * @param type Type of entity, can be 'customer', 'supplier', 'broker'
   */
  fetchPurchaseOrders(type: string): Observable<ContractDto[]> {
    const endpoint =
      type === 'customer'
        ? `${ CONFIG.API }/commissioned_pos?viewType=report`
        : `${ CONFIG.API }/purchase_orders?viewType=report`;
    return this.http.get<ContractDto[]>(endpoint);
  }

  /**
   * Service to create a Purchase Order
   * @param organizationId ID of the company
   * @param purchaseOrder Purchase Order obj
   */
  addPurchaseOrder(organizationId: string, purchaseOrder: ContractDto): Observable<ContractDto> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders`;
    return this.http.post<ContractDto>(endpoint, purchaseOrder);
  }

  purchaseOrder(purchaseOrderId: string): Observable<ContractDto> {
    const endpoint = `${ CONFIG.API }/purchase_orders/${ purchaseOrderId }`;
    return this.http.get<ContractDto>(endpoint);
  }

  /**
   * Service to fetch a PO given its ID and the Company's ID
   * @param organizationId ID of the company where the PO will be retrieved
   * @param id ID of the PO
   * @param companyType Type of company, 'supplier' or 'customer' are valid values
   */
  fetchPurchaseOrder(organizationId: string, id: string, companyType?: string): Observable<ContractDto> {
    const endpoint =
      companyType === 'supplier' || companyType == null
        ? `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ id }`
        : `${ CONFIG.API }/organizations/${ organizationId }/commissioned_pos/${ id }`;

    return this.http.get<ContractDto>(endpoint);
  }

  deletePurchaseOrder(organizationId: string, id: string) {
    return new Promise((resolve, reject) => {
      const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ id }`;
      this.http.delete(endpoint).subscribe(resolve, reject);
    });
  }

  updatePurchaseOrder(organizationId: string, po: ContractDto): Observable<ContractDto> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ po.id }`;
    return this.http.put<ContractDto>(endpoint, po);
  }

  getResourceMessages(organizationId: string, id: string): Observable<MessageDto[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/resources/${ id }/messages`;
    return this.http.get<MessageDto[]>(endpoint);
  }

  sendPurchaseOrderToSupplier(purchaseOrderId: string) {
    const endpoint = `${ CONFIG.API }/purchase_orders/${ purchaseOrderId }/send_to_supplier`;
    return this.http.post(endpoint, {});
  }

  isPurchaseOrderNumberAvailable(organizationId: string, purchaseOrderNumber: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/is_value_available?poNumber=` + encodeURIComponent(purchaseOrderNumber);
    return this.http.get(endpoint);
  }

  isSalesOrderNumberAvailable(organizationId: string, salesOrderNumber: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/is_value_available?salesOrderNumber=` + encodeURIComponent(salesOrderNumber);
    return this.http.get(endpoint);
  }

  /**
   * Service to fetch actions for a purchase order
   * @param resourceId ID of the purchase order
   */
  resourceHistoryActions(resourceId: string): Observable<Action[]> {
    const endpoint = `${ CONFIG.API }/logs/${ resourceId }`;
    return this.http.get<Action[]>(endpoint).pipe(
      map(actions => actions.map((a: Action) => {
        const objectDate = moment(a.created_at);
        a.created_at_formatted = moment().diff(objectDate, 'days') >= 2 ?
          objectDate.format('LLL') :
          objectDate.fromNow();
        return a;
      })));
  }

  /**
   * Service to Accept or Reject a PO
   * @param value True if PO is accepted, false otherwise
   * @param token Token to make sure this is not made client side by force
   * @param id ID of the PO
   * @param uId ID of the user
   */
  purchaseOrderAcknowledge(value: boolean, token: string, id: string, uId: string, reason?: string): Observable<any> {
    const endpoint = `${ CONFIG.API }/purchase_orders/change_state/${ id }`;
    const user_id = uId;
    const body = {
      reason,
      token,
      user_id,
      accepted: value,
    };
    return this.http.post(endpoint, body);
  }

  purchaseOrderChangeStatus(organizationId: string, po: ContractDto, status: string) {
    po.status = status as any;
    return this.updatePurchaseOrder(organizationId, po);
  }


  /**
   * List company line items
   */
  lineItems(organizationId: string, purchaseOrderId: string): Promise<LineItem[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/line_items`;
    return new Promise((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  /**
   * Service to save a Line Item for a Purchase Order
   * @param id ID of the Purchase Order
   * @param lineItem Line Item OBJ to be stored in the DB
   */
  addLineItem(id: string, lineItem: LineItem) {
    const endpoint = `${ CONFIG.API }/purchase_orders/${ id }/line_items`;
    return new Promise((resolve, reject) => this.http.post(endpoint, lineItem).subscribe(resolve, reject));
  }

  /**
   * Service to update a Line Item Quantity for a Purchase Order
   * @param id ID of the Purchase Order
   * @param lineItem Line Item OBJ to be updated in the DB
   */
  updateLineItem(id: string, lineItem: LineItem): Promise<LineItem[]> {
    const endpoint = `${ CONFIG.API }/purchase_orders/${ id }/line_items/${ lineItem.id }`;
    return new Promise((resolve, reject) => this.http.put(endpoint, lineItem).subscribe(resolve, reject));
  }

  /**
   * Service to delete a Line Item for a Purchase Order
   * @param id ID of the Line Item
   */
  deleteLineItem(id: string) {
    const endpoint = `${ CONFIG.API }/line_items/${ id }`;
    return new Promise((resolve, reject) => this.http.delete(endpoint).subscribe(resolve, reject));
  }

  lineItemDetail(organizationId: string, poID: string, id: string, companyType: string): Promise<LineItem> {
    // different request depending on the type of company
    const endpoint =
      companyType === 'supplier'
        ? `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poID }/line_items/${ id }`
        : `${ CONFIG.API }/commissioned_pos/${ poID }/commissioned_line_items/${ id }`;

    return new Promise((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  /**
   * Service to update a Line Item's step to produce progress
   * @param lineItemID Line Item ID to be updated in the DB
   * @param stepID step to produce ID
   * @param qty Quantity for the step
   */
  updateStepProgress(lineItemID: string, stepID: string, qty: number): Observable<any> {
    const endpoint = `${ CONFIG.API }/line_items/${ lineItemID }/step_progress/${ stepID }`;
    return this.http.put(endpoint, { quantity: qty });
  }

  lineItemSteps(organizationId: string, lineItemID: string): Observable<Step[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/line_items/${ lineItemID }/steps`;
    return this.http.get<Step[]>(endpoint);
  }

  /**
   * add a new note to a purchase order
   * @param organizationId
   * @param purchaseOrderId
   * @param note
   */
  addNote(organizationId: string, purchaseOrderId: string, note: AddMessageDto): Promise<Batch> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/notes`;
    return new Promise((resolve, reject) => this.http.post(endpoint, note).subscribe(resolve, reject));
  }

  deleteNote(organizationId: string, purchaseOrderId: string, noteId: string): Promise<Batch> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/messages/${ noteId }`;
    return new Promise((resolve, reject) => this.http.delete(endpoint).subscribe(resolve, reject));
  }

   broadcastMessage(organizationId: string, payload: any, mention_all: boolean = false, is_contract: boolean = false) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/messages/broadcast`;
    const params = new HttpParams({
      fromObject: {
        mention_all: mention_all.toString(),
        is_contract: is_contract.toString(),
      }
    });
    return this.http.post(endpoint, payload, { params });
  }

  /**
   * Service to fetch users from a company
   * @param organizationId ID of the company
   */
  users(organizationId: string): Observable<User[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/users`;
    return this.http.get<User[]>(endpoint).pipe(map(results => results.map(u => new User(u))));
  }

  user(organizationId: string, userId: string): Observable<User> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/users/${ userId }`;
    return this.http.get<User>(endpoint).pipe(map(r => new User(r)));
  }

  addUser(organizationId: string, user: User) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/users`;
    return this.http.post(endpoint, user);
  }

  updateUser(organizationId: string, user: any) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/users/${ user.id }`;
    return this.http.patch(endpoint, user, { observe: 'response' });
  }

  /**
   * Service to delete a User by its ID
   * @param organizationId ID of the company
   * @param userId ID of the user
   */
  removeUser(organizationId: string, userId: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/users/${ userId }`;
    return this.http.delete(endpoint);
  }

  employees(id): Promise<User[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/employees`;
    return new Promise((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  employee(organizationId: string, employeeId: string): Promise<User> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/employees/${ employeeId }`;
    return new Promise((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  addEmployee(organizationId: string, employee: User) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/employees`;
    return this.http.post(endpoint, employee, { observe: 'response' }).toPromise();
  }

  updateEmployee(organizationId: string, employee: User) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/employees/${ employee.id }`;
    return this.http.patch(endpoint, employee, { observe: 'response' }).toPromise();
  }

  /**
   * Service to fetch suppliers of a given company
   * @param id ID of the company
   */
  fetchSuppliers(id: string): Observable<CompanyDto[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/suppliers`;
    return this.http.get<CompanyDto[]>(endpoint);
  }

  /**
   * Service to fech supplier by its ID and Company ID
   * @param organizationId ID of the company
   * @param supplierId ID of the supplier/broker
   */
  fetchSupplier(organizationId: string, supplierId: string): Observable<CompanyDto> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/suppliers/${ supplierId }`;
    return this.http.get<CompanyDto>(endpoint);
  }

  /**
   * Service to fetch suppliers of a given company
   * @param id ID of the company
   */
  suppliers(id: string): Observable<CompanyDto[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ id }/suppliers`;
    return this.http.get(endpoint)
      .pipe(map((suppliers: CompanyDto[]) => {
        return _.sortBy(suppliers, supplier => supplier.name.toLowerCase(), ['desc']);
      }));
  }

  supplier(organizationId: string, supplierId: string): Observable<CompanyDto> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/suppliers/${ supplierId }`;
    return this.http.get<CompanyDto>(endpoint);
  }

  addSupplierFile(supplierId: string, file: File, data: any) {
    const endpoint = `${ CONFIG.API }/suppliers/${ supplierId }/files`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    const params = new HttpParams({ fromObject: data });
    return this.http.post(endpoint, formData, { params });
  }

  updateSupplierFile(supplierId: string, fileId: string, file: File | null, data: any) {
    const endpoint = `${ CONFIG.API }/suppliers/${ supplierId }/files/${ fileId }`;
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    const params = new HttpParams({ fromObject: data });
    return this.http.patch(endpoint, data, { params });
  }

  deleteSupplierFile(supplierId: string, fileId: string) {
    const endpoint = `${ CONFIG.API }/suppliers/${ supplierId }/files/${ fileId }`;
    return this.http.delete(endpoint);
  }

  getSupplierFileData(supplierId: string, fileId: string, file: File, data: any) {
    const endpoint = `${ CONFIG.API }/suppliers/${ supplierId }/files/${ fileId }`;
    return this.http.get(endpoint);
  }

  updateSupplier(organizationId: string, data: CompanyDto): Promise<CompanyDto[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/suppliers/${ data.id }`;
    return new Promise((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  updateCompany(data: CompanyDto): Observable<any> {
    const endpoint = `${ CONFIG.API }/organizations/${ data.id }`;
    return this.http.put(endpoint, data);
  }

  /**
   * Customer Required Files
   */
  getCustomerRequiredFiles(organizationId: string, supplierId: string): Observable<CustomerRequiredFile[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/suppliers/${ supplierId }/file_requirements`;
    return this.http.get<CustomerRequiredFile[]>(endpoint);
  }

  getCustomerRequiredFilesForSupplier(organizationId: string, customerId: string): Observable<CustomerRequiredFile[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/customers/${ customerId }/file_requirements`;
    return this.http.get<CustomerRequiredFile[]>(endpoint);
  }

  attachFileToCustomerRequiredFile(organizationId: string, customerId: string, requirementId: string, fileId: string): Observable<any> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/customers/${ customerId }/file_requirements/${ requirementId }/attach/${ fileId }`;
    return this.http.get<any>(endpoint);
  }

  addCustomerRequiredFile(organizationId: string, supplierId: string, requirement: CustomerRequiredFile): Observable<CustomerRequiredFile> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/suppliers/${ supplierId }/file_requirements`;
    return this.http.post<CustomerRequiredFile>(endpoint, requirement, {});
  }

  updateCustomerRequiredFile(organizationId: string, supplierId: string, requirement: CustomerRequiredFile): Observable<CustomerRequiredFile> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/suppliers/${ supplierId }/file_requirements/${ requirement.id }`;
    return this.http.patch<CustomerRequiredFile>(endpoint, requirement, {});
  }

  deleteCustomerRequiredFile(organizationId: string, supplierId: string, requirementId: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/suppliers/${ supplierId }/file_requirements/${ requirementId }`;
    return this.http.delete(endpoint);
  }

  /**
   * Service to fetch the checklist of a given purchase order
   * @param organizationId ID of the company
   * @param poId ID of the purchase order
   */
  getCheckList(organizationId: string, poId: string): Observable<Checklist[]> {
    const endpoint =
      `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/checklists?viewType=full`;
    return this.http.get<Checklist[]>(endpoint);
  }

  /**
   * Service to fetch files from a purchase order
   * @param poId ID of the purchase order
   */
  getPoFiles(poId: string, tag: string = ''): Observable<FileItemDto[]> {
    const endpoint =
      `${ CONFIG.API }/purchase_orders/${ poId }/purchase_order_files`;
    let p: any;
    if (tag) {
      p = { tag: tag };
    }
    const params = new HttpParams({ fromObject: p });
    return this.http.get<FileItemDto[]>(endpoint, { params });
  }

  /**
   * Service to fetch files from a purchase order
   * @param poId ID of the purchase order
   */
  getAllPoFiles(poId: string): Observable<any> {
    const endpoint = `${ CONFIG.API }/purchase_orders/${ poId }/purchase_order_all_files`;
    return this.http.get<any>(endpoint);
  }

  /**
   * Service to fetch files from a task
   * @param taskId ID of the task
   */
  getTaskFiles(taskId: string): Observable<FileItemDto[]> {
    const endpoint =
      `${ CONFIG.API }/checklist_tasks/${ taskId }/checklist_task_files`;
    return this.http.get<FileItemDto[]>(endpoint);
  }

  /**
   * Service to create a checklist for a purchase order
   * @param poId ID of the purchase order
   * @param name name of the checklist
   */
  createCheckList(poId: string, name: string) {
    const endpoint = `${ CONFIG.API }/purchase_orders/${ poId }/checklists`;
    const body = {
      checklist_name: name,
      checklist_tasks: [],
    };
    return this.http.post(endpoint, body);
  }

  getCheckListTasks(companyID: string, purchaseOrderID: string, checklistID: string): Promise<ChecklistTaskDto[]> {
    let endpoint = `${ CONFIG.API }/organizations/${ companyID }/purchase_orders/`;
    endpoint += `${ purchaseOrderID }/checklists/${ checklistID }/checklist_tasks`;
    return new Promise((resolve, reject) => this.http.get(endpoint).subscribe(resolve, reject));
  }

  getCheckListTask(companyID: string, purchaseOrderID: string, checklistID: string, taskID: string): Observable<ChecklistTaskDto> {
    let endpoint = `${ CONFIG.API }/organizations/${ companyID }/purchase_orders/`;
    endpoint += `${ purchaseOrderID }/checklists/${ checklistID }/checklist_tasks/${ taskID }`;
    return this.http.get<ChecklistTaskDto>(endpoint);
  }

  /**
   * Service to create a new task for a checklist
   * @param organizationId ID of the company
   * @param poId ID of the purchase order
   * @param checklistId ID of the checlist
   * @param task Task OBJ to create
   */
  createNewTask(organizationId: string, poId: string, checklistId: string, task: TaskRequest | ChecklistTaskDto) {
    const endpoint =
      `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/checklists/${ checklistId }/checklist_tasks`;
    return this.http.post(endpoint, task);
  }

  createSavedChecklistTask(organizationId: string, checklistId: string, task: TaskRequest | ChecklistTaskDto) {
    const endpoint =
      `${ CONFIG.API }/organizations/${ organizationId }/saved_checklists/${ checklistId }/saved_checklist_tasks`;
    return this.http.post(endpoint, task);
  }

  /**
   * Service to delete a task from a checklist
   * @param organizationId ID of the company
   * @param poId ID of the purchase order
   * @param checklistId ID of the checklist
   * @param taskId ID of the task to delete
   */
  removeTask(organizationId: string, poId: string, checklistId: string, taskId: string) {
    const endpoint =
      `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/checklists/${ checklistId }/checklist_tasks/${ taskId }`;
    return this.http.delete(endpoint);
  }

  /**
   * Service to update a task from a checklist
   * @param taskId ID of the task to be updated
   * @param task Task OBJ with changes
   */
  updateTask(taskId: string, task: TaskRequest | ChecklistTaskDto) {
    const endpoint = `${ CONFIG.API }/checklist_tasks/${ taskId }`;
    return this.http.patch(endpoint, task);
  }

  /**
   * Service to delete a file attached to a purchase order
   * @param organizationId ID of the company
   * @param poId ID of the purchase order
   * @param fileID ID of the file to remove
   */
  removeFileFromPO(organizationId: string, poId: string, fileID: string) {
    const endpoint =
      `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/purchase_order_files/${ fileID }`;
    return this.http.delete(endpoint);
  }

  /**
   * Service to delete a file attached to a checklist task
   * @param fileId ID of the file to be removed from a checklist task
   */
  removeFileFromTask(fileId: string) {
    const endpoint = `${ CONFIG.API }/checklist_task_files/${ fileId }`;
    return this.http.delete(endpoint);
  }


  /**
   * Service to delete a file attached to a product
   * @param poId ID of the purchase order
   * @param fileID ID of the file to remove
   */
  removeFileFromProduct(organizationId: string, fileID: string) {
    const endpoint =
      `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ fileID }/purchase_order_files/${ fileID }?isProduct=true`;
    return this.http.delete(endpoint);
  }

  /**
   * Service to upload a file for a purchase order/line item
   * @param organizationId ID of the company
   * @param objId ID of the purchase order / line item
   * @param formData Form data with the files to upload attached
   * @param isMainFile Indicates if it is the main file of the purchase order
   * @param isProduct Indicates if it is an image for a line item
   */
  addFileToPo(
    organizationId: string,
    objId: string,
    formData: FormData,
    isMainFile: boolean = false,
    isProduct: boolean = false,
    tag: string = '',
    isApprovalRequired: boolean = false,
    periodTag: string = null,
    insuranceDate: string = null,
    typeValue: string = null,
    typeRequired: boolean = null,
    name: string = null
  ): Observable<FileItemDto[]> {
    let objIdType: string = 'PURCHASE_ORDER';
    if (isProduct) {
      objIdType = 'PRODUCT';
    }
    return this.addPoFile(organizationId, objId, objIdType, formData, isMainFile, '', '', tag, isApprovalRequired, periodTag, insuranceDate, typeValue, typeRequired, name);
  }

  /**
   * addPoFile adds files to a purchase order
   * @param organizationId ID of the company
   * @param objId ID of the resource
   * @param objIdType type for the resource ID
   * @param formData Form data with the files to upload attached
   * @param isMainFile Indicates if it is the main file of the purchase order
   * @param shipmentFileType file type if its a shipment file
   * @param shipmentTrackingID shipment tracking id if the file is related to a shipment
   * @return {Observable<FileItemDto[]>} file list
   */
  addPoFile(
    organizationId: string,
    objId: string,
    objIdType: string,
    formData: FormData,
    isMainFile: boolean = false,
    shipmentFileType: string = '',
    shipmentTrackingID: string = '',
    tag: string = '',
    isApprovalRequired: boolean = false,
    periodTag: string = '',
    insuranceDate: string = null,
    typeValue: string = null,
    typeRequired: boolean = null,
    name: string = null
  ): Observable<FileItemDto[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/files/${ objId }/upload`;
    let p: any = {
        isMainFile: isMainFile.toString(),
        resourceType: objIdType,
        tag: tag,
        isApprovalRequired: isApprovalRequired,
        periodTag: periodTag,
    };
    if (insuranceDate) {
      p.insuranceDate = insuranceDate
    }
    if (name) {
      p.name = name
    }
    if (typeValue) {
      p.typeValue = typeValue
    }
    if (typeRequired) {
      p.typeRequired = typeRequired
    }
    if (shipmentFileType) {
      p.shipmentFileType = shipmentFileType;
    }
    if (shipmentTrackingID) {
      p.shipmentTrackingID = shipmentTrackingID;
    }
    const params = new HttpParams({ fromObject: p });
    return this.http.post<FileItemDto[]>(endpoint, formData, { params });
  }

  rejectPoFile(
    organizationId: string,
    poId: string,
    objId: string,
    reason: string,
  ): Observable<FileItemDto> {
    return this.updatePoFile(organizationId, poId, objId, false, reason);
  }

  approvePoFile(
    organizationId: string,
    poId: string,
    objId: string,
  ): Observable<FileItemDto> {
    return this.updatePoFile(organizationId, poId, objId, true);
  }

  updatePoFile(
    organizationId: string,
    poId: string,
    objId: string,
    accepted: boolean,
    reason: string = '',
  ): Observable<FileItemDto> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/purchase_order_files/${ objId }`;
    const data: any = {
      status: accepted ? 'approved' : 'rejected',
      isApprovalRequired: true,
      reason: reason,
    }
    return this.http.put<FileItemDto>(endpoint, data);
  }

  addLinkFile(
    organizationId: string,
    objId: string,
    url: string,
    metadata: string = '',
    isMainFile: boolean = false,
  ): Observable<any> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/files/${ objId }/upload_link`;
    let p: any = {
        isMainFile: isMainFile.toString(),
    };
    if (metadata) {
      p.metadata = metadata;
    }
    if (url) {
      p.url = url;
    }
    const params = new HttpParams({ fromObject: p });
    return this.http.post<any>(endpoint, new FormData(), { params });
  }

  rcdssUploadContractFile(
    organizationId: string,
    poId: string,
    formData: FormData,
  ): Observable<any> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/purchase_order_files/rcdss_upload_contract_file`;
    return this.http.post<any>(endpoint, formData);
  }

  rcdssUploadElectronicVoucher(
    organizationId: string,
    poId: string,
    period: string,
    formData: any,
  ): Observable<any> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/electronic_voucher?period=${period}`;
    return this.http.post<any>(endpoint, formData);
  }

  rcdssDownloadContractFile(
    organizationId: string,
    poId: string,
  ) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/purchase_order_files/rcdss_download_contract_file`;
    return this.http.get(endpoint, { observe: 'response', responseType: 'blob' });
  }

  rcdssGetLinkToContractDocument(
    organizationId: string,
    poId: string,
    docId: string = null,
  ): Observable<any> {
    let docIdParam = '';
    if (docId != null) {
      docIdParam = '?documentId=' + docId;
    }
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/purchase_order_files/rcdss_create_contract_document_link${docIdParam}`;
    return this.http.get<any>(endpoint);
  }

  /**
   * addPoFile adds files to a purchase order
   * @param organizationId ID of the company
   * @param fileID ID of the file
   * @param metadata the metadata for this file
   */
  updateFileMetadata(
    organizationId: string,
    fileID: string,
    metadata: string,
  ): Observable<any> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/files/${ fileID }/metadata`;
    return this.http.patch<any>(endpoint, { metadata: metadata });
  }

  replaceFileTag(
    purchaseOrderId: string,
    fileID: string,
    tag: string,
    replaceTag: string = null, 
  ): Observable<any> {
    const endpoint = `${ CONFIG.API }/purchase_orders/${ purchaseOrderId }/purchase_order_files/${ fileID }/tags`;
    return this.http.put<any>(endpoint, { tag: tag, replace_tag: replaceTag });
  }

  convertAndMergeAsSinglePDF(
    purchaseOrderId: string,
    data: any,
  ): Observable<any> {
    const endpoint = `${ CONFIG.API }/purchase_orders/${ purchaseOrderId }/purchase_order_files`;
    return this.http.post<any>(endpoint, data);
  }

  /**
   * Service to upload multiple files for a purchase order/line item
   * @param organizationId ID of the company
   * @param objId ID of the purchase order / line item
   * @param formData Form data with the files to upload attached
   * @param isMainFile Indicates if it is the main file of the purchase order
   * @param isProduct Indicates if it is an image for a line item
   */
  addMultipleFilesToPo(
    organizationId: string,
    objId: string,
    formData: FormData,
    isMainFile: boolean = false,
    isProduct: boolean = false,
  ): Observable<FileItemDto[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ objId }/purchase_order_files_batch`;

    const params = new HttpParams({
      fromObject: {
        isMainFile: isMainFile.toString(),
        isProduct: isProduct.toString(),
      },
    });
    return this.http.post<FileItemDto[]>(endpoint, formData, { params });
  }

  /**
   * Service to upload a file for a task
   * @param taskId ID of the task
   * @param formData Form data with the files to upload attached
   */
  addFileToTask(taskId: string, formData: FormData): Observable<FileItemDto[]> {
    const endpoint =
      `${ CONFIG.API }/checklist_tasks/${ taskId }/checklist_task_files`;
    return this.http.post<FileItemDto[]>(endpoint, formData);
  }

  /**
   * Service to upload a file to the server and attach it to a purchase order
   * @param organizationId ID of the company
   * @param poId ID of the purchase order
   * @param fileId ID of the file to upload to the server
   * @param isProduct Indicates if it is a product file
   */
  getPoFileById(
    organizationId: string,
    poId: string,
    fileId: string,
    isProduct: boolean = false,
  ) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/purchase_order_files/${ fileId }`;

    const params = {
      isProduct: isProduct.toString(),
    };
    return this.http.get(endpoint, { observe: 'response', responseType: 'blob', params });
  }

  /**
   * Service to download a file
   * @param fileId ID of the file to upload to the server
   */
  getTaskFileById(
    fileId: string,
  ) {
    const endpoint = `${ CONFIG.API }/checklist_task_files/${ fileId }`;
    return this.http.get(endpoint, { observe: 'response', responseType: 'blob' });
  }

    /**
   * Service to download a file
   * @param fileId ID of the file to upload to the server
   */
    getFileById(
      fileId: string,
    ) {
      const endpoint = `${ CONFIG.API }/file/${ fileId }`;
      return this.http.get(endpoint, { observe: 'response', responseType: 'blob' });
    }

  /**
   * Service to update the report
   * @param organizationId ID of the company
   * @param poId ID of the purchase order
   * @param reportId ID of the report
   * @param body Body of the report
   */
  updateReport(organizationId: string, poId: string, reportId: string, body: any) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ poId }/finished_goods_reports/${ reportId }`;
    return this.http.put(endpoint, body);
  }

  searchClient(email: string) {
    const endpoint = `${ CONFIG.API }/search/company_primary_contact?email=${ email }`;
    return this.http.get(endpoint);
  }

  addCompanyClients(data: any) {
    const endpoint = `${ CONFIG.API }/company_clients`;
    return this.http.post(endpoint, data);
  }

  addUserPrimaryContact(data: {
    primary_contact: any,
    client_organization_id: string,
    client_type: string,
  }) {
    const endpoint = `${ CONFIG.API }/company_clients/new_user`;
    return this.http.post(endpoint, data);
  }

  // getReport(companyID: string, purchaseOrderID: string) {
  //     let endpoint = `${CONFIG.API}/organizations/${companyID}/purchase_orders/`;
  //     endpoint += `${purchaseOrderID}/finished_goods_reports`;
  //     return new Promise((resolve, reject) => this.http.get(endpoint ).subscribe(resolve, reject) )
  // }

  /**
   * Service to identify what sections changed
   * @param poId ID of the Purchase Order
   */
  fetchPOChanges(poId: string): Observable<{ changed_sections: BadgeNotification[], unread_messages: number }> {
    const endpoint = `${ CONFIG.API }/changes/${ poId }`;
    return this.http.get<{ changed_sections: BadgeNotification[], unread_messages: number }>(endpoint);
  }

  /**
   * Service to register when was the last time this section was seen
   * @param organizationId ID of the company
   * @param poId ID of the Purchase Order
   * @param section Section seen. It can be 'purchase_orders', 'finished_goods_reports', 'purchase_order_files', 'checklist', 'tasks'
   */
  poChangesSeen(organizationId: string, poId: string, section: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/logs/${ poId }`;
    const obj = { section, type: 'read' };
    return this.http.post(endpoint, obj);
  }


  /**
   * Service to assign a saved checklist to a purchase order
   * @param organizationId ID of the company
   * @param purchaseOrderId ID of the purchase order
   * @param checklistId ID of the checklist
   * @param savedChecklistId ID of the saved checklist
   */
  assignSavedChecklist(organizationId: string, purchaseOrderId: string, checklistId: string, savedChecklistId: string) {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/checklists/${ checklistId }`;
    const obj = { saved_checklist_id: savedChecklistId };
    return this.http.put(endpoint, obj);
  }

  /**
   * Method to get the count of open POs for a given product
   * @param organizationId ID of the company
   * @param productId ID of the product
   */
  countProductAffectedPurchaseOrders(organizationId: string, productId: string): Observable<{ open_count: number, pending_count: number, past_count: number }> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/products/${ productId }/count_affected_pos`;
    return this.http.get<{ open_count: number, pending_count: number, past_count: number }>(endpoint);
  }


  /**
   * Workflow Endpoints
   */
  
  workflows(organizationId: string): Observable<Workflow[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/workflows `;
    return this.http.get<Workflow[]>(endpoint);
  }

  workflow(organizationId: string, workflowId: string): Observable<Workflow> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/workflows/${ workflowId }`;
    return this.http.get<Workflow>(endpoint);
  }

  createWorkflow(organizationId: string, data: any): Observable<{id:any}> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/workflows`;
    return this.http.post<{id:any}>(endpoint, data);
  }

  updateWorkflow(organizationId: string, workflowId: string, data: any): Observable<{id:any}> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/workflows/${ workflowId }`;
    return this.http.put<{id:any}>(endpoint, data);
  }

  applyWorkflow(organizationId: string, purchaseOrderId: string, workflowId: string, data: any): Observable<{instance_id:any}> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/purchase_orders/${ purchaseOrderId }/workflows/${ workflowId }/apply`;
    return this.http.post<{instance_id:any}>(endpoint, data);
  }

  workflowTaskDefinition(organizationId: string, workflowId: string, taskId: string): Observable<TaskDefinition> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/workflows/${ workflowId }/tasks/${ taskId }`;
    return this.http.get<TaskDefinition>(endpoint);
  }

  workflowTaskDefinitions(organizationId: string, workflowId: string): Observable<TaskDefinition[]> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/workflows/${ workflowId }/tasks`;
    return this.http.get<TaskDefinition[]>(endpoint);
  }

  createTaskDefinition(organizationId: string, workflowId: string, data: any): Observable<{id:any}> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/workflows/${ workflowId }/tasks`;
    return this.http.post<{id:any}>(endpoint, data);
  }

  updateTaskDefinition(organizationId: string, workflowId: string, taskId: string, data: any): Observable<{id:any}> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/workflows/${ workflowId }/tasks/${ taskId }`;
    return this.http.put<{id:any}>(endpoint, data);
  }

  workflowTaskTypes(): Observable<TaskType[]> {
    const endpoint = `${ CONFIG.API }/workflows/task_types`;
    return this.http.get<TaskType[]>(endpoint);
  }

  workflowInstance(organizationId: string, resourceId: string): Observable<WorkflowInstance> {
    const endpoint = `${ CONFIG.API }/organizations/${ organizationId }/resources/${ resourceId }/workflows/timeline`;
    return this.http.get<WorkflowInstance>(endpoint);
  }

  workflowTaskInstances(workflowId: string, workflowInstanceId: string): Observable<TaskInstance[]> {
    const endpoint = `${ CONFIG.API }/workflows/${ workflowId }/instances/${ workflowInstanceId }/instances`;
    return this.http.get<TaskInstance[]>(endpoint);
  }
  
  completeTaskInstance(workflowId: string, workflowInstanceId: string, taskId: string, taskInstanceId: string, data: any): Observable<any> {
    const endpoint = `${ CONFIG.API }/workflows/${ workflowId }/instances/${ workflowInstanceId }/tasks/${taskId}/instances/${taskInstanceId}/action`;
    return this.http.post<any>(endpoint, data);
  }

  completeTaskInstanceWithFile(workflowId: string, workflowInstanceId: string, taskId: string, taskInstanceId: string, formData: FormData): Observable<any> {
    const endpoint = `${ CONFIG.API }/workflows/${ workflowId }/instances/${ workflowInstanceId }/tasks/${taskId}/instances/${taskInstanceId}/upload/action`;
    return this.http.post<any>(endpoint, formData);
  }

}
