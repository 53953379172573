import { Directive, HostListener, Input } from '@angular/core';
import { POSearchService } from './po-search.service';

@Directive({
  selector: '[ngxClickOnEnter]',
})
export class EnteryKeyDirective {
  //   constructor(private el: ElementRef, private renderer: Renderer) {}
  constructor(private $searchMenu: POSearchService) {}

  @Input('ngxClickOnEnter') key: string;

  //   @HostListener('mouseover') onMouseOver() {
  //     this.ChangeBgColor('red');
  //   }

  //   @HostListener('mouseleave') onMouseLeave() {
  //     this.ChangeBgColor('black');
  //   }

  @HostListener('keydown.enter', ['$event']) onKeyEnter(event: KeyboardEvent) {
    this.$searchMenu.addSearchParameter(this.key, event.srcElement['value']);
    this.$searchMenu.toggleMenu(this.key, false);
  }

  //   ChangeBgColor(color: string) {
  //     this.renderer.setElementStyle(this.el.nativeElement, 'color', color);
  //   }
}
