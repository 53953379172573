<div class="container p-4 m-4">
  <ngx-loading [show]="loading"></ngx-loading>

  <div class="d-flex justify-content-between" *ngIf="modalRef">
    <h2 mat-dialog-title>Broadcast Message</h2>
    <div class="close-modal">
      <button type="button" class="close" aria-label="close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <form
    [formGroup]="messageForm"
    (ngSubmit)="save()"
    #newMessageForm
  >
    <div class="row align-items-center pt-4">
      <div class="col-md-6">
        <label>Who will be mentioned in each message:</label>
        <mat-form-field class="w-auto">
          <mat-select formControlName="mention_all" panelClass="menu-select-panel">
            <mat-option value="false">Subscribers only</mat-option>
            <mat-option value="true">Everyone</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div class="col-md-6">
        <mat-form-field>
          <input type="text" matInput formControlName="name" placeholder="Task Name" required/>
        </mat-form-field>
      </div> -->

      <div class="col-md-12">
        <label>Message:</label>
        <mat-form-field>
          <textarea placeholder="" rows="5" formControlName="content" matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="999"></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12 py-4">
        Message will be sent to the following {{ resourceType }}(s): {{ getResourceList() }}
      </div>

      <div class="col-auto text-right">
        <button
          type="button"
          (click)="reset()"
          class="btn btn-danger"
        >
          Clear
        </button>
        <button
          class="ml-2 btn bg-primary text-white"
        >
          <i class="fa fa-send"></i>
          Send
        </button>
      </div>
    </div>
  </form>

</div>