/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOrganization_clientCollection } from '../models/lasgro-organization-_client-collection';
import { OrganizationClientInputPayload } from '../models/organization-client-input-payload';
import { OrganizationClientWithUserInputPayload } from '../models/organization-client-with-user-input-payload';
@Injectable({
  providedIn: 'root',
})
class OrganizationClientsService extends __BaseService {
  static readonly organizationClientsListPath = '/organization_clients';
  static readonly organizationClientsAddPath = '/organization_clients';
  static readonly organizationClientsAddWithNewUserPath = '/organization_clients/new_user';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list organization_clients
   *
   * List current organization's clients
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  organizationClientsListResponse(): __Observable<__StrictHttpResponse<LasgroOrganization_clientCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization_clients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_clientCollection>;
      })
    );
  }
  /**
   * list organization_clients
   *
   * List current organization's clients
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  organizationClientsList(): __Observable<LasgroOrganization_clientCollection> {
    return this.organizationClientsListResponse().pipe(
      __map(_r => _r.body as LasgroOrganization_clientCollection)
    );
  }

  /**
   * add organization_clients
   *
   * Add organization to organization clients
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  organizationClientsAddResponse(payload: OrganizationClientInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organization_clients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add organization_clients
   *
   * Add organization to organization clients
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  organizationClientsAdd(payload: OrganizationClientInputPayload): __Observable<null> {
    return this.organizationClientsAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add_with_new_user organization_clients
   *
   * Add organization to organization clients with a new user like primary contact
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  organizationClientsAddWithNewUserResponse(payload: OrganizationClientWithUserInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organization_clients/new_user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_with_new_user organization_clients
   *
   * Add organization to organization clients with a new user like primary contact
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  organizationClientsAddWithNewUser(payload: OrganizationClientWithUserInputPayload): __Observable<null> {
    return this.organizationClientsAddWithNewUserResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module OrganizationClientsService {
}

export { OrganizationClientsService }
