import {
  ChangeDetectorRef,
  Component, ElementRef, Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList, ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { ContractDto } from '@app/models/contract.dto';
import { PurchaseOrderService } from '@app/services';
import { RejectionReasonModalComponent } from '@app/shared/modals/rejection-reason-modal/rejection-reason-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from '@services/toaster.service';
import { LocalStorage } from 'ngx-webstorage';
import * as es6printJS from 'print-js';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import Swal from 'sweetalert2';

// import printJS = require("print-js");
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import 'svg2pdf.js';
import { BusinessValidator } from '@app/shared/validators';

@Component({
  selector: 'ngx-rcdss-application-form-modal',
  templateUrl: './rcdss-application-form-modal.component.html',
  styleUrls: ['./rcdss-application-form-modal.component.scss'],
})
export class RcdssApplicationFormModalComponent implements OnInit, OnDestroy {
  appForm: UntypedFormGroup;
  isDraft = true;
  selectedForm = 'long';

  // hacer también OUTPUT
  @Input() purchaseOrder: ContractDto;

  @Input() companyType: string;

  @LocalStorage() organizationId;
  deliverableGroup = {
    deliverable_type: ['', [Validators.required, Validators.maxLength(30)]],
    deliverable_field_1: ['', [Validators.required]],
    deliverable_field_2: ['', [Validators.required]],
    deliverable_field_3: ['', [Validators.required]],
    deliverable_field_4: ['', [Validators.required]],
    deliverable_field_5: ['', [Validators.required]],
  };
  printing = false;
  rootViewContainer;
  @ViewChild('printApplicationForm', { static: false }) printContainer: ElementRef;
  @ViewChildren(CdkTextareaAutosize) autoSizeInputs: QueryList<CdkTextareaAutosize>;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private $modal: NgbModal,
    private fb: UntypedFormBuilder,
    private $toaster: Toaster,
    private purchaseOrderService: PurchaseOrderService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
  ) {
    this.setRootViewContainerRef(viewContainerRef);
  }

  ngOnInit() {
    const formFields = (Array.from(Array(34).keys()).reduce((object, current) => {
      object['field_' + (current + 1)] = ['', Validators.required];
      return object;
    }, {}));
    this.appForm = this.fb.group({
      ...formFields,
      county_department_managing: [{ value: 'DSS', disabled: true }],
      additional_eligibility_requirements: this.fb.array([]),
      services_deliverables: this.fb.array([]),
      program_services: this.fb.array([]),
      program_goals: this.fb.array([]),
      performance_outcomes: this.fb.array([]),
      budget_proposal: this.fb.array([]),

      individual_submitting_fax: [],
      proposed_program_fax: [],
      proposed_contract_fax: [],

      rockland_county_resident: [{ value: true, disabled: true }, []],
      rockland_county_resident_value: ['', [Validators.required]],
      unduplicated_number: ['', [Validators.required]],
      low_income: ['', []],
      low_income_value: [{ value: '', disabled: true }, [BusinessValidator.requiredIfExists('low_income')]],
      proposed_program_funded: [false, []],
      possible_ad_hoc_sites: ['', []],
      funding_amount: ['same_amount', []],
      same_amount_value: ['', []],
      additional_funding_value: [{ value: '', disabled: true }, []],
      additional_funding_value_total: [{ value: '', disabled: true }, []],
      population_individuals: ['', []],
      population_family: ['', []],
      service_deliverable_total_amount_requested: [{ value: '', disabled: true },
        [
          Validators.required,
          Validators.min(0),
        ],
      ],
      total_sources: [{ value: '', disabled: true }, [Validators.min(1)]],
      total_sources_p: [{ value: '', disabled: true }, [Validators.max(100)]],
    }, { validators: [BusinessValidator.requiredDisabledFields('service_deliverable_total_amount_requested')] });

    // Set last fields as optional and add percentage fields
    for (let i = 25; i <= 34; i++) {
      const control = this.appForm.get('field_' + i);
      this.appForm.addControl('field_' + i + '_p', new UntypedFormControl({ value: '', disabled: true }, []));
      control.setValidators([Validators.min(0)]);
    }

    if (this.purchaseOrder && this.purchaseOrder.rcdss_application_form) {
      this.selectedForm = this.purchaseOrder.rcdss_application_form.type;
      this.isDraft = this.purchaseOrder.rcdss_application_form.is_draft;
      const formData = JSON.parse(this.purchaseOrder.rcdss_application_form.json_data);

      this.populateForm(formData);

      this.calculateTotalSources();
    } else {
      this.addServiceDeliverables();
      this.addProgramServices();
    }

    // Validation for Other (specify) field
    this.appForm.get('field_33').setValidators([]);
    this.appForm.get('field_34').setValidators([
      Validators.min(0),
      BusinessValidator.requiredIfExists('field_33'),
    ]);

    if (
      this.purchaseOrder.rcdss_application_form.is_draft === false ||
      this.companyType === 'customer'
    ) {
      this.appForm.disable();
      this.ngZone.onStable.pipe(take(1))
        .subscribe(() => {
          this.autoSizeInputs.forEach(i => {
            i.minRows = 2;
            i.resizeToFitContent(true);
          });
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  populateForm(formData) {
    this.appForm.patchValue(formData);
    if (formData.additional_eligibility_requirements && formData.additional_eligibility_requirements.length) {
      formData.additional_eligibility_requirements.forEach(item => this.addEligibilityRequirement(item));
    }
    if (formData.services_deliverables && formData.services_deliverables.length) {
      formData.services_deliverables.forEach(item => this.addServiceDeliverables(item, false));
    }
    if (formData.program_services && formData.program_services.length) {
      formData.program_services.forEach(item => this.addProgramServices(item));
    }
    if (formData.program_goals && formData.program_goals.length) {
      formData.program_goals.forEach(item => this.addProgramGoal(item));
    }
    if (formData.performance_outcomes && formData.performance_outcomes.length) {
      formData.performance_outcomes.forEach(item => this.addPerformanceOutcomes(item));
    }
    if (formData.budget_proposal && formData.budget_proposal.length) {
      formData.budget_proposal.forEach(item => this.addBudgetProposal(item));
    }

    if (this.isDraft) {
      const selectedSourceValue: string = this.appForm.get('funding_amount').value;
      this.requestedFundingCheckboxSwitch({ value: selectedSourceValue });
      if (!!formData.low_income) {
        this.appForm.get('low_income_value').enable();
      }
    }
  }

  saveDraft() {
    const data = this.appForm.getRawValue();
    this.purchaseOrderService.saveRcdssApplicationForm(
      this.organizationId,
      this.purchaseOrder.id,
      this.selectedForm,
      true,
      data,
    )
      .subscribe(result => {
        this.purchaseOrder.rcdss_application_form = result;
        this.ngbActiveModal.dismiss();
        this.$toaster.show('success', 'Success', 'You saved your application form draft.');
      }, (error) => {
        console.error(error);
        this.$toaster.show('error', 'An error occurred', 'Please try again later.');
      });
  }

  cancel() {
    this.closeModal();
  }

  eligibilityRequirements($event: MatCheckboxChange) {
    if ($event.source.name === 'rockland_county_resident') {
      if ($event.checked) {
        this.appForm.get('rockland_county_resident_value').enable();
      } else {
        this.appForm.get('rockland_county_resident_value').reset();
        this.appForm.get('rockland_county_resident_value').disable();
      }
    }
    if ($event.source.name === 'low_income') {
      if ($event.checked) {
        this.appForm.get('low_income_value').enable();
      } else {
        this.appForm.get('low_income_value').reset();
        this.appForm.get('low_income_value').disable();
      }
    }
  }

  requestedFundingCheckboxSwitch($event: MatRadioChange | { value: string }) {
    if ($event.value === 'same_amount') {
      this.appForm.get('same_amount_value').enable();
      this.appForm.get('same_amount_value').setValidators([Validators.required]);
      this.appForm.get('additional_funding_value').reset();
      this.appForm.get('additional_funding_value').disable();
      this.appForm.get('additional_funding_value').setValidators([]);
      this.appForm.get('additional_funding_value_total').reset();
      this.appForm.get('additional_funding_value_total').disable();
      this.appForm.get('additional_funding_value_total').setValidators([]);
    }
    if ($event.value === 'additional_funding') {
      this.appForm.get('additional_funding_value').enable();
      this.appForm.get('additional_funding_value').setValidators([Validators.required]);
      this.appForm.get('additional_funding_value_total').enable();
      this.appForm.get('additional_funding_value_total').setValidators([Validators.required]);
      this.appForm.get('same_amount_value').reset();
      this.appForm.get('same_amount_value').disable();
      this.appForm.get('same_amount_value').setValidators([]);
    }
    this.calculateTotalSources();
  }

  submit() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to make changes after submitting the application form.',
      icon: 'warning',
      // icon: 'info',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Submit',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then((swalResult) => {
      if (swalResult && swalResult?.isConfirmed === true) {

        if (this.appForm.invalid) {
          this.appForm.markAllAsTouched();
          // this.getFormValidationErrors();
          this.$toaster.show('error', 'Error',
            'Please review your application and make sure to provide all required fields.',
          );
          return;
        }
        const data = this.appForm.getRawValue();
        this.purchaseOrderService.saveRcdssApplicationForm(this.organizationId,
          this.purchaseOrder.id,
          this.selectedForm,
          false,
          data,
        )
          .subscribe(result => {
            this.purchaseOrder.rcdss_application_form = result;
            this.$toaster.show('success', 'Success', 'Your application form has been submitted.');
            this.ngbActiveModal.dismiss();
          }, (error) => {
            console.error(error);
            this.$toaster.show('error', 'An error occurred', 'Please try again later.');
          });
      }
    }, (error) => {
      console.error(error);
    });
  }

  print() {
    this.printing = true;

    setTimeout(async () => {
      await es6printJS({
        printable: 'printApplicationForm',
        type: 'html',
        targetStyles: ['*'],
        maxWidth: 1000, // 800
        ignoreElements: ['buttons', 'closePopup'],
        onPrintDialogClose: () => {
          // this.printing = false;
        },
      });
      this.printing = false;
    }, 100);

    /*// es6printJS("printApplicationForm", "html");
    /!*es6printJS({
      printable: 'applicationForm',
      type: 'html',
      targetStyles: ['*'],
      maxWidth: 1200,
      ignoreElements: ['buttons'],
    });*!/

    // this.renderer2.addClass(this.elementRef.nativeElement, 'printing');
    const doc = new jsPDF('l', 'pt', 'a4', true);
    doc.html(this.elementRef.nativeElement, {
      windowWidth: 700,
      width: 700,
      html2canvas: {
        width: 700,
        // svgRendering
        // scale: 0.5,
        // ignoreElements: []
      },
      // windowWidth: 1500,
      callback: (ref) => {
        // ref.autoPrint({ variant: 'javascript' });
        /!*this.renderer2.removeClass(this.elementRef.nativeElement, 'printing');*!/
        // ref.save();
        doc.autoPrint();

        const hiddFrame = document.createElement('iframe');
        hiddFrame.style.position = 'fixed';
        hiddFrame.style.width = '1px';
        hiddFrame.style.height = '1px';
        hiddFrame.style.opacity = '0.01';
        const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
        if (isSafari) {
          // fallback in safari
          hiddFrame.onload = () => {
            try {
              hiddFrame.contentWindow.document.execCommand('print', false, null);
            } catch (e) {
              hiddFrame.contentWindow.print();
            }
          };
        }
        hiddFrame.src = doc.output('bloburl').toString();
        document.body.appendChild(hiddFrame);
      },
      x: 50,
      // y: 10
    });*/
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  closeModal() {
    if (this.companyType === 'customer' || !this.isDraft) {
      this.ngbActiveModal.dismiss();
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Any unsaved changes to the form will be discarded!',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then((result) => {
      if (result && result?.isConfirmed === true) {
        this.ngbActiveModal.dismiss();
      }
    }, (error) => {
      console.error(error);
    });
  }

  approve() {
    Swal.fire({
      title: 'Are you sure that you want to approve this application?',
      text: 'This change cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Approve',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then((result) => {
      if (result && result?.isConfirmed === true) {
        this.changeFormStatus('approved', '');
      }
    }, (error) => {
      console.error(error);
    });
  }

  underReview() {
    Swal.fire({
      title: 'Are you sure that you want to set this application as under review?',
      text: 'This change is not reversible and will be visible by your vendor!',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then((result) => {
      if (result && result?.isConfirmed === true) {
        this.changeFormStatus('closed', '');
      }
    }, (error) => {
      console.error(error);
    });
  }

  reject() {
    setTimeout(() => {
      this.$modal.open(RejectionReasonModalComponent, {
        size: 'sm',
        container: 'nb-layout',
        keyboard: false,
        windowClass: 'rejection-reason-modal',
      }).result
        .then((result: any) => {
          if (result) {
            this.changeFormStatus('rejected', result.reason);
          }
        });
    });
  }

  changeFormStatus(status, reason) {
    this.purchaseOrderService.changeStatusRcdssApplicationForm(this.organizationId, this.purchaseOrder.id, status, reason)
      .subscribe(result => {
        const statusText = status === 'closed' ? 'set as under review' : status;
        this.$toaster.show('success', 'Success', 'This application form has been ' + statusText);
        if (status !== 'closed') {
          this.ngbActiveModal.close(result);
        } else {
          this.purchaseOrder.rcdss_application_form = result;
        }
      }, (error) => {
        console.error(error);
        this.$toaster.show('error', 'An error occurred', 'Please try again later.');
      });
  }

  ngOnDestroy(): void {
  }

  addEligibilityRequirement(value?: any) {
    const formGroup = this.fb.group({
      field_name: ['', [Validators.required]],
      field_value: ['', [Validators.required]],
    });
    if (value) {
      formGroup.patchValue(value);
    }
    (this.appForm.get('additional_eligibility_requirements') as UntypedFormArray).push(formGroup);
  }

  addServiceDeliverables(value?: any, addExtraGroupFields = true) {
    const formGroup = this.fb.group({
      ...this.deliverableGroup,
    }, [Validators.minLength(1)]);
    if (value) {
      formGroup.patchValue(value);
    }
    (this.appForm.get('services_deliverables') as UntypedFormArray).push(formGroup);
    if (addExtraGroupFields) {
      this.addProgramGoal();
      this.addPerformanceOutcomes();
      this.addBudgetProposal();
    }
  }

  addProgramGoal(value?: any) {
    const formGroup = this.fb.group({
      field_value_1: ['', [Validators.required]],
    });
    if (value) {
      formGroup.patchValue(value);
    }
    (this.appForm.get('program_goals') as UntypedFormArray).push(formGroup);
  }

  addProgramServices(value?: any) {
    const formGroup = this.fb.group({
      program_service_field_1: ['', []],
      program_service_field_2: ['', []],
      program_service_field_3: ['', []],
    }, { validators: [BusinessValidator.allOrNoneRequired] });
    if (value) {
      formGroup.patchValue(value);
    }
    formGroup.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.checkAllOrNoneValidation(formGroup);
    });

    (this.appForm.get('program_services') as UntypedFormArray).push(formGroup);
  }

  checkAllOrNoneValidation(formGroup) {
    const controls = Object.keys(formGroup.controls);

    controls.forEach(c => {
      const control = formGroup.get(c);
      if (formGroup.errors && formGroup.errors.allOrNoneRequired) {
        control.setValidators([Validators.required]);
      } else {
        control.setValidators([]);
      }

      control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      formGroup.markAllAsTouched();
    });
    this.changeDetectorRef.detectChanges();
  }

  addPerformanceOutcomes(value?: any) {
    const formGroup = this.fb.group({
      performance_outcome_field_1: ['', [Validators.required]],
      performance_outcome_field_2: ['', [Validators.required]],
      performance_outcome_field_3: ['', [Validators.required]],
    });
    if (value) {
      formGroup.patchValue(value);
    }
    (this.appForm.get('performance_outcomes') as UntypedFormArray).push(formGroup);
  }

  addBudgetProposal(value?: any) {
    const formGroup = this.fb.group({
      budget_proposal_field_1: ['', []],
    });
    if (value) {
      formGroup.patchValue(value);
    }
    (this.appForm.get('budget_proposal') as UntypedFormArray).push(formGroup);
  }

  getFormValidationErrors() {
    this.appForm.get('service_deliverable_total_amount_requested')
      .updateValueAndValidity({ onlySelf: true, emitEvent: false });
    Object.keys(this.appForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.appForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  calculateTotalAmountRequested() {
    let total = 0;
    const budgetProposals = this.appForm.get('budget_proposal') as UntypedFormGroup;
    for (let i = 0; i <= (budgetProposals.controls as any).length - 1; i++) {
      const value = parseFloat(budgetProposals.controls[i].get('budget_proposal_field_1').value) || 0;
      total += value;
    }
    this.appForm.get('service_deliverable_total_amount_requested').setValue(total);
  }

  calculateTotalSources() {
    let total = 0;
    const fundingAmount = this.getFundingAmount();

    for (let i = 25; i <= 33; i++) {
      const value = parseInt(this.appForm.get('field_' + i).value, 10) || 0;
      total += value;
    }
    const lastValue = parseInt(this.appForm.get('field_34').value, 10) || 0;
    total += lastValue;

    const totalFunding = total + fundingAmount;
    for (let i = 25; i <= 33; i++) {
      const value = parseInt(this.appForm.get('field_' + i).value, 10) || 0;
      let percent = '0';
      if (fundingAmount > 0 && value > 0 && i < 33) {
        percent = ((value / totalFunding) * 100).toFixed(1);
      }
      this.appForm.get('field_' + i + '_p').setValue(percent);
    }

    if (fundingAmount > 0 && lastValue > 0) {
      const lastPercentage = ((lastValue / totalFunding) * 100);
      this.appForm.get('field_33_p').setValue(lastPercentage.toFixed(1));
    }

    if (fundingAmount) {
      this.appForm.get('total_sources_p').setValue(((total / totalFunding) * 100).toFixed(1));
    }

    this.appForm.get('total_sources').setValue(total);
  }

  getFundingAmount() {
    const value = this.appForm.get('same_amount_value').value ||
      this.appForm.get('additional_funding_value_total').value;
    return parseInt(value, 10) || 0;
  }

  removeEligibilityRequirement(index) {
    (this.appForm.get('additional_eligibility_requirements') as UntypedFormArray).removeAt(index);
  }

  removeServiceDeliverables(index) {
    (this.appForm.get('services_deliverables') as UntypedFormArray).removeAt(index);

    // Remove generated group fields
    (this.appForm.get('program_goals') as UntypedFormArray).removeAt(index);
    (this.appForm.get('performance_outcomes') as UntypedFormArray).removeAt(index);
    (this.appForm.get('budget_proposal') as UntypedFormArray).removeAt(index);
  }

  removeProgramService(index) {
    (this.appForm.get('program_services') as UntypedFormArray).removeAt(index);
  }
}
