import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-image-viewer',
  styleUrls: ['./image-viewer.component.scss'],
  templateUrl: './image-viewer.component.html',
})
export class ImageViewerComponent implements OnInit {
  @Input() loading: false;
  @Input() title: string;
  @Input() src: any;
  @Output() emitData: EventEmitter<any> = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  close(status: string) {
    this.emitData.emit(status);
    this.activeModal.close(status);
  }
}
