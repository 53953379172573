import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MailComponent } from '@app/shared/components/mail/mail.component';
import { AppConstants } from '@app/shared/constants/app.constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  selector: 'task-notification',
  templateUrl: './task-notification.modal.html',
  styleUrls: ['./task-notification.modal.scss'],
  imports: [
    MailComponent,
    CommonModule,
  ]
})
export class TaskNotificationModal implements OnInit {
  type = AppConstants.TaskMailType;
  onSave = new EventEmitter();
  public mailId = null;
  constructor(private modalRef: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalRef.close();
  }

  onSaveClick(event) {
    this.modalRef.close();
    this.onSave.emit(event)
  }
}
