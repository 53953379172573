<!-- REISSUE/ARCHIVE BTNS -->
<ng-container *ngIf="contract">
  <div *ngIf="viewType === 'customer' && contract?.status === 'rejected' && !isEDI">
    <div class="row justify-content-center mb-2">
      <div class="col-6 col-sm-2">
        <button type="button" class="btn btn-success btn-block p-2" (click)="reIssuePO()">RE-ISSUE PO</button>
      </div>
      <div class="col-6 col-sm-2">
        <button type="button" class="btn btn-danger btn-block p-2" (click)="archivePO()">ARCHIVE PO</button>
      </div>
    </div>
  </div>

  <div class="container-fluid pt-4" *ngIf="!isEDI">
    <div class="row" *ngIf="contract?.id">
      <div class="col-md-12">
        <div class="purchase-order-container border jumbotron-fluid">
          <form>
            <div class="row">
              <div class="col-sm-12">
                <div class="title mb-2">
                  <strong>Contract Details</strong>
                </div>
              </div>
              <div class="col-md-2">
                <mat-form-field>
                  <input
                    type="text"
                    matInput
                    placeholder="Contract ID"
                    [value]="contract.customer_po_number"
                    disabled
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field *ngIf="viewType == 'customer'">
                  <ng-container *ngIf="showSuppliersList(); else supplierInput">
                    <mat-select placeholder="Supplier" [value]="contract.supplier_id">
                      <mat-option *ngFor="let supplier of suppliers" [value]="supplier.id">
                        {{ supplier.name }}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                  <ng-template #supplierInput>
                    <input matInput placeholder="Vendor" [value]="supplier?.name" disabled />
                  </ng-template>
                </mat-form-field>
                <mat-form-field *ngIf="viewType == 'supplier'">
                  <input matInput placeholder="Department" [value]="customer?.name" disabled />
                </mat-form-field>
              </div>
              
              <div class="col-md-3">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Deliverable"
                    [value]="contract.rcdss_contract_program"
                    disabled
                  />
                </mat-form-field>
              </div>
              <ng-container *ngIf="viewType == 'customer'">
                <div class="col-md-3">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Contact name"
                      value="{{ supplier?.contact?.first_name + ' ' + supplier?.contact?.last_name }}"
                      disabled
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <input matInput placeholder="Email" [value]="supplier?.contact?.email" disabled />
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-container *ngIf="viewType == 'supplier'">
                <div class="col-md-3">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Contact name"
                      value="{{ customer?.contact?.first_name + ' ' + customer?.contact?.last_name }}"
                      disabled
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <input matInput placeholder="Email" [value]="customer?.contact?.email" disabled />
                  </mat-form-field>
                </div>
              </ng-container>
              <div class="col-md-3">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Term Date Start"
                    [value]="contract.term_date_start | date : 'MM/dd/yyyy'"
                    disabled
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Term Date End"
                    [value]="contract.term_date_end | date : 'MM/dd/yyyy'"
                    disabled
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Contract Type"
                    [value]="contract.contract_type"
                    disabled
                  />
                </mat-form-field>
              </div>
              <div class="col-md-2" *ngIf="viewType === 'customer' &&  currentUser.role != 'User'">
                <button class="btn main-btn" style="height: 40px" type="button" (click)="openSubscribersModal()">
                  Notification Settings
                </button>
              </div>
              <div class="col-md-2" *ngIf="viewType === 'customer' &&  currentUser.role != 'User'">
                <button class="btn main-btn" style="height: 40px" type="button" (click)="openFileTypeModal()">
                  File Type Settings
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>
