<h1>Change file type</h1>
<mat-dialog-content style="overflow: hidden;">
  <mat-form-field class="example-chip-list">
    <mat-chip-list #chipList aria-label="Types selection">
      <input
        [matAutocomplete]="auto"
        placeholder="Type of file"
        #typesInput
        [formControl]="fileTypesControl"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let typesGroup of filteredTypesGroup | async" [value]="typesGroup.values">
        {{typesGroup.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="col-12">
    <div *ngFor="let type of purchaseOrder.file_types" class="type-item d-flex justify-content-center">
      Expiration date is required for {{type.value}}?  <mat-checkbox class="ml-2 mr-2" [(ngModel)]="type.required"></mat-checkbox>
      <mat-icon (click)="remove(type)">delete</mat-icon>
    </div>
  </div>
  <div>
    <mat-checkbox [value]="saveTypesGroup" (change)="saveTypesGroup = !saveTypesGroup">
      Do you want save file types to separate group?</mat-checkbox
    >
  </div>
  <div *ngIf="saveTypesGroup">
    <mat-form-field>
      <input matInput [(ngModel)]="fileTypeGroup.name" placeholder="Type group name" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<div mat-dialog-actions>
  <button color="primary" mat-raised-button (click)="onSaveClick()" mat-dialog-close>Save</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</div>
