import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyDto, ContractDto, User } from '@app/models';
import { PoSubscribersComponent } from '@app/modules/purchase-order/components/po-subscribers/po-subscribers.component';
import { PurchaseOrderFileTypeModal } from '@app/modules/purchase-order/modals/purchase-order-file-type/purchase-order-file-type.modal';
import { RcdssApplicationFormModalComponent } from '@app/modules/purchase-order/modals/rcdss-application-form-modal/rcdss-application-form-modal.component';
import { RcdssContractModalComponent } from '@app/modules/purchase-order/modals/rcdss-contract-modal/rcdss-contract-modal.component';
import { Toaster } from '@app/services';
import { ContractHelper } from '@app/shared/helpers/contract.helper';
import { ReIssueReasonModalComponent, RejectionReasonModalComponent } from '@app/shared/modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApiModule } from 'api/api.module';
import { PurchaseOrderFilesService, PurchaseOrderService } from 'api/services';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { LocalStorage } from 'ngx-webstorage';
import selectFiles from 'select-files';
import { BaseComponent } from '../../base.component';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule

  ],
  providers: [],
})
export class ContractDetailsComponent extends ContractBaseComponent implements OnInit, AfterViewInit {
  currentUser: User;
  contractFile: any;
  sendingToSupplier: boolean = false;
  contract: ContractDto;
  customer: CompanyDto;
  supplier: CompanyDto;
  @LocalStorage() organizationId;
  @LocalStorage() contractId;

  constructor(
    public contractHelper: ContractHelper,
    private modal: NgbModal,
    private activatedRoute: ActivatedRoute,
    public store: Store<{ user: User }>,
    private toaster: Toaster,
    private changeDetector: ChangeDetectorRef,
    private contractService: PurchaseOrderService,
    private contractFilesService: PurchaseOrderFilesService,
    private router: Router,
    private matDialog: MatDialog,
    public injector: Injector
  ) {
    super(injector, store);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
  }

  showSuppliersList(): boolean {
    return this.contract.isRejected || this.contract.isPending;
  }

  approveContract(accepted: boolean) {
    if (!accepted) {
      const modalRef = this.modal.open(RejectionReasonModalComponent, {
        size: 'sm',
        container: 'nb-layout',
        keyboard: false,
        windowClass: 'rejection-reason-modal',
      });
      modalRef.componentInstance.required = true;
      modalRef.result.then((result: any) => {
        if (result) {
          if (result.reason && result.reason.length) {
            this.contract.reason = result.reason;
            this.contract.status = 'rejected';
            this.updateContract(false).then(() => this.toaster.show('success', 'Rejection', 'Contract rejected'));
          } else {
            this.toaster.show('error', 'Rejection', 'A reason is required');
          }
        }
      });
    } else {
      this.contract.status = 'approved';
      this.updateContract(false);
    }

    this.changeDetector.detectChanges();
  }

  reIssuePO() {
    const modalRef = this.modal.open(ReIssueReasonModalComponent, {
      size: 'sm',
      container: 'nb-layout',
      keyboard: false,
      windowClass: 'rejection-reason-modal',
    });
    modalRef.componentInstance.required = true;
    modalRef.result.then((result: any) => {
      if (result) {
        if (result.reason && result.reason.length) {
          this.contract.reason = result.reason;
          this.contract.status = 'pending';
          this.updateContract();
        } else {
          this.toaster.show('error', 'RE-ISSUE', 'A reason is required');
        }
      }
    });
  }

  archivePO() {
    this.contract.status = 'archived';
    this.contractService
      .purchaseOrderUpdate({
        organizationID: this.organizationId,
        purchaseOrderID: this.contract.id,
        payload: this.contract,
      })
      .subscribe((result) => {
        this.contractHelper.contractListent.sendMessage({
          action: 'archive_po',
        });
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      });
  }

  sentToSupplier() {
    this.sendingToSupplier = true;
    this.updateContract(false).then(
      () => {
        this.contractService.purchaseOrderSendToSupplier(this.contract.id).subscribe(
          () => {
            this.sendingToSupplier = false;
            this.toaster.show('success', 'Status Updated', 'Your Po was sent to supplier');
            this.contractHelper.getContractData();
          },
          (error) => {
            this.sendingToSupplier = false;
            const errMsg = !!error.error && !!error.error.detail ? error.error.detail : error.message;
            this.toaster.show('error', "Couldn't send to supplier", errMsg);
          },
        );
      },
      () => {
        this.sendingToSupplier = false;
      },
    );
  }

  updateContract(showNotificationMessages = true) {
    if (!this.validateDates()) {
      this.toaster.show(
        'warning',
        'Incomplete form',
        `There are some fields you need to complete before sending this Contract to the supplier`,
      );
      return Promise.reject();
    }

    const locale = window.navigator.language;
    moment.locale(locale);

    if (this.contract.ship_date) {
      this.contract.ship_date = moment.utc(this.contract.ship_date).format();
    }
    if (this.contract.order_date) {
      this.contract.order_date = moment.utc(this.contract.order_date).format();
    }
    if (this.contract.cancel_date) {
      this.contract.cancel_date = moment.utc(this.contract.cancel_date).format();
    }
    if (this.contract.report_start_date) {
      this.contract.report_start_date = moment.utc(this.contract.report_start_date).format();
    }

    return this.contractService
      .purchaseOrderUpdate({
        organizationID: this.organizationId,
        purchaseOrderID: this.contract.id,
        payload: this.contract,
      })
      .toPromise()
      .then(
        (result: ContractDto) => {
          if (showNotificationMessages) {
            this.toaster.show('success', 'Contract', 'Your change was saved successfully');
          }
          if (moment.utc(result.cancel_date).year() === 1900) {
            delete result.cancel_date;
          }
          if (moment.utc(result.ship_date).year() === 1900) {
            delete result.ship_date;
          }
          this.contract = result;
          this.contractHelper.contractListent.sendMessage({ action: 'purchase_order_updated' });
        },
        (error) => {
          if (showNotificationMessages) {
            const errMsg =
              !!error.error && !!error.error.detail ? error.error.detail : `An error occurred updating this  Contract`;
            this.toaster.show('error', 'Update Error', errMsg);
          }
          if (this.contract.status !== 'qb-imported') {
            this.contractHelper.getContractData();
          }
          return error;
        },
      );
  }

  validateDates() {
    const { ship_date, cancel_date, order_date, report_start_date } = this.contract;

    if (moment.utc(cancel_date).format() < moment.utc(ship_date).format()) {
      const errMessage = `The cancel date should be equal or greater than the shipping date`;
      this.toaster.show('warning', 'Invalid dates', errMessage);
      return false;
    }
    if (moment.utc(ship_date).format() < moment.utc(order_date).format()) {
      const errMessage = `The ship date should be equal or greater than the order date`;
      this.toaster.show('warning', 'Invalid dates', errMessage);
      return false;
    }
    if (moment.utc(cancel_date).format() < moment.utc(order_date).format()) {
      const errMessage = `The cancel date should be equal or greater than the order date`;
      this.toaster.show('warning', 'Invalid dates', errMessage);
      return false;
    }
    if (moment.utc(report_start_date).format() < moment.utc(order_date).format()) {
      const errMessage = `The report start date should be equal or greater than the order date`;
      this.toaster.show('warning', 'Invalid dates', errMessage);
      return false;
    }
    return true;
  }

  uploadRcdssContractPdf() {
    selectFiles({ accept: 'application/pdf' }).then((files) => {
      this.loading = true;
      const file = files[0];

      this.contractFilesService
        .purchaseOrderFilesRcdssUploadContractFile({
          organizationID: this.organizationId,
          purchaseOrderID: this.contract.id,
          file,
        })
        .subscribe(
          (result) => {
            this.loading = false;
            this.contract.rcdss_panda_doc_contract_document = result;
            if (result.status === 'document.uploaded' || result.status === 'document.draft') {
              this.startDocumentStatusCheckJob();
            }
            this.toaster.show('info', 'Notice', 'Your contract file has been uploaded and is being processed.');
          },
          (error) => {
            this.loading = false;
            console.error(error);
            this.toaster.show('error', 'An error occurred', 'Please try again later.');
          },
        );
    });
  }

  startDocumentStatusCheckJob() {
    var i = 0;
    var intervalId = window.setInterval(
      function () {
        if (i > 15) {
          clearInterval(intervalId);
          return;
        }
        i++;
        if (!this.purchaseOrder?.rcdss_panda_doc_contract_document?.status) {
          this.toaster.show(
            'error',
            'There was a problem processing the contract file',
            "Please make sure that you are uploading the right file and that it meets PandaDoc's recommedations.",
          );
          clearInterval(intervalId);
          return;
        }
        if (this.purchaseOrder.rcdss_panda_doc_contract_document.status === 'document.sent') {
          this.toaster.show('success', 'Notice', 'The contract document is now ready to be signed by participants.');
          clearInterval(intervalId);
          return;
        }
        this.getUpdatedData(false);
      }.bind(this),
      5000,
    );
  }

  getNameOfNextUserToSignRcdssContract() {
    if (!this.contract?.rcdss_panda_doc_contract_document?.recipients) {
      return 'N/A';
    }
    const p = this.contract?.rcdss_panda_doc_contract_document?.recipients.find((p) => p.has_completed === false);

    if (!p) {
      return 'N/A';
    }

    return p.first_name + ' ' + p.last_name;
  }

  getUserRcdssContractParticipant() {
    if (!this.contract?.rcdss_panda_doc_contract_document?.recipients) {
      return undefined;
    }
    return this.contract?.rcdss_panda_doc_contract_document?.recipients.find((p) => p.email === this.currentUser.email);
  }

  isUserRcdssContractParticipantNextToSign() {
    if (!this.contract?.rcdss_panda_doc_contract_document?.recipients) {
      return false;
    }

    let participant = this.contract?.rcdss_panda_doc_contract_document?.recipients.find(
      (p) => p.email === this.currentUser.email,
    );

    if (!participant) {
      return false;
    }

    let previous = this.contract?.rcdss_panda_doc_contract_document?.recipients.find(
      (p) => p.signing_order === participant.signing_order - 1,
    );

    return (!previous || previous.has_completed === true) && participant.has_completed === false;
  }

  openRcdssContractModal() {
    this.contractFilesService
      .purchaseOrderFilesRcdssCreateContractDocumentLink({
        organizationID: this.organizationId,
        purchaseOrderID: this.contract.id,
      })
      .subscribe(
        (res) => {
          console.log(res);
          const activeModal = this.modal.open(RcdssContractModalComponent, {
            size: 'xl',
            container: 'nb-layout',
            backdrop: 'static',
          });
          activeModal.componentInstance.documentID = res.id;
          activeModal.componentInstance.isCompleted = this.getUserRcdssContractParticipant()?.has_completed === true;
          activeModal.result
            .then((result) => {
              if (result.error) {
                console.error(result.error);
              }
              this.contractHelper.getContractData(false);
            })
            .catch((error) => {
              console.error(error);
              this.contractHelper.getContractData(false);
            });
        },
        (error) => {
          console.error(error);
          this.toaster.show('error', 'An error occurred', 'Please try again later');
        },
      );
  }

  hasFinalContractFile() {
    if (!this.contract || !this.contract?.files) {
      return false;
    }

    let finalContractFile = this.contract.files.find((file) => {
      return file?.tags.find((tag) => tag === '$final_contract_file');
    });

    return !!finalContractFile;
  }

  downloadRcdssContractPdf() {
    this.contractFilesService
      .purchaseOrderFilesRcdssDownloadContractFile({
        organizationID: this.organizationId,
        purchaseOrderID: this.contract.id,
      })
      .subscribe(
        (res: any) => {
          if (res && res.body) {
            const blob = new Blob([res.body], { type: 'application/octet-stream' });
            saveAs(blob, this.contract?.rcdss_panda_doc_contract_document?.name);
          }
        },
        (error) => {
          console.error(error);
          this.toaster.show('error', 'An error occurred', 'Please try again later');
        },
      );
  }

  openApplicationFormModal() {
    const activeModal = this.modal.open(RcdssApplicationFormModalComponent, {
      size: 'xl',
      container: 'nb-layout',
      keyboard: false,
      backdrop: 'static',
    });
    activeModal.componentInstance.purchaseOrder = this.contract;
    activeModal.componentInstance.companyType = this.viewType;
    activeModal.result
      .then((result) => {
        if (result) {
          this.contractHelper.getContractData();
        }
      })
      .catch(() => {});
  }

  openSubscribersModal() {
    const activeModal = this.modal.open(PoSubscribersComponent, {
      size: 'lg',
      container: 'nb-layout',
    });
    activeModal.componentInstance.allowEdit = true;
    activeModal.componentInstance.companyType = this.viewType;
    activeModal.componentInstance.supplierId = this.contract.supplier_id;
    activeModal.componentInstance.purchaseOrder = this.contract;
    activeModal.componentInstance.modal = activeModal;
    activeModal.result.then((result) => {}).catch(() => {});
  }

  openFileTypeModal() {
    let dialgRef = this.matDialog.open(PurchaseOrderFileTypeModal, {
      data: { organizationId: this.organizationId, purchaseOrder: this.contract },
    });
  }
}
