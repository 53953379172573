<div *ngIf="contract">
  <ngx-check-list
    [purchaseOrderId]="contract.id"
    [purchaseOrder]="contract"
    [companyType]="viewType"
    [allowEdit]="allowEdit"
    [selectedTaskId]="selectedTaskId"
    >
  </ngx-check-list>
</div>
