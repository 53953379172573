/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroAnalytics_pending_avg } from '../models/lasgro-analytics-_pending-_avg';
import { LasgroGeneral_success_payload } from '../models/lasgro-general-_success-_payload';
import { AddPendingAverageInputPayload } from '../models/add-pending-average-input-payload';
import { LasgroAnalytics_non_approved_filesCollection } from '../models/lasgro-analytics-_non-_approved-_files-collection';
import { LasgroAnalytics_resourcesCollection } from '../models/lasgro-analytics-_resources-collection';
import { LasgroAnalytics } from '../models/lasgro-analytics';
@Injectable({
  providedIn: 'root',
})
class AnalyticsService extends __BaseService {
  static readonly analyticsShowPendingAveragePath = '/organizations/{organizationID}/analytics/average-pending';
  static readonly analyticsAddPendingAveragePath = '/organizations/{organizationID}/analytics/average-pending/generate';
  static readonly analyticsShowNonApprovedFilesPath = '/organizations/{organizationID}/analytics/non-approved-files';
  static readonly analyticsShowResourcesPath = '/organizations/{organizationID}/analytics/resources';
  static readonly analyticsShowPath = '/organizations/{organizationID}/analytics/resources/{resourceID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * show_pending_average analytics
   *
   * fetches the historical data of submitted pending documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowPendingAverageParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowPendingAverageResponse(params: AnalyticsService.AnalyticsShowPendingAverageParams): __Observable<__StrictHttpResponse<LasgroAnalytics_pending_avg>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/average-pending`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_pending_avg>;
      })
    );
  }
  /**
   * show_pending_average analytics
   *
   * fetches the historical data of submitted pending documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowPendingAverageParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowPendingAverage(params: AnalyticsService.AnalyticsShowPendingAverageParams): __Observable<LasgroAnalytics_pending_avg> {
    return this.analyticsShowPendingAverageResponse(params).pipe(
      __map(_r => _r.body as LasgroAnalytics_pending_avg)
    );
  }

  /**
   * add_pending_average analytics
   *
   * generate some pending average data
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsAddPendingAverageParams` containing the following parameters:
   *
   * - `payload`: input payload for generating pending average analytical data
   *
   * - `organizationID`:
   *
   * @return Accepted
   */
  analyticsAddPendingAverageResponse(params: AnalyticsService.AnalyticsAddPendingAverageParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/average-pending/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * add_pending_average analytics
   *
   * generate some pending average data
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsAddPendingAverageParams` containing the following parameters:
   *
   * - `payload`: input payload for generating pending average analytical data
   *
   * - `organizationID`:
   *
   * @return Accepted
   */
  analyticsAddPendingAverage(params: AnalyticsService.AnalyticsAddPendingAverageParams): __Observable<LasgroGeneral_success_payload> {
    return this.analyticsAddPendingAverageResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * show_non_approved_files analytics
   *
   * generate some non approved files report
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowNonApprovedFilesParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowNonApprovedFilesResponse(params: AnalyticsService.AnalyticsShowNonApprovedFilesParams): __Observable<__StrictHttpResponse<LasgroAnalytics_non_approved_filesCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/non-approved-files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_non_approved_filesCollection>;
      })
    );
  }
  /**
   * show_non_approved_files analytics
   *
   * generate some non approved files report
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowNonApprovedFilesParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowNonApprovedFiles(params: AnalyticsService.AnalyticsShowNonApprovedFilesParams): __Observable<LasgroAnalytics_non_approved_filesCollection> {
    return this.analyticsShowNonApprovedFilesResponse(params).pipe(
      __map(_r => _r.body as LasgroAnalytics_non_approved_filesCollection)
    );
  }

  /**
   * show_resources analytics
   *
   * fetches the available analytical resources
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsShowResourcesResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_resourcesCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/resources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_resourcesCollection>;
      })
    );
  }
  /**
   * show_resources analytics
   *
   * fetches the available analytical resources
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsShowResources(organizationID: string): __Observable<LasgroAnalytics_resourcesCollection> {
    return this.analyticsShowResourcesResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_resourcesCollection)
    );
  }

  /**
   * show analytics
   *
   * fetches the analytical data of submitted documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowResponse(params: AnalyticsService.AnalyticsShowParams): __Observable<__StrictHttpResponse<LasgroAnalytics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/resources/${encodeURIComponent(String(params.resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics>;
      })
    );
  }
  /**
   * show analytics
   *
   * fetches the analytical data of submitted documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShow(params: AnalyticsService.AnalyticsShowParams): __Observable<LasgroAnalytics> {
    return this.analyticsShowResponse(params).pipe(
      __map(_r => _r.body as LasgroAnalytics)
    );
  }
}

module AnalyticsService {

  /**
   * Parameters for analyticsShowPendingAverage
   */
  export interface AnalyticsShowPendingAverageParams {
    organizationID: string;
    to?: string;
    from?: string;
  }

  /**
   * Parameters for analyticsAddPendingAverage
   */
  export interface AnalyticsAddPendingAverageParams {

    /**
     * input payload for generating pending average analytical data
     */
    payload: AddPendingAverageInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for analyticsShowNonApprovedFiles
   */
  export interface AnalyticsShowNonApprovedFilesParams {
    organizationID: string;
    to?: string;
    from?: string;
  }

  /**
   * Parameters for analyticsShow
   */
  export interface AnalyticsShowParams {
    resourceID: string;
    organizationID: string;
    to?: string;
    from?: string;
  }
}

export { AnalyticsService }
