import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from 'ngx-webstorage';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import CONFIG from '@app/app.config';

@Component({
  selector: 'ngx-add-link-file-modal',
  templateUrl: './add-link-file-modal.component.html',
})
export class AddLinkFileModalComponent implements OnInit {
  @LocalStorage() organizationId: string;
  @Input() metadata: string = '';
  @Input() isMetaUpdate: boolean;
  link: string = '';

  modalForm: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    protected router: Router,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    if (this.isMetaUpdate) {
      this.modalForm = this.fb.group({
        metadata: ['', [Validators.required]],
      });
    } else {
      this.modalForm = this.fb.group({
        link: ['', [Validators.required, Validators.pattern(CONFIG.URL_REGEX)]],
        metadata: [],
      });
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  submit() {
    const link = this.link.trim();
    const metadata = this.metadata.trim();
    this.activeModal.close({ link: link, metadata: metadata });
  }
}
