<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between">
    <h2 mat-dialog-title>Files</h2>
    <div class="close-modal">
      <button type="button" class="close" aria-label="close" (click)="modal.close(removedFiles)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <!-- Files -->
  <mat-dialog-content class="h-100 content-container">
    <ngx-loading [show]="filesLoading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>


    <!-- Form -->
    <div class="row" [formGroup]="form">
      <div class="col-md-4">
        <mat-form-field>
          <mat-select
            placeholder="File type"
            formControlName="file_type"
          >
            <mat-option *ngFor="let fileType of fileTypes" [value]="fileType.value">
              {{ fileType.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-select
            placeholder="Shipment (optional)"
            formControlName="shipment_tracking_id"
          >
            <mat-option>N/A</mat-option>
            <mat-option *ngFor="let shipment of shipments" [value]="shipment.id">
              {{ shipment?.tracking_code }} {{ '(' + shipment?.description + ')' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 add-files">
        <label for="fileToUpload" class="clickable">
          <i class="fa fa-paperclip"></i>
          Upload file
          <input type="file" id="fileToUpload" class="d-none" (change)="addFileSelectedTask($event)"/>
        </label>
      </div>
    </div>

    <div class="files">
        <div class="file" *ngFor="let file of files; let $index = index">
          <div class="row" *ngIf="file.shipment_file_info">
            <div class="col-md-10 offset-md-1">
              <div class="icon-file pl">
                <i class="fa fa-paperclip"></i>
              </div>
              <div class="filename">
                <a ngxFilesPreview
                    [fileId]="file.file_id">
                  <span *ngIf="file?.label">{{ file?.label }} - </span>
                  {{ file.file_name }}
                </a>
              </div>
              <div class="file-info">
                {{ getShipmentDetailsText(file?.shipment_file_info?.shipment_tracking_id) }}
              </div>
              <div class="file-info">
                Uploaded at
                <span>{{ file.created_at | date:'MM/dd/yyyy':'utc' }}</span>
                by
                <span>{{ file.created_by_name }}</span>
                <span>({{ file.file_size | fileSize }})</span>
              </div>
            </div>
            <div class="col-md-1">
              <a
                *ngIf="((user$ | async).id === file.created_by) && allowEdit"
                (click)="removeFile(file)" [matTooltip]="'Delete file'"
              >
                <span class="icon-file trash" aria-hidden="true">
                  <i class="fa fa-trash"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
    </div>
  </mat-dialog-content>
</div>
