export class VesselData {
  name: string;
  latitude: Number;
  longitude: Number;
  timestamp: Date;
  heading: Number;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}

export class Milestone {
  name: string;
  locode: string;
  timezone: string;
  location: string;
  latitude: number;
  longitude: number;
  key: string;
  is_done: boolean;
  actual: Date;
  detected: Date;
  planned_initial: Date;
  planned_last: Date;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}

export class ContainerData {
  id: string;
  external_id: number;
  url: string;
  container_number: string;
  bol_number?: string;
  booking_number?: string;
  name?: string;
  container_type: string;
  container_type_iso: string;
  eta_changed_48h: boolean;
  status: string;
  customs_release_state?: boolean;
  customs_release_date?: string;

  past_track: any[];

  constructor(data?: any) {
    Object.assign(this, data);
  }
}

export class OceanTrackingData {
  status: string;
  // new; active; completed; cancelled; invalid; archived
  last_carrier_update: Date;
  last_actuals_update: Date;
  updated_at: Date;
  description: string;
  contractual_etd_pol: Date;
  contractual_eta_pod: Date;
  contractual_eta_dlv: Date;
  container: ContainerData;

  current_vessel: VesselData;

  milestones: Milestone[];

  current_milestone: Milestone;
  origin_milestone: Milestone;
  pod_milestone: Milestone;
  url: string;
  subscription_status_page_url: string;

  constructor(data?: any) {
    Object.assign(this, data);
    if (data.containers && data.containers.length) {
      this.milestones = data.milestones.map(c => new Milestone(c));
    }

    if (data.container) {
      this.container = new ContainerData(data.container);
    }
    if (data.current_vessel) {
      this.current_vessel = new VesselData(data.current_vessel);
    }
    if (data.current_milestone) {
      this.current_milestone = new Milestone(data.current_milestone);
    }
    if (data.origin_milestone) {
      this.origin_milestone = new Milestone(data.origin_milestone);
    }
    if (data.pod_milestone) {
      this.pod_milestone = new Milestone(data.pod_milestone);
    }
  }
}
