import { Component, OnInit } from '@angular/core';
import { CalendlyService } from '@app/modules/calendly/services/calendly.service';
import { LocalStorage } from 'ngx-webstorage';
import { filter, take } from 'rxjs/operators';
import { User } from '@app/models';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { environment } from '@env/environment';

declare var Calendly: any;

@Component({
  selector: 'ngx-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.scss'],
})
export class CalendlyComponent implements OnInit {
  isEventScheduled: boolean = false;
  @LocalStorage() organizationId;
  currentUser: User;
  user$: Observable<User>;
  calendlyEventUrl = environment.calendlyEventUrl;

  constructor(
    private store: Store<{ user: User }>,
    private calendlyService: CalendlyService,
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {

    this.user$ = this.store.select('user');
    this.user$
      .pipe(filter(value => !_.isEmpty(value)))
      .pipe(take(1))
      .subscribe((user) => {
        this.currentUser = (user as User);
      });
    Calendly._autoLoadInlineWidgets();

    window.addEventListener(
      'message',
      (e) => {
        if (this.isCalendlyEvent(e)) {
          if (e.data.event === 'calendly.event_scheduled') {
            this.calendlyService.confirmEventSchedule(this.organizationId, this.currentUser.id, true)
              .subscribe(() => {
                this.isEventScheduled = true;
              });
          }
        }
      },
    );
  }

  closePopup() {
    if (this.isEventScheduled) {
      this.activeModal.close();
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Your Customer will be notified you elected not to schedule training.',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',

    }).then((result) => {
      if (result.value) {
        this.calendlyService.confirmEventSchedule(this.organizationId, this.currentUser.id, false)
          .subscribe(() => {
            this.activeModal.close();
          });
      }
    });
  }

  isCalendlyEvent(e) {
    return e.data.event &&
      e.data.event.indexOf('calendly') === 0;
  }

}
