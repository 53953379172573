import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Toaster } from '@app/services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ngx-rcdss-contract-modal',
  templateUrl: './rcdss-contract-modal.component.html',
  styleUrls: ['./rcdss-contract-modal.component.scss'],
})
export class RcdssContractModalComponent implements OnInit {

  @Input() documentID: string;
  @Input() isCompleted: boolean = false;
  iframeUrl;

  constructor(
    private $toaster: Toaster,
    private sanitizer: DomSanitizer,
    private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://app.pandadoc.com/s/' + this.documentID);
    window.addEventListener('message', (event) => {
      const type = event.data && event.data.type;
      const payload = event.data && event.data.payload;

      switch (type) {
        case 'session_view.document.loaded':
          console.log('Session view is loaded');
          break;
        case 'session_view.document.completed':
          console.log('Document is completed');
          console.log(payload);
          this.isCompleted = true;
          break;
        case 'session_view.document.exception':
          console.log('Exception during document finalization');
          console.log(payload);
          this.activeModal.dismiss({error: payload});
      }
    });
  }

  closeModal() {
    if (this.isCompleted) {
      this.activeModal.dismiss();
      return;
    }

    Swal.fire({
      title: 'Confirmation required',
      text: 'You haven\'t completed the document yet, do you still want to close this window?',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then((result) => {
      if (result && result?.isConfirmed === true) {
        this.activeModal.dismiss();
      }
    }, (error) => {
      console.error(error);
    });
  }

}
