import { Injectable } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { CalendlyComponent } from '@app/modules/calendly/components/calendly/calendly.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import CONFIG from '@app/app.config';

@Injectable({
  providedIn: 'root',
})
export class CalendlyService {
  overlayRef: OverlayRef;

  constructor(
    private $modal: NgbModal,
    private httpClient: HttpClient) {
  }

  showCalendly() {

    const modalRef = this.$modal.open(CalendlyComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      windowClass: 'calendly-popup',
    });
  }

  confirmEventSchedule(organizationId: string, userId: string, scheduled: boolean) {
    const url = `${CONFIG.API}/organizations/${organizationId}/users/${userId}/scheduled_onboarding`;

    return this.httpClient.put(url, {
      scheduled,
    });
  }
}
