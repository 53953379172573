import { Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyDto, ContractDto, User } from '@app/models';
import { ContractHelper } from '@app/shared/helpers/contract.helper';
import { Store } from '@ngrx/store';
import { LocalStorage } from 'ngx-webstorage';
import { BaseComponent } from '../base.component';

export class ContractBaseComponent extends BaseComponent {
  contract: ContractDto;
  customer: CompanyDto;
  supplier: CompanyDto;
  currentUser: User
  @LocalStorage() organizationId;
  @LocalStorage() contractId;

  constructor(public injector: Injector,  public store: Store<{ user: User }>,) {
    super()
    this.store.select('user').subscribe((user => this.currentUser = user));
    this.injector.get(ContractHelper).contract.subscribe((contract: ContractDto) => {
      this.contract = contract;
    });
    this.injector.get(ContractHelper).customer.subscribe((customer: CompanyDto) => {
      this.customer = customer;
    })
    this.injector.get(ContractHelper).supplier.subscribe((supplier: CompanyDto) => {
      this.supplier = supplier;
    })
    this.injector.get(ActivatedRoute).params.subscribe((params) => {
      this.contractId = params['purchaseOrderId'];
    });
    this.injector.get(ContractHelper).getContractData();
  }
}
