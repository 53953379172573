<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between">
    <h2 mat-dialog-title>Files</h2>
    <div class="close-modal">
      <button type="button" class="close" aria-label="close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <mat-dialog-content class="h-100 content-container">
    <ngx-loading [show]="filesLoading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    
    <div class="files">
        <div class="file" *ngFor="let file of taskFiles; let $index = index">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="icon-file pl">
                <i class="fa fa-paperclip"></i>
              </div>
              <div class="filename">
                <a ngxFilesPreview
                    [fileId]="file.file_id">
                  {{ file.file_name }}
                </a>
              </div>
              <div class="file-info">
                Uploaded at
                <span>{{ file.created_at | date:'MM/dd/yyyy':'utc' }}</span>
                by
                <span>{{ file.created_by_name }}</span>
                <span>({{ file.file_size | fileSize }})</span>
              </div>
            </div>
            <div class="col-md-1">
              <a
                *ngIf="(isCustomer || (user$ | async).id === file.created_by) && allowEdit"
                (click)="removeFile(file)" [matTooltip]="'Delete file'"
              >
                <span class="icon-file trash" aria-hidden="true">
                  <i class="fa fa-trash"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
    </div>

    <div class="d-flex justify-content-center add-files">
      <label for="fileToUpload" class="clickable">
        <i class="fa fa-paperclip"></i>
        Add files
        <input type="file" id="fileToUpload" class="d-none" (change)="addFileSelectedTask($event)"/>
      </label>
    </div>

  </mat-dialog-content>

</div>
