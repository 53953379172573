<div id="printApplicationForm" class="printing">
  <h2 class="modal-title text-center" id="modal-basic-title" style="font-size: 20px;">
    ROCKLAND COUNTY
    <br />
    <small>Department of Social Services</small>
  </h2>
  <div class="pt-4">
    <span class="text-center d-block">
      COUNTY OF ROCKLAND NOTICE OF {{ purchaseOrder?.period || '2024' }} FUNDING OPPORTUNITY
      <br />
      TO PROVIDE SERVICES/PROGRAMS TO COUNTY RESIDENTS
    </span>

    <div class="row mt-4" *ngIf="purchaseOrder?.rcdss_application_form?.status !== 'pending'">
      <div class="col-sm-12">
        <div class="alert alert-danger" *ngIf="purchaseOrder?.rcdss_application_form?.status === 'rejected'">
          This application has been rejected.
          {{
            purchaseOrder?.rcdss_application_form?.rejection_reason
              ? 'Reason: ' + purchaseOrder?.rcdss_application_form?.rejection_reason + '.'
              : ''
          }}
        </div>
        <div class="alert alert-success" *ngIf="purchaseOrder?.rcdss_application_form?.status === 'approved'">
          This application has been approved.
        </div>
      </div>
    </div>

    <div class="top-controls text-center mt-4 mb-1 px-4">
      <div class="row">
        <div class="col-auto m-auto w-25">
          <br />
          <div class="p-2 border">
            <span *ngIf="selectedForm === 'short'">Short Form</span>
            <span *ngIf="selectedForm === 'long'">Long Form</span>
          </div>
          <br />
        </div>
      </div>
      <span class="font-weight-bold d-block pt-4">
        FOR ORGANIZATIONS SEEKING FUNDING $20,000 OR MORE, PICK THE "LONG FORM"
        <br />
        [ORGANIZATIONS SEEKING FUNDING UNDER $19,999, PICK THE "SHORT FORM"]
      </span>
    </div>

    <div class="text-center d-block pt-4">
      <i>
        This opportunity for a minimum one-year contract is a consideration from the County
        <br />
        (January 1, {{ purchaseOrder?.period || '2024' }} – December 31, {{ purchaseOrder?.period || '2024' }}) and is
        subject to availability of funds.
        <br />
        A contract in one year does not guarantee a contract in future years.
      </i>
    </div>

    <!-- <p class="text-justify pt-4">
      NOTABLE CHANGES FOR 2023 CONTRACT FUNDING REQUESTS: At this time, please do not submit any attachments other than
      your completed Service Proposal and funding request. Funding provided by the County will be subject to the Office
      of Management and Budget’s Uniformed Guidance as detailed in the Code of Federal Regulations, Title 2, Grants and
      Agreements. All submissions must be developed on a price per unit of service basis rather than an annual, monthly
      cost or periodic date basis. Based on the Funding Application and the source of funding, the County will determine
      if a competitive procurement process is required. <u>Proposals that do not conform with this requirement and/or
      are incomplete will not be evaluated.</u>
    </p>
    <br>
    <p>
      After your submission of a completed Service Proposal and funding application request, IF you are subsequently
      advised that the County will be offering a 2023 contract to your agency, you will then be asked to furnish all
      appropriate documentation, as will be requested. *A separate portal opening date for this further documentation
      submission can be expected in November, 2023.
    </p>
    <br>
    <p style="color: red;">
      PROPOSAL DUE DATE: 5:00PM ON FRIDAY, OCTOBER 1st, 2022 ON THE DSS PORTAL ONLY. PROPOSALS WILL NOT BE ACCEPTED
      AFTER THIS TIME
    </p>
    <br>
    <ul class="ul-check-bullet">
      <li>
        <u>AGAIN: At this time, please do not submit any attachments other than your completed Service Proposal and
        funding request.</u>
      </li>
    </ul> -->
  </div>
  <!-- <br>
  <div>
    <ul>
      <li>
        PROPOSAL MUST BE COMPLETED USING THIS APPLICATION FORM. ENTER RESPONSES UNDER EACH SECTION. SECTIONS ARE
        EXPANDABLE.
      </li>
      <br>
      <li>
        FOR YOUR CONVENIENCE, THIS APPLICATION HAS BEEN MODIFIED FROM PRIOR YEARS. THEREFORE, YOU ARE ENCOURAGED TO READ
        THROUGH THE ENTIRE APPLICATION IN ADVANCE, BEFORE YOU BEGIN TO ANSWER QUESTIONS.
      </li>
      <br>
      <li>
        YOU WILL NOT BE ABLE TO SUBMIT INCOMPLETE APPLICATIONS, EVERY FIELD MUST BE POPULATED.
      </li>
      <br>
      <li>
        RESPOND TO ALL QUESTIONS IN THE EXPANDABLE SPACE PROVIDED.
      </li>
      <br>
      <li>
        ONLY APPLICATIONS SUBMITTED THROUGH THIS PORTAL WILL BE CONSIDERED
      </li>
      <br>
      <li>
        THE FOCUS OF THIS DOCUMENT IS ON THE PROPOSED PROGRAM (NOT ON YOUR ORGANIZATION).
      </li>
      <br>
      <li>
        <p>
          SUBSTANTIVE QUESTIONS RELATING TO THIS DOCUMENT, SHOULD BE SUBMITTED THROUGH THE "MESSAGES" SECTION LOCATED
          ON THE CONTRACT PAGE
        </p>
        <br>
        <p>
          FOR TECHNICAL ASSISTANCE RELATING TO THIS DOCUMENT, PLEASE EMAIL <a href="mailto:rcdss@ionsupply.com">rcdss@ionsupply.com</a>
        </p>
        <br>
        <p>
          INQUIRIES BY EMAIL, TELEPHONE, FAX, ETC. TO ANY OTHER COUNTY OF ROCKLAND DEPARTMENT OR EMPLOYEE WILL NOT BE
          ACCEPTED OR ANSWERED.
        </p>
        <br>
        <p>
          This funding request does not guarantee a contract and/or performance requirements may be modified by the
          County.</p>
      </li>
    </ul>
  </div> -->
  <br />
  <br />
  <br />
  <br />
  <div>
    <p class="pt-4">
      Organizations submitting a proposal must meet the following requirements, as applicable:
    </p>
    <ul>
      <li>
        Serve Rockland County residents
      </li>
      <li>
        Be a 501(c)3
      </li>
      <li>
        Be governed by a Board of Directors
      </li>
      <li>
        Be up-to-date with all IRS filings
      </li>
      <li>
        Be up-to-date with all NYS filings
      </li>
      <li>Have a {{ purchaseOrder?.period === '2023' ? '2022' : '2023' }} budget with Board approval</li>
      <li>
        Be fiscally sound
      </li>
      <li>
        Have a Non-discrimination Policy
      </li>
      <li>
        Have a Conflict of Interest Policy
      </li>
      <li>
        Maintain program records for the purpose of an audit and provide to the County all program-related information
        including proprietary materials, upon request
      </li>
      <li>
        Submit reports as required by the County Department monitoring the contract including year-end reports,
        attendance lists for each group activity, and log sheets for clients served
      </li>
      <li>
        Be available for on-site visits, announced and unannounced
      </li>
      <li>
        The proposal must sufficiently demonstrate how your organization will collaborate with RCDSS in providing
        services to individuals and families of Rockland County in order to protect the vulnerable Rockland County
        residents, empower them to achieve and maintain self-sufficiency, provide financial assistance, safety and
        support; with compassion, respect and dignity.
      </li>
    </ul>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>

  <div class="border my-4 font-weight-bold">
    <div class="row m-0">
      <div class="col-sm-6 border-right py-4">
        <div class="d-flex flex-column">
          <div>County Department Managing {{ purchaseOrder?.period || '2024' }} Contract:</div>
          <div class="field-container">
            {{ formData.county_department_managing }}
          </div>
        </div>

        <div class="d-flex flex-column">
          <div>Organization/Provider Name:</div>
          <div class="field-container">
            {{ formData.field_1 }}
          </div>
        </div>

        <div class="d-flex flex-column">
          <div>Organization/Provider Main Administrative Office Address: (must include street location):</div>
          <div class="field-container">
            {{ formData.field_2 }}
          </div>
        </div>

        <div class="d-flex flex-column mt-3">
          <div class="mt-4">Federal ID #:</div>
          <div class="field-container">
            {{ formData.field_3 }}
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex">Days and Hours of Proposed Program Operation:</div>
          <br />
          <div class="field-container">
            {{ formData.field_4 }}
          </div>
        </div>

        <hr />

        <div class="d-flex mb-2">
          <p>Requested Funding Amount for Proposed Service/Program:</p>
        </div>

        <div>
          <div class="row text-right">
            <div class="col-sm-2">
              <input
                type="radio"
                name="same_amount"
                value="same_amount"
                [checked]="formData.funding_amount === 'same_amount'"
              />
            </div>
            <div class="col-sm-6 font-weight-normal">
              Same amount as {{ purchaseOrder?.period === '2023' ? '2022' : '2023' }} funding
            </div>
            <div class="col-sm-4">
              <div class="field-container">
                {{ formData.same_amount_value }}
              </div>
            </div>
          </div>
          <div class="row text-right">
            <div class="col-sm-2">
              <input
                type="radio"
                name="additional_funding"
                value="additional_funding"
                [checked]="formData.funding_amount === 'additional_funding'"
              />
            </div>
            <div class="col-sm-6 font-weight-normal">
              Additional funding sought
            </div>
            <div class="col-sm-4">
              <div class="field-container">
                {{ formData.additional_funding_value }}
              </div>
            </div>
          </div>
          <div class="row text-right">
            <div class="col-sm-2"></div>
            <div class="col-sm-6 font-weight-normal">
              For a total contract amount of:
            </div>
            <div class="col-sm-4">
              <div class="field-container">
                {{ formData.additional_funding_value_total }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-left mb-5">
            Note: The County will consider reasonable and modest requests for additional funding, provided that
            additional service deliverables are proposed.
          </div>
        </div>
      </div>

      <div class="col-sm-6 pt-4">
        <div class="d-flex flex-column">
          <div>Title of Your Proposed Service/Program:</div>
          <div class="field-container">
            {{ formData.field_5 }}
          </div>
        </div>

        <hr />

        <div>Individual Submitting Proposal:</div>
        <div class="row">
          <div class="col-sm-6">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_6 }}
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_7 }}
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_8 }}
              </div>
            </div>

            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.individual_submitting_fax }}
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_9 }}
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div>Proposed Program Contact Person:</div>
        <div class="row">
          <div class="col-sm-6">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_10 }}
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_11 }}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_12 }}
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.proposed_program_fax }}
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_13 }}
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div>Proposed Contract Signatory:</div>
        <div class="row">
          <div class="col-sm-6">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_14 }}
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_15 }}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_16 }}
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.proposed_contract_fax }}
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="d-flex flex-column">
              <div class="field-container">
                {{ formData.field_17 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <p>
      <i>
        Note: Responses must contain accurate, current data and must reflect your most recent performance and financial
        records
      </i>
    </p>

    <div>
      <p><strong>PROPOSAL: PART I:</strong></p>
      <p>
        <strong>
          SERVICE/PROGRAM DESCRIPTION: Please provide detailed and specific information for each section. Use as much
          space as necessary but be precise.
        </strong>
      </p>
      <p>
        <strong>
          SERVICE/PROGRAM SUMMARY
        </strong>
      </p>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            1.
          </span>
        </div>
        <div>
          <p>
            <strong>BRIEF SERVICES/PROGRAM DESCRIPTION OF NEED/OPPORTUNITY:</strong>
            <br />
            <br />
            Describe the need(s) for the service/program - be specific. Provide data and/or a needs assessment that
            identifies/supports the need. How does your program address and/or meet this need(s)?
          </p>
          <div>
            <div class="field-container">
              {{ formData.field_18 }}
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            2.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            <strong>TARGET POPULATION:</strong>
            <br />
            <br />
            Describe:
          </p>
          <ul>
            <li>
              <p>Unduplicated number to be served in {{ purchaseOrder?.period || '2024' }}.</p>
              <div class="field-container my-2" style="max-width: 200px;">
                {{ formData.unduplicated_number }}
              </div>
            </li>
            <li>
              <p>Specify if:</p>
              <div class="d-flex d-row mb-4">
                <div class="d-flex px-4">
                  <input
                    type="checkbox"
                    name="population_individuals"
                    [checked]="formData.population_individuals"
                    color="primary"
                  />
                  <span class="ml-2">Individuals</span>
                </div>
                <div class="d-flex px-4">
                  <input
                    name="population_family"
                    type="checkbox"
                    [checked]="formData.population_family"
                    color="primary"
                  />
                  <span class="ml-2">Family</span>
                </div>
              </div>
            </li>
            <li>
              <p class="mt-2">Demographic breakdown (age, gender, and location)</p>
              <div class="field-container">
                {{ formData.field_19 }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="d-flex mt-2">
        <div>
          <span class="p-4">
            3.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            <strong>QUALIFICATIONS:</strong>
          </p>
          <div>
            <ol type="a">
              <li>What makes your organization uniquely qualified to provide this type of program/services?</li>
              <li>
                Are there any barriers to working with the target population that your organization is uniquely
                appropriate/prepared to address? How does your organization plan to overcome these barriers?
              </li>
            </ol>
          </div>
          <div class="field-container">
            {{ formData.field_20 }}
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            4.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            <strong>ELIGIBILITY REQUIREMENTS:</strong>
            <br />
            <br />
            <span
              >Does your proposed program have any eligibility requirements? If yes, complete boxes below and
              <u>explain how eligibility will be verified.</u></span
            >
          </p>

          <div class="d-flex flex-column border text-center">
            <div class="d-flex">
              <div class="col-md-4 border-right py-2">
                <strong>
                  Eligibility Requirements
                  <br />
                  (check, as applicable)
                </strong>
              </div>
              <div class="col-md-8 py-2 text-center">
                <strong>
                  List Process for Verifying Eligibility
                </strong>
              </div>
            </div>
          </div>
          <div class="d-flex border">
            <div class="col-md-4 border-right pt-2 d-flex align-items-center">
              <input type="checkbox" [checked]="formData.rockland_county_resident" name="rockland_county_resident" />
              <span class="ml-2">Rockland County Resident</span>
            </div>
            <div class="col-md-8 pt-2 d-flex align-items-center">
              <div class="field-container">
                {{ formData.rockland_county_resident_value }}
              </div>
            </div>
          </div>
          <div class="d-flex border">
            <div class="col-md-4 border-right pt-2 d-flex align-items-center">
              <input type="checkbox" [checked]="formData.low_income" name="low_income" />
              <span class="ml-2">Low Income</span>
            </div>
            <div class="col-md-8 pt-2 d-flex align-items-center">
              <div class="field-container">
                {{ formData.low_income_value }}
              </div>
            </div>
          </div>
          <div
            class="d-flex border eligibility-requirement pt-4"
            *ngFor="let item of formData.additional_eligibility_requirements; let index = index"
          >
            <div class="col-md-4 border-right pt-2 d-flex align-items-center">
              <div class="field-container">
                {{ item.field_name }}
              </div>
            </div>
            <div class="col-md-8 pt-2 pr-4 d-flex align-items-center">
              <div class="field-container">
                {{ item.field_value }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            5.
          </span>
        </div>
        <div style="width: 100%;">
          <div class="mb-5">
            <p>
              <strong>SERVICE(S) DELIVERABLES:</strong>
              <br />
              <br />
              Respond to each section below for each service deliverable.
              <br />
              <br />
              <span style="color: red;">
                <strong
                  >NOTE: FOR ANY FUNDING REQUESTS IN EXCESS OF
                  {{ purchaseOrder?.period === '2023' ? '2022' : '2023' }} CONTRACT AMOUNT, AN ADDED SERVICE DELIVERABLE
                  IS REQUIRED.</strong
                >
              </span>
            </p>
          </div>
          <div
            class="d-flex flex-column mt-4 p-4 border bg-light"
            *ngFor="let item of formData.services_deliverables; let index = index"
          >
            <div>
              <h5>SERVICE DELIVERABLE {{ index + 1 }}</h5>
              <hr />
            </div>
            <hr />
            <div>
              <p>Type (i.e.: Case Management, Employment Services, Information and Referrals, etc)</p>
              <div class="field-container">
                {{ item.deliverable_type }}
              </div>
            </div>
            <div>
              <p>
                Program Service Description
              </p>
              <div class="field-container">
                {{ item.deliverable_field_1 }}
              </div>
            </div>
            <div>
              <p>
                How will above services be delivered (delivery model / modality; reference best practice or
                evidence-based model being replicated)
              </p>
              <div class="field-container">
                {{ item.deliverable_field_2 }}
              </div>
            </div>
            <div>
              <p>
                Indicate how this approach addresses the need(s)
              </p>
              <div class="field-container">
                {{ item.deliverable_field_3 }}
              </div>
            </div>
            <div>
              <p>
                Explain your Agency’s Emergency Response Protocol, to ensure no / minimal disruption of service (ie due
                to a pandemic, extreme weather, etc.)
              </p>
              <div class="field-container">
                {{ item.deliverable_field_4 }}
              </div>
            </div>
            <div>
              <p>
                List all employees (name, title, contact information) who will provide the services listed
              </p>
              <div class="field-container">
                {{ item.deliverable_field_5 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            6.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            <strong>VOLUNTEERS:</strong>
            <br />
            <br />
            Do you intend to use volunteer staff to provide direct services for this proposed program? If yes, please
            specify. Describe your organization’s screening process for volunteers.
          </p>
          <div class="field-container">
            {{ formData.field_21 }}
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            7.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            <strong>COLLABORATION WITH ROCKLAND COUNTY DEPARTMENT OF SOCIAL SERVICES (RCDSS).</strong>
          </p>

          <div class="d-flex flex-row">
            <div>
              <span class="p-4">
                A.
              </span>
            </div>
            <div style="width: 100%;">
              <p>
                Explain how you will maintain a collaborative relationship with and refer individuals / families to the
                RCDSS in order to ensure that those served receive complete care.
              </p>
              <div class="field-container">
                {{ formData.field_22 }}
              </div>
            </div>
          </div>

          <div class="d-flex flex-row">
            <div>
              <span class="p-4">
                B.
              </span>
            </div>
            <div style="width: 100%;">
              <p>
                How do you propose to follow up to ensure someone referred to RCDSS was in contact with RCDSS? Ex. If
                someone is receiving assistance in completing an application, what is your Agency’s procedure for
                following up to ensure application was submitted or the client was contacted / received the service.
              </p>
              <div class="field-container">
                {{ formData.field_23 }}
              </div>
            </div>
          </div>

          <div class="d-flex flex-row">
            <div>
              <span class="p-4">
                C.
              </span>
            </div>
            <div style="width: 100%;">
              <p>
                Do you have any program-enhancing collaborations that are part of the program for which you are
                requesting funding?
              </p>
              <div class="field-container">
                {{ formData.field_24 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            8.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            <strong
              >If program services will be provided at any other location other than the address listed on the cover
              page, please identify:</strong
            >
          </p>

          <div class="container-fluid border">
            <div class="row py-2 border-bottom">
              <div class="col-md-1">
                <strong>
                  Site
                </strong>
              </div>
              <div class="col-md-5">
                <strong>
                  Name & Address of Site
                </strong>
              </div>
              <div class="col-md-3">
                <strong>
                  Day(s)
                </strong>
              </div>
              <div class="col-md-3">
                <strong>
                  Time (from____ to____)
                </strong>
              </div>
            </div>

            <div
              class="row pt-4 border-bottom program-service px-2"
              *ngFor="let item of formData.program_services; let index = index"
            >
              <div class="col-md-1">
                {{ index + 1 }}
              </div>
              <div class="col-md-5">
                <div class="field-container">
                  {{ item.program_service_field_1 }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="field-container">
                  {{ item.program_service_field_2 }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="field-container">
                  {{ item.program_service_field_3 }}
                </div>
              </div>
            </div>
          </div>

          <div class="row p-4">
            <div class="col-md-6">
              Use this space to explain possible ad hoc sites that may be used for this program, e.g. libraries,
              schools, etc.
            </div>
            <div class="col-md-6">
              <div class="field-container">
                {{ formData.possible_ad_hoc_sites }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            9.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            <strong>PROGRAM GOALS:</strong>
            <br />
            <br />
            Briefly summarize the specific goals and anticipated outcomes for this program
            <u>for each of the deliverables listed in Section 5 above.</u>
          </p>

          <div class="row border bg-light my-2" *ngFor="let item of formData.program_goals; let index = index">
            <div class="col-md-3">
              <span>
                SERVICE DELIVERABLE {{ index + 1 }} - {{ formData.services_deliverables[index].deliverable_type }}
              </span>
            </div>
            <div class="col-md-8">
              <div class="field-container">
                {{ item.field_value_1 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="p-4">
            10.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            <strong
              >DIRECTIONS FOR PERFORMANCE OUTCOMES, INDICATORS AND TARGETS: What will you measure and monitor to know if
              consumers have achieved the desired outcome(s) for each service deliverable outlined in Section 5,
              hereinabove?</strong
            >
            <br />
            <br />
            <strong
              >**NOTE: There must be a Performance Outcomes for each service deliverable outlined in Section 5.</strong
            >
          </p>
          <ul>
            <li>
              <strong>Performance Outcomes</strong> are <strong>benefits or changes</strong> for participants during or
              after participating in service/program activities. Outcomes are
              <strong>based on service/program goals.</strong>
            </li>
            <li>
              <strong>Outcome Indicators</strong> are the <strong>specific items of data</strong> that you will
              <strong>measure and monitor</strong> to track how well the service/program is succeeding on reaching
              identified outcomes. The data should tell you if participants have achieved the desired outcomes.
            </li>
            <li>
              <strong>Outcome Targets</strong> are either of the following:
              <strong>percentage, rate or number of participants</strong> expected to achieve the desired outcomes, or
              the
              <strong>amount of change</strong>
              participants are expected to experience.
            </li>
          </ul>
          <!--         <p class="text-center font-weight-bold" style="padding: 10px 10%;">
          <u>
            NOTE: THE FOLLOWING IS AN EXAMPLE ONLY. APPLICANT SHOULD USE ITS OWN JUDGEMENT
            IN DEVELOPING PROPOSAL-RELATED PERFORMANCE OUTCOMES, OUTCOME INDICATORS, TARGETS, ETC
            <br>
            <br>
            PERFORMANCE OUTCOME: <span class="pl-4" style="font-size: 20px;">EXAMPLE ONLY</span>
          </u>
        </p> -->

          <!-- <div>
          <div class="row mt-4 border border-dark py-2" style="background-color: #e2e2e2;">
            <div class="col-md-6 border-right border-dark">

            </div>
            <div class="col-md-6 py-2">
              Sample
            </div>
          </div>
          <div class="row border border-dark" style="background-color: #e2e2e2;">
            <div class="col-md-6 border-right border-dark py-3">
              <strong>Projected Performance Outcome:</strong>
              <br>
              <strong>Benefit or change for participants</strong> during or after participating in service/program
              activities. Should be based on service/program goals.
            </div>
            <div class="col-md-6 pt-3">
              <mat-form-field>
                <textarea placeholder=""
                          rows="3"
                          matInput
                          value="Recipient will comply with the conditions of apartment’s lease and maintain permanent, independent housing."
                          disabled></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row border border-dark" style="background-color: #e2e2e2;">
            <div class="col-md-6 border-right border-dark py-3">
              <strong>Outcome Indicator & Target</strong>
              <br>
              <br>
              The <strong>Outcome Indicator</strong> is the <strong>specific item of data to be measured and
              monitored</strong> to track how well the program is succeeding on this outcome. <strong>The data should
              tell you if participants have achieved the desired outcome</strong> within the specified timeframe
              <strong>(at least semiannually)</strong>.

              <br>
              <br>
              The <strong>Outcome Target</strong> is the <strong>percentage, rate or number of participants expected to
              achieve</strong> this outcome, or the <strong>amount of change</strong> participants are <strong>expected
              to experience quarterly</strong>.
            </div>
            <div class="col-md-6 pt-3">
              <mat-form-field>
                <textarea placeholder=""
                          rows="3" matInput
                          value="90% of leases will be renewed upon expiration"
                          disabled></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row border border-dark" style="background-color: #e2e2e2;">
            <div class="col-md-6 border-right border-dark py-3">
              <strong>Data Collection Method: (will be due to County Department as required)</strong>
              <br>
              How will data be collected to measure and monitor the outcome indicator.
              <br>
              <strong>Attach data collection instrument(s).</strong>

            </div>
            <div class="col-md-6 pt-3">
              <mat-form-field>
                <textarea placeholder=""
                          rows="5" matInput
                          value="Data will be collected based on the individual terms of the leases (i.e. month to month, semiannual or annual) and charted as leases expire."
                          disabled>
                </textarea>
              </mat-form-field>
            </div>
          </div>
        </div> -->

          <div>
            <div *ngFor="let item of formData.performance_outcomes; let index = index">
              <div class="row mt-4 py-2">
                <div class="col-md-12 font-weight-bold text-center">
                  <span class="text-underline font-weight-bold">
                    PERFORMANCE OUTCOME: SERVICE DELIVERABLE #{{ index + 1 }}
                    <span> &nbsp;-&nbsp;{{ formData.services_deliverables[index].deliverable_type }} </span>
                  </span>
                </div>
              </div>
              <div class="d-flex border border-dark bg-light">
                <div class="col-md-6 border-right border-dark py-3">
                  <strong>Projected Performance Outcome:</strong>
                  <br />
                  <strong>Benefit or change for participants</strong> during or after participating in service/program
                  activities. Should be based on service/program goals.
                </div>
                <div class="col-md-6 pt-3">
                  <div class="field-container">
                    {{ item.performance_outcome_field_1 }}
                  </div>
                </div>
              </div>
              <div class="d-flex border border-dark bg-light">
                <div class="col-md-6 border-right border-dark py-3">
                  <strong>Outcome Indicator & Target</strong>
                  <br />
                  <br />
                  The <strong>Outcome Indicator</strong> is the
                  <strong>specific item of data to be measured and monitored</strong> to track how well the program is
                  succeeding on this outcome.
                  <strong>The data should tell you if participants have achieved the desired outcome</strong> within the
                  specified timeframe <strong>(at least semiannually)</strong>.

                  <br />
                  <br />
                  The <strong>Outcome Target</strong> is the
                  <strong>percentage, rate or number of participants expected to achieve</strong> this outcome, or the
                  <strong>amount of change</strong> participants are <strong>expected to experience quarterly</strong>.
                </div>
                <div class="col-md-6 pt-3">
                  <div class="field-container">
                    {{ item.performance_outcome_field_2 }}
                  </div>
                </div>
              </div>
              <div class="d-flex border border-dark bg-light">
                <div class="col-md-6 border-right border-dark py-3">
                  <strong>Data Collection Method: (will be due to County Department as required)</strong>
                  <br />
                  How will data be collected to measure and monitor the outcome indicator.
                  <br />
                  <strong>Attach data collection instrument(s).</strong>
                </div>
                <div class="col-md-6 pt-3">
                  <div class="field-container">
                    {{ item.performance_outcome_field_3 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column">
        <div class="offset-md-1">
          <p class="font-weight-bold py-4">
            IF PERFORMANCE OUTCOMES ARE NOT MET, AND/OR REQUIRED REPORTS AND ANY REQUESTED DOCUMENTS ARE NOT SUBMITTED
            WHEN DUE, VOUCHER PROCESSING MAY BE DELAYED AND/OR A FINANCIAL PENALTY MAY BE DEDUCTED FROM THE CONTRACT
            AMOUNT
          </p>
        </div>
        <div>
          <p>
            <strong><u>BUDGET PROPOSAL: PART II:</u></strong>
            <br />
            <br />
            <strong>
              BEFORE DEVELOPING BUDGET, PLEASE READ BELOW AND THE NOTES AT END OF FORM:
            </strong>
          </p>
          <ul>
            <li>
              <strong
                >FOR ANY FUNDING REQUESTS IN EXCESS OF {{ purchaseOrder?.period === '2023' ? '2022' : '2023' }} CONTRACT
                AMOUNT, AN ADDED SERVICE DELIVERABLE IS REQUIRED.</strong
              >
              <br />
              <br />
              <br />
            </li>
            <li>
              <strong
                >This request does not guarantee a formal {{ purchaseOrder?.period || '2024' }} contract award. If
                awarded, the actual contract amount for {{ purchaseOrder?.period || '2024' }} is subject to approval by
                the County Legislature.</strong
              >
            </li>
            <li>
              <strong
                >The budget must be developed on a “price per unit of service” basis rather than an annual, monthly cost
                or periodic date basis; specific breakdowns should be provided</strong
              >
            </li>
            <li>
              <strong><u>Reimbursement is subject to full compliance with all contract deliverables.</u></strong>
            </li>
          </ul>
        </div>
      </div>

      <div class="d-flex flex-row mt-4">
        <div class="d-flex mt-4">
          <span class="p-4">
            1.
          </span>
        </div>
        <div class="d-flex flex-column" style="width: 100%;">
          <div class="d-flex mt-4">
            <div class="col-md-7 offset-md-1 py-2 border bg-light py-2">
              <strong>
                SERVICE DELIVERABLES
              </strong>
            </div>
            <div class="col-md-3 border bg-light py-2">
              <strong>
                COST PER UNIT
                <br />
                OF SERVICE
              </strong>
            </div>
          </div>
          <div class="d-flex" *ngFor="let item of formData.budget_proposal; let index = index">
            <div class="col-md-7 offset-md-1 border bg-light py-2">
              SERVICE DELIVERABLE {{ index + 1 }}
              <span> &nbsp;-&nbsp;{{ formData.services_deliverables[index].deliverable_type }} </span>
            </div>
            <div class="col-md-3 border bg-light py-2">
              <div class="field-container">
                {{ item.budget_proposal_field_1 }}
              </div>
            </div>
          </div>

          <div class="d-flex">
            <div class="col-md-7 offset-md-1 border bg-light py-2 text-right">
              <strong>
                TOTAL AMOUNT REQUESTED
              </strong>
            </div>
            <div class="col-md-3 border bg-light py-2">
              <div class="field-container">
                {{ formData.service_deliverable_total_amount_requested }}
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center h-100">
            <div class="d-flex flex-column">
              <div class="mt-4">
                  <span> * Service unit costs: total expense from above divided by number of service units [i.e.: number of
                    people served; number of meals delivered; number of training sessions provided; price per
                    advertisement, flyer, etc.] </span>
              </div>
              <div class="mb-4">
                <ul class="ul-check-bullet">
                  <li>
                    <strong class="pl-2">TOTAL EXPENSES ABOVE / SERVICE UNITS = COST PER UNIT OF SERVICES</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4">
        <div>
          <span class="px-4 d-block" style="width: max-content;">
            2. a.
          </span>
        </div>
        <div style="width: 100%;">
          <p>
            Will this specific proposed program be funded from different/additional sources?
          </p>

          <div class="row">
            <div class="col">
              <span class="d-block">
                <input type="radio" [checked]="formData.proposed_program_funded === false" />
                No
              </span>
              <span class="d-block">
                <input type="radio" [checked]="formData.proposed_program_funded === true" />
                Yes
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4" *ngIf="formData.proposed_program_funded === true">
        <div>
          <span class="px-4 d-block" style="width: max-content;">
            2. b.
          </span>
        </div>
        <div style="width: 100%;">
          <div>
            <span>
              State what amount of this program is funded through the following sources. Do not include in-kind
              donations/services, use of facilities, etc.
            </span>
            <div class="row">
              <div class="col-md-10 offset-md-1">
                <div class="row p-2 border bg-light">
                  <div class="col-md-8"></div>
                  <div class="col-md-2">
                    <strong>
                      Amount
                    </strong>
                  </div>
                  <div class="col-md-2">
                    <strong>
                      Percentage
                    </strong>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>a.</strong>
                  </div>
                  <div class="col-md-7">
                    Fundraising proceeds
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_25 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_25_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>b.</strong>
                  </div>
                  <div class="col-md-7">
                    School district
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_26 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_26_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>c.</strong>
                  </div>
                  <div class="col-md-7">
                    County funds or grants
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_27 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_27_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>d.</strong>
                  </div>
                  <div class="col-md-7">
                    Other County department funds or grants
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_28 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_28_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>e.</strong>
                  </div>
                  <div class="col-md-7">
                    State funds or grants
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_29 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_29_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>f.</strong>
                  </div>
                  <div class="col-md-7">
                    Federal funds or grants
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_30 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_30_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>g.</strong>
                  </div>
                  <div class="col-md-7">
                    Municipality funds or grants
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_31 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_31_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>h.</strong>
                  </div>
                  <div class="col-md-7">
                    Funds from other community agencies
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_32 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_32_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-1">
                    <strong>i.</strong>
                  </div>
                  <div class="col-md-7">
                    <div class="d-flex flex-row">
                      <span class="d-block pr-3">Other (specify)</span>
                      <div class="field-container">
                        {{ formData.field_33 || '' }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.field_34 || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.field_33_p || '') + '%' }}
                    </div>
                  </div>
                </div>
                <div class="row p-2 border bg-light">
                  <div class="col-md-8 text-right">
                    TOTAL
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ '$ ' + (formData.total_sources || '') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="field-container">
                      {{ (formData.total_sources_p || '') + '%' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <span class="my-4">
              <u
                >NOTE: Payments for the work to be performed as described in the corresponding Service/Program Proposal
                shall not duplicate payments for the same work performed or to be performed under other agreements
                between the Agency and other funding sources including Rockland County.</u
              >
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex mt-4" *ngIf="purchaseOrder?.rcdss_application_form?.status !== 'pending'">
        <div class="col-sm-12">
          <div class="alert alert-danger" *ngIf="purchaseOrder?.rcdss_application_form?.status === 'rejected'">
            This application has been rejected.
            {{
              purchaseOrder?.rcdss_application_form?.rejection_reason
                ? 'Reason: ' + purchaseOrder?.rcdss_application_form?.rejection_reason + '.'
                : ''
            }}
          </div>
          <div class="alert alert-success" *ngIf="purchaseOrder?.rcdss_application_form?.status === 'approved'">
            This application has been approved.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
