<!--
If you want to get focus on an element when opening the modal you need to add an
id="main_focus" to the DOM element you want to focus on

If you want to add a hint label to the button in order to indicate which filter is active use the
'btnHint' property
-->

<ngx-filter-container [btnTitle]="getTitle('number')" typePill="number" (onFilter)="onFilter('number', $event)">
  <div class="d-flex flex-column">
    <span>
      Write a Contract ID if you want to look for a specific one.
    </span>
    <mat-form-field>
      <input
        ngxClickOnEnter="number"
        matInput
        placeholder="Contract ID"
        [attr.aria-label]="'Contract ID'"
        [(ngModel)]="selectedPO"
        id="main_focus"
      />
    </mat-form-field>
  </div>
</ngx-filter-container>

<ngx-filter-container
  btnHint="Due Date"
  [btnTitle]="getTitle('cancel')"
  typePill="cancel"
  (onFilter)="onFilter('cancel', $event)"
>
  <ngx-date-selector
    (dateSelected)="selectedCancelDates = $event"
    [fromDate]="selectedCancelDates && selectedCancelDates[0] ? selectedCancelDates[0] : null"
    [untilDate]="selectedCancelDates && selectedCancelDates[1] ? selectedCancelDates[1] : null"
  ></ngx-date-selector>
</ngx-filter-container>
