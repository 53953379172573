/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ExchangeAdminTokenPayload } from '../models/exchange-admin-token-payload';
import { RefreshTokenPayload } from '../models/refresh-token-payload';
import { LoginPayload } from '../models/login-payload';
@Injectable({
  providedIn: 'root',
})
class TokenService extends __BaseService {
  static readonly tokenExchangeAdminTokenPath = '/exchange_admin_token';
  static readonly tokenRefreshPath = '/refresh';
  static readonly tokenGetTokenPath = '/token';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * exchange_admin_token token
   *
   * Could be accessed with admin only token to exchange admin token to new admin token with organizationID
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  tokenExchangeAdminTokenResponse(payload: ExchangeAdminTokenPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/exchange_admin_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * exchange_admin_token token
   *
   * Could be accessed with admin only token to exchange admin token to new admin token with organizationID
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  tokenExchangeAdminToken(payload: ExchangeAdminTokenPayload): __Observable<null> {
    return this.tokenExchangeAdminTokenResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * refresh token
   *
   * Refreshes an expired token
   * @param payload undefined
   */
  tokenRefreshResponse(payload: RefreshTokenPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * refresh token
   *
   * Refreshes an expired token
   * @param payload undefined
   */
  tokenRefresh(payload: RefreshTokenPayload): __Observable<null> {
    return this.tokenRefreshResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get_token token
   *
   * When POST request is sent to this action, user will receive a token to make API calls
   * @param payload undefined
   */
  tokenGetTokenResponse(payload: LoginPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * get_token token
   *
   * When POST request is sent to this action, user will receive a token to make API calls
   * @param payload undefined
   */
  tokenGetToken(payload: LoginPayload): __Observable<null> {
    return this.tokenGetTokenResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TokenService {
}

export { TokenService }
