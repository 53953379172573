<div class="container-fluid p-0" class="p-4">
  <div class="row">
    <button *ngIf="!isCreationMode" type="button" class="close" aria-label="Close" (click)="modal.dismiss('')">
      <span aria-hidden="true">&times;</span>
    </button>

    <dx-tree-list
      [rowAlternationEnabled]="true"
      [hoverStateEnabled]="true"
      [cellHintEnabled]="false"
      [columnAutoWidth]="true"
      [dataSource]="users"
    >

      <dxi-column caption="Gets Notifications" dataField="" cellTemplate="subs"></dxi-column>
    
      <dxi-column caption="Name" dataField="fullName"></dxi-column>
      <dxi-column caption="Phone" dataField="phone_number"></dxi-column>
      <dxi-column caption="Email" dataField="email"></dxi-column>

      <dxo-paging [pageSize]="12"></dxo-paging>

      <div class="text-center" *dxTemplate="let item of 'subs'">
        <mat-checkbox
          [disabled]="!(allowEdit && (companyType === 'customer' || purchaseOrder?.supplier_id === item.data.organization_id)) || item.data.id === purchaseOrder?.approver_id"
          (change)="setSubscriber($event, item.data.id)"
          [checked]="purchaseOrder?.subscribers?.indexOf(item.data.id) > -1"></mat-checkbox>
      </div>

      <div class="text-center" *dxTemplate="let item of 'copyEmail'">
        <mat-checkbox [disabled]="item.data.id === purchaseOrder?.approver_id || !isCreationMode"
                      [checked]="purchaseOrder?.copy_receivers?.indexOf(item.data.id) > -1"
                      (change)="setCopyEmailUser($event, item.data.id)"></mat-checkbox>
      </div>

      <div class="text-center" *dxTemplate="let item of 'approval'">
        <mat-checkbox
          [disabled]="purchaseOrder?.customer_id === item.data.organization_id || item.data.id === purchaseOrder?.approver_id || !isCreationMode"
          [checked]="item.data.id === purchaseOrder?.approver_id"
          (change)="setApprover($event, item.data.id)"></mat-checkbox>
      </div>
    </dx-tree-list>
  </div>
</div>
