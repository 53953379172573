import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from 'ngx-webstorage';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import CONFIG from '@app/app.config';

@Component({
  selector: 'ngx-product-changes-modal',
  templateUrl: './product-changes-modal.component.html',
})
export class ProductChangesModalComponent implements OnInit {
  @LocalStorage() organizationId: string;
  @Input() openCount: number;
  @Input() pendingCount: number;
  @Input() pastCount: number;
  reissuePos: boolean = false;

  private _makeNewProduct: boolean;
  get makeNewProduct(): boolean {
    return this._makeNewProduct;
  }
  set makeNewProduct(val: boolean) {
    this._makeNewProduct = val;
    if (val === false && this.openCount > 0) {
      this.reissuePos = true;
    }
  }

  modalForm: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    protected router: Router,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.reissuePos = this.openCount > 0;
    this.makeNewProduct = this.pastCount > 0;
    
    this.modalForm = this.fb.group({
      makeNewProduct: [(this.pastCount > 0)],
      reissuePos: [(this.openCount > 0)],
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  submit() {
    this.activeModal.close({ makeNewProduct: this.makeNewProduct, reissuePos: this.reissuePos });
  }
}
