import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'ngx-reissue-po-modal',
  templateUrl: './re-issue-reason-modal.component.html',
})
export class ReIssueReasonModalComponent implements OnInit {
  @LocalStorage() organizationId: string;
  @Input() closable = true;
  @Input() required = false;
  reason: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    protected router: Router,
  ) {}

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

  submit() {
    const reason = this.reason.trim();
    if (this.required && !reason.length) return;
    this.activeModal.close({ reason });
  }
}
