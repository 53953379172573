import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-fields-container',
  templateUrl: './fields-container.component.html',
  styleUrls: ['./fields-container.component.scss'],
})
export class FieldsContainerComponent implements OnInit {
  @Input() title: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() editionMode: boolean = false;
  @Input() allowToEdit: boolean = false;
  @Input() enableCopy: boolean = false;
  @Input() editionMessage: string = 'You are editing this item';
  @Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancelEdition: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() enableEditionModeChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if (this.formGroup) {
      this.formGroup.disable();
    }
  }

  enableEditionMode() {
    this.editionMode = true;
    this.enableEditionModeChange.emit();
  }

  cancelAction() {
    this.editionMode = false;
    this.cancelEdition.emit();
  }

  copyAction() {
    this.copyEvent.emit();
  }

  saveSection() {
    if (this.formGroup.invalid) return;
    this.save.emit(this.formGroup.getRawValue());
  }
}
